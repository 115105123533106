var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0 && xys[1].length > 0
              ? _c("div", [
                  _vm._v(
                    " <" +
                      _vm._s(xys[0][0].slice(-1)[0]) +
                      ">中任意两组样本<" +
                      _vm._s(xys[1][0]) +
                      ">的总体均数相等 "
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "H1",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0 && xys[1].length > 0
              ? _c("div", [
                  xys[0].length > 0 && xys[1].length > 0
                    ? _c("div", [
                        _vm._v(
                          " <" +
                            _vm._s(xys[0][0].slice(-1)[0]) +
                            ">中任意两组样本<" +
                            _vm._s(xys[1][0]) +
                            ">的总体均数不等或不全相等 "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }