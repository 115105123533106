var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-result", {
    staticStyle: { "margin-top": "40px" },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function() {
          return [
            _c("a-icon", {
              staticStyle: { color: "rgba(0, 0, 0, 0.45)", fontSize: "40px" },
              attrs: { type: "info-circle", theme: "outlined" }
            })
          ]
        },
        proxy: true
      },
      {
        key: "extra",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticStyle: { color: "rgba(0, 0, 0, 0.45)", fontSize: "20px" }
              },
              [_vm._v("请至少选择一个变量")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }