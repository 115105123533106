<template>
  <page-header-wrapper :breadcrumb="null" class="anysis">
    <template v-slot:extra>
      <sample></sample>
    </template>
    <div>
      <a-layout class="divContent" id="divContent">
        <a-row style="width: 100% !important">
          <a-col :span="5">
            <method></method>
          </a-col>
          <a-col
            :span="4"
            style="border-left: 1px solid rgb(232, 232, 232); border-right: 1px solid rgb(232, 232, 232)"
          >
            <field :from="'hypothesis'"></field>
          </a-col>
          <a-col :span="15" id="hypContent">
            <router-view style="height: 100% !important"></router-view>
          </a-col>
        </a-row>
      </a-layout>
    </div>
  </page-header-wrapper>
</template>

<script>
import Method from './components/method'
import Field from '@/views/customchart/components/field'
import { AutoHeight } from '@/utils/util'
import Sample from '@/views/customchart/components/sample.vue'

export default {
  data() {
    return {
    }
  },
  components: { Method, Field, Sample },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  activated() {
    this.autoHeightChange()
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var min = ['divContent', 'hypContent', 'field']
      var max = ['method', 'field', 'hypContent', 'childContent']
      AutoHeight(this, min, max, 176)
    }
  }
}
</script>

<style lang="less" >
.anysis .ant-form-item {
  margin: 5px !important;
  display: flex !important;
  align-items: center;
}
.dropDiv {
  height: auto;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center;
}
h1 {
  font-size: 16px;
  color: #1890ff;
}
</style>
<style lang="less" scoped>
body {
  overflow: hidden !important;
}
</style>