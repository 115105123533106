<template>
  <div>
    <h1 class="hCommon">人口学信息</h1>
    <div class="itemWrapperCommon" >
      <div class="itemWrapperCommonPadding40" id="age">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">年龄</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.agelostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.agelostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">平均值</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.ageavg }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">最小值</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.agemin }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">最大值</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.agemax }}</span>
                  </a-col>
                </a-row>
              </a-col>

              <a-col span="12" >
                <div id="myChart" style="width:250px;height:140px;float:right"></div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>

      <a-row style="margin-left:10px">
        <a-col span="24">
          <a-tabs type="card">
            <a-tab-pane key="1" tab="统计"></a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
      <div class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="24">
                <span class="htCommon">分位数统计</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">最小值</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agemin }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">第5百分位数</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agepercent5 }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">Q1</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.ageq1 }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">中位数</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agemedian }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">Q3</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.ageq3 }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">第95百分位数</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agepercent95 }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">最大值</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agemax }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">极差</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agerange }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">四分位间距(IQR)</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agequartile }}</span>
              </a-col>
            </a-row>

          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="24">
                <span class="htCommon">描述型类型</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">均值</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.ageavg }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">标准差</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agestd }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">变异系数</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agecv }}</span>
              </a-col>
            </a-row>
            <divider-common></divider-common>
            <a-row>
              <a-col span="12">
                <span class="httCommon">方差</span>
              </a-col>
              <a-col span="12">
                <span class="ttCommon">{{ datapro.agefc }}</span>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div class="itemWrapperCommonPaddingLeft" id="sex">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">性别</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">男</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.sexmalePercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">女</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.sexfmalePercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.sexlostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.sexlostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row >
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="sexChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="barExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div class="itemWrapperCommonPaddingLeft" id="nation">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">民族</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">汉族</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.nationhanPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">少数民族</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.nationotherPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.nationlostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.nationlostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="nationChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="barExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div class="itemWrapperCommonPaddingLeft" id="marry">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">婚姻状况</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">未婚</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marryunmarriedPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">已婚</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marrymarriedPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">离婚</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marrydivorcePercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">其他</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marryotherPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marrylostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ datapro.marrylostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-histogram
                  :data="marryChart"
                  height="200px"
                  width="250px"
                  :legend-visible="false"
                  :extend="histogramExtend"
                  style="float:right"></ve-histogram>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import DividerCommon from './DividerCommon'

export default {
    components: { DividerCommon },
     props: {
        datapro: {
         type: Object,
          default: () => {}
        }
    },
    data() {
      return {
        ChartLineGraph: null,
          sexChart: { columns: [], rows: [] },
          nationChart: { columns: [], rows: [] },
          ageChart: { columns: [], rows: [] },
          marryChart: { columns: [], rows: [] },
          histogramExtend: {
            tooltip: {
              axisPointer: {
                type: 'shadow'
              }
            },
            color: ['#3a77b6'],
            series: {
              type: 'bar',
              barWidth: 15,
              label: {
                show: true,
                position: 'top'
              }
            },
            yAxis: {
              show: false
            },
            xAxis: {
              axisLine: {
                show: true
              }
            }
          },
          barExtend: {
            color: ['#3a77b6'],
            tooltip: {
              axisPointer: {
                type: 'shadow'
              }
            },
            series: {
              type: 'bar',
              label: {
                show: true,
                position: 'inside'
              }
            },
            xAxis: {
              show: false
            }
          },
          agedata: []
      }
    },
    methods: {
      drawLine() {
        var myChart = document.getElementById('myChart')
        this.ChartLineGraph = this.$echarts.init(myChart)
        var agedata = this.agedata
        var edata = []
        if (agedata) {
          // var maxV = Math.max.apply(Math, agedata)
          // var minV = Math.min.apply(Math, agedata)
          var scopeMax = 100
          var scopeMin = 0
          var interval = 10
          var tmin = scopeMin
          while (tmin < scopeMax) {
              var x0 = tmin
              var x1 = (tmin * 10 + interval * 10) / 10
              var samplenum = 0
              for (var i = 0; i < agedata.length; i++) {
                  if (agedata[i] > x0 && agedata[i] <= x1) {
                      samplenum++
                  }
              }
              edata.push([x0, x1, samplenum])
              tmin = x1
          }
        }
        var option = {
              color: ['#3a77b6'],
              grid: {
                top: 20,
                bottom: 10,
                containLabel: true
              },
              tooltip: {
                trigger: 'item',
                formatter: function(params) {
                  var htmlStr = '<div>'
                  htmlStr += params.data[0] + '岁-' + params.data[1] + '岁<br/>'
                  htmlStr += '人数：' + params.data[2]
                  htmlStr += '</div>'
                  return htmlStr
                }
              },
              xAxis: [{
                  type: 'value',
                  min: scopeMin,
                  max: scopeMax,
                  interval: interval * 2,
                   splitLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  }
              }],
              yAxis: [{
                  type: 'value',
                   axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  },
                  show: false
              }],
              series: [{
                  name: 'height',
                  type: 'custom',
                  renderItem: this.renderItem,

                  barWidth: 15,
                  label: {
                      normal: {
                          show: true,
                          position: 'top'
                      }
                  },
                  encode: {
                      x: [0, 1],
                      y: 2,
                      tooltip: 2,
                      label: 2

                  },
                  // data: data
                  data: edata
              }]
        }
        this.ChartLineGraph.setOption(option, true)
      },
      renderItem(params, api) {
        var yValue = api.value(2)
        var start = api.coord([api.value(0), yValue])
        var size = api.size([api.value(1) - api.value(0), yValue])
        var style = api.style()

        return {
            type: 'rect',
            shape: {
                x: start[0] + 1,
                y: start[1],
                width: size[0] - 2,
                height: size[1]
            },
            style: style
        }
     }
    },
    watch: {
      datapro(val, oldval) {
        this.agedata = val.age && JSON.parse(val.age)
        this.drawLine()
        this.sexChart = {
          columns: ['类别', '人数'],
          rows: [
            { '类别': '女', '人数': val.sexfmale },
             { '类别': '男', '人数': val.sexmale }
            ]
        }
        this.nationChart = {
          columns: ['类别', '人数'],
          rows: [
            { '类别': '少数民族', '人数': val.nationother }, { '类别': '汉族', '人数': val.nationhan }
            ]
        }
        this.marryChart = {
          columns: ['类别', '人数'],
          rows: [
            { '类别': '未婚', '人数': val.marryunmarried },
            { '类别': '已婚', '人数': val.marrymarried },
            { '类别': '离婚', '人数': val.marrydivorce },
            { '类别': '其他', '人数': val.marryother }
            ]
        }
      }
    }

}
</script>
