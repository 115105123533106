<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module">
      <a-table
        :ref="`tables,2`"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="JSON.parse(table.data)"
        :columns="table.columns"
        size="small"
      >
        <!-- <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div> -->
      </a-table>
    </div>
    <div class="third-module">
      <slot name="text2"></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <a-row :gutter="16">
        <a-col v-for="(ary, index) in imgs" :key="index" :xs="24" :sm="24" :md="24" :lg="24" :xl="12" :xxl="12">
          <img :src="ary.src" :ref="`imgs,${indexT+5}`" />
        </a-col>
      </a-row>
    </div>
    <div class="third-module">
      <slot name="text4"></slot>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['tables', 'imgs', 'indexT'],
  created() {
    console.log('table', this.tables)
  },
  data() {
    return {
      imgsLayout: 2
    }
  }
}
</script>
<style lang="less">
</style>