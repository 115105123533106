var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0 50px !important" } },
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "a-breadcrumb",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/experiment/Exp1/List/5" } },
                        [_vm._v("精密度实验列表")]
                      )
                    ],
                    1
                  ),
                  _c("a-breadcrumb-item", [_vm._v(" 新建精密度实验 ")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            labelAlign: "left",
            "label-col": { md: { span: 4 }, xxl: { span: 3 } },
            "wrapper-col": { md: { span: 20 }, xxl: { span: 21 } }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "记录编号" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入记录编号" },
                model: {
                  value: _vm.form.serialNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "serialNum", $$v)
                  },
                  expression: "form.serialNum"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验类型", prop: "type" } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v(" 精密度验证 ")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v(" 正确度验证 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.type === 1
                ? _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: { background: "#f7f7f7" },
                                  attrs: { md: 12, xxl: 8 }
                                },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0 10px" },
                                      attrs: {
                                        "label-col": {
                                          md: { span: 8 },
                                          xxl: { span: 9 }
                                        },
                                        "wrapper-col": {
                                          md: { span: 16 },
                                          xxl: { span: 15 }
                                        },
                                        label: "请选择验证方法",
                                        prop: "accuracyFunction"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          model: {
                                            value: _vm.form.accuracyFunction,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "accuracyFunction",
                                                $$v
                                              )
                                            },
                                            expression: "form.accuracyFunction"
                                          }
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 0 } },
                                            [_vm._v(" 参考方法 ")]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 1 } },
                                            [_vm._v(" 常规方法 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.form.accuracyFunction === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "厂家声明偏倚(B)", prop: "b" } },
                [
                  _c("a-input-number", {
                    style: { width: _vm.style.input_width },
                    attrs: {
                      min: 0,
                      placeholder: "请输入厂家声明偏倚(B)",
                      formatter: function(value) {
                        return value + "mmol/L"
                      },
                      parser: function(value) {
                        return value.replace("mmol/L", "")
                      }
                    },
                    model: {
                      value: _vm.form.b,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "b", $$v)
                      },
                      expression: "form.b"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.accuracyFunction === 0
            ? _c(
                "div",
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "参考物质指定值", prop: "designedValue" }
                    },
                    [
                      _c("a-input-number", {
                        style: { width: _vm.style.input_width },
                        attrs: {
                          min: 0,
                          placeholder: "请输入参考物质指定值",
                          formatter: function(value) {
                            return value + "mmol/L"
                          },
                          parser: function(value) {
                            return value.replace("mmol/L", "")
                          }
                        },
                        model: {
                          value: _vm.form.designedValue,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "designedValue", $$v)
                          },
                          expression: "form.designedValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "指定标准差", prop: "designedStd" } },
                    [
                      _c("a-input-number", {
                        style: { width: _vm.style.input_width },
                        attrs: {
                          min: 0,
                          placeholder: "请输入指定标准差",
                          formatter: function(value) {
                            return value + "mmol/L"
                          },
                          parser: function(value) {
                            return value.replace("mmol/L", "")
                          }
                        },
                        model: {
                          value: _vm.form.designedStd,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "designedStd", $$v)
                          },
                          expression: "form.designedStd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === 0
            ? _c(
                "div",
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "规定重复CV1", prop: "cV1" } },
                    [
                      _c("a-input-number", {
                        style: { width: _vm.style.input_width },
                        attrs: {
                          precision: 1,
                          min: 0,
                          placeholder: "请输入样本浓度",
                          formatter: function(value) {
                            return value + "%"
                          },
                          parser: function(value) {
                            return value.replace("%", "")
                          }
                        },
                        model: {
                          value: _vm.form.cV1,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cV1", $$v)
                          },
                          expression: "form.cV1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "规定重复CV2", prop: "cV2" } },
                    [
                      _c("a-input-number", {
                        style: { width: _vm.style.input_width },
                        attrs: {
                          precision: 1,
                          min: 0,
                          placeholder: "请输入样本浓度",
                          formatter: function(value) {
                            return value + "%"
                          },
                          parser: function(value) {
                            return value.replace("%", "")
                          }
                        },
                        model: {
                          value: _vm.form.cV2,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cV2", $$v)
                          },
                          expression: "form.cV2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验名称", prop: "expName" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入实验名称" },
                model: {
                  value: _vm.form.expName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expName", $$v)
                  },
                  expression: "form.expName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验地点" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入实验地点" },
                model: {
                  value: _vm.form.expAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expAddress", $$v)
                  },
                  expression: "form.expAddress"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验天数" } },
            [
              _c("a-input-number", {
                style: { width: _vm.style.input_width },
                attrs: {
                  min: 0,
                  placeholder: "请输入实验天数",
                  "addon-after": ".com",
                  formatter: function(value) {
                    return value + "天"
                  },
                  parser: function(value) {
                    return value.replace("天", "")
                  }
                },
                model: {
                  value: _vm.form.expDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expDays", $$v)
                  },
                  expression: "form.expDays"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验人员" } },
            [
              _c(
                "a-checkbox-group",
                {
                  attrs: { value: JSON.parse(_vm.form.expUsers) },
                  on: {
                    change: function(value) {
                      return (_vm.form.expUsers = JSON.stringify(value))
                    }
                  }
                },
                _vm._l(_vm.res.users, function(user) {
                  return _c(
                    "a-checkbox",
                    { key: user.id, attrs: { value: user.id } },
                    [_vm._v(" " + _vm._s(user.userName) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验设备" } },
            [
              _c(
                "a-select",
                {
                  style: { width: _vm.style.input_width },
                  attrs: {
                    mode: "multiple",
                    value: JSON.parse(_vm.form.expDevices),
                    placeholder: "请选择实验设备"
                  },
                  on: {
                    change: function(value) {
                      return (_vm.form.expDevices = JSON.stringify(value))
                    }
                  }
                },
                _vm._l(_vm.res.expDevices, function(expDevice) {
                  return _c("a-select-option", { key: expDevice.id }, [
                    _vm._v(" " + _vm._s(expDevice.name) + " ")
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "说明" } },
            [
              _c("a-textarea", {
                staticStyle: { width: "50%" },
                attrs: {
                  placeholder: "请输入说明",
                  "auto-size": { minRows: 3, maxRows: 5 }
                },
                model: {
                  value: _vm.form.explain,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "explain", $$v)
                  },
                  expression: "form.explain"
                }
              })
            ],
            1
          ),
          _c("a-divider"),
          _c("item", {
            attrs: {
              name: "试剂",
              inputWidth: _vm.style.input_width,
              itemForms: _vm.form.reagents
            }
          }),
          _c("a-divider"),
          _c(
            "a-form-model-item",
            { attrs: { label: "样本名称" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入样本名称" },
                model: {
                  value: _vm.form.sampleName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sampleName", $$v)
                  },
                  expression: "form.sampleName"
                }
              })
            ],
            1
          ),
          _vm._l(_vm.form.sampleConcentrations, function(
            sampleConcentration,
            index
          ) {
            return _c(
              "a-form-model-item",
              { key: index, attrs: { label: "样本浓度" + (index + 1) } },
              [
                _c("a-input-number", {
                  style: { width: _vm.style.input_width },
                  attrs: {
                    min: 0,
                    placeholder: "请输入样本浓度",
                    formatter: function(value) {
                      return value + "%"
                    },
                    parser: function(value) {
                      return value.replace("%", "")
                    }
                  },
                  model: {
                    value: sampleConcentration.value,
                    callback: function($$v) {
                      _vm.$set(sampleConcentration, "value", $$v)
                    },
                    expression: "sampleConcentration.value"
                  }
                }),
                _vm.form.sampleConcentrations.length !== 1
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteSampleConcentration(index)
                          }
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: {
                            type: "minus-circle",
                            theme: "twoTone",
                            twoToneColor: "#1890FF"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                index === _vm.form.sampleConcentrations.length - 1
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          cursor: "pointer"
                        },
                        on: { click: _vm.addSampleConcentration }
                      },
                      [
                        _c("a-icon", {
                          attrs: {
                            type: "plus-circle",
                            theme: "twoTone",
                            twoToneColor: "#1890FF"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _c(
            "a-form-model-item",
            { attrs: { label: "样本批号" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入样本批号" },
                model: {
                  value: _vm.form.sampleBatchNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sampleBatchNum", $$v)
                  },
                  expression: "form.sampleBatchNum"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "重复测量次数" } },
            [
              _c("a-input-number", {
                style: { width: _vm.style.input_width },
                attrs: {
                  placeholder: "请输入重复测量次数",
                  min: 1,
                  formatter: function(value) {
                    return value + "次"
                  },
                  parser: function(value) {
                    return value.replace("次", "")
                  },
                  disabled: _vm.form.accuracyFunction === 1
                },
                model: {
                  value: _vm.form.repeatNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "repeatNum", $$v)
                  },
                  expression: "form.repeatNum"
                }
              })
            ],
            1
          ),
          _c("a-divider"),
          _c("item", {
            attrs: {
              name: "校准品",
              inputWidth: _vm.style.input_width,
              itemForms: _vm.form.calibration
            }
          }),
          _c("a-divider"),
          _c("item", {
            attrs: {
              name: "质控品",
              inputWidth: _vm.style.input_width,
              itemForms: _vm.form.quality
            }
          })
        ],
        2
      ),
      _c(
        "a-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
        [_vm._v(" 提交 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }