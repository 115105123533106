<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }">
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <div hidden>
          <a-form-item>
            <a-input v-decorator="['id']"></a-input>
          </a-form-item>
        </div>
        <a-form-item label="量表(问卷)名称">
          <a-input
            v-decorator="['title',{ rules:[
              {
                required: true,
                message: '请填写量表(问卷)名称',
              }]}]"></a-input>
        </a-form-item>
        <a-form-item label="量表(问卷)模版">
          <a-select
            v-decorator="['questionModelID',{ rules:[
              {
                required: true,
                message: '请选择量表(问卷)模版',
              }]}]"
            :disabled="(model&&model.numberOfPeople>0)?true:false">
            <a-select-option v-for="(item,i) in models" :key="i" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="有效期" >
          <a-range-picker v-decorator="['range-picker', {rules: [{ type: 'array', required: true, message: '请选择有效期' }]}]" />
        </a-form-item>
        <!-- <a-form-item label="有效期" style="margin-bottom:0;">
          <a-form-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
          >
            <a-date-picker
              v-decorator="['startDate',{ rules:[
                {
                  required: true,
                  message: '请选择开始日期',
                }]}]"
              style="width: 100%"
              placeholder="开始时间"/>
          </a-form-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
            -
          </span>
          <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
            <a-date-picker
              v-decorator="['endDate',{ rules:[
                {
                  required: true,
                  message: '请选择结束日期',
                }]}]"
              style="width: 100%"
              placeholder="结束时间"/>
          </a-form-item>
        </a-form-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { GetQuestionModel } from '@/api/questionnaire'
import pick from 'lodash.pick'
// 表单字段
const fields = ['id', 'title', 'questionModelID', 'range-picker']

export default {
  // props为父组件传过来的参数
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    title: {
      type: String,
      default: () => '创建量表(问卷)'
    },
    disable: {
      type: Boolean,
      default: () => false
    }
  },
  // 当前页面需要的参数
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      models: []
    }
  },
  created () {
    console.log('custom modal created')
    GetQuestionModel({ 'orgid': this.$store.state.user.info.orgid }).then(res => { this.models = res })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      console.log('model', this.model)
    })
  }
}
</script>
