<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :vuexForm="vuexForm"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
  </index>
</template>
<script>
import { settings, options } from './inputItems'
import Index from '@/views/analysisOfRegression/components/indexComponent'

export default {
  components: { Index },
  data() {
    return {
      xysName: 'fittingXys',
      xys: [[], []],
      formAddKeyName: 'fittingFormAddKey',
      settings,
      form: {
        formula: []
      },
      vuexForm: {
        compareList: []
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.analysisOfRegression.method) {
        var form = this.$deepClone(that.form)
        console.log('%c 🍆 var form: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', that.form)
        form.xys = this.$deepClone(that.computedXys)
        // 从多选框的options中找出选中的方程以及相关属性
        let array = []
        this.$deepClone(options).forEach(v => {
          array = array.concat(
            v.children.filter(v1 => form.formula.indexOf(v1.value) !== -1)
          )
        })
        form.formula = this.fetchFormulas(array)
        form.initialValues = this.fetchInitialValues(array)
        Object.assign(form, this.$store.state.analysisOfRegression.ordinaryLeastSquaresForm)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/analysisOfRegression/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    },
    fetchFormulas(array) {
      // 拼凑出后端想要的格式
      return array.map( v => {
        // 将label拆分成数组
        const list = v.label.split(': ')
        v.name = list[0]
        v.formula = list[1]
        delete v.label
        return v
      })
    },
    fetchInitialValues(array) {
      const initialValues = []
      array.forEach((e, index) => {
        initialValues.push([])
        for (let i = 0; i < e.argNumber; i++) {
          initialValues[index].push(0)
        }
      })
      return initialValues
    }
  }
}
</script>