<template>
  <a-modal
    width="600px"
    :maskClosable="false"
    :visible="visible" title="图表信息设置"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }">
    <a-form-model :model="data" v-bind="formLayout">
      <a-form-model-item label="标题">
        <a-input v-model="data.title"></a-input>
      </a-form-model-item>
      <a-form-model-item label="x轴" v-if="data.action!=='pie'&& data.action!=='funnel'&& data.action!=='TreeMap'&& data.action!=='Radar'" >
        <a-input v-model="data.x"></a-input>
      </a-form-model-item>
      <a-form-model-item label="y轴" v-if="data.action!=='pie'&& data.action!=='funnel'&& data.action!=='TreeMap'&& data.action!=='Radar'" >
        <a-input v-model="data.y"></a-input>
      </a-form-model-item>
      <a-form-model-item label="y副轴" v-if="data.action==='paleituotu'" >
        <a-input v-model="data.z"></a-input>
      </a-form-model-item>
      <div v-if="data.labels&&data.labels.length>0 && data.action!=='funnel'&& data.action!=='TreeMap'">
        <a-form-model-item label="图例位置">
          <div style="border:1px solid #dedede">
            <div style="padding:10px">
              图例位置
              <a-select v-model="data.legend.loc">
                <a-select-option key="0" :value="0">自动寻找最好的位置</a-select-option>
                <a-select-option key="1" :value="1">右上角</a-select-option>
                <a-select-option key="2" :value="2">左上角</a-select-option>
                <a-select-option key="3" :value="3">右下角</a-select-option>
                <a-select-option key="4" :value="4">左下角</a-select-option>
                <a-select-option key="5" :value="5">右边中间</a-select-option>
                <a-select-option key="6" :value="6">左边中间</a-select-option>
                <a-select-option key="8" :value="8">中间最下面</a-select-option>
                <a-select-option key="9" :value="9">中间最上面</a-select-option>
                <a-select-option key="10" :value="10">正中间</a-select-option>
              </a-select>
            </div>
            <div style="padding:10px">图例偏移<span style="color:#ff7200;margin-left:10px">(如果不想要可以清空)</span>
              <a-input-group compact>
                <a-input-number v-model="data.legend.x" style="width: 50%" placeholder="x轴偏移量" title="x轴偏移量,建议(-0.2,1.2)"/>
                <a-input-number v-model="data.legend.y" style="width: 50%" placeholder="y轴偏移量" title="y轴偏移量,建议(-0.2,1.2)"/>
              </a-input-group>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <div v-if="data.labels&&data.labels.length>0">
        <a-form-model-item label="图例内容">
          <div style="border:1px solid #dedede">
            <div v-for="(lab,i) in data.labels" :key="i" style="padding:10px">
              {{ lab.old }}:
              <a-input v-model="lab.new" placeholder="新的名称"></a-input>
            </div>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>

  </a-modal>
</template>
<script>
export default {
  props: ['visible', 'data'],
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      settings: []
    };
  },
  created() {
    console.log('aa', this.data)
  },
  methods: {
    setDefault() {
      var moren = ['#5f90e3', '#e5896b', '#81d385', '#e6cd75', '#a990da', '#6bc3e1', '#e5acaf', '#a7c23b', '#ddb06e', '#808693', '#adbfc3', '#41e7ea']
      this.data.colorsArry = moren
    }

  },
  computed: {

  }
}
</script>
<style lang="less" >
 .colorBtn{
  width:50px !important
}
</style>