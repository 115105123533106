var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("a-icon", {
        attrs: { type: "setting" },
        on: { click: _vm.showModal }
      }),
      _c(
        "a-modal",
        {
          staticStyle: { height: "auto" },
          attrs: { title: "智能报告配置" },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v("恢复默认")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 }
                    },
                    [
                      _c(
                        "a-button",
                        { key: "back", on: { click: _vm.handleCancel } },
                        [_vm._v(" 取消 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleOk }
                        },
                        [_vm._v(" 确定 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "显著水平α",
                "label-col": { span: 4 },
                "wrapper-col": { span: 20 }
              }
            },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.a,
                    callback: function($$v) {
                      _vm.a = $$v
                    },
                    expression: "a"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 0.01 } }, [_vm._v(" 1% ")]),
                  _c("a-radio", { attrs: { value: 0.05 } }, [_vm._v(" 5% ")]),
                  _c("a-radio", { attrs: { value: 0.1 } }, [_vm._v(" 10% ")])
                ],
                1
              )
            ],
            1
          ),
          _vm.type_name_list.length > 0
            ? _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": _vm.tab_default_active_key,
                    "tab-position": "top"
                  }
                },
                [
                  _vm._l(_vm.settings, function(type, type_index) {
                    return [
                      _vm.tabIsShow(type.value)
                        ? _c(
                            "a-tab-pane",
                            { key: type_index, attrs: { tab: type.name } },
                            [
                              "flows" in type.options
                                ? _c(
                                    "a-card",
                                    {
                                      class: {
                                        noBottomBorder:
                                          type.options.methods.length > 0
                                      },
                                      attrs: { size: "small", title: "方法" }
                                    },
                                    _vm._l(type.options.flows, function(
                                      flow,
                                      flow_index
                                    ) {
                                      return _c(
                                        "a-form-model-item",
                                        {
                                          key: flow_index,
                                          attrs: { label: flow.label }
                                        },
                                        [
                                          flow.type === "radio"
                                            ? _c(
                                                "a-radio-group",
                                                {
                                                  model: {
                                                    value: flow.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        flow,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "flow.value"
                                                  }
                                                },
                                                _vm._l(flow.options, function(
                                                  option,
                                                  option_index
                                                ) {
                                                  return _c(
                                                    "a-radio",
                                                    {
                                                      key: option_index,
                                                      attrs: {
                                                        value: option.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(option.text) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(type.options.methods, function(
                                method,
                                method_index
                              ) {
                                return _c(
                                  "a-card",
                                  {
                                    key: method_index,
                                    class: { noTopBorder: method_index !== 0 },
                                    attrs: { size: "small", title: method.name }
                                  },
                                  _vm._l(method.args, function(arg, arg_index) {
                                    return _c(
                                      "a-form-model-item",
                                      {
                                        key: arg_index,
                                        attrs: { label: arg.name }
                                      },
                                      [
                                        arg.type === "radio"
                                          ? _c(
                                              "a-radio-group",
                                              {
                                                model: {
                                                  value: arg.value,
                                                  callback: function($$v) {
                                                    _vm.$set(arg, "value", $$v)
                                                  },
                                                  expression: "arg.value"
                                                }
                                              },
                                              _vm._l(arg.options, function(
                                                option,
                                                option_index
                                              ) {
                                                return _c(
                                                  "a-radio",
                                                  {
                                                    key: option_index,
                                                    attrs: {
                                                      value: option.value
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(option.text) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "当前无可配置的研究方向，请向智能分析中拖入可研究的变量组。"
                )
              ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }