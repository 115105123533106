/* eslint-disable no-useless-constructor */
import {
  Req
} from '@/utils/util'
export class MakeReq extends Req {
  constructor(form, method, that) {
    super(form, method, that)
  }
  // 例如单样本比例检验
  oneLei() {
    const xys = this.form.xys
    const lei = xys[0][0].slice(-1)[0]
    this.form.list = {}
    let total = 0

    for (let index = 0; index < xys[0][0].length - 1; index++) {
      const key = xys[0][0][index]
      this.form.list[key] = 0
    }
    this.that.documents.forEach(element => {
        this.form.list[element[lei]]++
        total++
    })
    this.form.list.全体 = total
    return this.req
  }

  twoLei() {
    const xys = this.form.xys
    const lei1 = xys[0][0].slice(-1)[0]
    const lei2 = xys[1][0].slice(-1)[0]
    const lei1List = xys[0][0]
    const lei2List = xys[1][0]
    this.form.list = []
    this.form.totalList = [0, 0]
    for (let index = 0; index < xys[0][0].length - 1; index++) {
      this.form.list.push([])
      for (let index2 = 0; index2 < xys[1][0].length - 1; index2++) {
        this.form.list[index].push(0)
      }
    }
    this.that.documents.forEach(element => {
            const index1 = lei1List.indexOf(element[lei1])
            const index2 = lei2List.indexOf(element[lei2])
            this.form.list[index1][index2]++
            this.form.totalList[index2]++
    })
    this.form.optionList = this.form.list[lei1List.indexOf(this.form.option)]
    return this.req
  }
}