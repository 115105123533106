var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { background: "#dedede" } }, [
    _c("div", { staticClass: "pagediv" }, [
      _c("div", { staticClass: "titleB" }, [_vm._v("临床营养风险筛查记录表")]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "titleS" }, [_vm._v("1 患者基本信息")]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            _vm._s(
              "患者知情同意参加：是[ " +
                (_vm.data[0].value === "同意参加" ? "√" : "\xa0\xa0") +
                " ]；否[ " +
                (_vm.data[0].value === "不同意参加" ? "√" : "\xa0\xa0") +
                " ]"
            )
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("患者编号："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[1].value))
          ])
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("经伦理委员会批准。 批准号："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[2].value))
          ])
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(" 单位名称："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[3].value))
          ]),
          _vm._v(" 科室名称："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[4].value))
          ]),
          _vm._v(" 病历号："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[5].value))
          ])
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            " " +
              _vm._s(
                "适用对象：18岁~ 90岁，住院1 d以上，次日8时前未行手术，神志清者。 是[ " +
                  (_vm.data[6].value === "符合条件" ? "√" : "\xa0\xa0") +
                  " ]；否[ " +
                  (_vm.data[6].value === "不符合条件" ? "√" : "\xa0\xa0") +
                  " ]"
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            " " +
              _vm._s(
                "不适用对象：18岁以下，90岁以上，住院不过夜，次日8时前行手术，神志不清。 是[ " +
                  (_vm.data[7].value === "符合条件" ? "√" : "\xa0\xa0") +
                  " ]； 否[ " +
                  (_vm.data[7].value === "不符合条件" ? "√" : "\xa0\xa0") +
                  " ]"
              )
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(" 入院日期："),
          _c(
            "span",
            {
              staticClass: "contentUnderLine",
              staticStyle: { width: "150px" }
            },
            [_vm._v(_vm._s(_vm.data[8].value))]
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(" 病房"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[9].value))
          ]),
          _vm._v(", 病床"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[10].value))
          ]),
          _vm._v(", 姓名"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[11].value))
          ]),
          _vm._v("， 性別"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[12].value))
          ]),
          _vm._v("， 年龄"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[13].value))
          ]),
          _vm._v("岁， 联系电话"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[14].value))
          ])
        ]),
        _c("div", { staticClass: "titleS" }, [_vm._v("2临床营养风险筛查")]),
        _c("div", { staticClass: "titleS" }, [_vm._v("主要诊断")]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("主要诊断："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[15].value))
          ])
        ]),
        _c("div", { staticClass: "titleS" }, [_vm._v("2. 1 疾病评分")]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("若患有以下疾病请在[ ]打“√”，并参照标准进行评分。")
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            "注：未列入下述疾病者须“挂靠”, 如 “急性胆襄炎” 、“老年痴呆”等可挂靠于“慢性疾病急性发作或有并发症者”计1分(复核者有权决定挂靠的位置)。"
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            _vm._s(
              "髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤(1分) [ " +
                (_vm.data[16].value ===
                "髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤"
                  ? "√"
                  : "\xa0\xa0") +
                " ]；"
            )
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            _vm._s(
              "腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤(2分) [ " +
                (_vm.data[16].value ===
                "腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤"
                  ? "√"
                  : "\xa0\xa0") +
                " ]；"
            )
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            _vm._s(
              "颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者(3分) [ " +
                (_vm.data[16].value ===
                "颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者"
                  ? "√"
                  : "\xa0\xa0") +
                "]；"
            )
          )
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v(
            _vm._s(
              "疾病评分： 0分[ " +
                (_vm.jibing === 0 ? "√" : "\xa0\xa0") +
                " ]， 1分[ " +
                (_vm.data[16].value ===
                "髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤"
                  ? "√"
                  : "\xa0\xa0") +
                " ]， 2 分[ " +
                (_vm.data[16].value ===
                "腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤"
                  ? "√"
                  : "\xa0\xa0") +
                " ]， 3 分[ " +
                (_vm.data[16].value ===
                "颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者"
                  ? "√"
                  : "\xa0\xa0") +
                " ]。"
            )
          )
        ]),
        _c("div", { staticClass: "titleS" }, [_vm._v("2. 2 营养状况受损评分")]),
        _c("div", { staticClass: "titleS" }, [_vm._v("2. 2. 1 人体测量")]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("身高(经过校正的标尺，校正至0.1 cm)"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[17].value / 100))
          ]),
          _vm._v("m(免鞋)")
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("体重(经过校正的体重计，校正至0.1 kg)"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[18].value))
          ]),
          _vm._v("kg(空腹、病房衣服、免鞋)；")
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("体质指数(体重指数，BMI)"),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.data[19].value))
          ]),
          _vm._v("kg/m2(若BMI＜18.5且一般状况差，3分，若BMI≥18.5，0分)；")
        ]),
        _c("div", { staticClass: "contentS" }, [
          _vm._v("小计："),
          _c("span", { staticClass: "contentUnderLine" }, [
            _vm._v(_vm._s(_vm.BMI) + " ")
          ]),
          _vm._v(" 分。")
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "pagediv", staticStyle: { "padding-top": "40px" } },
      [
        _c(
          "div",
          { staticClass: "content", staticStyle: { "margin-top": "35px" } },
          [
            _c("div", { staticClass: "titleS" }, [_vm._v("2.2.2 体重状况")]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "近期(1个月~ 3个月)休重是否下降？(是[ " +
                    (_vm.data[20].value === "体重下降" ? "√" : "\xa0\xa0") +
                    " ]，否[ " +
                    (_vm.data[20].value === "体重没下降" ? "√" : "\xa0\xa0") +
                    " ])；若是休重下降 "
                ) + " "
              ),
              _c("span", { staticClass: "contentUnderLine" }, [
                _vm._v(
                  _vm._s(
                    _vm.data[20].value !== "体重下降" ? "" : _vm.data[21].value
                  )
                )
              ]),
              _vm._v(" kg；")
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "体重下降>5% 是在：3个月内(1分) [ " +
                    (_vm.data[20].value === "体重下降" &&
                    _vm.data[22].value === "体重下降＞5%在3个月内"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]， 2个月内(2分) [ " +
                    (_vm.data[20].value === "体重下降" &&
                    _vm.data[22].value === "体重下降＞5%在2个月内"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]，1个月内(3分) [ " +
                    (_vm.data[20].value === "体重下降" &&
                    _vm.data[22].value === "体重下降＞5%在1个月内"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]；"
                )
              )
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v("小计："),
              _c("span", { staticClass: "contentUnderLine" }, [
                _vm._v(_vm._s(_vm.height) + " ")
              ]),
              _vm._v("分")
            ]),
            _c("div", { staticClass: "titleS" }, [_vm._v("2.2.3 进食状况")]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "一周内进食量是否减少？( 是[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 23 : 21]
                      .value !== "没减少"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]， 否[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 23 : 21]
                      .value === "没减少"
                      ? "√"
                      : "\xa0\xa0") +
                    " ])；"
                )
              )
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "如果减少，较从前减少： 25%~ 50%(1分)[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 23 : 21]
                      .value === "较从前减少：25%~ 50%"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]， 51%~ 75%(2分)[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 23 : 21]
                      .value === "较从前减少：51%~ 75%"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]， 76%~100%(3分)[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 23 : 21]
                      .value === "较从前减少：76%~100%"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]；"
                )
              )
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v("小计："),
              _c("span", { staticClass: "contentUnderLine" }, [
                _vm._v(_vm._s(_vm.jinshi) + " ")
              ]),
              _vm._v("分")
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "营养状况受损评分：0分[ " +
                    (_vm.jinshi === 0 ? "√" : "\xa0\xa0") +
                    " ]，1分[ " +
                    (_vm.jinshi === 1 ? "√" : "\xa0\xa0") +
                    " ]，2分[ " +
                    (_vm.jinshi === 2 ? "√" : "\xa0\xa0") +
                    " ]，3分[ " +
                    (_vm.jinshi === 3 ? "√" : "\xa0\xa0") +
                    " ]；"
                )
              )
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v("注：取上述3个小结评分中的最高值。")
            ]),
            _c("div", { staticClass: "titleS" }, [_vm._v("2.2.4 年龄评分")]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v("若年龄≥70岁为1分，否则为0分；")
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                _vm._s(
                  "年龄评分： 0分[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 24 : 22]
                      .value === "年龄＜70岁"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]， 1分[ " +
                    (_vm.data[_vm.data[20].value === "体重下降" ? 24 : 22]
                      .value === "年龄≥70岁"
                      ? "√"
                      : "\xa0\xa0") +
                    " ]。"
                )
              )
            ]),
            _c("div", { staticClass: "titleS" }, [
              _vm._v("2.2.5 营养风险总评分")
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v("临床营养筛查总分＝"),
              _c("span", { staticClass: "contentUnderLine" }, [
                _vm._v(_vm._s(_vm.total) + " ")
              ]),
              _vm._v("分")
            ]),
            _c("div", { staticClass: "contentS" }, [
              _vm._v(
                "注：临床营养筛查总分＝疾病评分 + 营养状况受损评分 + 年龄评分。"
              )
            ]),
            _c("div", { staticClass: "titleS" }, [
              _vm._v("3 调查者及复核者签名")
            ]),
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "titleS" }, [_vm._v("4 筛查日期")]),
            _vm._m(2)
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentS" }, [
      _vm._v("调查者签名："),
      _c("span", {
        staticClass: "contentUnderLine",
        staticStyle: { width: "100px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentS" }, [
      _vm._v("复核者签名："),
      _c("span", {
        staticClass: "contentUnderLine",
        staticStyle: { width: "100px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "contentS",
        staticStyle: { margin: "auto auto 30px 100px" }
      },
      [
        _vm._v(" 筛查日期： "),
        _c("span", {
          staticClass: "contentUnderLine",
          staticStyle: { width: "100px" }
        }),
        _vm._v("年 "),
        _c("span", {
          staticClass: "contentUnderLine",
          staticStyle: { width: "100px" }
        }),
        _vm._v("月 "),
        _c("span", {
          staticClass: "contentUnderLine",
          staticStyle: { width: "100px" }
        }),
        _vm._v("日")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }