var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { staticStyle: { "margin-bottom": "10px" }, attrs: { span: 24 } },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.goToNewExp } },
                [_vm._v(" 新建实验 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("s-table", {
                ref: "table",
                attrs: {
                  size: "default",
                  rowKey: "key",
                  columns: _vm.columns,
                  data: _vm.loadData,
                  showPagination: "auto"
                },
                scopedSlots: _vm._u([
                  {
                    key: "type",
                    fn: function(text) {
                      return [
                        _vm._v(" " + _vm._s(_vm.typeEnum[text + ""]) + " ")
                      ]
                    }
                  },
                  {
                    key: "expName",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.goToDetail(record)
                              }
                            }
                          },
                          [_vm._v(_vm._s(text))]
                        )
                      ]
                    }
                  },
                  {
                    key: "operation",
                    fn: function(text, record) {
                      return [
                        _c("a", [_vm._v("编辑")]),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title:
                                "确认" +
                                (record.control.deleteFlag === 0
                                  ? "停用"
                                  : "启用") +
                                "?"
                            },
                            on: {
                              confirm: function() {
                                return _vm.onDelete(record.key)
                              }
                            }
                          },
                          [
                            _c("a", { attrs: { href: "javascript:;" } }, [
                              _vm._v(
                                _vm._s(
                                  record.control.deleteFlag === 0
                                    ? "停用"
                                    : "启用"
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "deleteFlag",
                    fn: function(text, record) {
                      return _c(
                        "span",
                        {},
                        [
                          [
                            _c("a-badge", {
                              attrs: {
                                status:
                                  record.control.deleteFlag === 0
                                    ? "success"
                                    : "error",
                                text:
                                  record.control.deleteFlag === 0
                                    ? "启用"
                                    : "停用"
                              }
                            })
                          ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }