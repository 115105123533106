var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      _vm._l(_vm.result, function(obj, i) {
        return _c(
          "a-row",
          { key: i, attrs: { gutter: 24 } },
          _vm._l(obj, function(p, j) {
            return _c(
              "a-col",
              {
                key: j,
                attrs: {
                  xs: 24,
                  sm: 24,
                  md: 24,
                  lg: 24,
                  xl: obj.length === 1 ? 24 : 12
                }
              },
              [
                _c("div", [
                  _c("div", { staticClass: "third-module" }, [
                    _c(
                      "p",
                      {
                        ref:
                          "text," +
                          (i === 0 ? (j + 1) * 3 + 1 : (j + 1 + 1) * 3 + 1),
                        refInFor: true
                      },
                      [_vm._v(" " + _vm._s(p.text1) + " ")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "third-module" },
                    _vm._l(p.tables, function(table, index) {
                      return _c("a-table", {
                        key: index,
                        ref:
                          "tables," +
                          (i === 0 ? (j + 1) * 3 + 2 : (j + 1 + 1) * 3 + 2),
                        refInFor: true,
                        attrs: {
                          pagination: false,
                          dataSource: JSON.parse(table.data),
                          columns: table.columns,
                          size: "small"
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "third-module" },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        _vm._l(p.imgs, function(ary, index) {
                          return _c(
                            "a-col",
                            {
                              key: index,
                              attrs: {
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 24,
                                xl: 12,
                                xxl: 8
                              }
                            },
                            [
                              _c("img", {
                                ref:
                                  "imgs," +
                                  (i === 0
                                    ? (j + 1) * 3 + 3
                                    : (j + 1 + 1) * 3 + 3),
                                refInFor: true,
                                attrs: { src: ary.src }
                              })
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          }),
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }