import request from '@/utils/request'
const api = {
  Add: '/VuexData/Add',
  Get: '/VuexData/Get'
}

/**
 * 保存
 */
export function Add(parameter) {
  sessionStorage['shuaxin2'] = '进入ADD' + Date()
  return request({
    url: api.Add,
    method: 'post',
    data: parameter
  })
}
/**
 * 获取
 */
export function Get(parm) {
  sessionStorage['qu1'] = '进入Get' + Date()
  return request({
    url: api.Get,
    method: 'get',
    params: parm
  })
}