<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.normIndex},for:5`"
        :id="`3.${secondIndex}.${thirdIndex.normIndex} 正态性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.normIndex }} 正态性检验
      </div>
      <div v-for="(lei_name, lei_name_index) in lei_name_list" :key="lei_name_index">
        <Norm
          :ref="`content,${thirdIndex.normIndex}`"
          :data="{ data1: data.data1[lei_name], data2: data.data2 }"
          :lei="lei_name"
          :xys="xys"
          :settings="obj_of_settings.norm"
          @isNorm="(val) => isNorm.push(val)"
        >
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.normIndex},1`">
              对不同{{ lei_name }}中的{{ liang }}做正态性检验，<br /><span class="H0H1"
              >H0：不同{{ lei_name }}中{{ liang }}的样本服从正态分布。</span
              ><br /><span class="H0H1">H1：不同{{ lei_name }}中{{ liang }}的样本不服从正态分布。</span
              ><br />检验结果如下表所示：
            </p>
          </template>
          <template #text2="{ text }" class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.normIndex},3`">
                <span v-html="text[0]"></span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.normIndex},4`">{{ liang }}的PP图和QQ图如下图所示：</p>
          </template>
        </Norm>
      </div>
    </div>
    <div v-if="isNorm.length === 2">
      <!-- 如果流程的方法中选择自动，那么必须正态，如果不是自动，那么必须选择了anova才会进行方差分析 -->
      <div
        :ref="`title,${thirdIndex.homogeneousIndex},for:3`"
        :id="`3.${secondIndex}.${thirdIndex.homogeneousIndex} 方差齐性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.homogeneousIndex }} 方差齐性检验
      </div>
      <div v-for="(lei_name, lei_name_index) in lei_name_list" :key="lei_name_index">
        <HomogeneityOfVariances
          :ref="`content,${thirdIndex.homogeneousIndex}`"
          :data="{ data1: data.data1[lei_name], data2: data.data2 }"
          :lei="lei_name"
          :isNorm="isNorm[lei_name_index]"
          :settings="obj_of_settings.homogeneous"
          @isHomogeneous="(val) => isHomogeneous.push(val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.homogeneousIndex},1`" v-html="get_text('方差齐性检验', lei_name_index)"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.homogeneousIndex},3`">
                由上表可得：
                <span
                  v-html="
                    $hypothesis.conclusion(
                      isHomogeneous[lei_name_index],
                      a,
                      `不同${lei_name}中${liang}的样本方差齐`,
                      `不同${lei_name}中${liang}的样本方差不齐`
                    )
                  "
                >
                </span>
              </p>
            </Conclusion>
          </template>
        </HomogeneityOfVariances>
      </div>
    </div>
    <div v-if="isHomogeneous.length === 2">
      <div
        :ref="`title,${thirdIndex.anovaIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.anovaIndex} 方差分析`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.anovaIndex }} 方差分析
      </div>
      <div>
        <DoubleVarianceIndex
          :ref="`content,${thirdIndex.anovaIndex}`"
          :data="data"
          :xys="xys"
          @diff_lei_list="diff_lei_list = $event"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.anovaIndex},1`">
              对{{ lei_name_list[0] }}、{{ lei_name_list[1] }}和{{ liang }}做双因素方差分析，分析结果如下表所示：
            </p>
          </template>
          <template #textBack="{ text_list }" class="text">
            <Conclusion>
              <span :ref="`text,${thirdIndex.anovaIndex},3`"> 由上表可得： </span>
              <p :ref="`text,${thirdIndex.anovaIndex},4`" v-for="(text, text_index) in text_list" :key="text_index">
                <span v-html="text"></span>
              </p>
            </Conclusion>
          </template>
        </DoubleVarianceIndex>
      </div>
    </div>
    <div v-if="diff_lei_list.length > 0">
      <div
        :ref="`title,${thirdIndex.postHocIndex},for:4`"
        :id="`3.${secondIndex}.${thirdIndex.postHocIndex} 事后多重比较`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.postHocIndex }} 事后多重比较
      </div>
      <div v-for="(lei_name, lei_index) in diff_lei_list" :key="lei_index">
        <PostHoc
          :ref="`content,${thirdIndex.postHocIndex}`"
          :data="{ data1: data.data1[lei_name], data3: data.data3 }"
          :lei="lei_name"
          :isLenEqual="is_len_equal_list[lei_index]"
          :leiOptionsCombine="options_combine_list[lei_index]"
          :methodOfAnova="'Anova'"
          :method="'auto'"
          @findLessP="(x) => (findLessP = x)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.postHocIndex},1`" v-html="get_text('事后检验', lei_index)"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <span :ref="`text,${thirdIndex.postHocIndex},3`" v-if="findLessP.length > 0"> 由上表可得： </span>
              <p :ref="`text,${thirdIndex.postHocIndex},4`">
                <span v-if="findLessP.length > 0">
                  {{ liang }}中
                  <span v-for="(groupObj, groupObjIndex) in findLessP[0]" :key="groupObjIndex">
                    {{ groupObj.A }}与{{ groupObj.B }}， </span
                  >这些组合的<i>p</i>值 &lt; {{ a }}，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。
                </span>
                <span v-else>
                  {{ liang }}中所有组合的<i>p</i>值 ≥ {{ a }}，因此不拒绝H0（差别不具有统计学意义）。
                </span>
              </p>
            </Conclusion>
          </template>
        </PostHoc>
      </div>
    </div>
  </div>
</template>
<script>
import Norm from '@/views/report/modules/hypothesis/norm'
import HomogeneityOfVariances, { get_text_homogeneityOfVariances } from '@/views/report/modules/hypothesis/homogeneityOfVariances'
import DoubleVarianceIndex from '@/views/report/modules/hypothesis/doubleVariance.vue'
import PostHoc, { get_text_postHoc } from '@/views/report/modules/hypothesis/postHoc'
export default {
  name: 'DoubleVarianceIndexMain',
  components: { Norm, HomogeneityOfVariances, DoubleVarianceIndex, PostHoc },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  created() {
    this.getIsLenEqual()
    this.obj_of_settings = this.get_settings()
  },
  data() {
    return {
      isNorm: [],
      isHomogeneous: [],
      diff_lei_list: [],
      is_len_equal_list: [],
      options_combine_list: [],
      findLessP: [],
      obj_of_settings: {}// 用来存放各模块配置的obj
    }
  },
  methods: {
    get_text(name_of_module, lei_index) {
      /**
       * @param 要获取文字信息的模块名
       */
      const { secondIndex, thirdIndex, liang, lei_name_list, isNorm, is_len_equal_list, diff_lei_list, obj_of_settings } = this
      // 这个定类的名字是一开始拖进来的两个定类
      const lei_name = lei_name_list[lei_index]
      const name_of_sample = `不同${lei_name}中${liang}的样本`
      // 这个定类的名字是需要进行事后检验的定类的名字
      const lei_name_of_postHoc = diff_lei_list[lei_index]
      const name_of_sample2 = `不同${lei_name_of_postHoc}中${liang}的样本`
      let text
      switch (name_of_module) {
        case '方差齐性检验':
          text = get_text_homogeneityOfVariances(obj_of_settings.homogeneous.args[0].value, name_of_sample, 'auto', isNorm[lei_index][0], secondIndex, thirdIndex)
          break
        // case '非参数检验':
        //   text = get_text_noneParam(name_of_sample, flow_anova_now, false, secondIndex, thirdIndex)
        //   break
        // case '参数检验':
        //   text = get_text_anova(obj_of_settings.anova.args[0].value, name_of_sample, flow_anova_now, isHomogeneous[0], secondIndex, thirdIndex)
        //   break
        case '事后检验':
          text = get_text_postHoc('auto', name_of_sample2, 'Anova', is_len_equal_list[0], secondIndex, thirdIndex)
      }
      return text
    },
    getIsLenEqual() {
      const { diff_lei_list, data, liang_name } = this
      const data1 = data.data1
      const list = []
      diff_lei_list.forEach(lei_name => {
        if (lei_name.indexOf(':') === -1) {
          const value_list = Object.values(data1[lei_name][liang_name])
          const total_len = value_list.reduce((a, b) => a.length + b.length)
          const avg_len = total_len / value_list.length
          const is_len_equal = value_list.every(list => list.length === avg_len)
          list.push([is_len_equal])
        }
      })
      this.is_len_equal_list = list
    },
    get_options_combine_list() {
      // 去xys中找定类的options，然后把各个定类都存放在list数组中
      const { diff_lei_list, xys } = this
      const list = []
      diff_lei_list.forEach(lei_name => {
        if (lei_name.indexOf(':') === -1) {
          const lei_list = xys[0].find(list => list[list.length - 1] === lei_name)
          const options_list = lei_list.slice(0, -1)
          this.$hypothesis.combine.array = options_list
          var list1 = this.$hypothesis.combine.run()
          // list1是全排列组合，但是我们只需要两两组合的排列组合
          const list2 = list1.filter(list => list.length === 2)
          list.push(list2)
        }
      })
      this.options_combine_list = list
    },
    get_settings() {
      const { settings } = this
      const norm = settings.options.methods.find((v) => v.name === '正态检验')
      const homogeneous = settings.options.methods.find((v) => v.name === '方差齐性检验')
      return { norm, homogeneous }
    }
  },
  computed: {
    thirdIndex() {
      const normIndex = 1
      const homogeneousIndex = normIndex + 1
      const anovaIndex = normIndex + 2
      const postHocIndex = anovaIndex + 1
      return { normIndex, anovaIndex, postHocIndex, homogeneousIndex }
    },
    liang_name() {
      return this.xys[1][0]
    },
    lei_name_list() {
      const { xys } = this
      const lei_name_list = xys[0].map(v => v[v.length - 1])
      return lei_name_list
    },
    liang() {
      return this.xys[1][0]
    },
    a() {
      return this.$store.state.report.a
    }
  },
  watch: {
    diff_lei_list(val) {
      if (val.length > 0) {
        this.getIsLenEqual()
        this.get_options_combine_list()
      }
    }
  }
}
</script>