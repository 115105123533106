<template>
  <a-row style=" border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'Kruskal-Wallis多样本秩检验分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'Kruskal-Wallis多样本秩检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['statistic', 'p'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      const item = form.xys[1][0]
      if (typeof (item) === 'object') {
        return makeReq.leiXu()
      } else {
        return makeReq.leiLiang()
      }
    }
  },
  computed: {
    options() { return this.part2.xys[0][0].map(v => `&lt;${v}&gt;`).join(' ') },
    liang() { return this.part2.xys[1][0] },
    H0() { return [`${this.options}${this.part2.xys[0][0].length}组人${this.liang}的中位数相等`] },
    H1() { return [`${this.options}${this.part2.xys[0][0].length}组人${this.liang}的中位数相等不等或不全相等`] },
    result() {
      return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0], 'Kruskal-Wallis')}`]
    }
  }
}
</script>