<template>
  <a-row style=" border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'独立样本t检验分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'独立样本t检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item"></span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../MakeReqClass'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [''],
        H0: [''],
        H1: ['']
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['t', 'p'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      const drop2 = form.xys[1][0]
      if (typeof (drop2) === 'string') return makeReq.pdybtjyLiang()
      return makeReq.pdybtjyLei(1, 0)
    }
  },
  computed: {
     result() {
      return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.part2.H0[0], this.part2.H1[0])}`]
    }
  }
}
</script>