import { onChange, onClose } from '@/views/hypothesisTest/utils/inputItemsClass'
var xysName = 'mixedXys'

export const settings = [
  {
    label: '测量值命名',
    prop: 'liang2',
    parentValue: 1,
    placeholder: '如针对BMI进行了3次重复测量，且拖入了这3次测量结果数据，则可以命名为“BMI”',
    type: 'input',
    rules() { return [{ required: true, message: this.label + '不能为空', trigger: 'blur' }] },
    value: '',
    options: [],
    children: []
  },
  {
    id: 1,
    prop: 'liang1',
    label: '各时间段测量值【定量】',
    parentValue: 1,
    type: 'drop',
    dropType: ['量'],
    min: 2,
    num: 10,
    value: '',
    options: [],
    list: [],
    divHelp: ['请拖入同一观察或记录指标重复进行多次测量的结果：(个数 ≥ 2个)'],
    children: [],
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    id: 0,
    prop: 'lei',
    label: '因素【定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项
    onChange(obj, item, that) {
      onChange.manyLei(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.manyLei(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  }
]