<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 2 && data.xys[1].length > 0">
        <div v-for="(v, index) in fetchCombineList(data.form)" :key="index">
          {{ index + 1 }}.<span v-html="v"></span><span v-if="v.indexOf('><') !== -1">的交互作用</span>对&lt;{{
            data.xys[1][0]
          }}&gt;的影响不显著
        </div>
      </div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 2 && data.xys[1].length > 0">
        <div v-if="data.xys[0].length > 2 && data.xys[1].length > 0">
          <div v-for="(v, index) in fetchCombineList(data.form)" :key="index">
            {{ index + 1 }}.<span v-html="v"></span><span v-if="v.indexOf('><') !== -1">的交互作用</span>对&lt;{{
              data.xys[1][0]
            }}&gt;的影响显著
          </div>
        </div>
      </div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'duoysfcfxXys',
      xys: [[], [], []],
      formAddKeyName: 'duoysfcfxFormAddKey',
      settings,
      // 这里的form是初始值，不会响应改变
      form: {
        control: [],
        step: [],
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$store.state.hypothesis.method) {
            var form = this.$deepClone(that.form)
            form.xys = this.$deepClone(that.computedXys)
            form.a = parseFloat(form.a) / 100
            this.$store.dispatch('saveDybtjy', form)
            this.$router.push('/hypothesistest/result')
          } else {
            this.$message.info('请选择假设检验方法')
            return false
          }
        }
      })
    },
    fetchOptions(list) {
      return list[0]
    },
    fetchCombineList(form) {
      this.$hypothesis.combine.array = this.$deepClone(form.control)
      var list1 = this.$hypothesis.combine.run()
      //   将阶数转换成数字
      var step = this.fetchStep(form.step)
      //   根据阶数筛选出需要的数组
      var list2 = this.selectByStep(this.$deepClone(list1), step, form.leiList)
      var list3 = list2.map(v => v.map(j => `&lt;${j}&gt;`).join(''))
      console.log('aaaa', list3)
      // 把全排列组合list2传到提交到后台的form里去
      form.combine = list2
      return list3
    },
    fetchStep(step) {
      var ary = ['一', '二', '三', '四', '五', '六']
      var stepIntList = []
      step.forEach(element => {
        stepIntList.push(ary.indexOf(element[0]) + 1)
      })
      return stepIntList
    },
    selectByStep(list1, step, leiList) {
      var list2 = []
      list1.forEach(element => {
        if (step.indexOf(element.length) !== -1) list2.push(element)
      })
      // 对数组进行排序，返回负数从大到小排，正数从小到大。
      return list2.concat(leiList.map(v => { return v instanceof Array ? v : [v] })).sort((x, y) => x.length - y.length)
    }
  }
}
</script>