var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    [
      _c("a-layout-header", {
        staticStyle: { background: "#fff", padding: "0" }
      }),
      _c(
        "a-layout",
        { attrs: { id: "components-layout-demo-custom-trigger" } },
        [
          _c(
            "a-layout-sider",
            {
              staticStyle: { width: "80px" },
              attrs: { trigger: null },
              model: {
                value: _vm.collapsed,
                callback: function($$v) {
                  _vm.collapsed = $$v
                },
                expression: "collapsed"
              }
            },
            [
              _c(
                "a-menu",
                { attrs: { theme: "dark" } },
                [
                  _c(
                    "a-menu-item",
                    { key: "1", staticStyle: { height: "90px" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("br"),
                      _c("span", [_vm._v("nav 1")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "2" },
                    [
                      _c("a-icon", { attrs: { type: "video-camera" } }),
                      _c("br"),
                      _c("span", [_vm._v("nav 2")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "3" },
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _c("br"),
                      _c("span", [_vm._v("nav 3")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-layout",
            [
              _c(
                "a-layout-content",
                {
                  style: {
                    margin: "24px 16px",
                    padding: "24px",
                    background: "#fff",
                    minHeight: "280px"
                  }
                },
                [_vm._v("Content")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }