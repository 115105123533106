var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "innerWrapper", staticStyle: { height: "100%" } },
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "帮助",
            placement: "right",
            closable: false,
            visible: _vm.visible,
            width: 500
          },
          on: { close: _vm.onClose }
        },
        [_c("help")],
        1
      ),
      _c(
        "a-row",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "a-col",
            {
              staticStyle: {
                "min-height": "750px",
                padding: "15px",
                "border-right": "1px solid rgb(232, 232, 232)"
              },
              attrs: { id: "test", span: 24 }
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    labelAlign: "left",
                    labelCol: { md: { span: 6 }, xxl: { span: 4 } },
                    wrapperCol: { md: { span: 18 }, xxl: { span: 20 } }
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            type: "primary",
                            disabled: this.load.anysisLoading
                          },
                          on: { click: _vm.onClick }
                        },
                        [_vm._v("开始分析")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { disabled: this.load.anysisLoading },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v("重置")]
                      ),
                      this.load.anysisLoading
                        ? _c("a-spin", {
                            staticStyle: { "margin-left": "10px" }
                          })
                        : _vm._e(),
                      _c("a-icon", {
                        staticStyle: { float: "right", "font-size": "16px" },
                        attrs: { type: "question-circle", theme: "twoTone" },
                        on: { click: _vm.showDrawer }
                      })
                    ],
                    1
                  ),
                  _c("drop-items2", {
                    attrs: {
                      settings: _vm.settings,
                      indexSettings: _vm.indexSettings,
                      parentValue: 1,
                      formAddKeyName: _vm.formAddKeyName,
                      xys: _vm.computedXys,
                      refs: _vm.$refs,
                      load: _vm.load
                    },
                    on: {
                      updateForm: _vm.updateForm,
                      initialRules: function($event) {
                        return _vm.initialRules(_vm.settings)
                      }
                    }
                  }),
                  _vm._t("extraInput", null, { xys: _vm.computedXys })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }