var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "ruleForm",
      attrs: {
        model: _vm.form,
        labelAlign: "left",
        rules: _vm.rules,
        labelCol: { span: 7 },
        wrapperCol: { span: 10 }
      }
    },
    [
      _c(
        "div",
        { staticClass: "documentItemWrapper" },
        [
          _c("h1", [_vm._v("基本信息")]),
          _c(
            "a-row",
            { attrs: { gutter: 50 } },
            [
              _c(
                "a-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "id" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "id", $$v)
                          },
                          expression: "form.id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "编号", prop: "serialNo" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入编号" },
                        model: {
                          value: _vm.form.serialNo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "serialNo", $$v)
                          },
                          expression: "form.serialNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "姓名" } },
                    [
                      !_vm.hideInput.name
                        ? _c(
                            "div",
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("hideName")(_vm.form.name))
                              ),
                              _c("a-icon", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "edit", theme: "twoTone" },
                                on: {
                                  click: function($event) {
                                    _vm.hideInput.name = true
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hideInput.name
                        ? _c(
                            "a-input",
                            {
                              staticStyle: { width: "220px" },
                              attrs: { placeholder: "请输入姓名" },
                              model: {
                                value: _vm.form.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name"
                              }
                            },
                            [
                              _c("a-icon", {
                                attrs: {
                                  slot: "addonAfter",
                                  type: "save",
                                  theme: "twoTone"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.clickIcon("name")
                                  }
                                },
                                slot: "addonAfter"
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "性别" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("男")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [_vm._v("女")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "出生日期" } },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请选择出生日期" },
                        on: { change: _vm.onBirthdayChange },
                        model: {
                          value: _vm.form.birthDay,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "birthDay", $$v)
                          },
                          expression: "form.birthDay"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      class: typeof _vm.age === "string" ? "ageHelp" : "",
                      attrs: { label: "年龄" }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.age))]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: typeof _vm.age != "string",
                              expression: "typeof age != 'string'"
                            }
                          ],
                          staticStyle: { "margin-left": "5px" }
                        },
                        [_vm._v("岁")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "手机号", prop: "mobile" } },
                    [
                      !_vm.hideInput.mobile
                        ? _c(
                            "div",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("hideMobilePhone")(_vm.form.mobile)
                                  )
                              ),
                              _c("a-icon", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "edit", theme: "twoTone" },
                                on: {
                                  click: function($event) {
                                    _vm.hideInput.mobile = true
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hideInput.mobile
                        ? _c(
                            "a-input",
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                placeholder: "请输入手机号",
                                maxLength: 11
                              },
                              model: {
                                value: _vm.form.mobile,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "mobile", $$v)
                                },
                                expression: "form.mobile"
                              }
                            },
                            [
                              _c("a-icon", {
                                attrs: {
                                  slot: "addonAfter",
                                  type: "save",
                                  theme: "twoTone"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.clickIcon("mobile")
                                  }
                                },
                                slot: "addonAfter"
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "籍贯", prop: "hometown" } },
                    [
                      _c("a-cascader", {
                        attrs: {
                          options: _vm.options,
                          "field-names": {
                            label: "name",
                            value: "code",
                            children: "children"
                          },
                          "expand-trigger": "hover",
                          placeholder: "请选择"
                        },
                        model: {
                          value: _vm.form.hometown,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "hometown", $$v)
                          },
                          expression: "form.hometown"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "文化程度" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择文化程度",
                            dropdownMatchSelectWidth: false
                          },
                          model: {
                            value: _vm.form.education,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "education", $$v)
                            },
                            expression: "form.education"
                          }
                        },
                        _vm._l(_vm.educations, function(education) {
                          return _c(
                            "a-select-option",
                            {
                              key: education.key,
                              attrs: { value: education.key }
                            },
                            [_vm._v(_vm._s(education.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "民族" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择民族"
                          },
                          model: {
                            value: _vm.form.minority,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "minority", $$v)
                            },
                            expression: "form.minority"
                          }
                        },
                        _vm._l(_vm.minorities, function(minority) {
                          return _c(
                            "a-select-option",
                            { key: minority.id, attrs: { value: minority.id } },
                            [_vm._v(_vm._s(minority.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "职业" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择职业",
                            dropdownMatchSelectWidth: false
                          },
                          model: {
                            value: _vm.form.occupation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "occupation", $$v)
                            },
                            expression: "form.occupation"
                          }
                        },
                        _vm._l(_vm.ocupations, function(ocupation) {
                          return _c(
                            "a-select-option",
                            {
                              key: ocupation.key,
                              attrs: { value: ocupation.key }
                            },
                            [_c("span", [_vm._v(_vm._s(ocupation.value))])]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "居住情况" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择居住情况"
                          },
                          model: {
                            value: _vm.form.stayWith,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "stayWith", $$v)
                            },
                            expression: "form.stayWith"
                          }
                        },
                        _vm._l(_vm.stayWiths, function(stayWith) {
                          return _c(
                            "a-select-option",
                            {
                              key: stayWith.key,
                              attrs: { value: stayWith.key }
                            },
                            [_vm._v(_vm._s(stayWith.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "生活满意度" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择生活满意度"
                          },
                          model: {
                            value: _vm.form.lifeSatisfaction,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "lifeSatisfaction", $$v)
                            },
                            expression: "form.lifeSatisfaction"
                          }
                        },
                        _vm._l(_vm.satisfactions, function(satisfaction) {
                          return _c(
                            "a-select-option",
                            {
                              key: satisfaction.key,
                              attrs: { value: satisfaction.key }
                            },
                            [_vm._v(_vm._s(satisfaction.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "婚姻情况" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择婚姻情况"
                          },
                          model: {
                            value: _vm.form.marry,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "marry", $$v)
                            },
                            expression: "form.marry"
                          }
                        },
                        _vm._l(_vm.marries, function(marry) {
                          return _c(
                            "a-select-option",
                            { key: marry.key, attrs: { value: marry.key } },
                            [_vm._v(_vm._s(marry.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "现居住地" } },
                    [
                      _c("a-cascader", {
                        attrs: {
                          options: _vm.options,
                          "field-names": {
                            label: "name",
                            value: "code",
                            children: "children"
                          },
                          "expand-trigger": "hover",
                          placeholder: "请选择"
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "详细地址" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入详细的居住地址" },
                        model: {
                          value: _vm.form.detailAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detailAddress", $$v)
                          },
                          expression: "form.detailAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "documentItemWrapper", attrs: { id: "secondWrapper" } },
        [
          _c("h1", [_vm._v("生活方式及社会因素")]),
          _c(
            "a-row",
            _vm._l(_vm.lifeStyles, function(lifeStyle) {
              return _c("a-col", { key: lifeStyle.key, attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "lifeStyleItem" },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: lifeStyle.keyName,
                          labelCol: _vm.handleCol(lifeStyle.keyName, "label"),
                          wrapperCol: _vm.handleCol(
                            lifeStyle.keyName,
                            "wrapper"
                          )
                        }
                      },
                      [
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.form[lifeStyle.key],
                              callback: function($$v) {
                                _vm.$set(_vm.form, lifeStyle.key, $$v)
                              },
                              expression: "form[lifeStyle.key]"
                            }
                          },
                          _vm._l(lifeStyle.valueName, function(selectItem) {
                            return _c(
                              "a-radio",
                              {
                                key: selectItem.id,
                                attrs: { value: selectItem.id }
                              },
                              [_vm._v(_vm._s(selectItem.name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }