import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import {
  VueAxios
} from './axios'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  withCredentials: true,
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 600000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: '登录信息失效',
        description: '登录信息失效，请重新登录'
      })
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          if (window.location.pathname.indexOf('/hypothesistest/result') > -1) {
            window.location.href = './'
          } else {
            window.location.reload()
          }
        }, 1500)
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor  request拦截器
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = config.url.indexOf('python') !== -1 ? token : 'bearer ' + token
  }
  if (config.url.includes(`python`)) {
    config.data.style = {
      language: store.state.user.info.setting.language,
      colors: store.state.user.info.setting.colors,
      picformat: store.state.user.info.setting.picformat
    }
  }

  config.headers['Access-Control-Allow-Credentials'] = true
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}