var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-container" }, [
    _c(
      "div",
      { staticClass: "imgAndTable" },
      [
        _c("h1", [_vm._v("相关性检验结果")]),
        _vm.table2.data.length
          ? _c(
              "a-row",
              { attrs: { type: "flex", justify: "center" } },
              [
                _c(
                  "a-col",
                  [
                    _c("a-table", {
                      attrs: {
                        columns: _vm.table2.columns,
                        dataSource: _vm.table2.data,
                        pagination: false,
                        bordered: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cxz",
                            fn: function(text) {
                              return _c(
                                "div",
                                {},
                                _vm._l(text, function(item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(_vm._s(item))
                                  ])
                                }),
                                0
                              )
                            }
                          },
                          {
                            key: "cgd",
                            fn: function(text) {
                              return _c(
                                "div",
                                {},
                                _vm._l(text, function(item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(_vm._s(item))
                                  ])
                                }),
                                0
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        3690710824
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "a-row",
          { attrs: { type: "flex", justify: "center" } },
          [
            _c(
              "a-col",
              [
                _c("MyImg", {
                  attrs: { imgBytes: _vm.img, fileName: "Spearman相关系数" }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { type: "flex" } },
          [
            _c(
              "a-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "a-table",
                  {
                    attrs: {
                      columns: _vm.table.columns,
                      dataSource: _vm.table.data,
                      pagination: false,
                      bordered: ""
                    }
                  },
                  [
                    _c("div", {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "title" },
                      domProps: {
                        innerHTML: _vm._s(_vm.translate("相关性<i>p</i>值"))
                      },
                      slot: "title"
                    })
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "conclusion" },
      [
        _c("box-shadow-card", { attrs: { title: "相关性检验智能分析结果" } }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.conclusionStr) } })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }