<template>
  <a-row type="flex" justify="center">
    <a-col>
      <img :width="width" :src="src" />
    </a-col>
  </a-row>
</template>
<script>
export default {
  name: 'ArtImg',
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    }
  }
}
</script>