import { render, staticRenderFns } from "./textarea.vue?vue&type=template&id=800e7d28&scoped=true&"
import script from "./textarea.vue?vue&type=script&lang=js&"
export * from "./textarea.vue?vue&type=script&lang=js&"
import style0 from "./textarea.vue?vue&type=style&index=0&id=800e7d28&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "800e7d28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('800e7d28')) {
      api.createRecord('800e7d28', component.options)
    } else {
      api.reload('800e7d28', component.options)
    }
    module.hot.accept("./textarea.vue?vue&type=template&id=800e7d28&scoped=true&", function () {
      api.rerender('800e7d28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hypothesisTest/components/textarea.vue"
export default component.exports