var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.array.length !== 1
      ? _c(
          "span",
          {
            staticStyle: { "margin-left": "10px", cursor: "pointer" },
            on: {
              click: function($event) {
                return _vm.deleteGroup(_vm.index)
              }
            }
          },
          [
            _c("a-icon", {
              attrs: {
                type: "minus-circle",
                theme: "twoTone",
                twoToneColor: "#1890FF"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.index === _vm.array.length - 1
      ? _c(
          "span",
          {
            staticStyle: { "margin-left": "10px", cursor: "pointer" },
            on: { click: _vm.addGroup }
          },
          [
            _c("a-icon", {
              attrs: {
                type: "plus-circle",
                theme: "twoTone",
                twoToneColor: "#1890FF"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }