var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "head-bar" } },
        [
          _c(
            "a-row",
            {
              attrs: {
                id: "main",
                type: "flex",
                justify: "center",
                align: "middle"
              }
            },
            [
              _c("a-col", [
                _c("a", { attrs: { href: "https://www.medspss.com/" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/logo.png"), width: "50px" }
                  }),
                  _c("span", { attrs: { id: "plat-title" } }, [
                    _vm._v("MedSPSS")
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "margin-top": "25px", padding: "0 20px" },
          attrs: { type: "flex", justify: "center" }
        },
        [
          _vm.form.date
            ? _c(
                "a-col",
                { attrs: { id: "left-main", span: 24 } },
                [
                  _c("div", { attrs: { id: "article-title" } }, [
                    _vm._v(_vm._s(_vm.form.title))
                  ]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", align: "middle", id: "meta" } },
                    [
                      _c("a-col", [
                        _c("span", { attrs: { id: "tag" } }, [_vm._v("原创")]),
                        _c("span", { attrs: { id: "time" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.form.date)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "article-content" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.form.details.detail) }
                    })
                  ])
                ],
                1
              )
            : _c(
                "a-col",
                [_c("span", [_vm._v(" 数据加载中 ")]), _c("a-spin")],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }