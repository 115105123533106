import request from '@/utils/request'

export function setSession(parameter) {
  return request({
    url: `/python/setSession`,
    method: 'post',
    data: parameter
  })
}

export function clearSession(parameter) {
  return request({
    url: `/python/clearSession`,
    method: 'post',
    data: parameter
  })
}