var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", { staticClass: "hCommon" }, [_vm._v("相关性")]),
      _c(
        "div",
        { staticClass: "itemWrapperCommon itemWrapperCommonPadding40" },
        [
          _c("div", {
            staticStyle: {
              width: "800px",
              height: "650px",
              margin: "0px auto"
            },
            attrs: { id: "chartpearson" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }