var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    { attrs: { breadcrumb: null } },
    [
      _c("a-card", { attrs: { bordered: false, id: "missvalue" } }, [
        _c(
          "div",
          [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.onBack } },
              [_vm._v("<< 返 回")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px", width: "810px" } },
          [
            _c("a-table", {
              attrs: {
                bordered: "",
                size: "small",
                pagination: false,
                dataSource: _vm.data,
                columns: _vm.columns,
                loading: _vm.loading
              },
              scopedSlots: _vm._u([
                {
                  key: "null",
                  fn: function(text) {
                    return _c("span", {}, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            text !== "" && text !== null && text !== "NaN"
                              ? text
                              : "-"
                          )
                        )
                      ])
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }