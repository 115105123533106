<template>
  <div id="personHome">
    <a-row id="top" type="flex" justify="center" align="middle">
      <a-col>
        <a-row type="flex" justify="center">
          <a-col style="color: white; font-size: 30px;margin-bottom:10px">数据分析 如此简单</a-col>
        </a-row>
        <a-row>
          <a-col style="color: white"
          >UUBigData采用标准化的数据分析算法，拖拽式操作，一键生成规范化的分析结果，帮助用户节省时间，提高分析效率和准确度。</a-col
          >
        </a-row>
      </a-col>
    </a-row>
    <a-row id="content" type="flex" justify="center">
      <a-col :span="15">
        <a-row style="margin: 15px; font-size: 25px" type="flex" justify="center">
          <a-col> 会员权益对比 </a-col>
        </a-row>
        <table id="table" border="1" cellpadding="10" width="100%">
          <tr>
            <th class="common-th">权益</th>
            <th class="common-th">普通会员</th>
            <th class="vip-th">VIP会员</th>
          </tr>
          <tr>
            <td>上传数据</td>
            <td>可用（限20条）</td>
            <td>可用（不限制数量）</td>
          </tr>
          <tr>
            <td>创建量表（问卷）</td>
            <td><a-icon type="close-circle" theme="filled" class="icon close" /></td>
            <td><a-icon type="check-circle" theme="filled" class="icon check" /></td>
          </tr>
          <tr>
            <td>假设检验</td>
            <td><a-icon type="close-circle" theme="filled" class="icon close" /></td>
            <td><a-icon type="check-circle" theme="filled" class="icon check" /></td>
          </tr>
          <tr>
            <td>回归分析</td>
            <td><a-icon type="close-circle" theme="filled" class="icon close" /></td>
            <td><a-icon type="check-circle" theme="filled" class="icon check" /></td>
          </tr>
          <tr>
            <td>智能图表</td>
            <td><a-icon type="close-circle" theme="filled" class="icon close" /></td>
            <td><a-icon type="check-circle" theme="filled" class="icon check" /></td>
          </tr>
          <tr>
            <td>分析数据</td>
            <td>限50条</td>
            <td>所有数据</td>
          </tr>
          <tr>
            <td>图表下载</td>
            <td>可下载，但不可自定义图表信息</td>
            <td>下载高清原图，并可自定义图表信息</td>
          </tr>
          <tr>
            <td>存储空间</td>
            <td>200M</td>
            <td>1G</td>
          </tr>
          <tr>
            <td>7*24客服</td>
            <td><a-icon type="close-circle" theme="filled" class="icon close" /></td>
            <td><a-icon type="check-circle" theme="filled" class="icon check" /></td>
          </tr>
        </table>
        <a-row style="margin: 15px; font-size: 25px" type="flex" justify="center">
          <a-col> VIP会员限时特价 </a-col>
        </a-row>
        <a-row :gutter="80" type="flex" justify="space-between">
          <a-col :flex="1"><PriceCard :title="`月会员`" :price="238" :unit="`月`"></PriceCard></a-col>
          <a-col :flex="1"><PriceCard :title="`季会员`" :price="678" :unit="`季`"></PriceCard></a-col>
          <a-col :flex="1"><PriceCard :title="`年会员`" :price="2428" :unit="`年`"></PriceCard></a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { AutoHeight } from '@/utils/util'
import PriceCard from '@/components/myComponents/PriceCard'
export default {
  components: { PriceCard },
  created() {
    this.autoHeightChange()
  },
  methods: {
    autoHeightChange() {
      // 高度自定义使用
      // 高度自定义使用
      var min = ['divContent', 'hypContent', 'field', 'personHome']
      var max = ['method', 'field', 'hypContent', 'childContent']
      AutoHeight(this, min, max, 176)
    }
  }
}
</script>
<style lang="less" scoped>
#top {
  background: url(~@/assets/gr_indexbj.png) no-repeat;
  background-size: 100%;
  height: 200px;
}
th:nth-child(1) {
  width: 20%;
}
th:nth-child(2) {
  width: 40%;
}
th:nth-child(3) {
  width: 40%;
}
#table {
  text-align: center;
  background-color: white;
  border-color: #dfdfdf;
  .icon {
    font-size: 20px;
  }
  .close {
    color: #999999;
  }
  .check {
    color: #dbb427;
  }
  .common-th {
    background-color: #595959;
    color: white;
  }
  .vip-th {
    background-color: #e2cd7e;
  }
}
</style>