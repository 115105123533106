<template>
  <div>
    <a-form :form="form" labelAlign="left">
      <div class="medicineWrapper">
        <div v-for="medicine in medicines" :key="medicine.id">
          <a-form-item :label="medicine.name" :labelCol="{span:8}" :wrapperCol="{span:8}">
            <a-input suffix="g" v-decorator="[medicine.name,{initialValue:medicine.amount}]"></a-input>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            medicines: [
                {
                  id: 1,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 2,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 3,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 4,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 5,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 6,
                  name: '法半夏',
                  amount: '30'
                 },
                 {
                  id: 7,
                  name: '法半夏',
                  amount: '30'
                 }
            ]
        }
    }
}
</script>
<style lang="less">
.medicineWrapper {
  display: flex;
  flex-wrap: wrap;
}
.medicineWrapper > div {
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 5px 10px;
  width: 20% !important;
  border: 1px solid rgb(232, 232, 232);
  .ant-form-item {
    margin: 0;
  }
}
</style>
