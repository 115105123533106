var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("a-spin", {
        staticStyle: {
          margin: "300px auto",
          display: "block",
          postion: "relative"
        },
        attrs: { tip: "生成中", size: "large" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }