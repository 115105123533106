<template>
  <div>
    <a-row v-if="title">
      <a-col :span="24">
        <h1>{{ title }}</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-table
          :showHeader="showHeader"
          :columns="columns"
          :data-source="data"
          bordered
          :pagination="false"
          :size="size"
          :scroll="{ x: true }"
        >
          <span slot="p" slot-scope="text">
            <i>{{ text }}</i>
          </span>
          <span slot="italic" slot-scope="text">
            <i v-if="/^[a-zA-Z]+$/.test(text)"
            ><b>
              {{ text }}
            </b></i
            >
            <span v-else>{{ text }}</span>
          </span>
          <span slot="bold" slot-scope="text,record">
            <b v-if="record.col2 === translate('系数')">
              {{ text }}
            </b>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="cof_int" slot-scope="text, record">
            <div v-if="record.col2 === translate('系数')">
              <b>{{ translate('置信区间') }}</b>
              <div style="font-size: 8px; color: grey">（{{ translate('置信度') }}：95%）</div>
            </div>
            <span v-else>{{ text }}</span>
          </span>
          <div v-for="item in columns" :key="item.key" :slot="item.slotKey === 'anova' ? item.slotValue : ''">
            <div v-html="item.slotValue"></div>
          </div>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { translate } from '@/utils/language'
export default {
  props: ['data', 'title', 'columns', 'scroll', 'showHeader', 'size'],
  data() {
    return {
    }
  },
  methods: {
    translate(text) {
      return translate(text)
    }
  }
}
</script>
<style lang="less">
th {
  // background-color:black !important;
}
// .colTitle
</style>