<template>
  <div >
    <div class="third-module">
      <slot name="text1" ></slot>
    </div>
    <div class="third-module">
      <a-table v-for="(table, index) in tables"
               :key="index"
               :ref="`tables,${8*indexT+3}`"
               :pagination="false"
               :dataSource="table.data"
               :columns="table.columns"
               size="small"
      >
      </a-table>
    </div>

    <div class="third-module">
      <slot name="text2" ></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <a-row >
        <a-col v-for="(ary, index) in imgs" :key="index" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <img :ref="`imgs,${8*indexT+6}`" :src="ary.src" />
        </a-col>
      </a-row>
    </div>
    <div class="third-module">
      <slot name="text4"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['tables', 'imgs', 'indexT'],
  created() {
    console.log('table', this.tables)
  }
}
</script>
<style lang="less">
</style>