import request from '@/utils/request'

const api = {
    examFields: '/ExamField',
    docExamField: '/DocExamField'
}

export function getExamFields(parameter) {
    return request({
        url: api.examFields,
        method: 'get',
        params: parameter
    })
}

export function getExamField(parameter) {
    return request({
        url: api.docExamField + '/' + parameter,
        method: 'get'
    })
}

export function addExamFields(parameter) {
    return request({
        url: api.docExamField,
        method: 'post',
        data: parameter
    })
}

export function updateExamFields(parameter) {
    return request({
        url: api.docExamField,
        method: 'put',
        data: parameter
    })
}
export function deleteExamFields(parameter) {
    return request({
        url: api.docExamField,
        method: 'delete',
        data: parameter
    })
}
