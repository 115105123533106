<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >template
    <template #H0="{ xys, form }">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span v-for="(item, index) in H0(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
      </div>
    </template>
    <template #H1="{ xys, form }">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span v-for="(item, index) in H1(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'dybzjyXys',
      xys: [[], []],
      formAddKeyName: 'dybzjyFormAddKey',
      settings,
      form: {
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        form.reducedValue = parseFloat(that.form.reducedValue)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }

  },
  computed: {
    options() {
      return xys => xys[0][0].slice(0, -1).map(v => `&lt;${v}&gt;`).join(' ')
    },
    H0() {
      return (xys, form) => {
        if (typeof (xys[1][0]) === 'string') {
          form.H0 = [`${this.options(xys)}${xys[0][0].length - 1}组人&lt;${xys[1][0]}&gt;的中位数相等`]
        } else {
          form.H0 = [`${this.options(xys)}${xys[0][0].length - 1}组人&lt;${xys[1][0].slice(-1)[0]}&gt;的中位数相等`]
        }
        return form.H0
      }
    },
    H1() {
      return (xys, form) => {
        if (typeof (xys[1][0]) === 'string') {
          form.H1 = [`${this.options(xys)}${xys[0][0].length - 1}组人&lt;${xys[1][0]}&gt;的中位数不等或不全相等`]
        } else {
          form.H1 = [`${this.options(xys)}${xys[0][0].length - 1}组人&lt;${xys[1][0].slice(-1)[0]}&gt;的中位数不等或不全相等`]
        }
        return form.H1
      }
    }
  }
}
</script>