<template>
  <div>
    <a-form-model-item
      :label="formItem.name"
      :labelCol="handleCol(formItem.name, 'label')"
      :wrapperCol="handleCol(formItem.name, 'wrapper')"
    >
      <a-input
        v-if="formItem.type === 0"
        :suffix="formItem.unit | fetchUnit"
        placeholder="请填写"
        style="width: 220px"
        @change="(e) => isHweight(e, formItem.name, formItem)"
        v-model="formItem.value"
      />
      <a-select v-else-if="formItem.type === 1" placeholder="请选择" v-model="formItem.value">
        <a-select-option v-for="item in formItem.option" :key="item" :value="item">{{ item }}</a-select-option>
      </a-select>
      <a-radio-group v-else-if="formItem.type === 2" v-model="formItem.value">
        <a-radio v-for="item in formItem.option" :key="item" :value="item">{{ item }}</a-radio>
      </a-radio-group>
      <a-date-picker
        v-else
        style="width: 220px"
        placeholder="请选择日期"
        :value="formItem.value ? $moment(formItem.value) : null"
        @change="(date, dateString) => change(date, dateString, formItem)"
      />
    </a-form-model-item>
    <a-row v-if="formItem.value == '有' && formItem.children">
      <a-col v-for="item in formItem.children" :key="item.id" :span="24">
        <form-items :formItem="item" :controller="controller"></form-items>
      </a-col>
    </a-row>
  </div>
</template>

<script>

export default {
  props: ['formItem', 'controller', 'examList', 'tabContent'],
  data() {
    return {
    }
  },
  methods: {
    change(date, dateString, formItem) {
      formItem.value = dateString
      console.log(formItem)
    },
    // 每个input绑定的事件，如果是身高或者体重，那么要去计算BMI
    isHweight(e, name, formItem) {
      formItem.value = e.target.value
      console.log(name)
      var height = 0
      var weight = 0
      const value = e.target.value

      var list = this.controller === 'modal' ? this.examList : this.$store.state.document.examIds
      if (name !== '身高' && name !== '体重') {
        console.log(name)
        return null
      } else if (name === '身高') {
        height = value
        weight = this.findChildren(list[1], this.tabContent).value
      } else {
        height = this.findChildren(list[0], this.tabContent).value
        weight = value
      }
      var BMIId = list[2]
      this.getBMI(parseInt(height), parseInt(weight), BMIId)
    },
    getBMI(height, weight, BMIId) {
      const BMI = weight / ((height / 100) * (height / 100))
      this.findChildren(BMIId, this.tabContent).value = isNaN(BMI.toFixed(2)) ? '' : BMI.toFixed(2)
    },
    findChildren(id, list) {
      for (var i in list) {
        var item = list[i]
        if (item.id === id) {
          return item
        } else if (item.children.length > 0) {
          return this.findChildren(id, item.children) || null
        }
      }
    },
    // 如果input为空，那么重置为undefined，便于页面显示请选择
    initialInput(value) {
      if (!value) return undefined
      return value
    },
    handleCol(name, labelOrWrapper) {
      if (this.controller === 'modal') {
        // 6 < name <= 8
        if (name.length > 6 && name.length <= 8) {
          return labelOrWrapper === 'label' ? { span: 7 } : { span: 17 }
          // 8 < name
        } else if (name.length > 8) {
          return labelOrWrapper === 'label' ? { span: 10 } : { span: 14 }
          // name <= 6
        } else if (name.length <= 6) {
          return labelOrWrapper === 'label' ? { span: 6 } : { span: 18 }
        }
      } else {
        if (name.length > 8) {
          return labelOrWrapper === 'label' ? { md: { span: 8 }, xxl: { span: 5 } } : { md: { span: 16 }, xxl: { span: 19 } }
        } else if (name.length >= 5 && name.length <= 8) {
          return labelOrWrapper === 'label' ? { md: { span: 6 }, xxl: { span: 5 } } : { md: { span: 18 }, xxl: { span: 19 } }
        } else {
          return labelOrWrapper === 'label' ? { md: { span: 4 }, xxl: { span: 4 } } : { md: { span: 20 }, xxl: { span: 20 } }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 20px;
}
</style>
