<!--现在使用的答卷-->
<template >
  <div>
    <div class="example" v-if="loading">
      <a-spin tip="加载中..." size="large"> </a-spin>
    </div>
    <div style="background-color: #fbfaf7">
      <a-form-model
        ref="ruleForm"
        :model="form"
        class="divStyle"
        :bordered="false"
        style="background-color: #fefefe"
        v-if="loading === false"
      >
        <a-row type="flex" justify="center">
          <a-col :span="20" class="title marginTop">{{ data.modelName }}</a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :span="20" style="color: #ff7200; margin-top: 20px"
          >提交问卷前请勿离开此页面，否则您将丢失输入的数据</a-col
          >
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :span="20">
            <div style="white-space: pre-wrap">{{ data.modelDescription }}</div></a-col
          >
        </a-row>
        <subject-comp-new
          v-if="screenWidth > 768"
          :data="data.questions"
          :childrenRequire="true"
          :isAnswer="true"
          :formaaa="form"
          @doclink="documentLink(arguments)"
          :linkQuestionIDs="data.linkQuestionIDs"
          @questionLink="questionLink(arguments)"
        ></subject-comp-new>
        <subject-comp-mobile-new
          v-if="screenWidth <= 768"
          :data="data.questions"
          :childrenRequire="true"
          :isAnswer="true"
          :formaaa="form"
          :linkQuestionIDs="data.linkQuestionIDs"
          @doclink="documentLink(arguments)"
        ></subject-comp-mobile-new>
        <a-row type="flex" justify="center">
          <a-col :span="20" style="color: #ff7200; margin-top: 20px"
          >提交问卷前请勿离开此页面，否则您将丢失输入的数据</a-col
          >
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :span="20" class="title" style="margin: 20px auto 20px auto">
            <a-button type="primary" style="width: 100%; height: 50px" @click="handelSubmit" :disabled="submitEnable">{{
              submitStr
            }}</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import { Preview, getdocinfo, AddAnswer, ExistSerial } from '@/api/questionnaire'
import SubjectCompMobileNew from './components/SubjectCompMobileNew'
import pick from 'lodash.pick'
import SubjectCompNew from './components/SubjectCompNew'
import { Base64 } from 'js-base64'
import { message, Modal } from 'ant-design-vue'
import { BMI, height, jinshi, age, jibing } from './jisuan'

export default {
  components: {
    SubjectCompNew,
    SubjectCompMobileNew
  },
  data() {
    return {
      form: {},
      data: {},
      isScore: false,
      screenWidth: document.body.clientWidth,
      loading: false, // 用来查看题目是否加载完毕，避免提交按钮先跑出来
      docKeys: [],
      serialKey: '',
      serialValue: '',
      submitStr: '提交',
      submitEnable: false,
      serialErro: false,
      scoreShow: false
    }
  },
  mounted() {
    // 关闭录入页面
    window.onunload = e => {
      // 设置为2
      localStorage.setItem(`watchInput${this.$route.params.id}${this.$route.params.index}`, 2)
      localStorage.setItem(`watchCurrentIndex${this.$route.params.id}`, this.$route.params.index)
    }
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }

    window.addEventListener('beforeunload', e => {
      this.updateHandler(e)
    })
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => {
      this.updateHandler(e)
    })
  },
  created() {
    this.loading = true

    Preview({ id: Number(Base64.decode(this.$route.params.id)), isAnswer: true }).then(res => {
      if (res.isOverdue) {
        switch (res.isOverdue) {
          case 1:
            this.$router.push({
              name: 'warnning',
              params: { id: 1, index: this.$route.params.index, qid: this.$route.params.id }
            })
            break
          case 2:
            this.$router.push({
              name: 'warnning',
              params: { id: 2, index: this.$route.params.index, qid: this.$route.params.id }
            })
            break
          case 3:
            this.$router.push({
              name: 'warnning',
              params: { id: 3, index: this.$route.params.index, qid: this.$route.params.id }
            })
            break
        }
      } else {
        this.form = res.questionKeys
        this.data = res

        console.log('data', res)
        this.isScore = res.isScore
        this.scoreShow = res.scoreShow
      }
      this.loading = false
    })
  },
  methods: {
    updateHandler(e) {
      e = e || window.event
      if (e && window.location.pathname.indexOf('result') === -1) {
        e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存'
      }
      return '您是否确认离开此页面-您输入的数据可能不会被保存'
    },
    handelSubmit() {
      this.$refs.ruleForm.validate((valid, obj) => {
        var values = this.form
        var ss = true
        if (valid && !this.serialErro && ss) {
          console.log('values', values)
          // api 新增的入参
          const parm = {}
          // 题目答案列表
          const model = []
          let score = 0
          parm['Options'] = model
          parm['QuestionnaireID'] = Number(Base64.decode(this.$route.params.id))
          var jb = 0
          var ageS = 0
          var bmis = 0
          var downHeightS = 0
          var shiliangS = 0
          for (var key in values) {
            const value = values[key]
            if (value !== null && value !== undefined) {
              const answer = {}
              // key的格式 questionID_type_docField,如果是图片后面还有_image或者_previewVisible或_previewImage
              var keys = key.split('_') // 将key属性用_分割，一般是3个，图片是4个
              answer['QuestionID'] = Number(keys[0])
              // 类型
              const type = Number(keys[1])
              answer['type'] = type
              // 字段名
              answer['docField'] = keys[2]
              // 图片key值
              var imagekey = ''
              if (keys.length === 4) {
                imagekey = keys[3]
              }
              // 一般题目或者图片类型，图片只取image和标签的值
              if (type !== 7 || (type === 7 && (keys.length === 3 || imagekey === 'image'))) {
                const checkboxValue = []
                const checkboxScore = []
                // 单选
                if (type === 2 || type === 1) {
                  answer['Value'] = value.slice(value.indexOf('_') + 1)
                  answer['Score'] = Number(value.slice(0, value.indexOf('_'))).toString()
                  score = score + Number(value.slice(0, value.indexOf('_')))
                } else if (type === 5) {
                  // 多选（value是选项的json值）
                  value.map(m => {
                    score = score + Number(m.slice(0, m.indexOf('_')))
                    checkboxScore.push(Number(m.slice(0, m.indexOf('_'))))
                    checkboxValue.push(m.slice(m.indexOf('_') + 1))
                  })
                  answer['Score'] = JSON.stringify(checkboxScore).toString()
                  answer['Value'] = JSON.stringify(checkboxValue)
                } else if (type === 3) {
                  // 日期
                  const moment = require('moment')
                  answer['Value'] = moment(value).format('YYYY-MM-DD HH:mm:ss')
                } else if (type === 6) {
                  answer['Value'] = JSON.stringify(value)
                } else if (type === 7) {
                  // 如果是图片内容的做个标记
                  if (keys.length === 4) {
                    answer['Remark'] = 'image'
                    var images = []
                    value.map(v => {
                      var image = {}
                      image['imageData'] = v.preview
                      image['imageType'] = v.type
                      image['imageName'] = v.name
                      image['imageID'] = v.uid
                      images.push(image)
                    })
                    answer['Value'] = JSON.stringify(images)
                  } else {
                    answer['Value'] = JSON.stringify(value)
                  }
                } else if (type === 8 || type === 9) {
                  answer['Value'] = JSON.stringify(value)
                } else {
                  answer['Value'] = value.toString()
                }
                switch (key) {
                  case '583_0_null':
                    bmis = BMI(answer['Value'])
                    break
                  case '585_2_null':
                    shiliangS = jinshi(answer['Value'])
                    break
                  case '588_2_null':
                    downHeightS = height(answer['Value'])
                    break
                  case '586_2_null':
                    ageS = age(answer['Value'])
                    break
                  case '580_2_null':
                    jb = jibing(answer['Value'])
                    break
                }
                model.push(answer)
              }
            }
          }

          parm['ScoreTotal'] = score
          console.log(parm)

          this.submitStr = '提交中'
          // this.submitEnable = true
          var aa = []
          aa.push(parm)
          if (this.isScore) {
            var renti = [bmis, downHeightS, shiliangS]
            var scoreRenti = Math.max.apply(Math, renti)
            var total = jb + scoreRenti + ageS
            parm['ComputeScore'] = total
            Modal.confirm({
              title: '总分数：' + total,
              onOk: () => {
                window.removeEventListener('beforeunload', e => {
                  this.updateHandler(e)
                })
                AddAnswer({ model: aa }).then(res => {
                  if (res && res.length > 0) {
                    message.error(res[0])
                  } else {
                    this.$router.push({
                      name: 'result',
                      params: { id: this.$route.params.id, index: this.$route.params.index }
                    })
                  }
                })
              }
            })
          } else {
            window.removeEventListener('beforeunload', e => {
              this.updateHandler(e)
            })
            AddAnswer({ model: aa }).then(res => {
              if (res && res.length > 0) {
                message.error(res[0])
              } else {
                this.$router.push({
                  name: 'result',
                  params: {
                    id: this.$route.params.id,
                    index: this.$route.params.index,
                    score: this.scoreShow ? score : null
                  }
                })
              }
            })
          }
        } else {
          if (this.serialErro) {
            message.error('编号已存在')
            // this.form.setFields({ [this.serialKey]: { value: values[this.serialKey], errors: [new Error('编号已存在')] } })
          }
          this.$message.error('必填项不能为空')
        }
      })
    },
    documentLink(arg) {
      var record = arg[0]
      var e = arg[1]
      if (this.data.docLink) {
        var docValue = []
        var doclink = this.data.docLink.filter(r => r.docField === record.docField)
        // 只有关联档案的几个选项修改是会更新
        if (doclink.length > 0) {
          // 量表(问卷)中关联档案的变量至少有2个
          if (this.data.docLink.length > 1) {
            this.data.docLink.map(r => {
              var key = r.id + '_' + r.type + '_' + r.docField + '_' + r.text
              if (r.docField === 'SerialNo') {
                this.serialKey = key
              }
              if (r.docField === record.docField) {
                if (e.target.value) {
                  docValue.push({ key: r.docField, value: e.target.value })
                }
              } else {
                var value = this.form.getFieldValue(key)
                if (value) {
                  docValue.push({ key: r.docField, value: value })
                }
              }
            })

            // 量表(问卷)中关联档案的变量都有值
            if (docValue.length === this.data.docLink.length) {
              getdocinfo({ fields: docValue }).then(result => {
                console.log('aa', result)
                const fields = []
                const model = {}
                result.doc = result.doc && JSON.parse(result.doc)
                if (result.serialCount > 0 && result.doc.length === 0) {
                  // 编号存在，目前没有调出那一个人，告诉他编号已存在
                  this.serialErro = true
                  message.error('编号已存在')
                  // this.form.setFields({ [this.serialKey]: { value: docValue.filter(r => r.key === 'SerialNo')[0].value, errors: [new Error('编号已存在')] } })
                } else {
                  // 若调出这个人，则将错误提示取消
                  this.serialErro = false
                  this.form[this.serialKey] = docValue.filter(r => r.key === 'SerialNo')[0].value
                  // this.form.setFields({ [this.serialKey]: { value: docValue.filter(r => r.key === 'SerialNo')[0].value } })
                  if (result.doc && result.doc.length > 0) {
                    var res = result.doc[0]
                    Object.keys(res).map(r => {
                      // 获得档案此属性相关的问题
                      var question = null
                      for (var i = 0; i < this.data.questions.length; i++) {
                        if (this.data.questions[i].docField && r) {
                          if (this.data.questions[i].docField.toLowerCase() === r.toLowerCase()) {
                            question = this.data.questions[i]
                            break
                          }
                        }
                      }
                      if (question) {
                        const qkey = question.id + '_' + question.type + '_' + question.docField + '_' + question.text
                        if (this.data.docLink.filter(a => a.docField === question.docField).length === 0) {
                          fields.push(qkey)
                          if (res[r]) {
                            if (question.docField === 'Address' || question.docField === 'Hometown') {
                              model[qkey] = JSON.parse(res[r])
                            } else {
                              if (question.type === 1 || question.type === 2 || question.type === 5) {
                                var options = question.options && JSON.parse(question.options)
                                if (options) {
                                  if (options.filter(a => a.Text === res[r]).length > 0) {
                                    var score = options.filter(a => a.Text === res[r])[0].Score
                                    model[qkey] = score + '_' + res[r]
                                  }
                                }
                              } else {
                                model[qkey] = res[r]
                              }
                            }
                          }
                        }
                      }
                    })
                  } else {
                    this.data.questions.map(r => {
                      if (r.docField) {
                        const qkey = r.id + '_' + r.type + '_' + r.docField + '_' + r.text
                        if (this.data.docLink.filter(a => a.docField === r.docField).length === 0) {
                          fields.push(qkey)
                          // model[qkey] = undefined
                        }
                      }
                    })
                  }

                  // 当 model 发生改变时，为表单设置值
                  fields.forEach(v => this.form.getFieldDecorator(v))
                  this.form.setFieldsValue(pick(model, fields))
                }
              })
            }
          } else if (doclink.length === 1 && doclink[0].docField === 'SerialNo') {
            // 如果只有一个且这个是编号的话
            var key = doclink[0].id + '_' + doclink[0].type + '_' + doclink[0].docField + '_' + doclink[0].text
            this.serialKey = key
            ExistSerial({ serial: e.target.value }).then(res => {
              if (res > 0) {
                this.serialErro = true
                message.error('编号已存在')
                //  this.form.setFields({ [this.serialKey]: { value: e.target.value, errors: [new Error('编号已存在')] } })
              } else {
                this.serialErro = false
                this.form[this.serialKey] = e.target.value
              }
            })
          }
        }
      }
      if (this.data.linkQuestionIDs.indexOf(record.id) > -1) {
        this.questionLink(arg)
      }
    },
    questionLink(arg) {
      // var record = arg[0]
      // var e = arg[1]
      // var isnull = 0
      // this.data.linkQuestionIDs.map(r => {
      //   this.form.keys.map(k => {
      //     var keys = k.split('_')
      //     if (keys.indexOf(k) > -1) {
      //       isnull++
      //     }
      //   })
      // })
      // if (isnull === this.data.linkQuestionIDs.length) {
      //   // questionLink(arg)
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  font: 2em sans-serif;
  text-align: center;
}

.marginTop {
  margin-top: 40px;
  font: 20px;
}
.marginSujectTop {
  margin-top: 10px;
  font: 20px;
}
.marginLeft {
  padding-left: 20px;
  text-align: left;
}

.example {
  text-align: center;
  min-height: 500px;
  padding-top: 300px;
}
//正常网页
@media screen {
  .content {
    font-size: 14px;
    height: 30px;
  }
  .subject {
    font-size: 14px;
    font-weight: bold;
  }
  .divStyle {
    width: 1280px;
    margin: 0 auto;
    height: 100%;
  }
  .widthStyle {
    width: 70%;
    height: 40px;
    line-height: 40px;
  }

  //手机
  @media (max-width: 768px) {
    .content {
      font-size: 18px;
      height: 30px;
    }
    .subject {
      font-size: 18px;
      font-weight: bold;
    }
    .divStyle {
      width: 100%;
      height: 100%;
    }
    .divBac {
      background-color: white;
      //   background-image:none;
    }
    .widthStyle {
      width: 100%;
    }
  }
}
</style>
