var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.prefixCls },
    [
      _c(
        "a-tabs",
        {
          on: { change: _vm.handleTabChange },
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        _vm._l(_vm.icons, function(v) {
          return _c("a-tab-pane", { key: v.key, attrs: { tab: v.title } }, [
            _c(
              "ul",
              _vm._l(v.icons, function(icon, key) {
                return _c(
                  "li",
                  {
                    key: v.key + "-" + key,
                    class: { active: _vm.selectedIcon == icon },
                    on: {
                      click: function($event) {
                        return _vm.handleSelectedIcon(icon)
                      }
                    }
                  },
                  [
                    _c("a-icon", {
                      style: { fontSize: "36px" },
                      attrs: { type: icon }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }