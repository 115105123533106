<template>
  <a-checkbox-group v-model="item.value" @change="onChange" style="width:100%">
    <div v-for="(category, index) in item.options" :key="index">
      <a-row style="margin-top:10px">
        <a-col :span="24">
          <span>{{ category.className }}:</span>
        </a-col>
      </a-row>
      <a-row>
        <a-col style="margin:5px 0" v-for="(option,i) in category.children" :key="i" :xxl="item.optionsColLg" :sm="item.optionsColSm">
          <a-checkbox :value="option.value"> <span v-html="option.label">{{ option.label }}</span> </a-checkbox>
        </a-col>
      </a-row>
    </div>
  </a-checkbox-group>
</template>
<script>
export default {
  props: ['item'],
  data() {
    return {
    }
  },
  methods: {
    onChange(value) {
      this.$emit('updateForm', 'formula', value)
    }
  }
}
</script>