var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chart",
    {
      attrs: {
        forceFit: true,
        height: "400",
        data: _vm.data,
        padding: [20, 20, 95, 20],
        scale: _vm.scale
      }
    },
    [
      _c("v-tooltip"),
      _c("v-axis", {
        attrs: {
          dataKey: _vm.axis1Opts.dataKey,
          line: _vm.axis1Opts.line,
          tickLine: _vm.axis1Opts.tickLine,
          grid: _vm.axis1Opts.grid
        }
      }),
      _c("v-axis", {
        attrs: {
          dataKey: _vm.axis2Opts.dataKey,
          line: _vm.axis2Opts.line,
          tickLine: _vm.axis2Opts.tickLine,
          grid: _vm.axis2Opts.grid
        }
      }),
      _c("v-legend", {
        attrs: { dataKey: "user", marker: "circle", offset: 30 }
      }),
      _c("v-coord", { attrs: { type: "polar", radius: "0.8" } }),
      _c("v-line", {
        attrs: { position: "item*score", color: "user", size: 2 }
      }),
      _c("v-point", {
        attrs: {
          position: "item*score",
          color: "user",
          size: 4,
          shape: "circle"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }