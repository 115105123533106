var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticStyle: { "border-right": "1px solid rgb(232, 232, 232)" } },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { staticStyle: { padding: "30px" }, attrs: { span: 24 } },
                  [
                    _c("myTable", {
                      attrs: {
                        data: _vm.data,
                        columns: _vm.columns,
                        title: "配对样本秩检验分析结果"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { staticStyle: { padding: "30px" }, attrs: { span: 24 } },
                  [
                    _c(
                      "box-shadow-card",
                      { attrs: { title: "配对样本秩检验智能分析结果" } },
                      _vm._l(_vm.result, function(item) {
                        return _c("div", { key: item }, [
                          _c(
                            "span",
                            { domProps: { innerHTML: _vm._s(item) } },
                            [_vm._v(_vm._s(item))]
                          )
                        ])
                      }),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }