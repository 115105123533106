var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leftWidthHypo fieldWrapper", attrs: { id: "afield" } },
    [
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: "变量",
            prop: "selectKeys",
            rules: {
              required: true,
              message: "请选择"
            }
          }
        },
        [
          _c(
            "div",
            { style: { borderBottom: "1px solid #E9E9E9" } },
            [
              _c(
                "a-checkbox",
                {
                  attrs: {
                    indeterminate: _vm.indeterminate,
                    checked: _vm.checkAll
                  },
                  on: { change: _vm.onCheckAllChange }
                },
                [_vm._v(" 全选 ")]
              )
            ],
            1
          ),
          _c(
            "a-checkbox-group",
            {
              on: { change: _vm.onChange },
              model: {
                value: _vm.form.selectKeys,
                callback: function($$v) {
                  _vm.$set(_vm.form, "selectKeys", $$v)
                },
                expression: "form.selectKeys"
              }
            },
            _vm._l(_vm.data, function(item, i) {
              return _c(
                "div",
                { key: i },
                [
                  _c(
                    "a-checkbox",
                    { style: _vm.radioStyle, attrs: { value: item.fieldName } },
                    [
                      _c("span", [
                        _c("img", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            src:
                              "/chartsIcons/" +
                              _vm.isFieldType(item, "icon") +
                              ".png",
                            alt: "定类"
                          }
                        }),
                        _vm._v(" " + _vm._s(item.fieldName) + " ")
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }