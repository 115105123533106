var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "height" },
    [
      _vm.loadingController ? _c("loading") : _vm._e(),
      _c(
        "div",
        {
          staticClass: "height",
          staticStyle: { padding: "50px 0 0 0", width: "100%" }
        },
        [
          _c(
            "a-row",
            { attrs: { id: "share" } },
            [
              _c("a-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "height", attrs: { id: "PrintContent" } },
                  [
                    _c("div", { staticClass: "height" }, [
                      _c(
                        "div",
                        { staticClass: "pagedivaa" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "25px",
                                "text-align": "center",
                                "margin-bottom": "10px"
                              }
                            },
                            [_vm._v("智能报告（仅供参考）")]
                          ),
                          _c(
                            "a-collapse",
                            {
                              model: {
                                value: _vm.activeKey,
                                callback: function($$v) {
                                  _vm.activeKey = $$v
                                },
                                expression: "activeKey"
                              }
                            },
                            [
                              _c(
                                "a-collapse-panel",
                                {
                                  key: "1",
                                  attrs: {
                                    id: "一、基础统计",
                                    header: "一、基础统计"
                                  }
                                },
                                [
                                  _c("BasicStatistics", {
                                    ref: "一、基础统计",
                                    attrs: { fields: _vm.fields }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-collapse-panel",
                                {
                                  key: "2",
                                  attrs: {
                                    id: "二、基础分析",
                                    header: "二、基础分析"
                                  }
                                },
                                [
                                  _vm.xy.length > 0
                                    ? _c("BasicAnalysis", {
                                        ref: "二、基础分析",
                                        attrs: { xys: _vm.xy }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "a-collapse-panel",
                                {
                                  key: "3",
                                  attrs: {
                                    id: "三、智能分析",
                                    header: "三、智能分析"
                                  }
                                },
                                [
                                  _vm.type.length > 0
                                    ? _c("IntelligentAnalysis", {
                                        ref: "三、智能分析",
                                        attrs: {
                                          data: _vm.data,
                                          type: _vm.type,
                                          xys: _vm.xys,
                                          settings: _vm.settings
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "a-drawer",
            {
              attrs: { closable: false, visible: _vm.drawerVisible },
              on: { close: _vm.onClose }
            },
            [
              _c(
                "a-anchor",
                { attrs: { "target-offset": _vm.targetOffset } },
                _vm._l(_vm.pageData, function(obj1, i1) {
                  return _c(
                    "a-anchor-link",
                    {
                      key: i1,
                      attrs: { href: "#" + obj1.title, title: obj1.title }
                    },
                    _vm._l(obj1.content, function(obj2, i2) {
                      return _c(
                        "a-anchor-link",
                        {
                          key: i2,
                          attrs: { href: "#" + obj2.title, title: obj2.title }
                        },
                        [
                          obj2.type === "arry"
                            ? _c(
                                "div",
                                _vm._l(obj2.content, function(obj3, i3) {
                                  return _c("a-anchor-link", {
                                    key: i3,
                                    attrs: {
                                      href: "#" + obj3.title,
                                      title: obj3.title
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "fixed",
                top: "0px",
                width: "100%",
                height: "50px",
                background: "#001529",
                "z-index": "500",
                opacity: "1"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c(
                    "a-col",
                    {
                      staticClass: "title",
                      staticStyle: {
                        "margin-right": "20px",
                        "vertical-align": "middle"
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "black",
                            "margin-top": "10px"
                          },
                          on: { click: _vm.exportWord }
                        },
                        [_vm._v(" 导出word文档 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            color: "black",
                            "font-weight": "bold"
                          },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            "vertical-align": "middle"
                          },
                          on: { click: _vm.showDrawer }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: {
                              "font-size": "25px",
                              color: "white"
                            },
                            attrs: { type: "left-square" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }