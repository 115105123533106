var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: { padding: "0 15%", height: "100%" },
      attrs: { type: "flex", align: "middle", justify: "space-between" }
    },
    [
      _c(
        "a-col",
        {
          staticStyle: {
            position: "relative",
            bottom: "50px",
            "line-height": "60px"
          },
          attrs: { span: 12 }
        },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "25px", color: "#61d0fe" } },
            [_vm._v("为特定数据提供")]
          ),
          _c("div", { staticStyle: { "font-size": "40px", color: "white" } }, [
            _vm._v("收集、展示、分析、处理的"),
            _c("br"),
            _vm._v("专业平台")
          ])
        ]
      ),
      _c("a-col", { staticStyle: { height: "100%" }, attrs: { span: 9 } }, [
        _c(
          "div",
          { attrs: { id: "loginContainer" } },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  {
                    staticClass: "changeButton psdLogin",
                    class: { active: _vm.currentActive === "psdLogin" },
                    attrs: { span: 12 },
                    on: {
                      click: function($event) {
                        _vm.currentActive = "psdLogin"
                      }
                    }
                  },
                  [_vm._v("密码登录")]
                ),
                _c(
                  "a-col",
                  {
                    staticClass: "changeButton msgLogin",
                    class: { active: _vm.currentActive === "msgLogin" },
                    attrs: { span: 12 },
                    on: {
                      click: function($event) {
                        _vm.currentActive = "msgLogin"
                      }
                    }
                  },
                  [_vm._v("短信登录")]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-row",
                  { staticStyle: { padding: "0 10%" } },
                  [
                    _c(
                      "a-col",
                      [
                        _vm.currentActive === "psdLogin"
                          ? _c(
                              "a-form-model",
                              {
                                ref: "psdform",
                                attrs: {
                                  model: _vm.psdform,
                                  rules: _vm.psdRules
                                }
                              },
                              [
                                _vm.errorMsg
                                  ? _c("a-alert", {
                                      staticStyle: { "margin-bottom": "24px" },
                                      attrs: {
                                        type: "error",
                                        showIcon: "",
                                        message: "帐户或密码错误"
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "a-form-model-item",
                                  { attrs: { prop: "mobile" } },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          placeholder: "请输入手机号",
                                          size: _vm.inputSize
                                        },
                                        model: {
                                          value: _vm.psdform.mobile,
                                          callback: function($$v) {
                                            _vm.$set(_vm.psdform, "mobile", $$v)
                                          },
                                          expression: "psdform.mobile"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticClass: "icon",
                                          attrs: {
                                            slot: "prefix",
                                            type: "mobile"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  { attrs: { prop: "password" } },
                                  [
                                    _c(
                                      "a-input-password",
                                      {
                                        attrs: {
                                          placeholder: "请输入登录密码",
                                          size: _vm.inputSize,
                                          type: "password",
                                          autocomplete: "off"
                                        },
                                        model: {
                                          value: _vm.psdform.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.psdform,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "psdform.password"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticClass: "icon",
                                          attrs: {
                                            slot: "prefix",
                                            type: "lock"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-row",
                                  {
                                    attrs: {
                                      type: "flex",
                                      justify: "space-between"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-col",
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              checked: _vm.psdform.autoLogin
                                            },
                                            on: {
                                              change: _vm.onAutoLoginChange
                                            }
                                          },
                                          [_vm._v(" 自动登录 ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/person/findPassword1"
                                            }
                                          },
                                          [_vm._v(" 忘记密码 ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.currentActive === "msgLogin"
                          ? _c(
                              "a-form-model",
                              {
                                ref: "msgform",
                                attrs: {
                                  model: _vm.msgform,
                                  rules: _vm.msgRules
                                }
                              },
                              [
                                _c(
                                  "a-form-model-item",
                                  { attrs: { prop: "mobile" } },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          placeholder: "请输入手机号",
                                          size: _vm.inputSize
                                        },
                                        model: {
                                          value: _vm.msgform.mobile,
                                          callback: function($$v) {
                                            _vm.$set(_vm.msgform, "mobile", $$v)
                                          },
                                          expression: "msgform.mobile"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticClass: "icon",
                                          attrs: {
                                            slot: "prefix",
                                            type: "mobile"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("VerificationCode", {
                                  attrs: {
                                    form: _vm.msgform,
                                    inputSize: _vm.inputSize
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-row",
                          { staticStyle: { margin: "24px 0 7px 0" } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      block: "",
                                      size: _vm.inputSize,
                                      loading: _vm.buttonState.loginBtn,
                                      disabled: _vm.buttonState.loginBtn
                                    },
                                    on: { click: _vm.login }
                                  },
                                  [_vm._v("登录")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "border-top": "1px solid rgb(217, 217, 217)",
                  height: "8% !important",
                  width: "100%"
                }
              },
              [
                _c(
                  "a-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { type: "flex", justify: "center", align: "middle" }
                  },
                  [
                    _c(
                      "a-col",
                      [
                        _c("span", [_vm._v("还没有帐号？")]),
                        _c(
                          "router-link",
                          { attrs: { to: "/person/register" } },
                          [_vm._v(" 免费注册")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }