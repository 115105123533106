<template>
  <a-modal
    title="筛选条件"
    :visible="visible"
    :confirmLoading="loading"
    okText="确定"
    cancelText="取消"
    @ok="
      () => {
        $emit('ok')
      }
    "
    @cancel="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-form :form="form" v-bind="formLayout">
      <div style="color: #ff7200;height: 35px;background-color:#fff8ed;width:100%;line-height:35px;padding-left:10px">筛选只针对原始数据，缺失值处理过的数据将失效</div><br/>
      <a-form-item>
        <a-row>
          <a-col span="22">
            <a-button type="primary" icon="plus" @click="addOption">新增条件 </a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <div v-for="option in options" :key="option.key" style="margin-top: 20px">
        <a-row type="flex" align="middle">
          <a-col :span="22" style="background-color: #f9f9f9">
            <a-row type="flex" align="middle">
              <!-- 22是定类 20是定量 -->
              <a-col :span="option.addShow ? 20 : 22" offset="1">
                <a-form-item>
                  <a-select
                    style="margin-top: 10px"
                    v-decorator="[
                      `${option.key}_field`,
                      {
                        rules: [
                          {
                            required: true,
                            message: '变量必填',
                          },
                        ],
                      },
                    ]"
                    @change="
                      (value) => {
                        fieldChange(value, option)
                      }
                    "
                    placeholder="请选择"
                  >
                    <a-select-option v-for="(item, i) in option.fields" :key="i" :value="item.fieldName">{{
                      item.fieldName
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col span="2" style="margin-left: 10px" v-if="option.addShow">
                <a-icon class="dynamic-delete-button" type="plus-circle" @click="addOptionChild(option)" />
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col
                :id="`${option.key}_andCol`"
                span="4"
                offset="1"
                style="
                  text-align: center;
                  background-color: #ffffff;
                  border: 1px soild #dedede;
                  margin-top: 5px;
                  margin-bottom: 10px;
                "
                v-if="option.children.length > 1"
              >
                <a-form-item>
                  <a-radio-group v-decorator="[`${option.key}_relation`]" style="position: relative">
                    <a-radio value="and">并且</a-radio>
                    <a-radio value="or">或者</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="option.children.length === 1 ? 24 : 17">
                <a-row v-for="child in option.children" :key="child" style="margin-bottom: 10px">
                  <a-col :span="option.children.length > 1 ? 7 : 5" :offset="option.children.length > 1 ? 1 : 2">
                    <!-- <div hidden>
                      <a-form-item>
                        <a-input v-decorator="[option.key+'_keys']"></a-input>
                      </a-form-item>
                    </div> -->
                    <a-form-item>
                      <a-select
                        v-decorator="[
                          `${option.key}_${child}_dayu`,
                          {
                            rules: [
                              {
                                required: true,
                                message: '必填',
                              },
                            ],
                          },
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="(item, j) in option.dayu" :key="j" :value="item.value">{{
                          item.text
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="option.values && option.values.length > 0 ? 15 : 11" offset="1">
                    <a-form-item>
                      <a-select
                        mode="multiple"
                        v-decorator="[
                          `${option.key}_${child}_value_0`,
                          {
                            rules: [
                              {
                                required: option.required0,
                                message: '条件必填',
                              },
                            ],
                          },
                        ]"
                        placeholder="请选择"
                        v-if="option.values && option.values.length > 0"
                      >
                        <a-select-option :title="item" v-for="(item, k) in option.values" :key="k" :value="item">{{
                          item
                        }}</a-select-option>
                      </a-select>
                      <a-input
                        v-decorator="[
                          `${option.key}_${child}_value_1`,
                          {
                            rules: [
                              {
                                required: option.required1,
                                message: '条件必填',
                              },
                              {
                                message: '请输入数值',
                                pattern: /^(([0-9]+\.[0-9]*[0-9][0-9]*)|([0-9]*[0-9][0-9]*\.[0-9]+)|([0-9]*[0-9][0-9]*))$/,
                              },
                            ],
                          },
                        ]"
                        v-if="!option.values || option.values.length === 0"
                        style="width: 100%"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col
                    span="2"
                    style="margin-left: 10px"
                    v-if="(!option.values || option.values.length === 0) && option.children.length > 1"
                  >
                    <a-icon
                      class="dynamic-delete-button"
                      type="minus-circle-o"
                      @click="removeOptionChild(option, child)"
                    />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="2">
            <a-icon
              class="dynamic-delete-button"
              type="minus-circle-o"
              style="padding-left: 10px"
              @click="removeOption(option.key)"
            />
          </a-col>
        </a-row>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { string } from 'clipboard'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    ruleFields: {
      type: Array,
      default: () => []
    },
    // session的查询条件
    model: {
      type: Object,
      default: () => {}
    },
    errString: {
      type: string,
      default: () => ''
    },
    errors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 0 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }
    return {
      options: [], // 条件
      index: 0
    }
  },
  watch: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'datarule_form_item' })
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },
  created() {
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      if (this.model) {
        this.options = []
        if (this.model.keys) {
          this.model.keys.map(r => {
            var field = this.model[r + '_field']
            var rule = this.ruleFields.filter(m => m.key === field)
            var dayu = []
            var values = []
            if (rule.length > 0) {
              dayu = rule[0].dayu
              values = rule[0].option
            }
            // 如果values没有值，则说明是定量，显示+
            var addShow = false
            if (!values || values.length === 0) {
              addShow = true
            }
            var option = { key: r, dayu, values, addShow, fields: this.ruleFields, children: this.model[r + '_keys'] }
            this.options.push(option)
            this.andChange(option)
          })

          this.index = this.model.keys.length > 0 ? Math.max(...this.model.keys) + 1 : 1
        }

        Object.keys(this.model).forEach(v => {
          if (v.indexOf('value_0') > -1 || v.indexOf('_keys') > -1) {
            this.form.getFieldDecorator(v, { initialValue: [], preserve: true })
          } else {
            this.form.getFieldDecorator(v)
          }
          if (v.indexOf('_item') > -1 || v.indexOf('_keys') > -1) {
            this.form.getFieldDecorator(v, { initialValue: {}, preserve: true })
          } else {
            this.form.getFieldDecorator(v)
          }
        })
        this.model && this.form.setFieldsValue(pick(this.model, Object.keys(this.model)))
        console.log('model', this.model)
      }
    })
  },
  methods: {
    /**
     * 当增加或者减少子条件时，左侧“并且或者”的框高度实时变化
     */
    andChange(option) {
      this.$nextTick(function() {
        var col = document.getElementById(option.key + '_andCol')
        var group = document.getElementById('datarule_form_item_' + option.key + '_relation')
        if (col && group) {
          group.style.top = (col.offsetHeight - group.offsetHeight) / 2 + 'px'
        }
      })
    },
    /**
     * 新增条件
     */
    addOption() {
      const keys = this.form.getFieldValue('keys')
      const nextKeys = keys.concat(this.index)
      this.form.setFieldsValue({
        keys: nextKeys
      })
      var option = { key: this.index, dayu: [], values: [], fields: this.ruleFields, children: [0] }
      this.options.push(option)
      this.form.getFieldDecorator(this.index + '_keys', { initialValue: option.children, preserve: true }) // 子条件的key集合，格式"父级key_keys"
      this.form.getFieldDecorator(option.key + '_item', { initialValue: {}, preserve: true })
      this.form.setFieldsValue({
        [this.index + '_keys']: option.children
      })
      this.index++
    },
    /**
     * 新增子条件
     */
    addOptionChild(option) {
      if (option.children.length === 1) {
        this.form.getFieldDecorator(option.key + '_relation', { initialValue: 'and', preserve: true })
      }
      var max = Math.max(...option.children)
      var current = max + 1
      option.children.push(current)
      this.form.setFieldsValue({
        [option.key + '_keys']: option.children
      })
      this.andChange(option)
    },
    /**
     * 删除子条件
     */
    removeOptionChild(option, value) {
      option.children = option.children.filter(r => r !== value)
      this.form.getFieldDecorator([option.key + '_keys'], { initialValue: option.children, preserve: true })
      this.form.setFieldsValue({
        [option.key + '_keys']: option.children
      })
      this.andChange(option)
    },
    /**
     * 删除条件
     */
    removeOption(k) {
      // 要删除的option
      var option = this.options.filter(r => r.key === k)
      // 将子条件清空
      var key = option[0].key + '_keys' // key集合的名称
      // 重新给子集合赋值
      var aa = {}
      aa[key] = []
      this.form.setFieldsValue(aa)
      this.options = this.options.filter(r => r.key !== k)
      const keys = this.form.getFieldValue('keys')
      if (keys.length === 0) {
        return
      }
      this.form.setFieldsValue({
        keys: keys.filter(r => r !== k)
      })
    },
    fieldChange(e, option) {
      console.log('dd', option)
      option.children = [0]

      this.form.getFieldDecorator(option.key + '_keys', { initialValue: [0], preserve: true }) // 子条件的key集合，格式"父级key_keys"
      this.form.setFieldsValue({
        [option.key + '_keys']: option.children
      })
      this.andChange(option)

      option.values = []
      this.ruleFields.map(r => {
        // const aa = e.slice(e.indexOf('_') + 1)
        if (r.fieldName === e) {
          option.dayu = r.dayu
          option.values = r.option
          this.form.setFieldsValue({
            [option.key + '_item']: r
          })
        }
      })
      // 如果values没有值，则说明是定量，显示+
      if (!option.values || option.values.length === 0) {
        option.addShow = true
        option.required1 = true
        option.required0 = false
      } else {
        option.addShow = false
        option.required1 = false
        option.required0 = true
      }

      const valueObj0 = {}
      const valueKey0 = option.key + '_0_value_0'
      // delete valueObj0[valueKey0]
      valueObj0[valueKey0] = undefined

      this.form.setFieldsValue(valueObj0)
      const valueKey1 = option.key + '_0_value_1'
      const valueObj1 = {}
      // delete valueObj1[valueKey1]
      valueObj1[valueKey1] = undefined

      this.form.setFieldsValue(valueObj1)
      const dayuKey = option.key + '_0_dayu'
      const dayuObj = {}
      // delete dayuObj[dayuKey]
      dayuObj[dayuKey] = undefined
      this.form.setFieldsValue(dayuObj)
      this.form.getFieldDecorator(option.key + '_relation', { initialValue: 'and', preserve: true })
      option.children = [0]
      console.log('dd', option)
    }
  }
}
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  margin-left: 2px;
  color: #999;
  transition: all 0.3s;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
</style>
