<template>
  <a-card :bordered="false" id="divQuestion">
    <a-steps class="steps" :current="currentTab" style="width: 50%; margin: 0 auto">
      <a-step title="上传EXCEL文件">
        <a-icon slot="icon" type="cloud-upload"></a-icon>
      </a-step>
      <a-step title="数据预览">
        <a-icon slot="icon" type="file-search"></a-icon>
      </a-step>
    </a-steps>
    <div class="content">
      <step1 v-if="currentTab === 0" @nextStep="nextStep" />
      <step2 v-if="currentTab === 1" :form="exceldata" @prevStep="prevStep" @finish="finish" :excelerro="excelerro"/>
    </div>
  </a-card>
</template>
<script>
import { AutoHeight } from '@/utils/util'

import Step1 from './components/step1'
import Step2 from './components/step2'
export default {
  components: {
    Step1,
    Step2
  },
  data() {
    return {
      currentTab: 0,
      exceldata: {},
      excelerro: 0
    }
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  }, // 方法
  methods: {
    // 下一步
    nextStep(data) {
      if (this.currentTab < 2) {
        this.exceldata = data
        if (data.data) {
          // 整理预览的模型
          var length = data.data.length > 20 ? 20 : data.data.length
          this.exceldata.display = data.data
          var title = {}
          var fields = []
          if (length > 0) {
            var columns = []
            // 取一行数据
            var row = data.title.map(r => { return r.name })
            var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
            row.map((r, i) => {
              if (pattern.test(r)) {
                this.excelerro++
              }
              if (r !== 'key') {
                fields.push(r)
                title[r] = r
                // 如果只有一列就不fixed和width了，因为每个对象都有key，所以个数要大于2
                if (i === 0 && Object.keys(row).length > 2) {
                  columns.push({
                    title: `第${i + 1}列`,
                    align: 'center',
                    className: 'column-shili',
                    fixed: 'left',
                    width: 100,
                    children: [
                      {
                        title: r,
                        dataIndex: r,
                        align: 'center',
                        className: pattern.test(r) ? 'column-err' : 'column-money',
                        fixed: 'left',
                        width: 100
                      }
                    ]
                  })
                } else {
                  columns.push({
                    title: `第${i + 1}列`,
                    align: 'center',
                    className: 'column-shili',
                    children: [
                      {
                        title: r,
                        dataIndex: r,
                        align: 'center',
                        className: pattern.test(r) ? 'column-err' : 'column-money'
                      }
                    ]
                  })
                }
              }
            })
          }
          this.exceldata.fields = fields

          this.exceldata.columns = columns
        }
        console.log('exceldata', this.exceldata)
        this.currentTab += 1
      }
    },
    // 上一步
    prevStep() {
      if (this.currentTab > 0) {
        this.currentTab -= 1
        this.excelerro = 0
      }
    },
    finish() {
      this.$router.push('/file/List')
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divQuestion']
      AutoHeight(this, min, max, 215)
    }
  }
}
</script>