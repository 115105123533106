export default class MeansClass {
  constructor(data, text, value) {
    /**
     * @param data 与方差分析有关的组成的数据结构
     * @param text 变量与方差分析有关的文字描述
     * @param value 'variance'
     */
    this.data = data
    this.text = text
    this.value = value
  }

  getDataExceptions(centerForExceptions) {
    /**
     * @param centerForExceptions 存放数据错误信息的中心
     */
    const {
      checkDocLenForLei
    } = this
    const expMethodAry = [checkDocLenForLei]
    expMethodAry.forEach(method => {
      method(this, centerForExceptions)
    })
  }

  checkDocLenForLei(_this, centerForExceptions) {
    const {
      data
    } = _this
    // 取到包含分类名与数组的键值对
    const countObj = Object.values(data.data1)[0]
    // 定义一个数组，用于存放数据量小于3的分类的名字
    const lenLessThan3Ary = []
    // 遍历键值对
    for (const key in countObj) {
      const value = countObj[key]
      // 如果值的数组长度小于3，那么把键的名字存到数组里去
      if (value.length < 3) {
        lenLessThan3Ary.push(key)
      }
    }
    if (lenLessThan3Ary.length > 0) {
      const text = `${_this.text}中，${lenLessThan3Ary.join('、')}的样本数不能小于3。`
      centerForExceptions.push(text)
    }
  }
}