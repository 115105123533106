var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _vm.score
        ? _c(
            "a-result",
            { attrs: { status: "success", title: "提交成功,感谢您的参与" } },
            [
              _vm.score
                ? _c("div", { staticClass: "desc" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "20px",
                          "text-align": "center",
                          padding: "0px"
                        }
                      },
                      [
                        _c("strong", {
                          domProps: { innerHTML: _vm._s(_vm.level) }
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c("span", [
        _vm._v(
          "本次测试由软件根据测试人的选择项，自动生成测试结果，测试结果仅供参考，不可替代诊断"
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }