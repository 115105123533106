import request from '@/utils/request'

const api = {
  python: '/python'
}

export function getModule(parameter) {
  return request({
    url: api.python + '/module',
    method: 'post',
    data: parameter
  })
}