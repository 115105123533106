<template>
  <box-shadow-card :title="content.title" style="width: 450px;min-height:0px !important">
    <div>
      <p v-html="line" v-for="(line,index) in content.content" :key="index">{{ line }}</p>
    </div>
  </box-shadow-card>
</template>
<script>
export default {
    props: ['content']
}
</script>