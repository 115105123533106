var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { tip: "文件处理中...", spinning: _vm.spinning } },
        [
          _vm.form.merges
            ? _c("a-alert", {
                attrs: {
                  type: "error",
                  message: "Excel存在单元格合并，缺失部分数据，请核查校验！",
                  banner: ""
                }
              })
            : _vm._e(),
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.form, layout: "inline" } },
              "a-form-model",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "文件名称",
                    prop: "name",
                    rules: {
                      required: true,
                      message: "文件名称不能为空",
                      trigger: "blur"
                    }
                  }
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  }),
                  _c(
                    "a-checkbox",
                    {
                      staticStyle: { "margin-left": "30px" },
                      model: {
                        value: _vm.form.isSx,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "isSx", $$v)
                        },
                        expression: "form.isSx"
                      }
                    },
                    [_vm._v("是否是时序数据")]
                  )
                ],
                1
              ),
              _c("br"),
              _vm.form.isSx
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "时序时间",
                            prop: "name",
                            rules: {
                              required: true,
                              message: "文件名称不能为空",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "300px" },
                              model: {
                                value: _vm.form.sxtime,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sxtime", $$v)
                                },
                                expression: "form.sxtime"
                              }
                            },
                            _vm._l(_vm.form.fields, function(item, i) {
                              return _c(
                                "a-select-option",
                                { key: i, attrs: { value: item } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "时序变量",
                            prop: "name",
                            rules: {
                              required: true,
                              message: "文件名称不能为空",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { mode: "multiple" },
                              model: {
                                value: _vm.form.sxfield,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sxfield", $$v)
                                },
                                expression: "form.sxfield"
                              }
                            },
                            _vm._l(_vm.form.fields, function(item, i) {
                              return _c(
                                "a-select-option",
                                { key: i, attrs: { value: item } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(" 当前样本量:"),
                _c("span", { staticStyle: { color: "#3397ff" } }, [
                  _vm._v(_vm._s(_vm.form.data ? _vm.form.data.length : 0))
                ])
              ]),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          "font-size": "16px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v("数据预览")]
                    )
                  ]),
                  _c(
                    "a-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { to: "/file/List" }
                        },
                        [_vm._v("<<返回文件列表")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.excelerro > 0
                ? _c("a-alert", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      type: "error",
                      message:
                        "上传的文件的标题中含有[`~!@#$^&*()=|{}':;',\\\\[\\\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]空格特殊字符（红色标记代表有问题），请修改后重新上传",
                      banner: ""
                    }
                  })
                : _vm._e(),
              _c("a-table", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  bordered: "",
                  size: "small",
                  rowKey: "key",
                  columns: _vm.form.columns,
                  "data-source": _vm.form.display,
                  scroll: { x: true }
                }
              }),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("a-button", { on: { click: _vm.prevStep } }, [
                    _vm._v("上一步")
                  ]),
                  _vm.excelerro === 0
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.finish }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }