var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drops" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c("a-col", { staticClass: "center", attrs: { flex: "50px" } }),
          _c(
            "a-col",
            { attrs: { span: 14 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "space-around" } },
                _vm._l(_vm.item.settings[0], function(obj, index) {
                  return _c(
                    "a-col",
                    {
                      key: index,
                      staticClass: "center strong-font",
                      attrs: { span: _vm.numOfDrop }
                    },
                    [_vm._v(" " + _vm._s(obj.label) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c("a-col", { staticClass: "center", attrs: { flex: "25px" } }),
          _c(
            "a-col",
            { staticClass: "center strong-font", attrs: { span: 8 } },
            [_vm._v("研究方向")]
          )
        ],
        1
      ),
      _vm._l(_vm.item.settings, function(single, index) {
        return _c(
          "div",
          { key: index, staticClass: "single" },
          [
            _c(
              "a-row",
              { attrs: { type: "flex", align: "middle" } },
              [
                _c(
                  "a-col",
                  {
                    staticClass: "center strong-font",
                    attrs: { flex: "50px" }
                  },
                  [_vm._v(_vm._s(index + 1) + ".")]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 14 } },
                  [
                    _c(
                      "a-row",
                      {
                        attrs: {
                          type: "flex",
                          justify: "space-around",
                          align: "middle"
                        }
                      },
                      _vm._l(single, function(drop, i) {
                        var this$1 = this
                        return _c(
                          "a-col",
                          {
                            key: i,
                            staticStyle: {
                              display: "flex !important",
                              "align-items": "center"
                            },
                            attrs: { span: _vm.numOfDrop }
                          },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  labelCol: {
                                    md: { span: 0 },
                                    xxl: { span: 0 }
                                  },
                                  wrapperCol: {
                                    md: { span: 24 },
                                    xxl: { span: 24 }
                                  },
                                  label: "",
                                  prop: drop.prop,
                                  rules: single.every(function(drop) {
                                    return drop.list.length === 0
                                  })
                                    ? []
                                    : [
                                        {
                                          validator: function(
                                            rule,
                                            value,
                                            callback
                                          ) {
                                            return _vm.validateDrop(
                                              rule,
                                              value,
                                              callback,
                                              drop,
                                              _vm.$deepClone(drop.divHelp),
                                              this$1
                                            )
                                          }
                                        }
                                      ]
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "start"
                                    }
                                  },
                                  [
                                    _c("drop", {
                                      attrs: {
                                        load: _vm.load,
                                        item: drop,
                                        index: [index, i],
                                        vuex: _vm.vuex,
                                        parentType: "drops",
                                        xysName: _vm.item.xysName,
                                        indexGroup: _vm.group
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { staticClass: "center", attrs: { flex: "25px" } },
                  [
                    !("mark" in single[0])
                      ? _c("a-icon", {
                          staticClass: "dynamic-delete-button",
                          attrs: {
                            type: "minus-circle-o",
                            disabled: _vm.item.settings.length === 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteGroup(
                                index,
                                _vm.item.settings.length
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          {
                            staticStyle: {
                              height: "42px",
                              display: "flex",
                              "align-items": "center"
                            },
                            attrs: { span: 24 }
                          },
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  mode: "multiple",
                                  maxTagCount: 2,
                                  maxTagTextLength: 8,
                                  placeholder: "暂无研究方向",
                                  "option-label-prop": "label",
                                  disabled:
                                    _vm.typeSelectOptions[index].options
                                      .length === 1
                                },
                                model: {
                                  value: single[0].value2,
                                  callback: function($$v) {
                                    _vm.$set(single[0], "value2", $$v)
                                  },
                                  expression: "single[0].value2"
                                }
                              },
                              _vm._l(
                                _vm.typeSelectOptions[index].options,
                                function(v) {
                                  return _c(
                                    "a-select-option",
                                    { key: v.value, attrs: { label: v.label } },
                                    [_vm._v(_vm._s(v.text))]
                                  )
                                }
                              ),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c("a-col", { attrs: { flex: "50px" } }),
          _c(
            "a-col",
            { staticClass: "center", attrs: { span: 14 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "91%" },
                  attrs: { type: "dashed" },
                  on: { click: _vm.addGroup }
                },
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _vm._v(" 增加变量组 ")
                ],
                1
              )
            ],
            1
          ),
          _c("a-col", { staticClass: "center", attrs: { flex: "25px" } }),
          _c("a-col", { attrs: { span: 8 } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }