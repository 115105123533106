<template>
  <div>
    <div v-for="(a, thirdIndex) in charts" :key="thirdIndex">
      <div :ref="`title,${thirdIndex + 1}`" :id="`1.${secondIndex}.${thirdIndex + 1} ${a.text}`" class="third-title">
        1.{{ secondIndex }}.{{ thirdIndex + 1 }} {{ a.text }}
      </div>
      <div >
        <Conclusion v-if="a.detail">
          <p :ref="`text,${thirdIndex + 1},0`">
            {{ a.detail }}
          </p>
        </Conclusion >
      </div>
      <div v-for="(f, forthIndex) in a.charts" :key="forthIndex" style="margin-top:10px">
        <div :ref="`sub_title,${thirdIndex + 1},${8*forthIndex+1}`" :id="`1.${secondIndex}.${thirdIndex + 1}.${forthIndex+1} ${f.name}`" class="forth-title">
          1.{{ secondIndex }}.{{ thirdIndex+1 }}.{{ forthIndex + 1 }} {{ f.name }}
        </div>
        <div>
          <chartShow :ref="`content,${thirdIndex + 1}`" :tables="f.tables" :imgs="f.imgs" :indexT="forthIndex">
            <template #text1 class="text" v-if="f.tables.length > 0">
              <p :ref="`text,${thirdIndex + 1},${8*forthIndex+2}`">
                {{ f.tables[0].text }}
              </p>
            </template>
            <template #text2 class="text">
              <p :ref="`text,${thirdIndex + 1},${8*forthIndex+5}`">
                {{ f.text2 }}
              </p>
            </template>
            <!-- <template #text4 class="text">
              <Conclusion v-if="f.text4">
                <p :ref="`text,${thirdIndex + 1},${8*forthIndex+7}`">
                  {{ f.text4 }}
                </p>
              </Conclusion>
            </template> -->
          </chartShow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartShow from '@/views/report/modules/basicStatistics/chartShow'
import { getModule } from '@/api/module'
import { DataFilterClass } from '@/utils/dataFilterClass'
import pick from 'lodash.pick'
export default {
  components: { chartShow },
  props: ['fields', 'secondIndex'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    var data = [] // 清洗后的数据
    if (this.fields) {
      this.fields.map(r => {
        var rdata = new DataFilterClass([r]).main().map(a => pick(a, [r.name]))
        if (r.type === '量') {
          rdata.map(a => {
            a[r.name] = Number(a[r.name])
          })
        }
        data.push({ name: r.name, data: rdata })
      })
    }
    getModule({
      module_name: 'BasicStatistics',
      data: data,
      fields: this.fields,
      count: this.data ? this.data.length : 0,
      style: { language: this.$store.state.user.info.setting.language },
      method: 'charts',
      colors: JSON.parse(this.$store.state.user.info.setting.colors)
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '基础统计（图）',
          msg: res
        })
      } else {
        console.log('image', res.charts)
        res.charts.forEach(chart1 => {
          chart1.charts.forEach(chart => {
            if (chart.tables.length) {
            this.getMergeSetting(chart.tables)
          }
          chart.tables.forEach(table => {
            // 合并统计指标
            table.columns[0].customRender = (value, row, index) => {
              if (row.first) {
                var aa = table.colparm.filter(r => r.field === row.field)
                if (aa.length > 0) {
                  return {
                    children: value,
                    attrs: {
                      rowSpan: aa[0].length
                    }
                  }
                } else {
                  return {
                    children: value,
                    attrs: {
                      rowSpan: 0
                    }
                  }
                }
              } else {
                return {
                  children: value,
                  attrs: {
                    rowSpan: 0
                  }
                }
              }
            }
          })
          })
        })
        this.charts = res.charts

        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      charts: []
    }
  },
  methods: {
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      tables[0].settings = [[]]
      for (let index = 1; index <= table_row_num; index++) {
        tables[0].settings[0].push([index, 0])
      }
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
