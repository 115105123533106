<template>
  <a-card :bordered="false" id="divQuestion">
    <a-row type="flex">
      <a-col>
        <a-breadcrumb style="margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/file/List">文件列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 文件详情 </a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <div style="font-size: 15px">
          <span>文件名称：{{ filename }}</span>
          <span style="margin-left: 20px"
          >样本量:<span style="color: #3397ff">{{ count }}</span></span
          >
        </div>
      </a-col>
    </a-row>
    <s-table
      bordered
      style="margin-top: 20px"
      ref="table"
      size="small"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
      :scroll="{ x: true }"
    >
      <div :slot="item.dataIndex" v-for="(item, i) in columns" :key="i">
        {{ item.slotValue + '【' + item.fileType.split('_')[1] + '】' }}

        <a-popover title="修改类型" trigger="click" :visible="colVisible && colNum === i">
          <div slot="content" style="width: 200px">
            <a-form-model :v-model="columform" v-bind="formLayout" labelAlign="left">
              <a-form-model-item label="类型">
                <a-select v-model="columform.type">
                  <a-select-option key="0" value="0_定量">定量</a-select-option>
                  <a-select-option key="2" value="2_定类">定类</a-select-option>
                  <a-select-option key="3" value="3_日期">日期</a-select-option>
                  <a-select-option key="4" value="4_文本">文本</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-button type="primary" @click="PopoverSubmit(item, i)">确定</a-button>
              <a-button type="cancel" style="margin-left: 20px" @click="PopoverClose(item, i)">取消</a-button>
            </a-form-model>
          </div>
          <a-icon type="edit" theme="twoTone" @click="PopoverOpen(item, i)" />
        </a-popover>
      </div>
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { GetFileData, UpdateTitle } from '@/api/file'
import { Modal } from 'ant-design-vue'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
export default {
  // 加载组件
  components: {
    STable
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  // 页面展示的数据
  data() {
    this.columns = []
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }
    return {
      columform: {},
      colVisible: false, // 用于气泡popover显示
      colNum: 0, // 用于气泡popover哪一个列 只有当colVisible和colNum都能对上的时候才有效
      // 新建/编辑modal是否显示
      visible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      filename: '',
      count: 0,
      title: '创建量表(问卷)',
      data: [],
      // 查询参数
      queryParam: { fileID: Number(this.$route.params.id) },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return GetFileData({ ...requestParameters, orgid: this.$store.state.user.info.orgid }).then(res => {
          res.data = res && JSON.parse(res.data[0])
          this.data = res.data
          var colums = []
          this.filename = res.fileName
          res.headers.map((r, i) => {
            var displayName = r.displayName ? r.displayName : r.name
            var column = {
              // title: r.displayName ? r.displayName : r.name,
              dataIndex: r.name,
              align: 'center',
              slots: { title: r.name },
              slotValue: displayName,
              columnID: r.id,
              visible: false,
              fileType: r.fileTypestr
            }
            if (i === 0 && res.headers.length > 2) {
              column.fixed = 'left'
              column.width = 150
            }
            colums.push(column)
          })
          this.columns = colums
          this.count = res.totalCount
          return res
        })
      },
      // 如果有人答题则无法修改模版
      disable: false,
      qrvisible: false,
      url: '',
      Base64: Base64
    }
  },
  // 方法
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divQuestion']
      AutoHeight(this, min, max, 176)
    },
    /**
     * 新建事件
     */
    handleAdd() {
      this.$router.push('/file/Add')
    },
    /**
     * 编辑事件
     */

    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record, 'range-picker': [record.startDate, record.endDate] }
      this.title = '编辑量表(问卷)'
      if (record.numberOfPeople && Number(record.numberOfPeople) > 0) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
    /**
     * 新建/编辑cancel事件
     */

    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },

    /**
     *启用/停用事件
     */
    handleDel(record) {
      const title = record.deleteFlag ? '启用' : '停用'
      Modal.confirm({
        title: '您确定' + title + '该量表(问卷)吗？',
        content: '停用后将无法进行量表(问卷)录入，无法进行统计分析',
        onOk: () => {
          // deleteQuestionnaire(record.id).then(res => {
          //   // 刷新表格
          //   this.$refs.table.refresh()
          // })
        }
      })
    },

    handlePreview(record) {
      this.$router.push(`/file/FileData/${record.id}`)
    },
    PopoverOpen(item, i) {
      this.columform = {
        type: item.fileType,
        id: item.columnID
      }
      this.colVisible = true
      this.colNum = i
    },
    PopoverClose(item, i) {
      this.colVisible = false
      this.colNum = -1
    },
    PopoverSubmit(item, i) {
      var parm = {
        id: this.columform.id,
        fileType: parseInt(this.columform.type.split('_')[0])
      }
      // if (parm.fileType === 1 || parm.fileType === 2) {
      //   parm.option = JSON.stringify( Array.from(
      //     new Set(
      //       this.data.map(r => {
      //         if (r[item.dataIndex]) {
      //           return r[item.dataIndex].toString()
      //         }
      //       })
      //     )
      //   ))
      // } else {
      //   parm.option = null
      // }
      UpdateTitle(parm).then(res => {
        this.colVisible = false
        this.colNum = -1
        console.log('aa', this.columns[i]);
        this.columns[i].fileType = this.columform.type
        this.$store.dispatch('initSamples')
      })
    }
  },
  /**
   * 过滤器
   */
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }
  }
}
</script>
