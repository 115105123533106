<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 0">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 0 30px">
            <div v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
            </div>
            <my-table2 :showHeader="false" :data="table3.data" :columns="table3.columns" size="middle"></my-table2>
            <my-table2 :showHeader="false" :data="table4.data" :columns="table4.columns" size="middle"></my-table2>
            <my-table2 :data="tableRoc.data" :columns="tableRoc.columns" size="middle"></my-table2>
            <!-- <div style="margin: 20px 0 !important">回归方程式为：{{ formula }}</div> -->
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col>
            <img :src="rocImg" />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/views/analysisOfRegression/MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import MyTable2 from '../../table'
import { translate } from '@/utils/language'
import ChartClass from '@/commonmodules/chart'
import { get_formula, change_to_percent } from '@/utils/util'

export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {},
      y_name: null,
      formula: null,
      table3: {
        columns: [
          {
            title: '',
            dataIndex: 'col1',
            align: 'center',
            customRender: (text, record, index) => {
              const obj = {
                children: text,
                attrs: {}
              }
              if ([0, 5].includes(index)) {
                obj.attrs.colSpan = 2
              }
              if ([0].includes(index)) {
                obj.attrs.rowSpan = 3
              }
              if ([3].includes(index)) {
                obj.attrs.rowSpan = 2
              }
              if ([1, 2, 4].includes(index)) {
                obj.attrs.rowSpan = 0
                obj.attrs.colSpan = 0
              }
              return obj
            }
          },
          {
            title: '',
            dataIndex: 'col2',
            align: 'center',
            customRender: (text, record, index) => {
              const obj = {
                children: text,
                attrs: {}
              }
              if ([0, 1, 2, 5].includes(index)) {
                obj.attrs.rowSpan = 0
                obj.attrs.colSpan = 0
              }
              return obj
            }
          },
          {
            title: '',
            dataIndex: 'col3',
            align: 'center',
            customRender: (text, record, index) => {
              const obj = {
                children: text,
                attrs: {}
              }
              if ([1].includes(index)) {
                obj.attrs.colSpan = 2
              }
              if ([0].includes(index)) {
                obj.attrs.colSpan = 3
              }
              return obj
            }
          },
          {
            title: '',
            dataIndex: 'col4',
            align: 'center',

            customRender: (text, record, index) => {
              const obj = {
                children: text,
                attrs: {}
              }
              if ([0, 1].includes(index)) {
                obj.attrs.rowSpan = 0
                obj.attrs.colSpan = 0
              }
              return obj
            }
          },
          {
            title: '',
            dataIndex: 'col5',
            align: 'center',
            customRender: (text, record, index) => {
              const obj = {
                children: text,
                attrs: {}
              }
              if ([1].includes(index)) {
                obj.attrs.rowSpan = 2
              }
              if ([0, 2].includes(index)) {
                obj.attrs.rowSpan = 0
                obj.attrs.colSpan = 0
              }
              return obj
            }
          }
        ]
      },
      table4: {
        columns: [
          {
            title: '',
            dataIndex: 'col1',
            align: 'center'
          },
          {
            title: '',
            dataIndex: 'col2',
            align: 'center'
          },
          {
            title: '',
            dataIndex: 'col3',
            align: 'center'
          },
          {
            title: '',
            dataIndex: 'col4',
            align: 'center'
          }
        ],
        data: []
      },
      tableRoc: {
        columns: [],
        data: []
      },
      roc: null,
      rocImg: null
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).Logic()
      this.y_name = req.args.form.xys[1][0].slice(-1)[0]
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
      // 获取roc的图片
      this.getRocPicture(req)
    },
    handleRes(res) {
      console.log('处理res')
      console.log('%c 🍿 res: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', res)
      // 从res中取出相应表格3中需要的值，并且构造表格。
      this.makeTable3(res)
      this.makeTable4(res)
      // 生成方程式
      get_formula(res.tables[1], this)
      return res
    },
    makeTable3(res) {
      const { table3 } = this
      const { y_name, predAry, key_value_map } = res.result
      const leftTop = predAry[0][0]
      const rightTop = predAry[0][1]
      const leftBottom = predAry[1][0]
      const rightBottom = predAry[1][1]
      const totalSum = leftTop + rightTop + leftBottom + rightBottom
      const percentTop = (leftTop * 100 / (leftTop + rightTop)).toFixed(1) + '%'
      const percentMiddle = (rightBottom * 100 / (leftBottom + rightBottom)).toFixed(1) + '%'
      const percentBottom = (((leftTop + rightBottom) * 100) / totalSum).toFixed(1) + '%'
      const percentLeft = change_to_percent(leftTop + leftBottom, totalSum)
      const percentBottomMiddle = change_to_percent(rightTop + rightBottom, totalSum)
      const data = [
        {
          key: 0,
          col1: translate('观测值'),
          col3: translate('预测值')
        },
        {
          key: 1,
          col3: y_name,
          col5: translate('正确百分比')
        },
        {
          key: 2,
          col3: key_value_map['0'],
          col4: key_value_map['1']
        },
        {
          key: 3,
          col1: y_name,
          col2: key_value_map['0'],
          col3: leftTop,
          col4: rightTop,
          col5: percentTop
        },
        {
          key: 4,
          col2: key_value_map['1'],
          col3: leftBottom,
          col4: rightBottom,
          col5: percentMiddle
        },
        {
          key: 5,
          col1: translate('准确率'),
          col3: percentLeft,
          col4: percentBottomMiddle,
          col5: percentBottom
        }
      ]
      table3.data = data
    },
    makeTable4(res) {
      const { table4 } = this
      const { predAry } = res.result
      const TN = predAry[0][0]
      const FP = predAry[0][1]
      const FN = predAry[1][0]
      const TP = predAry[1][1]
      // const P = TP + FN
      // const N = FP + TN
      const TPR = change_to_percent(TP, TP + FN)
      const FPR = change_to_percent(FP, FP + TN)
      const FNR = change_to_percent(FN, FN + TP)
      const TNR = change_to_percent(TN, FP + TN)
      const data = [
        {
          key: 0,
          col1: translate('灵敏度'),
          col2: TPR,
          col3: translate('假阳率'),
          col4: FPR
        },
        {
          key: 1,
          col1: translate('假阴率'),
          col2: FNR,
          col3: translate('特异度'),
          col4: TNR
        }
      ]
      table4.data = data
    },
    getRocPicture(req) {
      // 由于代码耦合，只能在调用ChartClass的文件里加一个roc的变量，并且赋值分类的名字，例如“男”
      this.roc = '' + req.args.form.yAs1
      console.log('%c 🍅 this.roc: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', this.roc);
      new ChartClass(
        JSON.parse(req.args.form.rocData),
        {
          ...req.args.form.rocArgs,
          change: false,
          isdownload: false
        }, 'roc', {}, []
      ).main(this, undefined).then(res => {
        this.rocImg = res.imgs[0].src

        this.tableRoc.columns = res.columns
        this.tableRoc.data = JSON.parse(res.tabdata)
      })
    }
  }
}
</script>