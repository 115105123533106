<template>
  <div>
    <h1 class="hCommon">概述</h1>
    <div class="itemWrapperCommon itemWrapperCommonPadding40">
      <a-row>
        <a-col span="11" >
          <a-row>
            <a-col span="24">
              <span class="htCommon">数据集统计</span>
            </a-col>
          </a-row>
          <divider-common></divider-common>
          <a-row>
            <a-col span="12">
              <span class="httCommon">变量数</span>
            </a-col>
            <a-col span="12">
              <span class="ttCommon">{{ data.fieldCount }}</span>
            </a-col>
          </a-row>
          <divider-common></divider-common>
          <a-row>
            <a-col span="12">
              <span class="httCommon">个案数</span>
            </a-col>
            <a-col span="12">
              <span class="ttCommon">{{ data.caseCount }}</span>
            </a-col>
          </a-row>

        </a-col>
        <a-col span="11" :offset="2">
          <a-row>
            <a-col span="24">
              <span class="htCommon">变量类型</span>
            </a-col>
          </a-row>
          <divider-common></divider-common>
          <a-row>
            <a-col span="12">
              <span class="httCommon">数值型</span>
            </a-col>
            <a-col span="12">
              <span class="ttCommon">{{ data.numberCount }}</span>
            </a-col>
          </a-row>
          <divider-common></divider-common>
          <a-row>
            <a-col span="12">
              <span class="httCommon">分类型</span>
            </a-col>
            <a-col span="12">
              <span class="ttCommon">{{ data.classCount }}</span>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import DividerCommon from './DividerCommon'
export default {
    components: { DividerCommon },
     props: {
        data: {
          type: Object,
          default: () => {}
        }
    }

}
</script>