var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-form", { attrs: { form: _vm.form, labelAlign: "left" } }, [
        _c(
          "div",
          { staticClass: "medicineWrapper" },
          _vm._l(_vm.medicines, function(medicine) {
            return _c(
              "div",
              { key: medicine.id },
              [
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: medicine.name,
                      labelCol: { span: 8 },
                      wrapperCol: { span: 8 }
                    }
                  },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            medicine.name,
                            { initialValue: medicine.amount }
                          ],
                          expression:
                            "[medicine.name,{initialValue:medicine.amount}]"
                        }
                      ],
                      attrs: { suffix: "g" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }