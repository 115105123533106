<template>
  <div>
    <div>
      <a-row v-for="(obj, i) in result" :key="i" :gutter="24">
        <a-col v-for="(p, j) in obj" :key="j" :xs="24" :sm="24" :md="24" :lg="24" :xl="obj.length === 1 ? 24 : 12">
          <Anysis :ref="`content,${indexT*count+i*(obj.length)+j}`" :tables="p.tables" :imgs="p.imgs" :indexT="i*(obj.length)+j">

          </Anysis>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import Anysis from '@/views/report/modules/basicStatistics/anysis'
import { getModule } from '@/api/module'
export default {
  components: { Anysis },
  props: ['data', 'xys', 'indexT', 'count'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    this.result = []
    getModule({
      module_name: 'BasicAnalysis',
      data: this.data,
      xy: this.xys,
      count: this.data ? this.data.length : 0,
      style: { language: this.$store.state.user.info.setting.language },
      method: this.xys.type,
      colors: JSON.parse(this.$store.state.user.info.setting.colors)
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '基础分析',
          msg: res
        })
      } else {
        console.log('result', res)
        this.result = res.result
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      result: [],
      imgsLayout: 2
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
