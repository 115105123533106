var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "30px 0"
      }
    },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { staticStyle: { padding: "0 30px" }, attrs: { span: 24 } },
                  [
                    _c("my-table2", {
                      attrs: {
                        showHeader: _vm.res.table.showHeader,
                        data: _vm.res.table.data,
                        columns: _vm.res.table.columns,
                        title: _vm.res.table.title,
                        size: _vm.res.table.size
                      }
                    }),
                    _c("div", {
                      staticStyle: {
                        height: "5px",
                        border: "1px solid rgb(232, 232, 232)"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              _vm._l(_vm.res.images, function(item, index) {
                return _c(
                  "a-col",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c("MyImg", {
                      attrs: { imgBytes: item, fileName: "拟合图" }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }