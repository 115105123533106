<template>
  <div style="padding: 10px">
    <a-row style="margin-bottom: 5px">
      <a-col :span="24">
        <h1>{{ title }}</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-table
          :showHeader="showHeader"
          :columns="columns"
          :data-source="data"
          bordered
          :pagination="false"
          :scroll="{ x: true }"
          :size="size"
        >
          <!-- <span slot="title">hhh</span> -->
          <span slot="p">
            <i>p</i>
          </span>
          <!-- <span slot="conf">
            div
          </span> -->
          <div v-for="item in columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>

export default {
  props: ['data', 'title', 'columns', 'scroll', 'showHeader', 'size'],
  data() {
    return {
    }
  }
}
</script>
<style lang="less">
th {
  // background-color:black !important;
}
// .colTitle
</style>