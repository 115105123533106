// import { deepClone } from '@/utils/util'
export var onChange = {
    liang(xys, xysIndex, item) {
        xys[xysIndex].push(item.name)
    },
    lei(xys, xysIndex, item) {
        xys[xysIndex] = xys[xysIndex].concat(item.options, item.name)
        // computed属性监听对象时候，若对象的引导地址未改变，那么computed将不会检测到。比如object中的某个key对应的value变化了，computed检测不出来。
        // computed属性监听数组时候，要改变数组的长度才能监听到。
        changeLength(xys)
    },
    manyXu(xys, xysIndex, item) {
       xys[xysIndex].push([item.optionInit, item.name])
    },
    manyLei(xys, xysIndex, item) {
        var leiList = [].concat(item.options, item.name)
        xys[xysIndex].push(leiList)
        changeLength(xys)
    }
}

export var onClose = {
    liang(xys, xysIndex, index) {
        xys[xysIndex].splice(index, 1)
    },
    lei(xys, xysIndex, index) {
        xys[xysIndex].length = 0
        changeLength(xys)
    },
    manyLei(xys, xysIndex, leiIndex) {
        xys[xysIndex].splice(leiIndex, 1)
        changeLength(xys)
    },
    manyXu(xys, xysIndex, xuIndex) {
        xys[xysIndex].splice(xuIndex, 1)
        changeLength(xys)
     }
}

// 这个方法用于改变所监听的数组的长度，让computed生效
function changeLength(list) {
    list.push('')
    list.pop()
}
