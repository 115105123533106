var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "auto" }, attrs: { id: "childContent" } },
    [
      _c(
        "keep-alive",
        [
          _vm.$route.meta.keepAlive
            ? _c(_vm.currentComponent, { tag: "component" })
            : _vm._e()
        ],
        1
      ),
      !_vm.$route.meta.keepAlive
        ? _c(_vm.currentComponent, { tag: "component" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }