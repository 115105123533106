import request from '@/utils/request'
// import requestPython from '@/utils/requestPython'
const api = {
  post: '/Hypothesis/GetHypothesisResult',
  python: '/python'
}

export function getExamFields(parameter) {
  return request({
    url: api.post,
    method: 'post',
    data: parameter
  })
}

export function getHypothesis(parameter) {
  return request({
    url: api.python + '/hypothesis',
    method: 'post',
    data: parameter
  })
}

export function getChart(parameter) {
  return request({
    url: api.python + '/chart',
    method: 'post',
    data: parameter
  })
}

export function getAnalysisOfRegression(parameter) {
  return request({
    url: api.python + '/analysisOfRegression',
    method: 'post',
    data: parameter
  })
}

export function computed(parameter) {
  return request({
    url: api.python + '/computed',
    method: 'post',
    data: parameter
  })
}