var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "divContent", attrs: { id: "divContent" } },
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: _vm.screenWidth > 1080 ? 3 : 4 } }, [
            _c(
              "div",
              {
                staticClass: "table-page-search-wrapper divContentleft",
                attrs: { id: "divContentleft" }
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-search": "",
                              "filter-option": _vm.filterOption,
                              placeholder: "请选择文件"
                            },
                            on: { change: _vm.selectChange },
                            model: {
                              value: _vm.queryParam.id,
                              callback: function($$v) {
                                _vm.$set(_vm.queryParam, "id", $$v)
                              },
                              expression: "queryParam.id"
                            }
                          },
                          _vm._l(_vm.questionnaires, function(item, i) {
                            return _c(
                              "a-select-option",
                              {
                                key: i,
                                attrs: { title: item.name, value: item.id }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "table-operator",
                        staticStyle: { "margin-top": "10px" }
                      },
                      [_c("span", [_vm._v("筛选变量")])]
                    ),
                    _c("a-table", {
                      ref: "table",
                      attrs: {
                        size: "small",
                        rowKey: "key",
                        columns: _vm.columns,
                        "data-source": _vm.data,
                        pagination: false,
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onSelectChange,
                          columnWidth: 30
                        },
                        scroll: { y: _vm.scrolly }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c(
            "a-col",
            {
              staticStyle: { "border-left": "1px solid rgb(232, 232, 232)" },
              attrs: { span: _vm.screenWidth > 1080 ? 21 : 20 }
            },
            [
              _c(
                "a-layout-content",
                { staticStyle: { padding: "20px 20px", margin: "0 auto" } },
                [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }