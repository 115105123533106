var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "缺失值处理结果另存为",
        visible: _vm.visible,
        maskClosable: false,
        confirmLoading: _vm.loading,
        okText: "确定",
        cancelText: "取消"
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { attrs: { form: _vm.form } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "另存为的文件名称" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.form.fileName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fileName", $$v)
                  },
                  expression: "form.fileName"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }