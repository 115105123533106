import execMathExpress from 'exec-mathexpress'

export default class GroupComputeClass {
  constructor(tables, groupCompute) {
    this.tables = tables
    this.groupCompute = groupCompute
  }

  平均值(paramObj) {
    const tables = this.tables
    const keys = JSON.parse(paramObj.keys)
    const table = tables.find(table => table.data[0].组别 === keys[0])
    const list = table.data.map(obj => obj[keys[1]])
    if (list.every(v => v || v === 0)) {
      const mean = list.reduce((a, b) => a + b) / list.length
      return mean
    }
    return ''
  }

  公式(paramObj) {
    const map = JSON.parse(paramObj.map)
    const groupCompute = this.groupCompute
    const obj = {}
    map.forEach(v => {
      const key = v.value
      const x = groupCompute.find(g => `${g.name}(${g.unit})` === v.name)
      const value = x.value
      obj[key] = value
    })
    Object.keys(obj).forEach(v => {
      if (paramObj.formula.indexOf(v) === -1) delete obj[v]
    })
    if (Object.values(obj).length > 0 && Object.values(obj).every(v => v !== 'NaN' && v && v !== '0')) {
      const result = this.exec(paramObj.formula, obj)
      return result
    } else {
      return ''
    }
  }

  exec(formula, map) {
    const exe = execMathExpress(formula, map)
    const result = exe.num / exe.den
    return result
  }
}