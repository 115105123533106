<template>
  <page-header-wrapper :breadcrumb="null">
    <a-card :bordered="false" id="missvalue">
      <div>
        <a-button type="primary" @click="onBack">&lt;&lt; 返 回</a-button>
      </div>
      <div style="margin-top:20px;width:810px">
        <a-table bordered size="small" :pagination="false" :dataSource="data" :columns="columns" :loading="loading">
          <span slot="null" slot-scope="text">
            <span>{{ (text!==""&&text!==null&&text!=='NaN') ? text : '-' }}</span>
          </span>
        </a-table>
      </div>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { GetData, GetQuestionData, GetExperimentData, GetExperimentData2, GetFileData } from '@/api/screendata'
const columns = [
  {
    title: '变量名',
    dataIndex: 'fieldName',
    key: 'fieldName',
    align: 'left',
    width: '250px'
  },
  {
    title: 'N',
    dataIndex: 'number',
    key: 'number',
    align: 'right',
    width: '80px'
  },
  {
    title: '平均值',
    dataIndex: 'avg',
    key: 'avg',
    scopedSlots: { customRender: 'null' },
    align: 'right',
    width: '80px'
  },
  {
    title: '标准偏差',
    dataIndex: 'std',
    key: 'std',
    scopedSlots: { customRender: 'null' },
    align: 'right',
    width: '80px'
  },
  {
    title: '缺失',
    children: [
      {
        title: '计数',
        dataIndex: 'missN',
        key: 'missN',
        align: 'right',
        width: '80px'
      },
      {
        title: '百分比',
        dataIndex: 'missP',
        key: 'missP',
        align: 'right',
        width: '80px'
      }
    ]
  },
  {
    title: '极值数目',
    children: [
      {
        title: '低',
        dataIndex: 'minN',
        key: 'minN',
        scopedSlots: { customRender: 'null' },
        align: 'right',
        width: '80px'
      },
      {
        title: '高',
        dataIndex: 'maxN',
        key: 'maxN',
        scopedSlots: { customRender: 'null' },
        align: 'right',
        width: '80px'
      }
    ]
  }
]
export default {
  data() {
    return {
      columns,
      data: [],
      loading: false
    }
  },
  created() {
    this.loading = true
    const type = this.$store.state.samples.type
    if (type) {
    const fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
    if (type === 'document') {
      GetData({
        fields: fields,
        selectRows: JSON.parse(this.$store.state.samples.keys),
        orgid: this.$store.state.user.info.orgid,
        isMissVale: true
      }).then(res => {
        this.data = JSON.parse(res.data[0])
        this.loading = false
})
    } else if (type === 'questionnaire') {
      // 说明key值是long类型
      GetQuestionData({
        fields: fields,
        selectRows: JSON.parse(this.$store.state.samples.keys),
        questionnaireID: this.$store.state.samples.questionnaireID,
        orgid: this.$store.state.user.info.orgid,
        isMissVale: true
      }).then(res => {
        this.data = JSON.parse(res.data[0])
        this.loading = false
      })
    } else if (type === 'experiment') {
      var guid = this.$store.state.samples.experimentGuid
      var expid = this.$store.state.samples.experimentID
      if (guid === '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb') {
        GetExperimentData({
          fields: fields,
          selectRowsexp: JSON.parse(this.$store.state.samples.keys),
          questionnaireID: expid,
          orgid: this.$store.state.user.info.orgid,
          isMissVale: true
        }).then(res => {
          this.data = JSON.parse(res.data[0])
        this.loading = false
        })
      } else if (guid === '61bd643e-a81a-439b-94d6-61dac11a1b66') {
        GetExperimentData2({
          fields: fields,
          selectRowsexp: JSON.parse(this.$store.state.samples.keys),
          questionnaireID: expid,
          orgid: this.$store.state.user.info.orgid,
          isMissVale: true
        }).then(res => {
          this.data = JSON.parse(res.data[0])
        this.loading = false
        })
      }
    } else if (type === 'file') {
      GetFileData({
        fields: fields,
        selectRows: JSON.parse(this.$store.state.samples.keys),
        questionnaireID: this.$store.state.samples.fileid,
        orgid: this.$store.state.user.info.orgid,
        isMissVale: true
      }).then(res => {
        this.data = JSON.parse(res.data[0])
        this.loading = false
      })
    }
    } else {
      this.$router.back()
    }
  },
  methods: {
    onBack() {
      this.$router.back()
    }
  }
}
</script>