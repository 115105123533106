<template>
  <a-layout class="divContent" id="divContent">
    <a-row>
      <a-col :span="screenWidth > 1080 ? 3 : 4">
        <div class="table-page-search-wrapper divContentleft" id="divContentleft">
          <div>
            <div v-if="role === 'admin'">
              <a-select show-search :filter-option="filterOption" @change="
                (e) => {
                  selectChangeModel(e, 'select')
                }
              " v-model="queryParam.modelid" style="width: 100%" placeholder="请选择量表(问卷)模版">
                <a-select-option :title="item.name" v-for="(item, i) in questionModels" :key="i" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </div>
            <div v-if="role === 'admin'" style="margin-top: 10px">
              <a-select show-search :filter-option="filterOption" mode="multiple" @change="selectChange"
                        v-model="queryParam.id" style="width: 100%" placeholder="请选择量表(问卷)">
                <a-select-opt-group :title="item.orgName" v-for="(item, i) in orgquestionnaires" :key="i"
                                    :label="item.orgName">
                  <a-select-option :title="option.title" v-for="(option, j) in item.questionnaires" :key="j"
                                   :value="option.id">{{ option.title }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </div>
            <div v-if="role !== 'admin'">
              <a-select @change="selectChange" v-model="queryParam.id" style="width: 100%" placeholder="请选择量表(问卷)">
                <a-select-option :title="item.title" v-for="(item, i) in questionnaires" :key="i" :value="item.id">{{
                  item.title
                }}</a-select-option>
              </a-select>
            </div>
            <div class="table-operator" style="margin-top: 10px">
              <span>筛选变量</span>
              <!-- <a style="float: right; text-decoration: underline" @click="dataHandle()">变量处理</a> -->
            </div>
            <a-table ref="table" size="small" rowKey="key" :columns="columns" :data-source="data" :pagination="false"
                     :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 30 }"
                     :scroll="{ y: scrolly }"></a-table>
            <!-- <a-button type="primary" style="margin-top:10px;float:right" @click="submitChoose">确认选择</a-button> -->
          </div>
        </div>
      </a-col>
      <a-col :span="screenWidth > 1080 ? 21 : 20" style="border-left: 1px solid rgb(232, 232, 232)">
        <a-layout-content style="padding: 20px 20px; margin: 0 auto">
          <router-view v-if="isRouterAlive"></router-view>
        </a-layout-content>
      </a-col>
    </a-row>
  </a-layout>
</template>
<script>
import { getQuestionFields, GetQuestionnaire } from '@/api/screendata'

import { GetQuestionnaireModel, GetOrgQuestionnaire } from '@/api/questionnaire'
import { AutoHeight } from '@/utils/util'

const columns = [
  {
    title: '变量名',
    dataIndex: 'fieldName',
    key: 'key'
  }
]

export default {
  components: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    this.columns = columns
    return {
      screenWidth: document.body.clientWidth,
      // 查询参数
      queryParam: {},
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
      },
      pagination: {
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: false, // 是否可以快速跳转至某页
        showSizeChanger: false // 是否可以改变 pageSize
      },
      // 是否点击了新建按钮
      isNewAdd: false,
      selectedRowKeys: [],
      data: [],
      questionnaires: [],
      orgquestionnaires: [],
      questionModels: [],
      isRouterAlive: true,
      scrolly: window.screen.height > 800 ? 550 : 400,
      role: this.$store.state.user.roles,
      modelid: null
    }
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  created() {
    // 高度自定义使用
    this.autoHeightChange()
    const fields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
    const type = this.$store.state.samples.type
    // 如果身份是管理员的话，需要将当前机构及子机构的模板显示出来
    if (this.role === 'admin') {
      if (this.$store.state.samples.questionnaireModelID) {
        this.queryParam.modelid = this.$store.state.samples.questionnaireModelID
      }
      GetQuestionnaireModel({ id: this.$store.state.user.info.orgid }).then(res => {
        this.questionModels = res
        if (this.queryParam.modelid) {
          this.$store.dispatch('updateSamples', {
            fileName: this.questionModels.filter(r => r.id === this.queryParam.modelid)[0].name
          })
        }
      })
      if (this.queryParam.modelid) {
        this.selectChangeModel(this.queryParam.modelid, 'create')
      }
    } else {
      if (this.$store.state.samples.questionnaireID) {
        this.queryParam.id = JSON.parse(this.$store.state.samples.questionnaireID)[0]
      }
      if (type === 'questionnaire') {
        if (fields) {
          fields.map(r => {
            this.selectedRowKeys.push(r.key)
          })
        }
      }
      GetQuestionnaire({
        ids: this.queryParam.id,
        orgid: this.$store.state.user.info.orgid,
        userid: this.$store.state.user.info.id,
        trial: this.$store.state.user.info.trial

      }).then(res => {
        console.log('questionnaire', res)
        this.questionnaires = res
      })
      if (this.queryParam.id) {
        getQuestionFields({
          idsarry: [this.queryParam.id],
          orgid: this.$store.state.user.info.orgid,
          userid: this.$store.state.user.info.id,
          role: this.role
        }).then(res => {
          this.data = res.fields
          if (type === 'questionnaire') {
            if (fields && fields.length > 0) {
              this.$store.dispatch('updateSamples', {
                fields: JSON.stringify(res.filter(r => this.selectedRowKeys.indexOf(r.key) > -1))
              })
            }
          }
        })
      }
    }

    this.skip()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    skip(reload = false) {
      const roles = this.$store.state.user.roles
      const pathname = window.location.pathname
      if (this.selectedRowKeys.length === 0) {
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push(`/hypothesistest/screendata/${roles === 'person' ? 'file' : 'questionnaire'}/nodata`)
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push(`/customchart/screendata/${roles === 'person' ? 'file' : 'questionnaire'}/nodata`)
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push(`/intelligenceReports/screendata/${roles === 'person' ? 'file' : 'questionnaire'}/nodata`)
        } else {
          this.$router.push(`/analysisOfRegression/screendata/${roles === 'person' ? 'file' : 'questionnaire'}/nodata`)
        }
      } else {
        var questionid = ''
        if (this.role === 'admin') {
          questionid = JSON.stringify(this.queryParam.id)
        } else {
          questionid = JSON.stringify([this.queryParam.id])
        }
        if (pathname.indexOf('hypothesistest') > -1) {
          this.$router.push(`/hypothesistest/screendata/questionnaire/data/${questionid}`)
        } else if (pathname.indexOf('customchart') > -1) {
          this.$router.push(`/customchart/screendata/questionnaire/data/${questionid}`)
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push(`/intelligenceReports/screendata/questionnaire/data/${questionid}`)
        } else {
          this.$router.push(`/analysisOfRegression/screendata/questionnaire/data/${questionid}`)
        }
        if (reload) {
          this.reload()
        }
      }
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      // 高度自定义使用
      AutoHeight(this, ['divContent', 'divContentleft'], [], 215)
    },
    /**
     * 模型选择change
     * selectOrCreate// 判断是手动选的，还是返回的时候读的缓存
     */
    selectChangeModel(e, selectOrCreate) {
      this.modelid = e
      GetOrgQuestionnaire({ modeid: e, orgid: this.$store.state.user.info.orgid }).then(res => {
        if (selectOrCreate === 'select') {
          this.queryParam.id = undefined
          this.selectedRowKeys = []
          this.data = []
          // this.$store.dispatch('updateSamples', { questionnaireID: '' })

          this.skip()
        } else {
          if (this.$store.state.samples.questionnaireID) {
            this.queryParam.id = JSON.parse(this.$store.state.samples.questionnaireID)
            if (this.queryParam.id) {
              const fields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
              if (fields) {
                fields.map(r => {
                  this.selectedRowKeys.push(r.key)
                })
              }
              getQuestionFields({
                idsarry: this.queryParam.id,
                orgid: this.$store.state.user.info.orgid,
                userid: this.$store.state.user.info.id,
                role: this.role
              }).then(res => {
                this.data = res.fields
              })

              this.skip()
            }
          }
        }
        this.orgquestionnaires = res
      })
    },

    /**
     * 变量处理
     */
    dataHandle() {
      var id = this.$store.state.samples.questionnaireID
      if (id) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/dataHandle/questionnaire/rangecode')
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push('/customchart/dataHandle/questionnaire/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push('/intelligenceReports/dataHandle/questionnaire/rangecode')
        } else {
          this.$router.push('/analysisOfRegression/dataHandle/questionnaire/rangecode')
        }
      } else {
        this.$message.warning('请选择一个量表(问卷)')
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys

      this.$store.dispatch('updateSamples', { questionnaireModelID: this.modelid })
      if (this.questionModels.length > 0) {
        this.$store.dispatch('updateSamples', { fileName: this.questionModels.filter(r => r.id === this.modelid)[0].name })
      }
      this.$store.dispatch('updateSamples', { type: 'questionnaire' })
      this.$store.dispatch('updateSamples', { fields: JSON.stringify(selectedRows) })

      this.submitChoose()
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    selectChange(e) {
      this.$store.dispatch('updateSamples', { sourceModule: '量表(问卷)数据' })
      this.queryParam = Object.assign({ id: e }, this.queryParam)
      // 把存放问卷名称的数组提交到vuex中去
      if (Array.isArray(e)) { // 如果e是数组
        // 定义一个存放名字的数组
        const qesNameAry = []
        // 通过id来找到名字
        e.forEach(id => {
          this.orgquestionnaires.forEach(item => {
            item.questionnaires.forEach(option => {
              if (option.id === id) qesNameAry.push(option.title)
            })
          })
        })
        this.$store.dispatch('updateSamples', { qesNameAry })
      } else {
        this.$store.dispatch('updateSamples', { qesNameAry: ['量表(问卷)数据'] })
      }
      this.$store.dispatch('updateSamples', { fields: '' })
      this.$store.dispatch('updateSamples', { keys: '' })
      this.$store.dispatch('updateSamples', { experimentID: null })
      this.$store.dispatch('updateSamples', { fileid: null })
      // 缺失值处理数据用的
      if (this.role !== 'admin') {
        this.$store.dispatch('updateSamples', { fileName: this.questionnaires.filter(r => r.id === e)[0].title })
      }

      this.selectedRowKeys = []

      if ((e instanceof Array && e.length > 0) || (!(e instanceof Array) && e)) {
        if (this.role === 'admin') {
          this.$store.dispatch('updateSamples', { questionnaireID: JSON.stringify(e) })
        } else {
          this.$store.dispatch('updateSamples', { questionnaireID: JSON.stringify([e]) })
        }
        getQuestionFields({
          idsarry: e instanceof Array ? e : [e],
          orgid: this.$store.state.user.info.orgid,
          userid: this.$store.state.user.info.id,
          trial: this.$store.state.user.info.trial,
          role: this.role
        }).then(res => {
          this.data = res.fields
          this.questionnaires = res.questionnaires
        })
      }
      // 切换量表(问卷)后将数据也清空
      const pathname = window.location.pathname
      if (pathname.indexOf('hypothesistest') !== -1) {
        this.$router.push('/hypothesistest/screendata/questionnaire/nodata')
      } else if (pathname.indexOf('customchart') !== -1) {
        this.$router.push('/customchart/screendata/questionnaire/nodata')
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        this.$router.push('/intelligenceReports/screendata/questionnaire/nodata')
      } else {
        this.$router.push('/analysisOfRegression/screendata/questionnaire/nodata')
      }
    },
    submitChoose() {
      this.$store.dispatch('changeField')
      this.skip(true)
    }
  }
}
</script>
<style lang="less" scoped>
body {
  overflow: auto !important;
}
</style>