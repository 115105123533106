var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "box-shadow-card",
    {
      staticStyle: { width: "450px", "min-height": "0px !important" },
      attrs: { title: _vm.content.title }
    },
    [
      _c(
        "div",
        _vm._l(_vm.content.content, function(line, index) {
          return _c(
            "p",
            { key: index, domProps: { innerHTML: _vm._s(line) } },
            [_vm._v(_vm._s(line))]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }