<template>
  <div>
    <div :ref="`title,1`" :id="`3.${secondIndex}.1 检验`" class="third-title">3.{{ secondIndex }}.1 检验</div>
    <div>
      <ChiSquare
        :ref="`content,1`"
        :xys="xys"
        :data="data"
        :flowChiSquareNow="flow_chiSquare_now"
        @mainP="(x) => (mainP = x)"
      >
        <template #textFront class="text">
          <p :ref="`text,1,1`">
            <span v-if="flow_chiSquare_now === 'auto'">
              <span v-if="data.pyMethod === 'Pearson'">
                <span v-if="dimension[0] === 2 && dimension[1] === 2">
                  由于{{ leiName1 }}与{{ leiName2 }}中的类别数都等于2，样本数 ≥ 40且所有理论频数 ≥
                  5，因此使用Pearson算法</span
                  >
                <span v-if="(dimension[0] >= 2 && dimension[1] > 2) || (dimension[1] >= 2 && dimension[0] > 2)">
                  由于{{ leiName1 }}与{{ leiName2 }}的所有理论频数 ≥ 1且理论频数 &lt; 5的格子的比例 &lt;
                  20%，因此使用Pearson算法</span
                >
              </span>
              <span v-if="data.pyMethod === 'yates'">
                <span v-if="dimension[0] === 2 && dimension[1] === 2">
                  由于{{ leiName1 }}与{{ leiName2 }}中的类别数都等于2，样本数 ≥ 40且所有理论频数 >
                  1，且至少一个理论数出现1 ≤ E &lt; 5，因此使用yates校正算法</span
                  >
                <span v-if="(dimension[0] >= 2 && dimension[1] > 2) || (dimension[1] >= 2 && dimension[0] > 2)">
                  由于{{ leiName1 }}与{{ leiName2 }}中的所有理论频数 &lt; 1 或 理论频数 &lt;
                  5的格子的比例超过20%，因此使用yates校正算法</span
                >
              </span>
              <span v-if="data.pyMethod === 'Fisher'">
                由于{{ leiName1 }}与{{ leiName2 }}中的理论频数 &lt; 1或 样本数 &lt; 40，则使用Fisher’s确切概率法检验
              </span>
              <span v-if="data.pyMethod === ''"></span>
            </span>
            <span v-else> 使用{{ flow_chiSquare_now }}算法</span>
            <span
            >进行卡方检验，<br /><span class="H0H1">H0：{{ leiName1 }}与{{ leiName2 }}不具有关联性。</span><br /><span
              class="H0H1"
            >H1：{{ leiName1 }}与{{ leiName2 }}具有关联性。</span
            ><br />检验结果如下表所示：</span
            >
          </p>
        </template>
        <template #textBack class="text">
          <Conclusion>
            <p :ref="`text,1,3`">
              <span
                v-html="
                  $hypothesis.conclusion(
                    mainP,
                    $store.state.report.a,
                    `${leiName1}与${leiName2}不具有关联性`,
                    `${leiName1}与${leiName2}具有关联性`
                  )
                "
              ></span>
            </p>
          </Conclusion>
        </template>
      </ChiSquare>
    </div>
  </div>
</template>
<script>
import ChiSquare from '@/views/report/modules/hypothesis/chiSquare'
export default {
  components: { ChiSquare },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  data() {
    return {
      mainP: undefined
    }
  },
  computed: {
    dimension() {
      return this.data.dimension
    },
    leiName1() {
      return this.xys[0][0].slice(-1)[0]
    },
    leiName2() {
      return this.xys[1][0].slice(-1)[0]
    },
    flow_chiSquare_now() {
      // 配置中流程当前选择的方法的value值
      return this.settings.options.flows.find(v => v.label === '算法').value
    }
  }
}
</script>