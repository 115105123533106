<template>
  <a-row style=" border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'描述性分析结果'"></myTable>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="part2.table.data" :columns="part2.table.columns" :title="'主体间效应检验'"></myTable>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'重复测量方差分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      method2: this.$hypothesis.setColumns.common,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[''], ['']],
        pList: [],
        table: { data: [], columns: [] }
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, [''], this.setSecondTable)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.Lei1LiangDuo()
    },
    setSecondTable(res) {
      var columns = res.part2.table.columns
      this.method2(columns)
    }
  },
  computed: {
    Xys() {
      return this.part2.xys
    },
    liang() { return `&lt;${this.part2.liang2}&gt;` },
    options() {
      if (this.Xys[0].length > 0) {
        var list = this.$deepClone(this.Xys[0][0])
        list.pop()
        return list.map(v => `&lt;${v}&gt;`).join('')
      }
      return ''
    },
    lei() { return `&lt;${this.Xys[0].length && this.Xys[0][0].slice(-1)[0]}&gt;` },
    H0() {
      return [
        `${this.options}这${(this.Xys[0][0] || []).length - 1}组患者的${this.liang}均值相等`,
        `不同时间点患者的${this.liang}均值全相等`,
        `${this.lei}和时间因素无交互作用`
      ]
    },
    H1() {
      return [
        `${this.options}这${(this.Xys[0][0] || []).length - 1}组患者的${this.liang}均值不全相等`,
        `不同时间点患者的${this.liang}均值不全相等`,
        `${this.lei}和时间因素有交互作用`
      ]
    },
    result() {
      if (this.Xys[0].length === 0) {
        return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[1], this.H1[1])}`]
      } else {
        var list = []
        this.part2.pList.forEach((p, index) => {
          list.push(`${index + 1}.${this.$hypothesis.conclusion(p, this.part2.a, this.H0[index], this.H1[index])}`)
        })
        return list
      }
    }
  }
}
</script>