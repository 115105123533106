var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "30px 30px"
      }
    },
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "div",
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    _vm._l(_vm.res.tables, function(table, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { "margin-bottom": "20px" }
                        },
                        [
                          _c("my-table2", {
                            attrs: {
                              showHeader: table.showHeader,
                              data: table.data,
                              columns: table.columns,
                              title: table.title,
                              size: table.size
                            }
                          }),
                          _c("div", {
                            staticStyle: {
                              height: "5px",
                              border: "1px solid rgb(232, 232, 232)"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "a-row",
                _vm._l(_vm.res.images, function(item, index) {
                  return _c(
                    "a-col",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      },
                      attrs: { span: 24 }
                    },
                    [
                      _c("MyImg", {
                        attrs: { imgBytes: item, fileName: "碎石图" }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          "tables" in _vm.res
            ? _c(
                "box-shadow-card",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "主成分和各研究项之间的关系" }
                },
                [
                  _c(
                    "div",
                    _vm._l(_vm.getConclusion(), function(item, index) {
                      return _c("p", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }