var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.gpxys, function(gp, secondIndex) {
      return _c("div", { key: secondIndex }, [
        gp.type == "vc"
          ? _c(
              "div",
              [
                _c(
                  "div",
                  {
                    ref: "title",
                    refInFor: true,
                    staticClass: "second-title",
                    attrs: {
                      id: "2." + (secondIndex + 1) + " 定类指标与定量指标的关系"
                    }
                  },
                  [
                    _vm._v(
                      " 2." +
                        _vm._s(secondIndex + 1) +
                        " 定类指标与定量指标的关系 "
                    )
                  ]
                ),
                _c("Conclusion", [
                  _c("p", { ref: "text", refInFor: true }, [
                    _vm._v(
                      " 从抖动图、小提琴图和密度图上可以看出不同“定类指标”下“定量指标”的分布和概率密度。箱线图展示了不同“定类指标”下 “定量指标”的整体分布和异常数据情况。误差条图则体现了不同“定类指标”下“定量指标”的抽样误差。 "
                    )
                  ])
                ]),
                _c("vcfield", {
                  ref: "content",
                  refInFor: true,
                  staticClass: "second-module",
                  staticStyle: { padding: "0 25px" },
                  attrs: { gp: gp, indexT: secondIndex + 1 }
                })
              ],
              1
            )
          : _vm._e(),
        gp.type == "vv"
          ? _c(
              "div",
              [
                _c(
                  "div",
                  {
                    ref: "title",
                    refInFor: true,
                    staticClass: "second-title",
                    attrs: {
                      id: "2." + (secondIndex + 1) + " 定类指标与定量指标的关系"
                    }
                  },
                  [
                    _vm._v(
                      " 2." +
                        _vm._s(secondIndex + 1) +
                        " 定量指标与定量指标的关系 "
                    )
                  ]
                ),
                _c("Conclusion", [
                  _c("p", { ref: "text", refInFor: true }, [
                    gp.xys.filter(function(r) {
                      return r.data.length > 300
                    }).length > 0
                      ? _c("span", [
                          _vm._v(
                            " 从密度散点图上可以看出“定量指标”与“定量指标”之间的关系及数据点的分布密度，红色的区域表示分析数据的密度大，蓝色的区域表示分析数据的密度小。 "
                          )
                        ])
                      : _vm._e(),
                    gp.xys.filter(function(r) {
                      return r.data.length <= 300
                    }).length > 0
                      ? _c("span", [
                          _vm._v(
                            " 从折线图上可以看出变化趋势。从散点图可以看出数据点在直角坐标系上的分布情况。 "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("vvfield", {
                  ref: "content",
                  refInFor: true,
                  staticClass: "second-module",
                  staticStyle: { padding: "0 25px" },
                  attrs: { gp: gp, indexT: secondIndex + 1 }
                })
              ],
              1
            )
          : _vm._e(),
        gp.type == "cc"
          ? _c(
              "div",
              [
                _c(
                  "div",
                  {
                    ref: "title",
                    refInFor: true,
                    staticClass: "second-title",
                    attrs: {
                      id:
                        "2." +
                        (secondIndex + 1) +
                        " 定类指标与" +
                        gp.xys[0].y[0].name +
                        "的关系"
                    }
                  },
                  [
                    _vm._v(
                      " 2." + _vm._s(secondIndex + 1) + " 两个定类指标的关系 "
                    )
                  ]
                ),
                _c("Conclusion", [
                  _c("p", { ref: "text", refInFor: true }, [
                    _vm._v(
                      " 由饼图和柱状图可以看出两个“定类指标”之间的分布情况。 "
                    )
                  ])
                ]),
                _c("vcfield", {
                  ref: "content",
                  refInFor: true,
                  staticClass: "second-module",
                  staticStyle: { padding: "0 25px" },
                  attrs: { gp: gp, indexT: secondIndex + 1 }
                })
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }