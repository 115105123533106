var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", justify: "center" } },
    [_c("a-col", [_c("img", { attrs: { width: _vm.width, src: _vm.src } })])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }