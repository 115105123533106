var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "index",
    {
      attrs: {
        xysName: _vm.xysName,
        xys: _vm.xys,
        formAddKeyName: _vm.formAddKeyName,
        pForm: _vm.form,
        pSettings: _vm.settings
      },
      on: { click: _vm.onClick },
      scopedSlots: _vm._u([
        {
          key: "H0",
          fn: function(ref) {
            var xys = ref.xys
            var form = ref.form
            return [
              xys[0].length > 0 && xys[1].length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.H0(xys, form), function(item, index) {
                      return _c("span", { key: index }, [
                        _c("span", { domProps: { innerHTML: _vm._s(item) } })
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ]
          }
        },
        {
          key: "H1",
          fn: function(ref) {
            var xys = ref.xys
            var form = ref.form
            return [
              xys[0].length > 0 && xys[1].length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.H1(xys, form), function(item, index) {
                      return _c("span", { key: index }, [
                        _c("span", { domProps: { innerHTML: _vm._s(item) } })
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ]
          }
        }
      ])
    },
    [_vm._v("template ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }