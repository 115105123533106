<template>
  <div>
    <!-- 第一行 -->
    <a-row :gutter="24">
      <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="档案数量" :total="docCount | NumberFormat">
          <!-- <a-tooltip title="指标说明" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip> -->
          <div>
            <mini-area :madata="docdata" />
          </div>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="量表(问卷)数量" :total="qCount | NumberFormat">
          <div>
            <mini-bar :mbdata="quedata" color="#36cbcb" />
          </div>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="答卷数量" :total="aCount | NumberFormat">
          <div>
            <mini-area :madata="ansdata" color="#6bc3e1" />
          </div>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="文件数量" :total="fileCount | NumberFormat">
          <div>
            <mini-bar :mbdata="filedata" color="#5f90e3" />
          </div>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="实验数量" :total="experCount | NumberFormat">
          <div>
            <mini-bar :mbdata="expdata" color="#81d385" />
          </div>
        </chart-card>
      </a-col>
    </a-row>
    <!-- 第二行 -->
    <div class="salesCard">
      <a-row>
        <!-- 左侧5 -->
        <a-col :xl="5" :md="24" :sm="24" class="paddingright">
          <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
            <a-row>
              <div>
                <mini-ve-ring :data="docchartData" :color="docColor" :graphic="docgraphic" />
              </div>
            </a-row>
            <a-row>
              <div>
                <mini-ve-ring :data="quechartData" :color="queColor" :graphic="quegraphic" />
              </div>
            </a-row>
            <a-row>
              <div>
                <mini-ve-ring :data="filechartData" :color="fileColor" :graphic="filegraphic" />
              </div>
            </a-row>
            <!-- <a-row>
              <div>
                <v-chart :force-fit="true" :height="250" :data="que" :scale="pieScale">
                  <v-tooltip :showTitle="false" data-key="item*percent" title="123" />
                  <v-axis />
                  <v-legend data-key="item" />
                  <v-pie position="percent" color="item" :vStyle="pieStyle" />
                  <v-coord type="theta" :radius="0.75" :innerRadius="0.6" />
                </v-chart>
              </div>
            </a-row> -->
          </a-card>
        </a-col>
        <!-- 右侧20 -->
        <a-col :xl="20" :md="24" :sm="24">
          <!-- 右侧第一行 -->
          <a-row :style="{ marginBottom: '24px' }">
            <a-col :span="12" class="padding22">
              <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
                <a-tabs
                  size="small"
                  :tab-bar-style="{ marginBottom: '10px', paddingLeft: '16px' }"
                  :tab-active-key="tabActiveKey"
                >
                  <a-tab-pane loading="true" tab="档案数量" key="1">
                    <bar :data="barData" title />
                  </a-tab-pane>
                </a-tabs>
              </a-card>
            </a-col>
            <a-col :span="12" class="padding23">
              <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
                <a-tabs
                  size="small"
                  :tab-bar-style="{ marginBottom: '10px', paddingLeft: '16px' }"
                  :tab-active-key="tabActiveKey"
                >
                  <a-tab-pane loading="true" tab="量表（问卷）答卷数量" key="1">
                    <!-- v-if="showDocChart" -->
                    <bar :data="barData2" title />
                  </a-tab-pane>
                </a-tabs>
              </a-card>
            </a-col>
          </a-row>
          <!-- 右侧第二行 -->
          <a-row>
            <a-col :span="24" style="padding-left: 5px">
              <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
                <rank-list
                  title="现有量表(问卷)"
                  :list="rankList"
                  :style="{ marginTop: '14px', paddingLeft: '26px' }"
                />
              </a-card>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  ChartCard,
  MiniArea,
  MiniBar,
  MiniProgress,
  RankList,
  Area,
  Bar,
  Trend,
  NumberInfo,
  MiniSmoothArea,
  MiniVeRing
} from '@/components'
import { baseMixin } from '@/store/app-mixin'
import { getCard, getChartInfo, getVeRing } from '@/api/home'

const searchUserData = []
for (let i = 0; i < 7; i++) {
  searchUserData.push({
    x: moment()
      .add(i, 'days')
      .format('YYYY-MM-DD'),
    y: Math.ceil(Math.random() * 10)
  })
}
const searchUserScale = [
  {
    dataKey: 'x',
    alias: '时间'
  },
  {
    dataKey: 'y',
    alias: '用户数',
    min: 0,
    max: 10
  }
]

const searchData = []

const DataSet = require('@antv/data-set')

// const sourceData = [
//   { item: '档案占比', count: 30 },
//   { item: '量表(问卷)占比', count: 55 },
//   { item: '其他占比', count: 13 }
// ]

const pieScale = [
  {
    dataKey: 'percent',
    min: 0,
    formatter: '.0%'
  }
]
export default {
  name: 'Analysis',
  mixins: [baseMixin],
  components: {
    ChartCard,
    MiniArea,
    MiniBar,
    MiniProgress,
    RankList,
    Bar,
    Area,
    Trend,
    NumberInfo,
    MiniSmoothArea,
    MiniVeRing
  },
  data() {
    return {
      tabActiveKey: '',
      docColor: ['#5ab1ef', '#E3E3E3'],
      queColor: ['#36cbcb', '#E3E3E3'],
      fileColor: ['#5f90e3', '#E3E3E3'],
      capdata: [],
      doc: [],
      que: [],
      exam: [],
      file: [],
      docdata: [],
      quedata: [],
      ansdata: [],
      filedata: [],
      expdata: [],
      madata: [],
      mbdata: [],
      loading: true,
      // 量表(问卷)列表
      rankList: [],
      docCount: 0,
      // docCountthisweek: 0,
      // docCountthisweekP: 0,
      // docCounttodayP: 0,
      qCount: 0,
      aCount: 0,
      experCount: 0,
      fileCount: 0,
      // qCountConfirm: 0,
      // qCountConfirmP: 0,
      totalChart: 0,
      todayChart: 0,
      totalHy: 0,
      todayHy: 0,
      // 搜索用户数
      searchUserData,
      searchUserScale,
      // searchTableColumns,
      searchData,

      barData: [],
      sourceData: [],
      barData2: [],
      //
      pieScale,
      pieData: [],
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1
      },
      docchartData: {
        columns: ['item', 'count'],
        rows: []
      },
      docgraphic: [
        {
          type: 'text',
          left: 'center',
          top: '36.5%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#000',
            fontSize: 25
          }
        },
        {
          type: 'text',
          left: 'center',
          top: '48%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#999999',
            fontSize: 15,
            color: '#4d4f5c'
          }
        }
      ],
      filechartData: {
        columns: ['item', 'count'],
        rows: []
      },
      filegraphic: [
        {
          type: 'text',
          left: 'center',
          top: '36.5%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#000',
            fontSize: 25
          }
        },
        {
          type: 'text',
          left: 'center',
          top: '48%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#999999',
            fontSize: 15,
            color: '#4d4f5c'
          }
        }
      ],
      quechartData: {
        columns: ['item', 'count'],
        rows: []
      },
      quegraphic: [
        {
          type: 'text',
          left: 'center',
          top: '36.5%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#000',
            fontSize: 25
          }
        },
        {
          type: 'text',
          left: 'center',
          top: '48%',
          style: {
            text: '',
            textAlign: 'center',
            fill: '#999999',
            fontSize: 15,
            color: '#4d4f5c'
          }
        }
      ]
    }
  },

  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      var orgid = this.$store.state.user.info.orgid
      var userid = this.$store.state.user.info.id
      var org = this.$store.state.user.info.org
      var trial = this.$store.state.user.info.trial
      this.showDocChart = true
      // 默认是档案，如果档案隐藏的话，请跳转到问卷
      if (org && !org.isShowDoc) {
        this.showDocChart = false
      }
      if (!org || (org && org.isShowQue) || (org && org.isShowQue === null)) {
        // this.tabList.push({ key: '2', tab: '量表(问卷)数量' })
      }

      // 卡片相关的，档案人数，问卷数，确认数
      getCard({ orgid: orgid, userid: userid, trial: trial }).then(res => {
        // crad数字
        this.docCount = res.docM.docCount
        this.qCount = res.questionnaireM.qCount
        this.aCount = res.questionnaireM.aCount
        this.experCount = res.experimentM.experCount
        this.fileCount = res.fileM.fileCount
        res.questionnaireList.map(r => {
          this.rankList.push({
            name: r.title,
            total: '答卷数：' + r.numberOfPeople,
            tooltip: r.tooltip,
            enable: r.isEnable
          })
        })
        // 档案card
        var chartdata = []
        res.docM.data.map(r => {
          chartdata.push({
            x: r.x,
            y: Number(r.y)
          })
        })
        this.docdata = chartdata
        // 问卷card
        chartdata = []
        res.questionnaireM.qData.map(r => {
          chartdata.push({
            x: String(r.x), // moment(r.x).format('YYYY-MM-DD'),
            y: Number(r.y)
          })
        })
        this.quedata = chartdata
        // 答卷card
        chartdata = []
        res.questionnaireM.aData.map(r => {
          chartdata.push({
            x: r.x,
            y: Number(r.y)
          })
        })
        this.ansdata = chartdata
        // 文件card
        chartdata = []
        res.fileM.data.map(r => {
          chartdata.push({
            x: String(r.x),
            y: Number(r.y)
          })
        })
        this.filedata = chartdata
        // 实验card
        chartdata = []
        res.experimentM.data.map(r => {
          chartdata.push({
            x: String(r.x),
            y: Number(r.y)
          })
        })
        this.expdata = chartdata
      })

      // this.qCountConfirm = res.questionnaireM.qCountConfirm
      // this.qCountConfirmP = res.questionnaireM.qCountConfirmP
      // 图
      getChartInfo({ orgid: orgid, userid: userid, trial: trial }).then(res => {
        // 图表使用量
        // var mad = []
        // res.chartUsedVM.data.map(r => {
        //   mad.push({
        //     x: moment(r.x).format('YYYY-MM-DD'),
        //     y: Number(r.y)
        //   })
        // })
        // console.log('aa', md)
        // this.madata = mad
        // this.totalChart = res.chartUsedVM.total
        // this.todayChart = res.chartUsedVM.today
        // // 假设检验使用量
        // var mbd = []
        // res.hypothesisUsedVM.data.map(r => {
        //   mbd.push({
        //     x: moment(r.x).format('YYYY-MM-DD'),
        //     y: Number(r.y)
        //   })
        // })
        // this.mbdata = mbd
        // this.totalHy = res.hypothesisUsedVM.total
        // this.todayHy = res.hypothesisUsedVM.today
        // 档案数量图表
        res.docChartVM.map(r => {
          this.barData.push({
            x: r.x,
            y: Number(r.y)
          })
        })
        // 问卷数量图表
        res.queChartVM.map(r => {
          this.barData2.push({
            x: r.x,
            y: Number(r.y)
          })
          // this.barData.push({
        })
        //
        // res.pieScaleLabelVM.map(r => {
        //   this.sourceData.push({
        //     item: r.item,
        //     count: r.count
        //   })
        //   const dv = new DataSet.View().source(this.sourceData)
        //   dv.transform({
        //     type: 'percent',
        //     field: 'count',
        //     dimension: 'item',
        //     as: 'percent'
        //   })
        //   this.pieData = dv.rows
        //   console.log(this.pieData)
        // })
        // res.capVM.map(r => {
        //   this.capdata.push({
        //     item: r.item,
        //     count: r.count
        //   })
        //   console.log(this.capdata + '这是capdata++++++++++++++++++++')
        //   const dv = new DataSet.View().source(this.capdata)
        //   dv.transform({
        //     type: 'percent',
        //     field: 'count',
        //     dimension: 'item',
        //     as: 'percent'
        //   })
        //   this.capData = dv.rows
        // })
      })
      getVeRing({ orgid: orgid, userid: userid }).then(res => {
        // WENJIAN
        res.file.map(r => {
          r.map(re => {
            this.filechartData.rows.push({
              item: re.item,
              count: re.count
            })
          })
        })
        const dv1 = new DataSet.View().source(this.filechartData.rows)
        dv1.transform({
          type: 'percent',
          field: 'count',
          dimension: 'item',
          as: 'percent'
        })
        this.filechartData.rows = dv1.rows
        this.filegraphic[0].style.text = ((this.filechartData.rows[0].count / res.file[1][0].count) * 100).toFixed(1) + '%'
        this.filegraphic[1].style.text = res.file[1][0].count >= 1000 ? this.filechartData.rows[0].count + 'MB' + '/' + res.file[1][0].count / 1000 + 'GB' : this.filechartData.rows[0].count + '/' + res.file[1][0].count + 'MB'
        // 文档占比
        res.doc.map(r => {
          r.map(re => {
            this.docchartData.rows.push({
              item: re.item,
              count: re.count
            })
          })
        })
        const dv = new DataSet.View().source(this.docchartData.rows)
        dv.transform({
          type: 'percent',
          field: 'count',
          dimension: 'item',
          as: 'percent'
        })
        this.docchartData.rows = dv.rows
        console.log(this.docchartData.rows)
        this.docgraphic[0].style.text = ((this.docchartData.rows[0].count / res.doc[1][0].count) * 100).toFixed(1) + '%'
        this.docgraphic[1].style.text = res.doc[1][0].count >= 1000 ? this.docchartData.rows[0].count + 'MB ' + '/' + res.doc[1][0].count / 1000 + 'GB' : this.docchartData.rows[0].count + '/' + res.doc[1][0].count + 'MB'
        // 问卷占比
        res.que.map(r => {
          r.map(re => {
            this.quechartData.rows.push({
              item: re.item,
              count: re.count
            })
          })
        })
        const dv2 = new DataSet.View().source(this.quechartData.rows)
        dv2.transform({
          type: 'percent',
          field: 'count',
          dimension: 'item',
          as: 'percent'
        })
        console.log(this.quechartData.rows[0].count + 'rows=======================' + res.que[1][0].count)
        this.quechartData.rows = dv2.rows
        this.quegraphic[0].style.text = ((this.quechartData.rows[0].count / 1000) * 100).toFixed(1) + '%'
        this.quegraphic[1].style.text = res.que[1][0].count >= 1000 ? this.quechartData.rows[0].count + 'MB' + '/' + res.que[1][0].count / 1000 + 'GB' : this.quechartData.rows[0].count + '/' + res.que[1][0].count + 'MB'
      })
      this.loading = !this.loading
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'login') {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-col-xl-5 {
  width: 20%;
}
.ant-col-xl-20 {
  width: 80%;
}
.paddingright {
  padding-right: 20px;
}
.padding22 {
  padding-right: 9px;
  padding-left: 5px;
}
.padding23 {
  padding-right: 0px;
  padding-left: 14px;
}
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  height: calc(100% - 24px);
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
.ant-card {
  min-height: 150px !important;
}

body {
  overflow: auto !important;
}
</style>
