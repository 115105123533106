import request from '@/utils/request'

const api = {
    getFields: '/DataScreen/GetDocumentFields',
    getQuestionFields: '/DataScreen/GetQuestionnaireFields',
    GetData: '/DataScreen/GetData',
    GetQuestionData: '/DataScreen/GetQuestionData',
    GetQuestionnaire: '/DataScreen/GetQuestionnaire',
    GetExperiment: '/DataScreen/GetExperiment',
    GetExperimentField: '/DataScreen/GetExperimentField',
    GetExperimentData: '/DataScreen/GetExperimentData',
    GetExperimentField2: '/DataScreen/GetExperimentField2',
    GetExperimentData2: '/DataScreen/GetExperimentData2',
    GetFile: '/DataScreen/GetFile',
    GetFileField: '/DataScreen/GetFileField',
    GetFileData: '/DataScreen/GetFileData',
    GetNullData: '/DataScreen/GetNullData'
}
/**
 * 获取档案的字段
 */
export function getFields(parameter) {
    return request({
      url: api.getFields,
      method: 'get',
      params: parameter
    })
  }
/**
 * 获取量表(问卷)的字段
 */
  export function getQuestionFields(parameter) {
    return request({
      url: api.getQuestionFields,
      method: 'post',
      data: parameter
    })
  }
  /**
 * 获取量表(问卷)的字段
 */
export function GetQuestionnaire(parameter) {
  return request({
    url: api.GetQuestionnaire,
    method: 'post',
    data: parameter
  })
}
  export function GetData(parameter) {
    return request({
      url: api.GetData,
      method: 'post',
      data: parameter
    })
  }

  export function GetQuestionData(parameter) {
    return request({
      url: api.GetQuestionData,
      method: 'post',
      data: parameter
    })
  }

   /**
 * 获取实验数据
 */
export function GetExperiment(parameter) {
  return request({
    url: api.GetExperiment,
    method: 'post',
    data: parameter
  })
}

  /**
 * 获取实验数据
 */
export function GetExperimentField(parameter) {
  return request({
    url: api.GetExperimentField,
    method: 'post',
    params: parameter
  })
}
 /**
 * 获取实验数据
 */
export function GetExperimentData(parameter) {
  return request({
    url: api.GetExperimentData,
    method: 'post',
    data: parameter
  })
}
  /**
 * 获取实验数据
 */
export function GetExperimentField2(parameter) {
  return request({
    url: api.GetExperimentField2,
    method: 'post',
    params: parameter
  })
}
 /**
 * 获取实验数据
 */
export function GetExperimentData2(parameter) {
  return request({
    url: api.GetExperimentData2,
    method: 'post',
    data: parameter
  })
}
   /**
 * 获取文件数据
 */
export function GetFile(parameter) {
  return request({
    url: api.GetFile,
    method: 'post',
    data: parameter
  })
}

  /**
 * 获取文件数据
 */
export function GetFileField(parameter) {
  return request({
    url: api.GetFileField,
    method: 'post',
    params: parameter
  })
}
 /**
 * 获取文件数据
 */
export function GetFileData(parameter) {
  return request({
    url: api.GetFileData,
    method: 'post',
    data: parameter
  })
}

 /**
 * 因为loadData必须有一个promise返回，所以写了这么一个方法
 */
export function GetNullData(parameter) {
  return request({
    url: api.GetNullData,
    method: 'get'
  })
}
