var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile" },
    [
      _vm._l(_vm.data, function(item, key) {
        return _c("div", { key: key }, [
          item.isShow
            ? _c(
                "div",
                {
                  attrs: {
                    hidden: item.isHidden,
                    id: item.id + "_" + item.text
                  }
                },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "marginTop",
                      attrs: { type: "flex", justify: "center" }
                    },
                    [
                      _c(
                        "a-col",
                        { staticClass: "subject", attrs: { span: 23 } },
                        [
                          item.type !== null &&
                          _vm.childrenRequire &&
                          item.isRequired
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*")
                              ])
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                Number(item.order) > 0
                                  ? (item.parentId
                                      ? key + 1 + "）"
                                      : key + 1 + ". ") + item.text
                                  : item.text
                              ) +
                              " "
                          ),
                          item.type === 0 && item.unit
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s("(" + _vm.getUnit(item.unit) + ")")
                                )
                              ])
                            : _vm._e(),
                          item.location === "remark"
                            ? _c(
                                "span",
                                { staticStyle: { "padding-left": "5px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "(" +
                                          item.remarkName +
                                          ":" +
                                          (item.remark ? item.remark : "") +
                                          ")"
                                      )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  item.describe !== ""
                    ? _c(
                        "a-row",
                        { attrs: { type: "flex", justify: "center" } },
                        [
                          _c(
                            "a-col",
                            {
                              staticClass: "content marginLeft",
                              attrs: { span: 23 }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "white-space": "pre-wrap" } },
                                [_vm._v(_vm._s(item.describe))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === null
                    ? _c(
                        "a-row",
                        {
                          staticClass: "marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  }
                                },
                                [
                                  _c("subject-comp-mobile-new", {
                                    attrs: {
                                      data: item.children,
                                      formaaa: _vm.formdata,
                                      parentOrder: key + 1,
                                      childrenRequire: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 4
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c("a-input", {
                                    staticClass: "widthStyle",
                                    attrs: { placeholder: item.tips },
                                    on: {
                                      change: function(e) {
                                        _vm.$emit("doclink", item, e)
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 5
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: [
                                      {
                                        type: "array",
                                        required:
                                          _vm.childrenRequire &&
                                          item.isRequired,
                                        message: "必填"
                                      },
                                      {
                                        validator: function(
                                          rule,
                                          value,
                                          callback
                                        ) {
                                          return _vm.chkvalueChange(
                                            rule,
                                            value,
                                            callback,
                                            item
                                          )
                                        },
                                        trigger: "change"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "a-checkbox-group",
                                    {
                                      staticStyle: { width: "100%" },
                                      on: {
                                        change: function(value) {
                                          _vm.checkgroupChange(value, item)
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formdata,
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-list",
                                        { attrs: { bordered: "" } },
                                        _vm._l(item.optionArry, function(
                                          option,
                                          i
                                        ) {
                                          return _c(
                                            "a-list-item",
                                            { key: i },
                                            [
                                              _c(
                                                "a-checkbox",
                                                {
                                                  staticClass: "content",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    disabled: option.disabled,
                                                    value:
                                                      option.score +
                                                      "_" +
                                                      option.text
                                                  },
                                                  on: {
                                                    change: function(value) {
                                                      _vm.childrenRequireChange(
                                                        value,
                                                        item.optionArry
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(option.text))]
                                              ),
                                              option.childrenRequire
                                                ? _c(
                                                    "subject-comp-mobile-new",
                                                    {
                                                      attrs: {
                                                        formaaa: _vm.formdata,
                                                        data: option.children,
                                                        childrenRequire:
                                                          option.childrenRequire
                                                      }
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 2 || item.type === 1
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-radio-group",
                                    {
                                      staticStyle: { width: "100%" },
                                      on: {
                                        change: function(value) {
                                          _vm.radionChange(value, item)
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formdata,
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-list",
                                        { attrs: { bordered: "" } },
                                        _vm._l(item.optionArry, function(
                                          option,
                                          i
                                        ) {
                                          return _c(
                                            "a-list-item",
                                            { key: i },
                                            [
                                              _c(
                                                "a-radio",
                                                {
                                                  staticClass: "content",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    value:
                                                      option.score +
                                                      "_" +
                                                      option.text
                                                  }
                                                },
                                                [_vm._v(_vm._s(option.text))]
                                              ),
                                              option.childrenRequire
                                                ? _c(
                                                    "subject-comp-mobile-new",
                                                    {
                                                      attrs: {
                                                        formaaa: _vm.formdata,
                                                        data: option.children,
                                                        childrenRequire:
                                                          option.childrenRequire
                                                      }
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 1
                    ? _c(
                        "a-row",
                        {
                          staticClass: "marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        "show-search": "",
                                        "filter-option": _vm.filterOption
                                      },
                                      model: {
                                        value:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formdata,
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                      }
                                    },
                                    _vm._l(item.optionArry, function(
                                      option,
                                      i
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: i,
                                          attrs: {
                                            value:
                                              option.score + "_" + option.text
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(option.text))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 0
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c("a-input-number", {
                                    staticClass: "widthStyle",
                                    attrs: { placeholder: item.tips },
                                    on: {
                                      change: function(e) {
                                        return _vm.valueChange(e, item)
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 3
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop marginLeft",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    staticClass: "widthStyle",
                                    attrs: {
                                      locale: _vm.locale,
                                      placeholder: "选择时间"
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 6
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop marginLeft",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c("a-cascader", {
                                    attrs: {
                                      options: _vm.options,
                                      "field-names": {
                                        label: "name",
                                        value: "code",
                                        children: "children"
                                      },
                                      "expand-trigger": "hover",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 7
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop marginLeft",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 22 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField +
                                      "_image",
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "请上传图片"
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-upload",
                                    {
                                      attrs: {
                                        "list-type": "picture-card",
                                        "file-list":
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField +
                                              "_image"
                                          ],
                                        disabled: _vm.disabled,
                                        action: "/api/Upload"
                                      },
                                      on: {
                                        preview: function(e) {
                                          return _vm.handlePreview(e, item)
                                        },
                                        change: function(e) {
                                          return _vm.handleChange(e, item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "plus" }
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "ant-upload-text" },
                                            [_vm._v("选择图片")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-modal",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField +
                                              "_previewVisible"
                                          ],
                                        footer: null
                                      },
                                      on: {
                                        cancel: function($event) {
                                          return _vm.handleCancel(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          alt: "example",
                                          src:
                                            _vm.formdata[
                                              item.id +
                                                "_" +
                                                item.type +
                                                "_" +
                                                item.docField +
                                                "_previewImage"
                                            ]
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        mode: "tags",
                                        placeholder:
                                          "选择相应的标签，没有的话直接填写自己标签"
                                      },
                                      model: {
                                        value:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formdata,
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                      }
                                    },
                                    _vm._l(item.tags, function(tag) {
                                      return _c(
                                        "a-select-option",
                                        { key: tag },
                                        [_vm._v(" " + _vm._s(tag) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 8
                    ? _c(
                        "a-row",
                        {
                          staticClass: "content marginSujectTop",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 21 } },
                            _vm._l(
                              _vm.formdata[
                                item.id +
                                  "_" +
                                  item.type +
                                  "_" +
                                  item.docField +
                                  "_table"
                              ],
                              function(row, i) {
                                return _c(
                                  "div",
                                  { key: i },
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        staticClass: "content",
                                        model: {
                                          value: row.checked,
                                          callback: function($$v) {
                                            _vm.$set(row, "checked", $$v)
                                          },
                                          expression: "row.checked"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.tabrows[i]))]
                                    ),
                                    row.checked === true
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "border-radius": "6px !important",
                                              "background-color": "#fafafa",
                                              border: "1px solid #dedede",
                                              "padding-top": "20px"
                                            }
                                          },
                                          _vm._l(item.tabcols, function(
                                            col,
                                            j
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: j,
                                                staticStyle: {
                                                  padding: "0px 20px"
                                                }
                                              },
                                              [
                                                col.type === 1 &&
                                                !row[col.name + "_disabled"]
                                                  ? _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            item.id +
                                                            "_" +
                                                            item.type +
                                                            "_" +
                                                            item.docField +
                                                            "_table." +
                                                            i +
                                                            "." +
                                                            col.name,
                                                          rules: {
                                                            required:
                                                              col.isrequired ===
                                                                true &&
                                                              !row[
                                                                col.name +
                                                                  "_disabled"
                                                              ] === true,
                                                            message: "必填"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          attrs: {
                                                            disabled:
                                                              row[
                                                                col.name +
                                                                  "_disabled"
                                                              ]
                                                          },
                                                          model: {
                                                            value:
                                                              row[col.name],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row[col.name]"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 2 &&
                                                !row[col.name + "_disabled"]
                                                  ? _c(
                                                      "a-form-model-item",
                                                      {
                                                        staticClass: "content",
                                                        attrs: {
                                                          prop:
                                                            item.id +
                                                            "_" +
                                                            item.type +
                                                            "_" +
                                                            item.docField +
                                                            "_table." +
                                                            i +
                                                            "." +
                                                            col.name,
                                                          rules: {
                                                            required:
                                                              col.isrequired ===
                                                                true &&
                                                              !row[
                                                                col.name +
                                                                  "_disabled"
                                                              ] === true,
                                                            message: "必填"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(col.name)
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                col.shuoming
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c("a-input-number", {
                                                          staticStyle: {
                                                            width: "80%"
                                                          },
                                                          attrs: {
                                                            max:
                                                              col.rangeMax !==
                                                              null
                                                                ? col.rangeMax
                                                                : Infinity,
                                                            min:
                                                              col.rangeMin !==
                                                              null
                                                                ? col.rangeMin
                                                                : -Infinity,
                                                            disabled:
                                                              row[
                                                                col.name +
                                                                  "_disabled"
                                                              ]
                                                          },
                                                          model: {
                                                            value:
                                                              row[col.name],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row[col.name]"
                                                          }
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getUnit(
                                                                  col.unit
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 3 &&
                                                !row[col.name + "_disabled"]
                                                  ? _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            item.id +
                                                            "_" +
                                                            item.type +
                                                            "_" +
                                                            item.docField +
                                                            "_table." +
                                                            i +
                                                            "." +
                                                            col.name,
                                                          rules: {
                                                            required:
                                                              col.isrequired ===
                                                                true &&
                                                              !row[
                                                                col.name +
                                                                  "_disabled"
                                                              ] === true,
                                                            message: "必填"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content"
                                                          },
                                                          [
                                                            col.isrequired ===
                                                              true &&
                                                            !row[
                                                              col.name +
                                                                "_disabled"
                                                            ] === true
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#f5222d"
                                                                    }
                                                                  },
                                                                  [_vm._v("* ")]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(
                                                              _vm._s(col.name) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        col.shuoming
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      col.shuoming
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "a-radio-group",
                                                          {
                                                            staticStyle: {
                                                              width: "100%"
                                                            },
                                                            model: {
                                                              value:
                                                                row[col.name],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  row,
                                                                  col.name,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "row[col.name]"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "a-list",
                                                              {
                                                                staticStyle: {
                                                                  background:
                                                                    "white"
                                                                },
                                                                attrs: {
                                                                  bordered: ""
                                                                }
                                                              },
                                                              _vm._l(
                                                                col.options,
                                                                function(
                                                                  op,
                                                                  opi
                                                                ) {
                                                                  return _c(
                                                                    "a-list-item",
                                                                    {
                                                                      key: opi,
                                                                      staticStyle: {
                                                                        background:
                                                                          "white"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a-radio",
                                                                        {
                                                                          staticClass:
                                                                            "content",
                                                                          staticStyle: {
                                                                            width:
                                                                              "100%"
                                                                          },
                                                                          attrs: {
                                                                            disabled:
                                                                              row[
                                                                                col.name +
                                                                                  "_disabled"
                                                                              ],
                                                                            value:
                                                                              op.text
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              op.text
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 4 &&
                                                !row[col.name + "_disabled"]
                                                  ? _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            item.id +
                                                            "_" +
                                                            item.type +
                                                            "_" +
                                                            item.docField +
                                                            "_table." +
                                                            i +
                                                            "." +
                                                            col.name,
                                                          rules: {
                                                            required:
                                                              col.isrequired ===
                                                                true &&
                                                              !row[
                                                                col.name +
                                                                  "_disabled"
                                                              ] === true,
                                                            message: "必填",
                                                            trigger: "change"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(col.name)
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "a-checkbox-group",
                                                          {
                                                            staticStyle: {
                                                              width: "100%"
                                                            },
                                                            model: {
                                                              value:
                                                                row[col.name],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  row,
                                                                  col.name,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "row[col.name]"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "a-list",
                                                              {
                                                                staticStyle: {
                                                                  background:
                                                                    "white"
                                                                },
                                                                attrs: {
                                                                  bordered: ""
                                                                }
                                                              },
                                                              _vm._l(
                                                                col.options,
                                                                function(
                                                                  op,
                                                                  opi
                                                                ) {
                                                                  return _c(
                                                                    "a-list-item",
                                                                    {
                                                                      key: opi,
                                                                      staticStyle: {
                                                                        background:
                                                                          "white"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "content",
                                                                          staticStyle: {
                                                                            width:
                                                                              "100%"
                                                                          },
                                                                          attrs: {
                                                                            disabled:
                                                                              row[
                                                                                col.name +
                                                                                  "_disabled"
                                                                              ],
                                                                            value:
                                                                              op.text
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              op.text
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 5 &&
                                                j > 0 &&
                                                col.linkcol &&
                                                col.linkcol.length > 0
                                                  ? _c("a-divider")
                                                  : _vm._e(),
                                                col.type === 5 &&
                                                !row[col.name + "_disabled"]
                                                  ? _c(
                                                      "a-form-model-item",
                                                      {
                                                        staticStyle: {
                                                          "font-size":
                                                            "18px !important"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                row[
                                                                  col.name +
                                                                    "_disabled"
                                                                ]
                                                            },
                                                            on: {
                                                              change: function(
                                                                value
                                                              ) {
                                                                _vm.checkedChange(
                                                                  value,
                                                                  row,
                                                                  col.linkcol,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                row[col.name],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  row,
                                                                  col.name,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "row[col.name]"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(col.name)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.type === 9
                    ? _c(
                        "a-row",
                        {
                          staticClass:
                            "content marginSujectTop marginLeft daterange",
                          attrs: { type: "flex", justify: "center" }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px !important"
                                  },
                                  attrs: {
                                    prop:
                                      item.id +
                                      "_" +
                                      item.type +
                                      "_" +
                                      item.docField,
                                    rules: {
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "content" }, [
                                    _vm._v("开始时间")
                                  ]),
                                  _c(
                                    "a-input-group",
                                    { attrs: { compact: "" } },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticClass: "content",
                                          staticStyle: { width: "40%" },
                                          attrs: { placeholder: "时" },
                                          model: {
                                            value:
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].start[0],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formdata[
                                                  item.id +
                                                    "_" +
                                                    item.type +
                                                    "_" +
                                                    item.docField
                                                ].start,
                                                0,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formdata[`${item.id}_${item.type}_${item.docField}`].start[0]"
                                          }
                                        },
                                        _vm._l(_vm.enumHour, function(h, i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i,
                                              attrs: { value: h.value }
                                            },
                                            [_vm._v(_vm._s(h.value))]
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { margin: "auto 10px" }
                                        },
                                        [_vm._v(":")]
                                      ),
                                      _c(
                                        "a-select",
                                        {
                                          staticClass: "content",
                                          staticStyle: { width: "40%" },
                                          attrs: { placeholder: "分" },
                                          model: {
                                            value:
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].start[1],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formdata[
                                                  item.id +
                                                    "_" +
                                                    item.type +
                                                    "_" +
                                                    item.docField
                                                ].start,
                                                1,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formdata[`${item.id}_${item.type}_${item.docField}`].start[1]"
                                          }
                                        },
                                        _vm._l(_vm.enumMinture, function(h, i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i,
                                              attrs: { value: h.value }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  h.value === 0 ? "00" : h.value
                                                )
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "content" }, [
                                    _vm._v("结束时间")
                                  ]),
                                  _c(
                                    "a-input-group",
                                    { attrs: { compact: "" } },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticClass: "content",
                                          staticStyle: { width: "40%" },
                                          attrs: { placeholder: "时" },
                                          model: {
                                            value:
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].end[0],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formdata[
                                                  item.id +
                                                    "_" +
                                                    item.type +
                                                    "_" +
                                                    item.docField
                                                ].end,
                                                0,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formdata[`${item.id}_${item.type}_${item.docField}`].end[0]"
                                          }
                                        },
                                        _vm._l(_vm.enumHour, function(h, i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i,
                                              attrs: { value: h.value }
                                            },
                                            [_vm._v(_vm._s(h.value))]
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { margin: "auto 10px" }
                                        },
                                        [_vm._v(":")]
                                      ),
                                      _c(
                                        "a-select",
                                        {
                                          staticClass: "content",
                                          staticStyle: { width: "40%" },
                                          attrs: { placeholder: "分" },
                                          model: {
                                            value:
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].end[1],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formdata[
                                                  item.id +
                                                    "_" +
                                                    item.type +
                                                    "_" +
                                                    item.docField
                                                ].end,
                                                1,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formdata[`${item.id}_${item.type}_${item.docField}`].end[1]"
                                          }
                                        },
                                        _vm._l(_vm.enumMinture, function(h, i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i,
                                              attrs: { value: h.value }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  h.value === 0 ? "00" : h.value
                                                )
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      }),
      _c(
        "a-anchor",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          attrs: { affix: false },
          on: { change: _vm.onChangeAnchor }
        },
        _vm._l(_vm.data, function(item, key) {
          return _c("a-anchor-link", {
            key: key,
            attrs: { href: "#" + item.id + "_" + item.text }
          })
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }