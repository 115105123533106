var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "hCommon" }, [_vm._v("概述")]),
    _c(
      "div",
      { staticClass: "itemWrapperCommon itemWrapperCommonPadding40" },
      [
        _c(
          "a-row",
          [
            _c(
              "a-col",
              { attrs: { span: "11" } },
              [
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "24" } }, [
                      _c("span", { staticClass: "htCommon" }, [
                        _vm._v("数据集统计")
                      ])
                    ])
                  ],
                  1
                ),
                _c("divider-common"),
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "httCommon" }, [
                        _vm._v("变量数")
                      ])
                    ]),
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "ttCommon" }, [
                        _vm._v(_vm._s(_vm.data.fieldCount))
                      ])
                    ])
                  ],
                  1
                ),
                _c("divider-common"),
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "httCommon" }, [
                        _vm._v("个案数")
                      ])
                    ]),
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "ttCommon" }, [
                        _vm._v(_vm._s(_vm.data.caseCount))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: "11", offset: 2 } },
              [
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "24" } }, [
                      _c("span", { staticClass: "htCommon" }, [
                        _vm._v("变量类型")
                      ])
                    ])
                  ],
                  1
                ),
                _c("divider-common"),
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "httCommon" }, [
                        _vm._v("数值型")
                      ])
                    ]),
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "ttCommon" }, [
                        _vm._v(_vm._s(_vm.data.numberCount))
                      ])
                    ])
                  ],
                  1
                ),
                _c("divider-common"),
                _c(
                  "a-row",
                  [
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "httCommon" }, [
                        _vm._v("分类型")
                      ])
                    ]),
                    _c("a-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "ttCommon" }, [
                        _vm._v(_vm._s(_vm.data.classCount))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }