<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module">
      <a-table
        ref="tables,2"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        :settings="table.settings"
        size="small"
        class="test"
      >
        <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="text2" :text="pTextList"></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <a-row type="flex" justify="center" v-for="(imgAry, i) in imgs" :key="i">
        <a-col v-for="(img,j) in imgAry" :key="j" >
          <MyImg ref="imgs,5" :imgBytes="img" :fileName="imgFileNameAry[j]"></MyImg>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
import { getPictureSize } from '@/utils/util'

export default {
  props: ['data', 'lei', 'xys', 'settings'],
  created() {
    // 把settings里的配置添加到data里去。
    this.data.method = 'auto'
    if (this.settings) this.data.method = this.settings.args[0].value // method是正态检验时候要使用的方法。
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'Norm',
      data: { ...this.data, ...getPictureSize('report', 45) },
      lei: this.lei
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '正态检验',
          msg: res
        })
      } else {
        this.imgs = res.imgs
        const tables = res.tables
        tables.forEach(table => {
          // 合并统计指标
          table.columns[0].customRender = (text, record, index) => {
            const obj = {
              children: text,
              attrs: {}
            }
            if (index === 0) {
              obj.attrs.rowSpan = table.data.length
            } else {
              obj.attrs.rowSpan = 0
            }
            return obj
          }
        })
        this.getMergeSetting(tables)
        this.tables = tables
        const result = this.getPList(tables)
        const { pTextList, isNorm } = this.getTextAndIsNorm(result)
        this.pTextList = pTextList
        // 传递P值出去
        this.$emit('isNorm', isNorm)
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: [],
      text: '',
      pTextList: [''],
      imgFileNameAry: ['PP图', 'QQ图']
    }
  },
  methods: {
    getPList(tables) {
      const { a } = this
      // 把表格中的p值分分类。
      const result = []
      tables.forEach(table => {
        const pDivideObj = {
          greater: [],
          less: [],
          great: []
        }
        table.data.forEach(obj => {
          const option = obj.lei_option
          if (option !== '总体') {
            const N = obj.N
            const p = obj.p
            let list
            if (N >= 500) {
              if (p >= a) {
                list = pDivideObj.greater
              } else if (p >= a * (3 / 5) && p < a) {
                list = pDivideObj.great
              } else {
                list = pDivideObj.less
              }
            } else {
              list = p >= a ? pDivideObj.greater : pDivideObj.less
            }
            list.push(option)
          }
        })
        for (const key in pDivideObj) {
          if (Object.hasOwnProperty.call(pDivideObj, key)) {
            const value = pDivideObj[key]
            pDivideObj[key] = value.join('、')
          }
        }
        result.push(pDivideObj)
      })
      return result
    },
    getTextAndIsNorm(result) {
      // 这个pTextList是文字说明。
      const pTextList = []
      const isNorm = []
      const leiName = this.lei
      const text1 = '由上表可得：'
      let text
      let p
      result.forEach((obj, groupIndex) => {
        const { a } = this
        const { greater, less, great } = obj
        const liangName = Object.keys(this.data.data1)[groupIndex]
        const func_of_true_text = name => `不拒绝H0（${name}服从正态分布）。`
        const func_of_false_text = name => `拒绝H0（${name}服从正态分布），接受H1（${name}不服从正态分布）。`
        const lei_liang_name = `不同${leiName}中${liangName}的样本`
        const liang_liaing_name = `${this.xys[0][0]}和${this.xys[1][0]}的样本`
        // p = 1:完全正态分布;p = a:近似正态分布;p = 0：不是正态分布
        if (!less && greater && great) {
          text =
            this.lei === '变量'
              ? `${great}的样本量较大，且<i>p</i>值接近${a}，因此近似正态；${greater}的<i>p</i>值 ≥ ${a}，呈正态分布；综上所述：${func_of_true_text(
                liang_liaing_name
              )}`
              : `${great}的样本量较大，且<i>p</i>值接近${a}，因此近似正态；其他组的<i>p</i>值 ≥ ${a}，呈正态分布；综上所述：${func_of_true_text(
                lei_liang_name
              )}`
          p = a
        } else if (!less && greater && !great) {
          text =
            this.lei === '变量'
              ? `${greater}的<i>p</i>值都 ≥ ${a}，因此${func_of_true_text(liang_liaing_name)}`
              : `${leiName}中各组的<i>p</i>值都 ≥ ${a}，因此${func_of_true_text(lei_liang_name)}`
          p = 1
        } else if (!less && !greater && great) {
          text =
            this.lei === '变量'
              ? `${great}的样本量较大，且<i>p</i>值接近${a}，因此${func_of_true_text(liang_liaing_name)}`
              : `${leiName}中各组的样本量较大，且<i>p</i>值接近${a}，因此${func_of_true_text(lei_liang_name)}`
          p = a
        } else if (less && !greater && !great) {
          text =
            this.lei === '变量'
              ? `${less}的<i>p</i>值都 < ${a}，因此${func_of_false_text(liang_liaing_name)}`
              : `${leiName}中各组的<i>p</i>值都 < ${a}，因此${func_of_false_text(lei_liang_name)}`
          p = 0
        } else {
          text =
            this.lei === '变量'
              ? `${less}的<i>p</i>值 < ${a}，因此${func_of_false_text(liang_liaing_name)}`
              : `${less}中的<i>p</i>值 < ${a}，因此${func_of_false_text(lei_liang_name)}`
          p = 0
        }
        pTextList.push(text1 + text)
        isNorm.push(p)
      })
      return { pTextList, isNorm }
    },
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      tables[0].settings = [[]]
      for (let index = 1; index <= table_row_num; index++) {
        tables[0].settings[0].push([index, 0])
      }
    }
  },
  computed: {
    liang1_and_liang2() {
      return `${this.xys[0][0]}和${this.xys[1][0]}`
    },
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>
<style lang="less">
</style>