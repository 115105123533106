var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.yList.length === 0
        ? _c("div", { staticStyle: { color: "#bfbfbf" } }, [
            _vm._v("请向生存状态y2【二分类】中拖入变量")
          ])
        : _c(
            "a-radio-group",
            {
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.options, function(option, index) {
              return _c(
                "a-radio",
                {
                  key: index,
                  attrs: { value: isNaN(+option) ? option : +option }
                },
                [_vm._v(" " + _vm._s(option) + " ")]
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }