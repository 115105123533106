import request from '@/utils/request'

const api = {
  opt: '/OperationLog',
  chart: '/ChartLogDB',
  hypothesis: '/HypothesisLogDb',
  analysisOfRegression: '/AnalysisOfRegressionLogDb'
}
export function postChartlog(parameter) {
  return request({
    url: api.chart + '/Create',
    method: 'post',
    data: parameter
  })
}

export function postHypothesislog(parameter) {
  return request({
    url: api.hypothesis + '/Create',
    method: 'post',
    data: parameter
  })
}

export function postAORlog(parameter) {
  return request({
    url: api.analysisOfRegression + '/Create',
    method: 'post',
    data: parameter
  })
}

export function postOperationlog(parameter) {
  return request({
    url: api.opt + '/Create',
    method: 'post',
    data: parameter
  })
}