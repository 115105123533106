<template>
  <div>
    <h1 class="hCommon">相关性</h1>
    <div class="itemWrapperCommon itemWrapperCommonPadding40">
      <div id="chartpearson" style="width:800px;height:650px;margin:0px auto">
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        data: {
          type: Object,
          default: () => {}
        }
    },
    data() {
        return { ChartPearson: null }
    },
     methods: {
      drawLine() {
        var myChart = document.getElementById('chartpearson')
        this.ChartPearson = this.$echarts.init(myChart)
        // 是 变量*变量 转换的一维数组
        var pearsonData = this.data.pearsonData
        // 变量
        var fields = [...this.data.fields]
        var Vfields = [ ...this.data.fields ].reverse()
        // 图表数据
        var edata = []
        if (pearsonData && fields) {
            for (var i = 0; i < fields.length; i++) {
                for (var j = 0; j < fields.length; j++) {
                    var value = Math.floor(pearsonData[i * 10 + (fields.length - 1 - j)] * 100) / 100

                    edata.push([i, j, value])
                }
            }
        }
        edata = edata.map(function (item) {
            return [item[1], item[0], item[2] || '-']
        })

        var option = {
            title: {
                left: 'center',
                text: '档案相关系数'
            },
            tooltip: {
                position: 'top',
                trigger: 'item',
                formatter: function(params) {
                  var htmlStr = '<div>'
                  htmlStr += fields[params.data[0]] + '-' + Vfields[params.data[1]] + '<br/>'
                  htmlStr += '相关系数：' + params.data[2]
                  htmlStr += '</div>'
                  return htmlStr
                }
              },
            animation: false,
            grid: {
                height: '400px',
                width: '400px',
                top: '10%',
                left: 'center'
            },
            xAxis: {
                type: 'category',
                data: fields,
                splitArea: {
                    show: true
                },
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    formatter: function(value) {
                        return value.split('').join('\n')
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: Vfields,
                axisTick: { show: false },
                splitArea: {
                    show: true
                }
            },
            visualMap: {
                min: -1,
                max: 1,
                calculable: false,
                orient: 'vertical',
                right: '20px',
                precision: 2,
                align: 'right',
                top: '10%',
                text: [1, -1],
                inRange: { // 定义 在选中范围中 的视觉元素
                    color: ['#6a0211', '#ba242a', '#e48569', '#faceb5', '#f6f6f6', '#bfdaeb', '#6eabd0', '#286caf', '#31487d']
                }
            },
            series: [{
                type: 'heatmap',
                data: edata,
                label: {
                    show: false
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }]
        }
        this.ChartPearson.setOption(option, true)
      }
    },
     watch: {
      data(val, oldval) {
           this.drawLine()
      }
    }
}
</script>