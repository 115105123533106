<!--现在使用的答卷-->
<template >
  <div class="mobile">
    <div v-for="(item, key) in data" :key="key">
      <div v-if="item.isShow" :hidden="item.isHidden" :id="`${item.id}_${item.text}`">
        <!-- 题目 -->
        <a-row type="flex" justify="center" class="marginTop">
          <a-col class="subject" :span="23">
            <span style="color: red" v-if="item.type !== null && childrenRequire && item.isRequired">*</span>
            {{ Number(item.order) > 0 ? (item.parentId ? key + 1 + '）' : key + 1 + '. ') + item.text : item.text }}
            <span v-if="item.type === 0 && item.unit"> {{ '(' + getUnit(item.unit) + ')' }}</span>
            <span style="padding-left: 5px" v-if="item.location === 'remark'">
              {{ '(' + item.remarkName + ':' + (item.remark ? item.remark : '') + ')' }}</span
            >
          </a-col>
        </a-row>
        <!-- 描述 -->
        <a-row type="flex" justify="center" v-if="item.describe !== ''">
          <a-col class="content marginLeft" :span="23">
            <div style="white-space: pre-wrap">{{ item.describe }}</div>
          </a-col>
        </a-row>
        <!-- 题目的大分类 -->
        <a-row type="flex" justify="center" class="marginSujectTop" v-if="item.type === null">
          <a-col :span="24">
            <a-form-model-item style="margin-bottom: 0px !important">
              <subject-comp-mobile-new
                :data="item.children"
                :formaaa="formdata"
                :parentOrder="key + 1"
                :childrenRequire="true"
              ></subject-comp-mobile-new>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 文本框 -->
        <a-row type="flex" justify="center" class="content marginSujectTop" v-if="item.type === 4">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-input
                :placeholder="item.tips"
                @change="
                  (e) => {
                    $emit('doclink', item, e)
                  }
                "
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                class="widthStyle"
              ></a-input> </a-form-model-item
            ></a-col>
        </a-row>
        <!-- 多选 -->
        <a-row type="flex" justify="center" class="content marginSujectTop" v-if="item.type === 5">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="[{
                type: 'array',
                required: childrenRequire && item.isRequired,
                message: '必填',
              },{ validator:(rule, value, callback)=>chkvalueChange(rule, value, callback,item), trigger: 'change' }]"
            >
              <a-checkbox-group
                style="width: 100%"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                @change="
                  (value) => {
                    checkgroupChange(value, item)
                  }
                "
              >
                <a-list bordered>
                  <a-list-item v-for="(option, i) in item.optionArry" :key="i">
                    <a-checkbox
                      :disabled="option.disabled"
                      style="width: 100%"
                      class="content"
                      :value="`${option.score}_${option.text}`"
                      @change="
                        (value) => {
                          childrenRequireChange(value, item.optionArry)
                        }
                      "
                    >{{ option.text }}</a-checkbox
                    >
                    <subject-comp-mobile-new
                      v-if="option.childrenRequire"
                      :formaaa="formdata"
                      :data="option.children"
                      :childrenRequire="option.childrenRequire"
                    ></subject-comp-mobile-new>
                  </a-list-item>
                </a-list>
                <!-- <a-card-grid style="width: 100%" v-for="(option, i) in item.optionArry" :key="i">
                  <a-card style="min-height: none !important" :bordered="false" :body-style="{ padding: 0 }">
                    <a-row>
                      <a-col>
                        <a-checkbox
                          class="content"
                          :value="`${option.score}_${option.text}`"
                          @change="
                            (value) => {
                              childrenRequireChange(value, item.optionArry)
                            }
                          "
                        >{{ option.text }}</a-checkbox
                        >
                      </a-col>
                    </a-row>
                    <subject-comp-mobile-new
                      v-if="option.childrenRequire"
                      :formaaa="formdata"
                      :data="option.children"
                      :childrenRequire="option.childrenRequire"
                    ></subject-comp-mobile-new>
                  </a-card>
                </a-card-grid> -->
              </a-checkbox-group>
            </a-form-model-item></a-col
          >
        </a-row>
        <!-- 单选 -->
        <a-row type="flex" justify="center" class="content marginSujectTop" v-if="item.type === 2 || item.type === 1">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-radio-group
                style="width: 100%"
                @change="
                  (value) => {
                    radionChange(value, item)
                  }
                "
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              >
                <a-list bordered="">
                  <a-list-item v-for="(option, i) in item.optionArry" :key="i">
                    <a-radio style="width: 100%" class="content" :value="`${option.score}_${option.text}`">{{
                      option.text
                    }}</a-radio>
                    <subject-comp-mobile-new
                      v-if="option.childrenRequire"
                      :formaaa="formdata"
                      :data="option.children"
                      :childrenRequire="option.childrenRequire"
                    ></subject-comp-mobile-new>
                  </a-list-item>
                </a-list>
                <!-- <a-card-grid style="width: 100%" v-for="(option, i) in item.optionArry" :key="i">
                  <a-card style="min-height: none !important" :bordered="false" :body-style="{ padding: 0 }">
                    <a-row>
                      <a-col>
                        <a-radio class="content" :value="`${option.score}_${option.text}`">{{ option.text }}</a-radio>
                      </a-col>
                    </a-row>
                    <subject-comp-mobile-new
                      v-if="option.childrenRequire"
                      :formaaa="formdata"
                      :data="option.children"
                      :childrenRequire="option.childrenRequire"
                    ></subject-comp-mobile-new>
                  </a-card>
                </a-card-grid> -->
              </a-radio-group>
            </a-form-model-item></a-col
          >
        </a-row>
        <!-- 下拉框 -->
        <a-row type="flex" justify="center" class="marginSujectTop" v-if="item.type === 1">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-select
                show-search
                :filter-option="filterOption"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              >
                <a-select-option
                  :value="`${option.score}_${option.text}`"
                  v-for="(option, i) in item.optionArry"
                  :key="i"
                >
                  {{ option.text }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 数字 -->
        <a-row type="flex" justify="center" class="content marginSujectTop" v-if="item.type === 0">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-input-number
                @change="(e) => valueChange(e, item)"
                :placeholder="item.tips"
                class="widthStyle"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              ></a-input-number>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 时间 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 3">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-date-picker
                :locale="locale"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                placeholder="选择时间"
                class="widthStyle"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 地址 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 6">
          <a-col :span="21">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-cascader
                :options="options"
                :field-names="{ label: 'name', value: 'code', children: 'children' }"
                expand-trigger="hover"
                placeholder="请选择"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 图片 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 7">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}_image`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '请上传图片',
              }"
            >
              <a-upload
                list-type="picture-card"
                :file-list="formdata[`${item.id}_${item.type}_${item.docField}_image`]"
                @preview="(e) => handlePreview(e, item)"
                @change="(e) => handleChange(e, item)"
                :disabled="disabled"
                action="/api/Upload"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">选择图片</div>
                </div>
              </a-upload>
              <a-modal
                :visible="formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`]"
                :footer="null"
                @cancel="handleCancel(item)"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="formdata[`${item.id}_${item.type}_${item.docField}_previewImage`]"
                />
              </a-modal>
            </a-form-model-item>
            <a-form-model-item style="margin-bottom: 0px !important" :prop="`${item.id}_${item.type}_${item.docField}`">
              <a-select
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                mode="tags"
                style="width: 100%"
                placeholder="选择相应的标签，没有的话直接填写自己标签"
              >
                <a-select-option v-for="tag in item.tags" :key="tag">
                  {{ tag }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 表格 -->
        <a-row type="flex" justify="center" class="content marginSujectTop" v-if="item.type === 8">
          <a-col :span="21">
            <!--formdata[`${item.id}_${item.type}_${item.docField}_table`]是table datasource格式-->
            <div v-for="(row, i) in formdata[`${item.id}_${item.type}_${item.docField}_table`]" :key="i">
              <a-checkbox v-model="row.checked" class="content">{{ item.tabrows[i] }}</a-checkbox>
              <div
                style="
                  border-radius: 6px !important;
                  background-color: #fafafa;
                  border: 1px solid #dedede;
                  padding-top: 20px;
                "
                v-if="row.checked === true"
              >
                <div v-for="(col, j) in item.tabcols" :key="j" style="padding: 0px 20px">
                  <a-form-model-item
                    v-if="col.type === 1 && !row[col.name + '_disabled']"
                    :prop="`${item.id}_${item.type}_${item.docField}_table.${i}.${col.name}`"
                    :rules="{
                      required: col.isrequired === true && !row[col.name + '_disabled'] === true,
                      message: '必填',
                    }"
                  >
                    <a-input :disabled="row[col.name + '_disabled']" v-model="row[col.name]"></a-input>
                  </a-form-model-item>
                  <a-form-model-item
                    class="content"
                    v-if="col.type === 2 && !row[col.name + '_disabled']"
                    :prop="`${item.id}_${item.type}_${item.docField}_table.${i}.${col.name}`"
                    :rules="{
                      required: col.isrequired === true && !row[col.name + '_disabled'] === true,
                      message: '必填',
                    }"
                  >
                    <div class="content">{{ col.name }}</div>
                    <div style="font-size: 14px">{{ col.shuoming }}</div>
                    <a-input-number
                      style="width: 80%"
                      :max="col.rangeMax !== null ? col.rangeMax : Infinity"
                      :min="col.rangeMin !== null ? col.rangeMin : -Infinity"
                      :disabled="row[col.name + '_disabled']"
                      v-model="row[col.name]"
                    ></a-input-number>
                    <span style="margin-left: 10px">{{ getUnit(col.unit) }}</span>
                  </a-form-model-item>
                  <a-form-model-item
                    v-if="col.type === 3 && !row[col.name + '_disabled']"
                    :prop="`${item.id}_${item.type}_${item.docField}_table.${i}.${col.name}`"
                    :rules="{
                      required: col.isrequired === true && !row[col.name + '_disabled'] === true,
                      message: '必填',
                    }"
                  >
                    <div class="content">
                      <span
                        style="color: #f5222d"
                        v-if="col.isrequired === true && !row[col.name + '_disabled'] === true"
                      >* </span
                      >{{ col.name }}
                    </div>
                    <div style="font-size: 14px" v-if="col.shuoming">({{ col.shuoming }})</div>
                    <a-radio-group style="width: 100%" v-model="row[col.name]">
                      <a-list bordered style="background: white">
                        <a-list-item v-for="(op, opi) in col.options" :key="opi" style="background: white">
                          <a-radio
                            style="width: 100%"
                            :disabled="row[col.name + '_disabled']"
                            class="content"
                            :value="op.text"
                          >{{ op.text }}</a-radio
                          >
                        </a-list-item>
                      </a-list>
                    </a-radio-group>
                  </a-form-model-item>
                  <a-form-model-item
                    v-if="col.type === 4 && !row[col.name + '_disabled']"
                    :prop="`${item.id}_${item.type}_${item.docField}_table.${i}.${col.name}`"
                    :rules="{
                      required: col.isrequired === true && !row[col.name + '_disabled'] === true,
                      message: '必填',
                      trigger: 'change',
                    }"
                  >
                    <div class="content">{{ col.name }}</div>
                    <a-checkbox-group v-model="row[col.name]" style="width: 100%">
                      <a-list bordered style="background: white">
                        <a-list-item v-for="(op, opi) in col.options" :key="opi" style="background: white">
                          <a-checkbox
                            style="width: 100%"
                            :disabled="row[col.name + '_disabled']"
                            class="content"
                            :value="op.text"
                          >{{ op.text }}</a-checkbox
                          >
                        </a-list-item>
                      </a-list>
                    </a-checkbox-group>
                  </a-form-model-item>

                  <a-divider v-if="col.type === 5 && j > 0 && col.linkcol && col.linkcol.length > 0"></a-divider>
                  <a-form-model-item
                    v-if="col.type === 5 && !row[col.name + '_disabled']"
                    style="font-size: 18px !important"
                  >
                    <a-checkbox
                      :disabled="row[col.name + '_disabled']"
                      v-model="row[col.name]"
                      @change="
                        (value) => {
                          checkedChange(value, row, col.linkcol, item)
                        }
                      "
                    >{{ col.name }}</a-checkbox
                    >
                  </a-form-model-item>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- 时间范围 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft daterange" v-if="item.type === 9">
          <a-col :span="20">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <div class="content">开始时间</div>
              <a-input-group compact>
                <a-select
                  class="content"
                  placeholder="时"
                  style="width: 40%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].start[0]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumHour">{{ h.value }}</a-select-option>
                </a-select>
                <span style="margin: auto 10px">:</span>
                <a-select
                  class="content"
                  placeholder="分"
                  style="width: 40%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].start[1]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumMinture">{{
                    h.value === 0 ? '00' : h.value
                  }}</a-select-option>
                </a-select>
              </a-input-group>
              <div class="content">结束时间</div>
              <a-input-group compact>
                <a-select
                  class="content"
                  placeholder="时"
                  style="width: 40%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].end[0]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumHour">{{ h.value }}</a-select-option>
                </a-select>

                <span style="margin: auto 10px">:</span>
                <a-select
                  class="content"
                  placeholder="分"
                  style="width: 40%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].end[1]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumMinture">{{
                    h.value === 0 ? '00' : h.value
                  }}</a-select-option>
                </a-select>
              </a-input-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-anchor :affix="false" @change="onChangeAnchor" v-show="false">
      <a-anchor-link v-for="(item, key) in data" :key="key" :href="`#${item.id}_${item.text}`" />
    </a-anchor>
  </div>
</template>
<script>
import { test } from '@/common/test'
import { enumUnit, enumHour, enumMinture } from '@/common/enums'
import { getColFromNum } from '../utils'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'SubjectCompMobileNew',
  created() {
    this.$moment.locale('zh-cn')
  },
  props: {
    formaaa: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    },
    childrenRequire: {
      type: Boolean,
      default: () => false
    },
    parentOrder: {
      type: Number,
      default: () => null
    },
    disabled: { type: Boolean, default: () => false }
  },
  watch: {
    formaaa(v) {
      this.formdata = v
    }
  },
  data() {
    return {
      isShow: true,
      options: test,
      formdata: this.formaaa,
      enumHour,
      enumMinture
    }
  },
  methods: {
    chkvalueChange(a, value, callback, item) {
      if (item.chkNumberMin && value.length < item.chkNumberMin) {
        callback(new Error(`至少选择${item.chkNumberMin}项`))
      } else {
        callback()
      }
    },
    // 预览关闭
    handleCancel(item) {
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`] = false
    },
    // 预览
    async handlePreview(file, item) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewImage`] = file.url || file.preview
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`] = true
    },
    //
    async handleChange({ fileList }, item) {
      this.formdata[`${item.id}_${item.type}_${item.docField}_image`] = fileList
      if (fileList.length > 0) {
        fileList.map(async r => {
          if (!r.url && !r.preview) {
            r.preview = await getBase64(r.originFileObj)
          }
        })
      }
    },
    onChangeAnchor(link) {
      this.data.map(r => {
        if (r.describe && r.describeModel === true && link === `#${r.id}_${r.text}`) {
          if (!r['count']) {
            this.$notification['warning']({
              message: '如果在某个时间段内发生以上情况，均需在该时间段内填写信息。',
              duration: 6,
              style: { background: '#fffbe6', color: 'white !important' }
            })
            r['count'] = 1
          }
        }
      })
      console.log(link)
    },
    // 带检索的下拉框
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 多选个数限制
    checkgroupChange(value, item) {
      if (item.chkNumber && value.length === item.chkNumber) {
        item.optionArry.map(r => {
          if (value.indexOf(`${r.score}_${r.text}`) === -1) {
            r.disabled = true
          }
        })
        console.log('chkSelect', value)
        console.log('options', item)
      } else {
        item.optionArry.map(r => {
          r.disabled = false
        })
      }
    },
    childrenRequireChange(e, options) {
      var children = options.filter(r => r.children.length > 0)
      if (children.length > 0) {
        if (e.target.type === 'checkbox') {
          // 多选
          const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
          options.map(option => {
            if (value === option.text && option.children.length > 0) {
              if (e.target.checked) {
                option.childrenRequire = true
              } else {
                option.childrenRequire = false
              }
            }
          })
        } else {
          // 单选或下拉
          const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
          options.map(option => {
            if (value === option.text && option.children.length > 0) {
              option.childrenRequire = true
            } else {
              option.childrenRequire = false
            }
          })
        }
      }
    },
    radionChange(e, record) {
      const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
      var skipvalues = record.skipValue && JSON.parse(record.skipValue)
      if (skipvalues) {
        // 跳转值中最大的序号
        var max = 0
        skipvalues.map(r => {
          if (Number(r.order) > max) {
            max = Number(r.order)
          }
        })
        var skipvalue = skipvalues.filter(r => r.value === value)
        var endorder = null
        if (skipvalue.length > 0) {
          var skiporder = skipvalue[0].order
          this.data.map(r => {
            if (Number(r.order) > Number(record.order) && Number(r.order) <= max) {
              if (Number(r.order) > Number(record.order) && Number(r.order) < Number(skiporder)) {
                r.isShow = false
                this.formdata[`${r.id}_${r.type}_${r.docField}`] = undefined
              } else {
                r.isShow = true
                if (r.isEnd && !endorder) {
                  endorder = r.order
                }
              }
            }
          })
        } else {
          this.data.map(r => {
            if (Number(r.order) > Number(record.order) && Number(r.order) <= max) {
              r.isShow = true
              if (r.isEnd && !endorder) {
                endorder = r.order
              }
            }
          })
        }
        if (endorder) {
          this.data.map(r => {
            if (r.order > endorder) {
              r.isShow = false
            }
          })
        }
      }
      this.childrenRequireChange(e, record.optionArry)
      // 有公式时关联时
      if (record.isFormula) {
        // 单选取分值
        var score = e.target.value.slice(0, e.target.value.indexOf('_'))
        // 如果参与公式计算的话
        if (score !== '') {
          // 首先确保自己是有值的
          var qValue = [] // 值的数组{id,value}的集合
          qValue.push({ id: record.id.toString(), value: Number(score) }) // 先将当前值添加进去
          // 如果参与多个公式，需要一个个参与计算
          record.formulas.map(r => {
            // 如果有其他题目参与需要先判断其他题目是否已经有值了
            var otherCount = 0 // 用来判断其他的问题是不是都有值
            r.questions.map(q => {
              var key = q.id + '_' + q.type + '_' + q.docField
              if (q.type === 0) {
                // 数值型
                if (this.formdata[key] !== null) {
                  qValue.push({ id: q.id.toString(), value: Number(this.formdata[key]) })
                  otherCount++
                }
              } else if (q.type === 2) {
                // 单选
                if (this.formdata[key] !== null) {
                  var value = this.formdata[key].slice(0, this.formdata[key].indexOf('_'))
                  qValue.push({ id: q.id.toString(), value: Number(value) })
                  otherCount++
                }
              }
            })
            // 当参与计算的项都有值之后
            if (otherCount === r.questions.length) {
              // 按顺序将值付给ABCD
              var ids = r.formulaIDs.split(',')
              var gs = r.formula // 公式
              for (let i = ids.length - 1; i >= 0; i--) {
                var qKey = getColFromNum(i) // i从0开始，将变量key转化为ABC
                // 找到匹配的题目和答案,按理说应该有且只有一个
                var value = qValue.filter(a => a.id === ids[i])
                if (value.length > 0) {
                  gs = gs.replace(new RegExp(qKey, 'g'), value[0].value)
                }
              }
              // eslint-disable-next-line no-eval
              var result = Math.round(eval(gs) * 100) / 100
              var formulaKey = r.formulaQuestion.id + '_' + r.formulaQuestion.type + '_' + r.formulaQuestion.docField
              if (r.formulaQuestion.location === 'remark') {
                var formulaQ = this.data.filter(a => a.id === r.formulaQuestion.id) // 将结果的题目找出来
                // 给他的remark赋值
                formulaQ[0].remark = result
              } else {
                this.formdata[formulaKey] = result
              }
              console.log('结果', result)
            }
          })
        } else {
          // 否则已经生成的结果也要删掉
        }
      }
    },
    /**
     * 数值型ongchanges事件,改动后记得改另外两处,SubjectCompMobileNew和Preview
     */
    valueChange(e, record) {
      if (record.isFormula) {
        // 如果参与公式计算的话
        if (e !== '') {
          // 首先确保自己是有值的
          var qValue = [] // 值的数组{id,value}的集合
          qValue.push({ id: record.id.toString(), value: Number(e) }) // 先将当前值添加进去
          // 如果参与多个公式，需要一个个参与计算
          record.formulas.map(r => {
            // 如果有其他题目参与需要先判断其他题目是否已经有值了
            var otherCount = 0 // 用来判断其他的问题是不是都有值
            r.questions.map(q => {
              var key = q.id + '_' + q.type + '_' + q.docField
              if (this.formdata[key] !== null) {
                qValue.push({ id: q.id.toString(), value: Number(this.formdata[key]) })
                otherCount++
              }
            })
            // 当参与计算的项都有值之后
            if (otherCount === r.questions.length) {
              // 按顺序将值付给ABCD
              var ids = r.formulaIDs.split(',')
              var gs = r.formula // 公式
              for (let i = ids.length - 1; i >= 0; i--) {
                var qKey = getColFromNum(i) // i从0开始，将变量key转化为ABC

                // 找到匹配的题目和答案,按理说应该有且只有一个
                var value = qValue.filter(a => a.id === ids[i])
                if (value.length > 0) {
                  gs = gs.replace(new RegExp(qKey, 'g'), value[0].value)
                }
              }
              // eslint-disable-next-line no-eval
              var result = Math.round(eval(gs) * 100) / 100
              var formulaKey = r.formulaQuestion.id + '_' + r.formulaQuestion.type + '_' + r.formulaQuestion.docField
              if (r.formulaQuestion.location === 'remark') {
                var formulaQ = this.data.filter(a => a.id === r.formulaQuestion.id) // 将结果的题目找出来
                // 给他的remark赋值
                formulaQ[0].remark = result
              } else {
                this.formdata[formulaKey] = result
              }
              console.log('结果', result)
            }
          })
        } else {
          // 否则已经生成的结果也要删掉
        }
      }
    },

    getUnit(value) {
      if (value !== '') {
        var unit = enumUnit.filter(r => r.value === value)
        if (unit.length > 0) {
          return unit[0].text
        }
      }
      return ''
    },
    checkedChange(e, record, linkcol, item) {
      if (linkcol && linkcol.length > 0) {
        linkcol.map(r => {
          // record.key = record.key + '1'
          if (e.target.checked) {
            record[r + '_disabled'] = false
          } else {
            record[r] = undefined
            record[r + '_disabled'] = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less">
.mobile.ant-notification-notice-message {
  color: white !important;
}
</style>
<style lang="less" scoped>
.title {
  font: 2em sans-serif;
  text-align: center;
}

.marginTop {
  margin-top: 40px;
  font: 20px;
}
.marginSujectTop {
  margin-top: 10px;
  font: 20px;
}
.marginLeft {
  padding-left: 20px;
  text-align: left;
}
.content {
  font-size: 18px;
}
.ant-form label {
  font-size: 18px !important;
}
.subject {
  font-size: 18px;
  font-weight: bold;
}
.divStyle {
  width: 100%;
  height: 100%;
}
.divBac {
  background-color: white;
  //   background-image:none;
}
.widthStyle {
  width: 100%;
}
.divBorder {
  border: 1px solid #dadada;
  border-radius: 0.2em;
  padding: 10px;
}
.ant-input {
  height: 45px !important;
  line-height: 45px !important;
}
.ant-input-number {
  height: 45px !important;
  line-height: 45px !important;
}
.ant-calendar-picker /deep/ input {
  height: 45px !important;
  line-height: 45px !important;
}
.ant-cascader-picker /deep/ input {
  height: 45px !important;
  line-height: 45px !important;
}
.ant-radio-wrapper {
  white-space: pre-wrap !important;
}
label {
  display: block !important;
}
.ant-card {
  min-height: 40px !important;
}
</style>
