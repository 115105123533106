var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "third-module" }, [_vm._t("text1")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      _vm._l(_vm.tables, function(table, index) {
        return _c("a-table", {
          key: index,
          ref: "tables,2",
          refInFor: true,
          attrs: {
            pagination: false,
            dataSource: JSON.parse(table.data),
            columns: table.columns,
            size: "small"
          }
        })
      }),
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text2")], 2),
    _c("div", { staticClass: "third-module" }, [_vm._t("text3")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      [
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          _vm._l(_vm.imgs, function(ary, index) {
            return _c(
              "a-col",
              {
                key: index,
                attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }
              },
              [
                _c("img", {
                  ref: "imgs," + (_vm.indexT + 5),
                  refInFor: true,
                  attrs: { src: ary.src }
                })
              ]
            )
          }),
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text4")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }