<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module ">
      <a-table
        ref="tables,2"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        size="small"
        style="margin-top: 20px"
      >
        <div v-for="(item,i) in table.columns" :key="i" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
        <span slot="zhixin" slot-scope="text, record">
          <template>
            <span>{{ '(' + record['置信区间'][0] + ',' + record['置信区间'][1] + ')' }}</span>
          </template>
        </span>
        <span slot="number" slot-scope="text, record">
          <template>
            <span>{{ record['平均值'] + '±' + record['标准差'] }}</span>
          </template>
        </span>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="text2" ></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['tables', 'imgs'],
  created() {
    console.log('table', this.tables)
  },
  methods: {
    getPList(tables) {
      return tables.map(table => table.data.slice(-1)[0].p)
    }
  }
}
</script>
<style lang="less">
</style>