<template>
  <a-card :bordered="false" id="divPatientDetails">
    <a-row type="flex">
      <a-col :flex="1">
        <a-breadcrumb style="margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/question/List">问卷列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="`/question/sequentialStatistics/${Base64.encode(questionID)}`">患者列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>详情</a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-spin :spinning="spinning" />
    </a-row>
    <a-tabs default-active-key="1">
      <a-tab-pane v-for="(day, index) in days" :key="index" :tab="day.day">
        <a-descriptions title="">
          <a-descriptions-item v-for="(item, item_index) in day.info" :key="item_index" :label="item.Text">
            <span v-if="item.Text === '夜间最长睡眠时间'">
              <span v-if="JSON.stringify(item.Value).includes('null')">未填写</span>
              <span v-else>
                {{
                  `${format_sleep_time(item.Value.start[0])}:${format_sleep_time(
                    item.Value.start[1]
                  )} - ${format_sleep_time(item.Value.end[0])}:${format_sleep_time(item.Value.end[1])}`
                }}
              </span>
            </span>
            <span v-else>
              {{ Array.isArray(item.Value) ? item.Value.join('、') : item.Value }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-table
          class="th-background-color"
          v-if="day.day !== '手术前'"
          :columns="columns(index)"
          :dataSource="day.table.length > 0 ? day.table[0].Value : []"
          bordered
          size="small"
          :pagination="false"
          :scroll="{ x: true }"
        >
          <span slot="array" slot-scope="text">
            {{ Array.isArray(text) ? text.join('、') : text }}
          </span>
          <span slot="boolean" slot-scope="text">
            {{ text ? '是' : null }}
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
import { GetPatientDetails } from '@/api/questionnaire'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'

export default {
  name: 'SequentialStatisticsDetails',
  created() {
    // 高度自定义使用
    var max = []
    var min = ['divPatientDetails']
    AutoHeight(this, min, max, 215)
    this.questionID = this.$route.params.qID
    this.serialNumber = this.$route.params.sID
    this.getPatientDetails()
  },
  data() {
    return {
      days: [],
      Base64,
      questionID: null,
      spinning: true
    }
  },
  methods: {
    getPatientDetails() {
      GetPatientDetails({
        questionID: this.questionID,
        serialNumber: this.serialNumber
      }).then(res => {
        res.forEach(day => {
          day.answerOptions = JSON.parse(day.answerOptions)
          day.info = []
          day.table = []
          day.answerOptions.forEach(v => {
            // 如果答案里有`[`这个字符，那么把它反序列化一下
            if (v.Value.indexOf('[') !== -1) v.Value = JSON.parse(v.Value)
            // 把问题答案根据问题分类
            if (v.Text !== '用药、疼痛及排便情况' && v.Text !== '目前治疗阶段') day.info.push(v)
            if (v.Text === '用药、疼痛及排便情况') day.table.push(v)
          })
        })
        // 找到存放手术前相关信息的那个对象
        const objBefore = res.find(v => v.day === '手术前')
        if (objBefore) {
          // 删除手术前中的睡眠时间
          const index1 = objBefore.info.findIndex(v => v.Text === '睡眠时间')
          if (index1 !== -1) objBefore.info.splice(index1, 1)
          // 删除手术前中的夜间最长睡眠时间
          const index2 = objBefore.info.findIndex(v => v.Text === '夜间最长睡眠时间')
          if (index2 !== -1) objBefore.info.splice(index2, 1)
        }
        // 删除手术当天的手术日期的时分秒
        const objThatDay = res.find(v => v.day === '手术当天')
        if (objThatDay) {
          const obj = objThatDay.info.find(v => v.Text === '手术日期')
          // 如果有手术日期，那么格式化一下手术日期的时间
          if (obj) obj.Value = this.$moment(obj.Value).format('YYYY-MM-DD')
        }
        // 合并子父题
        this.handleChildAnswer(res)
        this.days = res
        this.$nextTick(() => {
          this.spinning = false
        })
      })
    },
    handleChildAnswer(daysObjAry) {
      /** 当一些题目中有其他选项时，需要将其他子题的答案合并到父题中。
       * @param {Array<Object>} daysObjAry 包含所有天信息对象的数组。
       */
      // 遍历包含所有天数的数组
      daysObjAry.forEach(dayObj => {
        if ('info' in dayObj) {
          // 遍历一天中的基本信息
          dayObj.info.forEach(qesObj => {
            // 如果一条信息对象中的ParentID有值，说明这是一个子题，需要把子题和父题的答案合并。
            if (qesObj.ParentID) {
              // 取到子题的id
              const childId = qesObj.ID
              // 取到子题的标题
              const childTitle = qesObj.ParentValue
              // 取到子题的值
              const childValue = qesObj.Value
              // 根据父题的id去基本信息数组中找到父题对象
              const parentObj = dayObj.info.find(v => v.ID === qesObj.ParentID)
              // 取到父题对象的值
              const value = parentObj.Value
              // 如果父题对象的值是数组
              if (Array.isArray(value)) {
                // 将数组中的其他替换成子题中的内容。
                const index = value.indexOf(childTitle)
                parentObj.Value.splice(index, 1, `${childTitle}（${childValue}）`)
              }
              // 根据子题的id找到在数组中的位置，并且删除它。
              const deleteIndex = dayObj.info.findIndex(v => v.id === childId)
              dayObj.info.splice(deleteIndex, 1)
            }
          })
        }
      })
    },
    // 睡眠时间中的0变成00
    format_sleep_time(value) {
      return value === 0 ? '00' : value
    }
  },
  computed: {
    columns() {
      return index => [
        {
          title: '时间',
          dataIndex: 'rname',
          width: 100,
          fixed: 'left'
        },
        {
          title: '用药情况',
          key: `${index}用药情况`,
          children: [
            {
              title: '是否使用药物',
              dataIndex: '是否使用药物',
              scopedSlots: { customRender: 'boolean' }
            },
            {
              title: '药物',
              dataIndex: '药物',
              scopedSlots: { customRender: 'array' }
            }
          ]
        },
        {
          title: '疼痛情况',
          key: `${index}疼痛情况`,
          children: [
            {
              title: '是否疼痛',
              dataIndex: '是否疼痛',
              scopedSlots: { customRender: 'boolean' }
            },
            {
              title: '是否加剧',
              dataIndex: '是否加剧',
              scopedSlots: { customRender: 'boolean' }
            },
            {
              title: '疼痛性质',
              dataIndex: '疼痛性质',
              scopedSlots: { customRender: 'array' }
            },
            {
              title: '疼痛程度',
              dataIndex: '疼痛程度'
            },
            {
              title: '疼痛持续时间',
              dataIndex: '疼痛持续时间'
            }
          ]
        },
        {
          title: '排便情况',
          key: `${index}排便情况`,
          children: [
            {
              title: '是否排便',
              dataIndex: '是否排便',
              scopedSlots: { customRender: 'boolean' }
            },
            {
              title: '排便性状',
              dataIndex: '排便性状'
            },
            {
              title: '排便持续时间',
              dataIndex: '排便持续时间'
            },
            {
              title: '便血量',
              dataIndex: '便血量'
            }
          ]
        }
      ]
    }
  }
}
</script>
<style lang="less">
.th-background-color {
  th {
    background-color: #fafafa !important;
  }
}
</style>