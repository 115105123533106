<!-- <template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <a href="https://www.github.com/vueComponent/pro-layout" target="_blank">Pro Layout</a>
      <a href="https://www.github.com/vueComponent/ant-design-vue-pro" target="_blank">Github</a>
      <a href="https://www.github.com/sendya/" target="_blank">@Sendya</a>
    </template>
    <template v-slot:copyright>
      <a href="https://github.com/vueComponent" target="_blank">vueComponent</a>
    </template>
  </global-footer>
</template> -->
<template>
  <global-footer class="footer custom-render" style="display:none">

    <template v-slot:links>
      <span><img src="/gongan.png" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021402001617" target="_blank">苏公网安备 32021402001617号</a></span>
      <a-divider type="vertical"></a-divider>
      <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备11090926号-3</a>
    </template>
    <template v-slot:copyright>
      <div class="copyright">Copyright &copy; 2020 无锡市优特科科技有限公司</div>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
