var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "hCommon" }, [_vm._v("人口学信息")]),
    _c(
      "div",
      { staticClass: "itemWrapperCommon" },
      [
        _c(
          "div",
          { staticClass: "itemWrapperCommonPadding40", attrs: { id: "age" } },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("年龄")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.agelostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.agelostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("平均值")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.ageavg))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("最小值")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.agemin))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("最大值")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.agemax))
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("div", {
                            staticStyle: {
                              width: "250px",
                              height: "140px",
                              float: "right"
                            },
                            attrs: { id: "myChart" }
                          })
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { staticStyle: { "margin-left": "10px" } },
          [
            _c(
              "a-col",
              { attrs: { span: "24" } },
              [
                _c(
                  "a-tabs",
                  { attrs: { type: "card" } },
                  [_c("a-tab-pane", { key: "1", attrs: { tab: "统计" } })],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "itemWrapperCommonPaddingLeft" },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "24" } }, [
                          _c("span", { staticClass: "htCommon" }, [
                            _vm._v("分位数统计")
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("最小值")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agemin))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("第5百分位数")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agepercent5))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("Q1")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.ageq1))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("中位数")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agemedian))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("Q3")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.ageq3))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("第95百分位数")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agepercent95))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("最大值")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agemax))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("极差")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agerange))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("四分位间距(IQR)")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agequartile))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "24" } }, [
                          _c("span", { staticClass: "htCommon" }, [
                            _vm._v("描述型类型")
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("均值")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.ageavg))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("标准差")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agestd))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("变异系数")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agecv))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("divider-common"),
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "httCommon" }, [
                            _vm._v("方差")
                          ])
                        ]),
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c("span", { staticClass: "ttCommon" }, [
                            _vm._v(_vm._s(_vm.datapro.agefc))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          { staticClass: "itemWrapperCommonPaddingLeft", attrs: { id: "sex" } },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("性别")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("男")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.sexmalePercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("女")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.sexfmalePercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.sexlostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.sexlostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.sexChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.barExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "nation" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("民族")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("汉族")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.nationhanPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("少数民族")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.nationotherPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.nationlostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.nationlostPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.nationChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.barExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "marry" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("婚姻状况")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("未婚")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.datapro.marryunmarriedPercent
                                      ) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("已婚")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.marrymarriedPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("离婚")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.marrydivorcePercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("其他")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.marryotherPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.datapro.marrylostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.datapro.marrylostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-histogram", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.marryChart,
                                height: "200px",
                                width: "250px",
                                "legend-visible": false,
                                extend: _vm.histogramExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }