<template>
  <div style="overflow: auto" id="childContent">
    <keep-alive>
      <component v-if="$route.meta.keepAlive" :is="currentComponent"></component>
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :is="currentComponent"></component>
  </div>
</template>
<script>
import Cox from './components/generateAnalysis/cox'
import Dysscfx from './components/generateAnalysis/dysscfx'
import Fitting from './components/fitting/fitting'
import GeneralizedOrdinaryLeastSquares from './components/linearRegression/generalizedOrdinaryLeastSquares'
import Logic from './components/nonlinearRegression/logic'
import MultipleLogic from './components/nonlinearRegression/multipleLogic'
import OrdinaryLeastSquares from './components/linearRegression/ordinaryLeastSquares'
import PolynomialRegression from './components/nonlinearRegression/polynomialRegression'
import RobustRegression from './components/linearRegression/robustRegression'
import SplineSmooth from './components/smooth/splineSmooth'
import PCA from './components/pca/pca'
import Index from './components/select'

export default {
  name: 'IndexChild',
  // created() {
  //   this.test(3, 3)
  // },
  data() {
    return {
      currentComponent: this.$route.params.name
    }
  },
  components: {
    Cox,
    Dysscfx,
    Fitting,
    GeneralizedOrdinaryLeastSquares,
    Logic,
    MultipleLogic,
    OrdinaryLeastSquares,
    PolynomialRegression,
    RobustRegression,
    SplineSmooth,
    Index,
    PCA
  },
  watch: {
    $route() {
      this.currentComponent = this.$route.params.name
    }
  }
}
</script>