<template>
  <div>
    <a-modal
      title="新增实验数据"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="$emit('handleCancel')"
    >
      <a-form-model ref="ruleForm" :model="form" labelAlign="left" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="实验人员" prop="userID">
          <a-select v-model="form.userID" placeholder="请选择实验人员">
            <a-select-option v-for="user in exp.users" :key="user.id">
              {{ user.userName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="实验设备" prop="deviceID">
          <a-select v-model="form.deviceID" placeholder="请选择实验设备">
            <a-select-option v-for="device in exp.devices" :key="device.id">
              {{ device.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="试剂批号" prop="reagentID">
          <a-select v-model="form.reagentID" placeholder="请选择试剂批号">
            <a-select-option v-for="reagent in res.reagents" :key="reagent.id">
              {{ reagent.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="实验时间" prop="date">
          <a-date-picker
            style="width: 100%"
            :value="form.date?$moment(form.date):undefined"
            @change="(date, dateString) => (date?(form.date = date.format()):(form.date=undefined))"
          />
        </a-form-model-item> -->
        <a-form-model-item label="实验时间" prop="date">
          <a-date-picker
            style="width: 100%"
            v-model="form.date"
          />
        </a-form-model-item>
        <a-divider />
        <a-row :gutter="8">
          <a-col v-for="(dataDetail, i) in form.dataDetails" :key="i" :span="24">
            <div style="border: 1px solid #e8e8e8; padding: 10px">
              <h4>{{ dataDetail.concentrationName }}</h4>
              <a-divider style="margin: 12px 0" />
              <div v-if="exp.accuracyFunction!==1">
                <a-form-model-item
                  :prop="`dataDetails[${i}].concentrationValue[${index}]`"
                  :rules="{ required: true, message: '测量值不能为空', trigger: 'blur' }"
                  :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 20 }"
                  v-for="(value, index) in dataDetail.concentrationValue"
                  :key="index"
                  :label="`重复${index + 1}`"
                >
                  <a-input-number
                    id="inputNumber"
                    style="width: 90%; margin-left: 5px"
                    v-model="form.dataDetails[i].concentrationValue[index]"
                    :step="0.1"
                    placeholder="请输入测量值"
                  />
                </a-form-model-item>
              </div>
              <div v-else-if="exp.accuracyFunction === 1">
                <a-form-model-item
                  :prop="`dataDetails[${i}].concentrationValue[0]`"
                  :rules="{ required: true, message: '测量值不能为空', trigger: 'blur' }"
                  :label-col="{ span: 6 }"
                  :wrapper-col="{ span: 18 }"
                  label="试验方法结果"
                >
                  <a-input-number
                    id="inputNumber"
                    style="width: 90%; margin-left: 5px"
                    v-model="form.dataDetails[i].concentrationValue[0]"
                    :step="0.1"
                    placeholder="请输入测量值"
                  />
                </a-form-model-item>
                <a-form-model-item
                  :prop="`dataDetails[${i}].concentrationValue[1]`"
                  :rules="{ required: true, message: '测量值不能为空', trigger: 'blur' }"
                  :label-col="{ span: 6 }"
                  :wrapper-col="{ span: 18 }"
                  label="比较方法结果"
                >
                  <a-input-number
                    id="inputNumber"
                    style="width: 90%; margin-left: 5px"
                    v-model="form.dataDetails[i].concentrationValue[1]"
                    :step="0.1"
                    placeholder="请输入测量值"
                  />
                </a-form-model-item>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-form-model-item label="质控结果" prop="result">
          <a-radio-group v-model="form.result">
            <a-radio :value="0"> 通过 </a-radio>
            <a-radio :value="1"> 未通过 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { Add } from '@/api/exp/experimentData'
export default {
  props: ['visible', 'exp'],
  data() {
    return {
      confirmLoading: false,
      res: {
        devices: [],
        users: [],
        reagents: []
      },
      form: {
        experimentID: null,
        userID: undefined,
        deviceID: undefined,
        reagentID: undefined,
        date: this.$moment(new Date()),
        result: undefined,
        dataDetails: []
      }
    }
  },
  methods: {
    handleOk(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.ModalText = 'The modal will be closed after two seconds'
          this.confirmLoading = true
          const form = this.$deepClone(this.form)
          // moment对象没法深拷贝，所以手动赋值一下
          form.date = this.form.date
          // 格式化concentrationValue，将数组转换成字符串
          form.dataDetails.forEach(v => {
            v.count = v.concentrationValue.length
            v.concentrationValue = JSON.stringify(v.concentrationValue)
          })
          // 格式化一下日期，把moment格式转化成字符串
          form.date = form.date.format()
          Add(form).then(res => {
            console.log('%c 🥧 res: ', 'font-size:20px;background-color: #FCA650;color:#fff;', res)
            this.$emit('handleCancel')
            this.$emit('refTable')
            this.$refs.ruleForm.resetFields()
            this.form.date = this.$moment(new Date())
            this.confirmLoading = false
          })
        } else {
          console.log('error submit!!')
          console.log(this.form)
          return false
        }
      })
      //   this.visible = false
    },
    // 获取数据详情
    fetchDataDetails() {
      this.form.dataDetails = JSON.parse(this.exp.sampleConcentrations).map(v => {
        const obj = {
          concentrationName: v,
          concentrationValue: []
        }
        for (let index = 0; index < this.exp.repeatNum; index++) {
          obj.concentrationValue.push(undefined)
        }
        return obj
      })
    },
    fetchReagents() {
      this.res.reagents = this.exp.reagents.filter((v) => v.type === 0)
    }
  },
  watch: {
    exp() {
      this.fetchReagents()
      this.fetchDataDetails()
      this.form.experimentID = this.exp.id
    }
  }
}
</script>
<style scoped>
.ant-form-item {
  margin: 12px 0;
}
</style>