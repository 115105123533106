import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
var xysName = 'sybbljyXys'
const Option = {
  makeOptions(obj, options) {
    obj.options = options.map(v => ({
      text: v,
      value: v
    }))
  },
  clearOptions(obj, that) {
    obj.options = []
    obj.value = ''
    that.formAddKey(obj)
  }
}

export const settings = [{
    id: 0,
    prop: 'lei2',
    label: '样本【定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '默认', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [{
        id: 2,
        label: '类别',
        prop: 'option',
        parentValue: 1,
        type: 'radio',
        value: '',
        options: [],
        rules() {
          return [{
            required: true,
            message: this.label + '不能为空',
            trigger: 'blur'
          }]
        },
        children: []
      },
      {
        label: '类别',
        parentValue: '默认',
        type: 'text',
        value: '请先向样本【定类】中拖入变量',
        children: []
      }
    ], // 必填项1
    onChange(obj, item, that) {
      onChange.manyLei(that.$store.state.hypothesis[xysName], this.id, item)
      Option.makeOptions(that.$hypothesis.findChildren(2, that.settings), item.options, that)
      this.value = 1
    },
    onClose(obj, item, that, index) {
      onClose.manyLei(that.$store.state.hypothesis[xysName], this.id, index)
      Option.clearOptions(that.$hypothesis.findChildren(2, that.settings), that)
      this.value = '默认'
    }
  },
  {
    label: '比率值',
    parentValue: 1,
    type: 'textarea',
    value($store, settings, $hypothesis) {
      if ($store.state.hypothesis[xysName][0].length > 0 && settings[0].children[0].value) {
        const option = $hypothesis.findChildren(2, settings).value
        const lei = $store.state.hypothesis[xysName][0][0].slice(-1)[0]
        const lei1 = $store.state.hypothesis[xysName][0][0].slice(0, -1).join(' + ')
        return `${$hypothesis.minimum(option, lei)}&nbsp;&nbsp;或&nbsp;&nbsp${$hypothesis.minimum(option, lei1)}`
      } else {
        return `<span style="color:red">请先向样本【定类】中拖入变量并选择一个类别</span>`
      }
    },
    children: []
  },
  {
    id: 1, // 之前换过顺序，所以为1
    prop: 'lei1',
    label: '组别【定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '默认', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    optLimit: 2,
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)，且变量中只能有两种类别'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [],
    onChange(obj, item, that) {
      onChange.manyLei(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.manyLei(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },

  {
    label: '比率差值对比',
    prop: 'proportion',
    parentValue: 1,
    value: '0',
    placeholder: '请填写比率差值对比',
    type: 'input',
    rules() {
      return [{
          required: true,
          message: this.label + '不能为空',
          trigger: 'change'
        },
        {
          pattern: '^(?:0|1|0.(?!0+$)[0-9]+)$',
          message: '请填写0~1之间的数值',
          trigger: 'blur'
        }
      ]
    },
    options: [],
    children: []
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  },
  {
    label: '判断条件',
    prop: 'judge',
    parentValue: 1,
    type: 'radio',
    value: '＝',
    options: [{
      text: '≥',
      value: '≥'
    }, {
      text: '=',
      value: '＝'
    }, {
      text: '≤',
      value: '≤'
    }],
    children: []
  }
]