/* eslint-disable no-useless-constructor */
import {
  Req,
  deepClone
} from '@/utils/util'
import {
  ParamStatistic
} from '@/views/hypothesisTest/utils/paramStatisticClass'
export class MakeReq extends Req {
  constructor(form, method, that) {
    super(form, method, that)
  }
  // 例如pdybtjy
  pdybtjyLei(indexLei, indexLiang) {
    this.form.list = []
    this.form.numpyList = []
    ///  描述性分析结构
    var statisObj = {}
    this.form.xys[indexLei].forEach(j => {
      statisObj[j.slice(-1)[0]] = {}
      var j2 = deepClone(j)
      j2.pop()
      j2.forEach(m => {
        statisObj[j.slice(-1)[0]][m] = []
      })
    })
    this.statisObj = statisObj
    // // 重构以后数据结构改变
    // this.form.xys[0] = this.form.xys[0][0]
    // //
    var xys = this.form.xys
    for (var i = 0; i < xys[indexLei][0].length - 1; i++) {
      this.form.list.push([])
      this.form.numpyList.push([])
    }
    var optionList = xys[indexLei][0].slice(0, xys[indexLei][0].length - 1)
    console.log('%c 🍝 optionList: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', optionList)
    var lei = xys[indexLei][0][xys[indexLei][0].length - 1]
    var liang = xys[indexLiang][0]
    console.log('%c 🍠 lei: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', lei, liang)
    this.that.documents.forEach(element => {
      // 确保每个人性别有值，并且每个人年龄也有值
      if (element[lei] && element[liang]) {
        optionList.forEach((gender, index) => {
          // 如果当前人的性别等于当前性别，那么存到比较的两个列表的对应下标的列表中
          if (this.that.$formatOption.main(element[lei]) === this.that.$formatOption.main(gender)) {
            // 如果当前人等于配对中的第二个，那么值加上比较值存入所在数组中
            const value = isNaN(Number(element[liang])) ? element[liang] : Number(element[liang])
            this.form.list[index].push(value)
            if (index === 1) {
              this.form.numpyList[index].push(parseFloat(element[liang]) + this.form.reducedValue)
            } else {
              this.form.numpyList[index].push(parseFloat(element[liang]))
            }
          }
        })
        // 构造变量统计表需要的结构
        var paramStatistic = new ParamStatistic(element, this.form.xys[indexLei], this.form.xys[indexLiang], this.statisObj)
        paramStatistic.LeiDuoLiang1()
      }
      //
    })
    this.form.msxfxReq = this.statisObj
    return this.req
  }
  pdybtjyLiang() {
    this.form.list = [
      [],
      []
    ]
    this.form.numpyList = [
      [],
      []
    ]
    const liangList = this.form.xys[0].concat(this.form.xys[1])
    this.descriptionLiangDuo(liangList)
    this.that.documents.forEach(element => {
      if (element[this.form.xys[0][0]] && element[this.form.xys[1][0]]) {
        this.form.list[0].push(parseFloat(element[this.form.xys[0][0]]))
        this.form.list[1].push(parseFloat(element[this.form.xys[1][0]]))
        this.form.numpyList[0].push(parseFloat(element[this.form.xys[0][0]]))
        this.form.numpyList[1].push(parseFloat(element[this.form.xys[1][0]]) + this.form.reducedValue)
        new ParamStatistic(element, [], liangList, this.form.description).liangDuo()
      }
    })
    return this.req
  }
}