<template>
  <!-- 双因素方差分析 -->
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <a-table
        ref="tables,2"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        size="small"
      >
        <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="textBack" :text_list="text_list"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
export default {
  name: 'DoubleVariance',
  props: ['data', 'xys'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'DoubleVariance', data: this.data, xys: this.xys }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '双因素方差分析（开发中）',
          msg: res
        })
      } else {
        // 取到table obj中的data数组
        const data_of_table = res.tables[0].data
        // 获取文字描述的数组
        const text_list = this.get_text(data_of_table)
        this.text_list = text_list
        // 取到p值小于0的定类数组
        const diff_lei_list = this.get_less_p_list(data_of_table)
        this.$emit('diff_lei_list', diff_lei_list)
        this.tables = res.tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: [],
      text_list: []
    }
  },
  methods: {
    get_text(data_of_table) {
      const { a } = this
      const liang = this.xys[1][0]
      const text_list = []
      data_of_table = data_of_table.slice(0, -1)
      data_of_table.forEach(row => {
        const { index, p } = row
        const text = this.$hypothesis.conclusion2(p, a, `${index}对${liang}的影响不显著`, `${index}对${liang}的影响显著`, index)
        text_list.push(text)
      })
      return text_list
    },
    get_less_p_list(data_of_table) {
      const { a } = this
      data_of_table = data_of_table.slice(0, -1)
      const diff_lei_list = []
      data_of_table.forEach(row => {
        const { index, p } = row
        if (p < a) diff_lei_list.push(index)
      })
      return diff_lei_list
    }
  },
  computed: {
    a() { return this.$store.state.report.a }
  }
}
</script>