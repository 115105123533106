<template>
  <div>
    <div style="color: #bfbfbf" v-if="yList.length === 0">请向因变量y【定类】中拖入变量</div>
    <a-radio-group v-else v-model="value">
      <a-radio :value="isNaN(+option) ? option : +option" v-for="(option, index) in options" :key="index">
        {{ option }}
      </a-radio>
    </a-radio-group>
  </div>
</template>
<script>
export default {
  props: ['item', 'settings', 'load', 'vuex'],
  data() {
    return {
      options: [],
      value: ''
    }
  },
  computed: {
    yList() {
      return this.settings[1].list
    }
  },
  watch: {
    yList(newVal) {
      if (newVal.length > 0) {
        const options = newVal[0].options
        this.options = options
      } else {
        this.options = []
        this.value = ''
      }
    },
    value(newVal) {
      this.$emit('updateForm', 'yAs1', newVal)
    }
  }
}
</script>