var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-header-wrapper", { attrs: { breadcrumb: null } }, [
    _c(
      "div",
      [
        _c(
          "a-layout",
          { staticClass: "divContent", attrs: { id: "divContent" } },
          [
            _c(
              "a-row",
              { staticStyle: { width: "100% !important" } },
              [
                _c(
                  "a-col",
                  {
                    staticStyle: { "border-right": "1px solid #dedede" },
                    attrs: { span: 4 }
                  },
                  [
                    _c("exp-menu", { on: { changeClick: _vm.changeComponent } })
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticStyle: { overflow: "auto" },
                    attrs: { span: 20, id: "chartContent" }
                  },
                  [_c("router-view", { staticStyle: { padding: "20px" } })],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }