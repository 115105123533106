<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'anderson检验分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row v-if="data.length > 0">
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'anderson检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['statistic', this.part2.pName])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.oneLiang()
    }
  },
  computed: {
    distribution() { return this.$hypothesis.distribution[this.part2.distribution] },
    liang() { return this.part2.xys[0][0] },
    H0() { return [`&lt;${this.liang}&gt;服从${this.distribution}`] },
    H1() { return [`&lt;${this.liang}&gt;不服从${this.distribution}`] },
    result() { return this.part2[this.part2.pName] ? [`1.${this.$hypothesis.conclusion2(this.part2[this.part2.pName], this.part2.statistic, this.H0[0], this.H1[0], 'anderson', this.part2.pName, 'statistic')}`] : [] }
  }
}
</script>