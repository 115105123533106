<template>
  <div id="personLayout">
    <div id="top">
      <img id="uuBigData" src="/gr_logo2.png" />
    </div>
    <div id="container">
      <router-view></router-view>
    </div>
    <div id="footer">
      <div class="links">
        <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备11090926号-4</a>
      </div>
      <div class="copyright">
        <div class="copyright">Copyright &copy; 2021 无锡市优特科科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'

export default {
  name: 'PersonLayout',
  mixins: [deviceMixin],
  mounted() {
    console.log('......')
    document.body.classList.add('personLayout')
  },
  beforeDestroy() {
    document.body.classList.remove('personLayout')
  }
}
</script>

<style lang="less" scoped>
#personLayout {
  height: 100%;
  background: #000610 url(~@/assets/gr_zhucebg.png) no-repeat;
  background-size: 100%;
  #top {
    position: relative;
    height: 18%;

    #uuBigData {
      position: absolute;
      top: 25%;
      left: 5%;
    }
  }
  #container {
    #registerContainer {
      background: #ffffff;
      #firstLine {
        font-size: 25px;
        font-weight: 1000;
      }
      #formWrapper {
        margin-top: 20px;
        #verification .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
  #footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 16px;
    margin: 48px 0 24px;
    text-align: center;

    .links {
      margin-bottom: 8px;
      font-size: 14px;
      a {
        color: white;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .copyright {
      color: white;
      font-size: 14px;
    }
  }
}
</style>
