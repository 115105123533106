<template>
  <a-modal
    title="二维码"
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
    :footer="null">
    <div style="width:100%;text-align:center">
      <div >{{ url }}  </div><br/>
      <div>已复制到剪贴板</div>
      <canvas style="margin:0 auto" id="QRCode"></canvas>
    </div>
  </a-modal>
</template>
<script>
import QRCode from 'qrcode'
var canvas = ''
export default {
props: {
    url: {
        type: String,
        default: () => ''
    },
    visible: {
        type: Boolean,
        default: () => ''
    }
},
mounted() {
    this.$nextTick(() => {
        canvas = document.getElementById('QRCode')
        QRCode.toCanvas(canvas, this.url, (error) => {
            console.log(error)
        })
    })
}
}

</script>