var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "methodWrapper",
      staticStyle: { padding: "15px", overflow: "auto" },
      attrs: { id: "method" }
    },
    [
      _c(
        "a-collapse",
        { attrs: { activeKey: [0, 1, 2, 3, 4, 5], bordered: false } },
        _vm._l(_vm.methods2, function(item, index) {
          return _c(
            "a-collapse-panel",
            {
              key: index.toString(),
              staticStyle: { "border-bottom": "1px solid rgb(232, 232, 232)" },
              attrs: { header: item.name }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [10, 8] } },
                _vm._l(item.children, function(item1, index1) {
                  return _c("a-col", { key: index1, attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        class: {
                          item: true,
                          active: _vm.active === item1.componentName,
                          disabled: item1.state === 0
                        },
                        on: {
                          click: function($event) {
                            return _vm.onClick(item1)
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item1.name) }
                        })
                      ]
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }