// import store from '@/store/index'
import {
  deepClone
} from '@/utils/util'

export default function makeReq(form, documents) {
  if (form.xys[2].length) {
    weight(form, documents)
  } else {
    //   往form里面添加维度的列表
    var x = form.xys[0][0]
    var y = form.xys[1][0]
    // const documents = store.state.samples.documents ? JSON.parse(store.state.samples.documents) : []
    // 由于后期重构导致的修改代码
    // x.unshift(x.pop())
    // y.unshift(y.pop())
    // form.xys = [x, y]
    //
    form.dimension = [x.length - 1, y.length - 1]
    form.list = []
    for (var i = 0; i < x.length - 1; i++) {
      form.list.push([])
    }
    form.list.forEach(v => {
      for (var j = 0; j < y.length - 1; j++) {
        v.push(0)
      }
    })
    var lei1 = x[x.length - 1]
    var lei2 = y[y.length - 1]
    documents.forEach(element => {
      // 取到男在列表['性别','男','女']中的下标，小学在列表['文化程度','小学',...]中的下标，添加到下标对应的数组中。
      // [[0,2,5,9,1,7,6,2,0],[0,3,4,1,5,10,11,13,5]]
      try {
        var xIndex = x.indexOf(element[lei1])
        var yIndex = y.indexOf(element[lei2])
        form.list[xIndex][yIndex]++
      } catch (err) {
        console.log('%c 🍇 xIndex: ', 'font-size:20px;background-color: #465975;color:#fff;', xIndex)
        console.log('%c 🥟 yIndex: ', 'font-size:20px;background-color: #FCA650;color:#fff;', yIndex)
        console.log('%c 🍢 element[lei1]: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', element[lei1])
        console.log('%c 🍥 element[lei2]: ', 'font-size:20px;background-color: #FCA650;color:#fff;', element[lei2])
      }
    })
    console.log(form.list)
    var list1 = []
    // [0,5,9,10,6,17,17,15,5] 用于计算期望频数
    for (var k = 0; k < y.length - 1; k++) {
      var total = 0
      for (var m = 0; m < x.length - 1; m++) {
        total += form.list[m][k]
      }
      list1.push(total)
    }
    var list2 = []
    //   [32,52] 用于计算期望频数
    form.list.forEach(element => {
      list2.push(element.reduce((a, b) => a + b))
    })
    // 总数N
    form.N = list2.reduce((a, b) => a + b)
    // 期望频数数组
    form.E = []
    // E2是二维的期望频数列表，E是一维的。
    form.E2 = deepClone(form.list)
    form.E2.forEach((v, index1) => {
      v.forEach((q, index2) => {
        var result = (list2[index1] * list1[index2]) / form.N
        form.E.push(result)
        v[index2] = result
      })
    })
    // 期望频数判断
    form.pyMethod = judgeE(form.dimension, form.N, form.E)
  }
}
// 期望频数判断
export function judgeE(dimension, N, E) {
  let pyMethod
  if (dimension[0] === 2 && dimension[1] === 2) {
    if (N >= 40 && E.every(element => element >= 5)) {
      pyMethod = 'Pearson'
    } else if (N >= 40 && E.some(element => element >= 1 && element < 5)) {
      pyMethod = 'yates'
    } else if (N < 40 || E.some(element => element < 1)) {
      pyMethod = 'Fisher'
    } else {
      pyMethod = 'Pearson'
    }
  } else if ((dimension[0] >= 2 && dimension[1] > 2) || (dimension[1] >= 2 && dimension[0] > 2)) {
    if (computeEPercent(E) < 0.2 && E.every(element => element >= 1)) {
      pyMethod = 'Pearson'
    } else if (computeEPercent(E) > 0.2 || E.every(element => element < 1)) {
      pyMethod = 'yates'
    } else {
      pyMethod = 'Pearson'
    }
  } else {
    pyMethod = 'Pearson'
  }
  return pyMethod
}

function computeEPercent(E) {
  var Etotal = E.length
  var count = 0
  E.forEach(element => {
    if (element < 5) count++
  })
  return count / Etotal
}

const weight = (form, document) => {
  console.log('%c 🍤 document: ', 'font-size:20px;background-color: #F5CE50;color:#fff;', document);
  /**
   * 如果有加权项的话执行这个代码
   */
  const xOptAry = form.xys[0][0].slice(0, -1)
  console.log('%c 🍿 xOptAry: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', xOptAry);
  const xName = form.xys[0][0].slice(-1)[0]
  const yOptAry = form.xys[1][0].slice(0, -1)
  console.log('%c 🍜 yOptAry: ', 'font-size:20px;background-color: #B03734;color:#fff;', yOptAry);
  const yName = form.xys[1][0].slice(-1)[0]
  const weightName = form.xys[2][0]

  const list = xOptAry.map(v => {
    const ary = yOptAry.map(v => 0)
    return ary
  })

  document.forEach(doc => {
    const valueX = doc[xName]
    const valueY = doc[yName]
    const indexX = xOptAry.indexOf(valueX)
    const indexY = yOptAry.indexOf(valueY)
    const valueWeight = doc[weightName]
    list[indexX][indexY] = +valueWeight
  })
  console.log('%c 🍈 list: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', list);

  form.list = list
}