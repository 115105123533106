var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        confirmLoading: _vm.loading
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { form: _vm.form } },
              "a-form",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "div",
                { attrs: { hidden: "" } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["id"],
                            expression: "['id']"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "量表(问卷)名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "title",
                          {
                            rules: [
                              {
                                required: true,
                                message: "请填写量表(问卷)名称"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['title',{ rules:[\n            {\n              required: true,\n              message: '请填写量表(问卷)名称',\n            }]}]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "量表(问卷)模版" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "questionModelID",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "请选择量表(问卷)模版"
                                }
                              ]
                            }
                          ],
                          expression:
                            "['questionModelID',{ rules:[\n            {\n              required: true,\n              message: '请选择量表(问卷)模版',\n            }]}]"
                        }
                      ],
                      attrs: {
                        disabled:
                          _vm.model && _vm.model.numberOfPeople > 0
                            ? true
                            : false
                      }
                    },
                    _vm._l(_vm.models, function(item, i) {
                      return _c(
                        "a-select-option",
                        { key: i, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "有效期" } },
                [
                  _c("a-range-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "range-picker",
                          {
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "请选择有效期"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['range-picker', {rules: [{ type: 'array', required: true, message: '请选择有效期' }]}]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }