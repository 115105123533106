<template>
  <div>
    <MsxfxV ref="content,1" :tables="msxfxV.tables">
      <!-- <template #text1 class="text">
        <p :ref="`text,1,1`">
          定量数据的整体情况如下表所示：
        </p>
      </template> -->
      <!-- <template #text2 class="text">
        <Conclusion>
          <div>
            <p :ref="`text,1,1`">由上表可以看出定量数据的整体情况：</p>
            <p :ref="`text,1,2`">
              1）平均值反应数据的集中趋势；2） 标准差反应有效样本的波动情况；3）中位数反映有效样本数据升序后中间的数值；
              4）峰度和偏度用于判断数据正态性情况，峰度的绝对值越大，说明数据越陡峭，峰度的绝对值大于3，意味着数据严重不正态。同时偏度的绝对值越大，说明数据偏斜程度越高，偏度的绝对值大于3，意味着严重不正态。
            </p>
          </div>
        </Conclusion>
      </template> -->
    </MsxfxV>
  </div>
</template>
<script>
import MsxfxV from '@/views/report/modules/basicStatistics/msxfxV'
import MsxfxC from '@/views/report/modules/basicStatistics/msxfxC'
import { getModule } from '@/api/module'
import { DataFilterClass } from '@/utils/dataFilterClass'
import pick from 'lodash.pick'
export default {
  components: { MsxfxV, MsxfxC },
  props: ['fields', 'secondIndex'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    var data = [] // 清洗后的数据
    if (this.fields) {
      this.fields.map(r => {
        var rdata = new DataFilterClass([r]).main().map(a => pick(a, [r.name]))
        rdata.map(a => { a[r.name] = Number(a[r.name]) })
        data.push({ name: r.name, data: rdata })
      })
    }
    getModule({
      module_name: 'BasicStatistics',
      data: data,
      fields: this.fields,
      count: this.data ? this.data.length : 0,
      style: { language: this.$store.state.user.info.setting.language },
      method: 'msxfx',
      colors: JSON.parse(this.$store.state.user.info.setting.colors)
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '基础统计（描述性分析）',
          msg: res
        })
      } else {
        // 描述性分析
        const tablesV = res.msxfxV
        this.msxfxV.tables = tablesV
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      msxfxV: { tables: [], imgs: [] }
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
