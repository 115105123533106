<template>
  <div >
    <div v-for="(gp, secondIndex) in gpxys" :key="secondIndex">
      <div v-if="gp.type=='vc'">
        <div ref="title" class="second-title" :id="`2.${secondIndex + 1} 定类指标与定量指标的关系`" >
          2.{{ secondIndex + 1 }} 定类指标与定量指标的关系
        </div>
        <Conclusion >
          <p :ref="`text`">
            从抖动图、小提琴图和密度图上可以看出不同“定类指标”下“定量指标”的分布和概率密度。箱线图展示了不同“定类指标”下 “定量指标”的整体分布和异常数据情况。误差条图则体现了不同“定类指标”下“定量指标”的抽样误差。
          </p>
        </Conclusion>

        <vcfield
          class="second-module"
          ref="content"
          :gp="gp"
          :indexT="secondIndex + 1"
          style="padding: 0 25px"
        >
        </vcfield>
      </div>
      <div v-if="gp.type=='vv'">
        <div ref="title" class="second-title" :id="`2.${secondIndex + 1} 定类指标与定量指标的关系`" >
          2.{{ secondIndex + 1 }} 定量指标与定量指标的关系
        </div>
        <Conclusion >
          <p :ref="`text`">
            <span v-if="gp.xys.filter(r=>r.data.length>300).length>0">
              从密度散点图上可以看出“定量指标”与“定量指标”之间的关系及数据点的分布密度，红色的区域表示分析数据的密度大，蓝色的区域表示分析数据的密度小。
            </span>
            <span v-if="gp.xys.filter(r=>r.data.length<=300).length>0">
              从折线图上可以看出变化趋势。从散点图可以看出数据点在直角坐标系上的分布情况。
            </span>
          </p>
        </Conclusion>

        <vvfield
          class="second-module"
          ref="content"
          :gp="gp"
          :indexT="secondIndex + 1"
          style="padding: 0 25px"
        >
        </vvfield>
      </div>
      <div v-if="gp.type=='cc'">
        <div ref="title" class="second-title" :id="`2.${secondIndex + 1} 定类指标与${gp.xys[0].y[0].name }的关系`" >
          2.{{ secondIndex + 1 }} 两个定类指标的关系
        </div>
        <Conclusion >
          <p :ref="`text`">
            由饼图和柱状图可以看出两个“定类指标”之间的分布情况。
          </p>
        </Conclusion>

        <vcfield
          class="second-module"
          ref="content"
          :gp="gp"
          :indexT="secondIndex + 1"
          style="padding: 0 25px"
        >
        </vcfield>
      </div>
      <!-- <div v-if="gp.type=='cc'">
        <div v-for="(obj, thirdIndex) in gp.xys" :key="thirdIndex">
          <div ref="title" class="second-title" :id="`2.${secondIndex + 1} ${obj.title}`" >
            2.{{ secondIndex + 1 +thirdIndex }} {{ obj.title }}
          </div>
          <ccfield
            class="second-module"
            ref="content"
            :data="obj.data"
            :xys="obj"
            :indexT="secondIndex + 1"
            style="padding: 0 25px"
          >
          </ccfield>
        </div>
      </div> -->
    </div>

  </div>

</template>
<script>
import vcfield from './vcfield'
import vvfield from './vvfield'
import ccfield from './ccfield'
export default {
  created() {
    this.gpxys = []
    var vc = this.xys.filter(r => r.type == 'vc')
    if (vc.length > 0) {
      this.gpxys.push({ type: 'vc', xys: vc })
    }
    var vv = this.xys.filter(r => r.type == 'vv')

    if (vv.length > 0) {
      this.gpxys.push({ type: 'vv', xys: vv })
    }
    var cc = this.xys.filter(r => r.type == 'cc')

    if (cc.length > 0) {
      this.gpxys.push({ type: 'cc', xys: cc })
    }

    console.log('xyz', this.gpxys)
  },
  data() {
    return {
      gpxys: [],
      vcFiels: ''
    }
  },
  components: { vcfield, vvfield, ccfield },
  props: ['xys']
}
</script>
<style>
.third-module {
  margin: 15px 0;
}
</style>