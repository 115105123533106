<template>
  <index
    ref="index"
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{ xys, form }">
      <div v-if="xys[0].length > 0">
        <span v-for="(item, index) in H0(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
      </div>
    </template>
    <template #H1="{ xys, form }">
      <div v-if="xys[0].length > 0">
        <span v-for="(item, index) in H1(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
      </div>
    </template>
  </index>
</template>
<script>
import { settings, fetchParam } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'dybksjyXys',
      xys: [[]],
      formAddKeyName: 'dybksjyFormAddKey',
      settings,
      form: {
        judge: '＝',
        distribution: 'norm',
        a: '5',
        mean: '',
        std: '',
        min: '',
        max: '',
        λ: ''
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        // form.mean = ''
        // form.std = ''
        // form.min = ''
        // form.max = ''
        // form.λ = ''
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  },
  computed: {
    liang() { return xys => xys[0][0] },
    mark() {
      return (xys, form) => {
        switch (form.distribution) {
          case 'norm':
            return `<i>N</i> ( ${form.mean} , ${form.std} )`
          case 'uniform':
            return `<i>U</i>(${form.min},${form.max})`
          case 'expon':
            return `<i>E</i>(${form.λ})`
          default:
            return ''
        }
      }
    },
    H0() {
      return (xys, form) => {
        if (form.distribution === 'poisson') {
          return [`&lt;${this.liang(xys)}&gt;服从泊松分布，即升学考试分数F(x)(累积分布函数)＝G(x)(给定分布函数)`]
        } else {
          form.H0 = [`&lt;${this.liang(xys)}&gt;服从${this.mark(xys, form)}的${this.$hypothesis.distribution[form.distribution]}，即&lt;${this.liang(xys)}&gt;的累积分布函数＝${this.mark(xys, form)}的累积分布函数`]
          return form.H0
        }
      }
    },
    H1() {
      return (xys, form) => {
        if (form.distribution === 'poisson') {
          return [`&lt;${this.liang(xys)}&gt;不服从泊松分布，即升学考试分数F(x)(累积分布函数) ≠ G(x)(给定分布函数)`]
        } else {
          form.H0 = [`&lt;${this.liang(xys)}&gt;不服从${this.mark(xys, form)}的${this.$hypothesis.distribution[form.distribution]}，即&lt;${this.liang(xys)}&gt;的累积分布函数 ≠ ${this.mark(xys, form)}的累积分布函数`]
          return form.H0
        }
      }
    },
    outlier() {
      return this.$store.state.samples.filtersAll.outlier !== 0
    },
    without0() {
      return this.$store.state.samples.filtersSimple.without0 !== 0
    }
  },
  watch: {
    outlier() {
      // 如果框里面已经拖了变量了，那么就去计算平均值、标准差等。
      if (this.$store.state.hypothesis[this.xysName].length > 0) fetchParam(this.$refs.index)
    },
    without0() {
      if (this.$store.state.hypothesis[this.xysName].length > 0) fetchParam(this.$refs.index)
    }
  }
}
</script>