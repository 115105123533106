var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        confirmLoading: _vm.loading
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { form: _vm.form } },
              "a-form",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { hidden: "" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["id"],
                        expression: "['id']"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "设备名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              { required: true, message: "请填写设备名称" }
                            ]
                          }
                        ],
                        expression:
                          "['name',{rules:[{required:true,message:'请填写设备名称'}]}]"
                      }
                    ],
                    attrs: { placeholder: "请填写设备名称" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }