<template>
  <div class="innerWrapper" style="height: 100%">
    <a-drawer title="帮助" placement="right" :closable="false" :visible="visible" @close="onClose" :width="500">
      <help></help>
    </a-drawer>
    <a-row style="height: 100%">
      <a-col id="test" :span="24" style="min-height: 750px; padding: 15px; border-right: 1px solid rgb(232, 232, 232)">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          labelAlign="left"
          :labelCol="{ md: { span: 6 }, xxl: { span: 4 } }"
          :wrapperCol="{ md: { span: 18 }, xxl: { span: 20 } }"
        >
          <div style="margin-bottom: 10px">
            <a-button type="primary" @click="onClick" style="margin-bottom: 10px" :disabled="this.load.anysisLoading"
            >开始分析</a-button
            >
            <a-button style="margin-left: 10px" @click="resetForm" :disabled="this.load.anysisLoading">重置</a-button>
            <a-spin style="margin-left: 10px" v-if="this.load.anysisLoading"></a-spin>
            <a-icon type="question-circle" @click="showDrawer" theme="twoTone" style="float: right; font-size: 16px" />
          </div>
          <drop-items2
            :settings="settings"
            :indexSettings="indexSettings"
            :parentValue="1"
            :formAddKeyName="formAddKeyName"
            :xys="computedXys"
            :refs="$refs"
            :load="load"
            @updateForm="updateForm"
            @initialRules="initialRules(settings)"
          ></drop-items2>
          <slot name="extraInput" :xys="computedXys"></slot>
        </a-form-model>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import Help from './help'
import DropItems2 from './dropItems'
export default {
  components: { Help, DropItems2 },
  created() {
    this.initialSettings()
    this.initialRules(this.settings)
  },
  // 这个form是用来提交给后台的
  // 这个form是用来重置表单时候用的
  props: ['xysName', 'xys', 'vuexForm', 'formAddKeyName', 'pForm', 'pSettings'],
  data() {
    return {
      // 由于这里的settings和form是会根据页面改变的，但是Vue又不允许组件改变传递过来的参数，所以把传递过来的参数深拷贝一份再操作
      settings: this.$deepClone(this.pSettings),
      indexSettings: this.$deepClone(this.pSettings),
      form: this.$deepClone(this.pForm),
      indexForm: this.$deepClone(this.pForm),
      rules: {},
      visible: false,
      load: { anysisLoading: false }
    }
  },
  methods: {
    validateDrop(rule, value, callback, v, divHelp, that) {
      // 'required' in v直接通过是因为存在required:false，允许为空的话，所以能通过。
      // v为拖进拖拽框的每一个项
      if ('required' in v && v.list.length === 0) { callback() } else {
        // 如果拖拽框里的个数不满足要求，页面报错
        if (v.list.length < (v.min === 0 ? 0 : (v.min || 1)) || v.list.length > (v.num || 5)) callback(divHelp[0])
        // 如果拖拽框有自定义规则，那么执行自定义规则，例如线性回归里的最小二乘
        if ('rules' in v && v.rules.length > 0) v.rules.forEach(func => { func(callback, v, divHelp, that) })
        // 如果都满足条件，直接通过
        callback()
      }
    },
    /**
     * 子组件drop时调用的方法
     */
    drop() {
      this.anysisLoading = false
    },
    onClick() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('click', this)
        } else { return false }
      })
    },
    resetForm() {
      this.$hypothesis.resetForm(this, this.xysName, this.xys)
      this.initialRules(this.settings)
      this.$log('form', this.form)
    },
    initialSettings() {
      this.$hypothesis.initialSettings(this, this.xysName, this.$deepClone(this.xys), this.indexSettings, 'analysisOfRegression', this.vuexForm)
    },
    formAddKey(list) {
      list.forEach(element => {
        this.form[element.key] = element.value
      })
      this.$log('form', this.form)
    },
    updateForm(key, value) {
      this.form[key] = value
    },
    makeRules(settings) {
      settings.forEach(v => {
        if (v.type === 'drop' || v.type === 'dropSelect') {
          var divHelp = this.$deepClone(v.divHelp)
          this.rules[v.prop] = [{ validator: (rule, value, callback) => this.validateDrop(rule, value, callback, v, divHelp, this) }]
        } else {
          // 例如回归分析中的拟合
          if (typeof v.rules === 'function') this.rules[v.prop] = v.rules()
          // 例如逻辑回归
          if (typeof v.rules === 'object') this.rules[v.prop] = [{ validator: (rule, value, callback) => v.rules.forEach(func => { func(callback, v, this) }) }]
        }
        // 如果children里面有元素，那么递归向rules里添加规则
        if (v.children) this.makeRules(v.children)
      })
    },
    initialRules(settings) {
      this.rules = {}
      this.makeRules(settings)
    },
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    }
  },
  computed: {
    computedXys() {
      return this.$store.state.analysisOfRegression[this.xysName]
    },
    formAddKeyList() {
      return this.$store.state.analysisOfRegression[this.formAddKeyName]
    },
    formOfStore() {
      return this.$store.state.analysisOfRegression.ordinaryLeastSquaresForm.compareList
    },
    rulesController() {
      return this.$store.state.analysisOfRegression.rulesController
    }
  },

  watch: {
    // formAddKeyList: {
    //   immediate: true,
    //   handler(value) {
    //     console.log('sdfasfasdfsafdsfsaf')
    //     this.formAddKey(value)
    //   },
    //   deep: true
    // }
    formAddKeyList(value) {
      this.formAddKey(value)
    },
    rulesController() {
      this.initialRules(this.settings)
    }
  }
}
</script>