<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px; min-height: 750px; border-right: 1px solid rgb(232, 232, 232)">
            <a-row>
              <a-col :span="24">
                <!-- <a-collapse :activeKey="[]" :bordered="false">
                  <a-collapse-panel
                    v-for="(value, key, index) in part2.tables"
                    :key="index"
                    :header="key"
                    style="border-bottom: 1px solid rgb(232, 232, 232)"
                  >
                    <myTable :data="value.data" :columns="value.columns" :title="'多因素方差分析结果'"></myTable>
                  </a-collapse-panel>
                </a-collapse> -->
                <a-row style="margin-bottom: 5px">
                  <a-col :span="24">
                    <h1>描述性分析结果</h1>
                  </a-col>
                </a-row>
                <a-tabs type="editable-card" hide-add>
                  <a-tab-pane
                    v-for="(value, key, index) in part2.tables"
                    :key="index"
                    :tab="key"
                    style="padding-bottom: 1px"
                    :closable="false"
                  >
                    <myTable :data="value.data" :columns="value.columns" :title="''"></myTable>
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <myTable :data="data" :columns="columns" :title="'主体间效应检验'"></myTable>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'多因素方差智能分析结果'">
              <div
                v-for="(item, index) in part2.combine.map((v) => v.map((j) => `&lt;${j}&gt;`).join(''))"
                :key="index"
              >
                <span> {{ index + 1 }}. </span>
                <span v-if="part2.pList[index] instanceof String">
                  由于{{ item }}的<i>p</i>＝{{ part2.pList[index] }}
                  {{ $hypothesis.getJudgeByP(part2.pList[index]) }} 0.05，因此
                </span>
                <span v-html="result(item, part2.pList[index])"></span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: (columns, t, res) => this.$hypothesis.setColumns.fcfx(columns, t, res, this),
      method2: this.$hypothesis.setColumns.manyParamsWithNoP,
      method3: this.$hypothesis.setColumns.common,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        combine: [[''], []],
        xys: [[[], []], ['']],
        pList: ['', '']
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, 'statistic', this.setSecondTable)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.LeiDuoLiang1()
    },
    setSecondTable(res) {
      this.method3()
    }
  },
  computed: {
    liang() { return `&lt;${this.part2.xys[1][0]}&gt;` },
    H0() {
      return (lei) => `${lei}对${this.liang}的影响不显著`
    },
    H1() {
      return (lei) => `${lei}对${this.liang}的影响显著`
    },
    result() {
      return (lei, p) => {
        if (p <= 0.05) { return `拒绝H0（${this.H0(lei)}），接受H1（${this.H1(lei)}）` } else if (p > 0.05) { return `不拒绝H0（${this.H0(lei)}）` } else { return `数据质量不佳，无法分析` }
      }
    }
  }
}
</script>