<template>
    <ve-ring :data="data" :colors="color" height="300px" :settings="chartSettings" :graphic="graphic" :legend-visible="false" :extend="extend" :tooltip-visible="true"></ve-ring>
</template>
<script>
export default {
    name: 'MiniDoc',
    props: {
    data: {
      type: Object,
      default: () => []
    },
     color: {
      type: Array,
      default: () => []
    },
    graphic: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
       extend: {
        legend: {
          show: true,
          top: '80%'
        },
        label: {
          formatter: '20',
          color: '#000',
          fontSize: 16
        }
      },
        chartSettings: {
        offsetY: 130,
        radius: [50, 80],
        labelLine: {
          show: false
        },
        label: {
          show: false
        }
      }
    }
  }
}
</script>