import Vue from 'vue'
import Router from 'vue-router'
import {
  constantRouterMap
} from '@/config/router.config'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: constantRouterMap
})

// router.beforeEach((to, from, next) => {
//   console.log('%c 🍺 to: ', 'font-size:20px;background-color: #FCA650;color:#fff;', to)
//   next()
// })
export default router