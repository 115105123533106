import request from '@/utils/request'

const api = '/python/report/'
const setting_api = '/ReportSetting'

export function exportWord(parameter) {
  return request({
    url: `${api}export_word`,
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}

export function getSettingsByID(parameter) {
  return request({
    url: `${setting_api}/${parameter}`,
    method: 'get'
  })
}

export function updateSettings(parameter) {
  return request({
    url: setting_api,
    method: 'put',
    data: parameter
  })
}