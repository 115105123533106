import {
  DataFilterClass
} from '@/utils/dataFilterClass'
import {
  deepClone
} from '@/utils/util'
import {
  judgeE
} from '@/views/hypothesisTest/components/correlation/kffx/makeReq'
export default class DataClass {
  constructor(xys, type, xys2) {
    this.xys = xys
    this.xys2 = xys2
    this.type = type
    this.documentsList = this.filter()
  }

  main() {
    const result = []
    this.xys.forEach((group, gIndex) => {
      // 每一个{}里面存放的是每组变量生成的数据以及各种参数
      result.push({})
      const type = this.type[gIndex].value
      const obj = result[gIndex]
      // 执行init，构造每组变量的数据结构
      this[`${type}Init`](obj, group, this.xys2[gIndex])
      // 执行遍历documents的方法，往构造好的数据结构中添加数据
      this.documentsList[gIndex].forEach(doc => {
        this[type](obj, group, doc)
      })
      // 如果当前变量组还需要执行End方法，那么执行
      if (`${type}End` in this) {
        this[`${type}End`](obj, group)
      }
    })
    // this.xys.forEach((group, gIndex) => {
    //   const type = this.type[gIndex].value

    // })
    return result
  }

  filter() {
    const list = []
    this.xys.forEach(group => {
      const groupList = []
      group.forEach(drop => {
        drop.forEach(item => {
          if (groupList.indexOf(item) === -1) groupList.push(item)
        })
      })
      list.push(groupList)
    })
    const documents = list.map(gList => new DataFilterClass(gList, true).main())
    return documents
  }

  setXys(xys) {
    this.xys = xys
  }

  static make_key_value_data(list, key_list, doc) {
    /** 生成key:value形式的数据。
     * @param list 存放key_value对象的数组
     * @param key_list 存放变量名key的数组
     * @param doc 每一条数据
     */
    const obj = {}
    key_list.forEach(x => {
      // 如果是定类，那么取数组的最后一个值为名字
      const key = typeof (x) === 'object' ? x[x.length - 1] : x
      const value = doc[key]
      // 如果能转换成数字类型的就转换成数字，不能的维持原来的值
      obj[key] = isNaN(+value) ? value : +value
      // obj[key] = '' + value
    })
    list.push(obj)
  }

  logicInit(obj, group, xys2) {
    obj.data = []
    // obj.yAs1 = group[1][0][0]
    // 如果y中包含1，恶性等关键词是，选择这些关键词，如果不包含，那么默认选择第一个分类。
    const auto_select_yAs1 = () => {
      const optionAry = group[1][0].slice(0, 2)
      return optionAry.find(v => ['1', '恶性', '阳性', '是', '有'].includes('' + v)) || group[1][0][0]
    }
    obj.yAs1 = auto_select_yAs1()
    obj.xys = group
    obj.compareList = []
    obj.rocArgs = {
      x: deepClone(xys2[0].list),
      y: deepClone(xys2[1].list),
      z: [],
      m: []
    }
    // 生成compareList
    const xDrop = group[0]
    const leiList = []
    xDrop.forEach(v => {
      if (typeof (v) === 'object') {
        leiList.push(v)
      }
    })
    // 自动选择包含无否0字样作为对比参考项，要是没有这些字样，那么取最后一个分类
    obj.compareList = leiList.map(v => v.slice(0, -1).find(v => ['无', '否', '0'].includes('' + v)) || v.slice(0, -1).slice(-1)[0])
  }

  logic(obj, group, doc) {
    const paramAry = group[0].concat(group[1])
    DataClass.make_key_value_data(obj.data, paramAry, doc)
  }

  logicEnd(obj, group) {
    obj.rocData = JSON.stringify(group[0].map(v => {
      return {
        name: Array.isArray(v) ? v.slice(-1)[0] : v,
        data: obj.data
      }
    }))
    // 逻辑回归中y的值必须是字符串形式，因此这里再手动把y的值转换成字符串。
    const y_name = group[1][0].slice(-1)[0]
    obj.data.forEach(v => {
      v[y_name] = '' + v[y_name]
    })
  }

  doubleVarianceInit(obj, group) {
    const liang_name = group[1][0]
    obj.data1 = {}
    group[0].forEach(lei_list => {
      const lei_name = lei_list[lei_list.length - 1]
      obj.data1[lei_name] = {}
      obj.data1[lei_name][liang_name] = {}
      const lei_options_list = lei_list.slice(0, -1)
      lei_options_list.forEach(option => {
        obj.data1[lei_name][liang_name][option] = []
      })
    })
    obj.data2 = {}
    obj.data2[liang_name] = []
    obj.data3 = []
  }

  doubleVariance(obj, group, doc) {
    const liang_name = group[1][0]
    // 生成key,value数据格式
    const key_list = group[0].concat(group[1])
    DataClass.make_key_value_data(obj.data3, key_list, doc)
    // 生成对象数组形式
    group[0].forEach(lei_list => {
      const lei_name = lei_list[lei_list.length - 1]
      const lei_value = doc[lei_name]
      obj.data1[lei_name][liang_name][lei_value].push(+doc[liang_name])
    })
    // 生成只包含定量的总数的data2
    obj.data2[liang_name].push(+doc[liang_name])
  }

  position_for_two_liangInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const liang1 = group[0][0]
    const liang2 = group[1][0]
    obj.data1 = {}
    obj.data1[liang1] = []
    obj.data1[liang2] = []
  }

  position_for_two_liang(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    const liang1 = group[0][0]
    const liang2 = group[1][0]
    const value1 = +doc[liang1]
    const value2 = +doc[liang2]
    obj.data1[liang1].push(value1)
    obj.data1[liang2].push(value2)
    obj.lei = '变量'
  }

  robustRegressionInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const xDrop = group[0]
    // const yDrop = group[1]
    const leiResultList = []
    const liangResultList = []
    const liangResultList2 = []
    // 构造leiList结构并且构造一个只有定类的数组和一个只有定量的数组
    const leiList = []
    const liangList = []
    xDrop.forEach(v => {
      if (typeof (v) === 'object') {
        leiResultList.push([])
        leiList.push(v)
      } else {
        liangResultList2.push([])
        liangList.push(v)
      }
    })
    // 因变量y的结果数组
    const yList = []
    obj.leiList = leiList
    obj.liangList = liangList
    obj.liangResultList = liangResultList
    obj.liangResultList2 = liangResultList2
    obj.leiResultList = leiResultList
    obj.yList = yList
    obj.data = []
  }

  robustRegression(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    const {
      yList,
      leiList,
      leiResultList,
      liangList,
      liangResultList2,
      liangResultList
    } = obj
    const xDrop = group[0]
    const yDrop = group[1]
    const xyDrop = xDrop.concat(yDrop)

    yList.push(Number(doc[yDrop[0]]))
    leiList.forEach((v, index) => {
      const value = doc[v.slice(-1)[0]]
      leiResultList[index].push(value)
    })
    const list = []
    // eslint-disable-next-line eqeqeq
    liangList.forEach((v, index) => {
      list.push(Number(doc[v]))
      liangResultList2[index].push(Number(doc[v]))
    })
    liangResultList.push(list)
    // 生成key,value数据格式
    DataClass.make_key_value_data(obj.data, xyDrop, doc)
  }

  robustRegressionEnd(obj, group) {
    // 生成compareList
    obj.compareList = obj.leiList.map(v => v.slice(-2)[0])
    // 生成xys
    obj.xys = group
  }

  generalizedOrdinaryLeastSquaresInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const xDrop = group[0]
    // const yDrop = group[1]
    const leiResultList = []
    const liangResultList = []
    const liangResultList2 = []
    // 构造leiList结构并且构造一个只有定类的数组和一个只有定量的数组
    const leiList = []
    const liangList = []
    xDrop.forEach(v => {
      if (typeof (v) === 'object') {
        leiResultList.push([])
        leiList.push(v)
      } else {
        liangResultList2.push([])
        liangList.push(v)
      }
    })
    // 因变量y的结果数组
    const yList = []
    obj.leiList = leiList
    obj.liangList = liangList
    obj.liangResultList = liangResultList
    obj.liangResultList2 = liangResultList2
    obj.leiResultList = leiResultList
    obj.yList = yList
    obj.data = []
  }

  generalizedOrdinaryLeastSquares(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    const {
      yList,
      leiList,
      leiResultList,
      liangList,
      liangResultList2,
      liangResultList
    } = obj
    const xDrop = group[0]
    const yDrop = group[1]
    const xyDrop = xDrop.concat(yDrop)
    yList.push(Number(doc[yDrop[0]]))
    leiList.forEach((v, index) => {
      const value = doc[v.slice(-1)[0]]
      leiResultList[index].push(value)
    })
    const list = []
    // eslint-disable-next-line eqeqeq
    liangList.forEach((v, index) => {
      list.push(Number(doc[v]))
      liangResultList2[index].push(Number(doc[v]))
    })
    liangResultList.push(list)
    // 生成key,value数据格式
    DataClass.make_key_value_data(obj.data, xyDrop, doc)
    // const obj2 = {}
    // xDrop.forEach(x => {
    //   // 如果是定类，那么取数组的最后一个值为名字
    //   const key = typeof (x) === 'object' ? x[x.length - 1] : x
    //   const value = doc[key]
    //   obj2[key] = isNaN(+value) ? value : +value
    // })
    // obj.data.push(obj2)
  }

  generalizedOrdinaryLeastSquaresEnd(obj, group) {
    // 生成compareList
    obj.compareList = obj.leiList.map(v => v.slice(-2)[0])
    // 生成xys
    obj.xys = group
  }

  ordinaryLeastSquaresInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const xDrop = group[0]
    // const yDrop = group[1]
    const leiResultList = []
    const liangResultList = []
    const liangResultList2 = []
    // 构造leiList结构并且构造一个只有定类的数组和一个只有定量的数组
    const leiList = []
    const liangList = []
    xDrop.forEach(v => {
      if (typeof (v) === 'object') {
        leiResultList.push([])
        leiList.push(v)
      } else {
        liangResultList2.push([])
        liangList.push(v)
      }
    })
    // 因变量y的结果数组
    const yList = []
    obj.leiList = leiList
    obj.liangList = liangList
    obj.liangResultList = liangResultList
    obj.liangResultList2 = liangResultList2
    obj.leiResultList = leiResultList
    obj.yList = yList
    obj.data = []
  }

  ordinaryLeastSquares(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    const {
      yList,
      leiList,
      leiResultList,
      liangList,
      liangResultList2,
      liangResultList
    } = obj
    const xDrop = group[0]
    const yDrop = group[1]
    const xyDrop = xDrop.concat(yDrop)
    yList.push(Number(doc[yDrop[0]]))
    leiList.forEach((v, index) => {
      const value = doc[v.slice(-1)[0]]
      leiResultList[index].push(value)
    })
    const list = []
    // eslint-disable-next-line eqeqeq
    liangList.forEach((v, index) => {
      list.push(Number(doc[v]))
      liangResultList2[index].push(Number(doc[v]))
    })
    liangResultList.push(list)
    // 生成key,value数据格式
    DataClass.make_key_value_data(obj.data, xyDrop, doc)
    // const obj2 = {}
    // xDrop.forEach(x => {
    //   // 如果是定类，那么取数组的最后一个值为名字
    //   const key = typeof (x) === 'object' ? x[x.length - 1] : x
    //   const value = doc[key]
    //   obj2[key] = isNaN(+value) ? value : +value
    // })
    // obj.data.push(obj2)
  }

  ordinaryLeastSquaresEnd(obj, group) {
    // 生成compareList
    obj.compareList = obj.leiList.map(v => v.slice(-2)[0])
    // 生成xys
    obj.xys = group
  }

  meansInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const leiList = group[0][0].slice(0, -1)
    const lei = group[0][0].slice(-1)[0]
    obj.lei = lei
    const yList = group[1]
    const obj3 = {}
    const obj4 = {}
    yList.forEach(y => {
      // data1
      const obj2 = {}
      leiList.forEach(x => {
        obj2[x] = []
      })
      obj3[y] = obj2
      // data2
      obj4[y] = []
    })
    obj.data1 = obj3
    obj.data2 = obj4
    obj.data3 = []
  }

  means(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    // const leiList = group[0][0].slice(0, -1)
    const lei = group[0][0].slice(-1)[0]
    const yList = group[1]
    const leiValue = doc[lei]
    // data3
    const dict = {
      [lei]: doc[lei]
    }
    yList.forEach(y => {
      const value = +doc[y]
      // data1
      obj.data1[y][leiValue].push(value)
      // data2
      obj.data2[y].push(value)
      // data3
      dict[y] = value
    })
    // data3
    obj.data3.push(dict)
  }

  chiSquareInit(obj, group) {
    const x = group[0][0]
    const y = group[1][0]
    obj.x = x
    obj.y = y
    // 卡方表的维度
    obj.dimension = [x.length - 1, y.length - 1]
    // 把两个定类的名字添加到obj中去,下面的chiSquare方法中需要用到。
    obj.xLeiName = x[x.length - 1]
    obj.yLeiName = y[y.length - 1]
    // 构造空的频数数组的结构
    obj.frequencyList = []
    for (var i = 0; i < x.length - 1; i++) {
      obj.frequencyList.push([])
    }
    obj.frequencyList.forEach(v => {
      for (var j = 0; j < y.length - 1; j++) {
        v.push(0)
      }
    })
  }

  chiSquare(obj, group, doc) {
    // 向空的频数数组中添加值。
    var xIndex = obj.x.indexOf(doc[obj.xLeiName])
    var yIndex = obj.y.indexOf(doc[obj.yLeiName])
    obj.frequencyList[xIndex][yIndex]++
  }

  chiSquareEnd(obj, group) {
    const x = obj.x
    const y = obj.y
    const frequencyList = obj.frequencyList
    const list1 = []
    // [0,5,9,10,6,17,17,15,5] 用于计算期望频数
    for (var k = 0; k < y.length - 1; k++) {
      var total = 0
      for (var m = 0; m < x.length - 1; m++) {
        total += frequencyList[m][k]
      }
      list1.push(total)
    }
    var list2 = []
    //   [32,52] 用于计算期望频数
    frequencyList.forEach(element => {
      list2.push(element.reduce((a, b) => a + b))
    })
    // 总数N
    obj.N = list2.reduce((a, b) => a + b)
    // 期望频数数组
    obj.E = []
    // E2是二维的期望频数列表，E是一维的。
    obj.E2 = deepClone(frequencyList)
    obj.E2.forEach((v, index1) => {
      v.forEach((q, index2) => {
        var result = (list2[index1] * list1[index2]) / obj.N
        obj.E.push(result)
        v[index2] = result
      })
    })
    // 通过条件来判断当前应当使用哪种卡方的方法
    obj.pyMethod = judgeE(obj.dimension, obj.N, obj.E)
  }

  varianceInit(obj, group) {
    /**
     * @param obj // 存放所有参数，数据的字典
     * @param group // 存放一组xys的数组
     */
    const leiList = group[0][0].slice(0, -1)
    const lei = group[0][0].slice(-1)[0]
    obj.lei = lei
    const yList = group[1]
    const obj3 = {}
    const obj4 = {}
    yList.forEach(y => {
      // data1
      const obj2 = {}
      leiList.forEach(x => {
        obj2[x] = []
      })
      obj3[y] = obj2
      // data2
      obj4[y] = []
    })
    obj.data1 = obj3
    obj.data2 = obj4
    obj.data3 = []
  }

  // 方差分析
  variance(obj, group, doc) {
    /**
     * @param obj 存放所有参数，数据的字典
     * @param group 存放一组xys的数组
     * @param doc 一条样本数据
     */
    // const leiList = group[0][0].slice(0, -1)
    const lei = group[0][0].slice(-1)[0]
    const yList = group[1]
    const leiValue = doc[lei]
    // data3
    const dict = {
      [lei]: doc[lei]
    }
    yList.forEach(y => {
      const value = +doc[y]
      // data1
      obj.data1[y][leiValue].push(value)
      // data2
      obj.data2[y].push(value)
      // data3
      dict[y] = value
    })
    // data3
    obj.data3.push(dict)
  }

  static statisticalVariable(xys) {
    const list = [{
      类: 0,
      量: 0,
      numOfOptions: null
    }, {
      类: 0,
      量: 0,
      numOfOptions: null
    }]
    xys.forEach((drop, index) => {
      drop.forEach(item => {
        if (typeof (item) === 'object') {
          list[index].类++
          list[index].numOfOptions = item.length - 1
        } else {
          list[index].量++
        }
      })
    })
    return list
  }
}