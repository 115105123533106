<template>
  <a-card :bordered="false" id="divAnswer">
    <div class="table-page-search-wrapper" style="margin-bottom: 24px">
      <a-form layout="inline">
        <a-row type="flex">
          <a-col>
            <a-breadcrumb style="margin-bottom: 20px">
              <a-breadcrumb-item>
                <router-link to="/question/List">问卷列表</router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 答卷列表 </a-breadcrumb-item>
            </a-breadcrumb>
          </a-col>
        </a-row>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="模板标题">
              <a-select v-model="queryParam.id" @change="()=>{queryParam.key=undefined; $refs.table.refresh(true)}">
                <a-select-option v-for="(item, i) in questionnaire" :key="i" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-input-search
              v-model="queryParam.key"
              placeholder="请输入搜索关键字"
              enter-button="搜索"
              @search="$refs.table.refresh(true)"
            />
          </a-col>
          <a-col :md="8" :sm="24" style="text-align:right">
            <router-link
              :to="`/question/Preview/${Base64.encode(queryParam.id)}`"
              target="_blank"
              style="display: inline-block"
            >
              <a-button type="primary">预览量表(问卷)</a-button>
            </router-link>
          </a-col>
          <a-col :md="8" :sm="24" style="text-align: right">
            <a-button title="打印选中的答卷" @click="Print(null)" v-if="printModel">批量打印</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      :row-selection="
        printModel
          ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }
          : null
      "
      showPagination="auto"
    >
      <span slot="number" slot-scope="text">
        <template>
          <span>{{ text ? Number(text) : 0 }}</span>
        </template>
      </span>
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ $moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </span>
      <span slot="deleteFlag" slot-scope="text">
        <template>
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="goDetail(record)">查看详情</a>
          <a-divider type="vertical" />
          <a @click="Print(record)" v-if="printModel" :disabled="record.isSubmit === 1 ? false : true">打印</a>
          <a-divider type="vertical" v-if="printModel" />
          <a @click="handleDel(record)" :disabled="record.isSubmit === 1 ? true : false">{{
            record.isSubmit === 2 ? '启用' : '废弃'
          }}</a>
        </template>
      </span>
    </s-table>
  </a-card>
</template>
<script>
import CreateForm from './components/CreateForm'
import { STable } from '@/components'
import { GetAnswerList, deleteAnswer } from '@/api/questionnaire'
import { Modal } from 'ant-design-vue'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'

// 列
const colums = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: '提交答卷时间',
    dataIndex: 'createDate',
    scopedSlots: { customRender: 'date' },
    align: 'center'
  },
  // {
  //   title: '总计分',
  //   dataIndex: 'score',
  //   scopedSlots: { customRender: 'number' },
  //   align: 'center'
  // },
  {
    title: '状态',
    dataIndex: 'isSubmit',
    scopedSlots: { customRender: 'deleteFlag' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  }
]
// 状态
const StatusMap = {
  0: {
    status: 'warning',
    text: '未确认'
  },
  1: {
    status: 'success',
    text: '已确认'
  },
  2: {
    status: 'error',
    text: '已废弃'
  }
}
export default {
  // 加载组件
  components: {
    CreateForm,
    STable
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      Base64,
      // 量表(问卷)列表
      questionnaire: [],
      // 查询参数
      queryParam: { id: Number(Base64.decode(this.$route.params.id)), key: undefined },
      timer: {},
      printModel: '',
      selectedRowKeys: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return GetAnswerList({ ...requestParameters, orgid: this.$store.state.user.info.orgid }).then(res => {
          this.questionnaire = res.questionnaires
          this.printModel = res.printModel
          console.log('qus', this.questionnaire)
          return res
        })
      }
    }
  },
  // mounted() {
  //   window.addEventListener('setItem', function(e) {
  //     this.newVal = localStorage.getItem('watchStorage')
  //     if (this.newVal) {
  //     console.log('标签页', this.newVal)
  //     window.reload()
  //     }
  //   })
  // },
  // 方法
  methods: {
    /**
    * 自定义高度
    */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divAnswer']
      AutoHeight(this, min, max, 215)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      sessionStorage['PrintIDs'] = JSON.stringify(this.selectedRowKeys)
    },
    getCheckboxProps: record => ({
      props: {
        disabled: record.isSubmit !== 1, // Column configuration not to be checked
        name: record.name
      }
    }),
    Print(record) {
      if (record) {
        // 打印单条
        sessionStorage['PrintIDs'] = JSON.stringify([record.id])
      } else {
        if (this.selectedRowKeys.length === 0) {
          this.$message.warning('请至少选择一份答卷')
          return
        }
      }
      const routeData = this.$router.resolve({ path: `/question/Print/${Base64.encode(this.queryParam.id)}` })
      window.open(routeData.href, '_blank')
    },
    init(record) {
      console.log('获取成功')
      clearInterval(this.timer)
      console.log('--------------监听结束------------')
    },
    /**
     * 新建事件
     */
    handleAdd() {
      this.mdl = null
      this.visible = true
    },
    /**
     *启用/停用事件
     */
    handleDel(record) {
      const title = record.isSubmit ? '启用' : '废弃'
      Modal.confirm({
        title: '您确定' + title + '该答卷吗',
        onOk: () => {
          deleteAnswer(record.id).then(res => {
            // 刷新表格
            this.$refs.table.refresh()
          })
        }
      })
    },
    /**
     * 查看详情
     */
    goDetail(record) {
      console.log('------------开启监听器---------------')
      // watchDetail 确认问卷的页面 0：初始化 1：确认答卷 2：离开详情页
      localStorage.setItem(`watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`, 0)
      console.log(`watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
      // 计时器
      this.timer = setInterval(() => {
        console.log('-------------------')
        var newVal = localStorage.getItem(`watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
        if (newVal === '1') {
          console.log(`1_watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
          this.$refs.table.refresh()
          this.init(record)
          localStorage.removeItem(`watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
        } else if (newVal === '2') {
          console.log(`2_watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
          this.init(record)
          localStorage.removeItem(`watchDetail${Base64.encode(record.id)}${Base64.encode(this.queryParam.id)}`)
        }
      }, 2000)
      const routeData = this.$router.resolve({ name: 'questionPerview', params: { currentid: `${Base64.encode(record.id)}`, id: `${Base64.encode(this.queryParam.id)}` } })
      window.open(routeData.href, '_blank')
    },
    onSearch() {

    }

  },
  /**
   * 过滤器
   */
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }
  }
}
</script>
<style scoped>
.routerLinkDiv {
  border: 1px #1890ff solid;
  height: 30px !important;
  width: 100px !important;
  border-radius: 2px;
  line-height: 30px;
  align-content: center;
  text-align: center;
}
.ant-form-item {
  margin: 0 !important;
}
</style>
