<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 20px 0 30px">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 0 30px">
            <div v-for="(table, index) in res.tables" :key="index" style="margin-bottom: 10px">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              ></my-table2>
            </div>
            <p v-if="resid && 'vif_array' in resid && num_of_param > 2" style="margin-top: 20px">
              <span>由上表可得：</span>
              <span v-if="resid.vif_array.every((v) => v < 10)">
                全部自变量的VIF &lt; 10，说明模型没有多重共线性问题，模型构建良好。
              </span>
              <span v-else>存在自变量的VIF ≥ 10，模型构建较差。 </span>
            </p>
            <span>回归方程式为：{{ formula }}</span>
            <p v-if="dw" style="margin-top: 20px">
              <span v-if="num_of_param === 2">由上表可得：</span>
              <span v-if="dw >= 1.7 && dw <= 2.3"
              >由于1.7 ≤ Durbin-Watson值 ({{ dw }}) ≤ 2.3，因此残差序列不存在自相关。</span
              >
              <span v-else-if="dw >= 1 && dw <= 1.7"
              >由于1 ≤ Durbin-Watson值 ({{ dw }}) ≤ 1.7，无法判断残差序列的相关性。</span
              >
              <span v-else-if="dw >= 2.3 && dw <= 3"
              >由于2.3 ≤ Durbin-Watson值 ({{ dw }}) ≤ 3，无法判断残差序列的相关性。</span
              >
              <span v-else-if="dw >= 0 && dw < 1"
              >由于0 ≤ Durbin-Watson值 ({{ dw }}) &lt; 1，因此残差序列存在正自相关。</span
              >
              <span v-else-if="dw > 3 && dw <= 4"
              >由于3 &lt; Durbin-Watson值 ({{ dw }}) ≤ 4，因此残差序列存在负自相关。</span
              >
            </p>
            <p>线性回归图如下图所示：</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            v-for="(item, index) in res.images"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="`线性回归图`"></MyImg>
          </a-col>
        </a-row>
        <a-row v-if="resid">
          <a-col :span="24" style="padding: 0 30px">
            <Resid
              :resid="resid"
              :parentType="`analysisOfRegression`"
              @resid_norm_p="(val) => (resid_norm_p = val)"
              @method_name="(val) => (method_name = val)"
              :yName="y_name"
            >
              <template #text1 class="text">
                <p>
                  <span> {{ `使用${method_name}算法对残差序列做正态性检验，检验结果如下表所示： ` }}</span>
                </p>
              </template>
              <template #text2 class="text">
                <p>
                  <span>由上表可得：</span>
                  <span
                    v-html="$hypothesis.conclusion(resid_norm_p, 0.05, '残差序列是正态的', '残差序列是非正态的')"
                  ></span>
                </p>
              </template>
              <template #text3 class="text">
                <p>
                  <span>标准化残差的直方图和PP图如下图所示：</span>
                </p>
              </template>
            </Resid>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../../../MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import MyTable2 from '../../table'
import { get_formula } from '@/utils/util'

export default {
  components: { MyTable2, Resid },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {},
      resid: null,
      resid_norm_p: null,
      method_name: null,
      dw: null,
      num_of_param: 0,
      y_name: null,
      formula: null
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).linearRegression()
      this.y_name = req.args.form.xys[1][0]
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      // 把残差相关需要的参数赋到resid里，做残差分析需要用
      this.resid = res.data
      // 把dw值取出来，并且赋值
      this.dw = res.tables[2].data[0].value2
      // 通过第二个表格的变量数量判断自变量的个数
      this.num_of_param = res.tables[1].data.length
      // 生成方程式
      get_formula(res.tables[1], this)
      return res
    }
  }
}
</script>