<template>
  <div style="display:inline-block !important;margin:0 5px">
    <div style="border-bottom:1px solid black;padding:0 2px;line-height:1.5;text-align:center">{{ up }}</div>
    <div style="padding:0 2px;line-height:1.5;text-align:center">{{ down }}</div>
  </div>
</template>
<script>
export default {
   props: ['up', 'down']
}
</script>