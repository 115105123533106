var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leftWidthHypo", attrs: { id: "colMenu" } },
    [
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("a-divider", [_vm._v("实验")]),
          _vm._l(
            _vm.menus.filter(function(r) {
              return r.type === "exp"
            }),
            function(item, index) {
              return _c(
                "a-row",
                { key: index, attrs: { type: "flex", justify: "center" } },
                [
                  _c("a-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      {
                        class: { item: true, active: item.state },
                        on: {
                          click: function($event) {
                            return _vm.clickIcon(item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  ])
                ],
                1
              )
            }
          )
        ],
        2
      ),
      _c("a-divider", [_vm._v("公共")]),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        _vm._l(
          _vm.menus.filter(function(r) {
            return r.type === "device"
          }),
          function(item, index) {
            return _c(
              "a-row",
              { key: index, attrs: { type: "flex", justify: "center" } },
              [
                _c("a-col", { attrs: { span: 20 } }, [
                  _c(
                    "div",
                    {
                      class: { item: true, active: item.state },
                      on: {
                        click: function($event) {
                          return _vm.clickIcon(item)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                ])
              ],
              1
            )
          }
        ),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }