<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{ xys }">
      <div v-if="xys[0].length > 0">
        <span v-html="H0(xys)"></span>
      </div>
    </template>
    <template #H1="{ xys }">
      <div v-if="xys[0].length > 0">
        <span v-html="H1(xys)"></span>
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'phiXys',
      xys: [[]],
      formAddKeyName: 'phiFormAddKey',
      settings,
      form: {
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        form.x = this.$deepClone(that.settings[0].list)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  },
  computed: {
    H0() {
      return (xys) => {
        const leiNameAry = xys[0]
        const leiNameStr = leiNameAry.map(v => `&lt;${v[v.length - 1]}&gt;`).join('、')
        return `${leiNameStr}之间差别不具有显著相关性。`
      }
    },
    H1() {
      return (xys) => {
        const leiNameAry = xys[0]
        const leiNameStr = leiNameAry.map(v => `&lt;${v[v.length - 1]}&gt;`).join('、')
        return `${leiNameStr}之间差别具有显著相关性。`
      }
    }
  }
}
</script>