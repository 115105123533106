const document = {
  state: {
    list: [],
    a: '',
    method: '',
    dybtjy: {},
    rulesController: true,
    // formAddKeyController: [],
    // 线性回归
    ordinaryLeastSquaresXys: [
      [],
      []
    ],
    ordinaryLeastSquaresFormAddKey: [],
    ordinaryLeastSquaresForm: {
      compareList: []
    },
    generalizedOrdinaryLeastSquaresXys: [
      [],
      []
    ],
    generalizedOrdinaryLeastSquaresFormAddKey: [],
    generalizedOrdinaryLeastSquaresForm: {
      compareList: []
    },
    robustRegressionXys: [
      [],
      []
    ],
    robustRegressionFormAddKey: [],
    robustRegressionForm: {
      compareList: []
    },
    // 多项式
    polynomialRegressionXys: [
      [],
      []
    ],
    polynomialRegressionFormAddKey: [],
    polynomialRegressionForm: {
      compareList: []
    },
    // 拟合
    fittingXys: [
      [],
      []
    ],
    fittingFormAddKey: [],
    fittingForm: {
      compareList: []
    },
    // 平滑
    splineSmoothXys: [
      [
        [],
        []
      ]
    ],
    splineSmoothFormAddKey: [],
    // 逻辑回归
    logicXys: [
      [],
      []
    ],
    logicFormAddKey: [],
    logicForm: {
      compareList: []
    },
    // 多分类逻辑回归
    multipleLogicXys: [
      [],
      []
    ],
    multipleLogicFormAddKey: [],
    multipleLogicForm: {
      compareList: []
    },
    // 主成分分析
    pcaXys: [
      []
    ],
    pcaFormAddKey: [],
    pcaForm: {
      compareList: []
    },
    // Cox回归
    coxXys: [
      [
        [],
        [],
        []
      ]
    ],
    coxFormAddKey: [],
    coxForm: {
      compareList: []
    },
    // Cox回归
    dysscfxXys: [
      [
        [],
        [],
        []
      ]
    ],
    dysscfxFormAddKey: [],
    dysscfxForm: {
      compareList: []
    },

    loading: true,
    seconds: 5,
    refresh: false
  },

  mutations: {
    SET_PARAMS: (state, params) => {
      state.dybtjyXys = params
    },
    SET_METHOD: (state, method) => {
      state.method = method
    },
    SET_DYBTJY: (state, form) => {
      state.dybtjy = form
    },
    SET_A: (state, a) => {
      state.a = a
    },
    PUSH_DROP_OBJ: (state, obj) => {
      state.xys.push(obj)
    },
    REFRESH: (state) => {
      state.refresh = !state.refresh
    },
    REFRESH_RULES: (state) => {
      state.rulesController = !state.rulesController
    }
  },

  actions: {
    saveParams(context, params) {
      context.commit('SET_PARAMS', params)
    },
    saveMethod(context, method) {
      context.commit('SET_METHOD', method)
    },
    saveDybtjy(context, form) {
      context.commit('SET_DYBTJY', form)
    },
    saveA(context, a) {
      context.commit('SET_A', a)
    },
    pushDropObj(context, obj) {
      context.commit('PUSH_DROP_OBJ', obj)
    },
    clearDropList(context) {
      context.commit('SET_PARAMS', [])
    },
    refreshAnalysisOfRegression(context) {
      context.commit('REFRESH')
    },
    refreshRules(context) {
      context.commit('REFRESH_RULES')
    }
  }
}

export default document