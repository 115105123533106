<template>
  <div style="margin: 0 50px !important">
    <a-row type="flex">
      <a-col style="padding: 0 12px">
        <a-breadcrumb style="margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/experiment/Exp1/List/5">精密度实验列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 新建精密度实验 </a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
    </a-row>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ md: { span: 4 }, xxl: { span: 3 } }"
      :wrapper-col="{ md: { span: 20 }, xxl: { span: 21 } }"
    >
      <a-form-model-item label="记录编号">
        <a-input :style="{ width: style.input_width }" placeholder="请输入记录编号" v-model="form.serialNum" />
      </a-form-model-item>
      <a-form-model-item label="实验类型" prop="type">
        <a-row>
          <a-col :span="24">
            <a-radio-group v-model="form.type">
              <a-radio :value="0"> 精密度验证 </a-radio>
              <a-radio :value="1"> 正确度验证 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row v-if="form.type === 1">
          <a-col :span="24">
            <a-row>
              <a-col :md="12" :xxl="8" style="background: #f7f7f7">
                <a-form-model-item
                  :label-col="{ md: { span: 8 }, xxl: { span: 9 } }"
                  :wrapper-col="{ md: { span: 16 }, xxl: { span: 15 } }"
                  style="margin: 0 10px"
                  label="请选择验证方法"
                  prop="accuracyFunction"
                >
                  <a-radio-group v-model="form.accuracyFunction">
                    <a-radio :value="0"> 参考方法 </a-radio>
                    <a-radio :value="1"> 常规方法 </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item v-if="form.accuracyFunction === 1" label="厂家声明偏倚(B)" prop="b">
        <a-input-number
          v-model="form.b"
          :style="{ width: style.input_width }"
          :min="0"
          placeholder="请输入厂家声明偏倚(B)"
          :formatter="(value) => `${value}mmol/L`"
          :parser="(value) => value.replace('mmol/L', '')"
        />
      </a-form-model-item>
      <div v-if="form.accuracyFunction === 0">
        <a-form-model-item label="参考物质指定值" prop="designedValue">
          <a-input-number
            v-model="form.designedValue"
            :style="{ width: style.input_width }"
            :min="0"
            placeholder="请输入参考物质指定值"
            :formatter="(value) => `${value}mmol/L`"
            :parser="(value) => value.replace('mmol/L', '')"
          />
        </a-form-model-item>
        <a-form-model-item label="指定标准差" prop="designedStd">
          <a-input-number
            v-model="form.designedStd"
            :style="{ width: style.input_width }"
            :min="0"
            placeholder="请输入指定标准差"
            :formatter="(value) => `${value}mmol/L`"
            :parser="(value) => value.replace('mmol/L', '')"
          />
        </a-form-model-item>
      </div>
      <div v-if="form.type === 0">
        <a-form-model-item label="规定重复CV1" prop="cV1">
          <a-input-number
            v-model="form.cV1"
            :precision="1"
            :style="{ width: style.input_width }"
            :min="0"
            placeholder="请输入样本浓度"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
          />
        </a-form-model-item>
        <a-form-model-item label="规定重复CV2" prop="cV2">
          <a-input-number
            v-model="form.cV2"
            :precision="1"
            :style="{ width: style.input_width }"
            :min="0"
            placeholder="请输入样本浓度"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item label="实验名称" prop="expName">
        <a-input :style="{ width: style.input_width }" placeholder="请输入实验名称" v-model="form.expName" />
      </a-form-model-item>
      <a-form-model-item label="实验地点">
        <a-input :style="{ width: style.input_width }" placeholder="请输入实验地点" v-model="form.expAddress" />
      </a-form-model-item>
      <a-form-model-item label="实验天数">
        <a-input-number
          v-model="form.expDays"
          :style="{ width: style.input_width }"
          :min="0"
          placeholder="请输入实验天数"
          addon-after=".com"
          :formatter="(value) => `${value}天`"
          :parser="(value) => value.replace('天', '')"
        >
        </a-input-number>
      </a-form-model-item>
      <a-form-model-item label="实验人员">
        <a-checkbox-group
          :value="JSON.parse(form.expUsers)"
          @change="(value) => (form.expUsers = JSON.stringify(value))"
        >
          <a-checkbox v-for="user in res.users" :key="user.id" :value="user.id"> {{ user.userName }} </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="实验设备">
        <a-select
          mode="multiple"
          :value="JSON.parse(form.expDevices)"
          placeholder="请选择实验设备"
          @change="(value) => (form.expDevices = JSON.stringify(value))"
          :style="{ width: style.input_width }"
        >
          <a-select-option v-for="expDevice in res.expDevices" :key="expDevice.id">
            {{ expDevice.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="说明">
        <a-textarea
          v-model="form.explain"
          style="width: 50%"
          placeholder="请输入说明"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
      <a-divider />
      <item :name="'试剂'" :inputWidth="style.input_width" :itemForms="form.reagents"></item>
      <a-divider />
      <!-- 样本 -->
      <a-form-model-item :label="`样本名称`">
        <a-input :style="{ width: style.input_width }" placeholder="请输入样本名称" v-model="form.sampleName" />
      </a-form-model-item>
      <a-form-model-item
        v-for="(sampleConcentration, index) in form.sampleConcentrations"
        :key="index"
        :label="`样本浓度${index + 1}`"
      >
        <a-input-number
          v-model="sampleConcentration.value"
          :style="{ width: style.input_width }"
          :min="0"
          placeholder="请输入样本浓度"
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
        />
        <span
          v-if="form.sampleConcentrations.length !== 1"
          style="margin-left: 10px; cursor: pointer"
          @click="deleteSampleConcentration(index)"
        >
          <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
        <span
          v-if="index === form.sampleConcentrations.length - 1"
          style="margin-left: 10px; cursor: pointer"
          @click="addSampleConcentration"
        >
          <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
      </a-form-model-item>
      <a-form-model-item :label="`样本批号`">
        <a-input :style="{ width: style.input_width }" placeholder="请输入样本批号" v-model="form.sampleBatchNum" />
      </a-form-model-item>
      <a-form-model-item :label="`重复测量次数`">
        <a-input-number
          v-model="form.repeatNum"
          placeholder="请输入重复测量次数"
          :min="1"
          :style="{ width: style.input_width }"
          :formatter="(value) => `${value}次`"
          :parser="(value) => value.replace('次', '')"
          :disabled="form.accuracyFunction === 1"
        />
      </a-form-model-item>
      <!-- 样本 -->
      <a-divider />
      <item :name="'校准品'" :inputWidth="style.input_width" :itemForms="form.calibration"></item>
      <a-divider />
      <item :name="'质控品'" :inputWidth="style.input_width" :itemForms="form.quality"></item>
    </a-form-model>
    <a-button type="primary" @click="handleOk"> 提交 </a-button>
  </div>
</template>
<script>
import Item from './item'
import { getDevices } from '@/api/exp/device'
import { getUsers } from '@/api/user'
import { postExperiment } from '@/api/exp/experiment'
export default {
  components: { Item },
  created() {
    this.fetchDevices()
    this.fetchUsers()
  },
  data() {
    return {
      res: {
        expDevices: [],
        users: []
      },
      form: {
        cV1: 4.0, // 精密度验证里的规定重复
        cV2: 5.0, // 精密度验证里的规定期间
        designedValue: undefined, // 参考方法里的参考物质指定值
        designedStd: undefined, // 参考方法里的参考物质指定的标准差
        b: undefined, // 常规方法里的偏倚（B)
        orgID: this.$store.state.user.info.orgid,
        serialNum: '',
        type: null,
        accuracyFunction: null,
        expName: '',
        expAddress: '',
        expDays: 5,
        expUsers: JSON.stringify([this.$store.state.user.info.id]),
        expTypeid: Number(this.$route.params.id),
        expDevices: '[]',
        explain: '',
        reagents: [{
          name: '',
          manufactor: '',
          batchNum: '',
          expiryDate: this.$moment(new Date()).format()
        }],
        sampleName: '',
        sampleConcentrations: [{ value: '' }],
        sampleBatchNum: '',
        repeatNum: 3,
        calibration: [{
          name: '',
          manufactor: '',
          batchNum: '',
          expiryDate: this.$moment(new Date()).format()
        }],
        quality: [{
          name: '',
          manufactor: '',
          batchNum: '',
          expiryDate: this.$moment(new Date()).format()
        }]
      },
      rules: {
        expName: [{ required: true, message: '实验名称不能为空', trigger: 'change' }],
        type: [{ required: true, message: '实验类型不能为空', trigger: 'change' }],
        b: [{ required: true, message: '厂家声明偏倚(B)不能为空', trigger: 'change' }],
        accuracyFunction: [{ required: true, message: '验证方法不能为空', trigger: 'change' }],
        designedValue: [{ required: true, message: '参考物质指定值不能为空', trigger: 'change' }],
        designedStd: [{ required: true, message: '指定标准差不能为空', trigger: 'change' }],
        cV1: [{ required: true, message: '规定重复CV1不能为空', trigger: 'change' }],
        cV2: [{ required: true, message: '规定重复CV2不能为空', trigger: 'change' }]

      },
      style: {
        input_width: '20%'
      }
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const form = this.$deepClone(this.form)
          this.format_sampleConcentrations(form)
          this.format_reagents(form)
          form.cV1 = form.cV1 / 100
          form.cV2 = form.cV2 / 100
          console.log('%c 🥑 form: ', 'font-size:20px;background-color: #FFDD4D;color:#fff;', form)
          postExperiment(form).then(res => {
            this.$router.push(`/experiment/Exp1/${Number(this.$route.params.id)}`)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    format_sampleConcentrations(form) {
      form.sampleConcentrations = JSON.stringify(form.sampleConcentrations.map(v => v.value))
    },
    format_reagents(form) {
      form.reagents.forEach(v => { v.type = 0 })
      form.calibration.forEach(v => { v.type = 1 })
      form.quality.forEach(v => { v.type = 2 })
      form.reagents = form.reagents.concat(form.calibration, form.quality).filter(v => v.name || v.manufactor || v.batchNum)
    },
    // 增加样本浓度
    addSampleConcentration() {
      this.form.sampleConcentrations.push({ value: '' })
    },
    deleteSampleConcentration(index) {
      this.form.sampleConcentrations.splice(index, 1)
    },
    // 接口：获取当前机构的所有设备
    fetchDevices() {
      getDevices({ orgid: this.$store.state.user.info.orgid }).then(res => {
        this.res.expDevices = res.data
      })
    },
    // 接口：获取当前机构的所有人
    fetchUsers() {
      getUsers({ orgid: this.$store.state.user.info.orgid }).then(res => {
        this.res.users = res.data
      })
    }
  },
  watch: {
    'form.accuracyFunction'(value) {
      value === 1 ? this.form.repeatNum = 2 : this.form.repeatNum = 3
    },
    'form.type'(value) {
      if (value === 0) {
        this.form.accuracyFunction = null
      }
    }
  }
}
</script>
<style lang="less">
.ant-form-item {
  margin: 12px;
}
</style>>