<template>
  <div>
    <a-table :columns="columns" :data-source="data" bordered :pagination="false">
      <template slot="f_exp" slot-scope="text, record">
        <span v-if="record.key === '总和'">{{ sum_of_f_exp }}</span>
        <span v-else> {{ record.f_exp }}</span>
      </template>
      <template slot="f_exp_ratio" slot-scope="text, record">
        <span v-if="record.key === '总和'">{{ sum_of_f_exp_ratio }}</span>
        <a-input v-else v-model="record.f_exp_ratio" placeholder="请填写频数预测值"></a-input>
      </template>
    </a-table>
  </div>
</template>
<script>
import {
  DataFilterClass
} from '@/utils/dataFilterClass'
import { translate } from '@/utils/language'
export default {
  name: 'ExpFrequency',
  props: [],
  data() {
    return {
      columns: [
        {
          title: '',
          dataIndex: 'key',
          key: 'key',
          align: 'center'
        },
        {
          title: translate('频数观测值'),
          dataIndex: 'f_obs',
          key: 'f_obs',
          align: 'center'
        },
        {
          title: translate('频数预测值'),
          dataIndex: 'f_exp',
          key: 'f_exp',
          align: 'center',
          scopedSlots: { customRender: 'f_exp' }
        },
        {
          title: translate('频数预测值比例'),
          dataIndex: 'f_exp_ratio',
          key: 'f_exp_ratio',
          align: 'center',
          scopedSlots: { customRender: 'f_exp_ratio' }
        }
      ],
      data: [],
      mean_of_f_exp: null // 频数预测值的平均值
    }
  },
  methods: {
    get_table_data_from_documents() {
      // 从xys中获取拖进去的变量
      const param_list = this.fittingXys[0]
      // 获取documents
      const documents = new DataFilterClass(param_list, true).main()
      const param = param_list[0]
      let data
      // 如果拖进来的是定类
      if (Array.isArray(param)) {
        this.columns[0].title = param[param.length - 1]
        data = this.get_data_if_lei(documents, param)
        // 如果拖进来的是定量
      } else if (typeof (param) === 'string') {
        this.columns[0].title = '序号'
        data = this.get_data_if_liang(documents, param)
        // 如果拖选框里清空了
      } else {
        this.columns[0].title = ''
        data = []
      }
      this.data = data
    },
    get_table_data_jiaquan() {
      const lei = this.fittingXys[0][0]
      const liang = this.fittingXys[1][0]
      // 从xys中获取拖进去的变量
      const param_list = [lei, liang]
      // 获取documents
      const documents = new DataFilterClass(param_list, true).main()
      console.log('%c 🍅 documents', 'color:#f5ce50', documents);
      const sum = documents.reduce((total, obj) => total + +obj[liang], 0)
      const f_exp = sum / documents.length
      this.mean_of_f_exp = f_exp
      this.sum = sum
      const data = []

      documents.forEach((doc, i) => {
        const obj = {
          key: doc[lei.slice(-1)[0]],
          f_obs: doc[liang],
          f_exp_ratio: f_exp / sum,
          f_exp
        }
        data.push(obj)
      })
      // 计算频数观测值的总和
      const sum_of_f_obs = data.map(v => v.f_obs).reduce((total, current) => +total + +current)
      data.push({
        key: '总和',
        f_obs: sum_of_f_obs,
        f_exp: null,
        f_exp_ratio: null
      })
      this.data = data
    },
    get_data_if_lei(documents, param) {
      const data = []
      // 取频数的平均值
      const f_exp = documents.length / (param.length - 1)
      // 把平均值赋值到this里面去
      this.mean_of_f_exp = f_exp
      this.sum = documents.length
      // 构造table_data的空数据结构
      param.slice(0, -1).forEach(option => {
        const obj = {
          key: option,
          f_obs: 0,
          f_exp_ratio: this.numDiv(f_exp, documents.length),
          f_exp
        }
        data.push(obj)
      })
      // 往空的数据结构中添加数据
      const lei_name = param[param.length - 1]
      documents.forEach(doc => {
        const lei_value = doc[lei_name]
        const obj = data.find(v => v.key === lei_value)
        obj.f_obs = obj.f_obs + 1
      })
      // 计算频数观测值的总和
      const sum_of_f_obs = data.map(v => v.f_obs).reduce((total, current) => +total + +current)
      data.push({
        key: '总和',
        f_obs: sum_of_f_obs,
        f_exp: null,
        f_exp_ratio: null
      })
      return data
    },
    get_data_if_liang(documents, param) {
      const sum = documents.reduce((total, obj) => total + +obj[param], 0)
      const f_exp = sum / documents.length
      this.mean_of_f_exp = f_exp
      this.sum = sum
      const data = []
      documents.forEach((doc, i) => {
        const obj = {
          key: i + 1,
          f_obs: doc[param],
          f_exp_ratio: this.numDiv(f_exp, sum),
          f_exp
        }
        data.push(obj)
      })
      // 计算频数观测值的总和
      const sum_of_f_obs = data.map(v => v.f_obs).reduce((total, current) => +total + +current)
      data.push({
        key: '总和',
        f_obs: sum_of_f_obs,
        f_exp: null,
        f_exp_ratio: null
      })
      return data
    },
    /**

  * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
  * @param num1被除数 | num2除数
  */
    numDiv(num1, num2) {
      var baseNum1 = 0; var baseNum2 = 0;
      var baseNum3, baseNum4;
      try {
        baseNum1 = num1.toString().split('.')[1].length;
      } catch (e) {
        baseNum1 = 0;
      }
      try {
        baseNum2 = num2.toString().split('.')[1].length;
      } catch (e) {
        baseNum2 = 0;
      }
      // with (Math) {
      baseNum3 = Number(num1.toString().replace('.', ''));
      baseNum4 = Number(num2.toString().replace('.', ''));
      return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
      // }
    }
  },
  computed: {
    fittingXys() {
      return this.$store.state.hypothesis.fittingXys
    },
    data_without_total() {
      return this.data.slice(0, -1)
    },
    sum_of_f_exp() {
      const data = this.data_without_total
      // 如果表格的data里有值并且除了总和那一行以外的频数预测值都有值
      if (data.length > 0 && data.slice(0, -1).every(v => v.f_exp || v.f_exp === 0)) {
        // 返回频数预测值那一列的总和
        return data.map(v => v.f_exp).reduce((total, current) => +total + +current)
      }
      return undefined
    },
    sum_of_f_exp_ratio() {
      const { data } = this
      // 如果表格的data里有值并且除了总和那一行以外的频数预测值比例都有值
      if (data.length > 0 && data.slice(0, -1).every(v => v.f_exp_ratio || v.f_exp_ratio === 0)) {
        // 返回频数预测值比例那一列的总和
        return data.map(v => v.f_exp_ratio).reduce((total, current) => +total + +current)
      }
      return undefined
    }
  },
  watch: {
    fittingXys(newVal) {
      console.log('%c 🥃 fittingXys', 'color:#93c0a4', newVal);
      if (Array.isArray(newVal[0][0]) && newVal[1].length) {
        this.get_table_data_jiaquan()
      } else {
        this.get_table_data_from_documents()
      }
      this.$emit('updateForm', 'data', this.data)
      this.$emit('updateForm', 'columns', this.columns)
    },
    // 监听频数预测值的总和变化，来一个个改变频数预测值比例
    sum_of_f_exp(newVal) {
      // var { data, sum } = this
      // data = data.slice(0, -1)
      // data.forEach(obj => {
      //   obj.f_exp_ratio = (obj.f_exp / sum).toFixed(1)
      // })
      // 把频数观测值总和提交到form里去，用于判断两个总和是否相等。
      this.$emit('updateForm', 'sum_of_f_exp', newVal)
    },
    // 监听频数预测值比例的总和变化，来一个个改变频数预测值
    sum_of_f_exp_ratio() {
      var { data, sum } = this
      // let hasValueCount = 0
      // let hasValueSum = 0
      // data.forEach(obj => {
      //   if (obj.f_exp_ratio > 0) {
      //     hasValueCount++
      //     hasValueSum += hasValueSum
      //   }
      // })
      // if (hasValueCount === data.length - 1) {
      //   data.forEach(obj => {
      //     if (obj.f_exp_ratio == 0 || !obj.f_exp_ratio) {
      //       obj.f_exp_ratio = (1 - hasValueSum).toFixed(1)
      //     }
      //   })
      // }
      data = data.slice(0, -1)
      data.forEach(obj => {
        obj.f_exp = (obj.f_exp_ratio * sum)
      })
    }
  }
}
</script>
<style>
.ant-form-item {
  align-items: flex-start !important;
}
</style>