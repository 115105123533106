var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model-item",
        {
          attrs: {
            label: _vm.formItem.name,
            labelCol: _vm.handleCol(_vm.formItem.name, "label"),
            wrapperCol: _vm.handleCol(_vm.formItem.name, "wrapper")
          }
        },
        [
          _vm.formItem.type === 0
            ? _c("a-input", {
                staticStyle: { width: "220px" },
                attrs: {
                  suffix: _vm._f("fetchUnit")(_vm.formItem.unit),
                  placeholder: "请填写"
                },
                on: {
                  change: function(e) {
                    return _vm.isHweight(e, _vm.formItem.name, _vm.formItem)
                  }
                },
                model: {
                  value: _vm.formItem.value,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "value", $$v)
                  },
                  expression: "formItem.value"
                }
              })
            : _vm.formItem.type === 1
            ? _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formItem.value,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "value", $$v)
                    },
                    expression: "formItem.value"
                  }
                },
                _vm._l(_vm.formItem.option, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item, attrs: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              )
            : _vm.formItem.type === 2
            ? _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.formItem.value,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "value", $$v)
                    },
                    expression: "formItem.value"
                  }
                },
                _vm._l(_vm.formItem.option, function(item) {
                  return _c("a-radio", { key: item, attrs: { value: item } }, [
                    _vm._v(_vm._s(item))
                  ])
                }),
                1
              )
            : _c("a-date-picker", {
                staticStyle: { width: "220px" },
                attrs: {
                  placeholder: "请选择日期",
                  value: _vm.formItem.value
                    ? _vm.$moment(_vm.formItem.value)
                    : null
                },
                on: {
                  change: function(date, dateString) {
                    return _vm.change(date, dateString, _vm.formItem)
                  }
                }
              })
        ],
        1
      ),
      _vm.formItem.value == "有" && _vm.formItem.children
        ? _c(
            "a-row",
            _vm._l(_vm.formItem.children, function(item) {
              return _c(
                "a-col",
                { key: item.id, attrs: { span: 24 } },
                [
                  _c("form-items", {
                    attrs: { formItem: item, controller: _vm.controller }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }