const document = {
  state: {
    andersonXys: [
      []
    ],
    cfclfcfxXys: [
      [],
      []
    ],
    cochranXys: [
      [],
      []
    ],
    danysfcfxXys: [
      [],
      []
    ],
    dcbjjyXys: [
      [],
      []
    ],
    dlybtjyXys: [
      []
    ],
    dlybzjyXys: [
      [],
      []
    ],
    duoysfcfxXys: [
      [],
      [],
      []
    ],
    dybbljyXys: [
      []
    ],
    dybksjyXys: [
      []
    ],
    dybtjyXys: [
      []
    ],
    dybzjyXys: [
      [],
      []
    ],
    fXys: [
      [],
      []
    ],
    fcqxfxXys: [
      [],
      []
    ],
    fittingXys: [
      [],
      []
    ],
    friedmanXys: [
      [],
      []
    ],
    gamesHowellXys: [
      [],
      []
    ],
    kendallXys: [
      []
    ],
    kffxXys: [
      [],
      [],
      []
    ],
    kfjyXys: [
      []
    ],
    linearRegressionXys: [
      [],
      []
    ],
    mcnemarXys: [
      [],
      []
    ],
    mixedXys: [
      [],
      []
    ],
    pdybtjyXys: [
      [],
      []
    ],
    pdybzjyXys: [
      [],
      []
    ],
    pearsonXys: [
      []
    ],
    phiXys: [
      []
    ],
    sybbljyXys: [
      [],
      []
    ],
    sybksjyXys: [
      [],
      []
    ],
    sysfcfxXys: [
      [],
      [],
      []
    ],
    spearmanXys: [
      []
    ],
    welchXys: [
      [],
      []
    ],
    ztjyXys: [
      [],
      []
    ],
    zXys: [
      []
    ],
    list: [],
    a: '',
    method: '',
    dybtjy: {},
    // formAddKeyController: [],
    zFormAddKey: [],
    fFormAddKey: [],
    andersonFormAddKey: [],
    cfclfcfxFormAddKey: [],
    cochranFormAddKey: [],
    danysfcfxFormAddKey: [],
    dcbjjyFormAddKey: [],
    dlybtjyFormAddKey: [],
    dlybzjyFormAddKey: [],
    dybbljyFormAddKey: [],
    dybtjyFormAddKey: [],
    duoysfcfxFormAddKey: [],
    dybksjyFormAddKey: [],
    dybzjyFormAddKey: [],
    fcqxfxFormAddKey: [],
    fittingFormAddKey: [],
    friedmanFormAddKey: [],
    gamesHowellFormAddKey: [],
    kendallFormAddKey: [],
    kffxFormAddKey: [],
    kfjyFormAddKey: [],
    linearRegressionFormAddKey: [],
    mcnemarFormAddKey: [],
    mixedFormAddKey: [],
    pdybtjyFormAddKey: [],
    pdybzjyFormAddKey: [],
    pearsonFormAddKey: [],
    phiFormAddKey: [],
    spearmanFormAddKey: [],
    sybbljyFormAddKey: [],
    sybksjyFormAddKey: [],
    sysfcfxFormAddKey: [],
    welchFormAddKey: [],
    ztjyFormAddKey: [
      []
    ],
    loading: true,
    seconds: 0,
    refresh: false
  },

  mutations: {
    SET_PARAMS: (state, params) => {
      state.dybtjyXys = params
    },
    SET_METHOD: (state, method) => {
      state.method = method
    },
    SET_DYBTJY: (state, form) => {
      state.dybtjy = form
    },
    SET_A: (state, a) => {
      state.a = a
    },
    PUSH_DROP_OBJ: (state, obj) => {
      state.xys.push(obj)
    },
    REFRESH: (state) => {
      state.refresh = !state.refresh
    }
  },

  actions: {
    saveParams(context, params) {
      context.commit('SET_PARAMS', params)
    },
    saveMethod(context, method) {
      context.commit('SET_METHOD', method)
    },
    saveDybtjy(context, form) {
      context.commit('SET_DYBTJY', form)
    },
    saveA(context, a) {
      context.commit('SET_A', a)
    },
    pushDropObj(context, obj) {
      context.commit('PUSH_DROP_OBJ', obj)
    },
    clearDropList(context) {
      context.commit('SET_PARAMS', [])
    },
    refreshHypothesis(context) {
      context.commit('REFRESH')
    }
  }
}

export default document