<template>
  <div :class="{ inputNumberWrapper: true, inputNumberWrapperFocused: focusActive }" style="">
    <!-- <my-input-number
          :max="10"
          :min="5"
          :style="{ width: style.input_width }"
          placeholder="请输入记录编号"
          v-model="form.test" />   父组件里的用法-->
    <a-input-number
      :value="value"
      v-bind="$attrs"
      @change="$emit('input', $event)"
      @mouseover="hoverActive = true"
      @mouseout="hoverActive = focusActive"
      @focus="focusActive = true"
      @blur="
        focusActive = false
        hoverActive = false
      "
      :class="{ inputnumber: true, antInputNumberHover: hoverActive }"
    />
    <div
      @mouseover="hoverActive = true"
      @mouseout="hoverActive = focusActive"
      :class="{ after: true, afterhover: hoverActive || focusActive }"
    >
      {{ unit }}
    </div>
  </div>
</template>
<script>

export default {
    inheritAttrs: false,
    created() {
        console.log('%c 🍝 this.$attrs: ', 'font-size:20px;background-color: #FCA650;color:#fff;', this.$attrs)
    },

  props: ['value', 'unit'],
  data() {
    return {
      hoverActive: false,
      focusActive: false
    }
  }
}
</script>
<style lang="less">
.hasError{
  border-color:red;
}
.inputNumberWrapperFocused {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.inputNumberWrapper {
  display: flex;
  align-items: center;
  border-radius: 2px;
  .inputnumber {
    width: auto;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0;
  }
  .after {
    border: 1px solid rgb(217, 217, 217) !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-left-width: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 32px !important;
    line-height: 1.5;
    border: 1px solid black;
    transition: all 0.3s;
  }
  .afterhover {
    border: 1px solid #40a9ff !important;
    border-left-width: 0 !important;
    transition: all 0.3s;
  }
  .ant-input-number-focused,
  .ant-input-number:hover {
    border-right-width: 0 !important;
  }
  .ant-input-number-focused {
    box-shadow: 0 0 0 0 white;
  }
  .antInputNumberHover {
    border: 1px solid #40a9ff !important;
    border-right-width: 0 !important;
    transition: all 0.3s;
  }
  .ant-input-number-handler-wrap {
    border-left-width: 0;
  }
}
</style>