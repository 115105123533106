var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.gp.xys, function(obj, index) {
      return _c(
        "div",
        { key: index, attrs: { id: obj.title } },
        [
          _c(
            "div",
            {
              ref: "title," + (index + 1),
              refInFor: true,
              staticClass: "third-title",
              attrs: {
                id: "1." + _vm.indexT + "." + (index + 1) + " " + obj.title
              }
            },
            [
              _vm._v(
                " 2." +
                  _vm._s(_vm.indexT) +
                  "." +
                  _vm._s(index + 1) +
                  _vm._s(obj.title) +
                  " "
              )
            ]
          ),
          _vm.gp.type == "vc"
            ? _c("vcchart", {
                ref: "content," + (index + 1),
                refInFor: true,
                staticClass: "third-module",
                staticStyle: { padding: "0 25px" },
                attrs: {
                  data: obj.data,
                  xys: _vm.gp.xys[index],
                  indexT: index + 1
                }
              })
            : _vm._e(),
          _vm.gp.type == "cc"
            ? _c("ccfield", {
                ref: "content," + (index + 1),
                refInFor: true,
                staticClass: "third-module",
                staticStyle: { padding: "0 25px" },
                attrs: {
                  data: obj.data,
                  xys: _vm.gp.xys[index],
                  indexT: index + 1,
                  count: _vm.gp.xys.length
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }