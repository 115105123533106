<template>
  <div>
    <div v-for="(obj, index) in data" :key="index" :id="`1.${index + 1} ${obj[1]}`">
      <div ref="title" class="second-title">1.{{ index + 1 }} {{ obj[1] }}</div>
      <component
        class="second-module"
        ref="content"
        :is="obj[0]"
        :fields="fields"
        :secondIndex="index + 1"
        style="padding: 0 25px"
      ></component>
    </div>
  </div>
</template>
<script>
import Msxfx from './msxfx'
import Charts from './charts'
export default {
  components: { Msxfx, Charts },
  data() {
    return {
      data: []
    }
  },

  created() {
    console.log('fields', this.fields)
    var vF = this.fields.filter(r => r.type === '量')
    if (vF.length > 0) {
      this.data.push(['msxfx', '定量描述性分析'])
    }
    this.data.push(['charts', '图表分析'])
  },
  props: ['type', 'fields']

}
</script>
<style>
.third-module {
  margin: 15px 0;
}
</style>