var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.data, function(obj, index) {
      return _c(
        "div",
        { key: index, attrs: { id: "1." + (index + 1) + " " + obj[1] } },
        [
          _c(
            "div",
            { ref: "title", refInFor: true, staticClass: "second-title" },
            [_vm._v("1." + _vm._s(index + 1) + " " + _vm._s(obj[1]))]
          ),
          _c(obj[0], {
            ref: "content",
            refInFor: true,
            tag: "component",
            staticClass: "second-module",
            staticStyle: { padding: "0 25px" },
            attrs: { fields: _vm.fields, secondIndex: index + 1 }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }