import { render, staticRenderFns } from "./FileData.vue?vue&type=template&id=28a68a6f&"
import script from "./FileData.vue?vue&type=script&lang=js&"
export * from "./FileData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28a68a6f')) {
      api.createRecord('28a68a6f', component.options)
    } else {
      api.reload('28a68a6f', component.options)
    }
    module.hot.accept("./FileData.vue?vue&type=template&id=28a68a6f&", function () {
      api.rerender('28a68a6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/file/FileData.vue"
export default component.exports