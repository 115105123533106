<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 30px">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24">
            <div style="margin-bottom: 20px" v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
              <div style="margin: 10px 0">
                <b v-html="table.text"></b>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-col>
    <a-col :span="24" v-for="(item, index) in res.imgs" :key="index" style="display: flex; justify-content: center">
      <MyImg :imgBytes="item" :fileName="`协变量平均值处的生存函数`"></MyImg>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../../../MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import MyTable2 from '../../table'
import { change_to_percent } from '@/utils/util'
export default {
  components: { MyTable2, Resid },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {}
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).Cox()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      const { y_liang, jiao_cha_list, y_lei_list, x_lei_list, y_as_1, median_list, median_survival_time_list, median_confidence_interval_list, T_median_survival_time, T_median_confidence_interval, chi_square, p_value, pair_log_table_json } = res
      return {
        imgs: res.imgs,
        tables: [
          this.getBasicTable(y_liang, jiao_cha_list, y_lei_list, [...x_lei_list], y_as_1, median_list),
          this.getMedianTable([...x_lei_list], median_survival_time_list, median_confidence_interval_list, T_median_survival_time, T_median_confidence_interval),
          this.getLogRankTable(chi_square, p_value, [...x_lei_list]),
          this.getPairLogTable(pair_log_table_json, [...x_lei_list])
        ]
      }
    },
    getBasicTable(y_liang, jiao_cha_list, y_lei_list, x_lei_list, y_as_1, median_list) {
      const y_lei_name = y_lei_list.pop()
      const x_lei_name = x_lei_list.pop()
      const columns = [
        {
          title: '',
          dataIndex: 'key',
          align: 'center',
          key: 'key'
        },
        {
          title: '总计',
          dataIndex: 'total',
          align: 'center',
          key: 'total'
        }
      ]
      y_lei_list.forEach(v => {
        columns.push({
          title: `${y_lei_name} = ${v}`,
          dataIndex: `${v}`,
          align: 'center',
          key: `${v}`
        })
      })
      columns.push({
        title: '事件占比',
        dataIndex: 'percent',
        align: 'center',
        key: 'percent'
      })
      columns.push({
        title: `中位数(${y_liang})`,
        dataIndex: 'median',
        align: 'center',
        key: 'median'
      })

      const data = []
      let total_sum = 0

      const total_ary = []
      y_lei_list.forEach(v => {
        total_ary.push(0)
      })

      x_lei_list.forEach((v, xi) => {
        const obj = {
          key: `${x_lei_name} = ${v}`
        }
        let sum = 0
        y_lei_list.forEach((j, ji) => {
          obj[j] = jiao_cha_list[xi][ji]
          sum += obj[j]
          obj.total = sum
          obj.percent = change_to_percent(obj[y_as_1], obj.total)
          total_ary[ji] += obj[j]
        })
        total_sum += obj.total
        obj.median = median_list[xi]
        data.push(obj)
      })

      const obj = {
        key: '合计',
        total: total_sum,
        median: median_list.slice(-1)[0]
      }
      y_lei_list.forEach((v, i) => {
        obj[v] = total_ary[i]
      })

      obj.percent = change_to_percent(obj[y_as_1], obj.total)

      data.push(obj)

      return {
        columns, data, title: '模型基本信息', showHeader: true, size: 'small'
      }
    },
    getMedianTable(x_lei_list, median_survival_time_list, median_confidence_interval_list, T_median_survival_time, T_median_confidence_interval) {
      const x_lei_name = x_lei_list.pop()

      const columns = [
        {
          title: '',
          dataIndex: 'key',
          align: 'center',
          key: 'key'
        },
        {
          title: '中位数估计值',
          dataIndex: 'median',
          align: 'center',
          key: 'median'
        },
        {
          title: '95%置信区间',
          dataIndex: 'confidence',
          align: 'center',
          key: 'confidence'
        }
      ]
      const data = []
      x_lei_list.forEach((v, i) => {
        const obj = {
          key: `${x_lei_name} = ${v}`
        }
        obj.median = median_survival_time_list[i]
        obj.confidence = median_confidence_interval_list[i]
        data.push(obj)
      })
      const obj = {
        key: '合计',
        median: T_median_survival_time,
        confidence: T_median_confidence_interval
      }
      data.push(obj)
      return {
        columns, data, title: '生存时间中值估计', showHeader: true, size: 'small'
      }
    },
    getLogRankTable(chi_square, p_value, x_lei_list) {
      const x_lei_name = x_lei_list.pop()
      const columns = [
        {
          title: '项目',
          dataIndex: 'project',
          align: 'center',
          key: 'project'
        },
        {
          title: '卡方值',
          dataIndex: 'chi_square',
          align: 'center',
          key: 'chi_square'
        },
        // {
        //   title: '自由度',
        //   dataIndex: 'df',
        //   align: 'center',
        //   key: 'df'
        // },
        {
          title: 'p值',
          dataIndex: 'p_value',
          align: 'center',
          key: 'p_value'
        }
      ]
      const data = [
        {
          key: 0,
          project: 'Log Rank检验',
          chi_square: chi_square.toFixed(3),
          p_value: p_value.toFixed(3),
          df: ''
        }
      ]
      let text = `分析建议：Log Rank用于对模型的有效性进行检验，放入"${x_lei_name}"，模型的原假设为：不同${x_lei_name}下的模型效果无差异，结合本次检验，`
      if (p_value >= 0.05) {
        text = text + `则接受原假设（卡方值 ＝ ${chi_square.toFixed(3)}），p ＝ ${p_value.toFixed(3)} >= 0.05），表示不同${x_lei_name}下模型效果无差异。`
      } else {
        text = text + `则拒绝原假设（卡方值 ＝ ${chi_square.toFixed(3)}），p ＝ ${p_value.toFixed(3)} < 0.05），表示不同${x_lei_name}下模型效果有差异。`
      }
      return {
        columns, data, title: 'Log Rank检验', showHeader: true, size: 'small', text
      }
    },
    getPairLogTable(pair_log_table_json, x_lei_list) {
      const x_lei_name = x_lei_list.pop()
      const columns = [
        {
          title: '第一项',
          dataIndex: 'level_0',
          align: 'center',
          key: 'level_0'
        },
        {
          title: '第二项',
          dataIndex: 'level_1',
          align: 'center',
          key: 'level_1'
        },
        {
          title: '卡方值',
          dataIndex: 'test_statistic',
          align: 'center',
          key: 'test_statistic'
        },
        {
          title: 'p值',
          dataIndex: 'p',
          align: 'center',
          key: 'p'
        }
      ]
      const data = JSON.parse(pair_log_table_json).data
      // 给data中的值都保留三位小数
      data.forEach((v, i) => {
        for (const key in v) {
          if (!['level_0', 'level_1'].includes(key)) {
            const value = v[key]
            v[key] = value && value.toFixed(3)
          }
          v['key'] = i
        }
      })
      const text = `分析建议：<div>配对Log Rank用于"${x_lei_name}"下两两组别间生存时间的差异性比较。</div>
<div>若p值小于0.05，表示两个组别之间的生存时间有显著性差异。</div>
<div>若p值大于0.05，表示两个组别之间的生存时间无显著性差异。</div>`
      return {
        columns, data, title: '配对 Log Rank 检验', showHeader: true, size: 'small', text
      }
    }
  }
}
</script>