var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "缺失值处理",
        visible: _vm.visible,
        maskClosable: false,
        confirmLoading: _vm.loading,
        okText: "确定",
        cancelText: "取消"
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "missvalue", attrs: { model: _vm.form, layout: "vertical" } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "div",
            {
              staticStyle: {
                color: "#ff7200",
                height: "35px",
                "background-color": "#fff8ed",
                width: "100%",
                "line-height": "35px",
                "padding-left": "10px"
              }
            },
            [
              _vm._v(
                "缺失值处理后的数据仅供一次分析使用，如果想永久使用请另存为"
              )
            ]
          ),
          _c("br"),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "缺失值处理方法",
                prop: "fun",
                rules: {
                  required: true,
                  message: "必填"
                }
              }
            },
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.funchange },
                  model: {
                    value: _vm.form.fun,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "fun", $$v)
                    },
                    expression: "form.fun"
                  }
                },
                [
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "35px" },
                      attrs: { value: "default" }
                    },
                    [
                      _vm._v("固定值填充 "),
                      _c("a-input-number", {
                        attrs: {
                          placeholder: "固定值",
                          disabled: _vm.form.fun !== "default"
                        },
                        model: {
                          value: _vm.form.default,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "default", $$v)
                          },
                          expression: "form.default"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v("(仅支持定量数据) "),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.form.err))
                  ]),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "mean" }
                    },
                    [_vm._v("均值填充(仅支持定量数据)")]
                  ),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "median" }
                    },
                    [_vm._v("中位数填充(仅支持定量数据)")]
                  ),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "knn" }
                    },
                    [_vm._v("KNN填充(仅支持定量数据)")]
                  ),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "mode" }
                    },
                    [_vm._v("众数填充")]
                  ),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "last" }
                    },
                    [_vm._v("上条数据填充")]
                  ),
                  _c("br"),
                  _c(
                    "a-radio",
                    {
                      staticStyle: { height: "30px" },
                      attrs: { value: "next" }
                    },
                    [_vm._v("下条数据填充")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "处理的变量" } },
            [
              _vm.form.fun === "mode" ||
              _vm.form.fun === "last" ||
              _vm.form.fun === "next"
                ? _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder:
                          "默认对所有的变量进行处理，若需要处理部分变量，请在这里选择"
                      },
                      model: {
                        value: _vm.form.fields,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fields", $$v)
                        },
                        expression: "form.fields"
                      }
                    },
                    _vm._l(_vm.fields, function(item, i) {
                      return _c(
                        "a-select-option",
                        { key: i, attrs: { value: "" + item.fieldName } },
                        [_vm._v(_vm._s(item.fieldName))]
                      )
                    }),
                    1
                  )
                : _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder:
                          "默认对所有的变量进行处理，若需要处理部分变量，请在这里选择"
                      },
                      model: {
                        value: _vm.form.fields,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fields", $$v)
                        },
                        expression: "form.fields"
                      }
                    },
                    _vm._l(
                      _vm.fields.filter(function(r) {
                        return r.fieldType === "数字"
                      }),
                      function(item, i) {
                        return _c(
                          "a-select-option",
                          { key: i, attrs: { value: "" + item.fieldName } },
                          [_vm._v(_vm._s(item.fieldName))]
                        )
                      }
                    ),
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }