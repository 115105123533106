var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 1 && data.xys[1].length > 0
              ? _c("div", [
                  _c("div", [
                    _vm._v(
                      "1.<" +
                        _vm._s(data.xys[0][0].slice(-1)[0]) +
                        ">对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响不显著"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      "2.<" +
                        _vm._s(data.xys[0][1].slice(-1)[0]) +
                        ">对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响不显著"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      " 3.<" +
                        _vm._s(data.xys[0][0].slice(-1)[0]) +
                        ">和<" +
                        _vm._s(data.xys[0][1].slice(-1)[0]) +
                        ">的交互作用对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响不显著 "
                    )
                  ])
                ])
              : _vm._e()
          ])
        }
      },
      {
        key: "H1",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 1 && data.xys[1].length > 0
              ? _c("div", [
                  _c("div", [
                    _vm._v(
                      "1.<" +
                        _vm._s(data.xys[0][0].slice(-1)[0]) +
                        ">对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响显著"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      "2.<" +
                        _vm._s(data.xys[0][1].slice(-1)[0]) +
                        ">对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响显著"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      " 3.<" +
                        _vm._s(data.xys[0][0].slice(-1)[0]) +
                        ">和<" +
                        _vm._s(data.xys[0][1].slice(-1)[0]) +
                        ">的交互作用对<" +
                        _vm._s(data.xys[1][0]) +
                        ">的影响显著 "
                    )
                  ])
                ])
              : _vm._e()
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }