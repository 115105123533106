import { render, staticRenderFns } from "./PersonLayout.vue?vue&type=template&id=5474a59e&scoped=true&"
import script from "./PersonLayout.vue?vue&type=script&lang=js&"
export * from "./PersonLayout.vue?vue&type=script&lang=js&"
import style0 from "./PersonLayout.vue?vue&type=style&index=0&id=5474a59e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5474a59e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5474a59e')) {
      api.createRecord('5474a59e', component.options)
    } else {
      api.reload('5474a59e', component.options)
    }
    module.hot.accept("./PersonLayout.vue?vue&type=template&id=5474a59e&scoped=true&", function () {
      api.rerender('5474a59e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/PersonLayout.vue"
export default component.exports