var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false, id: "divQuestion" } },
    [
      _c(
        "div",
        { staticClass: "table-page-search-wrapper" },
        [
          _c(
            "a-form",
            { staticClass: "deviceC", attrs: { layout: "inline" } },
            [
              _c(
                "a-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "文件名称" } },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入文件名称（支持模糊查询）"
                            },
                            model: {
                              value: _vm.queryParam.name,
                              callback: function($$v) {
                                _vm.$set(_vm.queryParam, "name", $$v)
                              },
                              expression: "queryParam.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { md: 8, sm: 24 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "table-page-search-submitButtons",
                        style: {}
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.table.refresh(true)
                              }
                            }
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            on: {
                              click: function() {
                                ;(this$1.queryParam = {}),
                                  _vm.$refs.table.refresh(true)
                              }
                            }
                          },
                          [_vm._v("重置")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("a-col", { attrs: { md: 8, sm: 24 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "table-page-search-submitButtons",
                        staticStyle: { float: "right" }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", icon: "cloud-upload" },
                            on: { click: _vm.handleAdd }
                          },
                          [_vm._v("上传文件")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("s-table", {
        ref: "table",
        attrs: {
          size: "default",
          rowKey: "key",
          columns: _vm.columns,
          data: _vm.loadData,
          showPagination: "auto"
        },
        scopedSlots: _vm._u([
          {
            key: "number",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/question/AnswerList/" +
                            _vm.Base64.encode(record.id)
                        }
                      },
                      [_vm._v(_vm._s(text ? Number(text) : 0))]
                    )
                  ]
                ],
                2
              )
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$moment(text).format("YYYY-MM-DD HH:mm:ss"))
                      )
                    ])
                  ]
                ],
                2
              )
            }
          },
          {
            key: "deleteFlag",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  [
                    _c("a-badge", {
                      attrs: {
                        status: _vm._f("statusTypeFilter")(text),
                        text: _vm._f("statusFilter")(text)
                      }
                    })
                  ]
                ],
                2
              )
            }
          },
          {
            key: "action",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  [
                    _c(
                      "a",
                      {
                        staticClass: "copyData",
                        attrs: { disabled: record.deleteFlag ? true : false },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(record)
                          }
                        }
                      },
                      [_vm._v("查看数据")]
                    ),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleDel(record)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(record.control.deleteFlag ? "启用" : "删除")
                        )
                      ]
                    )
                  ]
                ],
                2
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }