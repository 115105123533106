<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :vuexForm="vuexForm"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <!-- <template #extraInput>
      <div></div>
    </template> -->
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '@/views/analysisOfRegression/components/indexComponent'

export default {
  components: { Index },
  data() {
    return {
      xysName: 'logicXys',
      xys: [[], []],
      formAddKeyName: 'logicFormAddKey',
      settings,
      form: {

      },
      vuexForm: {
        compareList: []
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.analysisOfRegression.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        // roc需要的参数
        form.rocArgs = {
          x: this.$deepClone(that.settings[0].list),
          y: this.$deepClone(that.settings[1].list),
          z: [],
          m: []
        }
        console.log('%c 🥠 form.rocArgs: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', form.rocArgs);
        Object.assign(form, this.$store.state.analysisOfRegression.logicForm)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/analysisOfRegression/result')
      } else {
        this.$message.info('请选择回归分析方法')
        return false
      }
    }
  }
}
</script>