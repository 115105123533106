var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticStyle: { "border-right": "1px solid rgb(232, 232, 232)" } },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  {
                    staticStyle: {
                      padding: "30px",
                      "min-height": "750px",
                      "border-right": "1px solid rgb(232, 232, 232)"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "a-row",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("a-col", { attrs: { span: 24 } }, [
                                  _c("h1", [_vm._v("描述性分析结果")])
                                ])
                              ],
                              1
                            ),
                            _c(
                              "a-tabs",
                              {
                                attrs: { type: "editable-card", "hide-add": "" }
                              },
                              _vm._l(_vm.part2.tables, function(
                                value,
                                key,
                                index
                              ) {
                                return _c(
                                  "a-tab-pane",
                                  {
                                    key: index,
                                    staticStyle: { "padding-bottom": "1px" },
                                    attrs: { tab: key, closable: false }
                                  },
                                  [
                                    _c("myTable", {
                                      attrs: {
                                        data: value.data,
                                        columns: value.columns,
                                        title: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c("myTable", {
                              attrs: {
                                data: _vm.data,
                                columns: _vm.columns,
                                title: "主体间效应检验"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { staticStyle: { padding: "30px" }, attrs: { span: 24 } },
                  [
                    _c(
                      "box-shadow-card",
                      { attrs: { title: "多因素方差智能分析结果" } },
                      _vm._l(
                        _vm.part2.combine.map(function(v) {
                          return v
                            .map(function(j) {
                              return "<" + j + ">"
                            })
                            .join("")
                        }),
                        function(item, index) {
                          return _c("div", { key: index }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(index + 1) + ". ")
                            ]),
                            _vm.part2.pList[index] instanceof String
                              ? _c("span", [
                                  _vm._v(" 由于" + _vm._s(item) + "的"),
                                  _c("i", [_vm._v("p")]),
                                  _vm._v(
                                    "＝" +
                                      _vm._s(_vm.part2.pList[index]) +
                                      " " +
                                      _vm._s(
                                        _vm.$hypothesis.getJudgeByP(
                                          _vm.part2.pList[index]
                                        )
                                      ) +
                                      " 0.05，因此 "
                                  )
                                ])
                              : _vm._e(),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.result(item, _vm.part2.pList[index])
                                )
                              }
                            })
                          ])
                        }
                      ),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }