var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "二维码", visible: _vm.visible, footer: null },
      on: {
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c("div", { staticStyle: { width: "100%", "text-align": "center" } }, [
        _c("div", [_vm._v(_vm._s(_vm.url) + " ")]),
        _c("br"),
        _c("div", [_vm._v("已复制到剪贴板")]),
        _c("canvas", {
          staticStyle: { margin: "0 auto" },
          attrs: { id: "QRCode" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }