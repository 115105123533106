<template>
  <a-card :bordered="false" id="divQuestion">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="手机号码">
              <a-input v-model="queryParam.name" placeholder="请输入手机号码（支持模糊查询）" />
            </a-form-item>
          </a-col>
          <a-col :md="8 " :sm="24">
            <span class="table-page-search-submitButtons" :style=" {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="() => {this.queryParam = {id:queryParam.id},$refs.table.refresh(true)}"
              >重置</a-button>
            </span>
          </a-col>
          <a-col :md="8" :sm="24" style="text-align:right">
            <router-link to="/question" style="margin-left: 10px">&lt;&lt;返回量表(问卷)列表</router-link>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      bordered
      style="margin-top: 20px"
      ref="table"
      size="small"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
      :scroll="{ x: true }"
    >
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { GetScoreStatices } from '@/api/questionnaire'
import { Modal } from 'ant-design-vue'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
// 列
const colums = [
  {
    title: '手机号码',
    dataIndex: '手机号码',
    fixed: 'left',
    width: 200,
    align: 'center'
  },
  {
    title: '躯体不适',
    children: [
      {
        title: '治疗前',
        dataIndex: '治疗前_躯体不适',
        align: 'center'
      },
      {
        title: '治疗2周',
        dataIndex: '治疗2周_躯体不适',
        align: 'center'
      },
      {
        title: '治疗1个月',
        dataIndex: '治疗1个月_躯体不适',
        align: 'center'
      }
    ]
  },
  {
    title: '心理社会不适',
    children: [
      {
        title: '治疗前',
        dataIndex: '治疗前_心理社会不适',
        align: 'center'
      },
      {
        title: '治疗2周',
        dataIndex: '治疗2周_心理社会不适',
        align: 'center'
      },
      {
        title: '治疗1个月',
        dataIndex: '治疗1个月_心理社会不适',
        align: 'center'
      }
    ]
  },
  {
    title: '担心和焦虑',
    children: [
      {
        title: '治疗前',
        dataIndex: '治疗前_担心和焦虑',
        align: 'center'
      },
      {
        title: '治疗2周',
        dataIndex: '治疗2周_担心和焦虑',
        align: 'center'
      },
      {
        title: '治疗1个月',
        dataIndex: '治疗1个月_担心和焦虑',
        align: 'center'
      }
    ]
  },
  {
    title: '满意度',
    children: [
      {
        title: '治疗前',
        dataIndex: '治疗前_满意度',
        align: 'center'
      },
      {
        title: '治疗2周',
        dataIndex: '治疗2周_满意度',
        align: 'center'
      },
      {
        title: '治疗1个月',
        dataIndex: '治疗1个月_满意度',
        align: 'center'
      }
    ]
  },
  {
    title: '总体',
    children: [
      {
        title: '治疗前',
        dataIndex: '治疗前_总体',
        align: 'center'
      },
      {
        title: '治疗2周',
        dataIndex: '治疗2周_总体',
        align: 'center'
      },
      {
        title: '治疗1个月',
        dataIndex: '治疗1个月_总体',
        align: 'center'
      }
    ]
  }
]
export default {
  // 加载组件
  components: {
    STable
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      // 新建/编辑modal是否显示
      visible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      filename: '',
      count: 0,
      title: '创建量表(问卷)',
      // 查询参数
      queryParam: { id: Number(Base64.decode(this.$route.params.id)) },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return GetScoreStatices({ ...requestParameters, orgid: this.$store.state.user.info.orgid }).then(res => {
          res.data = res && JSON.parse(res.data[0])
          return res
        })
      },
      // 如果有人答题则无法修改模版
      disable: false,
      qrvisible: false,
      url: '',
      Base64: Base64
    }
  },
  // 方法
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divQuestion']
      AutoHeight(this, min, max, 176)
    },
    /**
     * 新建事件
     */
    handleAdd() {
      this.$router.push('/file/Add')
    },
    /**
     * 编辑事件
     */

    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record, 'range-picker': [record.startDate, record.endDate] }
      this.title = '编辑量表(问卷)'
      if (record.numberOfPeople && Number(record.numberOfPeople) > 0) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
    /**
     * 新建/编辑cancel事件
     */

    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },

    /**
     *启用/停用事件
     */
    handleDel(record) {
      const title = record.deleteFlag ? '启用' : '停用'
      Modal.confirm({
        title: '您确定' + title + '该量表(问卷)吗？',
        content: '停用后将无法进行量表(问卷)录入，无法进行统计分析',
        onOk: () => {
          // deleteQuestionnaire(record.id).then(res => {
          //   // 刷新表格
          //   this.$refs.table.refresh()
          // })
        }
      })
    },

    handlePreview(record) {
      this.$router.push(`/file/FileData/${record.id}`)
    }
  },
  /**
   * 过滤器
   */
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }
  }
}
</script>
