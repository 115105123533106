var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "personHome" } },
    [
      _c(
        "a-row",
        {
          attrs: { id: "top", type: "flex", justify: "center", align: "middle" }
        },
        [
          _c(
            "a-col",
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        color: "white",
                        "font-size": "30px",
                        "margin-bottom": "10px"
                      }
                    },
                    [_vm._v("数据分析 如此简单")]
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c("a-col", { staticStyle: { color: "white" } }, [
                    _vm._v(
                      "UUBigData采用标准化的数据分析算法，拖拽式操作，一键生成规范化的分析结果，帮助用户节省时间，提高分析效率和准确度。"
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { id: "content", type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 15 } },
            [
              _c(
                "a-row",
                {
                  staticStyle: { margin: "15px", "font-size": "25px" },
                  attrs: { type: "flex", justify: "center" }
                },
                [_c("a-col", [_vm._v(" 会员权益对比 ")])],
                1
              ),
              _c(
                "table",
                {
                  attrs: {
                    id: "table",
                    border: "1",
                    cellpadding: "10",
                    width: "100%"
                  }
                },
                [
                  _c("tr", [
                    _c("th", { staticClass: "common-th" }, [_vm._v("权益")]),
                    _c("th", { staticClass: "common-th" }, [
                      _vm._v("普通会员")
                    ]),
                    _c("th", { staticClass: "vip-th" }, [_vm._v("VIP会员")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("上传数据")]),
                    _c("td", [_vm._v("可用（限20条）")]),
                    _c("td", [_vm._v("可用（不限制数量）")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("创建量表（问卷）")]),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon close",
                          attrs: { type: "close-circle", theme: "filled" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon check",
                          attrs: { type: "check-circle", theme: "filled" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("假设检验")]),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon close",
                          attrs: { type: "close-circle", theme: "filled" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon check",
                          attrs: { type: "check-circle", theme: "filled" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("回归分析")]),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon close",
                          attrs: { type: "close-circle", theme: "filled" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon check",
                          attrs: { type: "check-circle", theme: "filled" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("智能图表")]),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon close",
                          attrs: { type: "close-circle", theme: "filled" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon check",
                          attrs: { type: "check-circle", theme: "filled" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("分析数据")]),
                    _c("td", [_vm._v("限50条")]),
                    _c("td", [_vm._v("所有数据")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("图表下载")]),
                    _c("td", [_vm._v("可下载，但不可自定义图表信息")]),
                    _c("td", [_vm._v("下载高清原图，并可自定义图表信息")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("存储空间")]),
                    _c("td", [_vm._v("200M")]),
                    _c("td", [_vm._v("1G")])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("7*24客服")]),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon close",
                          attrs: { type: "close-circle", theme: "filled" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("a-icon", {
                          staticClass: "icon check",
                          attrs: { type: "check-circle", theme: "filled" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "a-row",
                {
                  staticStyle: { margin: "15px", "font-size": "25px" },
                  attrs: { type: "flex", justify: "center" }
                },
                [_c("a-col", [_vm._v(" VIP会员限时特价 ")])],
                1
              ),
              _c(
                "a-row",
                {
                  attrs: { gutter: 80, type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { flex: 1 } },
                    [
                      _c("PriceCard", {
                        attrs: { title: "月会员", price: 238, unit: "月" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { flex: 1 } },
                    [
                      _c("PriceCard", {
                        attrs: { title: "季会员", price: 678, unit: "季" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { flex: 1 } },
                    [
                      _c("PriceCard", {
                        attrs: { title: "年会员", price: 2428, unit: "年" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }