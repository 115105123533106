/* eslint-disable no-useless-constructor */
import {
    Req
  } from '@/utils/util'
  import {
    ParamStatistic
  } from '@/views/hypothesisTest/utils/paramStatisticClass'
  export class MakeReq extends Req {
    constructor(form, method, that) {
      super(form, method, that)
    }
    // 例如cochran
    leiDuo() {
        const leiList = this.form.xys[0]
        this.form.list = []
        this.form.listToMakeResult = []
        this.form.yList = []
        this.that.documents.forEach(element => {
            const obj = {}
            const list = []
            leiList.forEach(lei => {
                const player = lei[lei.length - 1]
                const comment = element[player] || ''
                obj[player] = comment
                list.push(comment)
            })
            this.form.list.push(obj)
            this.form.listToMakeResult.push(list)
            this.form.yList.push(element.key)
        })
        return this.req
    }
    // 例如Friedman
    liangDuo() {
        const liangList = this.form.xys[0]
        this.form.list = []
        // 构造描述性分析需要的数据结构
        this.descriptionLiangDuo(liangList)
        liangList.forEach(v => {
            this.form.list.push([])
        })
        for (const element of this.that.documents) {
                liangList.forEach((liang, index) => {
                    this.form.list[index].push(element[liang])
                })
                // 构造描述性分析函数
                new ParamStatistic(element, '', liangList, this.form.description).liangDuo()
        }
        return this.req
    }
    // 例如kruskal-wallis多样本秩检验
    // xuLiang(){

    // }
  }