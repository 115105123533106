<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    :rules="rules"
    @click="onClick"
  >
    <template #H0="{ xys }">
      <div v-if="xys[1].length > 0 && xys[0].length === 0">&lt;{{ xys[1][0] }}&gt;服从正态分布</div>
      <div v-if="xys[1].length > 0 && xys[0].length > 0">
        分别为&lt;{{ xys[0][0].slice(-1)[0] }}&gt;中&lt;总体>、{{
          xys[0][0]
            .slice(0, -1)
            .map((v) => `&lt;${v}&gt;`)
            .join('、')
        }}的&lt;{{ xys[1][0] }}&gt;做正态检验，符合正态分布。
      </div>
    </template>
    <template #H1="{ xys }">
      <div v-if="xys[1].length > 0 && xys[0].length === 0">&lt;{{ xys[1][0] }}&gt;不服从正态分布</div>
      <div v-if="xys[1].length > 0 && xys[0].length > 0">
        分别为&lt;{{ xys[0][0].slice(-1)[0] }}&gt;中&lt;总体>、{{
          xys[0][0]
            .slice(0, -1)
            .map((v) => `&lt;${v}&gt;`)
            .join('、')
        }}的&lt;{{ xys[1][0] }}&gt;做正态检验，不符合正态分布。
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'ztjyXys',
      xys: [[], []],
      formAddKeyName: 'ztjyFormAddKey',
      settings,
      form: {
        a: '5',
        method: 0
      },
      rules: {}
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>