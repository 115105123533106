<template>
  <a-modal
    title="缺失值处理结果另存为"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="loading"
    okText="确定"
    cancelText="取消"
    @ok="
      () => {
        $emit('ok')
      }
    "
    @cancel="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-form-model :form="form" v-bind="formLayout">
      <a-form-model-item label="另存为的文件名称">
        <a-input v-model="form.fileName"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },

    loading: {
      type: Boolean,
      default: () => false
    },
    form: {
        type: Object,
        default: () => {}
    }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }
    return {

    }
  },
  methods: {
    funchange(e) {
      if (e.target.value !== 'default') {
        this.form.default = ''
      }
    }
  }
}
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  margin-left: 2px;
  color: #999;
  transition: all 0.3s;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
</style>