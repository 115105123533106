var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      staticClass: "minHeight",
      attrs: {
        breadcrumb: null,
        "tab-list": _vm.tabList,
        "tab-active-key": _vm.tabActiveKey,
        "tab-change": _vm.handleTabChange
      }
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }