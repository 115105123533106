import Mock from 'mockjs2'
import { builder, getQueryParameters } from '../util'

const name = [
  '张三',
  '王春伟',
  '李荣飞',
  '范丞丞',
  '李雪',
  '王璐瑶',
  '朱黎明',
  '宋文',
  '张三1',
  '王春伟1',
  '李荣飞1',
  '范丞丞1',
  '李雪1',
  '王璐瑶1',
  '朱黎明1',
  '宋文1',
  '张三2',
  '王春伟2',
  '李荣飞2',
  '范丞丞2',
  '李雪2',
  '王璐瑶2',
  '朱黎明2',
  '宋文2',
  '张三3',
  '王春伟3',
  '李荣飞3',
  '范丞丞3',
  '李雪3',
  '王璐瑶3'
]

const SerialNo = [
  '321282187810184201',
  '321282187810184202',
  '321282187810184203',
  '321282187810184204',
  '321282187810184205',
  '321282187810184206',
  '321282187810184207',
  '321282187810184208',
  '321282187810184209',
  '321282187810184210',
  '321282187810184211',
  '321282187810184212',
  '321282187810184213',
  '321282187810184214',
  '321282187810184215',
  '321282187810184216',
  '321282187810184217',
  '321282187810184218',
  '321282187810184219',
  '321282187810184220',
  '321282187810184221',
  '321282187810184222',
  '321282187810184223',
  '321282187810184224',
  '321282187810184225',
  '321282187810184226',
  '321282187810184227',
  '321282187810184228',
  '321282187810184229',
  '321282187810184230'
]

const sex = ['男', '女']

const bloodtype = ['A', 'B', 'O', 'AB']

const document = (options) => {
  const totalCount = name.length
  const parameters = getQueryParameters(options)
  console.log('queryParameters', parameters)
  const pageNo = parseInt(parameters.pageNo)
  const pageSize = parseInt(parameters.pageSize)
  const totalPage = Math.ceil(totalCount / pageSize)

  const result = []
  for (let i = 0; i < totalCount; i++) {
    result.push({
      key: i + 1,
      id: i + 1,
      name: name[i],
      serialNo: SerialNo[i],
      sex: sex[Math.floor(Math.random())],
      age: Math.floor(Math.random() * (60 - 20) + 20),
      bloodtype: bloodtype[Math.floor(Math.random() * 3)]
    })
  }
  return builder({
    pageSize: pageSize,
    pageNo: pageNo,
    totalCount: totalCount,
    totalPage: totalPage,
    data: result
  })
}

const createDocument = {
  code: 200,
  message: '保存成功'
}

Mock.mock(/\/list\/document/, 'get', document)
Mock.mock(/\/list\/document/, 'post', createDocument)
