var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      attrs: { breadcrumb: null },
      scopedSlots: _vm._u([
        {
          key: "extra",
          fn: function() {
            return [_c("Sample")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "a-layout",
            { staticClass: "divContent", attrs: { id: "divContent" } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        "border-left": "1px solid rgb(232, 232, 232)",
                        "border-right": "1px solid rgb(232, 232, 232)"
                      },
                      attrs: { span: 4 }
                    },
                    [_c("field", { attrs: { from: "hypothesis" } })],
                    1
                  ),
                  _c("a-col", { attrs: { span: 20, id: "hypContent" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          padding: "20px",
                          overflow: "auto"
                        }
                      },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-bottom": "10px" },
                                    attrs: { type: "primary" },
                                    on: { click: _vm.onClick }
                                  },
                                  [_vm._v("生成智能报告")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { margin: "0 10px" },
                                    attrs: {
                                      disabled: this.load.anysisLoading
                                    },
                                    on: { click: _vm.reset }
                                  },
                                  [_vm._v("重置")]
                                ),
                                _c("Settings", {
                                  ref: "settings",
                                  attrs: { parentType: _vm.typeOfFilter }
                                }),
                                _vm.load.anysisLoading
                                  ? _c("a-spin", {
                                      staticStyle: { "margin-left": "10px" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "part" },
                            [
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 24 } }, [
                                    _c("h1", [_vm._v("智能分析")])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { gutter: 16 }
                                },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "a-form-model",
                                        {
                                          ref: "ruleForm1",
                                          attrs: { model: _vm.form1 }
                                        },
                                        [
                                          _c("ReportDrops", {
                                            attrs: {
                                              item: _vm.item3,
                                              vuex: "report",
                                              typeSelectOptions:
                                                _vm.typeSelectOptions,
                                              load: _vm.load
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "part" },
                            [
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 24 } }, [
                                    _c("h1", [_vm._v("基础分析")])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { gutter: 16 }
                                },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "a-form-model",
                                        {
                                          ref: "ruleForm2",
                                          attrs: { model: _vm.form2 }
                                        },
                                        [
                                          _c("Drops", {
                                            attrs: {
                                              item: _vm.item2,
                                              vuex: "report",
                                              load: _vm.load
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "part" },
                            [
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 24 } }, [
                                    _c("h1", [_vm._v("基础统计")])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { gutter: 16 }
                                },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { type: "flex" } },
                                        [
                                          _c("a-col", {
                                            staticClass: "center",
                                            attrs: { flex: "50px" }
                                          }),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 21 } },
                                            [
                                              _c(
                                                "a-row",
                                                {
                                                  attrs: {
                                                    type: "flex",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 22 } },
                                                    [
                                                      _c("Drop", {
                                                        attrs: {
                                                          item: _vm.item1,
                                                          index: 0,
                                                          parentType: "drop",
                                                          load: _vm.load,
                                                          indexGroup:
                                                            _vm
                                                              .indexSettings1[0],
                                                          xysName: "drop1",
                                                          vuex: "report"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("a-col", {
                                            staticClass: "center",
                                            attrs: { flex: "25px" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }