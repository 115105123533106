import { render, staticRenderFns } from "./dropItems.vue?vue&type=template&id=a17bd262&"
import script from "./dropItems.vue?vue&type=script&lang=js&"
export * from "./dropItems.vue?vue&type=script&lang=js&"
import style0 from "./dropItems.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a17bd262')) {
      api.createRecord('a17bd262', component.options)
    } else {
      api.reload('a17bd262', component.options)
    }
    module.hot.accept("./dropItems.vue?vue&type=template&id=a17bd262&", function () {
      api.rerender('a17bd262', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hypothesisTest/components/dropItems.vue"
export default component.exports