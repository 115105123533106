<template>
  <div class="methodWrapper" style="padding: 15px; overflow: auto" id="method">
    <a-collapse :activeKey="[0, 1, 2, 3, 4, 5, 6, 7]" :bordered="false">
      <a-collapse-panel
        v-for="(item, index) in methods"
        :key="index.toString()"
        :header="item.name"
        style="border-bottom: 1px solid rgb(232, 232, 232)"
      >
        <a-row :gutter="[10, 8]">
          <a-col v-for="(item1, index1) in item.children" :key="index1" :span="12">
            <div
              :class="{ item: true, active: active === item1.componentName, disabled: item1.state === 0 }"
              @click="onClick(item1)"
            >
              {{ item1.name }}
            </div>
            <!-- <router-link :to="{path:`/hypothesistest/hypothesis/${item1.componentName}`}">{{ item1.name }}</router-link> -->
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>
<script>
import { methods } from '@/common/enums'
export default {
  created() {
    console.log(this.$route.params.name)
  },
  data() {
    return {
      methods,
      activeKey: [0, 1, 2, 3, 4, 5, 6, 7]
    }
  },
  methods: {
    onClick(item) {
      if (item.state === 1) {
        this.$store.dispatch('saveMethod', item.componentName)
        this.$router.push(`/hypothesistest/hypothesis/${item.componentName}`)
      }
    }
  },
  computed: {
    active() {
      return this.$route.params.name === 'index' ? '' : this.$store.state.hypothesis.method
    }
  }
  // ,
  // watch: {
  //   $route(newValue, oldValue) {
  //     if (newValue.name === '假设检验样本') {
  //       var menu = document.getElementsByClassName('ant-menu')
  //       var liList = menu[0].childNodes
  //       var liHypothesis = liList[liList.length - 2]
  //       var a = liHypothesis.childNodes[0]
  //       a.classList.add('router-link-active')
  //       liHypothesis.className = 'ant-menu-item ant-menu-item-selected'
  //     }
  //   }
  // }
}
</script>

<style lang="less" scoped>
.item {
  cursor: pointer;
  background-color: white;
  padding: 5px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.disabled {
  cursor: auto;
  color: grey;
  background-color: #f7f7f7;
}
.active {
  color: white;
  background-color: rgb(24, 144, 255);
}
</style>