<template>
  <page-header-wrapper
    :breadcrumb="null"
    :tab-list="tabList"
    :tab-active-key="tabActiveKey"
    :tab-change="handleTabChange"
    class="minHeight"
  >
    <router-view />
  </page-header-wrapper>
</template>

<script>
const getActiveKey = path => {
  if (path.indexOf('document') > -1) {
    return '1'
  } else if (path.indexOf('questionnaire') > -1) {
    return '2'
  } else if (path.indexOf('experiment') > -1) {
    return '3'
  } else if (path.indexOf('file') > -1) {
    return '4'
  }
}

export default {
  data() {
    return {
      tabList: [],
      tabActiveKey: '1'
    }
  },
  created() {
    // 默认是档案，如果档案隐藏的话，请跳转到问卷
    const type = this.$store.state.samples.type
    if (
      !this.$store.state.user.info.org ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowDoc) ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowDoc === null)
    ) {
      this.tabList.push({ key: '1', tab: '档案数据' })
    } else {
      if (type) {
        if (type === 'questionnaire') { this.handleTabChange('2') } else if (type === 'file') { this.handleTabChange('4') } else if (type === 'experiment') { this.handleTabChange('3') }
      } else {
      this.handleTabChange('2')
      }
    }
    if (
      !this.$store.state.user.info.org ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowQue) ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowQue === null)
    ) {
      this.tabList.push({ key: '2', tab: '量表(问卷)数据' })
    }

    if (
      !this.$store.state.user.info.org ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowExperiment) ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowExperiment === null)
    ) {
      this.tabList.push({ key: '3', tab: '实验数据' })
    }

    if (
      !this.$store.state.user.info.org ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowFile) ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowFile === null)
    ) {
      this.tabList.push({ key: '4', tab: '文件' })
    }

    // this.tabList.push({ key: '4', tab: '文件' })

    this.tabActiveKey = getActiveKey(this.$route.path)
    this.$watch('$route', val => {
      if (val.path.indexOf('document') !== -1) {
        if (
          !(
            !this.$store.state.user.info.org ||
            (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowDoc) ||
            (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowDoc === null)
          )
        ) {
          this.handleTabChange('2')
        }
      }
      this.tabActiveKey = getActiveKey(val.path)
    })
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    handleTabChange(key) {
      this.tabActiveKey = key
      const pathname = window.location.pathname
      if (pathname.indexOf('hypothesistest') !== -1) {
        // 假设检验
        switch (key) {
          case '1':
            this.$router.push('/hypothesistest/screendata/document')
            this.reload()
            break
          case '2':
            this.$router.push('/hypothesistest/screendata/questionnaire')
            this.reload()
            break
          case '3':
            this.$router.push('/hypothesistest/screendata/experiment')
            this.reload()
            break
          case '4':
            this.$router.push('/hypothesistest/screendata/file')
            this.reload()
            break
          default:
            this.$router.push('/workplace')
        }
      } else if (pathname.indexOf('customchart') !== -1) {
        // 自定义图表
        switch (key) {
          case '1':
            this.$router.push('/customchart/screendata/document')
            this.reload()
            break
          case '2':
            this.$router.push('/customchart/screendata/questionnaire')
            this.reload()
            break
          case '3':
            this.$router.push('/customchart/screendata/experiment')
            this.reload()
            break
          case '4':
            this.$router.push('/customchart/screendata/file')
            this.reload()
            break
          default:
            this.$router.push('/workplace')
        }
      } else if (pathname.indexOf('analysisOfRegression') !== -1) {
        // 自定义图表
        switch (key) {
          case '1':
            this.$router.push('/analysisOfRegression/screendata/document')
            this.reload()
            break
          case '2':
            this.$router.push('/analysisOfRegression/screendata/questionnaire')
            this.reload()
            break
          case '3':
            this.$router.push('/analysisOfRegression/screendata/experiment')
            this.reload()
            break
          case '4':
            this.$router.push('/analysisOfRegression/screendata/file')
            this.reload()
            break
          default:
            this.$router.push('/workplace')
        }
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        // 自定义图表
        switch (key) {
          case '1':
            this.$router.push('/intelligenceReports/screendata/document')
            this.reload()
            break
          case '2':
            this.$router.push('/intelligenceReports/screendata/questionnaire')
            this.reload()
            break
          case '3':
            this.$router.push('/intelligenceReports/screendata/experiment')
            this.reload()
            break
          case '4':
            this.$router.push('/intelligenceReports/screendata/file')
            this.reload()
            break
          default:
            this.$router.push('/workplace')
        }
      } else {
        // 常用图表
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  overflow: auto !important;
}
</style>