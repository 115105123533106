<template>
  <div class="leftWidthHypo fieldWrapper" id="afield">
    <a-form-model-item
      label="变量"
      prop="selectKeys"
      :rules="{
        required: true,
        message: '请选择',
      }"
    >
      <div :style="{ borderBottom: '1px solid #E9E9E9' }">
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"> 全选 </a-checkbox>
      </div>
      <a-checkbox-group v-model="form.selectKeys" @change="onChange">
        <div v-for="(item, i) in data" :key="i">
          <a-checkbox :value="item.fieldName" :style="radioStyle">
            <span>
              <img :src="`/chartsIcons/${isFieldType(item, 'icon')}.png`" alt="定类" style="margin-right: 10px" />
              {{ item.fieldName }}
            </span>
          </a-checkbox>
        </div>
      </a-checkbox-group>
    </a-form-model-item>
  </div>
</template>
<script>
// import { getFields, getQuestionFields, GetFileField, GetExperimentField } from '@/api/screendata'

import { AutoHeight } from '@/utils/util'
const columns = [
  {
    title: '变量名',
    dataIndex: 'fieldName',
    key: 'key'
  }
]
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    this.columns = columns
    return {
      indeterminate: false,
      checkAll: false,
      selectedRowKeys: [],
      data: this.$store.state.samples.fields
        ? JSON.parse(this.$store.state.samples.fields).filter(r => r.fieldType === '数字' || r.fieldType === '日期')
        : [],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      }
    }
  },
  watch: {
    form(val, oldval) {
      this.indeterminate = !!val.selectKeys.length && val.selectKeys.length < this.data.length
      this.checkAll = val.selectKeys.length > 0
    }
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.data.length
      this.checkAll = checkedList.length === this.form.selectKeys.length
    },
    onCheckAllChange(e) {
      this.form.selectKeys = e.target.checked
        ? this.data.map(r => {
            return r.fieldName
          })
        : []
      this.indeterminate = false
      this.checkAll = e.target.checked
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = ['afield']
      var min = ['afield']
      AutoHeight(this, min, max, 176)
    },
    gotoBack() {
      const pathname = window.location.pathname
      if (pathname.indexOf('questionnaire') > -1) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/questionnaire')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/questionnaire')
        } else {
          this.$router.push('/customchart/screendata/questionnaire')
        }
      } else if (pathname.indexOf('document') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/document')
        } else {
          this.$router.push('/customchart/screendata/document')
        }
      } else if (pathname.indexOf('experiment') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/experiment')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/experiment')
        } else {
          this.$router.push('/customchart/screendata/experiment')
        }
      } else if (pathname.indexOf('file') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/file')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/file')
        } else {
          this.$router.push('/customchart/screendata/file')
        }
      }
    },
    onSelectChange(selectedRowKeys) {
      this.form.selectKeys = selectedRowKeys
    },
    // 判断定量还是定类的对象
    isFieldType(type, from) {
      switch (type.fieldType) {
        case '数字':
        case '日期':
          return from === 'icon' ? 'dingliang' : '定量'
        case '单选':
          if (type.forOrder === 1) {
            return from === 'icon' ? 'youxu' : '定序'
          } else {
            return from === 'icon' ? 'dinglei' : '定类'
          }
        case '多选':
        case '下拉框':
          return from === 'icon' ? 'dinglei' : '定类'
        default:
          return from === 'icon' ? 'kong' : '其他变量'
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  background-color: blanchedalmond;
}
.leftWidthChart {
  padding: 20px;
  background-color: white;
  border-right: 1px solid rgb(232, 232, 232);
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 999;
  width: 252px !important;
  overflow: auto;
}
.leftWidthHypo {
  padding: 20px;
  width: 100%;
  overflow: auto;
}
.ant-layout-sider {
  width: 100% !important;
  max-width: 100% !important;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.ant-layout-sider {
  background-color: white !important;
}
</style>