// 数字转换成excel列AA
export const getColFromNum = (n) => {
    const ordA = 'A'.charCodeAt(0)
    const ordZ = 'Z'.charCodeAt(0)
    const len = ordZ - ordA + 1
    let str = ''
    while (n >= 0) {
      str = String.fromCharCode((n % len) + ordA) + str
      n = Math.floor(n / len) - 1
    }
    return str
  }