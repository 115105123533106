import request from '@/utils/request'

const api = {
    historyModels: '/HistoryModel'
}

export function getHistoryModels(parameter) {
    return request({
        url: api.historyModels,
        method: 'get',
        data: parameter
    })
}
