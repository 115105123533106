<template>
  <a-layout class="divContent" id="divContent">
    <a-row>
      <a-col :span="screenWidth > 1080 ? 3 : 4">
        <div class="table-page-search-wrapper divContentleft" id="divContentleft">
          <div>
            <div>
              <a-select show-search :filter-option="filterOption" @change="selectChange" v-model="queryParam.id"
                        style="width: 100%" placeholder="请选择文件">
                <a-select-option :title="item.name" v-for="(item, i) in questionnaires" :key="i" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </div>
            <div class="table-operator" style="margin-top: 10px">
              <span>筛选变量</span>
              <!-- <a style="float: right; text-decoration: underline" @click="dataHandle()">变量处理</a> -->
            </div>
            <a-table ref="table" size="small" rowKey="key" :columns="columns" :data-source="data" :pagination="false"
                     :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 30 }"
                     :scroll="{ y: scrolly }"></a-table>
            <!-- <a-button type="primary" style="margin-top:10px;float:right" @click="submitChoose">确认选择</a-button> -->
          </div>
        </div>
      </a-col>
      <a-col :span="screenWidth > 1080 ? 21 : 20" style="border-left: 1px solid rgb(232, 232, 232)">
        <a-layout-content style="padding: 20px 20px; margin: 0 auto">
          <router-view v-if="isRouterAlive"></router-view>
        </a-layout-content>
      </a-col>
    </a-row>
  </a-layout>
</template>
<script>
import { GetFileField, GetFile } from '@/api/screendata'
import { AutoHeight } from '@/utils/util'

const columns = [
  {
    title: '变量名',
    dataIndex: 'displayname',
    key: 'key'
  }
]

export default {
  components: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    this.columns = columns
    return {
      screenWidth: document.body.clientWidth,
      // 查询参数
      queryParam: {},
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
      },
      pagination: {
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: false, // 是否可以快速跳转至某页
        showSizeChanger: false // 是否可以改变 pageSize
      },
      // 是否点击了新建按钮
      isNewAdd: false,
      selectedRowKeys: [],
      data: [],
      questionnaires: [],
      isRouterAlive: true,
      scrolly: window.screen.height > 800 ? 550 : 400
    }
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  created() {
    // 高度自定义使用
    this.autoHeightChange()
    const fields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
    const type = this.$store.state.samples.type
    if (this.$store.state.samples.fileid) {
      this.queryParam.id = Number(this.$store.state.samples.fileid)
    }
    const pathname = window.location.pathname
    if (type === 'file') {
      if (fields) {
        fields.map(r => {
          this.selectedRowKeys.push(r.key)
        })
      }
    }
    GetFile({ orgid: this.$store.state.user.info.orgid, userid: this.$store.state.user.info.id, trial: this.$store.state.user.info.trial }).then(res => {
      this.questionnaires = res
    })
    if (this.queryParam.id) {
      GetFileField({ id: this.queryParam.id }).then(res => {
        this.data = res
        if (type === 'file') {
          if (fields && fields.length > 0) {
            this.$store.dispatch('updateSamples', {
              fields: JSON.stringify(res.filter(r => this.selectedRowKeys.indexOf(r.key) > -1))
            })
          }
        }
      })
    }

    if (this.selectedRowKeys.length === 0) {
      if (pathname.indexOf('hypothesistest') !== -1) {
        this.$router.push('/hypothesistest/screendata/file/nodata')
      } else if (pathname.indexOf('customchart') !== -1) {
        this.$router.push('/customchart/screendata/file/nodata')
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        this.$router.push('/intelligenceReports/screendata/file/nodata')
      } else {
        this.$router.push('/analysisOfRegression/screendata/file/nodata')
      }
    } else {
      var questionid = this.queryParam.id !== undefined ? this.queryParam.id : ''
      if (pathname.indexOf('hypothesistest') > -1) {
        this.$router.push(`/hypothesistest/screendata/file/data/${questionid}`)
      } else if (pathname.indexOf('customchart') > -1) {
        this.$router.push(`/customchart/screendata/file/data/${questionid}`)
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        this.$router.push(`/intelligenceReports/screendata/file/data/${questionid}`)
      } else {
        this.$router.push(`/analysisOfRegression/screendata/file/data/${questionid}`)
      }
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      // 高度自定义使用
      AutoHeight(this, ['divContent', 'divContentleft'], [], 215)
    },
    /**
     * 变量处理
     */
    dataHandle() {
      var id = this.$store.state.samples.fileid
      if (id) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/dataHandle/file/rangecode')
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push('/customchart/dataHandle/file/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push('/intelligenceReports/dataHandle/file/rangecode')
        } else {
          this.$router.push('/analysisOfRegression/dataHandle/file/rangecode')
        }
      } else {
        this.$message.warning('请选择一个文件')
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.$store.dispatch('updateSamples', { type: 'file' })
      this.$store.dispatch('updateSamples', { fields: JSON.stringify(selectedRows) })
      this.$store.dispatch('updateSamples', { sxFields: null })

      this.submitChoose()
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    selectChange(e) {
      this.$store.dispatch('updateSamples', { fields: '' })
      this.$store.dispatch('updateSamples', { keys: '' })
      this.$store.dispatch('updateSamples', { questionnaireID: null })
      this.$store.dispatch('updateSamples', { experimentID: null })
      this.$store.dispatch('updateSamples', { questionnaireModelID: null })
      this.selectedRowKeys = []
      if (e) {
        this.$store.dispatch('updateSamples', { fileid: e })
        this.$store.dispatch('updateSamples', { source: this.questionnaires.filter(r => r.id === e)[0].source })
        this.$store.dispatch('updateSamples', { fileName: this.questionnaires.filter(r => r.id === e)[0].name })
        this.$store.dispatch('updateSamples', { sourceModule: '文件' })
        GetFileField({ id: e }).then(res => {
          this.data = res
        })
      }
      // 切换量表(问卷)后将数据也清空
      const pathname = window.location.pathname
      if (pathname.indexOf('hypothesistest') !== -1) {
        this.$router.push('/hypothesistest/screendata/file/nodata')
      } else if (pathname.indexOf('customchart') !== -1) {
        this.$router.push('/customchart/screendata/file/nodata')
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        this.$router.push('/intelligenceReports/screendata/file/nodata')
      } else {
        this.$router.push('/analysisOfRegression/screendata/file/nodata')
      }
    },
    submitChoose() {
      this.$store.dispatch('changeField')
      const pathname = window.location.pathname
      if (this.selectedRowKeys.length === 0) {
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/file/nodata')
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push('/customchart/screendata/file/nodata')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push('/intelligenceReports/screendata/file/nodata')
        } else {
          this.$router.push('/analysisOfRegression/screendata/file/nodata')
        }
      } else {
        var questionid = this.queryParam.id !== undefined ? this.queryParam.id : ''
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push(`/hypothesistest/screendata/file/data/${questionid}`)
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push({ path: `/customchart/screendata/file/data/${questionid}` })
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push({ path: `/intelligenceReports/screendata/file/data/${questionid}` })
        } else {
          this.$router.push({ path: `/analysisOfRegression/screendata/file/data/${questionid}` })
        }
        this.reload()
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  overflow: auto !important;
}
</style>