<template>
  <a-modal
    title="用药史"
    :visible="isMedicine"
    :confirm-loading="modal.confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-row type="flex" justify="center">
      <a-col :span="22">
        <div>
          <a-form-item label="选择药物" :labelCol="{span:5}" :wrapperCol="{span:19}">
            <a-select
              mode="multiple"
              :default-value="[]"
              style="width: 100%"
              placeholder="请选择要添加的药物"
              @change="onSelectChange"
            >
              <a-select-option
                v-for="medcine in select.medicineOptions"
                :key="medcine.id"
              >{{ medcine.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-row :gutter="120" style="margin-top:10px">
            <a-col v-for="medicine in select.medicineList" :key="medicine.id" :span="12">
              <a-form-item :label="medicine.name" :labelCol="{span:10}" :wrapperCol="{span:10}">
                <a-input suffix="g" v-decorator="[medicine.name]"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { createDocument } from '@/api/manage'

export default {
  props: { isMedicine: Boolean },
  data() {
    return {
      modal: {
        confirmLoading: false
      },
      select: {
        medicineOptions: [
          {
            id: 1,
            name: '法半夏'
          },
          {
            id: 2,
            name: '炒白术'
          },
          {
            id: 3,
            name: '砂仁'
          },
          {
            id: 4,
            name: '只壳'
          },
          {
            id: 5,
            name: '炒麦芽'
          },
          {
            id: 6,
            name: '法古伢'
          },
          {
            id: 7,
            name: '苍术'
          }
        ],
        medicineList: []
      }
    }
  },
  methods: {
    handleOk(e) {
      this.confirmLoading = true
      e.preventDefault()
      createDocument(this.select.medicineList).then(res => {
        this.$message.success(res.message, 3)
        this.$emit('handleCancel')
      })
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      this.$emit('handleCancel')
    },
    findMedicinesById(medicineIds) {
      const medicineOptions = this.select.medicineOptions
      for (var i in medicineIds) {
        for (var j in medicineOptions) {
          if (medicineIds[i] === medicineOptions[j].id) {
            this.select.medicineList.push(medicineOptions[j])
            break
          }
        }
      }
    },
    onSelectChange(value, option) {
      console.log(value)
      this.select.medicineList.length = 0
      this.findMedicinesById(value)
      console.log(this.select.medicineList)
      // this.select.medicineList = value
    }
  }
}
</script>
<style lang="less">
.ant-form-item {
  margin: 0;
}
</style>
