<template>
  <div class="leftWidthHypo fieldWrapper" id="afield">
    <a-form-model-item
      label="变量"
      prop="selectKeys"
      :rules="{
        required: true,
        message: '请选择',
      }"
    >
      <a-radio-group v-model="form.selectKeys">
        <div v-for="(item, i) in data" :key="i">
          <a-radio :value="item" :style="radioStyle" >
            <span>
              <img :src="`/chartsIcons/${isFieldType(item, 'icon')}.png`" alt="定类" style="margin-right: 10px" />
              {{ item.fieldName }}
            </span>
          </a-radio>
        </div>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>
<script>
// import { getFields, getQuestionFields, GetFileField, GetExperimentField } from '@/api/screendata'
const columns = [
  {
    title: '变量名',
    dataIndex: 'fieldName',
    key: 'key'
  }
]
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    this.columns = columns
    return {
      selectedRowKeys: [],
      data: this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields).filter(r => r.fieldType === '数字' || r.fieldType === '日期'),
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      }
    }
  },
  created() {
    // const pathname = window.location.pathname
    // if (pathname.indexOf('document') > -1) {
    //   getFields({ orgid: this.$store.state.user.info.orgid }).then(res => {
    //     this.data = res.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
    //   })
    // } else if (pathname.indexOf('questionnaire') > -1) {
    //   var id = this.$store.state.samples.questionnaireID
    //   if (!id) {
    //     this.gotoBack()
    //   } else {
    //     getQuestionFields({ ids: id }).then(res => {
    //       this.data = res.fields.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
    //     })
    //   }
    // } else if (pathname.indexOf('file') > -1) {
    //   id = this.$store.state.samples.fileid
    //   if (!id) {
    //     this.gotoBack()
    //   } else {
    //     GetFileField({ id: id }).then(res => {
    //       this.data = res.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
    //     })
    //   }
    // } else if (pathname.indexOf('experiment') > -1) {
    //   id = this.$store.state.samples.experimentID
    //   if (!id) {
    //     this.gotoBack()
    //   } else {
    //     GetExperimentField({ id: id }).then(res => {
    //       this.data = res.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
    //     })
    //   }
    // }
  },

  methods: {
    gotoBack() {
      const pathname = window.location.pathname
      if (pathname.indexOf('questionnaire') > -1) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/questionnaire')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/questionnaire')
        } else {
          this.$router.push('/customchart/screendata/questionnaire')
        }
      } else if (pathname.indexOf('document') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/document')
        } else {
          this.$router.push('/customchart/screendata/document')
        }
      } else if (pathname.indexOf('experiment') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/experiment')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/experiment')
        } else {
          this.$router.push('/customchart/screendata/experiment')
        }
      } else if (pathname.indexOf('file') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/file')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/file')
        } else {
          this.$router.push('/customchart/screendata/file')
        }
      }
    },
    onSelectChange(selectedRowKeys) {
      this.form.selectKeys = selectedRowKeys
    },
    // 判断定量还是定类的对象
    isFieldType(type, from) {
      switch (type.fieldType) {
        case '数字':
        case '日期':
          return from === 'icon' ? 'dingliang' : '定量'
        case '单选':
          if (type.forOrder === 1) {
            return from === 'icon' ? 'youxu' : '定序'
          } else {
            return from === 'icon' ? 'dinglei' : '定类'
          }
        case '多选':
        case '下拉框':
          return from === 'icon' ? 'dinglei' : '定类'
        default:
          return from === 'icon' ? 'kong' : '其他变量'
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  background-color: blanchedalmond;
}
.leftWidthChart {
  padding: 20px;
  background-color: white;
  border-right: 1px solid rgb(232, 232, 232);
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 999;
  width: 252px !important;
  overflow: auto;
}
.leftWidthHypo {
  padding: 20px;
  width: 100%;
  overflow: auto;
}
.ant-layout-sider {
  width: 100% !important;
  max-width: 100% !important;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.ant-layout-sider {
  background-color: white !important;
}
</style>