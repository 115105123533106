import request from '@/utils/request'

const api = {
  List: '/Questionnaire/List',
  get: '/Questionnaire',
  model: '/Questionnaire/GetModels',
  Preview: '/Questionnaire/Preview',
  Print: '/Questionnaire/Print',
  answer: '/Answer',
  getdocinfo: '/Answer/getDocInfo',
  GetQuestionnaireModel: '/Questionnaire/GetQuestionnaireModel',
  GetOrgQuestionnaire: '/Questionnaire/GetOrgQuestionnaire'
}
/**
 * 获取量表(问卷)列表
 * @param {*} parameter
 */
export function getQuestionnaireList(parameter) {
  return request({
    url: api.List,
    method: 'post',
    data: parameter
  })
}
/**
 * 根据id获取量表(问卷)
 * @param {*} parameter
 */
export function getQuestionnaire(parameter) {
  return request({
    url: api.get + '/' + parameter,
    method: 'get'
  })
}
/**
 * 创建量表(问卷)
 * @param {*} parameter
 */
export function createQuestionnaire(parameter) {
  return request({
    url: api.get,
    method: 'post',
    data: parameter
  })
}
/**
 * 更新量表(问卷)
 * @param {*} parameter
 */
export function updateQuestionnaire(parameter) {
  return request({
    url: api.get,
    method: 'put',
    data: parameter
  })
}
/**
 * 启用停用量表(问卷)
 * @param {*} parameter
 */
export function deleteQuestionnaire(parameter) {
  return request({
    url: api.get + '/' + parameter,
    method: 'delete'
  })
}
/**
 * 获取量表(问卷)模版列表
 * @param {*} parameter
 */
export function GetQuestionModel(parameter) {
  return request({
    url: api.model,
    method: 'get',
    params: parameter
  })
}
/**
 * 预览量表(问卷)
 * @param {*} parameter
 */
export function Preview(parameter) {
  return request({
    url: api.Preview,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 打印答卷可批量
 * @param {*} parameter
 */
export function Print(parameter) {
  return request({
    url: api.Print,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 提交答案
 * @param {*} parameter
 */
export function AddAnswer(parameter) {
  return request({
    url: api.answer + '/SaveExcelAnswer',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 编号是否已存在
 * @param {*} parameter
 */
export function ExistSerial(parameter) {
  return request({
    url: api.answer + '/ExistSerial',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 确认量表(问卷)并修改
 * @param {*} parameter
 */
export function SubmitAnswer(parameter) {
  return request({
    url: api.answer,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 查看答卷
 * @param {*} parameter
 */
export function GetAnswerList(parameter) {
  return request({
    url: api.answer + '/List',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 启用停用答卷
 * @param {*} parameter
 */
export function deleteAnswer(parameter) {
  return request({
    url: api.answer + '/' + parameter,
    method: 'delete'
  })
}
/**
 * 启用停用答卷
 * @param {*} parameter
 */
export function getdocinfo(parameter) {
  return request({
    url: api.getdocinfo,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 查看分数统计
 * @param {*} parameter
 */
export function GetScoreStatices(parameter) {
  return request({
    url: api.get + '/GetScoreStatices',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function GetPatients(parameter) {
  return request({
    url: `${api.answer}/timingSequence`,
    method: 'post',
    data: parameter
  })
}

export function GetPatientDetails(parameter) {
  return request({
    url: `${api.answer}/timingSequenceDetails`,
    method: 'post',
    data: parameter
  })
}

export function GetQuestionnaireModel(parameter) {
  return request({
    url: api.GetQuestionnaireModel,
    method: 'get',
    params: parameter
  })
}

export function GetOrgQuestionnaire(parameter) {
  return request({
    url: api.GetOrgQuestionnaire,
    method: 'get',
    params: parameter
  })
}