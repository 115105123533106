var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.prefixCls] },
    [
      _vm._t("subtitle", [
        _c("div", { class: [_vm.prefixCls + "-subtitle"] }, [
          _vm._v(
            _vm._s(
              typeof _vm.subTitle === "string" ? _vm.subTitle : _vm.subTitle()
            )
          )
        ])
      ]),
      _c("div", { staticClass: "number-info-value" }, [
        _c("span", [_vm._v(_vm._s(_vm.total))]),
        _c(
          "span",
          { staticClass: "sub-total" },
          [
            _vm._v(" " + _vm._s(_vm.subTotal) + " "),
            _c("icon", { attrs: { type: "caret-" + _vm.status } })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }