var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.gp.xys, function(obj, index) {
      return _c(
        "div",
        { key: index, attrs: { id: obj.title } },
        [
          _c("vvchart", {
            staticStyle: { padding: "0 25px" },
            attrs: {
              data: obj.data,
              xys: _vm.gp.xys[index],
              indexT: index + 1,
              count: _vm.gp.xys.length
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }