<template>
  <div>
    <div id="head-bar">
      <a-row id="main" type="flex" justify="center" align="middle" style="">
        <a-col>
          <a href="https://www.medspss.com/">
            <img src="@/assets/logo.png" width="50px" />
            <span id="plat-title">MedSPSS</span>
          </a>
        </a-col>
      </a-row>
    </div>
    <a-row style="margin-top: 25px; padding: 0 20px" type="flex" justify="center">
      <!-- 左侧主体 -->
      <a-col v-if="form.date" id="left-main" :span="24">
        <div id="article-title">{{ form.title }}</div>
        <a-row type="flex" align="middle" id="meta">
          <a-col>
            <span id="tag">原创</span>
            <span id="time"> {{ $moment(form.date).format('YYYY-MM-DD HH:mm:ss') }}</span>
            <!-- <span id="source">参考消息网</span> -->
          </a-col>
        </a-row>
        <!-- 正文 -->
        <div class="article-content">
          <div v-html="form.details.detail"></div>
        </div>
        <!-- <div class="light-blue-bg">
          <div>sdfsdafdsf</div>
          <br />
          <div>sfzxcvxczvzxc</div>
        </div>
        <div class="title1-blue">
          <span class="number">02</span>
          <span class="text">上海各区阳性感染率</span>
        </div>
        <div class="contact-us-1">
          <div>本份数据的统计和分析是基于MedSPSS数据分析平台，由算法自动生成，结果和结论仅做参考，不表达任何观点。</div>
          <br />
          <div>上传数据，您也可以获取自己的报告，敬请联系我们！</div>
        </div> -->
        <!-- <div class="contact-us-1">
          <div>本份数据的统计和分析是基于MedSPSS数据分析平台，由算法自动生成，结果和结论仅做参考，不表达任何观点。</div>
          <div>上传数据，您也可以获取自己的报告，敬请联系我们！</div>
          <div>联系邮箱：contacts@swktech.com.cn</div>
          <div>MedSPSS版权所有，欢迎转载。</div>
        </div>
        <div class="light-blue-bg">
          <div>“徐汇区”总感染人数虽然排第三，但其阳性感染率最高。</div>
          <br />
          <div>“闵行区”总的感染人数和阳性感染率都排第二。</div>
          <br />
          <div>“浦东新区”总感染人数最多，但由于人口基数大，其阳性感染率排第三。</div>
        </div> -->
      </a-col>
      <a-col v-else>
        <span> 数据加载中 </span>
        <a-spin />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ArtImg from '@/views/article/components/ArtImg'
import { Get } from '@/api/article'

export default {
  components: { ArtImg },
  created() {
    this.clientWidth = document.body.clientWidth
    console.log('%c 🥩 this.clientWidth: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', this.clientWidth);
    Get({ id: this.$route.params.id, showdetails: true }).then(res => {
      const data = res
      console.log('%c 🍼️ data: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', data);
      if (data) {
        this.form = data
      }
    })
  },
  data() {
    return {
      clientWidth: 0,
      form: {
        title: null,
        details: {
          detail: ''
        },
        description: null,
        logo_id: null,
        date: null
      }
    }
  },
  computed: {
    isMobile() {
      return this.clientWidth <= 578
    },
    imgWidth() {
      return this.clientWidth > 578 ? '70%' : '100%'
    }
  }
}
</script>
<style lang="less">
@import '~@/views/article/components/article.less';
</style>