import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c675b920&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c675b920')) {
      api.createRecord('c675b920', component.options)
    } else {
      api.reload('c675b920', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=c675b920&", function () {
      api.rerender('c675b920', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hypothesisTest/components/correlationNew/Pearson/index.vue"
export default component.exports