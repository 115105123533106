<template>
  <div>
    <a-row type="flex" id="wrapper">
      <a-col flex="20px" style="padding: 5px 0">
        <div id="mark"></div>
      </a-col>
      <a-col flex="auto" style="word-break: break-all">
        <slot>结论</slot>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: 'Conclusion'
}
</script>
<style lang="less" scoped>
#wrapper {
  flex-wrap: nowrap;
  color: #5f90e3;
  background-color: #f8fbff;
  border: 1px solid #c3d5f3;
  border-radius: 4px;
  padding: 10px 20px 10px 0;
  line-height: 25px;
  font-weight: 800;
}
#mark {
  border: 1px solid #5f90e3;
  background-color: #5f90e3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 16px;
  width: 10px;
}
</style>