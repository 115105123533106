var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ve-ring", {
    attrs: {
      data: _vm.data,
      colors: _vm.color,
      height: "300px",
      settings: _vm.chartSettings,
      graphic: _vm.graphic,
      "legend-visible": false,
      extend: _vm.extend,
      "tooltip-visible": true
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }