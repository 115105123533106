import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
import {
  deepClone
} from '@/utils/util'
var xysName = 'zXys'

export const settings = [{
    id: 0,
    onChange: function (e, item, that, refs) {
      var a = that.$hypothesis.findChildren(item.id, deepClone(that.indexSettings))
      item.children = a.children
      item.value = e.target.value
      // refs.ruleForm.resetFields()
      that.$emit('initialRules')
      if (item.value === '0') {
        that.$store.state.hypothesis.zXys = [
          []
        ]
      } else {
        that.$store.state.hypothesis.zXys = [
          [],
          []
        ]
      }
    },
    parentValue: 1,
    label: '样本数量',
    type: 'radioSelect',
    options: [{
      text: '单样本',
      value: '0'
    }, {
      text: '双样本',
      value: '1'
    }],
    value: '0',
    children: [{
        id: 1,
        label: '样本【定量】',
        prop: 'liang1',
        xysIndex: 0,
        onChange(obj, item, that) {
          onChange.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
        },
        onClose(obj, item, that, index) {
          onClose.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
        },
        parentValue: '0',
        type: 'drop',
        options: [],
        dropType: ['量'],
        num: 1,
        value: [],
        list: [],
        divHelp: ['定量：(个数＝1个)'],
        children: []
      },
      {
        id: 2,
        xysIndex: 0,
        prop: 'liang2',
        label: '样本1【定量】', // 字段名(必填项)
        parentValue: '1', // 当父对象的value为1时，则显示该input(必填项)
        type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
        dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
        num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
        value: '', // 用来决定children列表里哪些input是显示的
        options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
        list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
        divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
        children: [], // 必填项1
        onChange(obj, item, that) {
          onChange.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
        },
        onClose(obj, item, that, index) {
          onClose.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
        }
      },
      {
        id: 3,
        prop: 'leiOrLiang',
        xysIndex: 1,
        label: '组别或样本2【定类或定量】',
        onChange(obj, item, that) {
          if (item.type === '类') {
            onChange.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, item)
          } else if (item.type === '量') {
            onChange.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
          }
        },
        onClose(obj, item, that, index) {
          if (item.type === '类') {
            onClose.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, index)
          } else if (item.type === '量') {
            onClose.liang(that.$store.state.hypothesis[xysName], this.xysIndex, index)
          }
        },
        parentValue: '1',
        type: 'dropSelect',
        options: [{
            value: '类',
            label: '组别【定类】',
            type: 'drop',
            optLimit: 2,
            dropType: ['类']
          },
          {
            label: '样本2【定量】',
            value: '量',
            type: 'drop',
            optLimit: null,
            dropType: ['量']
          }
        ],
        dropType: '类量',
        num: 1,
        value: '',
        list: [],
        optLimit: null,
        divHelp: ['请拖入左侧变量：(个数＝1个，且定类变量中只能有两种类别)'],
        children: []
      },
      {
        label: '方差齐性',
        prop: 'fangCha',
        parentValue: '1',
        type: 'radio',
        value: '1',
        options: [{
          text: '是',
          value: '1'
        }, {
          text: '否',
          value: '0'
        }],
        children: []
      }
    ]
  },
  {
    label: '对比值',
    prop: 'reducedValue',
    parentValue: 1,
    placeholder: '请填写对比值',
    type: 'input',
    rules() {
      return [{
        required: true,
        message: this.label + '不能为空',
        trigger: 'blur'
      }]
    },
    value: '0',
    options: [],
    children: []
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  },
  {
    label: '判断条件',
    prop: 'judge',
    parentValue: 1,
    type: 'radio',
    value: '＝',
    options: [{
      text: '≥',
      value: '≥'
    }, {
      text: '=',
      value: '＝'
    }, {
      text: '≤',
      value: '≤'
    }],
    children: []
  }
]