var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-container" }, [
    _c(
      "div",
      { staticClass: "imgAndTable" },
      [
        _c("h1", [_vm._v("相关性检验结果")]),
        _c(
          "a-row",
          { attrs: { type: "flex", justify: "center" } },
          [
            _c(
              "a-col",
              [
                _c("MyImg", {
                  attrs: { imgBytes: _vm.img, fileName: "Phi相关系数" }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { type: "flex" } },
          [
            _c(
              "a-col",
              { attrs: { span: 24 } },
              [
                _c("a-table", {
                  attrs: {
                    columns: _vm.table.columns,
                    dataSource: _vm.table.data,
                    pagination: false
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }