<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col>
        <img :src="imgBytes" />
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col>
        <a :href="imgBytes" :download="fileName">下载原图</a>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: 'MyImg',
  props: ['imgBytes', 'fileName']
}
</script>