var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0 50px !important" } },
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "a-breadcrumb",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/experiment/Exp1/List/5" } },
                        [_vm._v("药效实验列表")]
                      )
                    ],
                    1
                  ),
                  _c("a-breadcrumb-item", [_vm._v(" 新建药效实验 ")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            labelAlign: "left",
            "label-col": { md: { span: 4 }, xxl: { span: 3 } },
            "wrapper-col": { md: { span: 20 }, xxl: { span: 21 } }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "实验编号" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入记录编号" },
                model: {
                  value: _vm.form.serialNum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "serialNum", $$v)
                  },
                  expression: "form.serialNum"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "实验名称",
                prop: "expName",
                rules: [{ required: true, message: "实验名称不能为空" }]
              }
            },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { placeholder: "请输入实验名称" },
                model: {
                  value: _vm.form.expName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expName", $$v)
                  },
                  expression: "form.expName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验类型" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [_c("a-radio", { attrs: { value: 0 } }, [_vm._v(" 普通 ")])],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验人员" } },
            [
              _c(
                "a-checkbox-group",
                {
                  attrs: { value: JSON.parse(_vm.form.expUsers) },
                  on: {
                    change: function(value) {
                      return (_vm.form.expUsers = JSON.stringify(value))
                    }
                  }
                },
                _vm._l(_vm.res.users, function(user) {
                  return _c(
                    "a-checkbox",
                    { key: user.id, attrs: { value: user.id } },
                    [_vm._v(" " + _vm._s(user.userName) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._l(_vm.form.groups, function(group, index) {
            return _c(
              "div",
              {
                key: index + "group",
                style: {
                  borderTop:
                    index === 0 ? "1px solid rgb(217,217,217)" : "none",
                  borderBottom: "1px solid rgb(217,217,217)"
                }
              },
              [
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: "分组" + (index + 1),
                      prop: "groups[" + index + "].value",
                      rules: [
                        {
                          required: true,
                          message: "分组" + (index + 1) + "不能为空"
                        }
                      ]
                    }
                  },
                  [
                    _c("a-input", {
                      style: { width: _vm.style.input_width },
                      attrs: { placeholder: "请输入分组" },
                      model: {
                        value: group.value,
                        callback: function($$v) {
                          _vm.$set(group, "value", $$v)
                        },
                        expression: "group.value"
                      }
                    }),
                    _c("PlusAndMinus", {
                      attrs: {
                        index: index,
                        array: _vm.form.groups,
                        obj: {
                          value: "",
                          animals: [
                            {
                              gender: undefined,
                              num: undefined
                            }
                          ]
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._l(group.animals, function(animal, i) {
                  return _c(
                    "a-form-model-item",
                    {
                      key: i,
                      staticClass: "myRequired",
                      attrs: {
                        label: "动物性别" + (i + 1),
                        prop: "groups[" + index + "].animals[" + i + "].gender",
                        rules: {
                          validator: function(rule, value, callback) {
                            _vm.validator(rule, value, callback, {
                              valueList: [value, animal.num],
                              checkMsg: "动物性别" + (index + 1) + "及其动物数"
                            })
                          }
                        }
                      }
                    },
                    [
                      _c(
                        "a-input-group",
                        {
                          style: { width: _vm.style.input_width },
                          attrs: { compact: "" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入动物性别" },
                            model: {
                              value: animal.gender,
                              callback: function($$v) {
                                _vm.$set(animal, "gender", $$v)
                              },
                              expression: "animal.gender"
                            }
                          }),
                          _c("a-input", {
                            staticStyle: { width: "50%" },
                            attrs: {
                              suffix: "只",
                              placeholder: "请输入所需动物数"
                            },
                            model: {
                              value: animal.num,
                              callback: function($$v) {
                                _vm.$set(animal, "num", $$v)
                              },
                              expression: "animal.num"
                            }
                          })
                        ],
                        1
                      ),
                      _c("PlusAndMinus", {
                        attrs: {
                          index: i,
                          array: group.animals,
                          obj: {
                            gender: undefined,
                            num: undefined
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          _c(
            "a-form-model-item",
            { attrs: { label: "实验天数", prop: "expDays" } },
            [
              _c("a-input", {
                style: { width: _vm.style.input_width },
                attrs: { suffix: "天", placeholder: "请输入实验天数" },
                model: {
                  value: _vm.form.expDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "expDays", $$v)
                  },
                  expression: "form.expDays"
                }
              })
            ],
            1
          ),
          _vm.form.expDays && !isNaN(Number(_vm.form.expDays))
            ? _c(
                "a-form-model-item",
                {
                  attrs: { label: "哪些天记录数据", prop: "recordedDataDays" }
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      style: { width: _vm.style.input_width },
                      attrs: {
                        mode: "multiple",
                        placeholder: "请选择在哪几天记录数据"
                      },
                      model: {
                        value: _vm.form.recordedDataDays,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "recordedDataDays", $$v)
                        },
                        expression: "form.recordedDataDays"
                      }
                    },
                    _vm._l(Number(_vm.form.expDays), function(i) {
                      return _c("a-select-option", { key: i }, [
                        _vm._v(" 第" + _vm._s(i) + "天 ")
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.form.periodValues, function(item, index) {
            return _c(
              "a-form-model-item",
              {
                key: "periodValues" + index,
                staticClass: "myRequired",
                attrs: {
                  rules: {
                    validator: function(rule, value, callback) {
                      _vm.validator(rule, value, callback, {
                        valueList: [value, item.unit],
                        checkMsg: "周期性测量值" + (index + 1) + "及其单位"
                      })
                    }
                  },
                  label: "周期性测量值名称" + (index + 1),
                  prop: "periodValues[" + index + "].name"
                }
              },
              [
                _c(
                  "a-input-group",
                  {
                    style: { width: _vm.style.input_width },
                    attrs: { compact: "" }
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "70%" },
                      attrs: { placeholder: "请输入周期性测量值名称" },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    }),
                    _c("a-input", {
                      staticStyle: { width: "30%" },
                      attrs: { placeholder: "单位" },
                      model: {
                        value: item.unit,
                        callback: function($$v) {
                          _vm.$set(item, "unit", $$v)
                        },
                        expression: "item.unit"
                      }
                    })
                  ],
                  1
                ),
                _c("PlusAndMinus", {
                  attrs: {
                    index: index,
                    array: _vm.form.periodValues,
                    obj: { name: undefined, unit: undefined }
                  }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.form.finalValues, function(item, index) {
            return _c(
              "a-form-model-item",
              {
                key: index,
                attrs: {
                  label: "最终测量值名称" + (index + 1),
                  prop: "finalValues[" + index + "].name",
                  rules: {
                    validator: function(rule, value, callback) {
                      _vm.validator2(rule, value, callback, {
                        valueList: Object.values(item),
                        checkMsg: "名称及其单位不能为空"
                      })
                    }
                  }
                }
              },
              [
                _c(
                  "a-input-group",
                  {
                    style: { width: _vm.style.input_width },
                    attrs: { compact: "" }
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "70%" },
                      attrs: { placeholder: "请输入最终测量值名称" },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    }),
                    _c("a-input", {
                      staticStyle: { width: "30%" },
                      attrs: { placeholder: "单位" },
                      model: {
                        value: item.unit,
                        callback: function($$v) {
                          _vm.$set(item, "unit", $$v)
                        },
                        expression: "item.unit"
                      }
                    })
                  ],
                  1
                ),
                _c("PlusAndMinus", {
                  attrs: {
                    index: index,
                    array: _vm.form.finalValues,
                    obj: { name: undefined, unit: undefined }
                  }
                })
              ],
              1
            )
          }),
          _c("a-form-model-item", { attrs: { label: "可选参数" } }, [
            _c(
              "div",
              _vm._l(_vm.params, function(param, index) {
                return _c(
                  "span",
                  { key: index, staticStyle: { "margin-right": "10px" } },
                  [
                    param.name
                      ? _c("a-tag", [
                          _vm._v(
                            " " +
                              _vm._s(param.name) +
                              "：" +
                              _vm._s(param.value) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _vm._l(_vm.form.yName, function(item, index) {
            return _c(
              "a-form-model-item",
              {
                key: "yName" + index,
                attrs: {
                  label: "计算属性名称" + (index + 1),
                  prop: "yName[" + index + "].name",
                  rules: {
                    validator: function(rule, value, callback) {
                      _vm.validator2(rule, value, callback, {
                        valueList: Object.values(item),
                        checkMsg: "名称、单位及其公式不能为空"
                      })
                    }
                  }
                }
              },
              [
                _c(
                  "a-input-group",
                  { style: { width: "80%" }, attrs: { compact: "" } },
                  [
                    _c("a-input", {
                      staticStyle: { width: "42.5%" },
                      attrs: { placeholder: "请输入计算属性" },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    }),
                    _c("a-input", {
                      staticStyle: { width: "15%" },
                      attrs: { placeholder: "单位" },
                      model: {
                        value: item.unit,
                        callback: function($$v) {
                          _vm.$set(item, "unit", $$v)
                        },
                        expression: "item.unit"
                      }
                    }),
                    _c("a-input", {
                      staticStyle: { width: "42.5%" },
                      attrs: { placeholder: "请输入公式" },
                      model: {
                        value: item.formula,
                        callback: function($$v) {
                          _vm.$set(item, "formula", $$v)
                        },
                        expression: "item.formula"
                      }
                    })
                  ],
                  1
                ),
                _c("PlusAndMinus", {
                  attrs: {
                    index: index,
                    array: _vm.form.yName,
                    obj: {
                      name: undefined,
                      unit: undefined,
                      formula: undefined
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.form.groupCompute, function(obj, index) {
            return _c(
              "div",
              {
                key: "组运算" + index,
                style: {
                  borderTop:
                    index === 0 ? "1px solid rgb(217,217,217)" : "none",
                  borderBottom: "1px solid rgb(217,217,217)"
                }
              },
              [
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: "组运算参数",
                      rules: {
                        validator: function(rule, value, callback) {
                          _vm.validator3(rule, value, callback, {
                            valueList: [obj.name, obj.unit, obj.formula],
                            checkValueList: [obj.name, obj.unit],
                            checkMsg: "名称及其单位不能为空"
                          })
                        }
                      },
                      prop: "groupCompute[" + index + "].name"
                    }
                  },
                  [
                    _c(
                      "a-input-group",
                      {
                        style: { width: _vm.style.input_width },
                        attrs: { compact: "" }
                      },
                      [
                        _c("a-input", {
                          staticStyle: { width: "70%" },
                          attrs: { placeholder: "请输入参数名" },
                          model: {
                            value: obj.name,
                            callback: function($$v) {
                              _vm.$set(obj, "name", $$v)
                            },
                            expression: "obj.name"
                          }
                        }),
                        _c("a-input", {
                          staticStyle: { width: "30%" },
                          attrs: { placeholder: "单位" },
                          model: {
                            value: obj.unit,
                            callback: function($$v) {
                              _vm.$set(obj, "unit", $$v)
                            },
                            expression: "obj.unit"
                          }
                        })
                      ],
                      1
                    ),
                    _c("PlusAndMinus", {
                      attrs: {
                        index: index,
                        array: _vm.form.groupCompute,
                        obj: {
                          type: "公式",
                          keys: [{ value: undefined }, { value: undefined }],
                          order: undefined,
                          formula: undefined,
                          map: undefined,
                          name: undefined,
                          unit: undefined,
                          isShow: true
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "类型" } },
                  [
                    _c(
                      "a-select",
                      {
                        style: { width: _vm.style.input_width },
                        model: {
                          value: obj.type,
                          callback: function($$v) {
                            _vm.$set(obj, "type", $$v)
                          },
                          expression: "obj.type"
                        }
                      },
                      [
                        _c(
                          "a-select-option",
                          { key: "公式", attrs: { value: "公式" } },
                          [_vm._v("公式")]
                        ),
                        _c(
                          "a-select-option",
                          { key: "平均值", attrs: { value: "平均值" } },
                          [_vm._v("平均值")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                obj.type === "公式"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "可选参数" } },
                          _vm._l(_vm.groupParams, function(param, paramIndex) {
                            return _c(
                              "span",
                              {
                                key: paramIndex,
                                staticStyle: { "margin-right": "10px" }
                              },
                              [
                                param.name
                                  ? _c("a-tag", [
                                      _vm._v(
                                        " " +
                                          _vm._s(param.name) +
                                          "：" +
                                          _vm._s(param.value) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "计算公式",
                              rules: {
                                validator: function(rule, value, callback) {
                                  _vm.validator3(rule, value, callback, {
                                    valueList: [
                                      obj.name,
                                      obj.unit,
                                      obj.formula
                                    ],
                                    checkValueList: [obj.formula],
                                    checkMsg: "计算公式不能为空"
                                  })
                                }
                              },
                              prop: "groupCompute[" + index + "].formula"
                            }
                          },
                          [
                            _c("a-input", {
                              style: { width: _vm.style.input_width },
                              attrs: { placeholder: "请输入计算公式" },
                              model: {
                                value: obj.formula,
                                callback: function($$v) {
                                  _vm.$set(obj, "formula", $$v)
                                },
                                expression: "obj.formula"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : obj.type === "平均值"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "关键词组" } },
                          [
                            _c(
                              "a-input-group",
                              {
                                style: { width: _vm.style.input_width },
                                attrs: { compact: "" }
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "50%" },
                                    attrs: { placeholder: "请选择组别名" },
                                    model: {
                                      value: obj.keys[0].value,
                                      callback: function($$v) {
                                        _vm.$set(obj.keys[0], "value", $$v)
                                      },
                                      expression: "obj.keys[0].value"
                                    }
                                  },
                                  _vm._l(_vm.keysSelectOptions.组别名, function(
                                    groupName,
                                    groupIndex
                                  ) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: groupIndex,
                                        attrs: { value: groupName }
                                      },
                                      [_vm._v(_vm._s(groupName))]
                                    )
                                  }),
                                  1
                                ),
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "50%" },
                                    attrs: { placeholder: "请选择运算参数名" },
                                    model: {
                                      value: obj.keys[1].value,
                                      callback: function($$v) {
                                        _vm.$set(obj.keys[1], "value", $$v)
                                      },
                                      expression: "obj.keys[1].value"
                                    }
                                  },
                                  _vm._l(
                                    _vm.keysSelectOptions.运算参数名,
                                    function(paramName, paramIndex) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: paramIndex,
                                          attrs: { value: paramName }
                                        },
                                        [_vm._v(_vm._s(paramName))]
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "显示在页面" } },
                  [
                    _c(
                      "a-radio-group",
                      {
                        model: {
                          value: obj.isShow,
                          callback: function($$v) {
                            _vm.$set(obj, "isShow", $$v)
                          },
                          expression: "obj.isShow"
                        }
                      },
                      [
                        _c("a-radio", { attrs: { value: true } }, [
                          _vm._v(" 是 ")
                        ]),
                        _c("a-radio", { attrs: { value: false } }, [
                          _vm._v(" 否 ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "a-form-model-item",
            { attrs: { label: "说明" } },
            [
              _c("a-textarea", {
                staticStyle: { width: "50%" },
                attrs: {
                  placeholder: "请输入说明",
                  "auto-size": { minRows: 3, maxRows: 5 }
                },
                model: {
                  value: _vm.form.explain,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "explain", $$v)
                  },
                  expression: "form.explain"
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "a-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
        [_vm._v(" 提交 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }