<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{ xys, form }">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span v-for="(item, index) in H0(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
        <span style="margin-left: 20px; cursor: pointer" @click="reverseOption()">
          <a-icon
            type="retweet"
            style="font-size: 20px; color: rgb(24, 144, 255)"
            :title="`替换&lt;${xys[0][0][0]}&gt;和&lt;${xys[0][0][1]}&gt;`"
          />
        </span>
      </div>
    </template>
    <template #H1="{ xys, form }">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span v-for="(item, index) in H1(xys, form)" :key="index">
          <span v-html="item"></span>
        </span>
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'dlybzjyXys',
      xys: [[], []],
      formAddKeyName: 'dlybzjyFormAddKey',
      settings,
      form: {
        a: '5',
        judge: '＝'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        form.reducedValue = parseFloat(that.form.reducedValue)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    },
    reverseOption() {
      var xys = this.$store.state.hypothesis[this.xysName]
      var lei = xys[0][0]
      lei = [lei[1], lei[0], lei[2]]
      xys[0].splice(0, 1, lei)
    }
  },
  computed: {
    H0() {
      return (xys, form) => {
        if (typeof (xys[1][0]) === 'string') {
          form.H0 = [`&lt;${xys[0][0][0]}&gt;的&lt;${xys[1][0]}&gt;中位数 ${form.judge} &lt;${xys[0][0][1]}&gt;的&lt;${xys[1][0]}&gt;中位数`]
        } else {
          form.H0 = [`&lt;${xys[0][0][0]}&gt;的&lt;${xys[1][0].slice(-1)[0]}&gt;中位数 ${form.judge} &lt;${xys[0][0][1]}&gt;的&lt;${xys[1][0].slice(-1)[0]}&gt;中位数`]
        }
        return form.H0
      }
    },
    H1() {
      return (xys, form) => {
        if (typeof (xys[1][0]) === 'string') {
          form.H1 = [`&lt;${xys[0][0][0]}&gt;的&lt;${xys[1][0]}&gt;中位数 ${this.$hypothesis.replaceJudge(form.judge)} &lt;${xys[0][0][1]}&gt;的&lt;${xys[1][0]}&gt;中位数`]
        } else {
          form.H1 = [`&lt;${xys[0][0][0]}&gt;的&lt;${xys[1][0].slice(-1)[0]}&gt;中位数 ${this.$hypothesis.replaceJudge(form.judge)} &lt;${xys[0][0][1]}&gt;的&lt;${xys[1][0].slice(-1)[0]}&gt;中位数`]
        }
        return form.H1
      }
    }
  }
}
</script>