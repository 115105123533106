var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function(content, index) {
      return _c(
        "a-row",
        { key: index, staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [_c("help-size1", { attrs: { content: content } })],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }