var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("MsxfxV", { ref: "content,1", attrs: { tables: _vm.msxfxV.tables } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }