export const Tables = {
    kaFang(columns, t, xLength, yLength, statisticLength) {
      statisticLength = statisticLength || 0
        columns[0].customRender = (value, record, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.rowSpan = yLength
          } else if (index > 0 && index < yLength) {
            obj.attrs.rowSpan = 0
          } else {
            obj.children = (<i>{value}</i>)
            obj.attrs.colSpan = 2
          }
          return obj
        }
        columns[1].customRender = (value, record, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index > yLength - 1) {
            obj.attrs.colSpan = 0
          }
          return obj
        }
        columns[2].children[0].customRender = (value, record, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index > yLength - 1 + statisticLength) obj.attrs.colSpan = xLength
          return obj
          }
        for (var i = 1; i < xLength; i++ ) {
          columns[2].children[i].customRender = (value, record, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            for (const param of t) {
              if (record.index === param) {
                obj.attrs.colSpan = 0
                }
            }
            return obj
          }
        }
      }
}