<template>
  <div>
    <a-row>
      <a-col :span="24">
        <h1>{{ title }}</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <slot></slot>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  props: ['title']
}
</script>
<style lang="less" scoped>
h1{
  font-size:14px;
  color:green
}
</style>