import request from '@/utils/request'

const api = {
    examModels: '/ExamModel'
}

export function getExamModels(parameter) {
    return request({
        url: api.examModels,
        method: 'get',
        data: parameter
    })
}
