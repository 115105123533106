import request from '@/utils/request'

export function getCPoint(parameter) {
    return request({
      url: '/python/getCPoint',
      method: 'post',
      data: parameter
    })
  }

  export function getT(parameter) {
    return request({
      url: '/python/getT',
      method: 'post',
      data: parameter
    })
  }