var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        inputNumberWrapper: true,
        inputNumberWrapperFocused: _vm.focusActive
      }
    },
    [
      _c(
        "a-input-number",
        _vm._b(
          {
            class: { inputnumber: true, antInputNumberHover: _vm.hoverActive },
            attrs: { value: _vm.value },
            on: {
              change: function($event) {
                return _vm.$emit("input", $event)
              },
              mouseover: function($event) {
                _vm.hoverActive = true
              },
              mouseout: function($event) {
                _vm.hoverActive = _vm.focusActive
              },
              focus: function($event) {
                _vm.focusActive = true
              },
              blur: function($event) {
                _vm.focusActive = false
                _vm.hoverActive = false
              }
            }
          },
          "a-input-number",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "div",
        {
          class: {
            after: true,
            afterhover: _vm.hoverActive || _vm.focusActive
          },
          on: {
            mouseover: function($event) {
              _vm.hoverActive = true
            },
            mouseout: function($event) {
              _vm.hoverActive = _vm.focusActive
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.unit) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }