<template>
  <div>
    <div :id="id" :data="data" ></div>
    <br>
    <a @click="downImage">下载</a>
  </div>
</template>

<script>
export default {
  name: 'BaseEchartsAllComponent',
  data() {
    return {
      ChartLineGraph: null
    }
  },
  //  深度监听 父组件刚开始没有值，只有图标的配置项
  //  父组件ajax请求后改变数据的值，传递过来，图标已生成，监听传过来的值的改变
  // deep:true.深度监听，确保data中子项修改也能监听到。写法参考：https://cn.vuejs.org/v2/api/#watch
  watch: {
    data: {
      handler(newvalue, oldvalue) {
        this.drawLineGraph(this.id, newvalue)
      },
      deep: true
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    },
    height: {
      type: Number,
      default: 500
    },
    width: {
      type: Number,
      default: 500
    },
    name: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.drawLineGraph(this.id, this.data)
  },
  methods: {
    downImage() {
      var src = this.ChartLineGraph.getDataURL()
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      var date = new Date()
      a.download = this.name + date.getFullYear() +
        (date.getMonth() + 1) +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds()
      a.href = src

      a.dispatchEvent(event)
    },
    drawLineGraph(id, data) {
      const _this = this
      const myChart = document.getElementById(id)
      this.ChartLineGraph = this.$echarts.init(myChart)
      var canvasdiv = myChart.children[0]
      if (this.width <= 800) {
      canvasdiv.style.margin = '0px auto'
      }
      this.ChartLineGraph.clear()
      this.ChartLineGraph.setOption(data)
      this.ChartLineGraph.resize({ height: this.height, width: this.width })
      window.addEventListener('resize', function () {
        _this.ChartLineGraph.resize()
      })
    }
  },
  beforeDestroy() {
    if (this.ChartLineGraph) {
      this.ChartLineGraph.clear()
    }
  }
}
</script>
<style scoped>
</style>
