import request from '@/utils/request'

const api = '/FieldInvalid'

export function List(parameter) {
  return request({
    url: `${api}/List`,
    method: 'post',
    data: parameter
  })
}

export function Get(parameter) {
  return request({
    url: `${api}/GetInvalidSampleDB`,
    method: 'post',
    data: parameter
  })
}

export function Add(parameter) {
  return request({
    url: api,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function Update(parameter) {
  return request({
    url: api,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function Delete(parameter) {
  return request({
    url: api + '/' + parameter,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}