var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          ref: "title," + _vm.thirdIndex.regression,
          staticClass: "third-title",
          attrs: {
            id:
              "3." + _vm.secondIndex + "." + _vm.thirdIndex.regression + " 回归"
          }
        },
        [
          _vm._v(
            " 3." +
              _vm._s(_vm.secondIndex) +
              "." +
              _vm._s(_vm.thirdIndex.regression) +
              " 回归 "
          )
        ]
      ),
      _c(
        "div",
        [
          _c("Logic", {
            ref: "content,1",
            attrs: { xys: _vm.xys, data: _vm.data },
            scopedSlots: _vm._u([
              {
                key: "text1",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.regression + ",1" },
                      [
                        _c("span", [
                          _vm._v(
                            " 使用" +
                              _vm._s(_vm.xys_str) +
                              "做逻辑回归分析，分析结果如下所示： "
                          )
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "text2",
                fn: function(ref) {
                  var formula = ref.formula
                  return [
                    _c("Conclusion", [
                      _c(
                        "p",
                        { ref: "text," + _vm.thirdIndex.regression + ",3" },
                        [
                          _c("span", [_vm._v("由上表可得：回归方程式为：")]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(formula))])
                        ]
                      )
                    ])
                  ]
                }
              },
              {
                key: "text3",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.regression + ",4" },
                      [_c("span", [_vm._v("逻辑回归的混淆矩阵如下表所示：")])]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "text4",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.regression + ",6" },
                      [
                        _vm.xys[0].length === 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.xys[0][0]) +
                                  "与" +
                                  _vm._s(_vm.xys[1][0]) +
                                  "的线性回归图如下图所示。"
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.xys_str +
                                      "的roc结果相关汇总表和图如下所示。"
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }