<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <a-table size="small" :columns="columns" :data-source="data" :pagination="false"> </a-table>
  </div>
</template>
<script>
export default {
  props: ['item', 'settings'],
  methods: {
    onChange(e, item, type) {
      item[type + 'Value'] = undefined
      this.$emit('updateForm', item.prop, item.radioValue || item.selectValue)
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '组别',
          dataIndex: 'colTitle',
          key: 'colTitle',
          align: 'center'
        },
        {
          title: 'X',
          dataIndex: 'X',
          key: 'X',
          align: 'center'
        },
        {
          title: 'Y',
          dataIndex: 'Y',
          key: 'Y',
          align: 'center'
        }
      ]
    },
    data() {
      const xList = this.settings[0].list
      const yList = this.settings[1].list
      const xLength = xList.length
      const yLength = yList.length
      const listLength = xLength > yLength ? xLength : yLength
      const list = []
      for (let index = 0; index < listLength; index++) {
        list.push({
          key: index,
          colTitle: index + 1,
          X: xList[index]?.name,
          Y: yList[index]?.name
        })
      }
      console.log('%c 🥟 list: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', list)

      return list
    }
  }
}
</script>