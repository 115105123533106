var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "third-module" },
      [
        _c(
          "a-row",
          _vm._l(_vm.imgs, function(img, index) {
            return _c("a-col", { key: index, attrs: { span: 12 } }, [
              _c("img", { attrs: { src: img } })
            ])
          }),
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("textBack")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }