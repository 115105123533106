import storage from 'store'
import {
  login,
  getInfo
} from '@/api/login'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import {
  welcome
} from '@/utils/util'
import store from '../index'
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    // isTrial: '',
    changePassword: false,
    payNoticeController: false,
    vipContentName: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    // SET_Trial: (state, Trial) => {
    //   state.isTrial = Trial
    // },
    SET_NAME: (state, {
      name,
      welcome
    }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_CHANGEPASSWORD: (state) => {
      state.changePassword = !state.changePassword
    },
    CHANGE_PAY_NOTICE_CONTROLLER: (state, vipContentName) => {
      state.vipContentName = vipContentName
      state.payNoticeController = !state.payNoticeController
    }
  },

  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(async response => {
          await store.dispatch('Logout')
          await store.dispatch('initSamples')
          const token = response.access_token
          storage.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response
          // result.Setting.colors = JSON.parse(result.Setting.colors)
          var role = ''
          switch (result.userRole) {
            case 0:
              role = 'admin'
              break
            case 1:
              role = 'groupleader'
              break
            case 2:
              role = 'user'
              break
            case 3:
              role = 'statistician'
              break
            case 4:
              role = 'person'
              break
          }
          commit('SET_ROLES', role)
          commit('SET_INFO', result)
          // commit('SET_Trial', response.trial)
          // if (result.role && result.role.permissions.length > 0) {
          //   const role = result.role
          //   role.permissions = result.role.permissions
          //   role.permissions.map(per => {
          //     if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
          //       const action = per.actionEntitySet.map(action => { return action.action })
          //       per.actionList = action
          //     }
          //   })
          //   role.permissionList = role.permissions.map(permission => { return permission.permissionId })
          //   commit('SET_ROLES', result.role)
          //   commit('SET_INFO', result)
          // } else {
          //   reject(new Error('getInfo: roles must be a non-null array !'))
          // }

          commit('SET_NAME', {
            name: result.name,
            welcome: welcome()
          })
          commit('SET_AVATAR', result.avatar)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({
      commit,
      state
    }) {
      return new Promise((resolve) => {
        resolve()
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        storage.remove(ACCESS_TOKEN)
      })
    },
    // 切换修改密码弹出框的状态
    ChangePassword(context) {
      context.commit('SET_CHANGEPASSWORD')
    },
    changePayNoticeController(context, vipContentName) {
      context.commit('CHANGE_PAY_NOTICE_CONTROLLER', vipContentName)
    }
  }
}

export default user