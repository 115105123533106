<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-for="(item, index) in resultH0(data)" :key="index">
        <span v-if="resultH0(data).length === 3">{{ index + 1 }}.</span> <span v-html="item"></span>
      </div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-for="(item, index) in resultH1(data)" :key="index">
        <span v-if="resultH1(data).length === 3">{{ index + 1 }}.</span> <span v-html="item"></span>
      </div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'mixedXys',
      xys: [[], []],
      formAddKeyName: 'mixedFormAddKey',
      settings,
      form: {
        a: '5',
        liang2: ''
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  },
  computed: {
    Xys() { return this.$store.state.hypothesis[this.xysName] },
    options() {
      if (this.Xys[0].length > 0) {
        var list = this.$deepClone(this.Xys[0][0])
        list.pop()
        return list.map(v => `&lt;${v}&gt;`).join('')
      }
      return ''
    },
    lei() { return this.Xys[0].length && this.Xys[0][0].slice(-1)[0] },
    H0() {
      return (liang) => [
        `${this.options}这${(this.Xys[0][0] || []).length - 1}组的&lt;${liang}&gt;均值相等`,
        `不同时间点的&lt;${liang}&gt;均值全相等`,
        `&lt;${this.lei}&gt;和时间因素无交互作用`
      ]
    },
    H1() {
      return (liang) => [
        `${this.options}这${(this.Xys[0][0] || []).length - 1}组的&lt;${liang}&gt;均值不全相等`,
        `不同时间点的&lt;${liang}&gt;均值不全相等`,
        `&lt;${this.lei}&gt;和时间因素有交互作用`
      ]
    },
    resultH0() {
      return (data) => {
        if (data.form.liang2 && this.Xys[1].length >= 2 && this.Xys[0].length > 0) {
          return this.H0(data.form.liang2)
        } else if (data.form.liang2 && this.Xys[1].length >= 2) {
          return [this.H0(data.form.liang2)[1]]
        }
      }
    },
    resultH1() {
      return (data) => {
        if (data.form.liang2 && this.Xys[1].length >= 2 && this.Xys[0].length > 0) {
          return this.H1(data.form.liang2)
        } else if (data.form.liang2 && this.Xys[1].length >= 2) {
          return [this.H1(data.form.liang2)[1]]
        }
      }
    }
  }
}
</script>