<template>
  <div style="overflow: auto" id="childContent">
    <keep-alive>
      <component v-if="$route.meta.keepAlive" :is="currentComponent"></component>
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :is="currentComponent"></component>
  </div>
</template>
<script>
import Method from './components/method'
import Dybtjy from './components/position/dybtjy'
import Dlybtjy from './components/position/dlybtjy'
import Pdybtjy from './components/position/pdybtjy'
import Index from './components/select'
import Z from './components/position/z'
import Kffx from './components/correlation/kffx'
import Dybksjy from './components/distribution/dybksjy'
import Sybksjy from './components/distribution/sybksjy'
import Fcqxfx from './components/distribution/fcqxfx'
import Ztjy from './components/distribution/ztjy'
import Danysfcfx from './components/anova/danysfcfx'
import Anderson from './components/distribution/anderson'
import Kfjy from './components/divergence/kfjy'
import F from './components/divergence/f'
import Sysfcfx from './components/anova/sysfcfx'
import Duoysfcfx from './components/anova/duoysfcfx'
import Cfclfcfx from './components/anova/cfclfcfx'
import Dcbjjy from './components/anova/dcbjjy'
import Pdybzjy from './components/non-parametric/pdybzjy'
import Dlybzjy from './components/non-parametric/dlybzjy'
import Dybzjy from './components/non-parametric/dybzjy'
import Friedman from './components/non-parametric/friedman'
import Cochran from './components/non-parametric/cochran'
import Welch from './components/anova/welch'
import Mixed from './components/anova/mixed'
import GamesHowell from './components/anova/gamesHowell'
import Dybbljy from './components/proportion/dybbljy'
import Sybbljy from './components/proportion/sybbljy'
import Mcnemar from './components/correlation/mcnemar'
import Fitting from './components/correlation/fitting'
import Pearson from './components/correlationNew/Pearson'
import Kendall from './components/correlationNew/Kendall'
import Phi from './components/correlationNew/Phi'
import Spearman from './components/correlationNew/Spearman'
export default {
  name: 'IndexChild',
  data() {
    return {
      currentComponent: this.$route.params.name
    }
  },
  components: {
    Method,
    Dybtjy,
    Dlybtjy,
    Pdybtjy,
    Index,
    Z,
    Kffx,
    Dybksjy,
    Sybksjy,
    Fcqxfx,
    Ztjy,
    Danysfcfx,
    Anderson,
    Kfjy,
    F,
    Sysfcfx,
    Duoysfcfx,
    Cfclfcfx,
    Dcbjjy,
    Pdybzjy,
    Dlybzjy,
    Dybzjy,
    Cochran,
    Friedman,
    Welch,
    Mixed,
    GamesHowell,
    Dybbljy,
    Sybbljy,
    Mcnemar,
    Fitting,
    Pearson,
    Kendall,
    Phi,
    Spearman
  },
  watch: {
    $route() {
      this.currentComponent = this.$route.params.name
    }
  }
}
</script>