<!--现在使用的答卷-->
<template >
  <div>
    <div v-for="(item, key) in data" :key="key">
      <div v-if="item.isShow" :hidden="item.isHidden">
        <!-- 题目 -->
        <a-row type="flex" justify="center" class="marginTop">
          <a-col class="subject" :span="22">
            <span style="color: red" v-if="item.type !== null && childrenRequire && item.isRequired">*</span>
            {{ Number(item.order) > 0 ? (item.parentId ? key + 1 + '）' : key + 1 + '. ') + item.text : item.text }}
            <span style="padding-left: 5px" v-if="item.location === 'remark'">
              {{ '(' + item.remarkName + ':' + (item.remark ? item.remark : '') + ')' }}</span
            ></a-col
          >
        </a-row>
        <!-- 描述 -->
        <a-row type="flex" justify="center" v-if="item.describe !== ''">
          <a-col class="content marginLeft" :span="22">
            <div style="white-space: pre-wrap">{{ item.describe }}</div>
          </a-col>
        </a-row>
        <!-- 题目的大分类 -->
        <a-row type="flex" justify="center" class="marginSujectTop marginLeft" v-if="item.type === null">
          <a-col :span="24">
            <a-form-item style="margin-bottom: 0px !important">
              <subject-comp-new
                :data="item.children"
                :childrenRequire="true"
                :formaaa="formdata"
                :disabled="disabled"
              ></subject-comp-new>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- 文本框 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 4">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-input
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                :disabled="disabled"
                :placeholder="item.tips"
                @change="
                  (e) => {
                    $emit('doclink', item, e)
                  }
                "
                class="widthStyle"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 多选 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 5">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important; width: 100%"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="[{
                type: 'array',
                required: childrenRequire && item.isRequired,
                message: '必填',
              },{ validator:(rule, value, callback)=>chkvalueChange(rule, value, callback,item), trigger: 'change' }]"
            >
              <a-checkbox-group
                :disabled="disabled"
                style="width: 80%"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                @change="
                  (value) => {
                    checkgroupChange(value, item)
                  }
                "
              >
                <div v-for="(option, i) in item.optionArry" :key="i">
                  <a-row>
                    <a-col>
                      <a-checkbox
                        :disabled="disabled || option.disabled"
                        :name="`${item.id}_${item.type}_${item.docField}`"
                        class="content"
                        :value="`${option.score}_${option.text}`"
                        @change="
                          (value) => {
                            childrenRequireChange(value, item.optionArry)
                          }
                        "
                      >{{ option.text }}</a-checkbox
                      ></a-col
                    >
                  </a-row>
                  <subject-comp-new
                    :data="option.children"
                    v-if="option.childrenRequire"
                    :formaaa="formdata"
                    :childrenRequire="option.childrenRequire"
                    :disabled="disabled"
                  ></subject-comp-new>
                </div>
              </a-checkbox-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 单选 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 2">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-radio-group
                style="width: 80%"
                @change="
                  (value) => {
                    radionChange(value, item)
                  }
                "
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              >
                <div v-for="(option, i) in item.optionArry" :key="i">
                  <a-row>
                    <a-col>
                      <a-radio :disabled="disabled" class="content" :value="`${option.score}_${option.text}`">{{
                        option.text
                      }}</a-radio></a-col
                    >
                  </a-row>

                  <subject-comp-new
                    :data="option.children"
                    v-if="option.childrenRequire"
                    :formaaa="formdata"
                    :childrenRequire="option.childrenRequire"
                    :disabled="disabled"
                  ></subject-comp-new>
                </div>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 下拉框 -->
        <a-row type="flex" justify="center" class="marginSujectTop marginLeft" v-if="item.type === 1">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-select
                show-search
                :filter-option="filterOption"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              >
                <a-select-option
                  :value="`${option.score}_${option.text}`"
                  v-for="(option, i) in item.optionArry"
                  :key="i"
                >
                  {{ option.text }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 数字 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 0">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-input-number
                @change="(e) => valueChange(e, item)"
                :disabled="disabled"
                :placeholder="item.tips"
                class="widthStyle"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              ></a-input-number>

              <span> {{ getUnit(item.unit) }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 时间 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 3">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-date-picker
                :disabled="disabled"
                :locale="locale"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                placeholder="选择时间"
                class="widthStyle"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 地址 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 6">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <a-cascader
                :disabled="disabled"
                :options="options"
                :field-names="{ label: 'name', value: 'code', children: 'children' }"
                expand-trigger="hover"
                placeholder="请选择"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 图片 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 7">
          <a-col :span="22">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}_image`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '请上传图片',
              }"
            >
              <a-upload
                list-type="picture-card"
                :file-list="formdata[`${item.id}_${item.type}_${item.docField}_image`]"
                @preview="(e) => handlePreview(e, item)"
                @change="(e) => handleChange(e, item)"
                :disabled="disabled"
                action="/api/Upload"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">选择图片</div>
                </div>
              </a-upload>
              <a-modal
                :visible="formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`]"
                :footer="null"
                @cancel="handleCancel(item)"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="formdata[`${item.id}_${item.type}_${item.docField}_previewImage`]"
                />
              </a-modal>
            </a-form-model-item>
            <a-form-model-item style="margin-bottom: 0px !important" :prop="`${item.id}_${item.type}_${item.docField}`">
              <a-select
                v-model="formdata[`${item.id}_${item.type}_${item.docField}`]"
                mode="tags"
                style="width: 100%"
                placeholder="选择相应的标签，没有的话直接填写自己标签"
              >
                <a-select-option v-for="tag in item.tags" :key="tag">
                  {{ tag }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 表格 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 8">
          <a-col :span="22">
            <a-form-model-item>
              <a-table
                size="middle"
                :columns="item.columns"
                :dataSource="formdata[`${item.id}_${item.type}_${item.docField}_table`]"
                :pagination="false"
                v-model="formdata[`${item.id}_${item.type}_${item.docField}_table`]"
                :scroll="{ x: true }"
              >
                <template v-for="(col, i) in item.tabcols" :slot="col.name + '_Title'">
                  <div :key="i">{{ col.name }}</div>
                  <div :key="i + 'shuoming'" style="font-size: 8px; color: grey" v-if="col.shuoming">
                    （{{ col.shuoming }}）
                  </div>
                </template>
                <template v-for="col in item.tabcols" :slot="col.name" slot-scope="text, record">
                  <div :key="col.name" v-if="col.type === 1">
                    <a-form-model-item>
                      <a-input
                        :disabled="record[col.name + '_disabled'] || disabled"
                        v-model="record[col.name]"
                      ></a-input>
                    </a-form-model-item>
                  </div>
                  <div :key="col.name" v-if="col.type === 2">
                    <a-form-model-item>
                      <a-input-number
                        :disabled="record[col.name + '_disabled'] || disabled"
                        v-model="record[col.name]"
                        :max="col.rangeMax !== null ? col.rangeMax : Infinity"
                        :min="col.rangeMin !== null ? col.rangeMin : -Infinity"
                      ></a-input-number
                      ><span style="margin-left: 10px">{{ getUnit(col.unit) }}</span>
                    </a-form-model-item>
                  </div>
                  <div :key="col.name" v-if="col.type === 3">
                    <a-form-model-item>
                      <a-select
                        style="width: 160px"
                        :disabled="record[col.name + '_disabled'] || disabled"
                        v-model="record[col.name]"
                      >
                        <a-select-option v-for="(op, opi) in col.options" :key="opi" :value="op.text">{{
                          op.text
                        }}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <div :key="col.name" v-if="col.type === 4">
                    <a-form-model-item>
                      <a-select
                        :disabled="record[col.name + '_disabled'] || disabled"
                        v-model="record[col.name]"
                        mode="multiple"
                        style="width: 160px"
                      >
                        <a-select-option v-for="(op, opi) in col.options" :key="opi" :value="op.text">{{
                          op.text
                        }}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <div :key="col.name" v-if="col.type === 5">
                    <a-form-model-item>
                      <a-checkbox
                        :disabled="record[col.name + '_disabled'] || disabled"
                        v-model="record[col.name]"
                        @change="
                          (value) => {
                            checkedChange(value, record, col.linkcol, item)
                          }
                        "
                      ></a-checkbox>
                    </a-form-model-item>
                  </div>
                </template>
              </a-table>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 时间范围 -->
        <a-row type="flex" justify="center" class="content marginSujectTop marginLeft" v-if="item.type === 9">
          <a-col :span="20">
            <a-form-model-item
              style="margin-bottom: 0px !important"
              :prop="`${item.id}_${item.type}_${item.docField}`"
              :rules="{
                required: childrenRequire && item.isRequired,
                message: '必填',
              }"
            >
              <div class="content">开始时间</div>
              <a-input-group compact>
                <a-select
                  class="content"
                  placeholder="时"
                  style="width: 20%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].start[0]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumHour">{{ h.value }}</a-select-option>
                </a-select>
                <span style="margin: auto 10px">:</span>
                <a-select
                  class="content"
                  placeholder="分"
                  style="width: 20%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].start[1]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumMinture">{{
                    h.value === 0 ? '00' : h.value
                  }}</a-select-option>
                </a-select>
              </a-input-group>
              <div class="content">结束时间</div>
              <a-input-group compact>
                <a-select
                  class="content"
                  placeholder="时"
                  style="width: 20%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].end[0]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumHour">{{ h.value }}</a-select-option>
                </a-select>

                <span style="margin: auto 10px">:</span>
                <a-select
                  class="content"
                  placeholder="分"
                  style="width: 20%"
                  v-model="formdata[`${item.id}_${item.type}_${item.docField}`].end[1]"
                >
                  <a-select-option :key="i" :value="h.value" v-for="(h, i) in enumMinture">{{
                    h.value === 0 ? '00' : h.value
                  }}</a-select-option>
                </a-select>
              </a-input-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider v-if="item.children.length === 0" />
      </div>
    </div>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import { test } from '@/common/test'
import { enumUnit, enumHour, enumMinture } from '@/common/enums'
import { getColFromNum } from '../utils'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  created() {
    this.$moment.locale('zh-cn')
  },
  name: 'SubjectCompNew',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    formaaa: {
      type: Object,
      default: () => {}
    },
    childrenRequire: {
      type: Boolean,
      default: () => false
    },
    isAnswer: {
      type: Boolean,
      default: () => false
    },
    linkQuestionIDs: {
      type: Array,
      default: () => []
    },

    disabled: { type: Boolean, default: () => false }
  },
  watch: {
    formaaa(v) {
      this.formdata = v
      // console.log('a', v)
    }
  },
  data() {
    return {
      locale,
      isShow: true,
      options: test,
      formdata: this.formaaa,
      fileList: [],
      previewVisible: false,
      previewImage: '',
      enumHour,
      enumMinture
    }
  },
  methods: {
    chkvalueChange(a, value, callback, item) {
      if (item.chkNumberMin && value.length < item.chkNumberMin) {
        callback(new Error(`至少选择${item.chkNumberMin}项`))
      } else {
        callback()
      }
    },
    // 预览关闭
    handleCancel(item) {
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`] = false
    },
    // 预览
    async handlePreview(file, item) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewImage`] = file.url || file.preview
      this.formdata[`${item.id}_${item.type}_${item.docField}_previewVisible`] = true
    },
    //
    async handleChange({ fileList }, item) {
      this.formdata[`${item.id}_${item.type}_${item.docField}_image`] = fileList
      if (fileList.length > 0) {
        fileList.map(async r => {
          if (!r.url && !r.preview) {
            r.preview = await getBase64(r.originFileObj)
          }
        })
      }
    },
    // 带检索的下拉框
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 多选个数限制
    checkgroupChange(value, item) {
      if (item.chkNumber && value.length === item.chkNumber) {
        item.optionArry.map(r => {
          if (value.indexOf(`${r.score}_${r.text}`) === -1) {
            r.disabled = true
          }
        })
        console.log('chkSelect', value)
        console.log('options', item)
      } else {
        item.optionArry.map(r => {
          r.disabled = false
        })
      }
    },
    childrenRequireChange(e, options) {
      var children = options.filter(r => r.children.length > 0)
      if (children.length > 0) {
        if (e.target.type === 'checkbox') {
          // 多选
          const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
          options.map(option => {
            if (value === option.text && option.children.length > 0) {
              if (e.target.checked) {
                option.childrenRequire = true
              } else {
                option.childrenRequire = false
              }
            }
          })
        } else {
          // 单选或下拉
          const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
          options.map(option => {
            if (value === option.text && option.children.length > 0) {
              option.childrenRequire = true
            } else {
              option.childrenRequire = false
            }
          })
        }
      }
    },
    radionChange(e, record) {
      var a = this.formaaa
      console.log(a)
      const value = e.target.value.slice(e.target.value.indexOf('_') + 1)
      var skipvalues = record.skipValue && JSON.parse(record.skipValue)
      if (skipvalues) {
        // 跳转值中最大的序号
        var max = 0
        skipvalues.map(r => {
          if (Number(r.order) > max) {
            max = Number(r.order)
          }
        })
        var skipvalue = skipvalues.filter(r => r.value === value)
        var endorder = null
        if (skipvalue.length > 0) {
          var skiporder = skipvalue[0].order
          this.data.map(r => {
            if (Number(r.order) > Number(record.order) && Number(r.order) <= max) {
              if (Number(r.order) > Number(record.order) && Number(r.order) < Number(skiporder)) {
                r.isShow = false
                this.formdata[`${r.id}_${r.type}_${r.docField}`] = undefined
              } else {
                r.isShow = true
                if (r.isEnd && !endorder) {
                  endorder = r.order
                }
              }
            }
          })
        } else {
          this.data.map(r => {
            if (Number(r.order) > Number(record.order) && Number(r.order) <= max) {
              r.isShow = true
              if (r.isEnd && !endorder) {
                endorder = r.order
              }
            }
          })
        }
        if (endorder) {
          this.data.map(r => {
            if (r.order > endorder) {
              r.isShow = false
            }
          })
        }
      }
      this.childrenRequireChange(e, record.optionArry)
      // 有公式时关联时
      if (record.isFormula) {
        // 单选取分值
        var score = e.target.value.slice(0, e.target.value.indexOf('_'))
        // 如果参与公式计算的话
        if (score !== '') {
          // 首先确保自己是有值的
          var qValue = [] // 值的数组{id,value}的集合
          qValue.push({ id: record.id.toString(), value: Number(score) }) // 先将当前值添加进去
          // 如果参与多个公式，需要一个个参与计算
          record.formulas.map(r => {
            // 如果有其他题目参与需要先判断其他题目是否已经有值了
            var otherCount = 0 // 用来判断其他的问题是不是都有值
            r.questions.map(q => {
              var key = q.id + '_' + q.type + '_' + q.docField
              if (q.type === 0) {
                // 数值型
                if (this.formdata[key] !== null) {
                  qValue.push({ id: q.id.toString(), value: Number(this.formdata[key]) })
                  otherCount++
                }
              } else if (q.type === 2) {
                // 单选
                if (this.formdata[key] !== null) {
                  var value = this.formdata[key].slice(0, this.formdata[key].indexOf('_'))
                  qValue.push({ id: q.id.toString(), value: Number(value) })
                  otherCount++
                }
              }
            })
            // 当参与计算的项都有值之后
            if (otherCount === r.questions.length) {
              // 按顺序将值付给ABCD
              var ids = r.formulaIDs.split(',')
              var gs = r.formula // 公式
              for (let i = ids.length - 1; i >= 0; i--) {
                var qKey = getColFromNum(i) // i从0开始，将变量key转化为ABC
                // 找到匹配的题目和答案,按理说应该有且只有一个
                var value = qValue.filter(a => a.id === ids[i])
                if (value.length > 0) {
                  gs = gs.replace(new RegExp(qKey, 'g'), value[0].value)
                }
              }
              // eslint-disable-next-line no-eval
              var result = Math.round(eval(gs) * 100) / 100
              var formulaKey = r.formulaQuestion.id + '_' + r.formulaQuestion.type + '_' + r.formulaQuestion.docField
              if (r.formulaQuestion.location === 'remark') {
                var formulaQ = this.data.filter(a => a.id === r.formulaQuestion.id) // 将结果的题目找出来
                // 给他的remark赋值
                formulaQ[0].remark = result
              } else {
                this.formdata[formulaKey] = result
              }
              console.log('结果', result)
            }
          })
        } else {
          // 否则已经生成的结果也要删掉
        }
      }
      if (this.linkQuestionIDs.indexOf(record.id) > -1) {
        this.$emit('questionLink', record, e)
      }
    },
    getUnit(value) {
      if (value !== '') {
        var unit = enumUnit.filter(r => r.value === value)
        if (unit.length > 0) {
          return unit[0].text
        }
      }
      return ''
    },
    /**
     * 数值型ongchanges事件
     */
    valueChange(e, record) {
      if (record.isFormula) {
        // 如果参与公式计算的话
        if (e !== '') {
          // 首先确保自己是有值的
          var qValue = [] // 值的数组{id,value}的集合
          qValue.push({ id: record.id.toString(), value: Number(e) }) // 先将当前值添加进去
          // 如果参与多个公式，需要一个个参与计算
          record.formulas.map(r => {
            // 如果有其他题目参与需要先判断其他题目是否已经有值了
            var otherCount = 0 // 用来判断其他的问题是不是都有值
            r.questions.map(q => {
              var key = q.id + '_' + q.type + '_' + q.docField
              if (this.formdata[key] !== null) {
                qValue.push({ id: q.id.toString(), value: Number(this.formdata[key]) })
                otherCount++
              }
            })
            // 当参与计算的项都有值之后
            if (otherCount === r.questions.length) {
              // 按顺序将值付给ABCD
              var ids = r.formulaIDs.split(',')
              var gs = r.formula // 公式
              for (let i = ids.length - 1; i >= 0; i--) {
                var qKey = getColFromNum(i) // i从0开始，将变量key转化为ABC
                // 找到匹配的题目和答案,按理说应该有且只有一个
                var value = qValue.filter(a => a.id === ids[i])
                if (value.length > 0) {
                  gs = gs.replace(new RegExp(qKey, 'g'), value[0].value)
                }
              }
              // eslint-disable-next-line no-eval
              var result = Math.round(eval(gs) * 100) / 100
              var formulaKey = r.formulaQuestion.id + '_' + r.formulaQuestion.type + '_' + r.formulaQuestion.docField
              if (r.formulaQuestion.location === 'remark') {
                var formulaQ = this.data.filter(a => a.id === r.formulaQuestion.id) // 将结果的题目找出来
                // 给他的remark赋值
                formulaQ[0].remark = result
              } else {
                this.formdata[formulaKey] = result
              }
              console.log('结果', result)
            }
          })
        } else {
          // 否则已经生成的结果也要删掉
        }
      }
    },
    checkedChange(e, record, linkcol, item) {
      if (linkcol && linkcol.length > 0) {
        linkcol.map(r => {
          // record.key = record.key + '1'
          if (e.target.checked) {
            record[r + '_disabled'] = false
          } else {
            record[r] = undefined
            record[r + '_disabled'] = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0px !important;
}
.title {
  font: 2em sans-serif;
  text-align: center;
}

.marginTop {
  margin-top: 40px;
  font: 20px;
}
.marginSujectTop {
  margin-top: 10px;
  font: 20px;
}
.marginLeft {
  padding-left: 20px;
  text-align: left;
}

.content {
  font-size: 14px;
}
.subject {
  font-size: 14px;
  font-weight: bold;
}
.divStyle {
  width: 40%;
  margin: 0 auto;
  height: 100%;
}
.widthStyle {
  width: 70%;
  height: 40px;
  line-height: 40px;
}
</style>
