<template>
  <div id="card-wrapper">
    <a-row id="top" type="flex" justify="center" align="middle">
      <a-col style="font-size: 15px">{{ title }}</a-col>
    </a-row>
    <a-row id="content" type="flex" justify="center" align="middle">
      <a-col>
        <a-row style="padding-bottom: 10px">
          <a-col align="center">
            ¥<span style="font-size: 22px">{{ price }}</span
            >/{{ unit }}
          </a-col>
        </a-row>
        <a-row>
          <a-col> <a-button type="primary" disabled style="padding: 5px 25px"> 立即抢购</a-button> </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  props: ['title', 'price', 'unit']
}
</script>
<style lang="less" scoped>
#card-wrapper {
  border: 1px solid #dfdfdf;
  width: 100%;
  height: 170px;
  #top {
    height: 30%;
    background-color: #f5ebc7;
  }
  #content {
    height: 70%;
    background-color: white;
  }
}
</style>