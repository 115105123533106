<template>
  <div>
    <div class="third-module">
      <a-table
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        size="small"
        class=""
      >
        <!-- <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div> -->
      </a-table>
    </div>
    <div class="third-module">
      <slot name="text"></slot>
    </div>
    <div class="third-module">
      <a-row v-for="(ary, index) in imgs" :key="index">
        <a-col v-for="(img, i) in ary" :key="i" :span="12">
          <img width="100%" :src="img" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ['tables', 'imgs'],
  created() {
    console.log('table', this.tables)
  }
}
</script>
<style lang="less">
</style>