var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expdetail" },
    [
      _c("a-divider"),
      _c(
        "a-descriptions",
        {
          staticStyle: { "margin-top": "20px", padding: "0 50px" },
          attrs: { size: "small" }
        },
        [
          _c("a-descriptions-item", { attrs: { label: "实验编号" } }, [
            _vm._v(_vm._s(_vm.res.serialNum))
          ]),
          _c("a-descriptions-item", { attrs: { label: "实验名称" } }, [
            _vm._v(_vm._s(_vm.res.expName))
          ]),
          _c("a-descriptions-item", { attrs: { label: "实验人员" } }, [
            _vm._v(
              _vm._s(
                _vm.res.users && _vm.res.users.length > 0
                  ? _vm.res.users
                      .map(function(r) {
                        return r.name
                      })
                      .join(",")
                  : ""
              )
            )
          ]),
          _c("a-descriptions-item", { attrs: { label: "实验天数" } }, [
            _vm._v(_vm._s(_vm.res.expDays))
          ]),
          _c("a-descriptions-item", { attrs: { label: "实验说明" } }, [
            _vm._v(_vm._s(_vm.res.explain))
          ])
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _vm._l(_vm.yName, function(item, i) {
            return _c(
              "a-col",
              { key: "y" + i, attrs: { span: 8 } },
              [
                _c(
                  "a-card",
                  {
                    attrs: {
                      title: item.name + "(" + item.unit + ")",
                      hoverable: "",
                      size: "small"
                    }
                  },
                  [
                    _c("p", [_vm._v("公式：" + _vm._s(item.formula))]),
                    _c(
                      "p",
                      _vm._l(item.params, function(map, mapIndex) {
                        return _c(
                          "span",
                          { key: mapIndex },
                          [
                            item.formula.indexOf(map.value) !== -1
                              ? _c("a-tag", [
                                  _vm._v(
                                    " " +
                                      _vm._s(map.value) +
                                      "：" +
                                      _vm._s(map.name) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            )
          }),
          _vm._l(_vm.res.groupCompute, function(item, i) {
            return _c(
              "a-col",
              { key: "groupY" + i, attrs: { span: 8 } },
              [
                item.isShow
                  ? _c(
                      "a-card",
                      {
                        attrs: {
                          title: item.name + "(" + item.unit + ")",
                          hoverable: "",
                          size: "small"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v("值：" + _vm._s(_vm._f("round")(item.value)))
                        ]),
                        item.type === "公式"
                          ? _c("div", [
                              _c("p", [
                                _vm._v("公式：" + _vm._s(item.formula))
                              ]),
                              _c(
                                "p",
                                _vm._l(JSON.parse(item.map), function(
                                  map,
                                  mapIndex
                                ) {
                                  return _c(
                                    "span",
                                    { key: mapIndex },
                                    [
                                      item.formula.indexOf(map.value) !== -1
                                        ? _c("a-tag", [
                                            _vm._v(
                                              " " +
                                                _vm._s(map.value) +
                                                "：" +
                                                _vm._s(map.name) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _c("a-divider"),
      !_vm.isEditable
        ? [
            _c(
              "a-button",
              {
                staticStyle: { "margin-right": "10px" },
                on: { click: _vm.edit }
              },
              [_vm._v("编辑")]
            )
          ]
        : [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("保存")]
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                on: { click: _vm.cancel }
              },
              [_vm._v("取消")]
            )
          ],
      _c("a-alert", {
        staticStyle: { display: "inline-block", "margin-left": "10px" },
        attrs: {
          message:
            "编辑状态下，支持从excel或者word中的整表复制(文档中表格格式需与页面中表格格式一致)",
          type: "info",
          "show-icon": ""
        }
      }),
      _c(
        "div",
        {
          ref: "pasteDom",
          staticClass: "table-cell-auto",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.activeKey,
                callback: function($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey"
              }
            },
            _vm._l(_vm.tables, function(table, index) {
              return _c(
                "a-tab-pane",
                {
                  key: index,
                  staticStyle: { overflow: "auto !important" },
                  attrs: { tab: table.data[0].组别 }
                },
                [
                  _c("a-table", {
                    staticStyle: { overflow: "auto !important" },
                    attrs: {
                      size: "small",
                      columns: table.columns,
                      pagination: false,
                      "data-source": table.data,
                      bordered: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "value",
                          fn: function(text, record, index2) {
                            return [
                              _vm.isEditable
                                ? _c("a-input-number", {
                                    attrs: { size: "small" },
                                    on: {
                                      focus: function($event) {
                                        _vm.oldValue = text[0]
                                      },
                                      blur: function($event) {
                                        return _vm.onBlur(text, index, index2)
                                      }
                                    },
                                    model: {
                                      value: text[0],
                                      callback: function($$v) {
                                        _vm.$set(text, 0, $$v)
                                      },
                                      expression: "text[0]"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(text[0]))])
                            ]
                          }
                        },
                        {
                          key: "serialNumber",
                          fn: function(text, record, index2) {
                            return [
                              _vm.isEditable
                                ? _c("a-input-number", {
                                    attrs: { size: "small" },
                                    on: {
                                      focus: function($event) {
                                        _vm.oldValue = text
                                      },
                                      blur: function($event) {
                                        return _vm.onBlur(text, index, index2)
                                      }
                                    },
                                    model: {
                                      value: record.serialNumber,
                                      callback: function($$v) {
                                        _vm.$set(record, "serialNumber", $$v)
                                      },
                                      expression: "record.serialNumber"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(text))])
                            ]
                          }
                        },
                        _vm._l(_vm.finalValues, function(item, i) {
                          return {
                            key: "1" + item.name + "(" + item.unit + ")",
                            fn: function(text, record, index2) {
                              return _c(
                                "span",
                                { key: i },
                                [
                                  _vm.isEditable
                                    ? _c("a-input-number", {
                                        attrs: { size: "small" },
                                        on: {
                                          focus: function($event) {
                                            _vm.oldValue = text
                                          },
                                          blur: function($event) {
                                            return _vm.onBlur(
                                              text,
                                              index,
                                              index2
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            record[
                                              item.name + "(" + item.unit + ")"
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              record,
                                              item.name + "(" + item.unit + ")",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "record[`${item.name}(${item.unit})`]"
                                        }
                                      })
                                    : _c("span", [_vm._v(_vm._s(text))])
                                ],
                                1
                              )
                            }
                          }
                        }),
                        _vm._l(_vm.yName, function(item, i) {
                          return {
                            key: "2" + item.name + "(" + item.unit + ")",
                            fn: function(text) {
                              return _c("span", { key: i }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm._f("round")(text)))
                                ])
                              ])
                            }
                          }
                        })
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }