<template>
  <div>
    <div v-for="(item, index) in settings" :key="index">
      <a-form-model-item
        :class="{
          myRequired:
            (item.type === 'drop' || item.type === 'dropSelect' || item.type === 'text') &&
            !item.hasOwnProperty('required'),
        }"
        :label="item.label"
        v-if="item.parentValue === parentValue"
        :prop="item.prop"
      >
        <a-radio-group
          v-if="item.type === 'radio' || item.type === 'radioSelect'"
          v-model="item.value"
          @change="(e) => onRadioChange(e, item)"
        >
          <a-radio :value="option.value" v-for="(option, optionIndex) in item.options" :key="optionIndex">{{
            option.text
          }}</a-radio>
        </a-radio-group>
        <a-select
          v-else-if="item.type === 'select' || item.type === 'selectSelect'"
          v-model="item.value"
          @change="(e) => onSelectChange(e, item)"
        >
          <a-select-option v-for="(option, optionIndex) in item.options" :key="optionIndex" :value="option.value">
            {{ option.text }}
          </a-select-option>
        </a-select>
        <a-input
          v-else-if="item.type === 'input'"
          :placeholder="typeof item.placeholder === 'string' ? item.placeholder : item.placeholder()"
          v-model="item.value"
          @change="(e) => onInputChange(e, item)"
        />
        <a-checkbox-group
          v-else-if="item.type === 'checkbox'"
          v-model="item.value"
          :options="item.options"
          @change="(e) => onCheckboxChange(e, item)"
        ></a-checkbox-group>
        <div
          type="text"
          v-else-if="item.type === 'drop' || item.type === 'dropSelect'"
          class="ant-input dropDiv"
          @drop="drop(item)"
          @dragover.prevent
        >
          <span v-for="help in item.divHelp" :key="help" class="helpFontColor">{{ help }}</span>
          <a-tag
            closable
            @close="(e) => onClose(e, item, i, value)"
            v-for="(value, i) in item.list"
            :key="i"
            :visible="value !== null"
          >{{ value.name }}</a-tag
          >
        </div>
        <div v-else-if="item.type === 'text'" style="font-size: 14px; color: red">
          {{ item.value }}
        </div>
        <div
          v-else-if="item.type === 'textarea'"
          style="display: flex; align-items: center"
          v-html="item.value($store, settings, $hypothesis)"
        >
          {{ item.value($store, settings, $hypothesis) }}
        </div>
        <component
          v-else-if="item.type === 'component'"
          :is="item.componentName"
          :item="item"
          :vuex="'hypothesis'"
          :settings="settings"
          :load="load"
          @updateForm="(key, value) => $emit('updateForm', key, value)"
        ></component>
      </a-form-model-item>
      <drop-items
        v-if="item.children.length > 0 && item.parentValue === parentValue"
        :settings="item.children"
        :formAddKeyName="formAddKeyName"
        :indexSettings="indexSettings"
        :parentValue="item.value"
        :refs="refs"
        :xys="xys"
        :load="load"
        @updateForm="(key, value) => $emit('updateForm', key, value)"
        @initialRules="$emit('initialRules')"
      ></drop-items>
    </div>
  </div>
</template>
<script>
import { DropClass } from '@/utils/util'
import ExpFrequency from '@/views/hypothesisTest/components/correlation/fitting/ExpFrequency'
export default {
  name: 'InputItems',
  props: ['indexSettings', 'settings', 'parentValue', 'formAddKeyName', 'refs', 'xys', 'load'],
  components: { ExpFrequency },
  data() {
    return {
      timer: {}
    }
  },
  methods: {
    drop(obj) {
      /**
       * @param obj 一个拖拽框的相关配置对象
       * @param item 一个变量的相关配置对象
       */
      this.load.anysisLoading = true
      const djson = event.dataTransfer.getData('item')
      const item = JSON.parse(djson)
      this.$hypothesis.changeDropRule(obj, item, this)
      const checkObj = {
        num: obj.num,
        type: obj.dropType,
        location: obj.label,
        optLimit: obj.optLimit,
        docLenLimit: obj.docLenLimit || false,
        vm: this,
        load: { anysisLoading: false }
      }
      const dropObj = new DropClass(obj.list, item, obj.divHelp, this.$message, `aaa`, this, true, obj)
      const result = dropObj.check(checkObj)
      if (result instanceof Promise) {
        result.then(res => {
          if (!res) {
            // 如果拖拽框里没有变量，那么重置拖拽框
            if (obj.list.length === 0) {
              this.initialDropDiv(obj)
            }
          }
        })
      } else {
        if (!result) {
          // 如果拖拽框里没有变量，那么重置拖拽框
          if (obj.list.length === 0) {
            this.initialDropDiv(obj)
          }
        }
      }

      // 如果是可以选择的拖拽框，那么根据拖拽的变量的类别来决定这个框的属性。
      // if (result) this.$hypothesis.changeDropRule(obj, item, this)
    },
    onClose(e, item, index, value) {
      item.list.splice(index, 1)
      if (item.hasOwnProperty('onClose')) item.onClose(item, value, this, index)
      // 如果拖拽框里一个变量都没有，那么初始化它以及它的子内容。
      if (item.list.length === 0) {
        this.initialDropDiv(item)
      }
    },
    // 初始化拖拽框
    initialDropDiv(item) {
      var a = this.$hypothesis.findChildren(item.id, this.$deepClone(this.indexSettings))
      // 如果是dropSelect，那么执行下面代码
      if (item.options.length > 0) {
        item.label = a.label
        item.value = a.value
        item.type = a.type
        item.children = a.children
      }
      item.divHelp = a.divHelp
      this.$emit('initialRules')
    },
    // 往form里提交数据
    formAddKey(obj) {
      this.$store.state.hypothesis[this.formAddKeyName] = []
      this.$store.state.hypothesis[this.formAddKeyName].push({
        key: obj.prop,
        value: obj.value
      })
    },
    onRadioChange(e, item) {
      if (item.type === 'radioSelect') { item.onChange(e, item, this, this.refs) } else {
        console.log('%c 🍷 item: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', item.value)
        this.formAddKey(item)
      }
    },
    onSelectChange(e, item) {
      if (item.type === 'selectSelect') { item.onChange(e, item, this) }
      this.formAddKey(item)
    },
    onInputChange(e, item) {
      const breakMark = [false]
      if ('onChange' in item) item.onChange(e, item, this, breakMark)
      if (!breakMark[0]) this.formAddKey(item)
    },
    onCheckboxChange(e, item) {
      if ('onChange' in item) item.onChange(item, this)
      this.formAddKey(item)
    }
  }
}
</script>
<style>
.myRequired > div > label:before {
  margin-right: 4px;
  content: '*';
  color: #f5222d;
  font-size: 14px;
}
</style>