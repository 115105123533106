<template>
  <div class="expdetail">
    <a-divider />
    <a-descriptions style="margin-top: 20px; padding: 0 50px" size="small">
      <a-descriptions-item label="实验编号">{{ res.serialNum }}</a-descriptions-item>
      <a-descriptions-item label="实验名称">{{ res.expName }}</a-descriptions-item>
      <a-descriptions-item label="实验人员">{{
        res.users && res.users.length > 0 ? res.users.map((r) => r.name).join(',') : ''
      }}</a-descriptions-item>
      <a-descriptions-item label="实验天数">{{ res.expDays }}</a-descriptions-item>
      <a-descriptions-item label="实验说明">{{ res.explain }}</a-descriptions-item>
    </a-descriptions>
    <a-divider />
    <a-row :gutter="16">
      <a-col v-for="(item, i) in yName" :key="`y${i}`" :span="8">
        <a-card :title="`${item.name}(${item.unit})`" hoverable size="small">
          <p>公式：{{ item.formula }}</p>
          <p>
            <span v-for="(map, mapIndex) in item.params" :key="mapIndex">
              <a-tag v-if="item.formula.indexOf(map.value) !== -1"> {{ map.value }}：{{ map.name }} </a-tag>
            </span>
          </p>
        </a-card>
      </a-col>
      <a-col v-for="(item, i) in res.groupCompute" :key="`groupY${i}`" :span="8">
        <a-card v-if="item.isShow" :title="`${item.name}(${item.unit})`" hoverable size="small">
          <p>值：{{ item.value | round }}</p>
          <div v-if="item.type === '公式'">
            <p>公式：{{ item.formula }}</p>
            <p>
              <span v-for="(map, mapIndex) in JSON.parse(item.map)" :key="mapIndex">
                <a-tag v-if="item.formula.indexOf(map.value) !== -1"> {{ map.value }}：{{ map.name }} </a-tag>
              </span>
            </p>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-divider></a-divider>
    <template v-if="!isEditable">
      <a-button style="margin-right: 10px" @click="edit">编辑</a-button>
      <!-- <a-button type="primary" @click="report">查看报告</a-button> -->
    </template>
    <template v-else>
      <a-button type="primary" @click="save">保存</a-button>
      <a-button @click="cancel" style="margin-left: 10px">取消</a-button>
    </template>
    <a-alert
      style="display: inline-block; margin-left: 10px"
      message="编辑状态下，支持从excel或者word中的整表复制(文档中表格格式需与页面中表格格式一致)"
      type="info"
      show-icon
    />
    <div style="margin-top: 10px" class="table-cell-auto" ref="pasteDom">
      <a-tabs v-model="activeKey">
        <a-tab-pane
          style="overflow: auto !important"
          v-for="(table, index) in tables"
          :key="index"
          :tab="table.data[0].组别"
        >
          <a-table
            style="overflow: auto !important"
            size="small"
            :columns="table.columns"
            :pagination="false"
            :data-source="table.data"
            bordered
          >
            <template slot="value" slot-scope="text, record, index2">
              <a-input-number
                size="small"
                v-if="isEditable"
                v-model="text[0]"
                @focus="oldValue = text[0]"
                @blur="onBlur(text, index, index2)"
              />
              <span v-else>{{ text[0] }}</span>
            </template>
            <template slot="serialNumber" slot-scope="text, record, index2">
              <a-input-number
                v-if="isEditable"
                size="small"
                v-model="record.serialNumber"
                @focus="oldValue = text"
                @blur="onBlur(text, index, index2)"
              />
              <span v-else>{{ text }}</span>
            </template>
            <!-- 最终测量值的插槽 -->
            <span
              v-for="(item, i) in finalValues"
              :key="i"
              :slot="`1${item.name}(${item.unit})`"
              slot-scope="text, record, index2"
            >
              <a-input-number
                v-if="isEditable"
                size="small"
                v-model="record[`${item.name}(${item.unit})`]"
                @focus="oldValue = text"
                @blur="onBlur(text, index, index2)"
              />
              <span v-else>{{ text }}</span>
            </span>
            <!-- 计算属性的插槽 -->
            <span v-for="(item, i) in yName" :key="i" :slot="`2${item.name}(${item.unit})`" slot-scope="text">
              <span>{{ text | round }}</span>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { getByID, putAnimalAndData } from '@/api/exp2/experiment'
import execMathExpress from 'exec-mathexpress'
import GroupComputeClass from './GroupComputeClass'
export default {
  name: 'ExpDetail',
  created() {
    const exe = execMathExpress('sin(a)', { a: 45 })
    console.log('%c 🥕 exe: ', 'font-size:20px;background-color: #F5CE50;color:#fff;', exe);
    getByID(Number(this.$route.params.id)).then(res => {
      console.log('%c 🍣 res: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', res)
      this.res = res
      // 给最终测量值赋值
      this.finalValues = JSON.parse(res.finalValues)
      const groupGenders = res.groupGenders
      const periodValues = JSON.parse(res.periodValues)
      const yName = JSON.parse(res.yName)
      this.yName = yName
      const tables = []
      // 遍历组合性别表
      groupGenders.forEach((v, gIndex) => {
        // 创建每个表的columns
        const columns = [
          {
            title: '组别',
            key: '组别',
            dataIndex: '组别',
            align: 'center'
          },
          {
            title: '性别',
            key: '性别',
            dataIndex: '性别',
            align: 'center'

          },
          {
            title: '动物编号',
            key: 'serialNumber',
            dataIndex: 'serialNumber',
            scopedSlots: { customRender: 'serialNumber' },
            align: 'center'

          }
        ]
        const data = []
        v.animals.forEach((a, aIndex) => {
          const obj = {
            key: a.key,
            组别: v.group,
            性别: a.gender,
            serialNumber: a.serialNumber
          }
          // 把animal表的最终测量值赋到data里
          const list = JSON.parse(a.finalValues)
          list.forEach((v, index) => {
            const o = this.finalValues[index]
            obj[`${o.name}(${o.unit})`] = v
          })
          // 把animal表的计算值赋到data里
          const list2 = JSON.parse(a.yValues)
          list2.forEach((v, index) => {
            const y = yName[index]
            obj[`${y.name}(${y.unit})`] = v
          })
          a.animalDatas.forEach(ad => {
            const recordedDataDay = ad.recordedDataDay
            // const title = `第${recordedDataDay}天${res.valueName}(${res.unit})`
            // 每张表遍历到的第一行的时候，需要生成记录天数等字段到column里去
            if (aIndex === 0) {
              if (gIndex === 0) {
                // 整个页面只要生成一次
                // 生成[1,5,7]数组，等会转换格式有用
                this.recordDataDays.push(recordedDataDay)
              }
              const obj3 = {
                title: `给药体积(ml)`,
                key: `给药体积${recordedDataDay}`,
                dataIndex: `给药体积${recordedDataDay}`,
                scopedSlots: { customRender: 'value' },
                align: 'center'
              }
              // 生成额外药物的名字单位到columns里去
              periodValues.forEach((eObj, i) => {
                const obj4 = {
                  // 如果是第一个周期性测量值的名字，那么前面要加上第一天
                  title: i === 0 ? `第${recordedDataDay}天${eObj.name}(${eObj.unit})` : `${eObj.name}(${eObj.unit})`,
                  key: `${eObj.name}${recordedDataDay}`,
                  dataIndex: `${eObj.name}${recordedDataDay}`,
                  scopedSlots: { customRender: 'value' },
                  align: 'center'
                }
                columns.push(obj4)
              })
              columns.push(obj3)
            }
            // 把几天记录的数据都复制到data里
            obj[`给药体积${recordedDataDay}`] = [ad.volume, ad.id]
            // 赋值周期性测量值的值
            periodValues.forEach((eObj, index) => {
              const periodValues = JSON.parse(ad.periodValues)
              obj[`${eObj.name}${recordedDataDay}`] = [periodValues[index], ad.id]
            })
          })
          data.push(obj)
        })
        // 获取最终测量值的名字并且赋到columns里去
        const list = JSON.parse(res.finalValues)
        list.forEach((v, index) => {
          const title = `${v.name}(${v.unit})`
          const dict = {
            title,
            key: title,
            dataIndex: title,
            scopedSlots: { customRender: `1${title}` },
            align: 'center'

          }
          columns.push(dict)
        })
        // 获取每个动物的Y的名字并且赋到columns里去
        if (yName.length > 0 && yName[0].name && yName[0].unit && yName[0].formula) {
          yName.forEach((v, index) => {
            const title = `${v.name}(${v.unit})`
            const dict = {
              title,
              key: title,
              dataIndex: title,
              scopedSlots: { customRender: `2${title}` },
              align: 'center'

            }
            columns.push(dict)
          })
        }
        // 把每个表格的columns和data拼成一个对象添加到tables里去
        tables.push({ columns, data })
      })
      this.tables = tables
      // 增加对最终测量值的监听
      this.addFinalValuesWatch(this.tables, res)
      // 增加对组计算值的监听
      this.addGroupComputeValuesWatch(this.tables, res)
      console.log('%c 🥃 this.table: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', this.tables)
    })
  },
  mounted() {
    console.log(this.$refs)

    this.watchPaste()
  },
  data() {
    return {
      tables: [],
      cloneTables: [],
      isEditable: false,
      oldValue: '',
      finalValues: [],
      recordValueChange: [],
      recordDataDays: [],
      yName: [],
      res: {},
      activeKey: 0
    }
  },
  methods: {
    save() {
      console.log('%c 🥑 this.tables: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', this.tables)
      const req = this.fetchReq()
      console.log('%c 🍪 req: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', req)
      putAnimalAndData(req).then(res => {
        console.log('更新成功')
        this.isEditable = false
      })
    },
    fetchReq() {
      const { recordValueChange } = this
      const animalDBList = []
      const animalDataDBList = []
      // recordValueChange是用来记录数据发生边行的那一行的下标的
      recordValueChange.forEach(v => {
        const tableIndex = v[0]
        const dataIndex = v[1]
        // 获取到那一行的data对象
        const obj = this.tables[tableIndex].data[dataIndex]
        // 构造最终测量值的数组
        const finalValuesList = []
        this.finalValues.forEach((f, index) => {
          finalValuesList.push(obj[`${f.name}(${f.unit})`])
        })
        // 构造计算属性的数组
        const yValuesList = []
        this.yName.forEach(y => {
          yValuesList.push(obj[`${y.name}(${y.unit})`])
        })
        // 构造animalDB的其中一个对象
        const obj2 = {
          id: obj.key,
          serialNumber: obj.serialNumber && Number(obj.serialNumber),
          gender: obj.性别,
          group: obj.组别,
          finalValues: JSON.stringify(finalValuesList),
          yValues: JSON.stringify(yValuesList)
        }
        animalDBList.push(obj2)
        // 构造animalData的数据结构
        this.recordDataDays.forEach(r => {
          const volume = obj[`给药体积${r}`]
          const periodValues = []
          JSON.parse(this.res.periodValues).forEach(eObj => {
            periodValues.push(obj[`${eObj.name}${r}`][0])
          })
          const obj3 = {
            id: volume[1],
            recordedDataDay: r,
            // value: value[0] && Number(value[0]),
            volume: volume[0] && Number(volume[0]),
            periodValues: JSON.stringify(periodValues)
          }
          animalDataDBList.push(obj3)
        })
      })
      // 构造Exp2ParamDB的数据
      const exp2ParamDBList = this.res.groupCompute
      return {
        animalDBList, animalDataDBList, exp2ParamDBList
      }
    },
    onBlur(text, index, index2) {
      text = text instanceof Array ? text[0] : text
      if (text !== this.oldValue) {
        console.log('值发生了变化')
        // index表示第几个table，index2表示table中data的第几条。
        const list = [index, index2]
        // 如果recordValueChange中不存在list数组，那么加进去
        if (this.recordValueChange.every(v => v[0] !== list[0] || v[1] !== list[1])) {
          this.recordValueChange.push(list)
        }
      } else {
        console.log('值没变化')
      }
    },
    edit() {
      this.cloneTables = this.$deepClone(this.tables)
      this.groupCompute = this.$deepClone(this.res.groupCompute)
      // 增加对最终测量值的监听
      this.addFinalValuesWatch(this.tables, this.res)
      // 增加对组计算值的监听
      this.addGroupComputeValuesWatch(this.tables, this.res)
      this.isEditable = true
    },
    cancel() {
      this.tables = this.cloneTables
      this.res.groupCompute = this.groupCompute
      this.isEditable = false
    },
    fetchData() {
      const data = []
      this.tables.forEach(table => {
        table.data.forEach(obj => {
          data.push(obj)
        })
      })
      return data
    },
    report() {
      const data = this.fetchData()
      console.log('%c 🎂 data: ', 'font-size:20px;background-color: #B03734;color:#fff;', data);
      // const path = '/pesticideEffect/Print'
      this.$router.push({
        name: 'pesticideEffectPrint',
        params: {
          data
        }
      })
    },
    addFinalValuesWatch(tables, res) {
      const yName = JSON.parse(res.yName)
      tables.forEach(table => {
        table.data.forEach(dataObj => {
          yName.forEach((yNameObj, yIndex) => {
            this.$watch(() => {
              const obj = {}
              // 生成exec需要的map。
              yNameObj.params.forEach(param => {
                obj[param.value] = dataObj[param.name] + ''
              })
              // 把obj中跟公式无关的映射去掉
              Object.keys(obj).forEach(v => {
                if (yNameObj.formula.indexOf(v) === -1) delete obj[v]
              })
              if (Object.values(obj).every(v => v !== 'NaN' && v && v !== '0')) {
                const exe = execMathExpress(yNameObj.formula, obj)
                return exe.num / exe.den
              } else {
                return ''
              }
            }, (newVal) => {
              dataObj[`${yNameObj.name}(${yNameObj.unit})`] = newVal
            })
          })
        })
      })
    },
    addGroupComputeValuesWatch(tables, res) {
      const groupCompute = res.groupCompute
      const groupComputeObj = new GroupComputeClass(tables, groupCompute)
      groupCompute.forEach(obj => {
        this.$watch(() => {
          return groupComputeObj[obj.type](obj)
        }, (newVal) => {
          obj.value = newVal
        })
      })
    },
    // 监听粘贴事件
    watchPaste() {
      this.$refs.pasteDom.addEventListener('paste', (event) => {
        // 获取解析 粘贴的文本
        const text = (event.clipboardData || window.clipboardData).getData('text')
        if (text.indexOf('\r\n') !== -1 && this.isEditable) {
          console.log('执行了监听方法')
          event.preventDefault()
          const current_table_index = this.activeKey
          const table = this.tables[current_table_index]
          let current_row_index = 0
          let current_col_index = 2
          let word = ''
          for (var i in text) {
            const value = text[i]
            if (value !== '\t' && value !== '\r' && value !== '\n') {
              word += value
            } else if (value === '\t') {
              this.setTableValue(table, current_col_index, current_row_index, word)
              word = ''
              current_col_index++
            } else if (value === '\n') {
              this.setTableValue(table, current_col_index, current_row_index, word)
              word = ''
              current_col_index = 2
              current_row_index++
            }
          }
          // 把当前粘贴的表格所有data下标添加到recordValueChange里去。
          table.data.forEach((v, index) => {
            const list = [current_table_index, index]
            if (this.recordValueChange.every(v => v[0] !== list[0] || v[1] !== list[1])) {
              this.recordValueChange.push(list)
            }
          })
        }
      })
    },
    setTableValue(table, col_index, row_index, value) {
      const key = table.columns[col_index].dataIndex
      const old_value = table.data[row_index][key]
      // const id = old_value instanceof Array ? old_value[1] : null
      if (old_value instanceof Array) {
        table.data[row_index][key].splice(0, 1, +value)
      } else {
        table.data[row_index][key] = +value
      }
    }
  }
}
</script>
<style lang="less">
.table-cell-auto .ant-table-wrapper * {
  width: auto !important;
}
.table-cell-auto .ant-table-wrapper {
  display: flex !important;
  justify-content: start;
  width: auto !important;
  .ant-input-number {
    width: 50px !important;
    border: none;
  }
}
.expdetail .ant-card-body {
  height: 150px;
  overflow: auto;
}
</style>
