<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.normIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.normIndex} 正态性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.normIndex }} 正态性检验
      </div>
      <div>
        <Norm
          :ref="`content,${thirdIndex.normIndex}`"
          :data="data"
          :lei="data.lei"
          :xys="xys"
          :settings="settings.options.methods.find((v) => v.name === '正态检验')"
          @isNorm="(val) => (isNorm = val)"
        >
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.normIndex},1`">
              对不同{{ lei }}中的{{ liang }}做正态性检验，<br />
              <span class="H0H1">H0：不同{{ lei }}中{{ liang }}的样本服从正态分布。</span><br />
              <span class="H0H1">H1：不同{{ lei }}中{{ liang }}的样本不服从正态分布。</span><br />
              检验结果如下表所示：
            </p>
          </template>
          <template #text2="{ text }" class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.normIndex},3`" v-for="(pText, index) in text" :key="index">
                <span v-html="pText">{{ pText }}</span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.normIndex},4`">{{ liang }}的PP图和QQ图如下图所示：</p>
          </template>
        </Norm>
      </div>
    </div>
    <div v-if="isNorm.length > 0 && (flow_anova_now === 'auto' ? isNorm[0] >= a : flow_anova_now === 'anova')">
      <!-- 如果流程的方法中选择自动，那么必须正态，如果不是自动，那么必须选择了anova才会进行方差分析 -->
      <div
        :ref="`title,${thirdIndex.homogeneousIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.homogeneousIndex} 方差齐性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.homogeneousIndex }} 方差齐性检验
      </div>
      <div>
        <HomogeneityOfVariances
          :ref="`content,${thirdIndex.homogeneousIndex}`"
          :data="data"
          :lei="data.lei"
          :isNorm="isNorm"
          :settings="settings.options.methods.find((v) => v.name === '方差齐性检验')"
          @isHomogeneous="(val) => (isHomogeneous = val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.homogeneousIndex},1`" v-html="get_text('方差齐性检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.homogeneousIndex},3`">
                由上表可得：
                <span
                  v-html="
                    $hypothesis.conclusion(
                      isHomogeneous,
                      a,
                      `不同${lei}中${liang}的样本方差齐`,
                      `不同${lei}中${liang}的样本方差不齐`
                    )
                  "
                >
                </span>
              </p>
            </Conclusion>
          </template>
        </HomogeneityOfVariances>
      </div>
    </div>
    <div v-if="isHomogeneous.length > 0 && (flow_anova_now === 'auto' ? isNorm[0] >= a : flow_anova_now === 'anova')">
      <div
        :ref="`title,${thirdIndex.anovaIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.anovaIndex} 方差分析`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.anovaIndex }} 方差分析
      </div>
      <div>
        <Anova
          :ref="`content,${thirdIndex.anovaIndex}`"
          :data="data"
          :lei="data.lei"
          :settings="obj_of_settings.anova"
          :isHomogeneous="isHomogeneous"
          @isDiff="(val) => (isDiff = val)"
          @saveMethod="(val) => put_method_name_into_obj('anova', val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.anovaIndex},1`" v-html="get_text('参数检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.anovaIndex},3`">
                由上表可得：
                <span
                  v-html="
                    $hypothesis.conclusion(
                      isDiff,
                      a,
                      `不同${lei}中${liang}的样本间差异不显著`,
                      `不同${lei}中${liang}的样本间差异显著`
                    )
                  "
                ></span>
              </p>
            </Conclusion>
          </template>
        </Anova>
      </div>
    </div>
    <div v-if="isDiff.length > 0 && (isDiff[0] || isDiff[0] === 0) && isDiff[0] < a">
      <div
        :ref="`title,${thirdIndex.postHocIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.postHocIndex} 事后多重比较`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.postHocIndex }} 事后多重比较
      </div>
      <div>
        <PostHoc
          :ref="`content,${thirdIndex.postHocIndex}`"
          :data="data"
          :lei="data.lei"
          :isLenEqual="isLenEqual"
          :leiOptionsCombine="leiOptionsCombine"
          :methodOfAnova="obj_of_methods.anova"
          :method="obj_of_settings.postHoc.args[0].value"
          @findLessP="(x) => (findLessP = x)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.postHocIndex},1`" v-html="get_text('事后检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.postHocIndex},3`" v-if="findLessP.length > 0">由上表可得：</p>
              <p :ref="`text,${thirdIndex.postHocIndex},4`" v-for="(group, groupIndex) in findLessP" :key="groupIndex">
                <span v-if="group.length > 0">
                  {{ liang }}中
                  <p v-for="(groupObj, groupObjIndex) in group" :key="groupObjIndex">
                    {{ groupObj.A }}与{{ groupObj.B }}，
                  </p>
                  这些组合的<i>p</i>值 &lt;
                  {{ a }}，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。
                </span>
                <span v-else>
                  {{ liang }}中所有组合的<i>p</i>值 ≥ {{ a }}，因此不拒绝H0（差别不具有统计学意义）。
                </span>
              </p>
            </Conclusion>
          </template>
        </PostHoc>
      </div>
    </div>
    <div v-if="isNorm.length > 0 && (flow_anova_now === 'auto' ? isNorm[0] < a : flow_anova_now === 'none_param')">
      <div
        :ref="`title,${thirdIndex.noneParamIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.noneParamIndex} 非参数检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.noneParamIndex }} 非参数检验
      </div>
      <div>
        <NoneParam
          :ref="`content,${thirdIndex.noneParamIndex}`"
          :data="data"
          :lei="data.lei"
          @noneParamIsDiff="(x) => (noneParamIsDiff = x)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.noneParamIndex},1`" v-html="get_text('非参数检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.noneParamIndex},3`">
                由上表可得：
                <span
                  v-html="
                    $hypothesis.conclusion(
                      noneParamIsDiff,
                      a,
                      `不同${lei}中${liang}的样本间差异不显著`,
                      `不同${lei}中${liang}的样本间差异显著`
                    )
                  "
                ></span>
              </p>
            </Conclusion>
          </template>
        </NoneParam>
      </div>
    </div>
    <div
      v-if="noneParamIsDiff.length > 0 && (noneParamIsDiff[0] || noneParamIsDiff[0] === 0) && noneParamIsDiff[0] < a"
    >
      <div
        :ref="`title,${thirdIndex.noneParamPostHocIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.noneParamPostHocIndex} 非参数检验的事后多重比较`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.noneParamPostHocIndex }} 非参数检验的事后多重比较
      </div>
      <div>
        <NoneParamPostHoc
          :ref="`content,${thirdIndex.noneParamPostHocIndex}`"
          :data="data"
          :lei="data.lei"
          :noneParamIsDiff="noneParamIsDiff"
          :leiOptionsCombine="leiOptionsCombine"
          @findLessP="(x) => (NoneParamFindLessP = x)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.noneParamPostHocIndex},1`">
              由3.{{ secondIndex }}.{{ thirdIndex.noneParamIndex }}可知：
              <span v-if="noneParamPostHocObj.nemenyi"
              >不同{{ lei }}中{{
                noneParamPostHocObj.nemenyi
              }}的样本间差异显著，因此采用Nemenyi算法进行事后检验，<br />
                <span class="H0H1">H0：差别不具有统计学意义。</span><br />
                <span class="H0H1">H1：差别具有统计学意义。</span><br />
                检验结果如下表所示：</span
              >
            </p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p
                :ref="`text,${thirdIndex.noneParamPostHocIndex},3`"
                v-for="(group, groupIndex) in NoneParamFindLessP"
                :key="groupIndex"
              >
                <span>由上表可得：</span>
                <span v-if="group.length > 0">
                  {{ Object.keys(data.data1)[groupIndex] }}中
                  <p v-for="(groupObj, groupObjIndex) in group" :key="groupObjIndex">
                    {{ groupObj.A }}与{{ groupObj.B }}，
                  </p>
                  这些组合的<i>p</i>值 &lt;
                  {{ a }}，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。
                </span>
                <span v-else>
                  {{ Object.keys(data.data1)[groupIndex] }}中所有组合的<i>p</i>值 ≥
                  {{ a }}，因此不拒绝H0（差别不具有统计学意义）。
                </span>
              </p>
            </Conclusion>
          </template>
        </NoneParamPostHoc>
      </div>
    </div>
  </div>
</template>
<script>
import Norm from '@/views/report/modules/hypothesis/norm'
import HomogeneityOfVariances, { get_text_homogeneityOfVariances } from '@/views/report/modules/hypothesis/homogeneityOfVariances'
import Anova, { get_text_anova } from '@/views/report/modules/hypothesis/anova'
import PostHoc, { get_text_postHoc } from '@/views/report/modules/hypothesis/postHoc'
import NoneParam, { get_text_noneParam } from '@/views/report/modules/hypothesis/noneParam'
import NoneParamPostHoc from '@/views/report/modules/hypothesis/noneParamPostHoc'
// import {get_text_homogeneityOfVariances} from '@/views/report/modules/hypothesis/'
export default {
  components: { Norm, HomogeneityOfVariances, Anova, PostHoc, NoneParam, NoneParamPostHoc },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  created() {
    this.getIsLenEqual()
    // 生成定类中options的排列组合
    this.leiOptionsCombine = this.getLeiOptionsCombine()
    this.obj_of_settings = this.get_settings()
  },
  data() {
    return {
      isNorm: [], // 是否正态,
      isHomogeneous: [], // 方差是否齐性
      isDiff: [], // 差异是否显著
      isLenEqual: [], // 每组样本是否相同
      findLessP: [], // 事后检验文字描述的结果的数组
      noneParamIsDiff: [], // 非参数检验的差异是否显著
      NoneParamFindLessP: [], // 非参数事后检验文字描述的结果的数组
      obj_of_settings: {}, // 用来存放各模块配置的obj
      obj_of_methods: {} // 用来存放各模块使用的算法的obj
    }
  },
  methods: {
    getIsLenEqual() {
      const list = []
      const data1 = this.data.data1
      for (const liang in data1) {
        const obj = data1[liang]
        const totalLen = this.data.data2[liang].length
        const avgLen = totalLen / Object.keys(obj).length
        list.push(Object.values(obj).every(v => v.length === avgLen))
      }
      this.isLenEqual = list
    },
    getLeiOptionsCombine() {
      // 定类里面options的排列组合
      const leiOptions = this.$deepClone(this.leiOptions)
      this.$hypothesis.combine.array = leiOptions
      var list1 = this.$hypothesis.combine.run()
      // list1是全排列组合，但是我们只需要两两组合的排列组合
      const list2 = list1.filter(list => list.length === 2)
      return list2
    },
    get_text(name_of_module) {
      /**
       * @param 要获取文字信息的模块名
       */
      const { secondIndex, thirdIndex, obj_of_settings, obj_of_methods, isLenEqual, lei, liang, flow_anova_now, isNorm, isHomogeneous, a } = this
      const name_of_sample = `不同${lei}中${liang}的样本`
      let text
      switch (name_of_module) {
        case '方差齐性检验':
          text = get_text_homogeneityOfVariances(obj_of_settings.homogeneous.args[0].value, name_of_sample, flow_anova_now, isNorm[0], secondIndex, thirdIndex, a)
          break
        case '非参数检验':
          text = get_text_noneParam(name_of_sample, flow_anova_now, false, secondIndex, thirdIndex, a)
          break
        case '参数检验':
          text = get_text_anova(obj_of_settings.anova.args[0].value, name_of_sample, flow_anova_now, isHomogeneous[0], secondIndex, thirdIndex, a)
          break
        case '事后检验':
          text = get_text_postHoc(obj_of_settings.postHoc.args[0].value, name_of_sample, obj_of_methods.anova, isLenEqual, secondIndex, thirdIndex, a)
      }
      return text
    },
    get_settings() {
      const { settings } = this
      const norm = settings.options.methods.find((v) => v.name === '正态检验')
      const homogeneous = settings.options.methods.find((v) => v.name === '方差齐性检验')
      const anova = settings.options.methods.find((v) => v.name === '参数检验')
      const postHoc = settings.options.methods.find((v) => v.name === '事后检验')
      return { norm, homogeneous, anova, postHoc }
    },
    put_method_name_into_obj(key, value) {
      this.obj_of_methods[key] = value
    }
  },
  computed: {
    thirdIndex() {
      const normIndex = 1
      const homogeneousIndex = normIndex + 1
      const anovaIndex = homogeneousIndex + 1
      const postHocIndex = anovaIndex + 1
      const noneParamIndex = normIndex + 1
      const noneParamPostHocIndex = noneParamIndex + 1
      return { normIndex, homogeneousIndex, anovaIndex, postHocIndex, noneParamIndex, noneParamPostHocIndex }
    },
    lei() {
      return this.data.lei
    },
    liang() {
      return this.xys[1][0]
    },
    leiOptions() {
      return this.xys[0][0].slice(0, -1)
    },
    isAnova() {
      const { isNorm, isHomogeneous, a } = this
      const isAnova = []
      if (isNorm.length > 0 && isHomogeneous.length > 0) {
        isNorm.forEach((p, index) => {
          isAnova.push(p >= a && isHomogeneous[index] >= a ? 1 : 0)
        })
      }
      return isAnova
    },
    divideP() {
      return (ary) => {
        const obj = {
          less: [],
          greater: []
        }
        ary.forEach((p, index) => {
          if (p || p === 0) {
            const liang = this.xys[1][index]
            if (typeof p === 'number') {
              // p值有可能是数字/数组/true或者false
              p >= this.a ? obj.greater.push(liang) : obj.less.push(liang)
            } else if (typeof p === 'object') {
              p.every(v => v >= this.a) ? obj.greater.push(liang) : obj.less.push(liang)
            } else {
              p ? obj.greater.push(liang) : obj.less.push(liang)
            }
          }
        })
        obj.less = obj.less.join('、')
        obj.greater = obj.greater.join('、')
        return obj
      }
    },
    noneParamPostHocObj() {
      const obj = {
        nemenyi: [],
        none: []
      }
      const { noneParamIsDiff, a } = this
      if (noneParamIsDiff.length > 0) {
        const data1 = this.data.data1
        let index = 0
        let listName
        for (const key in data1) {
          const pIsDiff = noneParamIsDiff[index]
          if (pIsDiff || pIsDiff === 0) {
            if (pIsDiff < a) {
              listName = 'nemenyi'
            } else {
              listName = 'none'
            }
          } else {
            listName = 'none'
          }
          obj[listName].push(key)
          index++
        }
        for (const key in obj) {
          obj[key] = obj[key].join('、')
        }
      }
      return obj
    },
    postHocObj() {
      const { a } = this
      const data1 = this.data.data1
      const obj = {
        gamesHowell: [],
        turkey: [],
        scheffe: [],
        none: []
      }
      let index = 0
      for (const key in data1) {
        if (this.isHomogeneous[index] < a) {
          if (this.isDiff[index] < a) {
            obj.gamesHowell.push(key)
          } else {
            obj.none.push(key)
          }
        } else {
          if (this.isDiff[index] < a) {
            if (this.isLenEqual[index]) {
              obj.turkey.push(key)
            } else {
              obj.scheffe.push(key)
            }
          } else {
            obj.none.push(key)
          }
        }
        index++
      }
      for (const key in obj) {
        obj[key] = obj[key].join('、')
      }
      return obj
    },
    a() {
      return this.$store.state.report.a
    },
    flow_anova_now() {
      // 配置中流程当前选择的方法的value值
      return this.settings.options.flows.find(v => v.label === '差异显著性检验方法').value
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
