var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chart",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        padding: [0],
        data: _vm.data,
        scale: _vm.scale
      }
    },
    [
      _c("v-tooltip", { attrs: { "show-title": false } }),
      _c("v-coord", { attrs: { type: "rect", direction: "TL" } }),
      _c("v-point", {
        attrs: {
          position: "x*y",
          color: "category",
          shape: "cloud",
          tooltip: "value*category"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }