<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 0">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 0 30px">
            <div v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
                <template #a>
                  <template slot="param" slot-scope="text">hhh{{ text }}asfsfdsfs</template>
                </template>
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            v-for="(item, index) in res.images"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="`多项式回归图`"></MyImg>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/views/analysisOfRegression/MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import MyTable2 from '../../table'

export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {}
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).polynomialRegression()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      res.tables[1].columns[0].customRender = (text, row, index) => {
        return <span domPropsInnerHTML={text}></span>
      }
      return res
    }
  }
}
</script>