import { render, staticRenderFns } from "./MiniSmoothArea.vue?vue&type=template&id=39e36e2c&scoped=true&"
import script from "./MiniSmoothArea.vue?vue&type=script&lang=js&"
export * from "./MiniSmoothArea.vue?vue&type=script&lang=js&"
import style0 from "./MiniSmoothArea.vue?vue&type=style&index=0&id=39e36e2c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e36e2c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39e36e2c')) {
      api.createRecord('39e36e2c', component.options)
    } else {
      api.reload('39e36e2c', component.options)
    }
    module.hot.accept("./MiniSmoothArea.vue?vue&type=template&id=39e36e2c&scoped=true&", function () {
      api.rerender('39e36e2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Charts/MiniSmoothArea.vue"
export default component.exports