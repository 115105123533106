import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import {
  setDocumentTitle,
  domTitle
} from '@/utils/domUtil'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import {
  i18nRender
} from '@/locales'
import {
  asyncRouterMap,
  constantRouterMap
} from './config/router.config'

// import {
//   Get
// } from './api/sample'

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = ['login', 'personLogin', 'register', 'registerResult', 'result', 'warnning', 'findPassword1', 'article'] // no redirect whitelist
// const loginRoutePath = router.currentRoute.query.roles === 'person' ? '/person/login' : '/user/login'
const loginRoutePath = document.domain.toLowerCase().includes('uubigdata') ? '/personLogin/login' : '/user/login'
// const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  // 顶部进度条的插件，这里是进度条开始
  NProgress.start() // start progress bar
  // 设置每个路由的title
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`))
  /* has token */
  // debugger
  if (storage.get(ACCESS_TOKEN)) {
    // 如果路由去的路径是登录页面的路径
    if (to.path === loginRoutePath) {
      next()
      // next({
      //   path: defaultRoutePath
      // })
      // 这里是进度条结束
      NProgress.done()
    } else {
      // check login user.roles is null
      // 如果当前不存在用户角色信息
      if (store.state.user.roles.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then(res => {
            var role = ''
            switch (res.userRole) {
              case 0:
                role = 'admin'
                break
              case 1:
                role = 'groupleader'
                break
              case 2:
                role = 'user'
                break
              case 3:
                role = 'statistician'
                break
              case 4:
                role = 'person'
                break
            }
            // if (sessionStorage['guid']) {
            //   Get({
            //     guid: sessionStorage['guid'].toString()
            //   }).then(aa => {
            //     sessionStorage['qu2'] = '已取' + Date()
            //     if (aa) {
            //       store.dispatch('updateSamples', aa)
            //     }

            //     getrounter(role, res, from, to, next)
            //     sessionStorage['guid'] = ''
            //   })
            // } else {
            sessionStorage['biaoshi'] = '无'
            // 如果是个人用户且是vip
            if (role === 'person' && res.vip) {
              // 给需要付费的模块的路由添加权限
              addPermisson(asyncRouterMap)
              addPermisson(constantRouterMap)
            }
            getrounter(role, res, from, to, next)
            // }
          })
          .catch((e) => {
            console.log('%c 🍏 e: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', e);
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({
                path: loginRoutePath,
                query: {
                  redirect: to.fullPath
                }
              })
            })
          })
        // 如果当前存在用户信息的
      } else {
        // 如果是个人用户，要判断有没有权限进入当前路由
        if (store.state.user.roles === 'person') {
          if ('meta' in to) {
            // 如果当前路由是付过费被激活的，那么进入
            if (to.meta.active) {
              next()
              // 如果当前路由没被激活，那么触发付费的弹窗提醒
            } else {
              store.dispatch('changePayNoticeController', to.meta.title)
              NProgress.done()
            }
          }
          // 如果是非个人，那么就是机构，则可以随便进入
        } else {
          next()
        }
      }
    }
    // 如果已经没有token
  } else {
    // 如果是白名单列表的页面，可以直接跳转
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else if (to.path.indexOf('questionnaire') !== -1) {
      next()
    } else {
      // 不是白名单的页面，就表示是需要token才能进的，那么跳转到登录页面，并且附带一个参数，参数是原先想跳转的路由路径。
      next({
        path: loginRoutePath,
        query: {
          redirect: to.fullPath
        }
      })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

function getrounter(role, res, from, to, next) {
  // generate dynamic router
  store.dispatch('GenerateRoutes', {
    role,
    org: res.org
  }).then(() => {
    // 根据roles权限生成可访问的路由表
    // 动态添加可访问路由表
    router.addRoutes(store.getters.addRouters)
    // 请求带有 redirect 重定向时，登录自动重定向到该地址
    const redirect = decodeURIComponent(from.query.redirect || to.path)
    if (to.path === redirect) {
      // set the replace: true so the navigation will not leave a history record
      next({
        ...to,
        replace: true
      })
    } else {
      // 跳转到目的路由
      next({
        path: redirect
      })
    }
  })
}

function addPermisson(routerAry) {
  /** 该方法是给路由数组中需要付费的路由添加active:true的
   * @param routerAry 路由数组
   */
  // 遍历路由数组
  routerAry.forEach(v => {
    // 如果当前路由对象的路径包含数组中的字符串，则要被激活
    if (['/intelligenceReports', '/analysisOfRegression', '/report/Print', '/question', '/question/Print'].some(i => v.path.includes(i))) {
      if ('meta' in v) {
        v.meta.active = true
      }
      // 如果当前路由对象还有children的，那么继续遍历进去
      if ('children' in v) {
        addPermisson(v.children)
      }
      // 如果当前路由对象的路径不包含数组中的字符串
    } else {
      // 如果有children，那么继续遍历进去
      if ('children' in v) addPermisson(v.children)
    }
  })
}