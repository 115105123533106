var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px" } },
    [
      _c(
        "a-row",
        { staticStyle: { "margin-bottom": "5px" } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    showHeader: _vm.showHeader,
                    columns: _vm.columns,
                    "data-source": _vm.data,
                    bordered: "",
                    pagination: false,
                    scroll: { x: true },
                    size: _vm.size
                  }
                },
                [
                  _c("span", { attrs: { slot: "p" }, slot: "p" }, [
                    _c("i", [_vm._v("p")])
                  ]),
                  _vm._l(_vm.columns, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.key,
                        attrs: {
                          slot: "slotValue" in item ? item.slotValue : ""
                        },
                        slot: "slotValue" in item ? item.slotValue : ""
                      },
                      [
                        _c(
                          "div",
                          { domProps: { innerHTML: _vm._s(item.slotValue) } },
                          [_vm._v(_vm._s(item.slotValue))]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }