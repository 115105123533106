var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false, id: "divQuestion" } },
    [
      _c(
        "a-steps",
        {
          staticClass: "steps",
          staticStyle: { width: "50%", margin: "0 auto" },
          attrs: { current: _vm.currentTab }
        },
        [
          _c(
            "a-step",
            { attrs: { title: "上传EXCEL文件" } },
            [
              _c("a-icon", {
                attrs: { slot: "icon", type: "cloud-upload" },
                slot: "icon"
              })
            ],
            1
          ),
          _c(
            "a-step",
            { attrs: { title: "数据预览" } },
            [
              _c("a-icon", {
                attrs: { slot: "icon", type: "file-search" },
                slot: "icon"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.currentTab === 0
            ? _c("step1", { on: { nextStep: _vm.nextStep } })
            : _vm._e(),
          _vm.currentTab === 1
            ? _c("step2", {
                attrs: { form: _vm.exceldata, excelerro: _vm.excelerro },
                on: { prevStep: _vm.prevStep, finish: _vm.finish }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }