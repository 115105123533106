<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 30px">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24">
            <div style="margin-bottom: 20px" v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-col>
    <a-col :span="24" v-for="(item, index) in res.imgs" :key="index" style="display: flex; justify-content: center">
      <MyImg :imgBytes="item" :fileName="`协变量平均值处的生存函数`"></MyImg>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../../../MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import MyTable2 from '../../table'
export default {
  components: { MyTable2, Resid },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {}
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).Cox()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      return {
        imgs: res.imgs,
        tables: [
          this.getLogTable(res.value),
          this.getSummaryTable(res.table1)
        ]
      }
    },
    getSummaryTable(table1) {
      const columns = [
        {
          title: '',
          dataIndex: 'covariate',
          align: 'center',
          key: 'covariate'
        },
        {
          title: 'coef',
          dataIndex: 'coef',
          align: 'center',
          key: 'coef'
        },
        {
          title: 'exp(coef)',
          dataIndex: 'exp(coef)',
          align: 'center',
          key: 'exp(coef)'
        },
        {
          title: 'se(coef)',
          dataIndex: 'se(coef)',
          align: 'center',
          key: 'se(coef)'
        },
        {
          title: 'coef lower 95%',
          dataIndex: 'coef lower 95%',
          align: 'center',
          key: 'coef lower 95%'
        },
        {
          title: 'coef upper 95%',
          dataIndex: 'coef upper 95%',
          align: 'center',
          key: 'coef upper 95%'
        },
        {
          title: 'z',
          dataIndex: 'z',
          align: 'center',
          key: 'z'
        },
        {
          title: 'p值',
          dataIndex: 'p',
          align: 'center',
          key: 'p'
        }
      ]
      const data = JSON.parse(table1).data
      // 给data中的值都保留三位小数
      data.forEach((v, i) => {
        for (const key in v) {
          if (key !== 'covariate') {
            const value = v[key]
            v[key] = value && value.toFixed(3)
          }
          v['key'] = i
        }
      })
      console.log('%c 🍊 data: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', data);
      return {
        columns, data, title: '方程式中的变量', showHeader: true, size: 'small'
      }
    },
    getLogTable(value) {
      const columns = [
        {
          title: '-2对数似然',
          dataIndex: '-2log',
          key: '-2log',
          align: 'center'
        },
        {
          title: '卡方(H)',
          dataIndex: 'test_statistic',
          key: 'test_statistic',
          align: 'center'
        },
        {
          title: 'df',
          dataIndex: 'df',
          key: 'df',
          align: 'center'
        },
        {
          title: 'p值',
          dataIndex: 'p_value',
          key: 'p_value',
          align: 'center'
        }
      ]
      const data = [
        {
          key: 0,
          '-2log': value['-2log'].toFixed(3),
          test_statistic: value.test_statistic.toFixed(3),
          p_value: value.p_value.toFixed(3),
          df: value.df
        }
      ]
      return {
        columns, data, title: '模型系数的似然比检验', showHeader: true, size: 'small'
      }
    }
  }
}
</script>