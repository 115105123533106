// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import {
  VueAxios
} from './utils/request'
import ProLayout, {
  PageHeaderWrapper
} from '@ant-design-vue/pro-layout'
import {
  Cascader,
  TreeSelect,
  FormModel,
  Collapse,
  ConfigProvider,
  LocaleProvider,
  Slider,
  Pagination,
  message
} from 'ant-design-vue'
import 'ant-design-vue/lib/table/style/css'
import themePluginConfig from '../config/themePluginConfig'
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './global.less'
// import echarts from 'echarts'
import moment from 'moment'
import 'moment/locale/zh-cn'
import VCharts from 'v-charts'
import Print from 'vue-print-nb'
import {
  STable
} from '@/components'

// import Enum from 'vue-enum'
// import enumFiled from './common/enums'

import {
  log,
  deepClone,
  hypothesis,
  formatOption
} from '@/utils/util'
import MyTable from '@/views/hypothesisTest/components/table'
import MyTextarea from '@/views/hypothesisTest/components/textarea'
import BoxShadowCard from '@/components/myComponents/boxShadowCard'
import dropItems from '@/views/hypothesisTest/components/dropItems'
import FormItems from '@/views/document/formItems'
import MyImg from '@/components/myComponents/MyImg'
import Conclusion from '@/components/myComponents/Conclusion'
import vcolorpicker from 'vcolorpicker'
import {
  enumUnit
} from '@/common/enums'
import axios from 'axios'

// moment用中文
moment.locale('zh-cn')
axios.defaults.withCredentials = true
Vue.config.productionTip = false
// Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$log = log
Vue.prototype.$deepClone = deepClone
Vue.prototype.$hypothesis = hypothesis
Vue.prototype.$formatOption = formatOption

// Vue.use(Enum, { enumFiled })
// mount axios to `Vue.$http` and `this.$http`
Vue.use(Print)
Vue.use(VueAxios)
Vue.use(VCharts)
Vue.use(FormModel)
Vue.use(Collapse)
Vue.use(ConfigProvider)
Vue.use(LocaleProvider)
Vue.use(Slider)
Vue.use(Pagination)
Vue.use(vcolorpicker)
Vue.component('MyImg', MyImg)
Vue.component('pro-layout', ProLayout)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.component(Cascader.name, Cascader)
Vue.component(TreeSelect.name, TreeSelect)
Vue.component('MyTable', MyTable)
Vue.component('MyTextarea', MyTextarea)
Vue.component('BoxShadowCard', BoxShadowCard)
Vue.component('FormItems', FormItems)
Vue.component('DropItems', dropItems)
Vue.component('STable', STable)
Vue.component('Conclusion', Conclusion)

// 单位转换过滤器
Vue.filter('fetchUnit', value => {
  var obj = enumUnit.find(element => element.value === value)
  return obj ? obj.text : ''
})
Vue.filter('round', value => {
  return +value && +value !== 'NaN' ? value.toFixed(3) : value
})
window.umi_plugin_ant_themeVar = themePluginConfig.theme

message.config({
  top: `100px`
})
new Vue({
  router,
  store,
  i18n,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
// let _beforeUnload_time
// let _gap_time

// window.addEventListener('beforeunload', e => beforeunloadFn())
// window.addEventListener('unload', e => unloadFn())

// function unloadFn() {
//   _gap_time = new Date().getTime() - _beforeUnload_time;
//   if (_gap_time <= 5) {
//     storage.remove(ACCESS_TOKEN)
//   }
// }

// function beforeunloadFn() {
//   _beforeUnload_time = new Date().getTime();
// }