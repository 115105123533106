<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 0">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 0 30px">
            <div style="margin-bottom: 20px" v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
            </div>
            <my-table2 :showHeader="false" :data="table3.data" :columns="table3.columns" size="middle"></my-table2>
            <box-shadow-card style="margin-top: 20px" :title="'公式'">
              <div>
                <p v-for="(item, index) in formulaAry" :key="index">
                  {{ item }}
                </p>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/views/analysisOfRegression/MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import { translate } from '@/utils/language'
import MyTable2 from '../../table'
import { change_to_percent } from '@/utils/util'
export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {},
      formulaAry: [],
      table3: {
        columns: [
          {
            title: '',
            dataIndex: 'col1',
            align: 'center'

          },
          {
            title: '',
            dataIndex: 'col2',
            align: 'center'

          }

        ]
      }
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).MultipleLogic()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      console.log('%c 🍿 res: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', res)
      this.makeTable3(res)
      this.makeFormula(res)
      return res
    },
    makeFormula(res) {
      const yAs1 = res.result.key_value_map['1']
      const table = res.tables[1]
      const formulaAry = []
      let formulaStr
      table.data.forEach((v, i) => {
        // 如果是表头
        if (v.col5 === 'p') {
          // 如果不是第一行的表头，那么要把上一张表的公式添加到数组
          i !== 0 && formulaAry.push(formulaStr)
          // 生成一个新的公式字符串
          formulaStr = ''
          // 正则匹配等号后面的字符串并且添加到公式中
          const reg = /(= )(.+)/
          reg.test(v.col1)
          // $2表示正则分组中的第二个。
          const yStr = `ln( ${RegExp.$2} / ${yAs1} ) = `
          formulaStr += yStr
        } else if (v.col1 === translate('截距')) {
          // 把截距添加到公式中
          formulaStr += v.col2.trim()
        } else {
          formulaStr += `${+v.col2 > 0 ? ' + ' : ' - '}${Math.abs(+v.col2)} * ${v.col1}`
          // 如果遍历到最后一个，要把最后一个表的公式添加到数组中。
          i === table.data.length - 1 && formulaAry.push(formulaStr)
        }
      })
      this.formulaAry = formulaAry
    },
    makeTable3(res) {
      const { table3 } = this
      const { y_name, key_value_map, predAry } = res.result
      const optionNum = Object.keys(key_value_map).length
      const optionAry = Object.values(key_value_map)
      table3.columns[0].customRender = (text, record, index) => {
        const obj = {
          children: text,
          attrs: {}
        }
        // 观测值、准确率的纵向占2。
        if ([0, 3 + optionNum].includes(index)) {
          obj.attrs.colSpan = 2
        }
        // 观测值横向占3
        if ([0].includes(index)) {
          obj.attrs.rowSpan = 3
        }
        // 居住情况横向占分类数
        if ([3].includes(index)) {
          obj.attrs.rowSpan = optionNum
        }
        const ary = []
        for (let index = 4; index < 3 + optionNum; index++) {
          ary.push(index)
        }
        if ([1, 2, ...ary].includes(index)) {
          obj.attrs.rowSpan = 0
          obj.attrs.colSpan = 0
        }
        return obj
      }
      table3.columns[1].customRender = (text, record, index) => {
        const obj = {
          children: text,
          attrs: {}
        }
        if ([0, 1, 2, 3 + optionNum].includes(index)) {
          obj.attrs.rowSpan = 0
          obj.attrs.colSpan = 0
        }
        return obj
      }
      const data = [
        {
          key: 0,
          col1: translate('观测值'),
          col3: translate('预测值')
        },
        {
          key: 1,
          col3: y_name,
          [`col${3 + optionNum}`]: translate('正确百分比')
        },
        {
          key: 2
        }
      ]
      // 遍历定类的类别数组并且进行一系列的格式化操作。
      optionAry.forEach((v, i) => {
        // 添加横向的分类名。
        data[2][`col${3 + i}`] = v
        // 添加竖向的分类名。
        data.push({
          key: 3 + i,
          col1: y_name,
          col2: v
        })
        // 添加竖向的列
        table3.columns.push(
          {
            dataIndex: `col${3 + i}`,
            align: 'center',
            customRender: i > 0
              ? (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([0, 1].includes(index)) obj.attrs.colSpan = 0
                return obj
              }
              : (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([1].includes(index)) obj.attrs.colSpan = optionNum
                if ([0].includes(index)) obj.attrs.colSpan = optionNum + 1
                return obj
              }
          }
        )
      })
      // 添加最后一列
      table3.columns.push({
        dataIndex: `col${3 + optionNum}`,
        align: `center`,
        customRender: (text, record, index) => {
          const obj = {
            children: text,
            attrs: {}
          }
          if ([0].includes(index)) obj.attrs.colSpan = 0
          if ([1].includes(index)) obj.attrs.rowSpan = 2
          if ([2].includes(index)) obj.attrs.rowSpan = 0
          return obj
        }
      })
      // 添加最后一行
      data.push({
        key: '准确率',
        col1: translate('准确率'),
        [`col${3 + optionNum}`]: ''
      })
      // 赋值
      // 二维数组转为一维
      const ary = predAry.reduce((acc, cur) => {
        return acc.concat(cur)
      }, [])
      const totalSum = ary.reduce((n, m) => n + m)
      // 列求和之前的初始数组
      const colSumAry = optionAry.map(v => 0)
      // 对角线求和的初始值
      let oppositeSum = 0
      // 遍历二维数组进行一系列计算赋值操作
      predAry.forEach((v, i) => {
        const rowSum = v.reduce((n, m) => n + m)
        v.forEach((j, i2) => {
          data[3 + i][`col${3 + i2}`] = j

          // 如果当前列等于行。
          if (i2 === i) {
            // 求百分比正确并且赋值
            data[3 + i][`col${3 + optionNum}`] = change_to_percent(j, rowSum)
            // 对角线求和
            oppositeSum = oppositeSum + j
          }

          // 求准确率并且赋值。
          // 求纵向和数组
          colSumAry[i2] = colSumAry[i2] + j
          // 如果是最后一行了，那么纵向已经求和完，可以赋值准确率了
          if (i === optionNum - 1) {
            data[3 + optionNum][`col${3 + i2}`] = change_to_percent(colSumAry[i2], totalSum)
          }
        })
      })
      // 赋值最右下角那个值
      data[3 + optionNum][`col${3 + optionNum}`] = change_to_percent(oppositeSum, totalSum)

      table3.data = data
    }
  }
}
</script>