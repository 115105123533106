var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "20px 0 30px"
      }
    },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { staticStyle: { padding: "0 30px" }, attrs: { span: 24 } },
                  [
                    _vm._l(_vm.res.tables, function(table, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { "margin-bottom": "10px" }
                        },
                        [
                          _c("my-table2", {
                            attrs: {
                              showHeader: table.showHeader,
                              data: table.data,
                              columns: table.columns,
                              title: table.title,
                              size: table.size
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _vm.resid &&
                    "vif_array" in _vm.resid &&
                    _vm.num_of_param > 2
                      ? _c("p", { staticStyle: { "margin-top": "20px" } }, [
                          _c("span", [_vm._v("由上表可得：")]),
                          _vm.resid.vif_array.every(function(v) {
                            return v < 10
                          })
                            ? _c("span", [
                                _vm._v(
                                  " 全部自变量的VIF < 10，说明模型没有多重共线性问题，模型构建良好。 "
                                )
                              ])
                            : _c("span", [
                                _vm._v("存在自变量的VIF ≥ 10，模型构建较差。 ")
                              ])
                        ])
                      : _vm._e(),
                    _c("span", [
                      _vm._v("回归方程式为：" + _vm._s(_vm.formula))
                    ]),
                    _vm.dw
                      ? _c("p", { staticStyle: { "margin-top": "20px" } }, [
                          _vm.num_of_param === 2
                            ? _c("span", [_vm._v("由上表可得：")])
                            : _vm._e(),
                          _vm.dw >= 1.7 && _vm.dw <= 2.3
                            ? _c("span", [
                                _vm._v(
                                  "由于1.7 ≤ Durbin-Watson值 (" +
                                    _vm._s(_vm.dw) +
                                    ") ≤ 2.3，因此残差序列不存在自相关。"
                                )
                              ])
                            : _vm.dw >= 1 && _vm.dw <= 1.7
                            ? _c("span", [
                                _vm._v(
                                  "由于1 ≤ Durbin-Watson值 (" +
                                    _vm._s(_vm.dw) +
                                    ") ≤ 1.7，无法判断残差序列的相关性。"
                                )
                              ])
                            : _vm.dw >= 2.3 && _vm.dw <= 3
                            ? _c("span", [
                                _vm._v(
                                  "由于2.3 ≤ Durbin-Watson值 (" +
                                    _vm._s(_vm.dw) +
                                    ") ≤ 3，无法判断残差序列的相关性。"
                                )
                              ])
                            : _vm.dw >= 0 && _vm.dw < 1
                            ? _c("span", [
                                _vm._v(
                                  "由于0 ≤ Durbin-Watson值 (" +
                                    _vm._s(_vm.dw) +
                                    ") < 1，因此残差序列存在正自相关。"
                                )
                              ])
                            : _vm.dw > 3 && _vm.dw <= 4
                            ? _c("span", [
                                _vm._v(
                                  "由于3 < Durbin-Watson值 (" +
                                    _vm._s(_vm.dw) +
                                    ") ≤ 4，因此残差序列存在负自相关。"
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v("线性回归图如下图所示：")])
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "a-row",
              _vm._l(_vm.res.images, function(item, index) {
                return _c(
                  "a-col",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c("MyImg", {
                      attrs: { imgBytes: item, fileName: "线性回归图" }
                    })
                  ],
                  1
                )
              }),
              1
            ),
            _vm.resid
              ? _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      {
                        staticStyle: { padding: "0 30px" },
                        attrs: { span: 24 }
                      },
                      [
                        _c("Resid", {
                          attrs: {
                            resid: _vm.resid,
                            parentType: "analysisOfRegression",
                            yName: _vm.y_name
                          },
                          on: {
                            resid_norm_p: function(val) {
                              return (_vm.resid_norm_p = val)
                            },
                            method_name: function(val) {
                              return (_vm.method_name = val)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "text1",
                                fn: function() {
                                  return [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "使用" +
                                                _vm.method_name +
                                                "算法对残差序列做正态性检验，检验结果如下表所示： "
                                            )
                                        )
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "text2",
                                fn: function() {
                                  return [
                                    _c("p", [
                                      _c("span", [_vm._v("由上表可得：")]),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$hypothesis.conclusion(
                                              _vm.resid_norm_p,
                                              0.05,
                                              "残差序列是正态的",
                                              "残差序列是非正态的"
                                            )
                                          )
                                        }
                                      })
                                    ])
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "text3",
                                fn: function() {
                                  return [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "标准化残差的直方图和PP图如下图所示："
                                        )
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3345238493
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }