<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.regression}`"
        :id="`3.${secondIndex}.${thirdIndex.regression} 回归`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.regression }} 回归
      </div>
      <div>
        <RobustRegression
          :ref="`content,1`"
          :xys="xys"
          :data="data"
          :settings="settings.options.methods.find((v) => v.name === '鲁棒线性回归')"
          @resid="(val) => (resid = val)"
        >
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.regression},1`">
              <span> 使用{{ xys_str }}做鲁棒回归分析，分析结果如下表所示： </span>
            </p>
          </template>
          <template #text2="{ formula }" class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.regression},3`">
                <span> 由上表可得： </span>
                <span v-if="xys[0].length > 1">
                  <span v-if="resid && resid.vif_array.every((v) => v < 10)">
                    全部自变量的VIF &lt; 10，说明模型没有多重共线性问题，模型构建良好。
                  </span>
                  <span v-else> 由上表可得：存在自变量的VIF ≥ 10，模型构建较差。 </span>
                </span>
                <span>回归方程式为：</span>
                <br />
                <span>{{ formula }}</span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.regression},4`">
              <span v-if="xys[0].length === 1">{{ xys[0][0] }}与{{ xys[1][0] }}的线性回归图如下图所示。</span>
              <span v-else>
                {{ `${xys[1][0]}拟合值与${xys[1][0]}观测值的线性回归图如下图所示。` }}
              </span>
            </p>
          </template>
        </RobustRegression>
      </div>
    </div>

    <div v-if="resid && xys[0].length > 1">
      <div
        :ref="`title,${thirdIndex.resid}`"
        :id="`3.${secondIndex}.${thirdIndex.resid} 残差序列分析`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.resid }} 残差序列分析
      </div>
      <div style="padding: 0 15px">
        <div>
          <div
            :ref="`sub_title,${thirdIndex.resid},1`"
            :id="`3.${secondIndex}.${thirdIndex.resid}.1 正态性检验`"
            class="forth-title"
          >
            3.{{ secondIndex }}.{{ thirdIndex.resid }}.1 正态性检验
          </div>
          <div>
            <Resid
              :ref="`content,${thirdIndex.resid},1`"
              :resid="resid"
              :parentType="`report`"
              :yName="xys[1][0]"
              @resid_norm_p="(val) => (resid_norm_p = val)"
              @method_name="(val) => (method_name = val)"
            >
              <template #text1 class="text">
                <p :ref="`text,${thirdIndex.resid},2`">
                  <span>
                    {{
                      `使用${method_name}算法对残差序列做正态性检验（H0：残差序列是正态的；H1：残差序列是非正态的），检验结果如下表所示：`
                    }}</span
                  >
                </p>
              </template>
              <template #text2 class="text">
                <Conclusion>
                  <p :ref="`text,${thirdIndex.resid},4`">
                    <span>由上表可得：</span>
                    <span
                      v-html="$hypothesis.conclusion(resid_norm_p, a, '残差序列是正态的', '残差序列是非正态的')"
                    ></span>
                  </p>
                </Conclusion>
              </template>
              <template #text3 class="text">
                <p :ref="`text,${thirdIndex.resid},5`">
                  <span>残差相关图如下所示：</span>
                </p>
              </template>
            </Resid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RobustRegression from '@/views/report/modules/hypothesis/robustRegression'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import Resid3 from '@/views/report/modules/hypothesis/resid3.vue'
export default {
  name: 'RobustRegressionMain',
  components: { RobustRegression, Resid, Resid3 },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  data() {
    return {
      resid: null,
      resid_norm_p: null,
      method_name: null // 正态检验用的算法名
    }
  },
  computed: {
    thirdIndex() {
      const regression = 1
      const resid = regression + 1
      const resid3 = regression + 2
      return { regression, resid, resid3 }
    },
    xys_str() {
      const { xys } = this
      const x_list = xys[0]
      const y_list = xys[1]
      const x_str = x_list.map(v => Array.isArray(v) ? `“${v[v.length - 1]}”` : `“${v}”`).join('、')
      const y_str = y_list[0]
      return `${x_str}与${y_str}`
    },
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>