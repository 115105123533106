  // ----------临床营养风险筛查记录表分值计算-------------
  export function jibing(value) {
    if (value) {
      switch (value) {
        case '髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤':
          return 1
        case '腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤':
          return 2
        case '颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者':
          return 3
        default:
          return 0
      }
    }
    return 0
  }
  export function BMI(value) {
    if (value) {
      if (value < 18.5) {
        return 3
      } else {
        return 0
      }
    }
    return 0
  }
  export function height(value) {
    if (value) {
      switch (value) {
        case '体重下降＞5%在3个月内':
          return 1
        case '体重下降＞5%在2个月内':
          return 2
        case '体重下降＞5%在1个月内':
          return 3
        case '体重下降≤5%':
          return 0
        default:
          return 0
      }
    }
    return 0
  }
  export function jinshi(value) {
    if (value) {
      switch (value) {
        case '没减少':
          return 0
        case '较从前减少：25%~ 50%':
          return 1
        case '较从前减少：51%~ 75%':
          return 2
        case '较从前减少：76%~100%':
          return 3
        default:
          return 0
      }
    }
    return 0
  }
  export function age(value) {
    if (value) {
      if (value === '年龄＜70岁') {
        return 0
      } else {
        return 1
      }
    }
    return 0
  }