<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index" style="margin-bottom: 10px">
        <a-table
          v-if="table"
          ref="tables,2"
          :title="() => table.title"
          :showHeader="table.showHeader"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="text2" :formula="formula"></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <a-row v-for="(img, index) in imgs" :key="index">
        <MyImg ref="imgs,5" :imgBytes="img" :fileName="'最小二乘线性回归图'"></MyImg>
      </a-row>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
import { getPictureSize } from '@/utils/util'
import { translate } from '@/utils/language'
export default {
  name: 'OrdinaryLeastSquares',
  props: ['data', 'xys', 'settings'],
  data() {
    return {
      tables: [],
      imgs: [],
      formula: null
    }
  },
  created() {
    this.handleSettings()
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'OrdinaryLeastSquares', data: { ...this.data, ...getPictureSize('report', 50) } }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '最小二乘回归',
          msg: res
        })
      } else {
        this.$emit('resid', res.data)
        // 把带有dw值的表格传递出去
        const dw_table = res.tables[2]
        this.$emit('dw_table', dw_table)
        // 把原表中带有dw值的表格去除
        res.tables.splice(2, 1)
        // 生成线性回归后的方程式
        const table = res.tables[1]
        this.get_formula(table)

        this.tables = res.tables
        this.imgs = res.images
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  methods: {
    handleSettings() {
      // 把settings里的配置添加到data里去。
      this.data.method = this.settings.args[0].value
    },
    get_formula(table) {
      const y_name = this.xys[1][0]
      let str_formula = `${y_name} ＝ `
      table.data.forEach((row, row_index) => {
        if (row_index === 0) {
          str_formula = `${str_formula}${row[translate('系数')].trim()}`
        } else {
          str_formula = `${str_formula}${+row[translate('系数')] > 0 ? ' ＋ ' : ' － '}${Math.abs(+row[translate('系数')])} × ${row[translate('变量')].trim()}`
        }
      })
      this.formula = str_formula
    }
  }
}
</script>