import { deepClone } from '@/utils/util'
export const group = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    min: 1,
    num: 10, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    value2: undefined
  },
  {
    id: 1,
    index: 1,
    prop: 'dependentVariable',
    label: '因变量Y', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    min: 1,
    num: 10, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [] // 必填项1
  }
]
export const settings1 = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 10, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [] // 必填项1
  }
]

export const settings2 = [{
  label: '', // 在不需要label的时候，一定要定义出一个空字符串。
  componentName: 'Drops',
  group, // 初始group
  xysName: 'dropGroups2',
  addXys: [
    [],
    []
  ], // 这个是点击增加后xys当中需要增加的数组，相当于一个group
  parentValue: 1,
  type: 'component',
  settings: [
    [{
      id: 0,
      index: 0,
      prop: 'independentVariable',
      label: '自变量X', // 字段名(必填项)
      parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
      type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
      dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
      min: 1,
      num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
      value: '', // 用来决定children列表里哪些input是显示的
      options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
      list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
      divHelp: ['请拖入左侧变量'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
      children: [], // 必填项1
      value2: undefined
    },
    {
      id: 1,
      index: 1,
      prop: 'dependentVariable',
      label: '因变量Y', // 字段名(必填项)
      parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
      type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
      dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
      min: 1,
      num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
      value: '', // 用来决定children列表里哪些input是显示的
      options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
      list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
      divHelp: ['请拖入左侧变量'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
      children: [] // 必填项1
    }
  ]
  ],
  children: []
}]

export const settings3 = [{
  label: '', // 在不需要label的时候，一定要定义出一个空字符串。
  componentName: 'Drops',
  group, // 初始group
  xysName: 'dropGroups3',
  addXys: [
    [],
    []
  ], // 这个是点击增加后xys当中需要增加的数组，相当于一个group
  parentValue: 1,
  type: 'component',
  settings: [
    deepClone(group)
  ],
  children: []
}]