var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          ref: "title," + _vm.thirdIndex.regression,
          staticClass: "third-title",
          attrs: {
            id:
              "3." + _vm.secondIndex + "." + _vm.thirdIndex.regression + " 回归"
          }
        },
        [
          _vm._v(
            " 3." +
              _vm._s(_vm.secondIndex) +
              "." +
              _vm._s(_vm.thirdIndex.regression) +
              " 回归 "
          )
        ]
      ),
      _c(
        "div",
        [
          _c("GeneralizedOrdinaryLeastSquares", {
            ref: "content,1",
            attrs: {
              xys: _vm.xys,
              data: _vm.data,
              settings: _vm.settings.options.methods.find(function(v) {
                return v.name === "广义最小二乘回归"
              })
            },
            on: {
              resid: function(val) {
                return (_vm.resid = val)
              },
              dw_table: function(val) {
                return (_vm.dw_table = val)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "text1",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.regression + ",1" },
                      [
                        _c("span", [
                          _vm._v(
                            " 使用" +
                              _vm._s(_vm.xys_str) +
                              "做广义最小二乘回归分析，分析结果如下所示： "
                          )
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "text2",
                fn: function(ref) {
                  var formula = ref.formula
                  return [
                    _c("Conclusion", [
                      _c("p", { ref: "text,1,3" }, [
                        _c("span", [_vm._v("由上表可得：")]),
                        _vm.xys[0].length > 1
                          ? _c("span", [
                              _vm.resid &&
                              _vm.resid.vif_array.every(function(v) {
                                return v < 10
                              })
                                ? _c("span", [
                                    _vm._v(
                                      " 由上表可得：全部自变量的VIF < 10，说明模型没有多重共线性问题，模型构建良好。 "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " 由上表可得：存在自变量的VIF ≥ 10，模型构建较差。 "
                                    )
                                  ])
                            ])
                          : _vm._e(),
                        _c("span", [_vm._v("回归方程式为：")]),
                        _c("br"),
                        _c("span", [_vm._v(_vm._s(formula))])
                      ])
                    ])
                  ]
                }
              },
              {
                key: "text3",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.regression + ",4" },
                      [
                        _vm.xys[0].length === 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.xys[0][0]) +
                                  "与" +
                                  _vm._s(_vm.xys[1][0]) +
                                  "的线性回归图如下图所示。"
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.xys[1][0] +
                                      "拟合值与" +
                                      _vm.xys[1][0] +
                                      "观测值的线性回归图如下图所示。"
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm.resid && _vm.xys[0].length > 1
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.resid,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.resid +
                  " 残差序列分析"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.resid) +
                  " 残差序列分析 "
              )
            ]
          ),
          _c("div", { staticStyle: { padding: "0 15px" } }, [
            _c("div", [
              _c(
                "div",
                {
                  ref: "sub_title," + _vm.thirdIndex.resid + ",1",
                  staticClass: "forth-title",
                  attrs: {
                    id:
                      "3." +
                      _vm.secondIndex +
                      "." +
                      _vm.thirdIndex.resid +
                      ".1 正态性检验"
                  }
                },
                [
                  _vm._v(
                    " 3." +
                      _vm._s(_vm.secondIndex) +
                      "." +
                      _vm._s(_vm.thirdIndex.resid) +
                      ".1 正态性检验 "
                  )
                ]
              ),
              _c(
                "div",
                [
                  _c("Resid", {
                    ref: "content," + _vm.thirdIndex.resid + ",1",
                    attrs: {
                      resid: _vm.resid,
                      yName: _vm.xys[1][0],
                      parentType: "report"
                    },
                    on: {
                      resid_norm_p: function(val) {
                        return (_vm.resid_norm_p = val)
                      },
                      method_name: function(val) {
                        return (_vm.method_name = val)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "text1",
                          fn: function() {
                            return [
                              _c(
                                "p",
                                { ref: "text," + _vm.thirdIndex.resid + ",2" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "使用" +
                                            _vm.method_name +
                                            "算法对残差序列做正态性检验（H0：残差序列是正态的；H1：残差序列是非正态的），检验结果如下表所示："
                                        )
                                    )
                                  ])
                                ]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text2",
                          fn: function() {
                            return [
                              _c("Conclusion", [
                                _c(
                                  "p",
                                  {
                                    ref: "text," + _vm.thirdIndex.resid + ",4"
                                  },
                                  [
                                    _c("span", [_vm._v("由上表可得：")]),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$hypothesis.conclusion(
                                            _vm.resid_norm_p,
                                            _vm.a,
                                            "残差序列是正态的",
                                            "残差序列是非正态的"
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text3",
                          fn: function() {
                            return [
                              _c(
                                "p",
                                { ref: "text," + _vm.thirdIndex.resid + ",5" },
                                [_c("span", [_vm._v("残差相关图如下所示：")])]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2418656344
                    )
                  })
                ],
                1
              )
            ]),
            _vm.dw_table && _vm.xys[0].length > 1
              ? _c("div", [
                  _c(
                    "div",
                    {
                      ref: "sub_title," + _vm.thirdIndex.resid + ",7",
                      staticClass: "third-title",
                      attrs: {
                        id:
                          "3." +
                          _vm.secondIndex +
                          "." +
                          _vm.thirdIndex.resid +
                          ".2 独立性检验"
                      }
                    },
                    [
                      _vm._v(
                        " 3." +
                          _vm._s(_vm.secondIndex) +
                          "." +
                          _vm._s(_vm.thirdIndex.resid) +
                          ".2 独立性检验 "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("Resid4", {
                        ref: "content," + _vm.thirdIndex.resid + ",2",
                        attrs: { table: _vm.dw_table },
                        on: {
                          dw: function(val) {
                            return (_vm.dw = val)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text1",
                              fn: function() {
                                return [
                                  _c(
                                    "p",
                                    {
                                      ref: "text," + _vm.thirdIndex.resid + ",8"
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "对残差序列做独立性检验，检验结果如下表所示："
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text2",
                              fn: function() {
                                return [
                                  _c("Conclusion", [
                                    _c(
                                      "p",
                                      {
                                        ref:
                                          "text," + _vm.thirdIndex.resid + ",10"
                                      },
                                      [
                                        _c("span", [_vm._v("由上表可得：")]),
                                        _vm.dw >= 1.7 && _vm.dw <= 2.3
                                          ? _c("span", [
                                              _vm._v(
                                                "由于1.7 ≤ Durbin-Watson值 (" +
                                                  _vm._s(_vm.dw) +
                                                  ") ≤ 2.3，因此残差序列不存在自相关。"
                                              )
                                            ])
                                          : _vm.dw >= 1 && _vm.dw <= 1.7
                                          ? _c("span", [
                                              _vm._v(
                                                "由于1 ≤ Durbin-Watson值 (" +
                                                  _vm._s(_vm.dw) +
                                                  ") ≤ 1.7，无法判断残差序列的相关性。"
                                              )
                                            ])
                                          : _vm.dw >= 2.3 && _vm.dw <= 3
                                          ? _c("span", [
                                              _vm._v(
                                                "由于2.3 ≤ Durbin-Watson值 (" +
                                                  _vm._s(_vm.dw) +
                                                  ") ≤ 3，无法判断残差序列的相关性。"
                                              )
                                            ])
                                          : _vm.dw >= 0 && _vm.dw < 1
                                          ? _c("span", [
                                              _vm._v(
                                                "由于0 ≤ Durbin-Watson值 (" +
                                                  _vm._s(_vm.dw) +
                                                  ") < 1，因此残差序列存在正自相关。"
                                              )
                                            ])
                                          : _vm.dw > 3 && _vm.dw <= 4
                                          ? _c("span", [
                                              _vm._v(
                                                "由于3 < Durbin-Watson值 (" +
                                                  _vm._s(_vm.dw) +
                                                  ") ≤ 4，因此残差序列存在负自相关。"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4153462424
                        )
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }