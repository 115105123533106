<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'Mann-WhitneyU独立样本秩检验分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'Mann-WhitneyU独立样本秩检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['U值', 'p', 'z'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      const item = form.xys[1][0]
      if (typeof (item) === 'object') {
        return makeReq.leiXu()
      } else {
        return makeReq.leiLiang()
      }
    }
  },
  computed: {
    lei1() { return this.part2.xys[0][0][0] },
    lei2() { return this.part2.xys[0][0][1] },
    judge() { return this.part2.judge },
    judge2() { return this.part2.judge2 },
    liang() { return this.part2.xys[1][0] },
    H0() { return [`${this.lei1}的${this.liang}中位数 ${this.judge} ${this.lei2}的${this.liang}中位数`] },
    H1() { return [`${this.lei1}的${this.liang}中位数 ${this.judge2} ${this.lei2}的${this.liang}中位数`] },
    result() {
      return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0], 'Mann-WhitneyU')}`]
    }
  }
}
</script>