const document = {
  state: {
    a: 0.05,
    drop1: [
      []
    ],
    dropGroups2: [
      [
        [],
        []
      ]
    ],
    dropGroups3: [
      [
        [],
        []
      ]
    ],
    reqNumOfShouldOfReportPage: 0, // 报告页面需要加载的请求数量
    reqNumOfFactOfReportPage: 0, // 报告页面实际加载的请求数量
    errorMsgs: [], // 智能报告页面中后端报错统一往这里面提交错误消息
    catchList: [],
    data: []
  },
  mutations: {
    RESET_DROP_GROUPS2: (state, param) => {
      state.dropGroups2 = [param]
    },
    RESET_DROP_GROUPS3: (state, param) => {
      state.dropGroups3 = [param]
    },
    RESET_DROP1: (state) => {
      state.drop1 = [
        []
      ]
    },
    CHANGE_IS_REPORT_FINISHED: (state) => {
      state.isReportFinished = !state.isReportFinished
    },
    ADD_REQ_NUM_OF_SHOULD_OF_REPORT_PAGE: (state) => {
      state.reqNumOfShouldOfReportPage++
    },
    ADD_REQ_NUM_OF_FACT_OF_REPORT_PAGE: (state) => {
      state.reqNumOfFactOfReportPage++
    },
    RESET_SHOULD_AND_FACT: (state) => {
      state.reqNumOfShouldOfReportPage = 0
      state.reqNumOfFactOfReportPage = 0
    },
    UPDATE_DROP_GROUPS2: (state, param) => {
      state.dropGroups2 = param
    },
    UPDATE_DROP1: (state, param) => {
      state.drop1 = param
    },
    KEEP_ALIVE: (state) => {
      state.catchList.push('Report')
    },
    NO_KEEP_ALIVE: (state) => {
      state.catchList = []
    },
    SET_A: (state, param) => {
      state.a = param
    },
    UPDATE_DATA: (state, param) => {
      state.data = param
    },
    PUSH_ERROR_MSG: (state, param) => {
      state.errorMsgs.push(param)
    }
  },

  actions: {
    resetDropGroups2(context, param) {
      context.commit('RESET_DROP_GROUPS2', param)
    },
    resetDropGroups3(context, param) {
      context.commit('RESET_DROP_GROUPS3', param)
    },
    resetDrop1(context) {
      context.commit('RESET_DROP1')
    },
    changeIsReportFinished(context) {
      context.commit('CHANGE_IS_REPORT_FINISHED')
    },
    addReqNumOfShouldOfReportPage(context) {
      context.commit('ADD_REQ_NUM_OF_SHOULD_OF_REPORT_PAGE')
    },
    addReqNumOfFactOfReportPage(context) {
      context.commit('ADD_REQ_NUM_OF_FACT_OF_REPORT_PAGE')
    },
    resetShouldAndFact(context) {
      context.commit('RESET_SHOULD_AND_FACT')
    },
    updateDropGroups(context, param) {
      context.commit('UPDATE_DROP_GROUPS2', param)
    },
    updateDrop1(context, param) {
      context.commit('UPDATE_DROP1', param)
    },
    keep_alive(context) {
      context.commit('KEEP_ALIVE')
    },
    no_keep_alive(context) {
      context.commit('NO_KEEP_ALIVE')
    },
    set_a(context, param) {
      context.commit('SET_A', param)
    },
    update_data(context, param) {
      context.commit('UPDATE_DATA', param)
    },
    push_error_msg(context, param) {
      context.commit('PUSH_ERROR_MSG', param)
    }
  }
}

export default document