<template>
  <a-card :bordered="false" id="divQuestion">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" class="deviceC">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="文件名称">
              <a-input v-model="queryParam.name" placeholder="请输入文件名称（支持模糊查询）" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" :style="{}">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="
                  () => {
                    ;(this.queryParam = {}), $refs.table.refresh(true)
                  }
                "
                >重置</a-button
              >
            </span>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" style="float: right">
              <a-button type="primary" icon="cloud-upload" @click="handleAdd">上传文件</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table ref="table" size="default" rowKey="key" :columns="columns" :data="loadData" showPagination="auto">
      <span slot="number" slot-scope="text, record">
        <template>
          <router-link :to="`/question/AnswerList/${Base64.encode(record.id)}`">{{
            text ? Number(text) : 0
          }}</router-link>
        </template>
      </span>
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ $moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </span>
      <span slot="deleteFlag" slot-scope="text">
        <template>
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a :disabled="record.deleteFlag ? true : false" @click="handlePreview(record)" class="copyData">查看数据</a>
          <!-- <a-divider type="vertical" />
          <a>标题处理</a> -->
          <a-divider type="vertical" />
          <a @click="handleDel(record)">{{ record.control.deleteFlag ? '启用' : '删除' }}</a>
        </template>
      </span>
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { List, Delete } from '@/api/file'
import { Modal } from 'ant-design-vue'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'
// 列
const colums = [
  {
    title: '上传时间',
    dataIndex: 'control.createDate',
    scopedSlots: { customRender: 'date' },
    align: 'center',
    width: 250
  },
  {
    title: '文件名称',
    dataIndex: 'name'
  },

  {
    title: '样本量',
    dataIndex: 'count',
    align: 'center',
    width: 200
  },
  {
    title: '状态',
    dataIndex: 'control.deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' },
    align: 'center',
    width: 150
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 250
  }
]
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
export default {
  // 加载组件
  components: {
    STable
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      // 新建/编辑modal是否显示
      visible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      title: '创建量表(问卷)',
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return List({ ...requestParameters, orgid: this.$store.state.user.info.orgid, userid: this.$store.state.user.info.id, trial: this.$store.state.user.info.trial }).then(res => {
          return res
        })
      },
      // 如果有人答题则无法修改模版
      disable: false,
      qrvisible: false,
      url: '',
      Base64: Base64
    }
  },
  // 方法
  methods: {
    /**
    * 自定义高度
    */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divQuestion']
      AutoHeight(this, min, max, 176)
    },
    /**
     * 新建事件
     */
    handleAdd() {
      this.$router.push('/file/Add')
    },
    /**
     * 编辑事件
     */

    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record, 'range-picker': [record.startDate, record.endDate] }
      this.title = '编辑量表(问卷)'
      if (record.numberOfPeople && Number(record.numberOfPeople) > 0) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
    /**
     * 新建/编辑cancel事件
     */

    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },

    /**
     *启用/停用事件
     */
    handleDel(record) {
      const title = record.control.deleteFlag ? '启用' : '删除'
      Modal.confirm({
        title: '您确定' + title + '该文件吗？删除后文件不可恢复。',
        onOk: () => {
          Delete(record.id).then(res => {
            // 刷新表格
            this.$refs.table.refresh()
          })
        }
      })
    },

    handlePreview(record) {
      this.$router.push(`/file/FileData/${record.id}`)
    }

  },
  /**
   * 过滤器
   */
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }
  }
}
</script>
<style lang="less" >
.deviceC .ant-form-item {
  margin: 0px !important;
}
</style>
