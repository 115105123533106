var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(data) {
          return _c(
            "div",
            {},
            _vm._l(_vm.resultH0(data), function(item, index) {
              return _c("div", { key: index }, [
                _vm.resultH0(data).length === 3
                  ? _c("span", [_vm._v(_vm._s(index + 1) + ".")])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { domProps: { innerHTML: _vm._s(item) } })
              ])
            }),
            0
          )
        }
      },
      {
        key: "H1",
        fn: function(data) {
          return _c(
            "div",
            {},
            _vm._l(_vm.resultH1(data), function(item, index) {
              return _c("div", { key: index }, [
                _vm.resultH1(data).length === 3
                  ? _c("span", [_vm._v(_vm._s(index + 1) + ".")])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { domProps: { innerHTML: _vm._s(item) } })
              ])
            }),
            0
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }