var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "personLayout" } }, [
    _vm._m(0),
    _c("div", { attrs: { id: "container" } }, [_c("router-view")], 1),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "top" } }, [
      _c("img", { attrs: { id: "uuBigData", src: "/gr_logo2.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "footer" } }, [
      _c("div", { staticClass: "links" }, [
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
          [_vm._v("苏ICP备11090926号-4")]
        )
      ]),
      _c("div", { staticClass: "copyright" }, [
        _c("div", { staticClass: "copyright" }, [
          _vm._v("Copyright © 2021 无锡市优特科科技有限公司")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }