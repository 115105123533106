<template>
  <a-row style=" border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'描述性分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="part2.table.data" :columns="part2.table.columns" :title="'ANOVA'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'单因素方差智能分析结果'">
              <div>
                <div v-for="(item,index) in result" :key="index">
                  <span v-html="item">{{ item }}</span>
                </div>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      method2: this.$hypothesis.setColumns.jiaoCha,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']],
        table: {}
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, [''], this.setSecondTable)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.leiLiang()
    },
    setSecondTable(res) {
      var columns = res.part2.table.columns
      this.method2(columns, res.part2.table.data.length)
    }
  },
  computed: {
     options() {
      return this.part2.xys[0][0].map(v => `&lt;${v}&gt;`).join(' ')
    },
    xys() { return this.part2.xys },
    lei() { return this.part2.lei },
    H0() { return [`${this.options}${this.xys[0][0].length}组样本&lt;${this.xys[1][0]}&gt;的总体均值相等`] },
    H1() { return [`${this.options}${this.xys[0][0].length}组样本&lt;${this.xys[1][0]}&gt;的总体均值不等或不全相等`] },
    result() { return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0])}`] }
  }
}
</script>