import request from '@/utils/request'

const api = {
  getCard: '/Statistics/GetCard',
  Chart: '/Statistics/GetChart',
  VeRing: '/Statistics/GetVeRing'

}
/**
 *卡片信息
 *
 * @export
 * @param {*} parameter
 * @returns
 */
export function getCard(parameter) {
  return request({
    url: api.getCard,
    method: 'post',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function getVeRing(parameter) {
  return request({
    url: api.VeRing,
    method: 'post',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function getCapList(parameter) {
  return request({
    url: api.Cap,
    method: 'post',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 *获取图表信息
 *
 * @export
 * @param {*} parameter
 * @returns
 */
export function getChartInfo(parameter) {
  return request({
    url: api.Chart,
    method: 'post',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// export function getDocumetList(parameter) {
//   return request({
//     url: api.documet,
//     method: 'post',
//     data: parameter
//   })
// }

// export function getDocument(parameter) {
//   return request({
//     url: api.get + '/' + parameter,
//     method: 'get'
//   })
// }

// export function createDocument(parameter) {
//   return request({
//     url: api.post,
//     method: 'post',
//     data: parameter
//   })
// }

// export function updateDocument(parameter) {
//   return request({
//     url: api.put + '/' + parameter.id,
//     method: 'put',
//     data: parameter
//   })
// }