<template>
  <div class="leftWidthHypo" id="colMenu">
    <div style="margin-top: 10px">
      <a-divider>实验</a-divider>
      <a-row v-for="(item, index) in menus.filter((r) => r.type === 'exp')" :key="index" type="flex" justify="center">
        <a-col :span="20">
          <div :class="{ item: true, active: item.state }" @click="clickIcon(item)">
            {{ item.name }}
          </div>
        </a-col>
      </a-row>
    </div>
    <a-divider>公共</a-divider>
    <div style="margin-top: 10px">
      <a-row
        v-for="(item, index) in menus.filter((r) => r.type === 'device')"
        :key="index"
        type="flex"
        justify="center"
      >
        <a-col :span="20">
          <div :class="{ item: true, active: item.state }" @click="clickIcon(item)">
            {{ item.name }}
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var menus = []
    this.$store.state.user.info.orgExps.map((r, i) => {
      menus.push({
        guid: r.guid,
        name: r.name,
        id: r.id,
        state: false, // 是否选中
        type: 'exp'
      })
    })
    menus.push({
      guid: 'device',
      name: '设备管理',
      state: false, // 是否选中
      type: 'device'
    })
    return {
      menus
    }
  },
  methods: {
    clickIcon(item) {
      this.menus.forEach(r => {
        r.state = false
      })
      item.state = true
      switch (item.guid) {
        case '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb':
          this.$router.push(`/experiment/Exp1/${item.id}`)
          break
        case '61bd643e-a81a-439b-94d6-61dac11a1b66':
          this.$router.push(`/experiment/Exp2/${item.id}`)
          break
        case 'c7288ec2-8468-41e9-8898-d5cbb83e8b8d':
          this.$router.push('/experiment/Exp3')
          break
        case 'device':
          this.$router.push('/experiment/Device')
          break
      }
    }
  },
  mounted() {
    // 刷新页面时，menus状态
    const pathname = window.location.pathname
    if (pathname.indexOf('Exp1') > -1) {
      const menu = this.menus.filter(r => r.guid === '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb')
      if (menu.length > 0) {
        menu[0].state = true
      }
    } else if (pathname.indexOf('Exp2') > -1) {
      const menu = this.menus.filter(r => r.guid === '28862694-e323-428a-aa13-367c7fef01c8')
      if (menu.length > 0) {
        menu[0].state = true
      }
    } else if (pathname.indexOf('Exp3') > -1) {
      const menu = this.menus.filter(r => r.guid === 'c7288ec2-8468-41e9-8898-d5cbb83e8b8d')
      if (menu.length > 0) {
        menu[0].state = true
      }
    } else if (pathname.indexOf('Device') > -1) {
      const menu = this.menus.filter(r => r.name === '设备管理')
      if (menu.length > 0) {
        menu[0].state = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border: 1px solid #dadada;
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.active {
  color: white;
  background-color: rgb(24, 144, 255);
}
.leftWidthHypo {
  overflow: auto;
}
</style>