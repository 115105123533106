var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "antd-pro-components-article-list-content-index-listContent"
    },
    [
      _c(
        "div",
        { staticClass: "description" },
        [_vm._t("default", [_vm._v(" " + _vm._s(_vm.description) + " ")])],
        2
      ),
      _c(
        "div",
        { staticClass: "extra" },
        [
          _c("a-avatar", { attrs: { src: _vm.avatar, size: "small" } }),
          _c("a", { attrs: { href: _vm.href } }, [_vm._v(_vm._s(_vm.owner))]),
          _vm._v(" 发布在 "),
          _c("a", { attrs: { href: _vm.href } }, [_vm._v(_vm._s(_vm.href))]),
          _c("em", [_vm._v(_vm._s(_vm._f("moment")(_vm.updateAt)))])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }