<template>
  <div>
    <div class="third-module">
      <slot name="text1"> </slot>
    </div>
    <div class="third-module">
      <a-table
        ref="tables,9"
        v-if="table"
        :showHeader="table.showHeader"
        :pagination="false"
        :dataSource="table.data"
        :columns="table.columns"
        size="small"
      >
        <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="text2"></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Resid4',
  props: ['table'],
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  created() {
    // 保证tables的格式，以便于导出word文档用
    this.tables.push(this.table)
    // 获取传过来的dw表格中的dw值，并且传递出去
    const dw = +this.table.data[0].value2
    this.$emit('dw', dw)
  }
}
</script>
