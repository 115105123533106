var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          ref: "title," + _vm.thirdIndex.normIndex,
          staticClass: "third-title",
          attrs: {
            id:
              "3." +
              _vm.secondIndex +
              "." +
              _vm.thirdIndex.normIndex +
              " 正态性检验"
          }
        },
        [
          _vm._v(
            " 3." +
              _vm._s(_vm.secondIndex) +
              "." +
              _vm._s(_vm.thirdIndex.normIndex) +
              " 正态性检验 "
          )
        ]
      ),
      _c(
        "div",
        [
          _c("Norm", {
            ref: "content," + _vm.thirdIndex.normIndex,
            attrs: {
              xys: _vm.xys,
              data: _vm.data,
              lei: _vm.data.lei,
              settings: _vm.settings.options.methods.find(function(v) {
                return v.name === "正态检验"
              })
            },
            on: {
              isNorm: function(val) {
                return (_vm.isNorm = val)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "text1",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.normIndex + ",1" },
                      [
                        _vm._v(
                          " 对" + _vm._s(_vm.text_label) + "做正态性检验，"
                        ),
                        _c("br"),
                        _c("span", { staticClass: "H0H1" }, [
                          _vm._v(
                            "H0：" + _vm._s(_vm.text_label) + "服从正态分布。"
                          )
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "H0H1" }, [
                          _vm._v(
                            "H1：" + _vm._s(_vm.text_label) + "不服从正态分布。"
                          )
                        ]),
                        _c("br"),
                        _vm._v("检验结果如下表所示： ")
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "text2",
                fn: function(ref) {
                  var text = ref.text
                  return [
                    _c(
                      "Conclusion",
                      _vm._l(text, function(pText, index) {
                        return _c(
                          "p",
                          {
                            key: index,
                            ref: "text," + _vm.thirdIndex.normIndex + ",3",
                            refInFor: true
                          },
                          [
                            _c(
                              "span",
                              { domProps: { innerHTML: _vm._s(pText) } },
                              [_vm._v(_vm._s(pText))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                }
              },
              {
                key: "text3",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.normIndex + ",4" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.lei === "变量"
                                ? _vm.liang1 + "和" + _vm.liang2
                                : _vm.liang
                            ) +
                            "的PP图和QQ图如下图所示： "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm.isNorm.length > 0 &&
    (_vm.flow_position_now === "auto"
      ? _vm.isNorm[0] >= _vm.a
      : _vm.flow_position_now !== "none_param")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.homogeneousIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.homogeneousIndex +
                  " 方差齐性检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.homogeneousIndex) +
                  " 方差齐性检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("HomogeneityOfVariances", {
                ref: "content," + _vm.thirdIndex.homogeneousIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  isNorm: _vm.isNorm,
                  settings: _vm.settings.options.methods.find(function(v) {
                    return v.name === "方差齐性检验"
                  })
                },
                on: {
                  isHomogeneous: function(val) {
                    return (_vm.isHomogeneous = val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref:
                              "text," + _vm.thirdIndex.homogeneousIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("方差齐性检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref:
                                  "text," +
                                  _vm.thirdIndex.homogeneousIndex +
                                  ",3"
                              },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.isHomogeneous,
                                        _vm.a,
                                        _vm.text_label + "方差齐",
                                        _vm.text_label + "方差不齐"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2539021517
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isHomogeneous.length > 0 &&
    (_vm.flow_position_now === "auto"
      ? _vm.is_small_sample()
      : _vm.flow_position_now === "t")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.tIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.tIndex +
                  " t检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.tIndex) +
                  " t检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("T", {
                ref: "content," + _vm.thirdIndex.tIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  isHomogeneous: _vm.isHomogeneous
                },
                on: {
                  isMeanEqual: function(val) {
                    return (_vm.isMeanEqual = val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.tIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("t检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              { ref: "text," + _vm.thirdIndex.tIndex + ",3" },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.isMeanEqual,
                                        _vm.a,
                                        _vm.text_label + "均值相等",
                                        _vm.text_label + "均值不相等"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  484763191
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isHomogeneous.length > 0 &&
    (_vm.flow_position_now === "auto"
      ? !_vm.is_small_sample()
      : _vm.flow_position_now === "z")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.zIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.zIndex +
                  " z检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.zIndex) +
                  " z检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("Z", {
                ref: "content," + _vm.thirdIndex.zIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  isHomogeneous: _vm.isHomogeneous
                },
                on: {
                  isMeanEqual: function(val) {
                    return (_vm.isMeanEqual = val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.zIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("z检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              { ref: "text," + _vm.thirdIndex.zIndex + ",3" },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.isMeanEqual,
                                        _vm.a,
                                        _vm.text_label + "均值相等",
                                        _vm.text_label + "均值不相等"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2131792569
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isNorm.length > 0 &&
    (_vm.flow_position_now === "auto"
      ? _vm.isNorm[0] < _vm.a
      : _vm.flow_position_now === "none_param")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.noneParamIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.noneParamIndex +
                  " 非参数检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.noneParamIndex) +
                  " 非参数检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("NoneParam", {
                ref: "content," + _vm.thirdIndex.noneParamIndex,
                attrs: { data: _vm.data, lei: _vm.data.lei },
                on: {
                  noneParamIsDiff: function(x) {
                    return (_vm.noneParamIsDiff = x)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.noneParamIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("非参数检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref:
                                  "text," + _vm.thirdIndex.noneParamIndex + ",3"
                              },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.noneParamIsDiff,
                                        _vm.a,
                                        _vm.text_label + "间差异不显著",
                                        _vm.text_label + "间差异显著"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  432630572
                )
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }