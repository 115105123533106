import { onChange, onClose } from '@/views/hypothesisTest/utils/inputItemsClass'
var xysName = 'cfclfcfxXys'

export const settings = [
  {
    label: '测量项命名',
    prop: 'liang2',
    parentValue: 1,
    placeholder: '如针对BMI进行了3次重复测量，且拖入了这3次测量结果数据，则可以命名为“BMI”',
    type: 'input',
    rules() { return [{ required: true, message: this.label + '不能为空', trigger: 'blur' }] },
    value: '',
    options: [],
    children: []
  },
  {
    id: 1,
    prop: 'liang1',
    label: '各时间段测量值【定量】',
    parentValue: 1,
    type: 'drop',
    dropType: ['量'],
    min: 2,
    num: 10,
    value: '',
    options: [],
    list: [],
    divHelp: ['请拖入同一观察或记录指标重复进行多次测量的结果：(个数 ≥ 2个)'],
    children: [],
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  }
]