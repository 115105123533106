<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index">
        <a-table
          ref="tables,2"
          v-if="table"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          :settings="table.settings"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
          <!-- <template #title>
            <div style="text-align: center">不同{{ lei }}对{{ Object.keys(data.data1)[index] }}的方差分析</div>
          </template> -->
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
export function get_text_anova(value, name_of_sample, flow_value, isHomogeneous, secondIndex, thirdIndex, a) {
  /** 获取方差齐性检验中文字的描述
   * @param value 当前进行的检验的值
   * @param name_of_sample 样本的名字，例如“不同文化程度中年龄的样本”
   * @param flow_value 流程的值
   * @param isNorm 正态检验的p值
   */
  const index_of_norm = `3.${secondIndex}.${thirdIndex.normIndex}`
  const index_of_homogeneousIndex = `3.${secondIndex}.${thirdIndex.homogeneousIndex}`
  const text_end = `<br/><span class="H0H1">H0：${name_of_sample}间差异不显著。</span><br/><span class="H0H1">H1：${name_of_sample}间差异显著。</span><br>检验结果如下表所示：`
  let text
  if (value !== 'auto') {
    text = `采用${value}算法进行方差分析，`
  } else {
    if (flow_value === 'auto') {
      if (isHomogeneous >= a) {
        text = `由${index_of_norm}和${index_of_homogeneousIndex}可知：${name_of_sample}服从正态分布且方差齐，因此采用Anova进行方差分析，`
      } else {
        text = `由${index_of_norm}和${index_of_homogeneousIndex}可知：${name_of_sample}服从正态分布且方差不齐，因此采用Welch进行方差分析，`
      }
    } else if (flow_value === 'anova') {
      if (isHomogeneous >= a) {
        text = `由${index_of_homogeneousIndex}可知：${name_of_sample}方差齐，因此采用Anova进行方差分析，`
      } else {
        text = `由${index_of_homogeneousIndex}可知：${name_of_sample}方差不齐，因此采用Welch进行方差分析，`
      }
    }
  }
  return text + text_end
}
export default {
  props: ['data', 'lei', 'isHomogeneous', 'settings'],
  data() {
    return {
      imgs: [],
      tables: []
    }
  },
  created() {
    const { data, lei, isHomogeneous, a } = this
    // 把settings里的配置添加到data里去。
    this.data.method = this.settings.args[0].value
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'Anova', data, lei, isHomogeneous, a }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '方差分析',
          msg: res
        })
      } else {
        // 传递method出去
        const method = res.method
        this.$emit('saveMethod', method)
        const tables = res.tables
        // 传递P值出去
        const ary = []
        tables.forEach((table, index) => {
          if (table) {
            for (let i = 4; i < 6; i++) {
              table.columns[i].customRender = (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if (index === 0) {
                  obj.attrs.rowSpan = table.data.length
                } else {
                  obj.attrs.rowSpan = 0
                }
                return obj
              }
            }
            ary.push('p' in table.data[0] ? table.data[0].p : table.data[0]['p-unc'])
          } else {
            ary.push(null)
          }
        })
        this.$emit('isDiff', ary)
        this.getMergeSetting(tables)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  methods: {
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      if (table_row_num > 1) {
        tables[0].settings = [[], []]
        const columnAry = [4, 5]
        columnAry.forEach((v, i) => {
          for (let index = 1; index <= table_row_num; index++) {
            tables[0].settings[i].push([index, v])
          }
        })
      }
    }
  },
  computed: {
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>