var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "card-wrapper" } },
    [
      _c(
        "a-row",
        {
          attrs: { id: "top", type: "flex", justify: "center", align: "middle" }
        },
        [
          _c("a-col", { staticStyle: { "font-size": "15px" } }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            id: "content",
            type: "flex",
            justify: "center",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            [
              _c(
                "a-row",
                { staticStyle: { "padding-bottom": "10px" } },
                [
                  _c("a-col", { attrs: { align: "center" } }, [
                    _vm._v(" ¥"),
                    _c("span", { staticStyle: { "font-size": "22px" } }, [
                      _vm._v(_vm._s(_vm.price))
                    ]),
                    _vm._v("/" + _vm._s(_vm.unit) + " ")
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { padding: "5px 25px" },
                          attrs: { type: "primary", disabled: "" }
                        },
                        [_vm._v(" 立即抢购")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }