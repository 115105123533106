var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }