<template>
  <div>
    <loading v-if="loadingController"></loading>
    <div style="padding: 50px 0 0 0">
      <div
        style="position: fixed; top: 0px; width: 100%; height: 50px; background: #001529; z-index: 500; opacity: 0.5"
      >
        <a-row type="flex" justify="end">
          <a-col class="title" style="margin-right: 20px">
            <a-button
              v-print="'#PrintContent'"
              style="font-weight: bold; color: black; margin-top: 10px"
            >打印</a-button
            >
            <a-button @click="cancel" style="margin-left: 10px; color: black; font-weight: bold">取消</a-button>
          </a-col>
        </a-row>
      </div>
      <div id="PrintContent">
        <div style="background: #dedede">
          <div class="pagediv">
            <a-table
              class="base recordTable"
              size="small"
              :columns="columns1"
              :data-source="data1"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template
                slot="title"
              ><span style="font-size: 20px; font-weight: 600">精密度验证数据记录表</span>
              </template>
            </a-table>
            <a-table
              class="dataTable recordTable"
              size="small"
              :columns="columns2"
              :data-source="data2"
              :pagination="false"
              bordered
            >
              <template slot="title">
                <a-row>
                  <a-col :span="24">
                    <span
                      :class="{ concentration: true, active: currentConcentrationIndex === index }"
                      v-for="(concentration, index) in sampleConcentrations"
                      :key="index"
                      @click="
                        currentConcentrationIndex = index
                        fetch()
                      "
                    >
                      {{ concentration }}%浓度
                    </span>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col
                    :span="24"
                    style="font-weight: 700; background-color: #f7f7f7; border-top: 1px solid #999999"
                  >数据</a-col
                  >
                </a-row>
              </template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns3"
              :data-source="data3"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title">计算</template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns4"
              :data-source="data4"
              :showHeader="false"
              :pagination="false"
              bordered
            >
            </a-table>
          </div>
          <div class="pagediv">
            <a-table
              class="recordTable"
              size="small"
              :columns="columns5"
              :data-source="data5"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title"> 结果判定 </template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns6"
              :data-source="data6"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title"> 结论 </template>
              <template slot="key" slot-scope="text, record, index">
                <span v-if="index === 2" style="font-weight: 700">{{ text }}</span>
                <span v-else>{{ text }}</span>
              </template>
              <template slot="value" slot-scope="text, record, index">
                <span v-if="index === 2" style="font-weight: 700; color: #43c73d">{{ text }}</span>
                <span v-else>{{ text }}</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCPoint } from '@/api/exp/python'
import { List } from '@/api/exp/experimentData'
import { getByID } from '@/api/exp/experiment'
import Loading from '../../components/loading'
const columnWidth = 150
const map = {
  批均值: 0,
  批标准差: 1,
  方差: 2,
  总均值: 3,
  批内方差: 4,
  批内标准差: 5,
  批间标准差: 6,
  批间方差: 7,
  VrVb比值: 8,
  总方差: 9,
  实验室内标准差: 10,
  实验室变异系数: 11
}
export default {
  // props: ['visible', 'exp'],
  components: { Loading },
  created() {
    // this.exp = JSON.parse(this.$route.query.exp)
    const id = Number(this.$route.query.id)
    List({ expID: id }).then(res => {
      const list = res.data
      getByID(id).then(res => {
        console.log('%c 🍏 res: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', res)
        this.exp = res
        this.exp.list = list
        this.fetch()
      })
    })
    // this.exp = this.$route.query.exp
  },
  data() {
    return {
      loadingController: true,
      currentConcentrationIndex: 0, // 当前选中浓度的下标
      columns1: [],
      data1: [],
      columns2: [],
      data2: [],
      columns3: [],
      data3: [],
      columns4: [],
      data4: [],
      columns5: [],
      data5: [],
      columns6: [],
      data6: [],
      exp: {}
    }
  },
  computed: {
    sampleConcentrations() {
      if (JSON.stringify(this.exp) !== '{}') return JSON.parse(this.exp.sampleConcentrations)
      return []
    }
  },
  methods: {
    fetch() {
      this.columns1 = [
        {
          title: 'key1',
          key: 'key1',
          dataIndex: 'key1',
          align: 'center'
        },
        {
          title: 'value1',
          key: 'value1',
          dataIndex: 'value1',
          align: 'center'
        },
        {
          title: 'key2',
          key: 'key2',
          dataIndex: 'key2',
          align: 'center'
        },
        {
          title: 'value2',
          key: 'value2',
          dataIndex: 'value2',
          align: 'center'
        },
        {
          title: 'key3',
          key: 'key3',
          dataIndex: 'key3',
          align: 'center'
        },
        {
          title: 'value3',
          key: 'value3',
          dataIndex: 'value3',
          align: 'center'
        }
      ]
      this.data1 = []
      this.columns2 = [{
        title: '',
        key: '',
        dataIndex: 'col_title',
        align: 'center',
        width: columnWidth
      }]
      this.data2 = []
      this.columns3 = [{
        title: '',
        key: '',
        dataIndex: 'col_title',
        align: 'center',
        width: columnWidth
      }]
      this.data3 = []
      this.columns4 = [{
        title: 'key1',
        key: 'key1',
        dataIndex: 'key1',
        align: 'center'
      },
      {
        title: 'value1',
        key: 'value1',
        dataIndex: 'value1',
        align: 'center'
      },
      {
        title: 'key2',
        key: 'key2',
        dataIndex: 'key2',
        align: 'center'
      },
      {
        title: 'value2',
        key: 'value2',
        dataIndex: 'value2',
        align: 'center'
      }]
      this.data4 = []
      this.columns5 = [{
        title: 'left',
        key: 0,
        dataIndex: 'left',
        customRender: (text, record, index) => {
          return <span style="white-space:normal !important">{text}</span>
        }
      },
      {
        title: 'right',
        key: 1,
        dataIndex: 'right',
        customRender: (text, record, index) => {
          return <span style="white-space:normal !important">{text}</span>
        }
      }]
      this.data5 = [{
        key: 0,
        left: '如果CVr≤规定重复CV，则验证声称规定的重复精密度可靠；若大于，再将CVr与验证值进行比较，小于验证值，声称的重复精密度可靠。',
        right: '如果CVl≤规定的批间CV，则验证声称规定的期间精密度可靠；若大于，再将CVl与验证值进行比较，小于验证值，声称的期间精密度可靠。'
      }]
      this.columns6 = [{
        title: 'key1',
        key: 'key1',
        dataIndex: 'key1',
        align: 'center',
        scopedSlots: { customRender: 'key' }
      },
      {
        title: 'value1',
        key: 'value1',
        dataIndex: 'value1',
        align: 'center',
        scopedSlots: { customRender: 'value' }
      },
      {
        title: 'key2',
        key: 'key2',
        dataIndex: 'key2',
        align: 'center',
        scopedSlots: { customRender: 'key' }
      },
      {
        title: 'value2',
        key: 'value2',
        dataIndex: 'value2',
        align: 'center',
        scopedSlots: { customRender: 'value' }
      }]
      this.data6 = [{
        key: 0,
        key1: 'CVr与CV1比较',
        value1: '',
        key2: 'CVl与CV2比较',
        value2: this.CVl <= this.CV2 ? 'OK' : 'NO'
      },
      {
        key: 1,
        key1: 'CVr与验证值比较',
        value1: '',
        key2: 'CVl与验证值比较',
        value2: ''
      },
      {
        key: 2,
        key1: '重复精密度验证',
        value1: '',
        key2: '期间精密度验证',
        value2: ''
      }
      ]
      this.fetchData1()
      this.fetchColumns2()
      this.fetchData2()
      this.fetchColumns3()
      this.fetchData3()
      this.fetchData4()
      this.loadingController = false
    },
    cancel() {
      // this.$router.back()
      window.close()
    },
    // 生成表格所需要的列数，”第1批，第2批“
    getColumns(tableIndex) {
      this.exp.list.forEach((v, index) => {
        this[`columns${tableIndex}`].push({
          title: `第${index + 1}批`,
          key: `第${index + 1}批`,
          dataIndex: `batch${index}`,
          align: 'center'
        })
      })
    },
    // 求批均值、批方差的平均值的公共方法
    avg(obj) {
      const day = this.exp.list.length
      let sum = 0
      for (const i in obj) {
        if (i !== 'key' && i !== 'col_title') {
          sum += Number(obj[i])
        }
      }
      return sum / day
    },
    fetchData1() {
      const exp = this.exp
      this.data1 = [
        {
          key: 0,
          key1: '试剂厂家：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.manufactor).join('、'),
          key2: '仪器：',
          value2: exp.devices.map(v => v.name).join('、'),
          key3: '记录编号：',
          value3: exp.serialNum
        },
        {
          key: 1,
          key1: '项目名称：',
          value1: exp.expName,
          key2: '校准品名称：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.name).join('、'),
          key3: '实验时间：',
          value3: `${this.$moment(exp.list[exp.list.length - 1].date).format('YYYY-MM-DD')}至${this.$moment(exp.list[0].date).format('YYYY-MM-DD')}`
        },
        {
          key: 2,
          key1: '试剂批号：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.batchNum).join('、'),
          key2: '校准品批号：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.batchNum).join('、'),
          key3: '实验人员：',
          value3: exp.users.map(v => v.name).join('、')
        }
      ]
    },
    fetchColumns2() {
      this.getColumns(2)
      // this.columns2.forEach(v=>{})
    },
    fetchData2() {
      // 遍历实验的重复次数给表格添加”重复1、重复2....那一列“
      for (let index = 0; index < this.exp.repeatNum; index++) {
        const obj = {
          key: index,
          col_title: `重复${index + 1}`
        }
        this.data2.push(obj)
      }
      // 给表格添加剩余的数据
      this.exp.list.forEach((v, index) => {
        const data = JSON.parse(v.dataDetails[this.currentConcentrationIndex].concentrationValue)
        data.forEach((m, i) => {
          this.data2[i][`batch${index}`] = m
        })
      })
    },
    fetchColumns3() {
      this.getColumns(3)
    },
    fetchData3() {
      // eslint-disable-next-line no-unused-vars
      for (const i in map) {
        this.data3.push({})
      }
      for (const i in map) {
        this.data3[map[i]].key = map[i]
        this.data3[map[i]].col_title = i
      }
      const n = this.exp.repeatNum
      const day = this.exp.list.length
      this.exp.list.forEach((v, index) => {
        const data = JSON.parse(v.dataDetails[this.currentConcentrationIndex].concentrationValue)
        const key = `batch${index}`
        // 求和
        const sum = data.reduce((a, b) => a + b, 0)
        // 批均值
        const batchAvg = (sum / n)
        this.data3[map.批均值][key] = batchAvg.toFixed(2)
        // 批标准差
        const batchStd = Math.sqrt((data.map(v => Math.pow(v - batchAvg, 2)).reduce((a, b) => a + b, 0)) / (n - 1))
        this.data3[map.批标准差][key] = batchStd.toFixed(3)
        // 方差
        const batchMean = Math.pow(batchStd, 2)
        this.data3[map.方差][key] = batchMean.toFixed(3)
      })
      // 总均值
      const totalAvg = this.avg(this.data3[map.批均值])
      // 批内方差
      const totalMean = this.avg(this.data3[map.方差])
      // 批内标准差
      const totalStd = Math.sqrt(totalMean)
      // 批间标准差
      let sum = 0
      const obj = this.data3[map.批均值]
      for (const i in obj) {
        if (i !== 'key' && i !== 'col_title') {
          sum += Math.pow(obj[i] - totalAvg, 2)
        }
      }
      const totalStd2 = Math.sqrt(sum / (day - 1))
      // 批间方差
      const totalMean2 = Math.pow(totalStd2, 2)
      // Vr/Vb比值
      const VrVb = totalMean2 === 0 ? '批间方差为0，无法计算' : (totalMean / totalMean2)
      // 总方差
      const totalMean3 = (((n - 1) / n) * totalMean + totalMean2)
      // 实验室内标准差
      const expStd = Math.sqrt(totalMean3)
      // 实验室变异系数
      const expCV = ((expStd / totalAvg) * 100)
      this.data3.forEach(v => {
        const map2 = {
          总均值: totalAvg,
          批内方差: totalMean,
          批内标准差: totalStd,
          批间标准差: totalStd2,
          批间方差: totalMean2,
          VrVb比值: VrVb,
          总方差: totalMean3,
          实验室内标准差: expStd,
          实验室变异系数: expCV
        }
        if (v.col_title in map2) {
          for (let index = 0; index < day; index++) {
            v[`batch${index}`] = typeof (map2[v.col_title]) === 'string' ? map2[v.col_title] : map2[v.col_title].toFixed(3)
          }
        }
      })
      // 合并单元格
      this.columns3[1].customRender = (text, row, index) => {
        if (index > 2) {
          return {
            children: text,
            attrs: {
              colSpan: day
            }
          }
        } else {
          return text
        }
      }
      for (let i = 2; i < day + 1; i++) {
        this.columns3[i].customRender = (text, row, index) => {
          if (index > 2) {
            return {
              children: text,
              attrs: {
                colSpan: 0
              }
            }
          } else {
            return text
          }
        }
      }
    },
    fetchData4() {
      const day = this.exp.list.length
      const n = this.exp.repeatNum
      const totalAvg = Number(this.data3[3].batch0)
      const Vb = Number(this.data3[7].batch0)
      const CV1 = this.exp.cV1 // 规定重复CV1
      const CV2 = this.exp.cV2 // 规定期间CV2
      // 测定重复精密度Sr
      const obj = this.data3[map.批标准差]
      let sum = 0
      for (const i in obj) {
        if (i !== 'key' && i !== 'col_title') {
          sum += Math.pow(Number(obj[i]), 2)
        }
      }
      const Sr = Math.sqrt(sum / day)
      // 测定期间精密度Sl
      const Sl = Math.sqrt(((n - 1) / n) * Math.pow(Sr, 2) + Vb)
      // 测定重复精密度CVr
      const CVr = Sr / totalAvg
      // 测定期间精密度CVl
      const CVl = Sl / totalAvg
      // 自由度T
      const T = (Math.pow((n - 1) * Math.pow(Sr, 2) + n * Vb, 2)) / (((n - 1) / day) * Math.pow(Sr, 4) + ((Math.pow(n, 2) * Math.pow(Vb, 2)) / (day - 1)))
      // C点
      getCPoint({ level: 2, T }).then(res => {
        const C = res.data
        const check1 = CV1 * (Math.sqrt(C) / Math.sqrt(T))
        const check2 = CV2 * (Math.sqrt(C) / Math.sqrt(T))
        // 给判定结果赋值
        this.data6[0].value1 = CVr <= CV1 ? 'OK' : 'NO'
        this.data6[0].value2 = CVl <= CV2 ? 'OK' : 'NO'
        this.data6[1].value1 = CVr <= check1 ? 'OK' : 'NO'
        this.data6[1].value2 = CVl <= check2 ? 'OK' : 'NO'
        this.data6[2].value1 = this.data6[1].value1 === 'OK' ? '合格' : '不合格'
        this.data6[2].value2 = this.data6[1].value2 === 'OK' ? '合格' : '不合格'
        this.data4 = [
          {
            key: 0,
            key1: '规定重复CV1',
            value1: (CV1 * 100).toFixed(1) + '' + '%',
            key2: '规定期间CV2',
            value2: (CV2 * 100).toFixed(1) + '' + '%'
          },
          {
            key: 1,
            key1: '测定重复精密度Sr',
            value1: Sr.toFixed(3),
            key2: '测定期间精密度Sl',
            value2: Sl.toFixed(3)
          },
          {
            key: 2,
            key1: '测定重复精密度CVr',
            value1: (CVr * 100).toFixed(1) + '' + '%',
            key2: '测定期间精密度CVl',
            value2: (CVl * 100).toFixed(1) + '' + '%'
          },
          {
            key: 3,
            key1: 'C',
            value1: C.toFixed(3),
            key2: '自由度T',
            value2: T.toFixed(3)
          },
          {
            key: 4,
            key1: '验证值',
            value1: (check1 * 100).toFixed(1) + '' + '%',
            key2: '验证值',
            value2: (check2 * 100).toFixed(1) + '' + '%'
          }
        ]
      })
    }
  }
}
</script>
<style lang="less">
@boder:1px solid #999999 !important;
.base .ant-table-title {
  text-align: center !important;
}
// .ant-table-title {
//   top: 0;
// }
.concentration {
  font-weight: 700;
  padding: 6px 5px;
  margin-right: 10px;
  cursor: pointer;
}
.active {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.pagediv {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  background: white;
}
.dataTable .ant-table-title {
  padding: 0 0 !important;
  .ant-col {
    padding: 8px 16px;
  }
}
.recordTable{
  td{
  border-right:@boder;
  border-bottom:@boder
}
  td:last-child{
      border-right:0 !important;
  }
  th{
    border-bottom: @boder;
    border-right:@boder
  }
  th:last-child{
      border-right:0 !important;
  }
  .ant-table-title{
   font-weight: 700;
   background-color: #f7f7f7;
   border-right:@boder;
   border-bottom:@boder;
  //  border-top:@boder;
   border-radius: 0;
  }
  .ant-table-content{
    border-right:@boder;
  }
  .ant-table{
    border-top: @boder;
    border-left:@boder;
    // border-bottom:@boder;
    border-radius:0
  }
}
@page {
  size: auto A4 landscape;
  margin: 5mm;
}
</style>