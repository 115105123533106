<template>
  <page-header-wrapper :breadcrumb="null">
    <template v-slot:extra>
      <sample></sample>
    </template>
    <loading v-show="loadingController"></loading>
    <a-layout class="divContent" id="divContent">
      <a-row style="width: 100% !important">
        <a-col :span="5">
          <method></method>
        </a-col>
        <a-col :span="19" style="overflow: auto; border-left: 1px solid rgb(232, 232, 232)" id="resultContent">
          <component :is="currentComponent" @hideLoading="loadingController = false" :key="key"></component>
        </a-col>
      </a-row>
    </a-layout>
  </page-header-wrapper>
</template>
<script>
import Method from './components/method'
import Dybtjy from './components/position/dybtjy/result'
import Dlybtjy from './components/position/dlybtjy/result'
import Pdybtjy from './components/position/pdybtjy/result'
import Z from './components/position/z/result'
import Kffx from './components/correlation/kffx/result'
import Dybksjy from './components/distribution/dybksjy/result'
import Sybksjy from './components/distribution/sybksjy/result'
import Fcqxfx from './components/distribution/fcqxfx/result'
import Ztjy from './components/distribution/ztjy/result'
import Danysfcfx from './components/anova/danysfcfx/result'
import Anderson from './components/distribution/anderson/result'
import Kfjy from './components/divergence/kfjy/result'
import F from './components/divergence/f/result'
import Sysfcfx from './components/anova/sysfcfx/result'
import Duoysfcfx from './components/anova/duoysfcfx/result'
import Cfclfcfx from './components/anova/cfclfcfx/result'
import Dcbjjy from './components/anova/dcbjjy/result'
import Pdybzjy from './components/non-parametric/pdybzjy/result'
import Dlybzjy from './components/non-parametric/dlybzjy/result'
import Dybzjy from './components/non-parametric/dybzjy/result'
import Cochran from './components/non-parametric/cochran/result'
import Friedman from './components/non-parametric/friedman/result'
import Welch from './components/anova/welch/result'
import Mixed from './components/anova/mixed/result'
import GamesHowell from './components/anova/gamesHowell/result'
import Dybbljy from './components/proportion/dybbljy/result'
import Sybbljy from './components/proportion/sybbljy/result'
import Mcnemar from './components/correlation/mcnemar/result'
import Fitting from './components/correlation/fitting/result.vue'
import Pearson from './components/correlationNew/Pearson/result.vue'
import Kendall from './components/correlationNew/Kendall/result.vue'
import Spearman from './components/correlationNew/Spearman/result.vue'
import Phi from './components/correlationNew/Phi/result.vue'
import Loading from './components/loading'
import { AutoHeight } from '@/utils/util'
import Sample from '@/views/customchart/components/sample.vue'
export default {
  name: 'ResultIndex',
  created() {
    this.autoHeightChange()
    console.log(this.$store.state.hypothesis.method)
    // 向sessionstorage里存数据
    const form = this.$store.state.hypothesis.dybtjy
    const method = this.$store.state.hypothesis.method
    if (JSON.stringify(form) !== '{}' && method) {
      sessionStorage.setItem('form', JSON.stringify(form))
      sessionStorage.setItem('method', method)
    } else {
      this.$store.state.hypothesis.dybtjy = JSON.parse(sessionStorage.form)
      this.$store.state.hypothesis.method = sessionStorage.method
    }
    //
    this.currentComponent = this.$store.state.hypothesis.method
  },
  data() {
    return {
      // 计算中的控制变量
      loadingController: true,
      currentComponent: '',
      key: 0
    }
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  components: {
    Sample,
    Dybtjy,
    Dlybtjy,
    Method,
    Pdybtjy,
    Z,
    Kffx,
    Dybksjy,
    Sybksjy,
    Fcqxfx,
    Ztjy,
    Danysfcfx,
    Anderson,
    Kfjy,
    F,
    Sysfcfx,
    Duoysfcfx,
    Cfclfcfx,
    Dcbjjy,
    Pdybzjy,
    Dlybzjy,
    Dybzjy,
    Cochran,
    Friedman,
    Welch,
    Mixed,
    GamesHowell,
    Dybbljy,
    Sybbljy,
    Mcnemar,
    Fitting,
    Pearson,
    Kendall,
    Spearman,
    Phi,
    Loading
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var min = ['divContent', 'resultContent']
      var max = ['method', 'resultContent']
      AutoHeight(this, min, max, 176)
    },
    refresh() {
      var count = this.$store.state.samples.keys ? JSON.parse(this.$store.state.samples.keys).length : 0 // 样本数量,
      if (count > 0) {
        this.loadingController = true
        // 组件的key值改变，那么会重新渲染组件
        this.key++
      } else {
        var type = this.$store.state.samples.type
        if (type === 'questionnaire') {
          this.$router.push('/hypothesistest/screendata/questionnaire')
        } else {
          this.$router.push('/hypothesistest/screendata/document')
        }
      }
    }
  },
  computed: {
    outlier() {
      return this.$store.state.samples.filtersAll.outlier !== 0
    },
    without0() {
      return this.$store.state.samples.filtersSimple.without0 !== 0
    },
    setting() {
      return this.$store.state.samples.settingController
    }
  },
  watch: {
    outlier() {
      this.refresh()
    },
    without0() {
      this.refresh()
    },
    setting() {
      this.refresh()
    }
  }
}
</script>
<style lang="less" >
h1 {
  font-size: 16px;
  color: #1890ff;
}
.result-container {
  padding: 30px;
  .imgAndTable {
  }
  .conclusion {
    margin-top: 20px;
  }
}
</style>

<style lang="less" scoped>
body {
  overflow: hidden !important;
}
</style>