var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main user-layout-register" },
    [
      _vm._m(0),
      _c(
        "a-form",
        { ref: "formRegister", attrs: { form: _vm.form, id: "formRegister" } },
        [
          _c(
            "a-form-item",
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "email",
                      {
                        rules: [
                          {
                            required: true,
                            type: "email",
                            message: "请输入邮箱地址"
                          }
                        ],
                        validateTrigger: ["change", "blur"]
                      }
                    ],
                    expression:
                      "[\n          'email',\n          {\n            rules: [{ required: true, type: 'email', message: '请输入邮箱地址' }],\n            validateTrigger: ['change', 'blur'],\n          },\n        ]"
                  }
                ],
                attrs: { size: "large", type: "text", placeholder: "邮箱" }
              })
            ],
            1
          ),
          _c(
            "a-popover",
            {
              attrs: {
                placement: "rightTop",
                trigger: ["focus"],
                getPopupContainer: function(trigger) {
                  return trigger.parentElement
                }
              },
              model: {
                value: _vm.state.passwordLevelChecked,
                callback: function($$v) {
                  _vm.$set(_vm.state, "passwordLevelChecked", $$v)
                },
                expression: "state.passwordLevelChecked"
              }
            },
            [
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { style: { width: "240px" } },
                  [
                    _c(
                      "div",
                      { class: ["user-register", _vm.passwordLevelClass] },
                      [
                        _vm._v(" 强度："),
                        _c("span", [_vm._v(_vm._s(_vm.passwordLevelName))])
                      ]
                    ),
                    _c("a-progress", {
                      attrs: {
                        percent: _vm.state.percent,
                        showInfo: false,
                        strokeColor: _vm.passwordLevelColor
                      }
                    }),
                    _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _c("span", [
                        _vm._v(
                          "请至少输入 6 个字符。请不要使用容易被猜到的密码。"
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "a-form-item",
                [
                  _c("a-input-password", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password",
                          {
                            rules: [
                              {
                                required: true,
                                message: "至少6位密码，区分大小写"
                              },
                              { validator: this.handlePasswordLevel }
                            ],
                            validateTrigger: ["change", "blur"]
                          }
                        ],
                        expression:
                          "[\n            'password',\n            {\n              rules: [\n                { required: true, message: '至少6位密码，区分大小写' },\n                { validator: this.handlePasswordLevel },\n              ],\n              validateTrigger: ['change', 'blur'],\n            },\n          ]"
                      }
                    ],
                    attrs: {
                      size: "large",
                      placeholder: "至少6位密码，区分大小写"
                    },
                    on: { click: _vm.handlePasswordInputClick }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c(
            "a-form-item",
            [
              _c("a-input-password", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "password2",
                      {
                        rules: [
                          {
                            required: true,
                            message: "至少6位密码，区分大小写"
                          },
                          { validator: this.handlePasswordCheck }
                        ],
                        validateTrigger: ["change", "blur"]
                      }
                    ],
                    expression:
                      "[\n          'password2',\n          {\n            rules: [{ required: true, message: '至少6位密码，区分大小写' }, { validator: this.handlePasswordCheck }],\n            validateTrigger: ['change', 'blur'],\n          },\n        ]"
                  }
                ],
                attrs: { size: "large", placeholder: "确认密码" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "mobile",
                        {
                          rules: [
                            {
                              required: true,
                              message: "请输入正确的手机号",
                              pattern: /^1[3456789]\d{9}$/
                            },
                            { validator: this.handlePhoneCheck }
                          ],
                          validateTrigger: ["change", "blur"]
                        }
                      ],
                      expression:
                        "[\n          'mobile',\n          {\n            rules: [\n              { required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\\d{9}$/ },\n              { validator: this.handlePhoneCheck },\n            ],\n            validateTrigger: ['change', 'blur'],\n          },\n        ]"
                    }
                  ],
                  attrs: { size: "large", placeholder: "11 位手机号" }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        slot: "addonBefore",
                        size: "large",
                        defaultValue: "+86"
                      },
                      slot: "addonBefore"
                    },
                    [
                      _c("a-select-option", { attrs: { value: "+86" } }, [
                        _vm._v("+86")
                      ]),
                      _c("a-select-option", { attrs: { value: "+87" } }, [
                        _vm._v("+87")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 16 } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-input",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "captcha",
                                {
                                  rules: [
                                    { required: true, message: "请输入验证码" }
                                  ],
                                  validateTrigger: "blur"
                                }
                              ],
                              expression:
                                "[\n              'captcha',\n              { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' },\n            ]"
                            }
                          ],
                          attrs: {
                            size: "large",
                            type: "text",
                            placeholder: "验证码"
                          }
                        },
                        [
                          _c("a-icon", {
                            style: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "mail" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 8 } },
                [
                  _c("a-button", {
                    staticClass: "getCaptcha",
                    attrs: { size: "large", disabled: _vm.state.smsSendBtn },
                    domProps: {
                      textContent: _vm._s(
                        (!_vm.state.smsSendBtn && "获取验证码") ||
                          _vm.state.time + " s"
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.getCaptcha($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-button",
                {
                  staticClass: "register-button",
                  attrs: {
                    size: "large",
                    type: "primary",
                    htmlType: "submit",
                    loading: _vm.registerBtn,
                    disabled: _vm.registerBtn
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    }
                  }
                },
                [_vm._v("注册 ")]
              ),
              _c(
                "router-link",
                { staticClass: "login", attrs: { to: { name: "login" } } },
                [_vm._v("使用已有帐户登录")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("注册")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }