var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 24 } }, [
                _c("h1", [_vm._v(_vm._s(_vm.title))])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    showHeader: _vm.showHeader,
                    columns: _vm.columns,
                    "data-source": _vm.data,
                    bordered: "",
                    pagination: false,
                    size: _vm.size,
                    scroll: { x: true }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "p",
                      fn: function(text) {
                        return _c("span", {}, [_c("i", [_vm._v(_vm._s(text))])])
                      }
                    },
                    {
                      key: "italic",
                      fn: function(text) {
                        return _c("span", {}, [
                          /^[a-zA-Z]+$/.test(text)
                            ? _c("i", [
                                _c("b", [_vm._v(" " + _vm._s(text) + " ")])
                              ])
                            : _c("span", [_vm._v(_vm._s(text))])
                        ])
                      }
                    },
                    {
                      key: "bold",
                      fn: function(text, record) {
                        return _c("span", {}, [
                          record.col2 === _vm.translate("系数")
                            ? _c("b", [_vm._v(" " + _vm._s(text) + " ")])
                            : _c("span", [_vm._v(_vm._s(text))])
                        ])
                      }
                    },
                    {
                      key: "cof_int",
                      fn: function(text, record) {
                        return _c("span", {}, [
                          record.col2 === _vm.translate("系数")
                            ? _c("div", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.translate("置信区间")))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "8px",
                                      color: "grey"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "（" +
                                        _vm._s(_vm.translate("置信度")) +
                                        "：95%）"
                                    )
                                  ]
                                )
                              ])
                            : _c("span", [_vm._v(_vm._s(text))])
                        ])
                      }
                    }
                  ])
                },
                _vm._l(_vm.columns, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.key,
                      attrs: {
                        slot: item.slotKey === "anova" ? item.slotValue : ""
                      },
                      slot: item.slotKey === "anova" ? item.slotValue : ""
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(item.slotValue) }
                      })
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }