import { render, staticRenderFns } from "./recordTable.vue?vue&type=template&id=3d6cddfc&"
import script from "./recordTable.vue?vue&type=script&lang=js&"
export * from "./recordTable.vue?vue&type=script&lang=js&"
import style0 from "./recordTable.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d6cddfc')) {
      api.createRecord('3d6cddfc', component.options)
    } else {
      api.reload('3d6cddfc', component.options)
    }
    module.hot.accept("./recordTable.vue?vue&type=template&id=3d6cddfc&", function () {
      api.rerender('3d6cddfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/experiment/Exp1/Detail/recordTable.vue"
export default component.exports