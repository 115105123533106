<template>
  <div id="rangecode" style="background: white">
    <a-form-model ref="ruleForm" :model="form" v-bind="formLayout" layout="vertical">
      <a-row>
        <a-col :span="6" style="border-right: 1px solid rgb(232, 232, 232)">
          <field :form="form"></field>
        </a-col>
        <a-col :span="16" style="margin-left: 20px; margin-top: 20px">
          <a-form-model-item
            label="新变量名称"
            prop="name"
            :rules="{
              required: true,
              message: '必填',
            }"
          >
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item
            label="是否为定序(当为定序时,编码值默认按照1,2,3...的顺序编号，也可以自定义)"
            :rules="{
              required: true,
              message: '必填',
            }"
          >
            <a-radio-group v-model="form.isXu" :default-value="false" button-style="solid" @change="radioChange()">
              <a-radio-button :value="true"> 是 </a-radio-button>
              <a-radio-button :value="false"> 否 </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            v-for="(item, index) in form.range"
            :key="index"
            :label="index === 0 ? '范围' : ''"
            :rules="{
              required: true,
              message: '必填',
            }"
          >
            <a-input-group compact>
              <a-form-model-item
                :prop="'range.' + index + '.min'"
                :rules="{
                  required: true,
                  message: '必填',
                }"
              >
                <a-input-number v-model="item.min" style="width: 100px" placeholder="最小值"></a-input-number>
              </a-form-model-item>
              <span style="font-size: 20px; margin: auto 10px">~</span>
              <a-form-model-item
                :prop="'range.' + index + '.max'"
                :rules="{
                  required: true,
                  message: '必填',
                }"
              >
                <a-input-number v-model="item.max" style="width: 100px" placeholder="最大值"></a-input-number>
              </a-form-model-item>
              <span style="font-size: 20px; margin: auto 10px">→</span>
              <a-form-model-item
                :prop="'range.' + index + '.display'"
                :rules="{
                  required: true,
                  message: '必填',
                }"
              >
                <a-input style="width: 100px" v-model="item.display" placeholder="分类名称"></a-input>
              </a-form-model-item>
              <a-form-model-item
                v-if="form.isXu === true"
                :prop="'range.' + index + '.code'"
                :rules="{
                  required: true,
                  message: '必填',
                }"
              >
                <a-input-number style="width: 100px" v-model="item.code" placeholder="定序编码值"></a-input-number>
              </a-form-model-item>
              <span
                style="font-size: 20px; margin: auto 10px">
                <a-icon
                  class="dynamic-delete-button"
                  type="plus-circle"
                  @click="addDomain(item)"
                />
              </span>
              <a-icon
                class="dynamic-delete-button"
                type="minus-circle"
                style="padding-left: 10px"
                v-if="form.range && form.range.length > 1"
                @click="removeDomain(item)"
              />
            </a-input-group>

          </a-form-model-item>
          <a-form-model-item style="margin-top: 20px">
            <a-button type="primary" html-type="submit" @click="submitForm('dynamicValidateForm')"> 确定 </a-button>
            <a-button style="margin-left: 10px" @click="gotoBack()"> 取消 </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import field from './components/field'
import { AutoHeight } from '@/utils/util'
import { Add } from '@/api/fieldCode'
export default {
  components: { field },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }
    return {
      form: {
        name: undefined,
        isXu: false,
        selectKeys: [],
        range: [
          {
            key: Date.now(),
            max: undefined,
            min: undefined,
            display: undefined
          }
        ]
      }
    }
  },
  created() {
    this.autoHeightChange()
    console.log('create调用了呢')
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = ['afield', 'rangecode']
      var min = ['afield', 'rangecode']
      AutoHeight(this, min, max, 215)
    },
    addDomain(item) {
      const index = this.form.range.indexOf(item)
      this.form.range.splice(index + 1, 0, {
        key: Date.now(),
        max: null,
        min: null,
        display: '',
        code: null
      })
      this.form.range.map((r, index) => {
        r.code = index + 1
      })
    },
    removeDomain(item) {
      const index = this.form.range.indexOf(item)
      if (index !== -1) {
        this.form.range.splice(index, 1)
      }
      this.form.range.map((r, index) => {
        r.code = index + 1
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var values = this.form

          var parm = { ...values, range: JSON.stringify(values.range), fields: JSON.stringify(values.selectKeys) }
          const pathname = window.location.pathname
          if (pathname.indexOf('document') > -1) {
            parm.dataType = 'document'
          } else if (pathname.indexOf('questionnaire') > -1) {
            var id = JSON.parse(this.$store.state.samples.questionnaireID)[0]
            parm.typeid = id
            parm.dataType = 'questionnaire'
          } else if (pathname.indexOf('file') > -1) {
            id = this.$store.state.samples.fileid
            parm.typeid = id
            parm.dataType = 'file'
          } else if (pathname.indexOf('experiment') > -1) {
            id = this.$store.state.samples.experimentID
            var guid = this.$store.state.samples.experimentGuid
            parm.expertmentGuid = guid
            parm.typeid = id
            parm.dataType = 'experiment'
          }
          Add(parm).then(res => {
            if (!res) {
              this.$message.error('该变量名称已存在')
            } else {
            this.gotoBack()
            }
          })
        }
      })
    },

    gotoBack() {
      const pathname = window.location.pathname
      if (pathname.indexOf('questionnaire') > -1) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/questionnaire')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/questionnaire')
        } else {
          this.$router.push('/customchart/screendata/questionnaire')
        }
      } else if (pathname.indexOf('document') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/document')
        } else {
          this.$router.push('/customchart/screendata/document')
        }
      } else if (pathname.indexOf('experiment') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/experiment')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/experiment')
        } else {
          this.$router.push('/customchart/screendata/experiment')
        }
      } else if (pathname.indexOf('file') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/file')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/file')
        } else {
          this.$router.push('/customchart/screendata/file')
        }
      }
    },

    radioChange(e) {
      if (e.target.value) {
        this.form.range.map((r, index) => {
          r.code = index + 1
        })
      } else {
        this.form.range.map((r, index) => {
          r.code = null
        })
      }
    }
  }
}
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: static !important;
  top: 4px;
  font-size: 24px;
  margin-left: 2px;
  color: #999;
  transition: all 0.3s;
}
.ant-form-item {
  margin: 0px !important;
}
</style>