var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "title,1",
        staticClass: "third-title",
        attrs: { id: "3." + _vm.secondIndex + ".1 检验" }
      },
      [_vm._v("3." + _vm._s(_vm.secondIndex) + ".1 检验")]
    ),
    _c(
      "div",
      [
        _c("ChiSquare", {
          ref: "content,1",
          attrs: {
            xys: _vm.xys,
            data: _vm.data,
            flowChiSquareNow: _vm.flow_chiSquare_now
          },
          on: {
            mainP: function(x) {
              return (_vm.mainP = x)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "textFront",
              fn: function() {
                return [
                  _c("p", { ref: "text,1,1" }, [
                    _vm.flow_chiSquare_now === "auto"
                      ? _c("span", [
                          _vm.data.pyMethod === "Pearson"
                            ? _c("span", [
                                _vm.dimension[0] === 2 && _vm.dimension[1] === 2
                                  ? _c("span", [
                                      _vm._v(
                                        " 由于" +
                                          _vm._s(_vm.leiName1) +
                                          "与" +
                                          _vm._s(_vm.leiName2) +
                                          "中的类别数都等于2，样本数 ≥ 40且所有理论频数 ≥ 5，因此使用Pearson算法"
                                      )
                                    ])
                                  : _vm._e(),
                                (_vm.dimension[0] >= 2 &&
                                  _vm.dimension[1] > 2) ||
                                (_vm.dimension[1] >= 2 && _vm.dimension[0] > 2)
                                  ? _c("span", [
                                      _vm._v(
                                        " 由于" +
                                          _vm._s(_vm.leiName1) +
                                          "与" +
                                          _vm._s(_vm.leiName2) +
                                          "的所有理论频数 ≥ 1且理论频数 < 5的格子的比例 < 20%，因此使用Pearson算法"
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm.data.pyMethod === "yates"
                            ? _c("span", [
                                _vm.dimension[0] === 2 && _vm.dimension[1] === 2
                                  ? _c("span", [
                                      _vm._v(
                                        " 由于" +
                                          _vm._s(_vm.leiName1) +
                                          "与" +
                                          _vm._s(_vm.leiName2) +
                                          "中的类别数都等于2，样本数 ≥ 40且所有理论频数 > 1，且至少一个理论数出现1 ≤ E < 5，因此使用yates校正算法"
                                      )
                                    ])
                                  : _vm._e(),
                                (_vm.dimension[0] >= 2 &&
                                  _vm.dimension[1] > 2) ||
                                (_vm.dimension[1] >= 2 && _vm.dimension[0] > 2)
                                  ? _c("span", [
                                      _vm._v(
                                        " 由于" +
                                          _vm._s(_vm.leiName1) +
                                          "与" +
                                          _vm._s(_vm.leiName2) +
                                          "中的所有理论频数 < 1 或 理论频数 < 5的格子的比例超过20%，因此使用yates校正算法"
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm.data.pyMethod === "Fisher"
                            ? _c("span", [
                                _vm._v(
                                  " 由于" +
                                    _vm._s(_vm.leiName1) +
                                    "与" +
                                    _vm._s(_vm.leiName2) +
                                    "中的理论频数 < 1或 样本数 < 40，则使用Fisher’s确切概率法检验 "
                                )
                              ])
                            : _vm._e(),
                          _vm.data.pyMethod === "" ? _c("span") : _vm._e()
                        ])
                      : _c("span", [
                          _vm._v(
                            " 使用" + _vm._s(_vm.flow_chiSquare_now) + "算法"
                          )
                        ]),
                    _c("span", [
                      _vm._v("进行卡方检验，"),
                      _c("br"),
                      _c("span", { staticClass: "H0H1" }, [
                        _vm._v(
                          "H0：" +
                            _vm._s(_vm.leiName1) +
                            "与" +
                            _vm._s(_vm.leiName2) +
                            "不具有关联性。"
                        )
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "H0H1" }, [
                        _vm._v(
                          "H1：" +
                            _vm._s(_vm.leiName1) +
                            "与" +
                            _vm._s(_vm.leiName2) +
                            "具有关联性。"
                        )
                      ]),
                      _c("br"),
                      _vm._v("检验结果如下表所示：")
                    ])
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "textBack",
              fn: function() {
                return [
                  _c("Conclusion", [
                    _c("p", { ref: "text,1,3" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$hypothesis.conclusion(
                              _vm.mainP,
                              _vm.$store.state.report.a,
                              _vm.leiName1 +
                                "与" +
                                _vm.leiName2 +
                                "不具有关联性",
                              _vm.leiName1 + "与" + _vm.leiName2 + "具有关联性"
                            )
                          )
                        }
                      })
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }