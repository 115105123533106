var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("change-password", {
        attrs: { show: _vm.changePasswordVisible },
        on: { show: _vm.handleChangePassword }
      }),
      _vm.currentUser && _vm.currentUser.name
        ? _c(
            "a-dropdown",
            {
              attrs: { placement: "bottomRight" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function() {
                      return [
                        _c(
                          "a-menu",
                          {
                            staticClass: "ant-pro-drop-down menu",
                            attrs: { "selected-keys": [] }
                          },
                          [
                            _c(
                              "a-menu-item",
                              { on: { click: _vm.info } },
                              [
                                _c("a-icon", { attrs: { type: "message" } }),
                                _vm._v(" 用户信息 ")
                              ],
                              1
                            ),
                            _c(
                              "a-menu-item",
                              {
                                key: "changePassword",
                                on: { click: _vm.handleChangePassword }
                              },
                              [
                                _c("a-icon", { attrs: { type: "edit" } }),
                                _vm._v(" 修改密码 ")
                              ],
                              1
                            ),
                            _c(
                              "a-menu-item",
                              {
                                key: "logout",
                                on: { click: _vm.handleLogout }
                              },
                              [
                                _c("a-icon", { attrs: { type: "logout" } }),
                                _vm._v(" 退出登录 ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1091771553
              )
            },
            [
              _c(
                "span",
                { staticClass: "ant-pro-account-avatar" },
                [
                  _c("a-avatar", {
                    staticClass: "antd-pro-global-header-index-avatar",
                    attrs: {
                      size: "small",
                      src:
                        "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.currentUser.name))])
                ],
                1
              )
            ]
          )
        : _c(
            "span",
            [
              _c("a-spin", {
                style: { marginLeft: 8, marginRight: 8 },
                attrs: { size: "small" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }