// 样本筛选的部分数据，之前session中的一些数据
const samples = {
  state: {
    settingController: true,
    documents: '', // 样本原始数据json
    keys: '', // 选中样本的id集合 json
    keysAll: [],
    fields: '', // 变量对象的集合 json
    questionnaireModelID: null, // 管理员选择的模版ID
    questionnaireID: null, // 量表筛选时，选中的量表id
    experimentID: null, // 实验筛选时，实验的id
    experimentGuid: null, // 实验筛选时，实验的Guid.因为现在有好多实验，每一种都需要重新写
    fileid: null, // 文件筛选时，文件的id
    fileName: '', // 用于缺失值处理后数据另存为文件时的名字
    type: '', // 数据筛选时，选中的是档案还是量表 'document','questionnaire'
    selectFields: '', // 样本筛选涉及到的变量field的集合 json
    selectValues: '', // 样本筛选涉及到的变量和值的集合 json
    selectStr: '', // 样本筛选页面上的显示条件,比如'身高>0'
    selectparms: '', // 样本筛选传入api的参数集合 json
    sxFields: [], // 时序字段
    source: '', // 文件-缺失值处理后的数据来源，档案，量表，实验
    // 单项数据进行筛查
    filtersSimple: {
      without0: 0 // 是否进行去0处理 1 or 0
    },
    // 需要对整体数据进行筛查
    filtersAll: {
      outlier: 0 // 是否进行离群值处理 1 or 0
    }
  },
  mutations: {
    // 更新samples类
    SET_UPDATE: (state, data) => {
      Object.keys(data).map(r => {
        state[r] = data[r]
      })
    }

  },
  actions: {
    /**
     * 重置样本对象,一般在退出登录时使用
     * @param {*} param0 自带，调用时不需要传
     */
    initSamples({
      commit
    }) {
      var data = {}
      data.documents = ''
      data.keys = ''
      data.fields = ''
      data.questionnaireModelID = null
      data.questionnaireID = null
      data.experimentID = null
      data.experimentGuid = null
      data.fileid = null
      data.fileName = ''
      data.type = ''
      data.source = ''
      data.filtersSimple = {
        without0: 0
      }
      data.filtersAll = {
        outlier: 0
      }
      data.selectFields = ''
      data.selectValues = ''
      data.selectStr = ''
      data.selectparms = ''
      data.keysAll = []
      commit('SET_UPDATE', data)
    },
    /**
     * 切换变量时，清空一些属性
     * @param {*} param0
     */
    changeField({
      commit
    }) {
      var data = {}
      data.documents = ''
      data.keys = ''
      data.filtersSimple = {
        without0: 0
      }
      data.filtersAll = {
        outlier: 0
      }
      data.selectFields = ''
      data.selectValues = ''
      data.selectStr = ''
      data.selectparms = ''
      data.keysAll = []
      commit('SET_UPDATE', data)
    },
    /**
     * 刷新时给样本对象重新赋值
     * @param {*} param0 自带，调用时不需要传
     */
    updateSamples({
      commit
    }, data) {
      console.log('field', data.fields && JSON.parse(data.fields))
      commit('SET_UPDATE', data)
    }
  }
}
export default samples