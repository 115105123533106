/* eslint-disable no-useless-constructor */
import {
    Req
  } from '@/utils/util'
  export class MakeReq extends Req {
    constructor(form, method, that) {
      super(form, method, that)
    }
    // 例如cochran
    lei2() {
        //   往form里面添加维度的列表
      var x = this.form.xys[0][0]
      var y = this.form.xys[1][0]
      // 构造
      this.form.list = []
      for (var i = 0; i < x.length - 1; i++) {
        this.form.list.push([])
      }
      this.form.list.forEach(v => {
        for (var j = 0; j < y.length - 1; j++) {
          v.push(0)
        }
      })
      console.log('%c 🍪 this.form.list: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', this.form.list)
      var lei1 = x[x.length - 1]
      var lei2 = y[y.length - 1]
      this.that.documents.forEach(element => {
        if (element[lei1] && element[lei2]) {
          // 取到男在列表['性别','男','女']中的下标，小学在列表['文化程度','小学',...]中的下标，添加到下标对应的数组中。
          // [[0,2,5,9,1,7,6,2,0],[0,3,4,1,5,10,11,13,5]]
          var xIndex = x.indexOf(element[lei1])
          var yIndex = y.indexOf(element[lei2])
          this.form.list[xIndex][yIndex]++
        }
      })
        return this.req
    }
  }