import { render, staticRenderFns } from "./CreateForm.vue?vue&type=template&id=08a56868&"
import script from "./CreateForm.vue?vue&type=script&lang=js&"
export * from "./CreateForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08a56868')) {
      api.createRecord('08a56868', component.options)
    } else {
      api.reload('08a56868', component.options)
    }
    module.hot.accept("./CreateForm.vue?vue&type=template&id=08a56868&", function () {
      api.rerender('08a56868', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/experiment/Device/components/CreateForm.vue"
export default component.exports