import request from '@/utils/request'

const api = '/User'

export function getUsers(parameter) {
  return request({
    url: `${api}/List`,
    method: 'post',
    data: parameter
  })
}

export function adduserinfo (parameter) {
  return request({
    url: '/user',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}