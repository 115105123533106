var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.isAddMedicalHistory,
        "confirm-loading": _vm.confirmLoading
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "div",
        { staticClass: "modalTitle" },
        [
          _c("span", { attrs: { id: "medicalHistoryTitle" } }, [
            _vm._v(
              _vm._s(_vm.tabController === "history" ? "既往史" : "相关检查")
            )
          ]),
          _c("a-tree-select", {
            staticStyle: { width: "120px", "margin-left": "75px" },
            attrs: {
              placeholder: "请选择",
              "dropdown-style": { maxHeight: "400px", overflow: "auto" },
              "tree-data": _vm.treeData,
              size: "small",
              getPopupContainer: function(triggerNode) {
                return triggerNode.parentNode
              }
            },
            on: { select: _vm.onTreeSelect },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "modalBody" },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _vm.medicalHistoryMadals !== null
                ? _c(
                    "a-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "a-form-model",
                        {
                          attrs: {
                            model: _vm.medicalHistoryMadals,
                            labelAlign: "left",
                            "label-col": { span: 5 },
                            "wrapper-col": { span: 19 }
                          }
                        },
                        [
                          _c(
                            "a-row",
                            _vm._l(_vm.medicalHistoryMadals.form, function(
                              medicalHistoryMadal
                            ) {
                              return _c(
                                "a-col",
                                {
                                  key: medicalHistoryMadal.id,
                                  attrs: { span: 24 }
                                },
                                [
                                  _c("form-items", {
                                    attrs: {
                                      tabContent: _vm.medicalHistoryMadals.form,
                                      formItem: medicalHistoryMadal,
                                      controller: "modal",
                                      examList: _vm.examList
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }