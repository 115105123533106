var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "third-module" }, [_vm._t("text1")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      _vm._l(_vm.tables, function(table, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "margin-bottom": "10px" } },
          [
            table
              ? _c(
                  "a-table",
                  {
                    ref: "tables,3",
                    refInFor: true,
                    attrs: {
                      showHeader: table.showHeader,
                      pagination: false,
                      dataSource: table.data,
                      columns: table.columns,
                      size: "small"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("正态检验")
                      ])
                    ]),
                    _vm._l(table.columns, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.key,
                          attrs: {
                            slot: "slotValue" in item ? item.slotValue : ""
                          },
                          slot: "slotValue" in item ? item.slotValue : ""
                        },
                        [
                          _c(
                            "div",
                            { domProps: { innerHTML: _vm._s(item.slotValue) } },
                            [_vm._v(_vm._s(item.slotValue))]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text2")], 2),
    _c("div", { staticClass: "third-module" }, [_vm._t("text3")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      [
        _c(
          "a-row",
          _vm._l(_vm.imgs, function(img, index) {
            return _c(
              "a-col",
              { key: index, attrs: { span: 12 } },
              [
                _c("MyImg", {
                  ref: "imgs,6",
                  refInFor: true,
                  attrs: { imgBytes: img, fileName: _vm.imgFileNameAry[index] }
                })
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }