<template>
  <div>
    <div class="third-module">
      <a-row>
        <a-col v-for="(img, index) in imgs" :key="index" :span="12">
          <img :src="img" />
        </a-col>
      </a-row>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'

export default {
  name: 'Resid3',
  props: ['resid'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'Resid3', resid: this.resid }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '残差（图）',
          msg: res
        })
      } else {
        this.tables = res.tables
        this.imgs = res.imgs
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      imgs: []
    }
  }
}
</script>