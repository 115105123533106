<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.regression}`"
        :id="`3.${secondIndex}.${thirdIndex.regression} 回归`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.regression }} 回归
      </div>
      <div>
        <GeneralizedOrdinaryLeastSquares
          :ref="`content,1`"
          :xys="xys"
          :data="data"
          :settings="settings.options.methods.find((v) => v.name === '广义最小二乘回归')"
          @resid="(val) => (resid = val)"
          @dw_table="(val) => (dw_table = val)"
        >
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.regression},1`">
              <span> 使用{{ xys_str }}做广义最小二乘回归分析，分析结果如下所示： </span>
            </p>
          </template>
          <template #text2="{ formula }" class="text">
            <Conclusion>
              <p :ref="`text,1,3`">
                <span>由上表可得：</span>
                <span v-if="xys[0].length > 1">
                  <span v-if="resid && resid.vif_array.every((v) => v < 10)">
                    由上表可得：全部自变量的VIF &lt; 10，说明模型没有多重共线性问题，模型构建良好。
                  </span>
                  <span v-else> 由上表可得：存在自变量的VIF ≥ 10，模型构建较差。 </span>
                </span>
                <span>回归方程式为：</span>
                <br>
                <span>{{ formula }}</span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.regression},4`">
              <span v-if="xys[0].length === 1">{{ xys[0][0] }}与{{ xys[1][0] }}的线性回归图如下图所示。</span>
              <span v-else>
                {{ `${xys[1][0]}拟合值与${xys[1][0]}观测值的线性回归图如下图所示。` }}
              </span>
            </p>
          </template>
        </GeneralizedOrdinaryLeastSquares>
      </div>
    </div>

    <div v-if="resid && xys[0].length > 1">
      <div
        :ref="`title,${thirdIndex.resid}`"
        :id="`3.${secondIndex}.${thirdIndex.resid} 残差序列分析`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.resid }} 残差序列分析
      </div>
      <div style="padding: 0 15px">
        <div>
          <div
            :ref="`sub_title,${thirdIndex.resid},1`"
            :id="`3.${secondIndex}.${thirdIndex.resid}.1 正态性检验`"
            class="forth-title"
          >
            3.{{ secondIndex }}.{{ thirdIndex.resid }}.1 正态性检验
          </div>
          <div>
            <Resid
              :ref="`content,${thirdIndex.resid},1`"
              :resid="resid"
              :yName="xys[1][0]"
              :parentType="`report`"
              @resid_norm_p="(val) => (resid_norm_p = val)"
              @method_name="(val) => (method_name = val)"
            >
              <template #text1 class="text">
                <p :ref="`text,${thirdIndex.resid},2`">
                  <span>
                    {{
                      `使用${method_name}算法对残差序列做正态性检验（H0：残差序列是正态的；H1：残差序列是非正态的），检验结果如下表所示：`
                    }}</span
                  >
                </p>
              </template>
              <template #text2 class="text">
                <Conclusion>
                  <p :ref="`text,${thirdIndex.resid},4`">
                    <span>由上表可得：</span>
                    <span
                      v-html="$hypothesis.conclusion(resid_norm_p, a, '残差序列是正态的', '残差序列是非正态的')"
                    ></span>
                  </p>
                </Conclusion>
              </template>
              <template #text3 class="text">
                <p :ref="`text,${thirdIndex.resid},5`">
                  <span>残差相关图如下所示：</span>
                </p>
              </template>
            </Resid>
          </div>
        </div>
        <div v-if="dw_table && xys[0].length > 1">
          <div
            :ref="`sub_title,${thirdIndex.resid},7`"
            :id="`3.${secondIndex}.${thirdIndex.resid}.2 独立性检验`"
            class="third-title"
          >
            3.{{ secondIndex }}.{{ thirdIndex.resid }}.2 独立性检验
          </div>
          <div>
            <Resid4 :ref="`content,${thirdIndex.resid},2`" :table="dw_table" @dw="(val) => (dw = val)">
              <template #text1 class="text">
                <p :ref="`text,${thirdIndex.resid},8`">
                  <span>对残差序列做独立性检验，检验结果如下表所示：</span>
                </p>
              </template>
              <template #text2 class="text">
                <Conclusion>
                  <p :ref="`text,${thirdIndex.resid},10`">
                    <span>由上表可得：</span>
                    <span v-if="dw >= 1.7 && dw <= 2.3"
                    >由于1.7 ≤ Durbin-Watson值 ({{ dw }}) ≤ 2.3，因此残差序列不存在自相关。</span
                    >
                    <span v-else-if="dw >= 1 && dw <= 1.7"
                    >由于1 ≤ Durbin-Watson值 ({{ dw }}) ≤ 1.7，无法判断残差序列的相关性。</span
                    >
                    <span v-else-if="dw >= 2.3 && dw <= 3"
                    >由于2.3 ≤ Durbin-Watson值 ({{ dw }}) ≤ 3，无法判断残差序列的相关性。</span
                    >
                    <span v-else-if="dw >= 0 && dw < 1"
                    >由于0 ≤ Durbin-Watson值 ({{ dw }}) &lt; 1，因此残差序列存在正自相关。</span
                    >
                    <span v-else-if="dw > 3 && dw <= 4"
                    >由于3 &lt; Durbin-Watson值 ({{ dw }}) ≤ 4，因此残差序列存在负自相关。</span
                    >
                  </p>
                </Conclusion>
              </template>
            </Resid4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralizedOrdinaryLeastSquares from '@/views/report/modules/hypothesis/generalizedOrdinaryLeastSquares'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import Resid3 from '@/views/report/modules/hypothesis/resid3.vue'
import Resid4 from '@/views/report/modules/hypothesis/resid4.vue'
export default {
  name: 'GeneralizedOrdinaryLeastSquaresMain',
  components: { GeneralizedOrdinaryLeastSquares, Resid, Resid3, Resid4 },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  data() {
    return {
      resid: null,
      resid_norm_p: null,
      dw: null,
      dw_table: null,
      method_name: null // 正态检验用的算法名
    }
  },
  computed: {
    thirdIndex() {
      const regression = 1
      const resid = regression + 1
      // const resid3 = regression + 2
      const resid4 = regression + 2
      return { regression, resid, resid4 }
    },
    xys_str() {
      const { xys } = this
      const x_list = xys[0]
      const y_list = xys[1]
      const x_str = x_list.map(v => Array.isArray(v) ? `“${v[v.length - 1]}”` : `“${v}”`).join('、')
      const y_str = y_list[0]
      return `${x_str}与${y_str}`
    },
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>