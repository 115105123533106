import {
  DataFilterClass
} from '@/utils/dataFilterClass'
import store from '@/store'

import {
  getPictureSize
} from '@/utils/util'
import DataClass from '../report/utils/DataClass'

export class MakeReq {
  constructor(form, method, that, isDrops = false, filter = 'yes') {
    /**
     * @param form 提交到后台的form，包含了所有的参数
     * @param method 例如 'Dybtjy'
     * @param that 指向result.vue，通过that可以访问到result.vue里的内容
     * @param isDrop 是否是Drops组件 当出现拖多组变量时，不能因为一个变量没值，把整条数据都删除掉，因为其他组会用的这条数据中有值的变量。
     * @param filter 是否需要数据清洗，'yes':需要;'no':不需要;'both':两者都要;
     */
    this.form = {
      ...form,
      style: {
        language: store.state.user.info.setting.language
      }
    }
    this.method = method
    this.that = that
    this.isDrops = isDrops
    // 数据清理
    // 如果是变量组，那么每组变量都要生成一个专有的documents。
    if (isDrops) {
      const documentsList = []
      this.form.xys.forEach(group => {
        const documents = this.filter(group)
        documentsList.push(documents)
      })
      this.that.documents = documentsList
    } else {
      this.that.documents = this.filter(this.form.xys)
    }
    this.makeReq()
  }
  /**
   * @param xys 一组变量组 [['身高'],['体重']]
   * @return 返回这组变量过滤后的documents。
   */
  filter(xys) {
    const list = []
    xys.forEach(v => {
      v.forEach(i => {
        list.push(i)
      })
    })
    return new DataFilterClass(list, true).main()
  }
  // 构造req
  makeReq() {
    this.req = {}
    this.req.method = this.method
    this.req.args = {}
    this.req.args.form = this.form
  }

  // 获取只有变量名的一个数组，用于判断documents里的每条数据是否为空
  static getParamNameArray(xys) {
    /**
     * @param xys 例如[[['男','女','性别'],'年龄'],['身高']]
     */
    const list = []
    xys.forEach(drop => {
      drop.forEach(v => {
        list.push(Array.isArray(v) ? v[v.length - 1] : v)
      })
    })
    return list
  }

  linearRegression() {
    const xDrop = this.form.xys[0]
    const yDrop = this.form.xys[1]
    const leiResultList = []
    const liangResultList = []
    const liangResultList2 = []
    // 构造leiList结构并且构造一个只有定类的数组和一个只有定量的数组
    const leiList = []
    const liangList = []
    // 存放对象形式数据的数组，用于残差分析
    const data = []
    xDrop.forEach(v => {
      if (typeof (v) === 'object') {
        leiResultList.push([])
        leiList.push(v)
      } else {
        liangResultList2.push([])
        liangList.push(v)
      }
    })
    // 因变量y的结果数组
    const yList = []
    this.that.documents.forEach(element => {
      if (this.that.$hypothesis.judgeEleIsNull(MakeReq.getParamNameArray(this.form.xys), element)) {
        yList.push(Number(element[yDrop[0]]))
        leiList.forEach((v, index) => {
          const value = element[v.slice(-1)[0]]
          leiResultList[index].push(value)
        })
        const list = []
        // eslint-disable-next-line eqeqeq
        liangList.forEach((v, index) => {
          list.push(Number(element[v]))
          liangResultList2[index].push(Number(element[v]))
        })
        liangResultList.push(list)
        // 给上面创建好的data数组中添加对象
        const obj2 = {}
        xDrop.forEach(x => {
          // 如果是定类，那么取数组的最后一个值为名字
          const key = typeof (x) === 'object' ? x[x.length - 1] : x
          const value = element[key]
          obj2[key] = isNaN(+value) ? value : +value
        })
        const yName = yDrop[0]
        const yValue = element[yName]
        obj2[yName] = isNaN(+yValue) ? yValue : +yValue
        data.push(obj2)
      }
    })
    this.form.leiList = leiList
    this.form.liangList = liangList
    this.form.liangResultList = liangResultList
    this.form.liangResultList2 = liangResultList2
    this.form.leiResultList = leiResultList
    this.form.yList = yList
    this.form.data = data
    const {
      figsize,
      dpi
    } = getPictureSize('analysisOfRegression', 60)
    this.form.figsize = figsize
    this.form.dpi = dpi
    return this.req
  }

  polynomialRegression() {
    this.linearRegression()
    const step = this.form.step
    // this.form.liangResultList = this.form.liangResultList.map(v => {
    //   let array = [...v]
    //   for (let i = 2; i <= step; i++) {
    //     array = array.concat(v.map(m => Math.pow(m, i)))
    //   }
    //   return array
    // })
    // let liangList = this.form.liangList
    // for (let i = 2; i <= step; i++) {
    //   liangList = liangList.concat(this.form.liangList.map(v => [v, i]))
    // }
    // this.form.liangList = liangList
    const xList = [...this.form.liangList]
    for (let i = 2; i <= step; i++) {
      this.form.liangList.forEach(v => {
        xList.push(`${v}<sup>${i}</sup>`)
      })
    }
    this.form.xList = xList
    // 给data加上次方以后的key、value值
    this.form.data = this.form.data.map(v => {
      const obj = {
        ...v
      }
      for (let i = 2; i <= step; i++) {
        for (const key in v) {
          if (key !== this.form.xys[1][0]) {
            obj[`${key}<sup>${i}</sup>`] = Math.pow(v[key], i)
          }
        }
      }
      return obj
    })
    this.form.method = 'pinv'
    const {
      figsize,
      dpi
    } = getPictureSize('analysisOfRegression', 60)
    this.form.figsize = figsize
    this.form.dpi = dpi
    return this.req
  }

  splineSmooth() {
    const xys = this.form.xys
    const data = []
    xys.forEach((drop, index) => {
      data.push([])
      drop.forEach(m => {
        data[index].push([])
      })
    })

    this.that.documents.forEach((documents, i) => {
      documents.forEach(ele => {
        xys[i].forEach((drop, j) => {
          data[i][j].push(Number(ele[drop[0]]))
        })
      })
    })
    this.form.k = this.that.k
    this.form.ind = this.that.ind
    this.form.data = data
    const {
      figsize,
      dpi
    } = getPictureSize('analysisOfRegression', 60)
    this.form.figsize = figsize
    this.form.dpi = dpi
    return this.req
  }

  Logic() {
    const xys = this.form.xys
    const data = []
    // 生成只包含变量名字且合并x和y后的数组
    const paramAry = xys[0].concat(xys[1])
    this.that.documents.forEach(element => {
      // 生成key:value形式的数据。
      DataClass.make_key_value_data(data, paramAry, element)
    })
    // 逻辑回归中y的值必须是字符串形式，因此这里再手动把y的值转换成字符串。
    const y_name = xys[1][0].slice(-1)[0]
    data.forEach(v => {
      v[y_name] = '' + v[y_name]
    })
    this.form.data = data
    // 构造roc的数据结构。
    this.form.rocData = JSON.stringify(xys[0].map(v => {
      return {
        name: Array.isArray(v) ? v.slice(-1)[0] : v,
        data
      }
    }))
    return this.req
  }

  MultipleLogic() {
    const xys = this.form.xys
    const data = []
    // 生成只包含变量名字且合并x和y后的数组
    const paramAry = xys[0].concat(xys[1])
    this.that.documents.forEach(element => {
      // 生成key:value形式的数据。
      DataClass.make_key_value_data(data, paramAry, element)
    })
    // 逻辑回归中y的值必须是字符串形式，因此这里再手动把y的值转换成字符串。
    const y_name = xys[1][0].slice(-1)[0]
    data.forEach(v => {
      v[y_name] = '' + v[y_name]
    })
    this.form.data = data
    return this.req
  }

  PCA() {
    const xys = this.form.xys
    const data = []
    const paramAry = xys[0]
    this.that.documents.forEach(element => {
      // 生成key:value形式的数据。
      DataClass.make_key_value_data(data, paramAry, element)
    })
    this.form.data = data
    this.form.maxNumber = xys[0].length
    return this.req
  }

  Cox() {
    const xys = this.form.xys
    const data = []
    // 生成只包含变量名字且合并x和y后的数组
    // const paramAry = MakeReq.getParamNameArray(xys)
    const paramAry = xys[0].concat(xys[1]).concat(xys[2])
    this.that.documents.forEach(element => {
      // 生成key:value形式的数据。
      DataClass.make_key_value_data(data, paramAry, element)
    })
    this.form.data = data
    this.form.maxNumber = xys[0].length
    const {
      figsize,
      dpi
    } = getPictureSize('analysisOfRegression', 60)
    this.form.figsize = figsize
    this.form.dpi = dpi
    return this.req
  }
}