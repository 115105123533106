<template>
  <div>
    <loading v-if="loadingController"></loading>
    <div style="padding: 50px 0 0 0">
      <div
        style="position: fixed; top: 0px; width: 100%; height: 50px; background: #001529; z-index: 500; opacity: 0.5"
      >
        <a-row type="flex" justify="end">
          <a-col class="title" style="margin-right: 20px">
            <a-button
              v-print="'#PrintContent'"
              style="font-weight: bold; color: black; margin-top: 10px"
            >打印</a-button
            >
            <a-button @click="cancel" style="margin-left: 10px; color: black; font-weight: bold">取消</a-button>
          </a-col>
        </a-row>
      </div>
      <div id="PrintContent">
        <div style="background: #dedede">
          <div class="pagediv">
            <a-table
              class="base recordTable"
              size="small"
              :columns="columns1"
              :data-source="data1"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template
                slot="title"
              ><span style="font-size: 20px; font-weight: 600">正确度验证（参考方法）</span>
              </template>
            </a-table>
            <a-table
              class="dataTable recordTable"
              size="small"
              :columns="columns2"
              :data-source="data2"
              :pagination="false"
              bordered
            >
              <template slot="title">
                <a-row>
                  <a-col :span="24">
                    <span
                      :class="{ concentration: true, active: currentConcentrationIndex === index }"
                      v-for="(concentration, index) in sampleConcentrations"
                      :key="index"
                      @click="
                        currentConcentrationIndex = index
                        fetch()
                      "
                    >
                      {{ concentration }}%浓度
                    </span>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col
                    :span="24"
                    style="font-weight: 700; background-color: #f7f7f7; border-top: 1px solid #999999"
                  >数据</a-col
                  >
                </a-row>
              </template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns3"
              :data-source="data3"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title">计算</template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns4"
              :data-source="data4"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title">结论</template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getT } from '@/api/exp/python'
import { List } from '@/api/exp/experimentData'
import { getByID } from '@/api/exp/experiment'
import Loading from '../../components/loading'
const columnWidth = 150
export default {
  components: { Loading },
  created() {
    // this.exp = JSON.parse(this.$route.query.exp)
    const id = Number(this.$route.query.id)
    List({ expID: id }).then(res => {
      const list = res.data
      getByID(id).then(res => {
        this.exp = res
        this.exp.list = list
      console.log('%c 🍔 this.exp: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.exp)
        this.fetch()
      })
    })
    // this.exp = this.$route.query.exp
  },
  data() {
    return {
      loadingController: true,
      currentConcentrationIndex: 0, // 当前选中浓度的下标
      columns1: [],
      data1: [],
      columns2: [],
      data2: [],
      columns3: [],
      data3: [],
      columns4: [],
      data4: [],
      exp: {}
    }
  },
  computed: {
    sampleConcentrations() {
      if (JSON.stringify(this.exp) !== '{}') return JSON.parse(this.exp.sampleConcentrations)
      return []
    }
  },
  methods: {
    async fetch() {
      this.columns1 = [
        {
          title: 'key1',
          key: 'key1',
          dataIndex: 'key1',
          align: 'center'
        },
        {
          title: 'value1',
          key: 'value1',
          dataIndex: 'value1',
          align: 'center'
        },
        {
          title: 'key2',
          key: 'key2',
          dataIndex: 'key2',
          align: 'center'
        },
        {
          title: 'value2',
          key: 'value2',
          dataIndex: 'value2',
          align: 'center'
        },
        {
          title: 'key3',
          key: 'key3',
          dataIndex: 'key3',
          align: 'center'
        },
        {
          title: 'value3',
          key: 'value3',
          dataIndex: 'value3',
          align: 'center'
        }
      ]
      this.data1 = []
      this.columns2 = [{
        title: '',
        key: '',
        dataIndex: 'col_title',
        align: 'center',
        width: columnWidth
      }]
      this.data2 = []
      this.columns3 = [
        {
        title: '',
        key: '',
        dataIndex: 'col_title',
        align: 'center',
        width: columnWidth
      },
      {
        title: '',
        key: 'value',
        dataIndex: 'value',
        align: 'center'
      }
      ]
      this.data3 = []
      this.columns4 = [{
        title: '',
        key: '',
        dataIndex: 'content',
        customRender: (text, record, index) =>
        <span style={`color:${text.indexOf('并没有') === -1 ? '#43c73d' : 'red'};white-space:normal !important`}>
          {text}
        </span>
      }]
      this.fetchData1()
      this.fetchColumns2()
      this.fetchData2()
      // eslint-disable-next-line arrow-spacing
      await this.fetchData3()
      this.fetchData4()
      this.loadingController = false
    },
    cancel() {
      // this.$router.back()
      window.close()
    },
    // 生成表格所需要的列数，”第1批，第2批“
    getColumns(tableIndex) {
      this.exp.list.forEach((v, index) => {
        this[`columns${tableIndex}`].push({
          title: `第${index + 1}批`,
          key: `第${index + 1}批`,
          dataIndex: `batch${index}`,
          align: 'center'
        })
      })
    },
    // 求批均值、批方差的平均值的公共方法
    avg(obj) {
      const day = this.exp.list.length
      let sum = 0
      for (const i in obj) {
        if (i !== 'key' && i !== 'col_title') {
          sum += Number(obj[i])
        }
      }
      return sum / day
    },
    fetchData1() {
      const exp = this.exp
      this.data1 = [
        {
          key: 0,
          key1: '试剂厂家：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.manufactor).join('、'),
          key2: '仪器：',
          value2: exp.devices.map(v => v.name).join('、'),
          key3: '记录编号：',
          value3: exp.serialNum
        },
        {
          key: 1,
          key1: '项目名称：',
          value1: exp.expName,
          key2: '校准品名称：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.name).join('、'),
          key3: '实验时间：',
          value3: `${this.$moment(exp.list[exp.list.length - 1].date).format('YYYY-MM-DD')}至${this.$moment(exp.list[0].date).format('YYYY-MM-DD')}`
        },
        {
          key: 2,
          key1: '试剂批号：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.batchNum).join('、'),
          key2: '校准品批号：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.batchNum).join('、'),
          key3: '实验人员：',
          value3: exp.users.map(v => v.name).join('、')
        }
      ]
    },
    fetchColumns2() {
      this.getColumns(2)
      // this.columns2.forEach(v=>{})
    },
    fetchData2() {
      // 遍历实验的重复次数给表格添加”重复1、重复2....那一列“
      for (let index = 0; index < this.exp.repeatNum; index++) {
        const obj = {
          key: index,
          col_title: `重复${index + 1}`
        }
        this.data2.push(obj)
      }
      // 给表格添加剩余的数据
      this.exp.list.forEach((v, index) => {
        const data = JSON.parse(v.dataDetails[this.currentConcentrationIndex].concentrationValue)
        data.forEach((m, i) => {
          this.data2[i][`batch${index}`] = m
        })
      })
    },
    async fetchData3() {
      const cloneList = this.$deepClone(this.exp.list)
      let list = []
      cloneList.forEach(v => {
        list = list.concat(JSON.parse(v.dataDetails[this.currentConcentrationIndex].concentrationValue))
      })
      // 计数
      const count = this.exp.expDays * this.exp.repeatNum
      const sum = list.reduce((a, b) => a + b, 0)
      // 均值
      const avg = sum / count
      // 标准差
      const std = Math.sqrt((list.map(v => Math.pow(v - avg, 2)).reduce((a, b) => a + b, 0)) / (count - 1))
      const std2 = std / Math.sqrt(count)
      console.log('%c 🥩 std2: ', 'font-size:20px;background-color: #FFDD4D;color:#fff;', std2)
      // t-临界值
      await getT({ freedom: count - 1, p: 1 - 0.01 / 2 }).then(res => {
        const t = res.data
        // 上置信限
        const upConfidence = avg + t * std2
        // 下置信限
        const downConfidence = avg - t * std2
        // 指定的标准差
        const assignStd = this.exp.designedStd
        // 合成标准不确定度
        const hcbzbqdd = Math.sqrt(std2 * std2 + assignStd * assignStd)
        // 上验证限
        const upValidation = avg + t * hcbzbqdd
        // 下验证限
        const downValidation = avg - t * hcbzbqdd
        this.data3 = [
        {
          key: 0,
          col_title: '计数',
          value: count
        },
        {
          key: 1,
          col_title: '均值',
          value: avg.toFixed(2)
        },
        {
          key: 2,
          col_title: '标准差',
          value: std.toFixed(2)
        },
        {
          key: 3,
          col_title: 't-临界值',
          value: t.toFixed(2)
        },
        {
          key: 10,
          col_title: '指定值',
          value: this.exp.designedValue.toFixed(2)
        },
        {
          key: 4,
          col_title: '上置信限',
          value: upConfidence.toFixed(3)
        },
        {
          key: 5,
          col_title: '下置信限',
          value: downConfidence.toFixed(3)
        },
        {
          key: 6,
          col_title: '指定的标准差',
          value: assignStd
        },
        {
          key: 7,
          col_title: '合成标准不确定度',
          value: hcbzbqdd.toFixed(3)
        },
        {
          key: 8,
          col_title: '上验证限',
          value: upValidation.toFixed(3)
        },
        {
          key: 9,
          col_title: '下验证限',
          value: downValidation.toFixed(3)
        }
      ]
      })
    },
    fetchData4() {
      const assignValue = this.data3[4].value
      const upConfidence = this.data3[5].value
      const downConfidence = this.data3[6].value
      const unit = 'mmol/L'
      let content = ''
      if (assignValue >= downConfidence && assignValue <= upConfidence) {
        content = `计算的95%置信区间为${downConfidence} ${unit}~${upConfidence} ${unit}，其包括指定值${assignValue} ${unit}，因此由这些实验数据能够证实正确度。`
      } else {
        content = `计算的95%置信区间为${downConfidence} ${unit}~${upConfidence} ${unit}，其并没有包括指定值${assignValue} ${unit}，因此由这些实验数据不能证实正确度。`
      }
      this.data4 = [{
        key: 0,
        content
      }]
    }
  }
}
</script>
<style lang="less">
@boder:1px solid #999999 !important;
.base .ant-table-title {
  text-align: center !important;
}
// .ant-table-title {
//   top: 0;
// }
.concentration {
  font-weight: 700;
  padding: 6px 5px;
  margin-right: 10px;
  cursor: pointer;
}
.active {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.pagediv {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  background: white;
}
.dataTable .ant-table-title {
  padding: 0 0 !important;
  .ant-col {
    padding: 8px 16px;
  }
}
.recordTable{
  td{
  border-right:@boder;
  border-bottom:@boder
}
  td:last-child{
      border-right:0 !important;
  }
  th{
    border-bottom: @boder;
    border-right:@boder
  }
  th:last-child{
      border-right:0 !important;
  }
  .ant-table-title{
   font-weight: 700;
   background-color: #f7f7f7;
   border-right:@boder;
   border-bottom:@boder;
  //  border-top:@boder;
   border-radius: 0;
  }
  .ant-table-content{
    border-right:@boder;
  }
  .ant-table{
    border-top: @boder;
    border-left:@boder;
    // border-bottom:@boder;
    border-radius:0
  }
}
@page {
  size: auto A4 landscape;
  margin: 5mm;
}
</style>