<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :vuexForm="vuexForm"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <!-- <template #extraInput>
      <div></div>
    </template> -->
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '@/views/analysisOfRegression/components/indexComponent'

export default {
  components: { Index },
  data() {
    return {
      xysName: 'pcaXys',
      xys: [[]],
      formAddKeyName: 'pcaFormAddKey',
      settings,
      form: {
        number: 0
      },
      vuexForm: {
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.analysisOfRegression.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        Object.assign(form, this.$store.state.analysisOfRegression.pcaForm)
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/analysisOfRegression/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>