<template>
  <div class="height">
    <loading v-if="loadingController"></loading>
    <div style="padding: 50px 0 0 0; width: 100%" class="height">
      <a-row id="share">
        <a-col :span="24">
          <div id="PrintContent" class="height">
            <div class="height">
              <div class="pagedivaa">
                <div style="font-size: 25px; text-align: center; margin-bottom: 10px">智能报告（仅供参考）</div>
                <a-collapse v-model="activeKey">
                  <a-collapse-panel id="一、基础统计" key="1" header="一、基础统计">
                    <BasicStatistics :fields="fields" ref="一、基础统计" />
                  </a-collapse-panel>
                  <a-collapse-panel id="二、基础分析" key="2" header="二、基础分析">
                    <BasicAnalysis :xys="xy" ref="二、基础分析" v-if="xy.length>0" />
                  </a-collapse-panel>
                  <a-collapse-panel id="三、智能分析" key="3" header="三、智能分析">
                    <IntelligentAnalysis
                      v-if="type.length > 0"
                      :data="data"
                      :type="type"
                      :xys="xys"
                      :settings="settings"
                      ref="三、智能分析"
                    />
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-drawer :closable="false" :visible="drawerVisible" @close="onClose">
        <a-anchor :target-offset="targetOffset">
          <a-anchor-link v-for="(obj1, i1) in pageData" :key="i1" :href="`#${obj1.title}`" :title="obj1.title">
            <a-anchor-link v-for="(obj2, i2) in obj1.content" :key="i2" :href="`#${obj2.title}`" :title="obj2.title">
              <div v-if="obj2.type === 'arry'">
                <a-anchor-link
                  v-for="(obj3, i3) in obj2.content"
                  :key="i3"
                  :href="`#${obj3.title}`"
                  :title="obj3.title"
                >
                </a-anchor-link>
              </div>
            </a-anchor-link>
          </a-anchor-link>
        </a-anchor>
      </a-drawer>
      <div style="position: fixed; top: 0px; width: 100%; height: 50px; background: #001529; z-index: 500; opacity: 1">
        <a-row type="flex" justify="end">
          <a-col class="title" style="margin-right: 20px; vertical-align: middle">
            <!-- <a-button v-print="'#PrintContent'" style="font-weight: bold; color: black; margin-top: 10px">
              打印
            </a-button> -->
            <!-- <a-button @click="shareReport">分享</a-button> -->
            <a-button @click="exportWord" style="font-weight: bold; color: black; margin-top: 10px">
              导出word文档
            </a-button>
            <a-button @click="cancel" style="margin-left: 10px; color: black; font-weight: bold">取消</a-button>
            <a style="margin-left: 10px; vertical-align: middle" @click="showDrawer">
              <a-icon type="left-square" style="font-size: 25px; color: white"></a-icon>
            </a>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import BasicAnalysis from './part/basicAnalysis'
import BasicStatistics from './part/basicStatistics'
import IntelligentAnalysis from './part/intelligentAnalysis'
import { exportWord } from '@/api/report'
import Loading from './components/loading'
import Conclusion from '@/components/myComponents/Conclusion'
import { postReportShare } from '@/api/reportShare'
export default {
  components: { BasicAnalysis, BasicStatistics, IntelligentAnalysis, Loading, Conclusion },
  mounted() {
    this.targetOffset = 50
    const _this = this
    this.timer = setInterval(() => {
      // 判断文档和所有子资源(图片、音视频等)已完成加载
      if (_this.$store.state.report.reqNumOfFactOfReportPage === _this.$store.state.report.reqNumOfShouldOfReportPage) {
        // 执行方法
        this.$nextTick(() => {
          _this.getPageData()
          _this.loadingController = false
        })
        window.clearInterval(_this.timer)
      }
    }, 500)
  },
  created() {
    this.getAnchorWidth()
    this.getData()
    this.$store.dispatch('resetShouldAndFact')
  },
  data() {
    return {
      activeKey: ['1', '2', '3'],
      loadingController: true,
      dataSource: [],
      data: [],
      settings: [],
      type: [],
      targetOffset: undefined,
      anchorWidth: '0', // 锚点宽度
      pageData: [],
      fields: [], // 所有的变量 基础统计用到
      xy: [], // 关系型[{x:[],y:[],title}:1个关系,type:关系类型vv-定量+定量,vc-定量+定类,cc-定类+定类] 基础分析用到
      drawerVisible: false
    }
  },
  methods: {
    shareReport() {
      const html = document.getElementById('share').outerHTML
      const userID = this.$store.state.user.info.id
      const req = {
        title: '智能报告',
        userID,
        html
      }
      console.log(req)
      postReportShare(req).then(res => {
        console.log('%c 🍬 res: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', res);
      })
    },
    showDrawer() {
      this.drawerVisible = true
    },
    onClose() {
      this.drawerVisible = false
    },
    getPageData() {
      const pageData = []
      const refs = this.$refs
      console.log('%c 🍮 refs: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', refs);
      for (const firstTitle in refs) {
        // firstTitle例如智能报告
        const firstContent = refs[firstTitle]
        const firstObj = {
          title: firstTitle,
          content: []
        }
        firstContent.$refs.title.forEach((secondTitle, secondIndex) => {
          // secondTitle例如”不同性别与年龄的方差分析“”身高与体重的线性回归“
          const secondObj = {
            title: secondTitle.innerText,
            content: [],
            text: [],
            type: 'arry' // arry说明还有子title,obj说明只有这一层
          }
          let thirdRefs = firstContent.$refs.content[secondIndex].$refs
          // 像均值分析中会出现页面复用的情况，因此ref会是空的，那么要重新去children里面找用的同一个组件。
          if (Object.keys(thirdRefs).length === 0) {
            thirdRefs = firstContent.$refs.content[secondIndex].$children[0].$refs
          }
          this.divideTitleTextContent(thirdRefs, secondObj)
          firstObj.content.push(secondObj)
        })
        pageData.push(firstObj)
      }
      this.pageData = pageData
      console.log('pageData', pageData)
    },
    divideTitleTextContent(vm, parentObj) {
      const keysList = Object.keys(vm).map(str => str.split(','))
      // const values = Object.values(vm)
      var title = keysList.filter(r => r[0] === 'title') // 找出是否有title，，没有说明是2级标题
      if (title.length > 0) {
        // 遍历一个模块（像单因素方差分析的index为一个模块）的refs，
        for (const key in vm) {
          const value = vm[key]
          // 把refs中的key转换成数组形式
          const key_list = key.split(',')
          // 如果key里包含title，并且key_list的长度只有2的时候，说明是3级标题，例如（3.1.1 正态性检验）
          if (key_list[0] === 'title') {
            // 定义一个num
            let num = null
            // 从key中查找有没有'for'这个标志
            const for_num = key_list.find(v => v.indexOf('for') !== -1)
            // 如果有'for'标志，那么把for后的数字赋值给num，num表示当前模块中包含的内容数
            if (for_num) num = +for_num.split(':')[1]
            const obj = {
              title: Array.isArray(value) ? value[0].innerText : value.innerText,
              content: []
            }
            // 重新遍历refs，找到与title相关的内容，并且生成obj添加到content数组中
            for (const key2 in vm) {
              const value2 = vm[key2]
              const key_list2 = key2.split(',')
              // 找到text并且添加
              if (key_list2[0] === 'text' && key_list2[1] === key_list[1]) {
                // 如果text的value值是数组，那么要继续遍历进去
                if (Array.isArray(value2)) {
                  value2.forEach((text_dom, text_index) => {
                    const text_obj = {
                      type: 'text',
                      value: text_dom.innerText,
                      order: num ? text_index * num + +key_list2[2] : +key_list2[2]
                    }
                    obj.content.push(text_obj)
                  })
                } else {
                  const text_obj = {
                    type: 'text',
                    value: value2.innerText,
                    order: +key_list2[2]
                  }
                  obj.content.push(text_obj)
                }
              }
              // 找到content，进去遍历添加表格或者图片。
              if (key_list2[0] === 'content' && key_list2[1] === key_list[1]) {
                if (Array.isArray(value2)) {
                  value2.forEach((vm_content, vm_index) => {
                    this.handle_content(vm_content, num, vm_index, obj)
                  })
                } else {
                  this.handle_content(value2, 0, 0, obj)
                }
              }
              if (key_list2[0] === 'sub_title' && key_list2[1] === key_list[1]) {
                const sub_title_obj = {
                  type: 'sub_title',
                  value: Array.isArray(value2) ? value2[0].innerText : value2.innerText,
                  order: +key_list2[2]
                }
                obj.content.push(sub_title_obj)
              }
            }
            parentObj.content.push(obj)
          }
        }
      } else {
        parentObj.type = 'obj'
        // 按照content进行组合
        var content = keysList.filter(r => r[0] === 'content')

        content.forEach(c => {
          var obj = []
          var text = keysList.filter(r => r[0] === 'text' && r[1] === c[1])
          text.map(t => {
            var textvalue = ''
            if (t.length === 3) {
              var textObj = vm[t[0] + ',' + t[1] + ',' + t[2]]
              textvalue = Array.isArray(textObj) ? textObj[0] : textObj
            } else {
              textvalue = vm[t[0] + ',' + t[1]]
            }
            const text_obj = {
              type: 'text',
              value: textvalue.innerText,
              order: t.length === 3 ? Number(t[2]) : 1
            }
            obj.push(text_obj)
          })
          var contentValue = vm[c[0] + ',' + c[1]]
          contentValue = Array.isArray(contentValue) ? contentValue[0] : contentValue
          if (contentValue.tables && contentValue.tables.length > 0) {
            const table_obj = {
              type: 'table',
              // title: table.title(),
              value: contentValue.tables,
              order: 2
            }
            obj.push(table_obj)
          }
          if (contentValue.imgs && contentValue.imgs.length > 0) {
            const img_obj = {
              type: 'img',
              value: contentValue.imgs,
              layout: contentValue.imgs.length > 1 ? ('imgsLayout' in contentValue ? contentValue.imgsLayout : 2) : 1,
              order: 5
            }
            obj.push(img_obj)
          }

          parentObj.content.push(obj)
        })
      }
    },
    // 把content里的图表添加进去
    handle_content(vm_content, num, index, obj) {
      /**
       * @param vm_content content指向的组件
       * @param num 一个模块包含的数量
       * @param index for循环出来的index
       * @param obj 添加图标的容器
       */
      const content_refs = vm_content.$refs
      // 遍历content中的refs对象
      for (const content_key in content_refs) {
        const content_value = content_refs[content_key]
        const content_key_list = content_key.split(',')
        // 如果key中有imgs
        if (content_key_list[0] === 'imgs') {
          // 如果key的value是数组，那么继续遍历
          if (Array.isArray(content_value)) {
            const imgValueAry = []
            // 把img的各个值放在一个数组里
            content_value.forEach(img => {
              imgValueAry.push('currentSrc' in img ? img.currentSrc : img.imgBytes) // 如果是dom，那么获取currentSrc；如果是组件，那么获取imgBytes变量
            })
            const img_obj = {
              type: 'imgAry',
              value: imgValueAry,
              layout: 'imgsLayout' in vm_content ? vm_content.imgsLayout : 2,
              order: index * num + +content_key_list[1]
            }
            obj.content.push(img_obj)
          } else {
            const img_obj = {
              type: 'img',
              value: content_value.currentSrc,
              order: index * num + +content_key_list[1]
            }
            obj.content.push(img_obj)
          }
        }
        // 如果key中有tables
        if (content_key_list[0] === 'tables') {
          // 如果key的value是数组，那么继续遍历
          if (Array.isArray(content_value)) {
            content_value.forEach((table, i) => {
              const table_obj = {
                type: 'table',
                title: table.title ? table.title() : null, // 由于ant-design-vue中表格的title只能以函数形式表示，因此要加()
                columns: table.columns,
                data: table.dataSource,
                settings: 'settings' in table.$attrs ? table.$attrs.settings : null,
                showHeader: table.showHeader,
                order: index * num + +content_key_list[1]
              }
              obj.content.push(table_obj)
            })
          } else {
            const table_obj = {
              type: 'table',
              columns: content_value.columns,
              title: content_value.title ? content_value.title() : null,
              data: content_value.dataSource,
              settings: 'settings' in content_value.$attrs ? content_value.$attrs.settings : null,
              showHeader: content_value.showHeader,
              order: index * num + +content_key_list[1]
            }
            obj.content.push(table_obj)
          }
        }
      }
    },
    exportWord() {
      exportWord({ pageData: this.pageData }).then(res => {
        console.log('%c 🥛 pageData: ', 'font-size:20px;background-color: #B03734;color:#fff;', this.pageData);
        this.Upexcele(res)
      })
    },
    Upexcele(value) {
      const url = window.URL.createObjectURL(value)
      const a = document.createElement('a')
      a.href = url
      const timeStr = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      a.download = `${timeStr}_MedSPSS_Report.docx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },
    getData() {
      const datas = this.$store.state.report.data
      console.log('%c 🍥 datas: ', 'font-size:20px;background-color: #42b983;color:#fff;', datas);
      this.data = datas.part3.data
      this.type = datas.part3.type
      this.xys = datas.part3.new_xys
      this.xys2 = datas.part3.xys2
      this.settings = datas.part3.settings
      this.fields = datas.part1.xys2
      this.xy = this.getBaseAnysisxy(datas.part2)
    },
    // 获取关系，基础分析用到
    getBaseAnysisxy(part2) {
      var xyArry = []
      // 多组关系循环，xy其中的一组关系
      part2.xys2.map((xy, i) => {
        var x = xy[0].list // 因变量
        var y = xy[1].list // 自变量
        var title = ''
        var type = ''
        // 1定类+1定量
        title =
          '不同' +
          x
            .map(r => {
              return r.name
            })
            .join(',') +
          '与' +
          y
            .map(r => {
              return r.name
            })
            .join(',') +
          '的关系'
        var fields = x.concat(y)
        // 定类
        var cF = fields.filter(r => r.type === '类')
        // 定量
        var vf = fields.filter(r => r.type === '量')
        if (vf.length === 0 && cF.length === 2) {
          // 0个定量+2个定类
          type = 'cc'
        } else if (vf.length === 1 && cF.length === 1) {
          // 1个定量+1个定类
          type = 'vc'
        } else if (vf.length === 1 && cF.length === 2) {
          // 1个定量+2个定类
          type = 'vcc'
        } else if (vf.length === 2 && cF.length === 0) {
          // 2个定量+0个定类
          type = 'vv'
        } else if (vf.length === 2 && cF.length === 1) {
          // 2个定量+1个定类
          type = 'vvc'
        } else if (vf.length === 2 && cF.length === 2) {
          // 2个定量+2个定类
          type = 'vvcc'
        } else if (vf.length > 2 && cF.length === 0) {
          // 多个定量+0个定类
          type = 'vvv'
        } else if (vf.length > 2 && cF.length === 1) {
          // 多个定量+1个定类
          type = 'vvvc'
        } else if (cF.length > 2 || (vf.length > 2 && cF.length > 1)) {
          // 多个定量+>2个定类
          type = 'vvccc'
        }
        part2.data[i].map(r => {
          vf.map(a => {
            r[a.name] = Number(r[a.name])
          })
        })
        xyArry.push({ x, y, title, type, data: part2.data[i] })
      })
      return xyArry
    },
    cancel() {
      this.$router.push('/intelligenceReports')
      //   window.close()
    },
    // 自动计算锚点的宽度
    getAnchorWidth() {
      const width = document.body.clientWidth
      this.anchorWidth = `${width}px`
    }
  },
  computed: {
    errorMsgs() { return this.$store.state.report.errorMsgs }
  },
  watch: {
    errorMsgs(newVal) {
      if (newVal.length > 0) {
        window.clearInterval(this.timer)
        this.$router.go(-1)
        // 消除之前的异常弹框
        this.$notification.destroy()
        // 弹出错误消息框
        this.$notification.error({
          message: `样本数据质量不佳`,
          description: () => {
            return (newVal.map((v, i) => <p style="color:red">{i + 1}. {v.moduleName}：{v.msg}</p>))
          },
          style: {
            width: '700px',
            marginLeft: `${335 - 700}px`
          },
          placement: `bottomRight`,
          duration: 0
        })
      }
    }
  }
}
</script>

<style lang="less">
@import '~@/less/report.less';
</style>
