<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{ xys, form }">
      <div
        v-if="xys[0].length > 0 && xys[1].length > 0 && form.option && form.proportion"
        style="display: flex; align-items: center"
      >
        <div v-for="(item, index) in H0(xys, form)" :key="index"><span v-html="item"></span></div>
        <span style="margin-left: 20px; cursor: pointer" @click="reverseOption()">
          <a-icon type="retweet" style="font-size: 20px; color: rgb(24, 144, 255)" />
        </span>
      </div>
    </template>
    <template #H1="{ xys, form }">
      <div
        v-if="xys[0].length > 0 && xys[1].length > 0 && form.option && form.proportion"
        style="display: flex; align-items: center"
      >
        <div v-for="(item, index) in H1(xys, form)" :key="index"><span v-html="item"></span></div>
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
import Minimum from '@/views/hypothesisTest/components/minimum'

export default {
  components: { Index, Minimum },
  data() {
    return {
      xysName: 'sybbljyXys',
      xys: [[], []],
      formAddKeyName: 'sybbljyFormAddKey',
      settings,
      form: {
        proportion: '0',
        judge: '＝',
        option: '',
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.proportion = Number(that.form.proportion)
        form.a = parseFloat(that.form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    },
    reverseOption() {
      var xys = this.$store.state.hypothesis[this.xysName]
      var lei = xys[1][0]
      lei = [lei[1], lei[0], lei[2]]
      xys[1].splice(0, 1, lei)
    }
  },
  computed: {
    H0() {
      return (xys, form) => {
        form.H0 = [`&lt;${xys[1][0][0]}&gt;的比率值 - &lt;${xys[1][0][1]}&gt;的比率值 ${form.judge} ${Number(form.proportion) * 100}%`]
        return form.H0
      }
    },
    H1() {
      return (xys, form) => {
        form.H1 = [`&lt;${xys[1][0][0]}&gt;的比率值 - &lt;${xys[1][0][1]}&gt;的比率值 ${this.$hypothesis.replaceJudge(form.judge)} ${Number(form.proportion) * 100}%`]
        return form.H1
      }
    }
  }
}
</script>