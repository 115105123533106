<template>
  <page-header-wrapper :breadcrumb="null">
    <template v-slot:extra>
      <sample></sample>
    </template>
    <loading v-show="loadingController"></loading>
    <a-layout class="divContent" id="divContent">
      <a-row style="width: 100% !important">
        <a-col :span="5">
          <method></method>
        </a-col>
        <a-col :span="19" style="overflow: auto; border-left: 1px solid rgb(232, 232, 232)" id="resultContent">
          <component
            ref="child"
            :is="currentComponent"
            @showLoading="loading"
            @hideLoading="loadingController = false"
            :key="key"
          ></component>
        </a-col>
      </a-row>
    </a-layout>
  </page-header-wrapper>
</template>
<script>
import Method from './components/method'
import Loading from './components/loading'
import { AutoHeight } from '@/utils/util'
import Sample from '@/views/customchart/components/sample.vue'
import Cox from '@/views/analysisOfRegression/components/generateAnalysis/cox/result.vue'
import Dysscfx from '@/views/analysisOfRegression/components/generateAnalysis/dysscfx/result.vue'
import Fitting from '@/views/analysisOfRegression/components/fitting/fitting/result'
import GeneralizedOrdinaryLeastSquares from '@/views/analysisOfRegression/components/linearRegression/generalizedOrdinaryLeastSquares/result'
import Logic from '@/views/analysisOfRegression/components/nonlinearRegression/logic/result.vue'
import MultipleLogic from '@/views/analysisOfRegression/components/nonlinearRegression/multipleLogic/result.vue'
import OrdinaryLeastSquares from '@/views/analysisOfRegression/components/linearRegression/ordinaryLeastSquares/result'
import PCA from '@/views/analysisOfRegression/components/pca/pca/result.vue'
import PolynomialRegression from '@/views/analysisOfRegression/components/nonlinearRegression/polynomialRegression/result'
import RobustRegression from '@/views/analysisOfRegression/components/linearRegression/robustRegression/result'
import SplineSmooth from '@/views/analysisOfRegression/components/smooth/splineSmooth/result'
export default {
  components: {
    Cox,
    Dysscfx,
    Sample,
    Loading,
    Method,
    PCA,
    Fitting,
    GeneralizedOrdinaryLeastSquares,
    Logic,
    MultipleLogic,
    OrdinaryLeastSquares,
    PolynomialRegression,
    RobustRegression,
    SplineSmooth
  },
  created() {
    // 高度自定义使用
    this.autoHeightChange()
    // 向sessionstorage里存数据
    const form = this.$store.state.analysisOfRegression.dybtjy
    const method = this.$store.state.analysisOfRegression.method
    if (JSON.stringify(form) !== '{}' && method) {
      sessionStorage.setItem('form', JSON.stringify(form))
      sessionStorage.setItem('method', method)
    } else {
      this.$store.state.analysisOfRegression.dybtjy = JSON.parse(sessionStorage.form)
      this.$store.state.analysisOfRegression.method = sessionStorage.method
    }
    //
    this.currentComponent = this.$store.state.analysisOfRegression.method
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  data() {
    return {
      // 计算中的控制变量
      loadingController: true,
      currentComponent: '',
      key: 0
    }
  },
  methods: {
    /**
    * 自定义高度
    */
    autoHeightChange() {
      // 高度自定义使用
      var min = ['divContent']
      var max = ['method', 'resultContent']
      AutoHeight(this, min, max, 176)
    },
    refresh() {
      this.loading()
      // 组件的key值改变，那么会重新渲染组件
      this.$refs.child.fetchResult()
    },
    loading() {
      this.loadingController = true
    }
  },
  computed: {
    outlier() {
      return this.$store.state.samples.filtersAll.outlier !== 0
    },
    without0() {
      return this.$store.state.samples.filtersSimple.without0 !== 0
    },
    setting() {
      return this.$store.state.samples.settingController
    }
  },
  watch: {
    outlier() {
      this.refresh()
    },
    without0() {
      this.refresh()
    },
    setting() {
      this.refresh()
    }
  }
}
</script>
<style lang="less" >
h1 {
  font-size: 16px;
  color: #1890ff;
}
</style>

<style lang="less" scoped>
body {
  overflow: hidden !important;
}
</style>