<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{xys,form}">
      <div v-if="xys[0].length>0&&form.option&&form.proportion" style="display:flex;align-items:center">
        <Minimum :up="form.option" :down="xys[0][0].slice(-1)[0]"></Minimum> {{ form.judge }} {{ Number(form.proportion)*100 }}%
      </div>
    </template>
    <template #H1="{xys,form}">
      <div v-if="xys[0].length>0&&form.option&&form.proportion" style="display:flex;align-items:center">
        <Minimum :up="form.option" :down="xys[0][0].slice(-1)[0]"></Minimum> {{ $hypothesis.replaceJudge(form.judge) }} {{ Number(form.proportion)*100 }}%
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Minimum from '@/views/hypothesisTest/components/minimum'
import Index from '../../indexComponent'
export default {
  components: { Index, Minimum },
  data() {
    return {
      xysName: 'dybbljyXys',
      xys: [[]],
      formAddKeyName: 'dybbljyFormAddKey',
      settings,
      form: {
        proportion: '',
        judge: '＝',
        option: '',
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.proportion = Number(that.form.proportion)
        form.a = parseFloat(that.form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>