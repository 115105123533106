var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block !important", margin: "0 5px" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            "border-bottom": "1px solid black",
            padding: "0 2px",
            "line-height": "1.5",
            "text-align": "center"
          }
        },
        [_vm._v(_vm._s(_vm.up))]
      ),
      _c(
        "div",
        {
          staticStyle: {
            padding: "0 2px",
            "line-height": "1.5",
            "text-align": "center"
          }
        },
        [_vm._v(_vm._s(_vm.down))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }