import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
var xysName = 'fittingXys'

export const settings = [{
    id: 0,
    prop: 'lei1',
    label: '变量【定类或定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'dropSelect', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: '量类', // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    min: 1,
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    docLenLimit: ['量', 20],
    value: '', // 用来决定children列表里哪些input是显示的
    options: [{
        label: '变量【定类】',
        value: '类',
        type: 'drop',
        optLimit: null,
        dropType: ['类']
      },
      {
        label: '变量【定量】',
        value: '量',
        type: 'drop',
        optLimit: null,
        dropType: ['量']
      }
    ], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个，定量变量的样本数不得大于20)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项
    xysIndex: 0,
    onChange(obj, item, that) {
      if (item.type === '类') {
        onChange.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, item)
      } else if (item.type === '量') {
        onChange.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.type === '类') {
        onClose.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, index)
      } else if (item.type === '量') {
        onClose.liang(that.$store.state.hypothesis[xysName], this.xysIndex, index)
      }
    }
  },
  {
    id: 1,
    prop: 'weight',
    label: '加权项【定量】',
    required: false,
    parentValue: 1,
    type: 'drop',
    dropType: ['量'],
    min: 1,
    num: 1,
    value: '',
    options: [],
    list: [],
    divHelp: ['请拖入左侧变量：(个数＝1个)'],
    children: [],
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    label: '自定义期望频数', // 在不需要label的时候，一定要定义出一个空字符串。
    componentName: 'ExpFrequency',
    parentValue: 1,
    type: 'component',
    children: []
  }
]