<template>
  <div>
    <div v-for="(itemForm, index) in itemForms" :key="index">
      <a-form-model-item :label="`${name}名称`">
        <a-input :style="{ width: inputWidth }" :placeholder="`请输入${name}名称`" v-model="itemForm.name" />
        <span
          v-if="itemForms.length!==1"
          style="color: #1890ff; margin-left: 10px; cursor: pointer"
          @click="deleteItem(index)"
        >
          <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span
        >
        <span
          v-if="index === itemForms.length - 1"
          style="color: #1890ff; margin-left: 10px; cursor: pointer"
          @click="addItem"
        >
          <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
      </a-form-model-item>
      <a-form-model-item :label="`${name}厂家`">
        <a-input :style="{ width: inputWidth }" :placeholder="`请输入${name}厂家`" v-model="itemForm.manufactor" />
      </a-form-model-item>
      <a-form-model-item :label="`${name}批号`">
        <a-input :style="{ width: inputWidth }" :placeholder="`请输入${name}批号`" v-model="itemForm.batchNum" />
      </a-form-model-item>
      <a-form-model-item
        :label="`${name}有效截止日`"
      >
        <a-date-picker
          :style="{ width: inputWidth }"
          :value="itemForm.expiryDate?$moment(itemForm.expiryDate):undefined"
          @change="(date, dateString) => (date?(itemForm.expiryDate = date.format()):(itemForm.expiryDate = undefined))"
        />
      </a-form-model-item>
      <a-divider v-if="index !== itemForms.length - 1" dashed style="min-width: 50%; width: 50%" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['name', 'inputWidth', 'itemForms'],
  created() {
    this.indexItemForm = this.$deepClone(this.itemForms[0])
  },
  data() {
    return {

    }
  },
  methods: {
    addItem() {
      this.itemForms.push(this.indexItemForm)
    },
    deleteItem(index) {
      this.itemForms.splice(index, 1)
    }
  }
}
</script>