<template>
  <div>
    <div
      style="position: fixed;top: 0px;width:100%;height:50px;background:#001529;z-index:9999;opacity: 0.5;"
    >
      <a-row type="flex" justify="end">
        <a-col class="title" style="margin-right:20px;">
          <a-button v-print="'#PrintContent'" style="font-weight: bold;color:black;margin-top:10px">打印</a-button>
          <a-button
            @click="cancel"
            style="margin-left:10px;color:black;
      font-weight: bold;"
          >取消</a-button>
        </a-col>
      </a-row>
    </div>
    <div id="PrintContent">
      <div v-if="printModel==='linchuangyyfxscjlb'">
        <linchuangyyfxscjlb v-for="(item,i) in answers" :key="i" :data="item" :ids="questions" ></linchuangyyfxscjlb>
      </div>
      <div v-if="printModel==='linchuangyyfxscjlbSimple'">
        <linchuangyyfxscjlbSimple v-for="(item,i) in answers" :key="i" :data="item" :ids="questions" ></linchuangyyfxscjlbSimple>
      </div>
    </div>
  </div>
</template>
<script>
import { Print } from '@/api/questionnaire'
import { Base64 } from 'js-base64'
import Linchuangyyfxscjlb from './print/linchuangyyfxscjlb.vue'
import LinchuangyyfxscjlbSimple from './print/linchuangyyfxscjlbSimple'

export default {
    components: { Linchuangyyfxscjlb, LinchuangyyfxscjlbSimple },
    data() {
        return {
            id: Number(Base64.decode(this.$route.params.id)),
            answers: [],
            questions: [],
            printModel: ''
        }
    },
    created() {
        var parm = { id: this.id, answerIds: sessionStorage['PrintIDs'] && JSON.parse(sessionStorage['PrintIDs']) }
        Print(parm).then(res => {
            this.answers = res.printAnswers
            this.printModel = res.printModel
        })
    },
    methods: {
         cancel() {
      window.close()
    }
    }

}
</script>
<style lang="less" scoped>
.title {
  font: 2em sans-serif;
  text-align: center;
}
</style>