import {
  onChange,
  onClose
} from '@/views/analysisOfRegression/utils/inputItemsClass'
var xysName = 'dysscfxXys'

export const settings = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      if (item.optionInit.length > 0) {
        onChange.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, item)
      } else {
        onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.optionInit.length > 0) {
        onClose.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, index)
      } else {
        onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
      }
    }
  },
  {
    id: 1,
    index: 1,
    prop: 'time',
    label: '生存时间y1【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      if (item.optionInit.length > 0) {
        onChange.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, item)
      } else {
        onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.optionInit.length > 0) {
        onClose.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, index)
      } else {
        onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
      }
    }
  },
  {
    id: 2,
    index: 2,
    prop: 'state',
    label: '生存状态y2【二分类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    optLimit: 2,
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      if (item.optionInit.length > 0) {
        onChange.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, item)
      } else {
        onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.optionInit.length > 0) {
        onClose.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, index)
      } else {
        onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
      }
    }
  },
  {
    label: '生存状态y2中作为1的项', // 在不需要label的时候，一定要定义出一个空字符串。
    prop: 'leiOptions',
    rules: [
      (callback, v, _this) => {
        if (_this.form.yAs1 || _this.form.yAs1 === 0) callback()
        callback(`请选择生存状态y2【二分类】中作为1的项`)
      }
    ],
    componentName: 'LeiOptions2',
    parentValue: 1,
    type: 'component',
    children: []
  }
]