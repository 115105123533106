import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
import {
  hypothesis
} from '@/utils/util'
var xysName = 'duoysfcfxXys'
var Control = {
  change(settings, id, item) {
    var control = hypothesis.findChildren(id, settings)
    console.log('%c 🌮 control: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', control)
    control.options.push(item.name)
    // control.value.push(item.name)  这一行是控制交互因素默认选不选中的
    return control
  },
  close(settings, id, item) {
    var control = hypothesis.findChildren(id, settings)
    var index = control.options.indexOf(item.name)
    var v = control.options.splice(index, 1)
    var i = control.value.indexOf(v[0])
    if (i !== -1) control.value.splice(i, 1)
    return control
  }
}
var Step = {
  ary: ['一', '二', '三', '四', '五', '六'],
  main(settings, length, that) {
    var step = hypothesis.findChildren(4, settings)
    var zhastr = 0
    if (step.options.length > 0) zhastr = step.options.slice(-1)[0][0]
    var num = this.ary.indexOf(zhastr) + 1
    if (length === 1 && step.options.length === 0) {
      return {
        prop: 'step',
        value: []
      }
    } else if (length > num) {
      return Step.change(step, length, that)
    } else if (length < num) {
      return Step.close(step, length, that)
    }
  },
  change(step, length) {
    // 往阶数里添加值
    var str = this.ary[length - 1] + '阶'
    step.options.push(str)
    step.value.push(str)
    return step
  },
  close(step, length) {
    // 把阶数里多的删除
    var index = step.options.indexOf(this.ary[length] + '阶')
    var v = step.options.splice(index, 1)
    var i = step.value.indexOf(v[0])
    if (i !== -1) step.value.splice(i, 1)
    return step
  }
}

export const settings = [{
    id: 1,
    prop: 'liang1',
    label: '样本【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    id: 0,
    prop: 'lei',
    label: '多因素【定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    min: 3,
    num: 5,
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(3个 ≤ 个数 ≤ 5个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项
    onChange(obj, item, that) {
      onChange.manyLei(that.$store.state.hypothesis[xysName], this.id, item)
      // 改变定制变量的options
      var control = Control.change(that.settings, 3, item)
      if (control.value.length >= 2) var step = Step.main(that.settings, control.value.length)
      // 触发formAddKey，向表单里提交值
      var list = [control, step, {
        prop: 'leiList',
        value: control.options
      }]
      list.forEach(element => {
        element = element || {
          prop: 'step',
          value: []
        }
        that.formAddKey(element)
      })
    },
    onClose(obj, item, that, index) {
      onClose.manyLei(that.$store.state.hypothesis[xysName], this.id, index)
      // 改变定制变量的options
      var control = Control.close(that.settings, 3, item)
      var step = Step.main(that.settings, control.value.length)
      // 触发formAddKey，向表单里提交值
      var list = [control, step]
      list.forEach(element => {
        if (element) that.formAddKey(element)
      })
    }
  },

  // {
  //   id: 2,
  //   prop: 'liang2',
  //   label: '协变量【定量】', // 字段名(必填项)
  //   required: false,
  //   parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
  //   type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
  //   dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
  //   min: 0,
  //   num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
  //   value: '', // 用来决定children列表里哪些input是显示的
  //   options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
  //   list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
  //   divHelp: ['请拖入左侧变量：(个数＝1个)（选填）'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
  //   children: [], // 必填项
  //   onChange(obj, item, that) {
  //     onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
  //   },
  //   onClose(obj, item, that, index) {
  //     onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
  //   }
  // },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  },
  {
    id: 3,
    prop: 'control',
    label: '交互因素', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'checkbox', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    value: [], // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    children: [], // 必填项
    onChange(obj, that) {
      // 通过点击交互因素的值来改变阶数里的值的变化
      const step = Step.main(that.settings, obj.value.length, that)
      // 把阶数的值传到form里去
      that.$emit('updateForm', step.prop, step.value)
    }
  },
  {
    id: 4,
    prop: 'step',
    label: '选择阶数', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'checkbox', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    value: [], // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    children: [] // 必填项
  }
]