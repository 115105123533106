var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "start",
        "align-items": "center"
      }
    },
    [
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.columns,
          "data-source": _vm.data,
          pagination: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }