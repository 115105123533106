<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{xys}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        &lt;{{ xys[0][0].slice(-1)[0] }}&gt;中任意两组样本&lt;{{ xys[1][0] }}&gt;的总体均数相等
      </div>
    </template>
    <template #H1="{xys}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <div v-if="xys[0].length > 0 && xys[1].length > 0">
          &lt;{{ xys[0][0].slice(-1)[0] }}&gt;中任意两组样本&lt;{{ xys[1][0] }}&gt;的总体均数不等或不全相等
        </div>
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'gamesHowellXys',
      xys: [[], []],
      formAddKeyName: 'gamesHowellFormAddKey',
      settings,
      form: {
        a: '5',
        method: 0
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>