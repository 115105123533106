<template>
  <page-header-wrapper
    :breadcrumb="null"
    :tab-list="tabList"
    :tab-active-key="tabActiveKey"
    :tab-change="handleTabChange"
    class="minHeight"
  >
    <router-view />
  </page-header-wrapper>
</template>
<script>
const getActiveKey = path => {
  if (path.indexOf('question') > -1) {
    return '1'
  } else if (path.indexOf('file') > -1) {
    return '2'
  }
}
export default {
  data() {
    return {
      tabList: [
        // { key: '1', tab: '量表(问卷)' }
        { key: '1', tab: '量表(问卷)' }
      ],
      tabActiveKey: ''
    }
  },
  created() {
    if (
      !this.$store.state.user.info.org ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowFile) ||
      (this.$store.state.user.info.org && this.$store.state.user.info.org.isShowFile === null)
    ) {
      this.tabList.push(
        { key: '2', tab: '文件' })
    }
    this.$router.push(this.$store.state.user.roles === 'person' ? '/file' : '/question')
    this.tabActiveKey = getActiveKey(this.$route.path)
    // this.handleTabChange()
    this.$watch('$route', val => {
      if (val.path === '/data') {
        this.$router.push(this.$store.state.user.roles === 'person' ? '/file' : '/question')
      }
      this.tabActiveKey = getActiveKey(val.path)
    })
  },
  mounted() {
    this.tabActiveKey = getActiveKey(this.$route.path)
    this.$nextTick(() => {
      if (this.$store.state.user.roles === 'person') this.addVipIcon(['量表(问卷)'])
    })
  },
  methods: {
    handleTabChange(key) {
      this.tabActiveKey = key
      switch (key) {
        case '1':
          this.$router.push('/question')
          break
        case '2':
          this.$router.push('/file')
          break
      }
    },
    addVipIcon(moduleNameAry) {
      /**
       * 该方法在需要的模块旁边加上vip图标
       * @param moduleNameAry 存放需要添加vip图标的的模块名的数组
       */
      const domAry = document.getElementsByClassName('ant-tabs-tab')
      domAry.forEach(itemDom => {
        if (moduleNameAry.includes(itemDom.innerText)) {
          const icon = `<img src="/vip.png" style="display:inline-block;position:relative;bottom:8px;left:5px">`
          // 在a标签内部的最后一个子节点之后插入html
          itemDom.insertAdjacentHTML('beforeend', icon);
        }
      })
    }
  }
}
</script>