export function get_settings_obj(settings) {
  /** 将settings整理成obj格式，方便取用。
   * @param settings 每个研究方向中获取到的那个settings
   */
  const norm = settings.options.methods.find((v) => v.name === '正态检验')
  const homogeneous = settings.options.methods.find((v) => v.name === '方差齐性检验')
  const anova = settings.options.methods.find((v) => v.name === '参数检验')
  const postHoc = settings.options.methods.find((v) => v.name === '事后检验')
  return {
    norm,
    homogeneous,
    anova,
    postHoc
  }
}