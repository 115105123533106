<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.regression}`"
        :id="`3.${secondIndex}.${thirdIndex.regression} 回归`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.regression }} 回归
      </div>
      <div>
        <Logic :ref="`content,1`" :xys="xys" :data="data">
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.regression},1`">
              <span> 使用{{ xys_str }}做逻辑回归分析，分析结果如下所示： </span>
            </p>
          </template>
          <template #text2="{ formula }" class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.regression},3`">
                <span>由上表可得：回归方程式为：</span>
                <br />
                <span>{{ formula }}</span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.regression},4`">
              <span>逻辑回归的混淆矩阵如下表所示：</span>
            </p>
          </template>
          <template #text4 class="text">
            <p :ref="`text,${thirdIndex.regression},6`">
              <span v-if="xys[0].length === 1">{{ xys[0][0] }}与{{ xys[1][0] }}的线性回归图如下图所示。</span>
              <span v-else>
                {{ `${xys_str}的roc结果相关汇总表和图如下所示。` }}
              </span>
            </p>
          </template>
        </Logic>
      </div>
    </div>
  </div>
</template>
<script>
import Logic from '@/views/report/modules/hypothesis/logic.vue'
import Resid from '@/views/report/modules/hypothesis/resid.vue'
import Resid3 from '@/views/report/modules/hypothesis/resid3.vue'
import Resid4 from '@/views/report/modules/hypothesis/resid4.vue'
export default {
  name: 'OrdinaryLeastSquaresMain',
  components: { Logic, Resid, Resid3, Resid4 },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  data() {
    return {
      resid: null,
      resid_norm_p: null,
      dw: null,
      dw_table: null,
      method_name: null // 正态检验用的算法名
    }
  },
  computed: {
    thirdIndex() {
      const regression = 1
      const resid = regression + 1
      // const resid3 = regression + 2
      const resid4 = regression + 2
      return { regression, resid, resid4 }
    },
    xys_str() {
      const { xys } = this
      const x_list = xys[0]
      const y_list = xys[1]
      const x_str = x_list.map(v => Array.isArray(v) ? `“${v[v.length - 1]}”` : `“${v}”`).join('、')
      const y_str = `“${y_list[0].slice(-1)[0]}”`
      return `${x_str}与${y_str}`
    },
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>