<template>
  <page-header-wrapper :breadcrumb="null">
    <div>
      <a-layout class="divContent" id="divContent">
        <a-row style="width: 100% !important">
          <a-col :span="4" style="border-right:1px solid #dedede" >
            <exp-menu @changeClick="changeComponent"></exp-menu>
          </a-col>
          <a-col :span="20" id="chartContent" style="overflow: auto">
            <!-- <component :is="currentComponent" @changeComponent="changeComponent" style="padding:20px 20px" :parm="parm"></component> -->
            <router-view style="padding:20px"/>
          </a-col>
        </a-row>
      </a-layout>
    </div>
  </page-header-wrapper>
</template>
<script>
import { AutoHeight } from '@/utils/util'
import ExpMenu from './components/ExpMenu.vue'
import Exp1 from './Exp1/index'
import Exp2 from './Exp2/index'
import Exp3 from './Exp3/index'
import Device from './Device/index'
import NewExp from './Exp1/NewExp'
import Jiaoyan from './Device/Jiaoyan/index'
import Detail from './Exp1/Detail'
export default {
  components: { ExpMenu, Exp1, Exp2, Exp3, NewExp, Device, Jiaoyan, Detail },
  data() {
      return {
          currentComponent: 'Exp1',
          parm: {}
      }
  },

  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = ['chartContent', 'divContentleft', 'field', 'colMenu']
      var min = ['divContent', 'divContentleft', 'field', 'colMenu']
      AutoHeight(this, min, max, 176)
    },

    changeComponent(aa, parm) {
        this.currentComponent = aa
        this.parm = parm
    }

  }
}
</script>
