var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("h1", { staticStyle: { "margin-bottom": "15px" } }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _c(
      "div",
      { staticStyle: { "letter-spacing": "1.4px !important" } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }