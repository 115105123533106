var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "筛选条件",
        visible: _vm.visible,
        confirmLoading: _vm.loading,
        okText: "确定",
        cancelText: "取消"
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-form",
        _vm._b({ attrs: { form: _vm.form } }, "a-form", _vm.formLayout, false),
        [
          _c(
            "div",
            {
              staticStyle: {
                color: "#ff7200",
                height: "35px",
                "background-color": "#fff8ed",
                width: "100%",
                "line-height": "35px",
                "padding-left": "10px"
              }
            },
            [_vm._v("筛选只针对原始数据，缺失值处理过的数据将失效")]
          ),
          _c("br"),
          _c(
            "a-form-item",
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: "22" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.addOption }
                        },
                        [_vm._v("新增条件 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.options, function(option) {
            return _c(
              "div",
              { key: option.key, staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", align: "middle" } },
                  [
                    _c(
                      "a-col",
                      {
                        staticStyle: { "background-color": "#f9f9f9" },
                        attrs: { span: 22 }
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { type: "flex", align: "middle" } },
                          [
                            _c(
                              "a-col",
                              {
                                attrs: {
                                  span: option.addShow ? 20 : 22,
                                  offset: "1"
                                }
                              },
                              [
                                _c(
                                  "a-form-item",
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              option.key + "_field",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "变量必填"
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    `${option.key}_field`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: '变量必填',\n                        },\n                      ],\n                    },\n                  ]"
                                          }
                                        ],
                                        staticStyle: { "margin-top": "10px" },
                                        attrs: { placeholder: "请选择" },
                                        on: {
                                          change: function(value) {
                                            _vm.fieldChange(value, option)
                                          }
                                        }
                                      },
                                      _vm._l(option.fields, function(item, i) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: i,
                                            attrs: { value: item.fieldName }
                                          },
                                          [_vm._v(_vm._s(item.fieldName))]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            option.addShow
                              ? _c(
                                  "a-col",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { span: "2" }
                                  },
                                  [
                                    _c("a-icon", {
                                      staticClass: "dynamic-delete-button",
                                      attrs: { type: "plus-circle" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addOptionChild(option)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          { attrs: { type: "flex" } },
                          [
                            option.children.length > 1
                              ? _c(
                                  "a-col",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "background-color": "#ffffff",
                                      border: "1px soild #dedede",
                                      "margin-top": "5px",
                                      "margin-bottom": "10px"
                                    },
                                    attrs: {
                                      id: option.key + "_andCol",
                                      span: "4",
                                      offset: "1"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c(
                                          "a-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  option.key + "_relation"
                                                ],
                                                expression:
                                                  "[`${option.key}_relation`]"
                                              }
                                            ],
                                            staticStyle: {
                                              position: "relative"
                                            }
                                          },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: "and" } },
                                              [_vm._v("并且")]
                                            ),
                                            _c(
                                              "a-radio",
                                              { attrs: { value: "or" } },
                                              [_vm._v("或者")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "a-col",
                              {
                                attrs: {
                                  span: option.children.length === 1 ? 24 : 17
                                }
                              },
                              _vm._l(option.children, function(child) {
                                return _c(
                                  "a-row",
                                  {
                                    key: child,
                                    staticStyle: { "margin-bottom": "10px" }
                                  },
                                  [
                                    _c(
                                      "a-col",
                                      {
                                        attrs: {
                                          span:
                                            option.children.length > 1 ? 7 : 5,
                                          offset:
                                            option.children.length > 1 ? 1 : 2
                                        }
                                      },
                                      [
                                        _c(
                                          "a-form-item",
                                          [
                                            _c(
                                              "a-select",
                                              {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: [
                                                      option.key +
                                                        "_" +
                                                        child +
                                                        "_dayu",
                                                      {
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message: "必填"
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    expression:
                                                      "[\n                        `${option.key}_${child}_dayu`,\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '必填',\n                            },\n                          ],\n                        },\n                      ]"
                                                  }
                                                ],
                                                attrs: { placeholder: "请选择" }
                                              },
                                              _vm._l(option.dayu, function(
                                                item,
                                                j
                                              ) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: j,
                                                    attrs: { value: item.value }
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      {
                                        attrs: {
                                          span:
                                            option.values &&
                                            option.values.length > 0
                                              ? 15
                                              : 11,
                                          offset: "1"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-form-item",
                                          [
                                            option.values &&
                                            option.values.length > 0
                                              ? _c(
                                                  "a-select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          option.key +
                                                            "_" +
                                                            child +
                                                            "_value_0",
                                                          {
                                                            rules: [
                                                              {
                                                                required:
                                                                  option.required0,
                                                                message:
                                                                  "条件必填"
                                                              }
                                                            ]
                                                          }
                                                        ],
                                                        expression:
                                                          "[\n                        `${option.key}_${child}_value_0`,\n                        {\n                          rules: [\n                            {\n                              required: option.required0,\n                              message: '条件必填',\n                            },\n                          ],\n                        },\n                      ]"
                                                      }
                                                    ],
                                                    attrs: {
                                                      mode: "multiple",
                                                      placeholder: "请选择"
                                                    }
                                                  },
                                                  _vm._l(
                                                    option.values,
                                                    function(item, k) {
                                                      return _c(
                                                        "a-select-option",
                                                        {
                                                          key: k,
                                                          attrs: {
                                                            title: item,
                                                            value: item
                                                          }
                                                        },
                                                        [_vm._v(_vm._s(item))]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                            !option.values ||
                                            option.values.length === 0
                                              ? _c("a-input", {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value: [
                                                        option.key +
                                                          "_" +
                                                          child +
                                                          "_value_1",
                                                        {
                                                          rules: [
                                                            {
                                                              required:
                                                                option.required1,
                                                              message:
                                                                "条件必填"
                                                            },
                                                            {
                                                              message:
                                                                "请输入数值",
                                                              pattern: /^(([0-9]+\.[0-9]*[0-9][0-9]*)|([0-9]*[0-9][0-9]*\.[0-9]+)|([0-9]*[0-9][0-9]*))$/
                                                            }
                                                          ]
                                                        }
                                                      ],
                                                      expression:
                                                        "[\n                        `${option.key}_${child}_value_1`,\n                        {\n                          rules: [\n                            {\n                              required: option.required1,\n                              message: '条件必填',\n                            },\n                            {\n                              message: '请输入数值',\n                              pattern: /^(([0-9]+\\.[0-9]*[0-9][0-9]*)|([0-9]*[0-9][0-9]*\\.[0-9]+)|([0-9]*[0-9][0-9]*))$/,\n                            },\n                          ],\n                        },\n                      ]"
                                                    }
                                                  ],
                                                  staticStyle: { width: "100%" }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    (!option.values ||
                                      option.values.length === 0) &&
                                    option.children.length > 1
                                      ? _c(
                                          "a-col",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            },
                                            attrs: { span: "2" }
                                          },
                                          [
                                            _c("a-icon", {
                                              staticClass:
                                                "dynamic-delete-button",
                                              attrs: { type: "minus-circle-o" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeOptionChild(
                                                    option,
                                                    child
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: "2" } },
                      [
                        _c("a-icon", {
                          staticClass: "dynamic-delete-button",
                          staticStyle: { "padding-left": "10px" },
                          attrs: { type: "minus-circle-o" },
                          on: {
                            click: function($event) {
                              return _vm.removeOption(option.key)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }