<template>
  <div style="background:#dedede">
    <div class="pagediv">
      <div class="titleB">临床营养风险筛查记录表</div>
      <div class="content">
        <div class="titleS">1 患者基本信息</div>
        <div class="contentS">患者编号：<span class="contentUnderLine" >{{ data[0].value }}</span></div>

        <div class="titleS">2临床营养风险筛查</div>
        <div class="titleS">主要诊断</div>
        <div class="contentS">主要诊断：<span class="contentUnderLine" >{{ data[1].value }}</span></div>

        <div class="titleS">2. 1 疾病评分</div>
        <div class="contentS">若患有以下疾病请在[ ]打“√”，并参照标准进行评分。</div>
        <div class="contentS">注：未列入下述疾病者须“挂靠”, 如 “急性胆襄炎” 、“老年痴呆”等可挂靠于“慢性疾病急性发作或有并发症者”计1分(复核者有权决定挂靠的位置)。</div>
        <div class="contentS">{{ `髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤(1分)
          [ ${data[2].value==='髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤'?'√':'\xa0\xa0'} ]；` }}</div>
        <div class="contentS">{{ `腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤(2分)
          [ ${data[2].value==='腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤'?'√':'\xa0\xa0'} ]；` }}</div>
        <div class="contentS">{{ `颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者(3分)
          [ ${data[2].value==='颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者'?'√':'\xa0\xa0'}]；` }}</div>
        <div class="contentS">{{ `疾病评分：
          0分[ ${jibing===0?'√':'\xa0\xa0'} ]，
          1分[ ${data[2].value==='髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤'?'√':'\xa0\xa0'} ]，
          2 分[ ${data[2].value==='腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤'?'√':'\xa0\xa0'}  ]，
          3 分[ ${data[2].value==='颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者'?'√':'\xa0\xa0'} ]。` }}</div>

        <div class="titleS">2. 2 营养状况受损评分</div>
        <div class="titleS">2. 2. 1 人体测量</div>
        <div class="contentS">身高(经过校正的标尺，校正至0.1 cm)<span class="contentUnderLine" >{{ data[3].value/100 }}</span>m(免鞋)</div>
        <div class="contentS">体重(经过校正的体重计，校正至0.1 kg)<span class="contentUnderLine" >{{ data[4].value }}</span>kg(空腹、病房衣服、免鞋)；</div>
        <div class="contentS">体质指数(体重指数，BMI)<span class="contentUnderLine" >{{ data[5].value }}</span>kg/m2(若BMI＜18.5且一般状况差，3分，若BMI≥18.5，0分)；</div>
        <div class="contentS">小计：<span class="contentUnderLine" >{{ BMI }} </span> 分。</div>
        <div class="titleS">2.2.2 体重状况</div>
        <div class="contentS">{{ `近期(1个月~ 3个月)休重是否下降？(是[ ${data[6].value==='体重下降'?'√':'\xa0\xa0'} ]，否[ ${data[6].value==='体重没下降'?'√':'\xa0\xa0'} ])；若是休重下降 ` }}
          <span class="contentUnderLine" >{{ data[6].value!=='体重下降'?'': data[7].value }}</span> kg；</div>
        <div class="contentS">{{ `体重下降>5% 是在：3个月内(1分)
        [ ${data[6].value==='体重下降'&&data[8].value==='体重下降＞5%在3个月内'?'√':'\xa0\xa0'} ]， 2个月内(2分)
        [ ${data[6].value==='体重下降'&&data[8].value==='体重下降＞5%在2个月内'?'√':'\xa0\xa0'} ]，1个月内(3分)
        [ ${data[6].value==='体重下降'&&data[8].value==='体重下降＞5%在1个月内'?'√':'\xa0\xa0'} ]；` }}</div>
        <div class="contentS">小计：<span class="contentUnderLine" >{{ height }} </span>分</div>
      </div>
    </div>
    <div class="pagediv" style="padding-top:40px;">
      <div class="content" style="margin-top:35px">

        <div class="titleS">2.2.3 进食状况</div>
        <div class="contentS">{{ `一周内进食量是否减少？(
        是[ ${data[data[6].value==='体重下降'?9:7].value!=='没减少'?'√':'\xa0\xa0'} ]，
        否[ ${data[data[6].value==='体重下降'?9:7].value==='没减少'?'√':'\xa0\xa0'} ])；` }}</div>
        <div class="contentS">{{ `如果减少，较从前减少：
        25%~ 50%(1分)[ ${data[data[6].value==='体重下降'?9:7].value==='较从前减少：25%~ 50%'?'√':'\xa0\xa0'} ]，
        51%~ 75%(2分)[ ${data[data[6].value==='体重下降'?9:7].value==='较从前减少：51%~ 75%'?'√':'\xa0\xa0'} ]，
        76%~100%(3分)[ ${data[data[6].value==='体重下降'?9:7].value==='较从前减少：76%~100%'?'√':'\xa0\xa0'} ]；` }}</div>
        <div class="contentS">小计：<span class="contentUnderLine" >{{ jinshi }} </span>分</div>
        <div class="contentS">{{ `营养状况受损评分：0分[ ${jinshi===0?'√':'\xa0\xa0'} ]，1分[ ${jinshi===1?'√':'\xa0\xa0'} ]，2分[ ${jinshi===2?'√':'\xa0\xa0'} ]，3分[ ${jinshi===3?'√':'\xa0\xa0'} ]；` }}</div>
        <div class="contentS">注：取上述3个小结评分中的最高值。</div>

        <div class="titleS">2.2.4 年龄评分</div>
        <div class="contentS">若年龄≥70岁为1分，否则为0分；</div>
        <div class="contentS">{{ `年龄评分：
        0分[ ${data[data[6].value==='体重下降'?10:8].value==='年龄＜70岁'?'√':'\xa0\xa0'} ]，
        1分[ ${data[data[6].value==='体重下降'?10:8].value==='年龄≥70岁'?'√':'\xa0\xa0'} ]。` }}</div>

        <div class="titleS">2.2.5 营养风险总评分</div>
        <div class="contentS">临床营养筛查总分＝<span class="contentUnderLine" >{{ total }} </span>分</div>
        <div class="contentS">注：临床营养筛查总分＝疾病评分 + 营养状况受损评分 + 年龄评分。</div>

        <div class="titleS">3 调查者及复核者签名</div>
        <div class="contentS">调查者签名：<span class="contentUnderLine" style="width:100px"></span></div>
        <div class="contentS">复核者签名：<span class="contentUnderLine" style="width:100px"></span></div>

        <div class="titleS">4 筛查日期</div>
        <div class="contentS" style="margin: auto auto 30px 100px;">
          筛查日期：
          <span class="contentUnderLine" style="width:100px;"></span>年
          <span class="contentUnderLine" style="width:100px;"></span>月
          <span class="contentUnderLine" style="width:100px;"></span>日</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'LinchuangyyfxscjlbSimple',
    props: {
         data: {
            type: Array,
            default: () => []
        }
    },
    computed: {
      jibing() {
        switch (this.data[2].value) {
          case '髋骨折、慢性疾病急性发作或有并发症、慢性阻塞性肺病、血液透析、肝硬化、一般恶性肿瘤':
            return 1
          case '腹部大手术、脑卒中、重度肺炎、血液恶性肿瘤':
            return 2
          case '颅脑损伤、骨髓移植、APACHE-II评分>10分ICU患者':
            return 3
          default:return 0
        }
      },
      BMI() {
        if (Number(this.data[3].value) < 18.5) {
          return 3
        } else {
          return 0
        }
      },
      height() {
        if (this.data[6].value === '体重下降') {
        switch (this.data[8].value) {
          case '体重下降＞5%在3个月内': return 1
          case '体重下降＞5%在2个月内': return 2
          case '体重下降＞5%在1个月内': return 3
          case '体重下降≤5%': return 0
          default:return 0
        }
        } else {
          return 0
        }
      },
      jinshi() {
        var index = 9
        if (this.data[6].value !== '体重下降') { index = 7 }
        switch (this.data[index].value) {
          case '没减少': return 0
          case '较从前减少：25%~ 50%': return 1
          case '较从前减少：51%~ 75%': return 2
          case '较从前减少：76%~100%': return 3
          default:return 0
        }
      },
      age() {
         var index = 10
        if (this.data[6].value !== '体重下降') { index = 8 }
        if (this.data[index].value === '年龄＜70岁') {
          return 0
        } else {
          return 1
        }
      },
      total() {
        var renti = [this.BMI, this.height, this.jinshi]
        var scoreRenti = Math.max.apply(Math, renti)
        return this.jibing + scoreRenti + this.age
      }
    }
}
</script>
<style lang="less" scoped>
.titleB{
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;
    background: white;
}
.titleS{
    font-size: 12px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    background: white;
}
.contentS{
font-size: 12px;
margin: 4px auto 4px 10px;
background: white;
}
.content{
    border:1px black solid;
    padding: 30px 20px;
    margin-left: 80px;
    margin-right: 80px;
    background: white;
}
.contentUnderLine{
    border-bottom:1px solid black;
    display:inline-block;
    padding-left: 10px;
    padding-right: 10px;

}
.pagediv{
width:210mm;
height:297mm;
margin:0 auto;
background: white;
}
@page{
  size: atuo A4 landscape;
  margin: 0mm;
}
</style>
<style