<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{xys,form}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        &lt;{{ xys[0][0][2] }}&gt;中&lt;{{ xys[0][0][0] }}&gt;的&lt;{{ xys[1][0] }}&gt;方差 {{ form.judge }} &lt;{{ xys[0][0][2] }}&gt;中&lt;{{ xys[0][0][1] }}&gt;的&lt;{{
          xys[1][0]
        }}&gt;方差
      </div>
    </template>
    <template #H1="{xys,form}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        &lt;{{ xys[0][0][2] }}&gt;中&lt;{{ xys[0][0][0] }}&gt;的&lt;{{ xys[1][0] }}&gt;方差 {{ $hypothesis.replaceJudge(form.judge) }} &lt;{{ xys[0][0][2] }}&gt;中&lt;{{ xys[0][0][1] }}&gt;的&lt;{{
          xys[1][0]
        }}&gt;方差
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'fXys',
      xys: [[], []],
      formAddKeyName: 'fFormAddKey',
      settings,
      form: {
        a: '5',
        judge: '＝'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
        if (this.$store.state.hypothesis.method) {
          var form = this.$deepClone(that.form)
          form.xys = this.$deepClone(that.computedXys)
          form.a = parseFloat(form.a) / 100
          this.$store.dispatch('saveDybtjy', form)
          this.$router.push('/hypothesistest/result')
        } else {
          this.$message.info('请选择假设检验方法')
          return false
        }
    }
  }
}
</script>