<template>
  <a-card :bordered="false">
    <a-result v-if="score" status="success" title="提交成功,感谢您的参与">
      <div class="desc" v-if="score">
        <p style="font-size: 20px; text-align: center;padding: 0px;">
          <strong v-html="level"></strong>
        </p>
      </div>

    </a-result>
    <span>本次测试由软件根据测试人的选择项，自动生成测试结果，测试结果仅供参考，不可替代诊断</span>
  </a-card>
</template>
<script>
export default {
  data() {
    return {
      score: null,
      level: ''
    }
  },
  created() {
    const score = this.$route.params.score ? this.$route.params.score * 1.25 : null
    let level = ''
    if (score >= 50 && score < 60) {
      level = '经过本次评测，<br/>你的焦虑程度为“<span style="color:#f0e469">轻度</span>”'
    } else if (score >= 60 && score < 70) {
      level = '经过本次评测，<br/>你的焦虑程度为“<span style="color:#ff7e01">中度</span>”'
    } else if (score >= 70) {
      level = '经过本次评测，<br/>你的焦虑程度为“<span style="color:#f50501">重度</span>”'
    } else {
      level = '经过本次测评，<br/>您暂无焦虑情绪！'
    }

    this.score = score
    this.level = level
  },
  mounted() {
    // 关闭录入页面
    window.onunload = e => {
      // 设置为2
      localStorage.setItem(`watchInput${this.$route.params.id}${this.$route.params.index}`, 1)
      localStorage.setItem(`watchCurrentIndex${this.$route.params.id}`, this.$route.params.index)
    }
  }
}
</script>