<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index">
        <a-table
          ref="tables,2"
          v-if="table"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
          <!-- <template #title>
            <div style="text-align: center">不同{{ lei }}对{{ Object.keys(data.data1)[index] }}的事后多重比较</div>
          </template> -->
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
export default {
  props: ['data', 'lei', 'isLenEqual', 'leiOptionsCombine', 'method', 'methodOfAnova'],
  created() {
    const { data, lei, method, methodOfAnova, isLenEqual, leiOptionsCombine } = this
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'PostHoc',
      data,
      lei,
      method,
      methodOfAnova,
      isLenEqual,
      leiOptionsCombine
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '事后多重比较',
          msg: res
        })
      } else {
        const tables = res.tables
        const findLessP = this.findLessP(tables)
        this.$emit('findLessP', findLessP)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  methods: {
    findLessP(tables) {
      const { a } = this
      const result = []
      tables.forEach((table, index) => {
        if (table) {
          const list = []
          table.data.forEach(dataObj => {
            if (dataObj.pval < a || dataObj.p < a || dataObj['p-tukey'] < a) {
              const A = dataObj.A
              const i = list.findIndex(v => v.A === A)
              if (i === -1) {
                list.push({
                  A, B: dataObj.B
                })
              } else {
                list[i].B = list[i].B.concat(`、${dataObj.B}`)
              }
            }
          })
          result.push(list)
        }
      })
      return result
    }
  },
  computed: {
    a() { return this.$store.state.report.a }
  }
}
export function get_text_postHoc(value, name_of_sample, anova_method, isLenEqual, secondIndex, thirdIndex) {
  /** 获取方差齐性检验中文字的描述
   * @param value 当前进行的检验的值
   * @param name_of_sample 样本的名字，例如“不同文化程度中年龄的样本”
   * @param anova_method 方差分析检验中用的算法名
   */
  const index_of_anova = `3.${secondIndex}.${thirdIndex.anovaIndex}`
  const text_end = `<br/><span class="H0H1">H0：差别不具有统计学意义。</span><br/><span class="H0H1">H1：差别具有统计学意义。</span><br>检验结果如下表所示：`
  let text
  if (value !== 'auto') {
    text = `采用${value}算法进行事后多重比较，`
  } else {
    if (anova_method === 'Welch') {
      text = `由${index_of_anova}可知：${name_of_sample}间差异显著，因此使用Games-Howell算法进行事后多重比较，`
    } else if (anova_method === 'Anova') {
      if (isLenEqual[0]) {
        text = `由${index_of_anova}可知：${name_of_sample}间差异显著且每组样本相同，因此使用Turkey算法进行事后多重比较，`
      } else {
        text = `由${index_of_anova}可知：${name_of_sample}间差异显著且每组样本不同，因此使用Scheffe算法进行事后多重比较，`
      }
    }
  }
  return text + text_end
}

  </script>