var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          ref: "title," + _vm.thirdIndex.normIndex,
          staticClass: "third-title",
          attrs: {
            id:
              "3." +
              _vm.secondIndex +
              "." +
              _vm.thirdIndex.normIndex +
              " 正态性检验"
          }
        },
        [
          _vm._v(
            " 3." +
              _vm._s(_vm.secondIndex) +
              "." +
              _vm._s(_vm.thirdIndex.normIndex) +
              " 正态性检验 "
          )
        ]
      ),
      _c(
        "div",
        [
          _c("Norm", {
            ref: "content," + _vm.thirdIndex.normIndex,
            attrs: {
              data: _vm.data,
              lei: _vm.data.lei,
              xys: _vm.xys,
              settings: _vm.settings.options.methods.find(function(v) {
                return v.name === "正态检验"
              })
            },
            on: {
              isNorm: function(val) {
                return (_vm.isNorm = val)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "text1",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.normIndex + ",1" },
                      [
                        _vm._v(
                          " 对不同" +
                            _vm._s(_vm.lei) +
                            "中的" +
                            _vm._s(_vm.liang) +
                            "做正态性检验，"
                        ),
                        _c("br"),
                        _c("span", { staticClass: "H0H1" }, [
                          _vm._v(
                            "H0：不同" +
                              _vm._s(_vm.lei) +
                              "中" +
                              _vm._s(_vm.liang) +
                              "的样本服从正态分布。"
                          )
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "H0H1" }, [
                          _vm._v(
                            "H1：不同" +
                              _vm._s(_vm.lei) +
                              "中" +
                              _vm._s(_vm.liang) +
                              "的样本不服从正态分布。"
                          )
                        ]),
                        _c("br"),
                        _vm._v(" 检验结果如下表所示： ")
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "text2",
                fn: function(ref) {
                  var text = ref.text
                  return [
                    _c(
                      "Conclusion",
                      _vm._l(text, function(pText, index) {
                        return _c(
                          "p",
                          {
                            key: index,
                            ref: "text," + _vm.thirdIndex.normIndex + ",3",
                            refInFor: true
                          },
                          [
                            _c(
                              "span",
                              { domProps: { innerHTML: _vm._s(pText) } },
                              [_vm._v(_vm._s(pText))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                }
              },
              {
                key: "text3",
                fn: function() {
                  return [
                    _c(
                      "p",
                      { ref: "text," + _vm.thirdIndex.normIndex + ",4" },
                      [_vm._v(_vm._s(_vm.liang) + "的PP图和QQ图如下图所示：")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm.isNorm.length > 0 &&
    (_vm.flow_anova_now === "auto"
      ? _vm.isNorm[0] >= _vm.a
      : _vm.flow_anova_now === "anova")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.homogeneousIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.homogeneousIndex +
                  " 方差齐性检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.homogeneousIndex) +
                  " 方差齐性检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("HomogeneityOfVariances", {
                ref: "content," + _vm.thirdIndex.homogeneousIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  isNorm: _vm.isNorm,
                  settings: _vm.settings.options.methods.find(function(v) {
                    return v.name === "方差齐性检验"
                  })
                },
                on: {
                  isHomogeneous: function(val) {
                    return (_vm.isHomogeneous = val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref:
                              "text," + _vm.thirdIndex.homogeneousIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("方差齐性检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref:
                                  "text," +
                                  _vm.thirdIndex.homogeneousIndex +
                                  ",3"
                              },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.isHomogeneous,
                                        _vm.a,
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本方差齐",
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本方差不齐"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2891025671
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isHomogeneous.length > 0 &&
    (_vm.flow_anova_now === "auto"
      ? _vm.isNorm[0] >= _vm.a
      : _vm.flow_anova_now === "anova")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.anovaIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.anovaIndex +
                  " 方差分析"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.anovaIndex) +
                  " 方差分析 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("Anova", {
                ref: "content," + _vm.thirdIndex.anovaIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  settings: _vm.obj_of_settings.anova,
                  isHomogeneous: _vm.isHomogeneous
                },
                on: {
                  isDiff: function(val) {
                    return (_vm.isDiff = val)
                  },
                  saveMethod: function(val) {
                    return _vm.put_method_name_into_obj("anova", val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.anovaIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("参数检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref: "text," + _vm.thirdIndex.anovaIndex + ",3"
                              },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.isDiff,
                                        _vm.a,
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本间差异不显著",
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本间差异显著"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2069988413
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isDiff.length > 0 &&
    (_vm.isDiff[0] || _vm.isDiff[0] === 0) &&
    _vm.isDiff[0] < _vm.a
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.postHocIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.postHocIndex +
                  " 事后多重比较"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.postHocIndex) +
                  " 事后多重比较 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("PostHoc", {
                ref: "content," + _vm.thirdIndex.postHocIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  isLenEqual: _vm.isLenEqual,
                  leiOptionsCombine: _vm.leiOptionsCombine,
                  methodOfAnova: _vm.obj_of_methods.anova,
                  method: _vm.obj_of_settings.postHoc.args[0].value
                },
                on: {
                  findLessP: function(x) {
                    return (_vm.findLessP = x)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.postHocIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("事后检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c(
                            "Conclusion",
                            [
                              _vm.findLessP.length > 0
                                ? _c(
                                    "p",
                                    {
                                      ref:
                                        "text," +
                                        _vm.thirdIndex.postHocIndex +
                                        ",3"
                                    },
                                    [_vm._v("由上表可得：")]
                                  )
                                : _vm._e(),
                              _vm._l(_vm.findLessP, function(
                                group,
                                groupIndex
                              ) {
                                return _c(
                                  "p",
                                  {
                                    key: groupIndex,
                                    ref:
                                      "text," +
                                      _vm.thirdIndex.postHocIndex +
                                      ",4",
                                    refInFor: true
                                  },
                                  [
                                    group.length > 0
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.liang) + "中 "
                                            ),
                                            _vm._l(group, function(
                                              groupObj,
                                              groupObjIndex
                                            ) {
                                              return _c(
                                                "p",
                                                { key: groupObjIndex },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(groupObj.A) +
                                                      "与" +
                                                      _vm._s(groupObj.B) +
                                                      "， "
                                                  )
                                                ]
                                              )
                                            }),
                                            _vm._v(" 这些组合的"),
                                            _c("i", [_vm._v("p")]),
                                            _vm._v(
                                              "值 < " +
                                                _vm._s(_vm.a) +
                                                "，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。 "
                                            )
                                          ],
                                          2
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.liang) +
                                              "中所有组合的"
                                          ),
                                          _c("i", [_vm._v("p")]),
                                          _vm._v(
                                            "值 ≥ " +
                                              _vm._s(_vm.a) +
                                              "，因此不拒绝H0（差别不具有统计学意义）。 "
                                          )
                                        ])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1547761771
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.isNorm.length > 0 &&
    (_vm.flow_anova_now === "auto"
      ? _vm.isNorm[0] < _vm.a
      : _vm.flow_anova_now === "none_param")
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.noneParamIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.noneParamIndex +
                  " 非参数检验"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.noneParamIndex) +
                  " 非参数检验 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("NoneParam", {
                ref: "content," + _vm.thirdIndex.noneParamIndex,
                attrs: { data: _vm.data, lei: _vm.data.lei },
                on: {
                  noneParamIsDiff: function(x) {
                    return (_vm.noneParamIsDiff = x)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c("p", {
                            ref: "text," + _vm.thirdIndex.noneParamIndex + ",1",
                            domProps: {
                              innerHTML: _vm._s(_vm.get_text("非参数检验"))
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref:
                                  "text," + _vm.thirdIndex.noneParamIndex + ",3"
                              },
                              [
                                _vm._v(" 由上表可得： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$hypothesis.conclusion(
                                        _vm.noneParamIsDiff,
                                        _vm.a,
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本间差异不显著",
                                        "不同" +
                                          _vm.lei +
                                          "中" +
                                          _vm.liang +
                                          "的样本间差异显著"
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  664932486
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.noneParamIsDiff.length > 0 &&
    (_vm.noneParamIsDiff[0] || _vm.noneParamIsDiff[0] === 0) &&
    _vm.noneParamIsDiff[0] < _vm.a
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.noneParamPostHocIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.noneParamPostHocIndex +
                  " 非参数检验的事后多重比较"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.noneParamPostHocIndex) +
                  " 非参数检验的事后多重比较 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("NoneParamPostHoc", {
                ref: "content," + _vm.thirdIndex.noneParamPostHocIndex,
                attrs: {
                  data: _vm.data,
                  lei: _vm.data.lei,
                  noneParamIsDiff: _vm.noneParamIsDiff,
                  leiOptionsCombine: _vm.leiOptionsCombine
                },
                on: {
                  findLessP: function(x) {
                    return (_vm.NoneParamFindLessP = x)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c(
                            "p",
                            {
                              ref:
                                "text," +
                                _vm.thirdIndex.noneParamPostHocIndex +
                                ",1"
                            },
                            [
                              _vm._v(
                                " 由3." +
                                  _vm._s(_vm.secondIndex) +
                                  "." +
                                  _vm._s(_vm.thirdIndex.noneParamIndex) +
                                  "可知： "
                              ),
                              _vm.noneParamPostHocObj.nemenyi
                                ? _c("span", [
                                    _vm._v(
                                      "不同" +
                                        _vm._s(_vm.lei) +
                                        "中" +
                                        _vm._s(
                                          _vm.noneParamPostHocObj.nemenyi
                                        ) +
                                        "的样本间差异显著，因此采用Nemenyi算法进行事后检验，"
                                    ),
                                    _c("br"),
                                    _c("span", { staticClass: "H0H1" }, [
                                      _vm._v("H0：差别不具有统计学意义。")
                                    ]),
                                    _c("br"),
                                    _c("span", { staticClass: "H0H1" }, [
                                      _vm._v("H1：差别具有统计学意义。")
                                    ]),
                                    _c("br"),
                                    _vm._v(" 检验结果如下表所示：")
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function() {
                        return [
                          _c(
                            "Conclusion",
                            _vm._l(_vm.NoneParamFindLessP, function(
                              group,
                              groupIndex
                            ) {
                              return _c(
                                "p",
                                {
                                  key: groupIndex,
                                  ref:
                                    "text," +
                                    _vm.thirdIndex.noneParamPostHocIndex +
                                    ",3",
                                  refInFor: true
                                },
                                [
                                  _c("span", [_vm._v("由上表可得：")]),
                                  group.length > 0
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Object.keys(_vm.data.data1)[
                                                  groupIndex
                                                ]
                                              ) +
                                              "中 "
                                          ),
                                          _vm._l(group, function(
                                            groupObj,
                                            groupObjIndex
                                          ) {
                                            return _c(
                                              "p",
                                              { key: groupObjIndex },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(groupObj.A) +
                                                    "与" +
                                                    _vm._s(groupObj.B) +
                                                    "， "
                                                )
                                              ]
                                            )
                                          }),
                                          _vm._v(" 这些组合的"),
                                          _c("i", [_vm._v("p")]),
                                          _vm._v(
                                            "值 < " +
                                              _vm._s(_vm.a) +
                                              "，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。 "
                                          )
                                        ],
                                        2
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Object.keys(_vm.data.data1)[
                                                groupIndex
                                              ]
                                            ) +
                                            "中所有组合的"
                                        ),
                                        _c("i", [_vm._v("p")]),
                                        _vm._v(
                                          "值 ≥ " +
                                            _vm._s(_vm.a) +
                                            "，因此不拒绝H0（差别不具有统计学意义）。 "
                                        )
                                      ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1695688695
                )
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }