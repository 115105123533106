var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0
              ? _c("div", [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.H0(xys)) } })
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "H1",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0
              ? _c("div", [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.H1(xys)) } })
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }