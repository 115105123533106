<template>
  <div style="display:flex;justify-content:start;align-items:center">
    <a-radio-group
      v-model="item.radioValue"
      @change="e => onChange(e, item,'select')"
    >
      <a-radio v-for="(option, optionIndex) in item.radioOptions" :key="optionIndex" :value="option.value">
        <span v-html="option.text">{{ option.text }}</span>
      </a-radio>
    </a-radio-group>
    <a-select
      v-model="item.selectValue"
      :placeholder="item.placeholder"
      @change="e => onChange(e, item,'radio')"
      style="width:100px;text-align:center !important"
    >
      <a-select-option v-for="(option, optionIndex) in item.selectOptions" :key="optionIndex" :value="option.value">
        {{ option.text }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
export default {
    props: ['item'],
    methods: {
        onChange(e, item, type) {
            item[type + 'Value'] = undefined
            this.$emit('updateForm', item.prop, item.radioValue || item.selectValue)
        }
    }
}
</script>