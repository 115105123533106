import router from '@/router'
import store from '../store'
// 变量处理网页跳转
export function dataHandleSkip() {
    var type = store.state.samples.type
      if (type === 'questionnaire') {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          router.push('/hypothesistest/dataHandle/questionnaire/rangecode')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          router.push('/analysisOfRegression/dataHandle/questionnaire/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          router.push('/intelligenceReports/dataHandle/questionnaire/rangecode')
        } else {
          router.push('/customchart/dataHandle/questionnaire/rangecode')
        }
      } else if (type === 'document') {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          router.push('/hypothesistest/dataHandle/document/rangecode')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          router.push('/analysisOfRegression/dataHandle/document/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          router.push('/intelligenceReports/dataHandle/document/rangecode')
        } else {
          router.push('/customchart/dataHandle/document/rangecode')
        }
      } else if (type === 'experiment') {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          router.push('/hypothesistest/dataHandle/experiment/rangecode')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          router.push('/analysisOfRegression/dataHandle/experiment/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          router.push('/intelligenceReports/dataHandle/experiment/rangecode')
        } else {
          router.push('/customchart/dataHandle/experiment/rangecode')
        }
      } else if (type === 'file') {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          router.push('/hypothesistest/dataHandle/file/rangecode')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          router.push('/analysisOfRegression/dataHandle/file/rangecode')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          router.push('/intelligenceReports/dataHandle/file/rangecode')
        } else {
          router.push('/customchart/dataHandle/file/rangecode')
        }
      }
}

// 返回数据筛选
export function gotoScreen() {
  var type = store.state.samples.type
  if (type === 'questionnaire') {
    const pathname = window.location.pathname
    if (pathname.indexOf('hypothesistest') !== -1) {
      router.push('/hypothesistest/screendata/questionnaire')
    } else if (pathname.indexOf('analysisOfRegression') !== -1) {
      router.push('/analysisOfRegression/screendata/questionnaire')
    } else if (pathname.indexOf('intelligenceReports') !== -1) {
      router.push('/intelligenceReports/screendata/questionnaire')
    } else {
      router.push('/customchart/screendata/questionnaire')
    }
  } else if (type === 'document') {
    const pathname = window.location.pathname
    console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
    if (pathname.indexOf('hypothesistest') !== -1) {
      router.push('/hypothesistest/screendata/document')
    } else if (pathname.indexOf('analysisOfRegression') !== -1) {
      router.push('/analysisOfRegression/screendata/document')
    } else if (pathname.indexOf('intelligenceReports') !== -1) {
      router.push('/intelligenceReports/screendata/document')
    } else {
      router.push('/customchart/screendata/document')
    }
  } else if (type === 'experiment') {
    const pathname = window.location.pathname
    console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
    if (pathname.indexOf('hypothesistest') !== -1) {
      router.push('/hypothesistest/screendata/experiment')
    } else if (pathname.indexOf('analysisOfRegression') !== -1) {
      router.push('/analysisOfRegression/screendata/experiment')
    } else if (pathname.indexOf('intelligenceReports') !== -1) {
      router.push('/intelligenceReports/screendata/experiment')
    } else {
      router.push('/customchart/screendata/experiment')
    }
  } else if (type === 'file') {
    const pathname = window.location.pathname
    console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
    if (pathname.indexOf('hypothesistest') !== -1) {
      router.push('/hypothesistest/screendata/file')
    } else if (pathname.indexOf('analysisOfRegression') !== -1) {
      router.push('/analysisOfRegression/screendata/file')
    } else if (pathname.indexOf('intelligenceReports') !== -1) {
      router.push('/intelligenceReports/screendata/file')
    } else {
      router.push('/customchart/screendata/file')
    }
  }
  store.dispatch('updateSamples', { documents: '' })
  store.dispatch('updateSamples', { filtersAll: { outlier: 0 } })
  store.dispatch('updateSamples', { filtersSimple: { without0: 0 } })
}
