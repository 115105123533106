<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{xys}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span
          v-for="(item, index) in xys[0][0]"
          :key="index"
        ><span v-if="index !== xys[0][0].length - 1"><{{ item }}&gt;</span></span
        >{{ xys[0][0].length - 1 }}组样本&lt;{{ xys[1][0] }}&gt;的总体均值相等
      </div>
    </template>
    <template #H1="{xys}">
      <div v-if="xys[0].length > 0 && xys[1].length > 0">
        <span
          v-for="(item, index) in xys[0][0]"
          :key="index"
        ><span v-if="index !== xys[0][0].length - 1"><{{ item }}&gt;</span></span
        >{{ xys[0][0].length - 1 }}组样本&lt;{{ xys[1][0] }}&gt;的总体均值不等或不全相等
      </div>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'welchXys',
      xys: [[], []],
      formAddKeyName: 'welchFormAddKey',
      settings,
      form: {
        a: '5',
        method: 0
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
          if (this.$store.state.hypothesis.method) {
            var form = this.$deepClone(that.form)
            form.xys = this.$deepClone(that.computedXys)
            form.a = parseFloat(form.a) / 100
            this.$store.dispatch('saveDybtjy', form)
            this.$router.push('/hypothesistest/result')
          } else {
            this.$message.info('请选择假设检验方法')
            return false
          }
    }
  }
}
</script>