<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px; min-height: 750px; border-right: 1px solid rgb(232, 232, 232)">
            <a-row>
              <a-col :span="24">
                <a-row style="margin-bottom: 5px">
                  <a-col :span="24">
                    <h1>描述性分析结果</h1>
                  </a-col>
                </a-row>
                <a-tabs type="editable-card" hide-add>
                  <a-tab-pane
                    v-for="(value, key, index) in part2.tables"
                    :key="index"
                    :tab="key"
                    style="padding-bottom: 1px"
                    :closable="false"
                  >
                    <myTable :data="value.data" :columns="value.columns" :title="''"></myTable>
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <myTable :data="data" :columns="columns" :title="'主体间效应检验'"></myTable>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'双因素方差智能分析结果'">
              <div v-html="larger('0', `${lei1}`)"></div>
              <div v-html="larger('1', `${lei2}`)"></div>
              <div v-html="larger('2', `${lei1}:${lei2}`)"></div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  name: 'SysfcfxResult',
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: (columns, t, res) => this.$hypothesis.setColumns.fcfx(columns, t, res, this),
      method2: this.$hypothesis.setColumns.manyParamsWithNoP,
      method3: this.$hypothesis.setColumns.common,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[], []], ['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, 'statistic', this.setSecondTable)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.Lei2Liang1()
    },
    setSecondTable(res) {
      this.method3()
    }
  },
  computed: {
    lei1() { return this.part2.xys[0][0].slice(-1)[0] },
    lei2() { return this.part2.xys[0][1].slice(-1)[0] },
    liang() { return this.part2.xys[1][0] },
    H0() {
      return [`&lt;${this.lei1}&gt;对&lt;${this.liang}&gt;的影响不显著`, `&lt;${this.lei2}&gt;对&lt;${this.liang}&gt;的影响不显著`, `&lt;${this.lei1}&gt;和&lt;${this.lei2}&gt;的交互作用对&lt;${this.liang}&gt;的影响不显著`]
    },
    H1() {
      return [`&lt;${this.lei1}&gt;对&lt;${this.liang}&gt;的影响显著`, `&lt;${this.lei2}&gt;对&lt;${this.liang}&gt;的影响显著`, `&lt;${this.lei1}&gt;和&lt;${this.lei2}&gt;的交互作用对&lt;${this.liang}&gt;的影响显著`]
    },
    larger() {
      return (index, lei) => {
        console.log('%c 🥘 lei: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', lei);
        var p = this.part2['p' + index]
        if (p !== null && p <= 0.05) {
          return `${1 + parseInt(index)}.由于&lt;${lei}&gt;的<i>p</i>=${p} ≤ 0.05，因此拒绝H0（${this.H0[index]}），接受H1（${this.H1[index]}）`
        } else if (p !== null && p > 0.05) {
          return `${1 + parseInt(index)}.由于&lt;${lei}&gt;的<i>p</i>=${p} > 0.05，因此不拒绝H0（${this.H0[index]}）`
        } else { return `${1 + parseInt(index)}.数据质量不佳，无法分析` }
      }
    }
  }
}
</script>