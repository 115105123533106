var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "600px",
        maskClosable: false,
        visible: _vm.visible,
        title: "图表信息设置"
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { attrs: { model: _vm.data } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "标题" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.data.title,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "title", $$v)
                  },
                  expression: "data.title"
                }
              })
            ],
            1
          ),
          _vm.data.action !== "pie" &&
          _vm.data.action !== "funnel" &&
          _vm.data.action !== "TreeMap" &&
          _vm.data.action !== "Radar"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "x轴" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.data.x,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "x", $$v)
                      },
                      expression: "data.x"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.data.action !== "pie" &&
          _vm.data.action !== "funnel" &&
          _vm.data.action !== "TreeMap" &&
          _vm.data.action !== "Radar"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "y轴" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.data.y,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "y", $$v)
                      },
                      expression: "data.y"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.data.action === "paleituotu"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "y副轴" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.data.z,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "z", $$v)
                      },
                      expression: "data.z"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.data.labels &&
          _vm.data.labels.length > 0 &&
          _vm.data.action !== "funnel" &&
          _vm.data.action !== "TreeMap"
            ? _c(
                "div",
                [
                  _c("a-form-model-item", { attrs: { label: "图例位置" } }, [
                    _c(
                      "div",
                      { staticStyle: { border: "1px solid #dedede" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _vm._v(" 图例位置 "),
                            _c(
                              "a-select",
                              {
                                model: {
                                  value: _vm.data.legend.loc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.data.legend, "loc", $$v)
                                  },
                                  expression: "data.legend.loc"
                                }
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { key: "0", attrs: { value: 0 } },
                                  [_vm._v("自动寻找最好的位置")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "1", attrs: { value: 1 } },
                                  [_vm._v("右上角")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "2", attrs: { value: 2 } },
                                  [_vm._v("左上角")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "3", attrs: { value: 3 } },
                                  [_vm._v("右下角")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "4", attrs: { value: 4 } },
                                  [_vm._v("左下角")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "5", attrs: { value: 5 } },
                                  [_vm._v("右边中间")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "6", attrs: { value: 6 } },
                                  [_vm._v("左边中间")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "8", attrs: { value: 8 } },
                                  [_vm._v("中间最下面")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "9", attrs: { value: 9 } },
                                  [_vm._v("中间最上面")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "10", attrs: { value: 10 } },
                                  [_vm._v("正中间")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _vm._v("图例偏移"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ff7200",
                                  "margin-left": "10px"
                                }
                              },
                              [_vm._v("(如果不想要可以清空)")]
                            ),
                            _c(
                              "a-input-group",
                              { attrs: { compact: "" } },
                              [
                                _c("a-input-number", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    placeholder: "x轴偏移量",
                                    title: "x轴偏移量,建议(-0.2,1.2)"
                                  },
                                  model: {
                                    value: _vm.data.legend.x,
                                    callback: function($$v) {
                                      _vm.$set(_vm.data.legend, "x", $$v)
                                    },
                                    expression: "data.legend.x"
                                  }
                                }),
                                _c("a-input-number", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    placeholder: "y轴偏移量",
                                    title: "y轴偏移量,建议(-0.2,1.2)"
                                  },
                                  model: {
                                    value: _vm.data.legend.y,
                                    callback: function($$v) {
                                      _vm.$set(_vm.data.legend, "y", $$v)
                                    },
                                    expression: "data.legend.y"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.data.labels && _vm.data.labels.length > 0
            ? _c(
                "div",
                [
                  _c("a-form-model-item", { attrs: { label: "图例内容" } }, [
                    _c(
                      "div",
                      { staticStyle: { border: "1px solid #dedede" } },
                      _vm._l(_vm.data.labels, function(lab, i) {
                        return _c(
                          "div",
                          { key: i, staticStyle: { padding: "10px" } },
                          [
                            _vm._v(" " + _vm._s(lab.old) + ": "),
                            _c("a-input", {
                              attrs: { placeholder: "新的名称" },
                              model: {
                                value: lab.new,
                                callback: function($$v) {
                                  _vm.$set(lab, "new", $$v)
                                },
                                expression: "lab.new"
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }