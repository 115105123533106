<template>
  <a-card :bordered="false" id="divQuestion">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" class="deviceC">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-model="queryParam.name"
                @pressEnter="$refs.table.refresh(true)"
                placeholder="请输入量表(问卷)名称或者模版名称（支持模糊查询）"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" :style="{}">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="
                  () => {
                    ;(this.queryParam = {}), $refs.table.refresh(true)
                  }
                "
                >重置</a-button
              >
            </span>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-row type="flex" justify="end">
              <a-col>
                <a-button type="primary" @click="handleAdd">创建新量表(问卷)</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table ref="table" size="default" rowKey="key" :columns="columns" :data="loadData" showPagination="auto">
      <span slot="number" slot-scope="text, record">
        <template>
          <router-link :to="`/question/AnswerList/${Base64.encode(record.id)}`">{{
            text ? Number(text) : 0
          }}</router-link>
        </template>
      </span>
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ $moment(text).format('YYYY-MM-DD') }}</span>
        </template>
      </span>
      <span slot="deleteFlag" slot-scope="text">
        <template>
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <router-link
            :disabled="record.scoreStatistics === true || record.sequential === true ? false : true"
            :to="
              record.scoreStatistics
                ? `/question/scoreStatistics/${Base64.encode(record.id)}`
                : `/question/sequentialStatistics/${Base64.encode(record.id)}`
            "
            >时序统计</router-link
          >
          <a-divider type="vertical" />
          <a :disabled="record.deleteFlag ? true : false" @click="copy(record)" class="copyData">二维码链接</a>
          <a-divider type="vertical" />
          <a :disabled="record.deleteFlag ? true : false" @click="goInput(record)" class="copyData">量表(问卷)录入</a>
          <a-divider type="vertical" />
          <!-- <router-link :disabled="record.deleteFlag?true:false" :to="`/questionnaire/${Base64.encode(record.id)}`" target="_blank">量表(问卷)录入</router-link>
            <a-divider type="vertical" /> -->
          <router-link :to="`/question/Preview/${Base64.encode(record.id)}`" target="_blank">预览</router-link>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)" :disabled="record.deleteFlag ? true : false">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDel(record)">{{ record.deleteFlag ? '启用' : '停用' }}</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :disable="disable"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      :title="title"
      @cancel="handleCancel"
      @ok="handleOk"
    />
    <q-r-code v-if="qrvisible" :visible="qrvisible" :url="url" @cancel="qrcodeCancel"></q-r-code>
  </a-card>
</template>
<script>
import CreateForm from './components/CreateForm'
import QRCode from './components/QRCode'
import { STable } from '@/components'
import { getQuestionnaireList, createQuestionnaire, updateQuestionnaire, deleteQuestionnaire } from '@/api/questionnaire'
import { Modal } from 'ant-design-vue'
import Clipboard from 'clipboard'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'
// 列
const colums = [
  {
    title: '量表(问卷)名称',
    dataIndex: 'title'
  },
  {
    title: '所用模版',
    dataIndex: 'modelName'
  },
  {
    title: '答卷',
    dataIndex: 'numberOfPeople',
    scopedSlots: { customRender: 'number' },
    align: 'center',
    width: 100
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'date' },
    align: 'center',
    width: 150
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    scopedSlots: { customRender: 'date' },
    align: 'center',
    width: 150
  },
  {
    title: '状态',
    dataIndex: 'deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' },
    align: 'center',
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 450
  }
]
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
export default {
  // 加载组件
  components: {
    CreateForm,
    STable,
    QRCode
  },
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      // 新建/编辑modal是否显示
      visible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      title: '创建量表(问卷)',
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return getQuestionnaireList({ ...requestParameters, orgid: this.$store.state.user.info.orgid, userid: this.$store.state.user.info.id, trial: this.$store.state.user.info.trial }).then(res => {
          return res
        })
      },
      // 如果有人答题则无法修改模版
      disable: false,
      qrvisible: false,
      url: '',
      Base64: Base64
    }
  },
  // 方法
  methods: {
    /**
    * 自定义高度
    */
    autoHeightChange() {
      // 高度自定义使用
      var max = []
      var min = ['divQuestion']
      AutoHeight(this, min, max, 215)
    },
    /**
     * 新建事件
     */
    handleAdd() {
      this.mdl = null
      this.visible = true
    },
    /**
     * 编辑事件
     */

    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record, 'range-picker': [record.startDate, record.endDate] }
      this.title = '编辑量表(问卷)'
      if (record.numberOfPeople && Number(record.numberOfPeople) > 0) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
    /**
     * 新建/编辑cancel事件
     */

    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    /**
     * 新建/编辑ok事件
     */

    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values = {
            ...values,
            startDate: values['range-picker'][0],
            endDate: values['range-picker'][1]
          }
          // 如果id有值则表示是编辑状态，否则是新建状态
          if (values.id > 0) {
            updateQuestionnaire(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('编辑成功')
            })
          } else {
            //   console.log(this.$store.state.user)
            createQuestionnaire({ ...values, userid: this.$store.state.user.info.id, orgid: this.$store.state.user.info.orgid }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     *启用/停用事件
     */
    handleDel(record) {
      const title = record.deleteFlag ? '启用' : '停用'
      Modal.confirm({
        title: '您确定' + title + '该量表(问卷)吗？',
        content: '停用后将无法进行量表(问卷)录入，无法进行统计分析',
        onOk: () => {
          deleteQuestionnaire(record.id).then(res => {
            // 刷新表格
            this.$refs.table.refresh()
          })
        }
      })
    },
    /**
     * 复制链接到粘贴板
     */
    copy(record) {
      var Url = window.location.protocol + '//' + document.location.host + '/questionnaire/' + Base64.encode(record.id)
      var clipboard = new Clipboard('.copyData', {
        text: function () {
          return Url
        }
      })
      this.url = Url
      // 复制成功
      clipboard.on('success', e => {
        console.log('成功')
        clipboard.destroy()
        this.qrvisible = true
      })
      // 复制失败
      clipboard.on('error', e => {
        console.log('失败')
        clipboard.destroy()
      })
    },
    /**
  * 新建/编辑cancel事件
  */

    qrcodeCancel() {
      this.qrvisible = false
    },
    init(record) {
      clearInterval(this.timer)
      console.log('--------------监听结束------------')
    },
    /**
 * 问卷录入
 */
    goInput(record) {
      console.log('------------开启监听器---------------')
      // 获取watchInputIndex,根据问卷的ID获取这份问卷打开了是第几份index从0开始，作为key值，传到答卷列表，
      // 当此页面关闭时，删除这个localStorage属性
      // 这样就保证了一份答卷的唯一性，关闭的时候就知道关的是哪份答卷了
      var index = 0// 当前是该问卷的第几份,默认是0,用来传参用
      var count = 0// 当前是该问卷一共打开了几份,默认是0，用来看当前还有几份是打开的，如果是0的话，则清除这个index和count属性
      var watchInputIndex = localStorage.getItem(`watchInputIndex${Base64.encode(record.id)}`)
      var watchInputCount = localStorage.getItem(`watchInputCount${Base64.encode(record.id)}`)
      if (watchInputIndex) {
        // 如果已存在+1
        index = Number(watchInputIndex) + 1
      }
      if (watchInputCount) {
        count = Number(watchInputCount) + 1
      } else {
        count = 1
      }
      localStorage.setItem(`watchInputIndex${Base64.encode(record.id)}`, index)// 设置index的值
      localStorage.setItem(`watchInputCount${Base64.encode(record.id)}`, count)// 设置count的值
      // 设置答卷的localStorage的watchInput属性值，0：初始化 1：确认答卷 2：离开详情页
      localStorage.setItem(`watchInput${Base64.encode(record.id)}${Base64.encode(index)}`, 0)
      // 计时器
      this.timer = setInterval(() => {
        console.log('-------------------')
        var count = Number(localStorage.getItem(`watchInputCount${Base64.encode(record.id)}`))
        var currentIndex = localStorage.getItem(`watchCurrentIndex${Base64.encode(record.id)}`)
        var newVal = localStorage.getItem(`watchInput${Base64.encode(record.id)}${currentIndex}`)
        if (newVal === '1') {
          count = count - 1// 关闭后count-1
          localStorage.setItem(`watchInputCount${Base64.encode(record.id)}`, count)// 重新保存count的值
          console.log(`1_watchInput${Base64.encode(record.id)}${currentIndex}`)
          this.$refs.table.refresh()
          this.init(record)
          localStorage.removeItem(`watchInput${Base64.encode(record.id)}${currentIndex}`)
          localStorage.removeItem(`watchCurrentIndex${Base64.encode(record.id)}`)
          if (count === 0) {
            // 如果是0的话，则清除这个index和count属性
            localStorage.removeItem(`watchInputCount${Base64.encode(record.id)}`)
            localStorage.removeItem(`watchInputIndex${Base64.encode(record.id)}`)
          }
        } else if (newVal === '2') {
          count = count - 1// 关闭后count-1
          localStorage.setItem(`watchInputCount${Base64.encode(record.id)}`, count)// 重新保存count的值
          console.log(`2_watchInput${Base64.encode(record.id)}${currentIndex}`)
          this.init(record)
          localStorage.removeItem(`watchInput${Base64.encode(record.id)}${currentIndex}`)
          localStorage.removeItem(`watchCurrentIndex${Base64.encode(record.id)}`)
          if (count === 0) {
            // 如果是0的话，则清除这个index和count属性
            localStorage.removeItem(`watchInputCount${Base64.encode(record.id)}`)
            localStorage.removeItem(`watchInputIndex${Base64.encode(record.id)}`)
          }
        }
      }, 2000)
      const routeData = this.$router.resolve({ path: `/questionnaire/${Base64.encode(record.id)}/${Base64.encode(index)}` })
      window.open(routeData.href, '_blank')
    }

  },
  /**
   * 过滤器
   */
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }
  }
}
</script>
<style lang="less" >
.deviceC .ant-form-item {
  margin: 0px !important;
}
</style>