<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
    >
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </template>
      </span>
      <span slot="result" slot-scope="text">
        <template>
          <span>{{ text | ResultFilter }}</span>
        </template>
      </span>
      <span slot="deleteFlag" slot-scope="text">
        <template>
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDel(record)">{{ record.control.deleteFlag ? '启用' : '停用' }}</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :deviceID="deviceID"
      :model="mdl"
      :title="title"
      @cancel="handleCancel"
      @ok="handleOk"
    />

  </a-card>
</template>
<script>
import CreateForm from './components/CreateForm'
import { STable } from '@/components'
import { List, Add, Update, Delete } from '@/api/exp/jiaoyan'
import { Modal } from 'ant-design-vue'
// 列
const colums = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 80
  },
  {
    title: '校验日期',
    dataIndex: 'date',
     scopedSlots: { customRender: 'date' },
    width: 250
  },

  {
    title: '校验结果',
    dataIndex: 'result',
    scopedSlots: { customRender: 'result' },
    width: 250
  },

  {
    title: '设备名称',
    dataIndex: 'deviceName',
    width: 250
  },

  {
    title: '状态',
    dataIndex: 'control.deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' },
    width: 80,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    // width: '150px',
    scopedSlots: { customRender: 'action' },
    width: 150,
    align: 'center'
  }
]
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
// 状态
const ResultMap = {
  0: {
    status: '0',
    text: '通过'
  },
  1: {
    status: '1',
    text: '不通过'
  }
}
export default {
  name: 'Jiaoyan',
  // 加载组件
  components: {
    CreateForm,
    STable
  },
  props: {
    parm: {
      type: Object,
      default: () => {}
    }
  },

  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      deviceID: Number(this.$route.params.id),
      // 新建/编辑modal是否显示
      visible: false,
      // 配置机构
      orgVisible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      // 机构的模型
      orgmdl: null,
      // 模板可用的机构
      selectOrg: [],
      title: '新增设备',
      // 查询参数
      queryParam: {},
      selectedRowKeys: [],
      questionModeID: this.$route.params.id,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ deviceID: Number(this.$route.params.id) }, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return List(requestParameters).then(res => {
          return res
        })
      }
    }
  },
  // 方法
  methods: {
    // 新建事件
    handleAdd() {
      this.mdl = null
      this.visible = true
      this.title = '新增设备'
    },
    // 编辑事件
    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record }
      this.title = '编辑设备'
    },
    // 新建/编辑cancel事件
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    // 新建/编辑ok事件
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 如果id有值则表示是编辑状态，否则是新建状态
          if (values.id > 0) {
            var aa = { ...this.mdl, ...values }
            aa.control.updateDate = new Date()
            Update(aa).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('编辑成功')
            })
          } else {
            var parm = { ...values, deviceID: Number(this.$route.params.id) }
            Add(parm).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
        this.confirmLoading = false
      })
    },
    // 分类选择
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 启用/停用事件
    handleDel(record) {
      const title = record.control.deleteFlag ? '启用' : '停用'
      Modal.confirm({
        title: '您确定' + title + '该设备吗',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          Delete(record.id).then(res => {
            // 刷新表格
            this.$refs.table.refresh()
          })
        }
      })
    }
  },
  // 过滤器
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    },
     ResultFilter(type) {
      return ResultMap[type].text
    }
  }
}
</script>