var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 2 && data.xys[1].length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.fetchCombineList(data.form), function(v, index) {
                    return _c("div", { key: index }, [
                      _vm._v(" " + _vm._s(index + 1) + "."),
                      _c("span", { domProps: { innerHTML: _vm._s(v) } }),
                      v.indexOf("><") !== -1
                        ? _c("span", [_vm._v("的交互作用")])
                        : _vm._e(),
                      _vm._v("对<" + _vm._s(data.xys[1][0]) + ">的影响不显著 ")
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        }
      },
      {
        key: "H1",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 2 && data.xys[1].length > 0
              ? _c("div", [
                  data.xys[0].length > 2 && data.xys[1].length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.fetchCombineList(data.form), function(
                          v,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _vm._v(" " + _vm._s(index + 1) + "."),
                            _c("span", { domProps: { innerHTML: _vm._s(v) } }),
                            v.indexOf("><") !== -1
                              ? _c("span", [_vm._v("的交互作用")])
                              : _vm._e(),
                            _vm._v(
                              "对<" + _vm._s(data.xys[1][0]) + ">的影响显著 "
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }