var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["user-layout-wrapper", _vm.isMobile && "mobile"],
      attrs: { id: "userLayout" }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _c("router-view"),
          _c("div", { staticClass: "footer" }, [
            _c(
              "div",
              { staticClass: "links" },
              [
                _vm._m(1),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://beian.miit.gov.cn",
                      target: "_blank"
                    }
                  },
                  [_vm._v("苏ICP备11090926号-3")]
                )
              ],
              1
            ),
            _vm._m(2)
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "header" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/logo.svg"), alt: "logo" }
          }),
          _c("span", { staticClass: "title" }, [
            _vm._v("医学数据统计与分析平台MedSPSS")
          ])
        ])
      ]),
      _c("div", { staticClass: "desc" }, [
        _vm._v("为特定数据提供展示，分析，处理的专业平台。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/gongan.png" } }),
      _c(
        "a",
        {
          attrs: {
            href:
              "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021402001617",
            target: "_blank"
          }
        },
        [_vm._v("苏公网安备 32021402001617号")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("div", { staticClass: "copyright" }, [
        _vm._v("Copyright © 2021 无锡市优特科科技有限公司")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }