var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", id: "wrapper" } },
        [
          _c(
            "a-col",
            { staticStyle: { padding: "5px 0" }, attrs: { flex: "20px" } },
            [_c("div", { attrs: { id: "mark" } })]
          ),
          _c(
            "a-col",
            {
              staticStyle: { "word-break": "break-all" },
              attrs: { flex: "auto" }
            },
            [_vm._t("default", [_vm._v("结论")])],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }