<template>
  <div>
    <!-- <PC v-if="clientWidth > 578"></PC>
    <Mobile v-else></Mobile> -->
    <!-- <Mobile></Mobile> -->
    <MobileNew></MobileNew>
  </div>
</template>
<script>
// import PC from '@/views/article/components/PC'
import Mobile from '@/views/article/components/Mobile'
import MobileNew from '@/views/article/components/MobileNew'
export default {
  components: { Mobile, MobileNew },
  created() {
    this.clientWidth = document.body.clientWidth
    console.log('%c 🥩 this.clientWidth: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', this.clientWidth);
  },
  data() {
    return {
      clientWidth: 0
    }
  }
}
</script>