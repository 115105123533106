import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import user from './modules/user'
import document from './modules/document'
import hypothesis from './modules/hypothesis'
import samples from './modules/sample'
import analysisOfRegression from './modules/analysisOfRegression'
import report from './modules/report'

// default router permission control
import permission from './modules/permission'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    document,
    hypothesis,
    analysisOfRegression,
    samples,
    report
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
  // plugins: [createPersistedState()]
})