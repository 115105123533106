<template>
  <div>
    <div style="width: 90%; margin: 20px auto">
      <a-input v-model="search" placeholder="搜索变量">
        <a-icon slot="suffix" type="search" />
      </a-input>
    </div>
    <a-divider style="margin: 0px"></a-divider>
    <div class="leftWidthHypo fieldWrapper" id="field">
      <div v-for="(item, i) in Fields" :key="i" class="content" style="height: 35px; padding-left: 10px;">
        <a-tooltip placement="left" >
          <template slot="title">{{ isFieldType(item, 'tooltip') }}：{{ item.fieldName }}</template>
          <span
            draggable="true"
            @dragstart="dragstart($event, item)"
            @dragend="dragend"
            style="cursor: move;"
            :id="item.fieldName"
          >
            <img :src="`/chartsIcons/${isFieldType(item, 'icon')}.png`" alt="定类" style="margin-right: 10px;" />
            <span >{{ item.fieldName }}</span>
          </span>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  activated() {
    this.indexFields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
  },
  data() {
    return {
      count: this.$store.state.samples.keys ? JSON.parse(this.$store.state.samples.keys).length : 0, // 样本数量,
      indexFields: this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields),
      search: ''
    }
  },
  methods: {
    //  用户开始拖动元素时触发
    dragstart(event, data) {
      console.log('dragstart', event, data)
      event.dataTransfer.setData('item', JSON.stringify(data))
    },
    // 完成元素拖动后触发
    dragend(event) {
      event.dataTransfer.clearData()
    },
    // 判断定量还是定类的对象
    isFieldType(type, from) {
      switch (type.fieldType) {
        case '数字':
        case '日期范围':
          if (type.ptype) {
            return from === 'icon' ? 'dingliang_shixu' : '时序定量'
          } else {
            return from === 'icon' ? 'dingliang' : '定量'
          }
        case '单选':
          if (type.ptype) {
            if (type.forOrder === 1) {
              return from === 'icon' ? 'youxu_shixu' : '时序定序'
            } else {
              return from === 'icon' ? 'dinglei_shixu' : '时序定类'
            }
          } else {
            if (type.forOrder === 1) {
              return from === 'icon' ? 'youxu' : '定序'
            } else {
              return from === 'icon' ? 'dinglei' : '定类'
            }
          }
        case '多选':
        case '下拉框':
          if (type.ptype) {
            return from === 'icon' ? 'dinglei_shixu' : '时序定类'
          } else {
            return from === 'icon' ? 'dinglei' : '定类'
          }
         case '文本':
            return from === 'icon' ? 'wenben' : '文本'

        case '日期':
          return from === 'icon' ? 'riqi' : '日期'
        default:
          return from === 'icon' ? 'kong' : '其他变量'
      }
    }
  },
  computed: {
    Fields: {
      get() {
        return this.search
          ? this.indexFields.filter(element => element.fieldName.indexOf(this.search) !== -1)
          : this.indexFields
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  background-color: blanchedalmond;
}
.leftWidthChart {
  padding: 20px;
  background-color: white;
  border-right: 1px solid rgb(232, 232, 232);
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 999;
  width: 252px !important;
  overflow: auto;
}
.leftWidthHypo {
  padding: 20px;
  width: 100%;
  overflow: auto;
}
.ant-layout-sider {
  width: 100% !important;
  max-width: 100% !important;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.ant-layout-sider {
  background-color: white !important;
}
</style>