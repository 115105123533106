<template>
  <div v-if="tabsData !== null">
    <a-form-model :model="tabsData" @submit="handleSubmit" labelAlign="left">
      <div>
        <a-tabs type="editable-card" hide-add @edit="onEdit">
          <a-tab-pane
            v-for="item in tabsData.form"
            :key="item.modelId"
            :tab="item.modelName"
            style="padding-bottom:1px;"
            :closable="true"
          >
            <a-row style="padding-right:1px">
              <a-col v-for="formItem in item.tabContent" :key="formItem.id" :span="colLayout">
                <div class="formItemWrapper">
                  <form-items
                    :formItem="formItem"
                    :tabContent="item.tabContent"
                    :controller="'notModal'"
                  ></form-items>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-form-model>
  </div>
</template>
<script>

import FormItems from './formItems'
import { updateHistoryFields, deleteHistoryFields } from '@/api/historyField'
import { updateExamFields, deleteExamFields } from '@/api/examField'

export default {
  props: ['tabsData', 'colLayout', 'isSave', 'tabController'],
  data() {
    return {
    }
  },
  methods: {
    handleSubmit() {
      const req = []

      this.$deepClone(this.tabsData.form).forEach(element => {
        this.getChild(req, element.tabContent)
      })
      console.log('put', req)
      if (this.tabController === 'history') {
        updateHistoryFields(req).then(res => {
          console.log('请求history')
          // 这个参数用来判断完成了几个请求，以便重新刷新页面
          this.$store.dispatch('addfinishedReqCount')
        })
      } else {
        updateExamFields(req).then(res => {
          console.log('请求exam')
          // 这个参数用来判断完成了几个请求，以便重新刷新页面
          this.$store.dispatch('addfinishedReqCount')
        })
      }
    },
    getChild(req, list) {
      list.forEach(v => {
        if (v.children) {
          this.getChild(req, v.children)
        }
        var obj = {
          id: v.id,
          value: v.value
        }
        req.push(obj)
      })
    },
    // date转dateString
    transferDate(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      var dateString = year + '-' + month + '-' + day
      return dateString
    },
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    remove(key) {
      if (this.tabController === 'history') {
        deleteHistoryFields({
          docId: this.$store.state.document.currentUserId,
          modelId: key
        }).then(res => {
          console.log('删除成功')
          this.$emit('updateHistoryValue')
        })
      } else {
        deleteExamFields({
          docId: this.$store.state.document.currentUserId,
          modelId: key
        }).then(res => {
          console.log('删除成功')
          this.$emit('updateExamValue')
        })
      }
    }
  },
  components: { FormItems },
  watch: {
    isSave() {
      this.handleSubmit()
    }
  }
}
</script>
<style lang="less">
.formItemWrapper {
  border: 1px solid rgb(232, 232, 232);
  padding-left: 10px;
  margin-bottom: -1px;
  margin-right: -1px;
  .ant-form-item {
    margin: 5px 0;
  }
}
.form-autolabel .ant-form-item-label {
  width: auto !important;
}
</style>
