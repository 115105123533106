<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <template #H0="{ xys, form }">
      <span
        v-if="(xys.length === 2 && xys[0].length > 0 && xys[1].length > 0) || (xys.length === 1 && xys[0].length > 0)"
      >
        <span v-for="(item, index) in H0(xys, form)" :key="index">
          {{ item }}
        </span>
        <span
          v-show="xys.length === 2 && typeof xys[1][0] !== 'string'"
          style="margin-left: 20px; cursor: pointer"
          @click="reverseOption()"
        >
          <a-icon type="retweet" style="font-size: 20px;color:rgb(24, 144, 255);"/>
        </span>
      </span>
    </template>
    <template #H1="{ xys, form }">
      <span
        v-if="(xys.length === 2 && xys[0].length > 0 && xys[1].length > 0) || (xys.length === 1 && xys[0].length > 0)"
      >
        <span v-for="(item, index) in H1(xys, form)" :key="index">
          {{ item }}
        </span>
      </span>
    </template>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'zXys',
      xys: [[]],
      formAddKeyName: 'zFormAddKey',
      settings,
      form: {
        reducedValue: '0',
        judge: '＝',
        a: '5',
        fangCha: '1'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.reducedValue = Number(that.form.reducedValue)
        form.a = parseFloat(that.form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    },
    reverseOption() {
      var xys = this.$store.state.hypothesis[this.xysName]
      var lei = xys[1][0]
      lei = [lei[1], lei[0], lei[2]]
      xys[1].splice(0, 1, lei)
    }
  },
  computed: {
    H0() {
      return (xys, form) => {
        if (xys.length === 1) {
          form.H0 = [`${xys[0][0]}的平均值 ${form.judge} ${form.reducedValue}`]
        } else {
          if (typeof (xys[1][0]) === 'string') {
            form.H0 = [`${xys[0][0]}的平均值 - ${xys[1][0]}的平均值 ${form.judge} ${form.reducedValue}`]
          } else {
            form.H0 = [`${xys[1][0][0]}的${xys[0][0]}平均值 - ${xys[1][0][1]}的${xys[0][0]}平均值 ${form.judge} ${form.reducedValue}`]
          }
        }
        return form.H0
      }
    },
    H1() {
      return (xys, form) => {
        if (xys.length === 1) {
          form.H1 = [`${xys[0][0]}的平均值 ${this.$hypothesis.replaceJudge(form.judge)} ${form.reducedValue}`]
        } else {
          if (typeof (xys[1][0]) === 'string') {
            form.H1 = [`${xys[0][0]}的平均值 - ${xys[1][0]}的平均值 ${this.$hypothesis.replaceJudge(form.judge)} ${form.reducedValue}`]
          } else {
            form.H1 = [`${xys[1][0][0]}的${xys[0][0]}平均值 - ${xys[1][0][1]}的${xys[0][0]}平均值 ${this.$hypothesis.replaceJudge(form.judge)} ${form.reducedValue}`]
          }
        }
        return form.H1
      }
    }
  }
}
</script>