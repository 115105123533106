<template>
  <div>
    <div v-for="(item, index) in settings" :key="index">
      <a-form-model-item
        :extra="item.type === 'custom1' ? '(参考项可任意选择一个)' : ''"
        :class="{
          myRequired:
            ['drop', 'dropSelect', 'text', 'component'].includes(item.type) && !item.hasOwnProperty('required'),
        }"
        :labelCol="item.label ? { md: { span: 7 }, lg: { span: 6 }, xxl: { span: 4 } } : { span: 0 }"
        :wrapperCol="item.label ? { md: { span: 17 }, lg: { span: 18 }, xxl: { span: 20 } } : { span: 24 }"
        :label="item.label"
        v-if="item.parentValue === parentValue"
        :style="{ alignItems: item.type === 'custom1' ? 'start' : 'center' }"
        :prop="item.prop"
      >
        <a-radio-group
          v-if="item.type === 'radio' || item.type === 'radioSelect'"
          v-model="item.value"
          @change="(e) => onRadioChange(e, item)"
        >
          <a-row v-if="'optionsColLg' in item">
            <a-col
              style="margin: 5px 0"
              :xxl="item.optionsColLg"
              :sm="item.optionsColSm"
              v-for="(option, optionIndex) in item.options"
              :key="optionIndex"
            >
              <a-radio :value="option.value">
                <span v-html="option.text">{{ option.text }}</span>
              </a-radio>
            </a-col>
          </a-row>
          <div v-else>
            <a-radio v-for="(option, optionIndex) in item.options" :key="optionIndex" :value="option.value">
              <span v-html="option.text">{{ option.text }}</span>
            </a-radio>
          </div>
        </a-radio-group>
        <a-select
          v-else-if="item.type === 'select' || item.type === 'selectSelect'"
          v-model="item.value"
          @change="(e) => onSelectChange(e, item)"
        >
          <a-select-option v-for="(option, optionIndex) in item.options" :key="optionIndex" :value="option.value">
            {{ option.text }}
          </a-select-option>
        </a-select>
        <a-input
          v-else-if="item.type === 'input'"
          :placeholder="typeof item.placeholder === 'string' ? item.placeholder : item.placeholder()"
          v-model="item.value"
          @change="(e) => onInputChange(e, item)"
        />
        <a-checkbox-group
          v-else-if="item.type === 'checkbox'"
          v-model="item.value"
          :options="item.options"
          @change="(e) => onCheckboxChange(e, item)"
        ></a-checkbox-group>
        <div
          type="text"
          v-else-if="item.type === 'drop' || item.type === 'dropSelect'"
          class="ant-input dropDiv"
          @drop="drop(item)"
          @dragover.prevent
        >
          <span v-for="help in item.divHelp" :key="help" class="helpFontColor">{{ help }}</span>
          <a-tag
            closable
            @close="(e) => onClose(e, item, i, value)"
            v-for="(value, i) in item.list"
            :key="i"
            :visible="value !== null"
          >{{ value.name }}</a-tag
          >
        </div>
        <div v-else-if="item.type === 'text'" style="font-size: 14px; color: red">
          {{ item.value }}
        </div>
        <div
          v-else-if="item.type === 'textarea'"
          style="display: flex; align-items: center"
          v-html="item.value($store, settings, $hypothesis)"
        >
          {{ item.value($store, settings, $hypothesis) }}
        </div>
        <div v-else-if="item.type === 'custom1'">
          <a-table
            v-if="item.data.length > 0"
            :showHeader="false"
            size="small"
            :columns="item.columns"
            :data-source="item.data"
            bordered
            :pagination="false"
          >
            <template slot="options" slot-scope="text, record, i">
              <a-radio-group
                v-model="record.value"
                @change="
                  (e) => $store.state.analysisOfRegression[item.formName].compareList.splice(i, 1, e.target.value)
                "
              >
                <a-radio :value="option" v-for="(option, optionIndex) in text" :key="optionIndex">{{ option }}</a-radio>
              </a-radio-group>
            </template>
          </a-table>
          <span v-else>无</span>
        </div>
        <component
          v-else-if="item.type === 'component'"
          :is="item.componentName"
          :item="item"
          :vuex="'analysisOfRegression'"
          :settings="settings"
          :load="load"
          @updateForm="updateForm"
        ></component>
      </a-form-model-item>
      <drop-items2
        v-if="item.children.length > 0 && item.parentValue === parentValue"
        :settings="item.children"
        :formAddKeyName="formAddKeyName"
        :indexSettings="indexSettings"
        :parentValue="item.value"
        :refs="refs"
        :xys="xys"
        :load="load"
        @updateForm="updateForm"
        @initialRules="$emit('initialRules')"
      ></drop-items2>
    </div>
  </div>
</template>
<script>
/** 为什么有了inputItems.js里的配置了，还要在index里定义form和vuex里定义xys?
 * 因为inputItems.js里的settings有可能是嵌套结构的，在提交表单的时候，不能够方便的取到各个值，
 * 因此每次操作把值再填写一遍到form和vuex里，提交表单的时候直接提交form和vuex就可以了。
 * * */

import { DropClass } from '@/utils/util'
import DropItems2 from './dropItems'
import CheckBox from '@/views/analysisOfRegression/components/fitting/fitting/formItem'
import Step from '@/views/analysisOfRegression/components/nonlinearRegression/polynomialRegression/formItem'
import Combine from '@/views/analysisOfRegression/components/smooth/splineSmooth/formItem'
import LeiOptions from '@/views/analysisOfRegression/components/nonlinearRegression/logic/LeiOptions'
import LeiOptions2 from '@/views/analysisOfRegression/components/generateAnalysis/cox/LeiOptions2'
// import Drops from '@/views/analysisOfRegression/components/smooth/splineSmooth/drops'
import Drops from '@/components/myComponents/drops'
export default {
  components: {
    CheckBox,
    DropItems2,
    Step,
    Combine,
    Drops,
    LeiOptions,
    LeiOptions2
  },
  props: ['indexSettings', 'settings', 'parentValue', 'formAddKeyName', 'refs', 'xys', 'load'],
  data() {
    return {
      timer: {}
    }
  },
  methods: {
    drop(obj) {
      this.load.anysisLoading = true
      const djson = event.dataTransfer.getData('item')
      const item = JSON.parse(djson)
      // 如果是可以选择的拖拽框，那么根据拖拽的变量的类别来决定这个框的属性。
      this.$hypothesis.changeDropRule(obj, item, this)
      const checkObj = {
        num: obj.num,
        type: obj.dropType,
        location: obj.label,
        optLimit: obj.optLimit,
        vm: this,
        load: { anysisLoading: false }
      }
      const dropObj = new DropClass(obj.list, item, obj.divHelp, this.$message, `aaa`, this, true, obj)
      dropObj.check(checkObj)
      // this.timer = setInterval(() => {
      //   console.log('dropItem', item2)
      //   if (!this.load.anysisLoading) {
      //     clearInterval(this.timer)
      //     // 如果校验规则通过，那么就要把拖拽项加入到store里的xys列表里去，如果当前拖拽的是定类，还要把它的所有类别都找出来存入它的属性当中去。
      //     if (item2) {
      //       if (item2.type === '类') {
      //         item2.options = dropObj.fetchOption()
      //       }
      //       if (obj.hasOwnProperty('onChange')) obj.onChange(obj, item2, this)
      //     }
      //     // this.$store.dispatch('pushDropObj', item)
      //     // 如果拖拽框里一个变量都没有，那么初始化它以及它的子内容。
      //     if (obj.list.length === 0) {
      //       this.initialDropDiv(obj)
      //     }
      //   }
      // }, 500)
    },
    onClose(e, item, index, value) {
      item.list.splice(index, 1)
      if (item.hasOwnProperty('onClose')) item.onClose(item, value, this, index)
      // 如果拖拽框里一个变量都没有，那么初始化它以及它的子内容。
      if (item.list.length === 0) {
        this.initialDropDiv(item)
      }
    },
    // 初始化拖拽框
    initialDropDiv(item) {
      var a = this.$hypothesis.findChildren(item.id, this.$deepClone(this.indexSettings))
      // 如果是dropSelect，那么执行下面代码
      if (item.options.length > 0) {
        item.label = a.label
        item.value = a.value
        item.type = a.type
        item.children = a.children
      }
      item.divHelp = a.divHelp
      this.$emit('initialRules')
    },
    // 往form里提交数据
    formAddKey(obj) {
      this.$store.state.analysisOfRegression[this.formAddKeyName] = []
      this.$store.state.analysisOfRegression[this.formAddKeyName].push({
        key: obj.prop,
        value: obj.value
      })
    },
    updateForm(key, value) {
      this.$emit('updateForm', key, value)
    },
    onRadioChange(e, item) {
      if (item.type === 'radioSelect') { item.onChange(e, item, this, this.refs) } else {
        console.log('%c 🍷 item: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', item.value)
        this.formAddKey(item)
      }
    },
    onSelectChange(e, item) {
      if (item.type === 'selectSelect') { item.onChange(e, item, this) }
      this.formAddKey(item)
    },
    onInputChange(e, item) {
      const breakMark = [false]
      if ('onChange' in item) item.onChange(e, item, this, breakMark)
      if (!breakMark[0]) this.formAddKey(item)
    },
    onCheckboxChange(e, item) {
      if ('onChange' in item) item.onChange(item, this)
      this.formAddKey(item)
    },
    onInnerRadioOfCustom1Change(e, i) {
      this.$store.state.analysisOfRegression.ordinaryLeastSquaresForm.compareList[i] = e.target.value
    }
  }
}
</script>
<style>
</style>