import {
  onChange,
  onClose
} from '@/views/analysisOfRegression/utils/inputItemsClass'
var xysName = 'robustRegressionXys'

function makeOption(item, that) {
  const obj = that.$hypothesis.findChildren(2, that.settings)
  const endOption = item.options.slice(-1)[0]
  obj.data.push({
    lei: item.name,
    key: item.name,
    options: item.options,
    value: endOption
  })
  that.$store.state.analysisOfRegression.robustRegressionForm.compareList.push(endOption)
}

function destroyOption(item, that) {
  const obj = that.$hypothesis.findChildren(2, that.settings)
  const index = obj.data.findIndex(v => v.lei === item.name)
  obj.data.splice(index, 1)
  that.$store.state.analysisOfRegression.robustRegressionForm.compareList.splice(index, 1)
}

export const settings = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定量/定类】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['类', '量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 20, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(1个 ≤ 个数 ≤ 20个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      if (item.optionInit.length > 0) {
        onChange.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, item)
        makeOption(item, that)
      } else {
        onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.optionInit.length > 0) {
        onClose.manyLei(that.$store.state.analysisOfRegression[xysName], this.index, index)
        destroyOption(item, that)
      } else {
        onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
      }
    }
  },
  {
    id: 1,
    index: 1,
    prop: 'dependentVariable',
    label: '因变量y【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
    }
  },
  {
    label: '协方差',
    prop: 'cov',
    parentValue: 1,
    type: 'radio',
    value: 'H1',
    options: [{
        text: 'H1',
        value: 'H1'
      },
      {
        text: 'H2',
        value: 'H2'
      },
      {
        text: 'H3',
        value: 'H3'
      }
    ],
    children: []
  },
  {
    id: 2,
    label: '哑变量对比参考项',
    prop: 'compare',
    parentValue: 1,
    formName: 'robustRegressionForm',
    type: 'custom1',
    columns: [{
        title: '类别',
        key: 'lei',
        dataIndex: 'lei',
        width: 150,
        align: 'center'
      },
      {
        title: '项',
        key: 'options',
        dataIndex: 'options',
        scopedSlots: {
          customRender: 'options'
        }
      }
    ],
    data: [],
    children: []
  }
]