var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-left": "20px" } },
    [
      _c(
        "a-checkbox",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { checked: _vm.without0 },
          on: { change: _vm.onCheckChange }
        },
        [_vm._v("去零处理")]
      ),
      _c(
        "a-checkbox",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { checked: _vm.outlier },
          on: { change: _vm.outlierChange }
        },
        [_vm._v("离群值自动处理")]
      ),
      _vm._v(" 当前样本数: "),
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "8px",
            "margin-right": "8px",
            "font-size": "16px",
            "font-weight": "bold",
            color: "#1890ff"
          }
        },
        [_vm._v(_vm._s(_vm.count))]
      ),
      _vm._v(" 数据源: "),
      _c(
        "span",
        {
          staticStyle: {
            "margin-left": "8px",
            "font-size": "14px",
            "font-weight": "bold",
            color: "#1890ff"
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm.$store.state.samples.sourceModule == "量表(问卷)数据"
                  ? _c(
                      "div",
                      _vm._l(_vm.$store.state.samples.qesNameAry, function(
                        name,
                        i
                      ) {
                        return _c("div", { key: i }, [_vm._v(_vm._s(name))])
                      }),
                      0
                    )
                  : _c("div", [
                      _vm._v(_vm._s(_vm.$store.state.samples.sourceModule))
                    ])
              ]),
              _vm._v(" " + _vm._s(_vm.$store.state.samples.fileName) + " ")
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-tooltip",
        { attrs: { placement: "bottom" } },
        [
          _c("template", { slot: "title" }, [_vm._v(" 图表设置 ")]),
          _c("a-icon", {
            staticStyle: { "margin-left": "20px", "font-size": "20px" },
            attrs: { type: "setting", theme: "twoTone" },
            on: { click: _vm.onSetting }
          })
        ],
        2
      ),
      _c(
        "a-button",
        {
          staticStyle: { "margin-left": "28px" },
          attrs: { type: "primary" },
          on: { click: _vm.onBack }
        },
        [_vm._v("<< 重新选择数据")]
      ),
      _c("Settings", {
        attrs: { visible: _vm.setVisible, data: _vm.setData },
        on: { cancel: _vm.handleCancel, ok: _vm.handleOk }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }