var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("basic-information", {
        attrs: { isSave: _vm.isSave },
        on: {
          refreshTable: function($event) {
            return _vm.$emit("refreshtable")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "documentItemWrapper" },
        [
          _c("medical-history-modal", {
            attrs: {
              isAddMedicalHistory: _vm.isAddMedicalHistory,
              tabController: "history",
              tabsExamList: _vm.healthData,
              tabsHistoryList: _vm.tabsData
            },
            on: {
              handleCancel: function($event) {
                _vm.isAddMedicalHistory = false
              },
              updateHistoryValue: function($event) {
                return _vm.fetchDocHistory(_vm.$route.params.id)
              },
              updateExamValue: function($event) {
                return _vm.fetchDocExam(_vm.$route.params.id)
              }
            }
          }),
          _c("h1", { staticStyle: { float: "left" } }, [_vm._v("既往史")]),
          _c(
            "div",
            { staticClass: "addTag", on: { click: _vm.handleHistoryAddTag } },
            [
              _c("a-icon", {
                attrs: {
                  type: "plus-circle",
                  theme: "twoTone",
                  twoToneColor: "#1890FF"
                }
              }),
              _c("span", { staticClass: "iconText" }, [_vm._v("添加既往史")])
            ],
            1
          ),
          _c("div", { staticStyle: { clear: "both" } }),
          _c("medical-history", {
            attrs: {
              tabsData: _vm.tabsData,
              colLayout: _vm.tabsLayout.history,
              isSave: _vm.isSave,
              tabController: "history"
            },
            on: {
              updateHistoryValue: function($event) {
                return _vm.fetchDocHistory(_vm.$route.params.id)
              }
            }
          })
        ],
        1
      ),
      false
        ? _c(
            "div",
            { staticClass: "documentItemWrapper" },
            [
              _c("medicine-modal", {
                attrs: { isMedicine: _vm.isAddMedicine },
                on: {
                  handleCancel: function($event) {
                    _vm.isAddMedicine = false
                  }
                }
              }),
              _c("h1", { staticStyle: { float: "left" } }, [_vm._v("用药史")]),
              _c(
                "div",
                {
                  staticClass: "addTag",
                  on: {
                    click: function($event) {
                      _vm.isAddMedicine = true
                    }
                  }
                },
                [
                  _c("a-icon", {
                    attrs: {
                      type: "plus-circle",
                      theme: "twoTone",
                      twoToneColor: "#1890FF"
                    }
                  }),
                  _c("span", { staticClass: "iconText" }, [
                    _vm._v("添加用药史")
                  ])
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } }),
              _c("medicine")
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "documentItemWrapper" },
        [
          _c("medical-history-modal", {
            attrs: {
              isAddMedicalHistory: _vm.isAddMedicalHistory,
              tabController: _vm.tabController,
              tabsExamList: _vm.healthData,
              tabsHistoryList: _vm.tabsData
            },
            on: {
              handleCancel: function($event) {
                _vm.isAddMedicalHistory = false
              },
              updateExamValue: function($event) {
                return _vm.fetchDocExam(_vm.$route.params.id)
              },
              updateHistoryValue: function($event) {
                return _vm.fetchDocHistory(_vm.$route.params.id)
              }
            }
          }),
          _c("h1", { staticStyle: { float: "left" } }, [_vm._v("相关检查")]),
          _c(
            "div",
            { staticClass: "addTag", on: { click: _vm.handleExamAddTag } },
            [
              _c("a-icon", {
                attrs: {
                  type: "plus-circle",
                  theme: "twoTone",
                  twoToneColor: "#1890FF"
                }
              }),
              _c("span", { staticClass: "iconText" }, [_vm._v("添加相关检查")])
            ],
            1
          ),
          _c("div", { staticStyle: { clear: "both" } }),
          _c("medical-history", {
            attrs: {
              tabsData: _vm.healthData,
              colLayout: _vm.tabsLayout.health,
              isSave: _vm.isSave,
              tabController: "exam"
            },
            on: {
              updateExamValue: function($event) {
                return _vm.fetchDocExam(_vm.$route.params.id)
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "padding-bottom": "30px" } },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.isSave = !_vm.isSave
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }