import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
var xysName = 'sybksjyXys'

export const settings = [{
    id: 0,
    prop: 'liang',
    label: '样本【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, index)
    }
  },
  {
    id: 1,
    prop: 'leiOrLiang',
    xysIndex: 1,
    label: '组别或样本2【定类或定量】',
    onChange(obj, item, that) {
      if (item.type === '类') {
        onChange.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, item)
      } else if (item.type === '量') {
        onChange.liang(that.$store.state.hypothesis[xysName], this.xysIndex, item)
      }
    },
    onClose(obj, item, that, index) {
      if (item.type === '类') {
        onClose.manyLei(that.$store.state.hypothesis[xysName], this.xysIndex, index)
      } else if (item.type === '量') {
        onClose.liang(that.$store.state.hypothesis[xysName], this.xysIndex, index)
      }
    },
    parentValue: 1,
    type: 'dropSelect',
    options: [{
        value: '类',
        label: '组别【定类】',
        type: 'drop',
        optLimit: 2,
        dropType: ['类']
      },
      {
        label: '样本2【定量】',
        value: '量',
        type: 'drop',
        optLimit: null,
        dropType: ['量']
      }
    ],
    dropType: '',
    num: 1,
    value: '',
    list: [],
    optLimit: null,
    divHelp: ['请拖入左侧变量：(个数＝1个，且定类变量中只能有两种类别)'],
    children: []
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  },
  {
    label: '判断条件',
    prop: 'judge',
    parentValue: 1,
    type: 'radio',
    value: '＝',
    options: [{
      text: '≥',
      value: '≥'
    }, {
      text: '=',
      value: '＝'
    }, {
      text: '≤',
      value: '≤'
    }],
    children: []
  }
]