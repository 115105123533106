<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'f检验分析结果'"></myTable>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'f智能分析结果'">
              <div>
                <div v-for="(item, index) in result" :key="index">
                  <span v-html="item"></span>
                </div>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['F', 'p'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.leiLiang()
    }
  },
  computed: {
    lei() { return this.part2.xys[0][0].slice(-1)[0] },
    option1() { return this.part2.xys[0][0][0] },
    option2() { return this.part2.xys[0][0][1] },
    liang() { return this.part2.xys[1][0] },
    H0() {
      return [`&lt;${this.lei}&gt;中&lt;${this.option1}&gt;的&lt;${this.liang}&gt;方差 ${this.part2.judge} &lt;${this.lei}&gt;中&lt;${this.option2}&gt;的&lt;${this.liang}&gt;方差`]
    },
    H1() {
      return [`&lt;${this.lei}&gt;中&lt;${this.option1}&gt;的&lt;${this.liang}&gt;方差 ${this.part2.judge2} &lt;${this.lei}&gt;中&lt;${this.option2}&gt;的&lt;${this.liang}&gt;方差`]
    },
    result() { return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0])}`] }
  }
}
</script>