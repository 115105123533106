
<template>
  <page-header-wrapper :breadcrumb="null">
    <template v-slot:extra>
      <router-link to="/commonchart">
        <a-button type="primary" style="margin-left: 28px;">档案统计</a-button>
      </router-link>
    </template>
    <a-layout class="divContent" id="divContent">
      <a-layout-sider class="divContentleft" id="divContentleft" >
        <a-row>
          <a-col :span="24">
            <div>
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="isNewAdd = true">新建</a-button>
              </div>
              <div class="table-page-search-wrapper" style="margin-bottom: 5px">
                <a-row :gutter="38">
                  <a-col :md="80" :sm="24">
                    <a-input v-model="queryParam.name" placeholder="搜索用户" @change="$refs.table.refresh(true)" />
                  </a-col>
                </a-row>
              </div>
              <s-table
                ref="table"
                size="small"
                rowKey="key"
                :columns="columns"
                :data="loadData"
                :pagination="pagination"
                :pageSize="pageSize"
                :customRow="click"
                :rowClassName="(record) => (record.id === this.docId ? 'clickRowStyle' : '')"
              >
                <span slot="name" slot-scope="text">{{ text | hideName }}</span>
              </s-table>
            </div>
          </a-col>
        </a-row>
      </a-layout-sider>
      <a-layout-content style="padding-top: 20px">
        <a-row type="flex" justify="center">
          <a-col :span="22">
            <router-view @refreshtable="$refs.table.refresh()"></router-view>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
    <basic-information-modal
      :isNewAdd="isNewAdd"
      @handleCancel="isNewAdd = false"
      @refreshDocList="$refs.table.refresh(true)"
    ></basic-information-modal>
  </page-header-wrapper>
</template>
<script>
import { STable } from '@/components'
import { getDocumetList } from '@/api/document'
import Document from './document'
import BasicInformationModal from './basicInformationModal'
import { AutoHeight } from '@/utils/util'

const columns = [
  {
    title: '编号',
    dataIndex: 'serialNo'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  }
]

export default {
  components: {
    STable,
    Document,
    BasicInformationModal
  },
  data() {
    this.columns = columns
    return {
      // 查询参数
      queryParam: {
      },
      pagination: {
        simple: true,
        size: 'small'
      },
      pageSize: document.body.clientHeight > 800 ? 15 : 10,
      loadData: parameter => {
        var requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return getDocumetList({ ...requestParameters, orgid: this.$store.state.user.info.orgid }).then(res => {
          console.log('get患者列表', res)
          this.$store.dispatch('saveDocList', res.data)
          return res
        })
      },
      // 是否点击了新建按钮
      isNewAdd: false,
      docId: null
    }
  },
    created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
    // 高度自定义使用
    // 高度自定义使用
    AutoHeight(this, ['divContent', 'divContentleft'], [], 176)
    },
    customCell(record, index) {
      return {
        style: {
          padding: '5px !important'
        }
      }
    },
    click(record, index) {
      return {
        on: {
          click: () => {
            console.log('asdf', record, index)
            this.docId = record.id
            this.$router.push(`/document/${record.id}`)
          }
        }
      }
    }
  },
  filters: {
    hideName(value) {
      if (value) {
        if (value.length < 3) {
          value = value.slice(0, 1)
          return value + '*'
        } else {
          const value1 = value.slice(0, 1)
          const value2 = value.substring(value.length - 1, value.length)
          const length = value.length - 2
          let xx = ''
          for (let i = 0; i < length; i++) {
            xx = xx + '*'
          }
          return value1 + xx + value2
        }
      } else { return '---' }
    }
  }
}
</script>
<style>
.clickRowStyle {
  background-color: #e6f7ff;
}
</style>
