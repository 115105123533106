var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticStyle: { width: "450px", "min-height": "150px !important" },
      attrs: { size: "small", title: "Default size card" }
    },
    [_c("p", [_vm._v("card content")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }