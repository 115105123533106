import {
  DataFilterClass
} from '@/utils/dataFilterClass'
import store from '@/store'

// import {
//   getPictureSize
// } from '@/utils/util'

export class MakeReq {
  constructor(form, method, that, isDrops = false) {
    this.form = {
      ...form,
      style: {
        language: store.state.user.info.setting.language
      }
    }
    this.method = method
    this.that = that
    // 数据清理
    if (isDrops) {
      this.filterForDrops()
    } else {
      this.filter()
    }
    this.makeReq()
  }
  // 数据清理
  filter() {
    const list = []
    this.form.xys.forEach(v => {
      v.forEach(i => {
        list.push(i)
      })
    })
    this.that.documents = new DataFilterClass(list, true).main()
  }
  // 数据清理，给drops组件用的
  filterForDrops() {
    const list = []
    this.form.xys.forEach(group => {
      group.forEach(drop => {
        drop.forEach(i => {
          list.push(i)
        })
      })
    })
    this.that.documents = new DataFilterClass(list, true).main()
  }
  // 构造req
  makeReq() {
    this.req = {}
    const args = {
      action: this.method,
      count: this.that.documents.length,
      colors: JSON.parse(this.that.$store.state.user.info.setting.colors),
      field: {
        x: this.form.x
      },
      style: {
        language: this.that.$store.state.user.info.setting.language
      }
    }
    this.req.args = args
    this.req.method = 'chart'
  }

  Pearson() {
    // 取到存放定量名字的数组
    const liangNameAry = this.form.xys[0]
    // 定义存放最终数据结构的数组
    const data = []
    this.that.documents.forEach(element => {
      // 构造key,value形式的数据
      const obj = {}
      liangNameAry.forEach(liangName => {
        const value = element[liangName]
        obj[liangName] = isNaN(+value) ? value : +value
      })
      data.push(obj)
    })
    this.req.args.data = JSON.stringify(data)
    return this.req
  }

  Phi() {
    // 取到存放定类名字的数组
    const LeiNameAry = this.form.xys[0].map(v => v[v.length - 1])
    // 定义存放最终数据结构的数组
    const data = []
    this.that.documents.forEach(element => {
      // 构造key,value形式的数据
      const obj = {}
      LeiNameAry.forEach(leiName => {
        const value = element[leiName]
        obj[leiName] = isNaN(+value) ? value : +value
      })
      data.push(obj)
    })
    this.req.args.data = JSON.stringify(data)
    return this.req
  }
}