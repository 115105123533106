<template>
  <div>
    <a-modal title="修改密码" :visible="show" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form-model labelAlign="left" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }" ref="form" :model="form" :rules="rules" style="width:80%">
        <a-form-model-item label="原密码" prop="oldPassword">
          <a-input-password
            :value="form.oldPassword"
            @change="(e) => handlePassword(e, 'oldPassword')"
            placeholder="请输入原密码"
          />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPassword1">
          <a-input-password
            :value="form.newPassword1"
            @change="(e) => handlePassword(e, 'newPassword1')"
            placeholder="请输入新密码"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="newPassword2">
          <a-input-password
            :value="form.newPassword2"
            @change="(e) => handlePassword(e, 'newPassword2')"
            placeholder="请确认新密码"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import md5 from 'md5'
import { changePassword } from '@/api/login'
export default {
  props: ['show'],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.form.newPassword2 !== '') {
          this.$refs.form.validateField('newPassword2')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认新密码'))
      } else if (value !== this.form.newPassword1) {
        callback(new Error('两次输入的新密码不一致'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
        userId: Number(this.$store.state.user.info.id)
      },
      form2: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
        userId: Number(this.$store.state.user.info.id)
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'change' }],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          { validator: validatePass, trigger: 'change' }],
        newPassword2: [
          { required: true, message: '请确认新密码', trigger: 'change' },
          { validator: validatePass2, trigger: 'change' }]
      },
      ModalText: 'Content of the modal',
      visible: true,
      confirmLoading: false
    }
  },
  methods: {
    handlePassword(e, prop) {
      const value = e.target.value
      this.form2[prop] = md5(value)
      this.form[prop] = value
    },
    handleOk(e) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          console.log('%c 🍯 form: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', this.form)
          console.log('%c 🍯 form: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', this.form2)
          changePassword(this.form2).then(res => {
            console.log('%c 🌽 res: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', res)
            if (res === '修改成功') {
              this.$message.success(res, 5)
              this.handleCancel()
              this.$refs['form'].resetFields()
            } else if (res === '原密码不正确，请重新输入') {
              this.$message.warning(res, 10)
            } else { this.$message.warning(res, 10) }
            this.confirmLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCancel() {
      this.$emit('show')
    }
  }
}
</script>
<style scoped>
.ant-modal-body {
  display: flex;
  justify-content: center;
}
</style>