<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" class="deviceC">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="设备名称">
              <a-input v-model="queryParam.name" placeholder />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" :style="{}">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="
                  () => {
                    this.queryParam = {}
                    $refs.table.refresh(true)
                  }
                "
              >重置</a-button
              >
            </span>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-row type="flex" justify="end">
              <a-col>
                <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
      :row-selection="questionModeID && { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 30 }"
    >
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ text && $moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </span>
      <span slot="deleteFlag" slot-scope="text">
        <template>
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleJiaoyan(record)">校验记录</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDel(record)">{{ record.control.deleteFlag ? '启用' : '停用' }}</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      :title="title"
      @cancel="handleCancel"
      @ok="handleOk"
    />

    <a-button style="margin-top: 20px; float: right" type="primary" @click="onSubmitGroup" v-if="questionModeID"
    >确认选择</a-button
    >
  </a-card>
</template>
<script>
import CreateForm from './components/CreateForm'
import { STable } from '@/components'
import { getDevices, Add, Update, Delete } from '@/api/exp/device'
import { Modal } from 'ant-design-vue'
// 列
const colums = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 80
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 250
  },
  {
    title: '最后一次校验时间',
    dataIndex: 'lastCalibrationDate',
    scopedSlots: { customRender: 'date' },
    width: 250
  },
  {
    title: '最后一次校验结果',
    dataIndex: 'lastCalibrationResult',

    width: 250
  },
  {
    title: '状态',
    dataIndex: 'control.deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' },
    width: 80,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    // width: '150px',
    scopedSlots: { customRender: 'action' },
    width: 150,
    align: 'center'
  }
]
// 状态
const StatusMap = {
  0: {
    status: 'success',
    text: '启用'
  },
  1: {
    status: 'error',
    text: '停用'
  }
}
export default {
  name: 'Device',
  // 加载组件
  components: {
    CreateForm,
    STable
  },
  // 页面展示的数据
  data() {
    this.columns = colums
    return {
      // 新建/编辑modal是否显示
      visible: false,
      // 配置机构
      orgVisible: false,
      // 加载...
      confirmLoading: false,
      // 编辑的模型
      mdl: null,
      // 机构的模型
      orgmdl: null,
      // 模板可用的机构
      selectOrg: [],
      title: '新增设备',
      // 查询参数
      queryParam: {},
      selectedRowKeys: [],
      questionModeID: this.$route.params.id,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ orgid: this.$store.state.user.info.org.id }, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return getDevices(requestParameters).then(res => {
          return res
        })
      }
    }
  },
  // 方法
  methods: {
    handleJiaoyan(record) {
      this.$router.push(`/experiment/Device/calibration/${record.id}`)
    },
    // 新建事件
    handleAdd() {
      this.mdl = null
      this.visible = true
      this.title = '新增设备'
    },
    // 编辑事件
    handleEdit(record) {
      this.visible = true
      // 这边简单可以直接用record，复杂的需要调用api
      this.mdl = { ...record }
      this.title = '编辑设备'
    },
    // 新建/编辑cancel事件
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    // 新建/编辑ok事件
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 如果id有值则表示是编辑状态，否则是新建状态
          if (values.id > 0) {
            var aa = { ...this.mdl, ...values }
            aa.control.updateDate = new Date()
            Update(aa).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('编辑成功')
            })
          } else {
            Add(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
        this.confirmLoading = false
      })
    },
    // 分类选择
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 启用/停用事件
    handleDel(record) {
      const title = record.control.deleteFlag ? '启用' : '停用'
      Modal.confirm({
        title: '您确定' + title + '该设备吗',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          Delete(record.id).then(res => {
            // 刷新表格
            this.$refs.table.refresh()
          })
        }
      })
    }

  },
  // 过滤器
  filters: {
    StatusFilter(type) {
      return StatusMap[type].text
    },
    statusTypeFilter(type) {
      return StatusMap[type].status
    }

  }
}
</script>
<style lang="less" >
.deviceC .ant-form-item {
  margin: 0px !important;
}
</style>