<template>
  <a-row type="flex" justify="center">
    <a-col :span="7" id="registerContainer">
      <a-row type="flex" justify="center" align="middle">
        <a-col :span="16">
          <div id="firstLine">找回密码</div>
          <!-- <router-link to="/personLogin/login">去登录？</router-link> -->
          <div id="formWrapper">
            <a-form-model ref="form" :model="form" :rules="rules">
              <a-alert v-if="errorMsg" type="error" showIcon style="margin-bottom: 24px" message="该手机号尚未注册" />
              <a-form-model-item has-feedback prop="mobile">
                <a-input v-model="form.mobile" placeholder="请输入手机号" :size="inputSize">
                  <a-icon slot="prefix" type="mobile" class="icon" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item has-feedback prop="password">
                <a-input-password
                  v-model="form.password"
                  placeholder="请输入新的登录密码"
                  :size="inputSize"
                  type="password"
                  autocomplete="off"
                >
                  <a-icon slot="prefix" type="lock" class="icon" />
                </a-input-password>
              </a-form-model-item>
              <a-form-model-item has-feedback prop="password2">
                <a-input-password
                  v-model="form.password2"
                  placeholder="请再次输入新的登录密码"
                  :size="inputSize"
                  type="password"
                  autocomplete="off"
                >
                  <a-icon slot="prefix" type="lock" class="icon" />
                </a-input-password>
              </a-form-model-item>
              <VerificationCode :form="form" :inputSize="inputSize"></VerificationCode>
              <a-row style="margin: 17px 0 7px 0">
                <a-col :span="24">
                  <a-button
                    type="primary"
                    block
                    :size="inputSize"
                    @click="register"
                    :loading="buttonState.loginBtn"
                    :disabled="buttonState.loginBtn"
                  >确定</a-button
                  >
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
import { findPassword } from '@/api/login'
import md5 from 'md5'
import VerificationCode from '@/components/myComponents/VerificationCode.vue'

export default {
  components: { VerificationCode },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录密码'));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('password2');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入登录密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两个密码不一致'));
      } else {
        callback();
      }
    };
    return {
      form: {
        mobile: null,
        password: null,
        password2: null,
        verificationCode: null
      },
      rules: {
        mobile: [
          { required: true, message: '请输入正确的手机号码', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePass2, trigger: 'blur' }],
        verificationCode: [{ required: true, message: '请输入正确的验证码', trigger: 'blur', pattern: /^\d{6}$/ }]
      },
      inputSize: 'large',
      buttonState: {
        loginBtn: false
      },
      errorMsg: false
    }
  },
  methods: {
    register() {
      const { buttonState, resetButton } = this
      buttonState.loginBtn = true
      const { form } = this
      this.$refs.form.validate(valid => {
        if (valid) {
          const req = {
            mobile: form.mobile,
            password: md5(form.password)
          }
          findPassword(req).then(res => {
            console.log(res)
            if (res === '该手机号尚未注册') {
              this.errorMsg = true
            } else {
              this.$message.success('新密码设置成功')
              resetButton()
              this.$router.push('/personLogin/login')
            }
          }).catch(e => {
            console.log('%c 🥦 e: ', 'font-size:20px;background-color: #B03734;color:#fff;', e);
          }).finally(
            this.resetButton
          )
        } else {
          resetButton()
          return false;
        }
      });
    },
    resetButton() {
      setTimeout(() => {
        this.buttonState.loginBtn = false
      }, 600)
    }
  }
}
</script>
<style lang="less" scoped>
#registerContainer {
  border-radius: 5px;
  background: #ffffff;
  padding: 40px 0;
  #firstLine {
    font-size: 25px;
    font-weight: 1000;
  }
  #formWrapper {
    margin-top: 20px;
    #verification .ant-form-item {
      margin-bottom: 0;
    }
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 12px;
  }
  .icon {
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>