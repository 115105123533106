var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "white" }, attrs: { id: "rangecode" } },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.form, layout: "vertical" } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "border-right": "1px solid rgb(232, 232, 232)"
                  },
                  attrs: { span: 6 }
                },
                [_c("field", { attrs: { form: _vm.form } })],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "margin-left": "20px", "margin-top": "20px" },
                  attrs: { span: 16 }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "新变量名称",
                        prop: "name",
                        rules: {
                          required: true,
                          message: "必填"
                        }
                      }
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label:
                          "是否为定序(当为定序时,编码值默认按照1,2,3...的顺序编号，也可以自定义)",
                        rules: {
                          required: true,
                          message: "必填"
                        }
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          attrs: {
                            "default-value": false,
                            "button-style": "solid"
                          },
                          on: {
                            change: function($event) {
                              return _vm.radioChange()
                            }
                          },
                          model: {
                            value: _vm.form.isXu,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isXu", $$v)
                            },
                            expression: "form.isXu"
                          }
                        },
                        [
                          _c("a-radio-button", { attrs: { value: true } }, [
                            _vm._v(" 是 ")
                          ]),
                          _c("a-radio-button", { attrs: { value: false } }, [
                            _vm._v(" 否 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.form.range, function(item, index) {
                    return _c(
                      "a-form-model-item",
                      {
                        key: index,
                        attrs: {
                          label: index === 0 ? "范围" : "",
                          rules: {
                            required: true,
                            message: "必填"
                          }
                        }
                      },
                      [
                        _c(
                          "a-input-group",
                          { attrs: { compact: "" } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  prop: "range." + index + ".min",
                                  rules: {
                                    required: true,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-input-number", {
                                  staticStyle: { width: "100px" },
                                  attrs: { placeholder: "最小值" },
                                  model: {
                                    value: item.min,
                                    callback: function($$v) {
                                      _vm.$set(item, "min", $$v)
                                    },
                                    expression: "item.min"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  margin: "auto 10px"
                                }
                              },
                              [_vm._v("~")]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  prop: "range." + index + ".max",
                                  rules: {
                                    required: true,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-input-number", {
                                  staticStyle: { width: "100px" },
                                  attrs: { placeholder: "最大值" },
                                  model: {
                                    value: item.max,
                                    callback: function($$v) {
                                      _vm.$set(item, "max", $$v)
                                    },
                                    expression: "item.max"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  margin: "auto 10px"
                                }
                              },
                              [_vm._v("→")]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  prop: "range." + index + ".display",
                                  rules: {
                                    required: true,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { placeholder: "分类名称" },
                                  model: {
                                    value: item.display,
                                    callback: function($$v) {
                                      _vm.$set(item, "display", $$v)
                                    },
                                    expression: "item.display"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.form.isXu === true
                              ? _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop: "range." + index + ".code",
                                      rules: {
                                        required: true,
                                        message: "必填"
                                      }
                                    }
                                  },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: { width: "100px" },
                                      attrs: { placeholder: "定序编码值" },
                                      model: {
                                        value: item.code,
                                        callback: function($$v) {
                                          _vm.$set(item, "code", $$v)
                                        },
                                        expression: "item.code"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  margin: "auto 10px"
                                }
                              },
                              [
                                _c("a-icon", {
                                  staticClass: "dynamic-delete-button",
                                  attrs: { type: "plus-circle" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addDomain(item)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.form.range && _vm.form.range.length > 1
                              ? _c("a-icon", {
                                  staticClass: "dynamic-delete-button",
                                  staticStyle: { "padding-left": "10px" },
                                  attrs: { type: "minus-circle" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDomain(item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "a-form-model-item",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", "html-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("dynamicValidateForm")
                            }
                          }
                        },
                        [_vm._v(" 确定 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function($event) {
                              return _vm.gotoBack()
                            }
                          }
                        },
                        [_vm._v(" 取消 ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }