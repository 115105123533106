var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "30px 0"
      }
    },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { staticStyle: { padding: "0 30px" }, attrs: { span: 24 } },
                  [
                    _vm._l(_vm.res.tables, function(table, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("my-table2", {
                            attrs: {
                              showHeader: table.showHeader,
                              data: table.data,
                              columns: table.columns,
                              title: table.title,
                              size: table.size
                            }
                          }),
                          _c("div", {
                            staticStyle: {
                              height: "5px",
                              border: "1px solid rgb(232, 232, 232)"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c("my-table2", {
                      attrs: {
                        showHeader: false,
                        data: _vm.table3.data,
                        columns: _vm.table3.columns,
                        size: "middle"
                      }
                    }),
                    _c("my-table2", {
                      attrs: {
                        showHeader: false,
                        data: _vm.table4.data,
                        columns: _vm.table4.columns,
                        size: "middle"
                      }
                    }),
                    _c("my-table2", {
                      attrs: {
                        data: _vm.tableRoc.data,
                        columns: _vm.tableRoc.columns,
                        size: "middle"
                      }
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { type: "flex", justify: "center" } },
              [_c("a-col", [_c("img", { attrs: { src: _vm.rocImg } })])],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }