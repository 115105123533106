var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        confirmLoading: _vm.loading
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { form: _vm.form } },
              "a-form",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { hidden: "" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["deviceID"],
                        expression: "['deviceID']"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { hidden: "" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["id"],
                        expression: "['id']"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "校验日期" } },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "date",
                          {
                            rules: [
                              { required: true, message: "请选择校验时间" }
                            ]
                          }
                        ],
                        expression:
                          "['date', { rules: [{ required: true, message: '请选择校验时间' }] }]"
                      }
                    ],
                    attrs: {
                      format: "YYYY-MM-DD HH:mm:ss",
                      placeholder: "请选择校验时间"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "校验结果" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "result",
                            {
                              rules: [
                                { required: true, message: "请填写设备名称" }
                              ]
                            }
                          ],
                          expression:
                            "['result',{rules:[{required:true,message:'请填写设备名称'}]}]"
                        }
                      ]
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("通过")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("不通过")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }