<template>
  <div class="result-container">
    <div class="imgAndTable">
      <h1>相关性检验结果</h1>
      <a-row type="flex" justify="center">
        <a-col>
          <MyImg :imgBytes="img" :fileName="`Phi相关系数`"></MyImg>
        </a-col>
      </a-row>
      <a-row type="flex">
        <a-col :span="24">
          <a-table :columns="table.columns" :dataSource="table.data" :pagination="false"></a-table>
        </a-col>
      </a-row>
    </div>
    <!-- <div class="conclusion">
      <h1>相关性检验智能分析</h1>
    </div> -->
  </div>
</template>
<script>
import { MakeReq } from '@/views/hypothesisTest/utils/MakeReqClass'
import { FetchResult } from '@/views/hypothesisTest/utils/fetchResultClass'
import MyTable2 from '../../table'
export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      table: {
        columns: [],
        data: []
      },
      img: ''
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.hypothesis.dybtjy
      const method = this.$store.state.hypothesis.method
      const req = new MakeReq(form, method, this).Phi()
      console.log('%c 🍺 req: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', req);
      const fetchResult = new FetchResult(this, req, 'hypothesis')
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      const { table } = this
      const { columns, tabdata, imgs } = res
      table.columns = columns
      table.data = JSON.parse(tabdata)
      this.img = imgs[0]
      return res
    }
  }
}
</script>