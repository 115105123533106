<template>
  <span>
    <a-icon @click="showModal" type="setting" />
    <a-modal v-model="visible" title="智能报告配置" @ok="handleOk" style="height: auto">
      <template slot="footer">
        <a-row>
          <a-col :span="12" style="text-align: left">
            <a-button type="primary" @click="handleReset">恢复默认</a-button>
          </a-col>
          <a-col :span="12" style="text-align: right">
            <a-button key="back" @click="handleCancel"> 取消 </a-button>
            <a-button key="submit" type="primary" @click="handleOk"> 确定 </a-button>
          </a-col>
        </a-row>
      </template>
      <a-form-model-item label="显著水平α" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-radio-group v-model="a">
          <a-radio :value="0.01"> 1% </a-radio>
          <a-radio :value="0.05"> 5% </a-radio>
          <a-radio :value="0.1"> 10% </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-tabs v-if="type_name_list.length > 0" :default-active-key="tab_default_active_key" tab-position="top">
        <template v-for="(type, type_index) in settings">
          <a-tab-pane v-if="tabIsShow(type.value)" :key="type_index" :tab="type.name">
            <a-card
              :class="{ noBottomBorder: type.options.methods.length > 0 }"
              v-if="'flows' in type.options"
              size="small"
              title="方法"
            >
              <a-form-model-item v-for="(flow, flow_index) in type.options.flows" :key="flow_index" :label="flow.label">
                <a-radio-group v-if="flow.type === 'radio'" v-model="flow.value">
                  <a-radio v-for="(option, option_index) in flow.options" :key="option_index" :value="option.value">
                    {{ option.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-card>
            <a-card
              size="small"
              :class="{ noTopBorder: method_index !== 0 }"
              v-for="(method, method_index) in type.options.methods"
              :key="method_index"
              :title="method.name"
            >
              <a-form-model-item v-for="(arg, arg_index) in method.args" :key="arg_index" :label="arg.name">
                <a-radio-group v-if="arg.type === 'radio'" v-model="arg.value">
                  <a-radio v-for="(option, option_index) in arg.options" :key="option_index" :value="option.value">
                    {{ option.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-card>
          </a-tab-pane>
        </template>
      </a-tabs>
      <div style="color: red" v-else>当前无可配置的研究方向，请向智能分析中拖入可研究的变量组。</div>
    </a-modal>
  </span>
</template>
<script>
import { settings } from '../utils/settings'
import { getSettingsByID, updateSettings } from '@/api/report'
export default {
  name: 'Settings',
  props: ['parentType'],
  data() {
    return {
      visible: false,
      settings: []
    };
  },
  created() {
    console.log(JSON.stringify(settings))
    this.getUserSettings()
  },
  methods: {
    getUserSettings() {
      const userID = this.$store.state.user.info.id
      getSettingsByID(userID).then(res => {
        this.userSettings = res
        this.settings = JSON.parse(res.settings)
        this.$store.dispatch('set_a', res.a)
      })
    },
    showModal() {
      this.visible = true
    },
    handleOk(e) {
      this.userSettings.settings = JSON.stringify(this.settings)
      this.userSettings.a = this.a
      updateSettings(this.userSettings).then(res => {
        this.$message.success('配置保存成功', 2)
      })
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    handleReset() {
      this.userSettings.settings = JSON.stringify(settings)
      this.userSettings.a = 0.05
      updateSettings(this.userSettings).then(res => {
        this.getUserSettings()
        this.$message.success('已恢复成默认配置', 2)
      })
    },
    // 用来判断哪个tab是需要显示在页面中的
    tabIsShow(value) {
      // 如果当前tab的value值在页面的研究方向中，则返回true
      return value.split(',').some(v => this.type_name_list.indexOf(v) !== -1)
    }
  },
  computed: {
    // 返回页面上所有研究方向名字的数组
    type_name_list() {
      let array = []
      this.parentType.forEach(list => {
        array = array.concat(list)
      })
      return [...new Set(array)]
    },
    // 取到tab的默认tab的key
    tab_default_active_key() {
      return this.settings.findIndex(v => v.value.indexOf(this.type_name_list[0]) !== -1)
    },
    a: {
      get() {
        return this.$store.state.report.a
      },
      set(value) {
        this.$store.dispatch('set_a', value)
      }
    }
  }
}
</script>
<style>
.noTopBorder {
  border-top: 0;
}
.noBottomBorder {
  border-bottom: 0;
}
</style>