var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-result", {
    attrs: {
      isSuccess: true,
      content: false,
      title: _vm.email,
      "sub-title": _vm.description
    },
    scopedSlots: _vm._u([
      {
        key: "extra",
        fn: function() {
          return [
            _c("a-button", { attrs: { size: "large", type: "primary" } }, [
              _vm._v("查看邮箱")
            ]),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "8px" },
                attrs: { size: "large" },
                on: { click: _vm.goHomeHandle }
              },
              [_vm._v("返回首页")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }