var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticStyle: { "border-right": "1px solid rgb(232, 232, 232)" } },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  {
                    staticStyle: {
                      padding: "30px",
                      "min-height": "750px",
                      "border-right": "1px solid rgb(232, 232, 232)"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "a-row",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("a-col", { attrs: { span: 24 } }, [
                                  _c("h1", [_vm._v("描述性分析结果")])
                                ])
                              ],
                              1
                            ),
                            _c(
                              "a-tabs",
                              {
                                attrs: { type: "editable-card", "hide-add": "" }
                              },
                              _vm._l(_vm.part2.tables, function(
                                value,
                                key,
                                index
                              ) {
                                return _c(
                                  "a-tab-pane",
                                  {
                                    key: index,
                                    staticStyle: { "padding-bottom": "1px" },
                                    attrs: { tab: key, closable: false }
                                  },
                                  [
                                    _c("myTable", {
                                      attrs: {
                                        data: value.data,
                                        columns: value.columns,
                                        title: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c("myTable", {
                              attrs: {
                                data: _vm.data,
                                columns: _vm.columns,
                                title: "主体间效应检验"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { staticStyle: { padding: "30px" }, attrs: { span: 24 } },
                  [
                    _c(
                      "box-shadow-card",
                      { attrs: { title: "双因素方差智能分析结果" } },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.larger("0", "" + _vm.lei1))
                          }
                        }),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.larger("1", "" + _vm.lei2))
                          }
                        }),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.larger("2", _vm.lei1 + ":" + _vm.lei2)
                            )
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }