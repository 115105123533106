<template>
  <div class="antv-chart-mini">
    <div class="chart-wrapper" :style="{ height: 46 }">
      <v-chart :force-fit="true" :height="height" :data="mbdata" :padding="[36, 5, 18, 5]">
        <v-tooltip />
        <v-bar position="x*y" :color="color"/>
      </v-chart>
    </div>
  </div>
</template>

<script>

const data = []

const tooltip = [
  'x*y',
  (x, y) => ({
    name: x,
    value: y
  })
]
const scale = [{
  dataKey: 'x',
  min: 2
}, {
  dataKey: 'y',
  min: 1,
  max: 30
}
]

export default {
  name: 'MiniBar',
  props: {
    mbdata: {
      type: Array,
      default: () => []
    },
     color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      data,
      tooltip,
      scale,
      height: 100
    }
  }
}
</script>

<style lang="less" scoped>
@import 'chart';
</style>
