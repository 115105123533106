import request from '@/utils/request'

const api = 'ReportShare'

export function getReportShareList(parameter) {
  return request({
    url: `${api}/List`,
    method: 'post',
    data: parameter
  })
}

export function getReportShare(parameter) {
  return request({
    url: `${api}/${parameter}`,
    method: 'get'
  })
}
// export function getDocument(parameter) {
//   return request({
//     url: api.get + '/' + parameter,
//     method: 'get'
//   })
// }

export function postReportShare(parameter) {
  return request({
    url: api,
    method: 'post',
    data: parameter
  })
}

// export function updateDocument(parameter) {
//   return request({
//     url: api.put + '/' + parameter.id,
//     method: 'put',
//     data: parameter
//   })
// }
// export function updateNameOrMobile(parameter) {
//   return request({
//     url: api.put + '/updateNameOrMobile/' + parameter.id,
//     method: 'put',
//     data: parameter
//   })
// }

// export function getDocByNum(parameter) {
//   return request({
//     url: api.post + '/getDocByName',
//     method: 'post',
//     data: parameter
//   })
// }