import request from '@/utils/request'

const api = '/Experiment2'

export function getExperiments (parameter) {
    return request({
      url: `${api}/List`,
      method: 'post',
      data: parameter
    })
  }

export function postExperiment2 (parameter) {
  return request({
    url: api,
    method: 'post',
    data: parameter
  })
}

export function getByID (parameter) {
  return request({
    url: `${api}/${parameter}`,
    method: 'get'
  })
}

export function deleteExp (parameter) {
  return request({
    url: api + '/' + parameter,
    method: 'delete'
  })
}

export function putAnimalAndData (parameter) {
  return request({
    url: api,
    method: 'put',
    data: parameter
  })
}