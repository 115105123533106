<template>
  <div>
    <div class="divLoading" v-if="isload">
      <a-spin :tip="tip" class="spin-content" size="large" />
    </div>
    <div fixed="top">
      <a-button type="primary" @click="goRules">筛选样本</a-button>
      <a-checkbox v-model="perviewAll" style="margin-left: 20px" v-if="sxFields !== null" @change="handelPerviewAll">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>默认不显示挖掘的数据，不影响后面的分析，预览全部加载时间会比较长！</span>
          </template>
          预览全部
        </a-tooltip>
      </a-checkbox>
      <span style="margin-left: 20px"
      >样本总数: <span style="color: #1890ff">{{ totalCount }}</span></span
      >
      <span style="margin-left: 20px"
      >选择样本量: <span style="color: #1890ff">{{ selectedRowKeys.length }}</span></span
      >
      <span v-if="selectString" style="margin-left: 20px"
      >条件: <span style="color: #1890ff">{{ selectString }}</span></span
      >
      <span class="table-page-search-submitButtons" style="float: right">
        <a-button
          style="margin-right: 10px"
          v-if="sxFields === null"
          @click="goExcel"
          :disabled="selectedRowKeys.length > 0 ? false : true"
        >导出</a-button
        >
        <a-button type="primary" @click="goAnysis()" :disabled="selectedRowKeys.length > 0 ? false : true"
        >进入分析</a-button
        >
      </span>

      <a
        v-if="
          fields.filter((r) => r.fieldType === '数字' || r.fieldType === '日期').length > 0 &&
            !(role === 'admin' && type === 'questionnaire')
        "
        style="float: right; text-decoration: underline; margin-right: 20px; text-align: center; line-height: 32px"
        @click="dataHandle"
      >数据处理</a
      >
      <a
        v-if="missDocuments.length > 0"
        @click="handleSave"
        title="将缺失值处理后的数据另存到文件中，以供永久使用"
        style="float: right; text-decoration: underline; margin-right: 20px; text-align: center; line-height: 32px"
      >另存为</a
      >
      <a
        v-if="sxFields === null"
        style="float: right; text-decoration: underline; margin-right: 20px; text-align: center; line-height: 32px"
        @click="handleMiss"
      >缺失值处理</a
      >
      <a
        v-if="sxFields === null"
        style="float: right; text-decoration: underline; margin-right: 20px; text-align: center; line-height: 32px"
        @click="handleMissStatistics"
      >缺失值统计</a
      >
    </div>
    <s-table
      style="margin-top: 10px"
      bordered
      ref="table"
      size="small"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      class="tableStyle"
      :scroll="{ x: true }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        fixed: true,
        onSelectAll: onSelectAllChange,
      }"
    >
      <!-- 计算属性的插槽 -->
      <span slot="Number" slot-scope="text">
        <span>{{ text && Number(text) !== NaN ? Math.round(text * 1000) / 1000 : '' }}</span>
      </span>
      <span slot="xuhao" slot-scope="text, record, index">
        <span>{{ page.pageSize * (page.pageNo - 1) + index + 1 }}</span>
      </span>
      <span slot="popover" slot-scope="text">
        <a-popover v-if="text && text.constructor === Array">
          <template slot="content" v-if="text && text.constructor === Array">
            <div v-for="(a, j) in text" :key="j">
              {{ a.time + ':' + a.value }}
            </div>
          </template>
          <span v-if="text && text.constructor === Array"> {{ text[0].time + '：' + text[0].value }}... </span>
          <!-- <span v-if="isJson(text)">{{ JSON.parse(text)[0].constructor===Object?( Object.keys(JSON.parse(text)[0])[0]+'：'+JSON.parse(text)[0][Object.keys(JSON.parse(text)[0])[0]]):JSON.parse(text)[0] }}...</span> -->
        </a-popover>

        <span v-if="text && text.constructor !== Array && !isJson(text)">{{ text }}</span>
        <span v-if="text && isJson(text)">{{ JSON.parse(text).time + '：' + JSON.parse(text).value }}</span>
      </span>
    </s-table>
    <data-rule
      ref="DataRules"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :ruleFields="ruleFields"
      :model="selectModel"
      :errString="errString"
      :errors="errors"
      @cancel="handleCancel"
      @ok="handleOk"
    ></data-rule>
    <missing-value
      ref="missvalue"
      :visible="missvisible"
      @cancel="handleMissCancel"
      @ok="handleMissOk"
      :loading="missLoading"
    ></missing-value>
    <save-data
      ref="savedata"
      :visible="savevisible"
      @cancel="handleSaveCancel"
      @ok="handleSaveOk"
      :form="saveform"
      :loading="saveLoading"
    ></save-data>
  </div>
</template>
<script>
import { STable } from '@/components'
import {
  GetData,
  GetQuestionData,
  GetExperimentData,
  GetExperimentData2,
  GetFileData,
  GetNullData
} from '@/api/screendata'
import DataRule from './components/DataRule'
import MissingValue from './components/MissingValue'
import { dataHandleSkip } from '@/utils/datasource'
import ExportJsonExcel from 'js-export-excel'
import { getChart } from '@/api/hypothesis'
import { Add } from '@/api/file'
import SaveData from './components/SaveData'
export default {
  // 加载组件
  components: {
    STable,
    DataRule,
    MissingValue,
    SaveData
  },
  data() {
    return {
      tip: '数据加载中，请稍候',
      // 新建/编辑modal是否显示
      visible: false,
      perviewAll: false,
      missvisible: false,
      missLoading: false,
      savevisible: false,
      saveLoading: false,
      // 加载...
      confirmLoading: false,
      // 查询参数
      queryParam: {},
      columns: [],
      ruleFields: [],
      totalCount: 0,
      missDocuments: [], // 缺省值处理过的data
      fields: this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : [],
      isload: false,
      selectModel: {}, // 弹出框里的条件，从session中获取
      selectString: this.$store.state.samples.selectStr, // 筛选条件
      errString: '', // 如果有条件没有选择的话
      errors: {}, // 条件筛选页面的错误
      page: {},
      saveform: {}, // 选择的文件名，量表名，实验名
      role: this.$store.state.user.roles,
      type: this.$store.state.samples.type,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.page = parameter
        // 查询session中的查询条件
        const selectparms = this.$store.state.samples.selectparms && JSON.parse(this.$store.state.samples.selectparms)
        // 如果session中有条件则加到queryParam中去
        if (selectparms) {
          this.queryParam['selectparms'] = selectparms
        }
        const selectFields =
          this.$store.state.samples.selectFields && JSON.parse(this.$store.state.samples.selectFields)
        // 如果session中有条件则加到queryParam中去
        if (selectFields) {
          this.queryParam['selectFields'] = selectFields
        }
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        const fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
        this.columns = [
          { title: '编号', scopedSlots: { customRender: 'xuhao' }, align: 'center', fixed: 'left', width: 100 }
        ]
        this.ruleFields = []
        const type = this.$store.state.samples.type
        const source = this.$store.state.samples.source
        fields.map((r, i) => {
          var column = {
            title: r.fieldName,
            dataIndex: r.fieldName,
            align: 'left'
          }
          // if (i === 0 && fields.length > 1) {
          //   column.fixed = 'left'
          //   column.width = 100
          // }
          if (r.fieldType === '数字' && r.ptype !== '时序') {
            column.scopedSlots = { customRender: 'Number' }
          } else if (r.ptype === '时序') {
            column.scopedSlots = { customRender: 'popover' }
          }
          this.columns.push(column)
          // 条件筛选时的配置
          const dayu = []
          if (r.option === '[]' || !r.option) {
            dayu.push({ value: '=', text: '=' })
            dayu.push({ value: '>', text: '>' })
            dayu.push({ value: '<', text: '<' })
            dayu.push({ value: '>=', text: '≥' })
            dayu.push({ value: '<=', text: '≤' })
            dayu.push({ value: '<>', text: '≠' })
          } else {
            dayu.push({ value: '=', text: '=' })
            dayu.push({ value: '<>', text: '≠' })
          }

          if (type === 'document' || type === 'experiment' || (type === 'file' && source !== 'questionnaire')) {
            var option = r.option && r.option.split(',')
            if (option && option.length > 0) {
              option.push('(空白)')
            }
            this.ruleFields.push({
              ...r,
              option: option,
              dayu: dayu
            })
          } else {
            option = []
            if (r.option) {
              JSON.parse(r.option).map(o => {
                option.push(o.Text)
              })
              if (option && option.length > 0) {
                option.push('(空白)')
              }
            }
            this.ruleFields.push({
              ...r,
              option: option,
              dayu: dayu
            })
          }
        })
        console.log('ruleField', this.ruleFields)
        if (this.missDocuments.length > 0) {
          return GetNullData().then(() => {
            var res = {}
            res.data = this.missDocuments
            res.totalCount = this.missDocuments.length

            res.totalPage = Math.ceil(this.missDocuments.length / res.pageSize)
            res.pageNo = parameter.pageNo > res.totalPage ? 1 : parameter.pageNo
            res.pageSize = parameter.pageSize
            this.totalCount = res.totalCount
            return res
          })
        } else {
          if (type === 'document') {
            return GetData({ ...requestParameters, fields: fields, orgid: this.$store.state.user.info.orgid }).then(
              res => {
                // 如果缺省值处理的数据有值，说明进行过缺省值处理了，需要用处理后的数据
                res.data = res && JSON.parse(res.data[0])
                console.log('dd', res.ids)

                var selectIDs = this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys)
                if (selectIDs && this.$store.state.samples.type === 'document') {
                  this.selectedRowKeys = selectIDs
                } else {
                  this.selectedRowKeys = res.ids
                }
                this.selectKeyAll = res.ids // 所有的ID集合，用于全选或者全不选

                this.$store.dispatch('updateSamples', { source: '' })
                this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
                this.totalCount = res.totalCount
                this.sxFields = null
                return res
              }
            )
          } else if (type === 'questionnaire') {
            return GetQuestionData({
              ...requestParameters,
              fields: fields,
              questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null,
              orgid: this.$store.state.user.info.orgid,
              userid: this.$store.state.user.info.id,
              perviewAll: this.perviewAll
            }).then(res => {
              // 如果缺省值处理的数据有值，说明进行过缺省值处理了，需要用处理后的数据

              res.data = res && JSON.parse(res.data[0])
              this.totalCount = res.totalCount

              console.log('model', res.ids)
              var selectIDs = this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys)
              if (selectIDs && this.$store.state.samples.type === 'questionnaire') {
                this.selectedRowKeys = selectIDs
              } else {
                this.selectedRowKeys = res.ids ? res.ids : res.strids
              }
              this.selectKeyAll = res.ids ? res.ids : res.strids // 所有的ID集合，用于全选或者全不选
              this.sxFields = res.sxField
              this.$store.dispatch('updateSamples', { source: '' })
              this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
              this.$store.dispatch('updateSamples', { sxFields: res.sxField })
              return res
            })
          } else if (type === 'file') {
            return GetFileData({
              ...requestParameters,
              fields: fields,
              questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null,
              orgid: this.$store.state.user.info.orgid
            }).then(res => {
              // 如果缺省值处理的数据有值，说明进行过缺省值处理了，需要用处理后的数据{
              res.data = res && JSON.parse(res.data[0])
              this.totalCount = res.totalCount

              console.log('model', res.ids)
              var selectIDs = this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys)
              if (selectIDs && this.$store.state.samples.type === 'file') {
                this.selectedRowKeys = selectIDs
              } else {
                this.selectedRowKeys = res.ids
              }
              this.selectKeyAll = res.ids // 所有的ID集合，用于全选或者全不选
              this.$store.dispatch('updateSamples', { source: res.source })
              this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
              this.$store.dispatch('updateSamples', { sxFields: res.sxField })
              this.sxFields = null
              return res
            })
          } else if (type === 'experiment') {
            var guid = this.$store.state.samples.experimentGuid
            var expid = this.$store.state.samples.experimentID
            if (guid === '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb') {
              return GetExperimentData({
                ...requestParameters,
                fields: fields,
                questionnaireID: expid ? expid.toString() : null,
                orgid: this.$store.state.user.info.orgid
              }).then(res => {
                // 如果缺省值处理的数据有值，说明进行过缺省值处理了，需要用处理后的数据
                res.data = res && JSON.parse(res.data[0])
                this.totalCount = res.totalCount

                console.log('model', res.strids)
                var selectIDs = this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys)
                if (selectIDs && this.$store.state.samples.type === 'experiment') {
                  this.selectedRowKeys = selectIDs
                } else {
                  this.selectedRowKeys = res.strids
                }
                this.selectKeyAll = res.strids // 所有的ID集合，用于全选或者全不选
                this.$store.dispatch('updateSamples', { source: '' })
                this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
                this.sxFields = null
                return res
              })
            } else if (guid === '61bd643e-a81a-439b-94d6-61dac11a1b66') {
              this.totalCount = 0
              // 实验2
              return GetExperimentData2({
                ...requestParameters,
                fields: fields,
                questionnaireID: expid ? expid.toString() : null,
                orgid: this.$store.state.user.info.orgid
              }).then(res => {
                res.data = res && JSON.parse(res.data[0])
                this.totalCount = res.totalCount

                console.log('model', res.strids)
                var selectIDs = this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys)
                if (selectIDs && this.$store.state.samples.type === 'experiment') {
                  this.selectedRowKeys = selectIDs
                } else {
                  this.selectedRowKeys = res.ids
                }
                this.selectKeyAll = res.ids // 所有的ID集合，用于全选或者全不选
                this.$store.dispatch('updateSamples', { source: '' })
                this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
                this.sxFields = null

                return res
              })
            }
          }
        }
      },
      selectedRowKeys: [],
      sxFields: null,
      selectKeyAll: [] // 所有的key值，用于全选和取消全选
    }
  },
  methods: {
    onSelectAllChange(e) {
      if (e === true) {
        this.selectedRowKeys = this.selectKeyAll
      } else if (e === false) {
        this.selectedRowKeys = []
      }
      this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
    },
    /**
     * 符号转换
     * @param {符号} symbols
     */
    replaceSymbols(symbols) {
      switch (symbols) {
        case '>=':
          return '≥'
        case '<=':
          return '≤'
        case '<>':
          return '≠'
        default:
          return symbols
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectrows', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
      this.$store.dispatch('updateSamples', { keys: JSON.stringify(this.selectedRowKeys) })
    },
    exportExcel(res) {
      // 导出
      const option = {}
      var columns = ['编号']
      columns.push(...res.headers)
      const date = new Date()
      option.fileName =
        'outputexcel' +
        date.getFullYear() +
        (date.getMonth() + 1) +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds()
      option.datas = [
        {
          sheetData: JSON.parse(res.data[0]).map((item, i) => {
            const result = {}
            columns.map(c => {
              if (c === '编号') {
                result[c] = i + 1
              } else {
                result[c] = item[c]
              }
            })
            return result
          }),
          sheetName: 'sheet1',
          sheetFilter: columns.map(c => c),
          sheetHeader: columns.map(c => c),
          columnWidths: columns.map(() => 10)
        }
      ]
      const toExcel = new ExportJsonExcel(option)
      toExcel.saveExcel()
      this.isload = false
    },
    getdata(fun) {
      const type = this.$store.state.samples.type
      const requestParameters = Object.assign({}, this.queryParam)
      const fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
      var vfield = []
      const form = this.$refs.missvalue.form
      if (form.fun === 'default' || form.fun === 'mean' || form.fun === 'median' || form.fun === 'knn') {
        vfield = fields.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
      } else {
        vfield = fields
      }
      if (type === 'document') {
        GetData({
          ...requestParameters,
          fields: fields,
          selectRows: this.selectedRowKeys,
          orgid: this.$store.state.user.info.orgid
        }).then(res => {
          if (fun === 'excel') {
            this.exportExcel(res)
          } else if (fun === 'miss') {
            this.$store.dispatch('updateSamples', { source: '' })
            var document = JSON.parse(res.data[0])
            this.getMissValue(document, vfield)
          }
        })
      } else if (type === 'questionnaire') {
        console.log(this.$route.params.id)
        if (this.$route.params.id) {
        }
        if (this.selectedRowKeys.filter(a => !isNaN(Number(a))).length === this.selectedRowKeys.length) {
          // 说明key值是long类型
          GetQuestionData({
            ...requestParameters,
            fields: fields,
            selectRows: this.selectedRowKeys,
            questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null,
            orgid: this.$store.state.user.info.orgid,
            userid: this.$store.state.user.info.id
          }).then(res => {
            if (fun === 'excel') {
              this.exportExcel(res)
            } else if (fun === 'miss') {
              this.$store.dispatch('updateSamples', { source: '' })
              var document = JSON.parse(res.data[0])
              this.getMissValue(document, vfield)
            }
          })
        } else {
          GetQuestionData({
            ...requestParameters,
            fields: fields,
            selectRowsexp: this.selectedRowKeys,
            questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null,
            orgid: this.$store.state.user.info.orgid,
            userid: this.$store.state.user.info.id
          }).then(res => {
            if (fun === 'excel') {
              this.exportExcel(res)
            } else if (fun === 'miss') {
              this.$store.dispatch('updateSamples', { source: '' })
              var document = JSON.parse(res.data[0])
              this.getMissValue(document, vfield)
            }
          })
        }
      } else if (type === 'experiment') {
        var guid = this.$store.state.samples.experimentGuid
        var expid = this.$store.state.samples.experimentID
        if (guid === '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb') {
          GetExperimentData({
            ...requestParameters,
            fields: fields,
            selectRowsexp: this.selectedRowKeys,
            questionnaireID: expid,
            orgid: this.$store.state.user.info.orgid
          }).then(res => {
            if (fun === 'excel') {
              this.exportExcel(res)
            } else if (fun === 'miss') {
              this.$store.dispatch('updateSamples', { source: '' })
              var document = JSON.parse(res.data[0])
              this.getMissValue(document, vfield)
            }
          })
        } else if (guid === '61bd643e-a81a-439b-94d6-61dac11a1b66') {
          GetExperimentData2({
            ...requestParameters,
            fields: fields,
            selectRowsexp: this.selectedRowKeys,
            questionnaireID: expid,
            orgid: this.$store.state.user.info.orgid
          }).then(res => {
            if (fun === 'excel') {
              this.exportExcel(res)
            } else if (fun === 'miss') {
              this.$store.dispatch('updateSamples', { source: '' })
              var document = JSON.parse(res.data[0])
              this.getMissValue(document, vfield)
            }
          })
        }
      } else if (type === 'file') {
        GetFileData({
          ...requestParameters,
          fields: fields,
          selectRows: this.selectedRowKeys,
          questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null,
          orgid: this.$store.state.user.info.orgid
        }).then(res => {
          if (fun === 'excel') {
            this.exportExcel(res)
          } else if (fun === 'miss') {
            this.$store.dispatch('updateSamples', { source: res.source })
            var document = JSON.parse(res.data[0])
            this.getMissValue(document, vfield)
          }
        })
      }
    },
    handelPerviewAll() {
      // 刷新表格
      this.$refs.table.refresh()
    },
    getMissValue(document, fields) {
      const form = this.$refs.missvalue.form
      var vField = []
      if (fields.length > 0) {
        if (fields[0] instanceof Object) {
          vField = fields.map(r => {
            return r.fieldName
          })
        } else {
          vField = fields
        }
      }
      document.map(r => {
        fields.map(f => {
          if (!r[f.fieldName] && r[f.fieldName] !== '0') {
            r[f.fieldName] = NaN
          }
          if (f.fieldType === '数字') {
            r[f.fieldName] = Number(r[f.fieldName])
          }
        })
      })

      var data = {
        fields: form.fields.length === 0 ? vField : form.fields,
        default: form.default,
        type: form.fun,
        data: JSON.stringify(document)
      }
      // 组合api参数
      var req = {
        method: 'handle',
        args: data
      }
      getChart(req).then(res => {
        console.log('result', res)
        this.missDocuments = res
        this.missvisible = false
        this.$store.dispatch('updateSamples', { documents: JSON.stringify(res) })
        this.missLoading = false
        this.$refs.table.refresh()
      })
    },
    goExcel() {
      this.tip = '正在导出，请稍候'
      this.isload = true
      this.getdata('excel')
    },
    goAnysis() {
      const type = this.$store.state.samples.type
      if (type === 'questionnaire') {
        this.$store.dispatch('updateSamples', {
          questionnaireID: this.$route.params.id ? this.$route.params.id.toString() : null
        })
      } else if (type === 'experiment') {
        var val = this.$route.params.id.split('_')
        this.$store.dispatch('updateSamples', { experimentID: val[0] })
        this.$store.dispatch('updateSamples', { experimentGuid: val[1] })
      }
      const pathname = window.location.pathname
      if (pathname.indexOf('hypothesistest') !== -1) {
        this.$router.push('/hypothesistest/hypothesis/index')
      } else if (pathname.indexOf('analysisOfRegression') !== -1) {
        this.$router.push('/analysisOfRegression/analysisOfRegression/index')
      } else if (pathname.indexOf('intelligenceReports') !== -1) {
        this.$router.push('/intelligenceReports')
      } else {
        this.$router.push('/customchart/chart')
      }
    },
    /**
     * 筛选条件
     */
    goRules() {
      // session中弹出框的查询条件
      const selectValues = this.$store.state.samples.selectValues && JSON.parse(this.$store.state.samples.selectValues)
      if (selectValues) {
        this.selectModel = selectValues
      }
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.selectModel = {}
      const form = this.$refs.DataRules.form
      form.resetFields()
      // const keys = form.getFieldValue('keys')
      // keys.map(r => {
      //   form.setFieldsValue({ [r + '_keys']: undefined })
      // })
      // form.setFieldsValue({ keys: undefined })
    },
    handleOk() {
      const form = this.$refs.DataRules.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          this.errString = ''
          // 变量的集合
          const parms = []
          var parmStr = '' // 条件说明
          var selectFields = [] // 查询用到的几个字段
          // 变量循环
          values.keys.map((r, i) => {
            const child = {}
            // 查看一个变量有几个子条件
            const childrenkey = r + '_keys' // 子条件的key
            const relationkey = r + '_relation' // 子条件的关系key
            child['relation'] = values[relationkey]
            child['parms'] = []
            parms.push(child)
            // 拼条件说明
            if (i > 0) {
              parmStr += ' 且 '
            }
            if (values[childrenkey].length > 1 && values.keys.length > 1) {
              parmStr += '('
            }
            // 子条件循环
            values[childrenkey].map((m, j) => {
              const field = r + '_field'
              const item = r + '_item'
              const dayu = r + '_' + m + '_dayu'
              const value0 = r + '_' + m + '_value_0'
              const value1 = r + '_' + m + '_value_1'
              selectFields.push({
                fieldID: values[item].fieldID,
                fieldName: values[item].fieldName,
                fieldType: values[item].fieldType,
                fieldcheckbox: values[item].fieldcheckbox,
                type: values[item].type
              })
              // 拼条件说明
              if (j > 0) {
                parmStr += values[relationkey] === 'and' ? ' 且 ' : ' 或 '
              }
              if (values[value0]) {
                // 当子条件有2个及以上，并且变量有2个及以上
                if (values[value0].length > 1 && values.keys.length > 1) {
                  parmStr += '('
                }
                values[value0].map((n, k) => {
                  if (k > 0) {
                    parmStr += values[dayu] === '<>' ? ' 且 ' : ' 或 '
                  }
                  parmStr += values[field]
                  parmStr += this.replaceSymbols(values[dayu])
                  parmStr += n
                })
                if (values[value0].length > 1 && values.keys.length > 1) {
                  parmStr += ')'
                }
              } else {
                parmStr += values[field]
                parmStr += this.replaceSymbols(values[dayu])
                parmStr += values[value1]
              }

              if (values[dayu] === '<>') {
                // 定类
                if (values[value0]) {
                  var parmsC = '' // 定类多选时
                  if (values[value0].length > 1) {
                    parmsC += '('
                  }
                  var nullselect = false // 是否选择了空白
                  values[value0].map((n, k) => {
                    if (k > 0) {
                      parmsC += ' and '
                    }
                    if (n === '(空白)') {
                      parmsC += 'BB.[' + values[field] + '] is not null'
                      nullselect = true
                    } else {
                      parmsC += 'BB.[' + values[field] + '] ' + values[dayu] + `'${n}'`
                    }
                  })
                  if (values[value0].length > 1) {
                    parmsC += ')'
                  }
                  // 如果没有选中（空白）则需要吧为null的也显示出来
                  if (!nullselect) {
                    parmsC += ' or BB.[' + values[field] + '] is null'
                  }
                  child['parms'].push(nullselect ? parmsC : '(' + parmsC + ')')
                } else {
                  // 定量
                  child['parms'].push(
                    '(convert(float,BB.[' +
                    values[field] +
                    '])' +
                    values[dayu] +
                    `${values[value1]}` +
                    ' or BB.[' +
                    values[field] +
                    '] is null)'
                  )
                }
              } else {
                if (values[value0]) {
                  parmsC = ''
                  if (values[value0].length > 1) {
                    parmsC += '('
                  }
                  values[value0].map((n, k) => {
                    if (k > 0) {
                      parmsC += ' or '
                    }
                    if (n === '(空白)') {
                      parmsC += 'BB.[' + values[field] + '] is null'
                    } else {
                      parmsC += 'BB.[' + values[field] + '] ' + values[dayu] + `'${n}'`
                    }
                  })
                  if (values[value0].length > 1) {
                    parmsC += ')'
                  }
                  child['parms'].push(parmsC)
                } else {
                  child['parms'].push('convert(float,BB.[' + values[field] + '])' + values[dayu] + `${values[value1]}`)
                }
              }
            })
            if (values[childrenkey].length > 1 && values.keys.length > 1) {
              parmStr += ')'
            }
          })
          this.selectString = parmStr
          this.queryParam['selectparms'] = parms
          this.$store.dispatch('updateSamples', { selectFields: JSON.stringify(selectFields) })
          // 显示的查询条件
          this.$store.dispatch('updateSamples', { selectStr: parmStr })
          // 查询的条件api中需要的
          this.$store.dispatch('updateSamples', { selectparms: JSON.stringify(parms) })
          // 查询的条件 弹出框需要的
          this.$store.dispatch('updateSamples', { selectValues: JSON.stringify(values) })
          this.$store.dispatch('updateSamples', { documents: '' })
          this.$store.dispatch('updateSamples', { keys: '' })
          this.$store.dispatch('updateSamples', { outlier: 0 })
          this.missDocuments = []
          // 刷新表格
          this.$refs.table.refresh()
          this.visible = false
          this.confirmLoading = false
        } else {
          this.errors = errors
          // Object.keys(errors).map(r => {
          //   form.setFields({ [r]: errors[r] })
          // })
          // this.errString = '所有增加的条件请必填'
        }
      })
    },
    handleMiss() {
      this.missvisible = true
    },
    handleMissStatistics() {
      this.$router.push('/missingStatistics')
    },
    handleMissCancel() {
      this.missvisible = false
      this.missLoading = false
      this.selectModel = {}
    },
    handleMissOk() {
      const form = this.$refs.missvalue.form

      this.$refs.missvalue.$refs.missvalue.validate((valid, obj) => {
        if (valid) {
          const fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
          var vfield = []
          if (form.fun === 'default' || form.fun === 'mean' || form.fun === 'median' || form.fun === 'knn') {
            vfield = fields
              .filter(r => r.fieldType === '数字' || r.fieldType === '日期')
              .map(r => {
                return r.fieldName
              })
          } else {
            vfield = fields.map(r => {
              return r.fieldName
            })
          }
          if (form.fun === 'default' && !form.default) {
            form.err = '请填写固定值'
          } else {
            if (this.missDocuments.length > 0) {
              if (form.fields.length > 0) {
                this.getMissValue(this.missDocuments, form.fields)
              } else {
                this.getMissValue(this.missDocuments, vfield)
              }
            } else {
              form.err = ''
              this.missLoading = true
              this.getdata('miss')
            }
          }
        }
      })
    },
    /** 另存为 */
    handleSave() {
      this.saveLoading = false
      this.savevisible = true
      this.saveform = {
        fileName: this.$store.state.samples.fileName + '_缺失值处理数据'
      }
    },
    handleSaveCancel() {
      this.savevisible = false
      this.saveLoading = false
    },
    handleSaveOk() {
      const type = this.$store.state.samples.type
      const source = this.$store.state.samples.source
      this.saveLoading = true
      const form = this.$refs.savedata.form
      const fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
      var filetitle = []
      var fieldCodes = []

      const Control = { CreateDate: new Date(), Order: 0, DeleteFlag: 0 }
      fields.map((r, i) => {
        if (r.type === 'code') {
          var code = {
            dataType: 'file',
            fields: JSON.stringify(r.rangeField),
            range: JSON.stringify(r.range),
            isXu: r.forOrder,
            name: r.fieldName,
            Control
          }
          fieldCodes.push(code)
        } else {
          var option = []
          if (type === 'questionnaire' || source === 'questionnaire') {
            option = r.option && r.option !== '[]' ? r.option : null
          } else {
            option = r.option && r.option !== '[]' ? JSON.stringify(r.option.split(',')) : null
          }

          var title = {
            name: r.fieldName,
            index: i,
            fieldName: r.fieldName,
            option: option || null,
            fileType: this.getfieldType(r.fieldType),
            forOrder: r.forOrder,
            Control
          }
          filetitle.push(title)
        }
      })
      var parm = {
        orgid: this.$store.state.user.info.org.id,
        userid: this.$store.state.user.info.id,
        name: form.fileName,
        count: this.missDocuments.length,
        source: source || type,
        fileTitles: filetitle,
        fileData: { value: JSON.stringify(this.missDocuments) },
        fieldCodes
      }
      Add(parm).then(res => {
        this.saveLoading = false
        this.savevisible = false
      })
    },
    getfieldType(str) {
      var type = 0
      switch (str) {
        case '数字':
          type = 0
          break
        case '日期':
          type = 3
          break
        case '日期范围':
          type = 9
          break
        case '单选':
          type = 2
          break
        case '多选':
          type = 5
          break
        case '下拉框':
          type = 1
          break
        default:
          type = 0
      }
      return type
    },
    /**
     * 变量处理
     */
    dataHandle() {
      dataHandleSkip()
    },
    /**
     * 判断是否为json字符串
     */
    isJson(str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    }
  }
}
</script>
<style scoped>
.divLoading {
  position: fixed !important;
  background: #dadada;
  opacity: 0.85;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 100;
}
.spin-content {
  margin: 500px auto;
  display: block;
  z-index: 500;
  color: black;
}
body {
  overflow: auto !important;
}
</style>