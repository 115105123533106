<template>
  <div id="rangecode" style="background: white">
    <a-form-model ref="ruleForm" :model="form" v-bind="formLayout" layout="vertical">
      <a-row>
        <a-col :span="6" style="border-right: 1px solid rgb(232, 232, 232)">
          <fieldcheck :form="form"></fieldcheck>
        </a-col>
        <a-col :span="16" style="margin-left: 20px; margin-top: 20px">
          <!-- <a-form-model-item label="判断标准">
            <a-checkbox v-model="form.sameNum">相同数字</a-checkbox>&gt;&nbsp;<a-input-number
              v-model="form.sameNumP"
              style="width: 200px"
            ></a-input-number
            >&nbsp;%
          </a-form-model-item> -->
          <a-form-model-item label="判断标准">
            <a-checkbox v-model="form.sameNaN">缺失比例</a-checkbox>&gt;&nbsp;<a-input-number
              v-model="form.sameNaNP"
              style="width: 200px"
            ></a-input-number
            >&nbsp;%
          </a-form-model-item>
          <a-form-model-item label="无效样本处理">
            <a-radio-group v-model="form.handel">
              <a-row style="height: 45px important">
                <a-col :span="24"> <a-radio value="new">标识(新生成标题)</a-radio></a-col>
                <a-col :span="24"> <a-radio value="delete">删除无效样本</a-radio></a-col>
              </a-row>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-top: 40px">
            <a-button type="primary" html-type="submit" @click="submitForm('dynamicValidateForm')"> 确定 </a-button>
            <a-button style="margin-left: 10px" @click="gotoBack()"> 取消 </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import field from './components/field'
import fieldcheck from './components/fieldcheck'
import { AutoHeight } from '@/utils/util'
import { Add, Get } from '@/api/fieldInvalid'
export default {
  components: { field, fieldcheck },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }
    return {
      form: {
        selectKeys: [],
        sameNumP: 70,
        sameNaNP: 70,
        handel: 'new',
        handelName: 'Invalid_1表示有效0表示无效'
      }
    }
  },
  created() {
    this.autoHeightChange()
    console.log('create调用了呢')
    var parm = {}
    const pathname = window.location.pathname
    if (pathname.indexOf('document') > -1) {
      parm.dataType = 'document'
    } else if (pathname.indexOf('questionnaire') > -1) {
      var id = JSON.parse(this.$store.state.samples.questionnaireID)[0]
      parm.typeid = id
      parm.dataType = 'questionnaire'
    } else if (pathname.indexOf('file') > -1) {
      id = this.$store.state.samples.fileid
      parm.typeid = id
      parm.dataType = 'file'
    } else if (pathname.indexOf('experiment') > -1) {
      id = this.$store.state.samples.experimentID
      var guid = this.$store.state.samples.experimentGuid
      parm.expertmentGuid = guid
      parm.typeid = id
      parm.dataType = 'experiment'
    }
    Get(parm).then(res => {
      if (res) {
        this.form = { ...res }
        this.$set(
          this.form,
          'selectKeys',
          res.fields
            ? JSON.parse(res.fields).map(r => {
                return r.fieldName
              })
            : []
        )
        // this.form.selectKeys = res.fields ? JSON.parse(res.fields).map(r => { return r.fieldName }) : []
        this.form.handel = res.handel === 0 ? 'new' : 'delete'
        console.log(this.form)
      }
    })
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  methods: {
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = ['afield', 'rangecode']
      var min = ['afield', 'rangecode']
      AutoHeight(this, min, max, 215)
    },

    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var values = this.form
          var fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
          fields = fields.filter(r => values.selectKeys.filter(a => a === r.fieldName).length > 0)
          var parm = { ...values, name: values.handelName, fields: JSON.stringify(fields) }
          const pathname = window.location.pathname
          if (pathname.indexOf('document') > -1) {
            parm.dataType = 'document'
          } else if (pathname.indexOf('questionnaire') > -1) {
            var id = JSON.parse(this.$store.state.samples.questionnaireID)[0]
            parm.typeid = id
            parm.dataType = 'questionnaire'
          } else if (pathname.indexOf('file') > -1) {
            id = this.$store.state.samples.fileid
            parm.typeid = id
            parm.dataType = 'file'
          } else if (pathname.indexOf('experiment') > -1) {
            id = this.$store.state.samples.experimentID
            var guid = this.$store.state.samples.experimentGuid
            parm.expertmentGuid = guid
            parm.typeid = id
            parm.dataType = 'experiment'
          }
          Add(parm).then(res => {
            if (!res) {
              this.$message.error('该变量名称已存在')
            } else {
              this.$store.dispatch('changeField')

              this.$store.dispatch('updateSamples', { fields: '' })
              this.gotoBack()
            }
          })
        }
      })
    },

    gotoBack() {
      const pathname = window.location.pathname
      if (pathname.indexOf('questionnaire') > -1) {
        const pathname = window.location.pathname
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/questionnaire')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/questionnaire')
        } else {
          this.$router.push('/customchart/screendata/questionnaire')
        }
      } else if (pathname.indexOf('document') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/document')
        } else {
          this.$router.push('/customchart/screendata/document')
        }
      } else if (pathname.indexOf('experiment') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/experiment')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/experiment')
        } else {
          this.$router.push('/customchart/screendata/experiment')
        }
      } else if (pathname.indexOf('file') > -1) {
        const pathname = window.location.pathname
        console.log('%c 🥧 pathname: ', 'font-size:20px;background-color: #465975;color:#fff;', pathname)
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/file')
        } else if (pathname.indexOf('analysisOfRegression') !== -1) {
          this.$router.push('/analysisOfRegression/screendata/file')
        } else {
          this.$router.push('/customchart/screendata/file')
        }
      }
    }
  }
}
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: static !important;
  top: 4px;
  font-size: 24px;
  margin-left: 2px;
  color: #999;
  transition: all 0.3s;
}
.ant-form-item {
  margin: 0px !important;
}
</style>