<template>
  <div>
    <div v-for="(obj, index) in gp.xys" :key="index" :id="obj.title">

      <vvchart
        :data="obj.data"
        :xys="gp.xys[index]"
        :indexT="index + 1"
        :count="gp.xys.length"
        style="padding: 0 25px"
      ></vvchart>

    </div>
  </div>
</template>
<script>

import vvchart from './vvchart'
export default {
  components: { vvchart },
  props: ['gp', 'indexT']

}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
