import { render, staticRenderFns } from "./PriceCard.vue?vue&type=template&id=e662e008&scoped=true&"
import script from "./PriceCard.vue?vue&type=script&lang=js&"
export * from "./PriceCard.vue?vue&type=script&lang=js&"
import style0 from "./PriceCard.vue?vue&type=style&index=0&id=e662e008&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e662e008",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e662e008')) {
      api.createRecord('e662e008', component.options)
    } else {
      api.reload('e662e008', component.options)
    }
    module.hot.accept("./PriceCard.vue?vue&type=template&id=e662e008&scoped=true&", function () {
      api.rerender('e662e008', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/myComponents/PriceCard.vue"
export default component.exports