var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 0 && data.xys[1].length > 0
              ? _c("div", [
                  _vm._v(
                    _vm._s(data.xys[0][0].slice(-1)[0]) +
                      "与" +
                      _vm._s(data.xys[1][0].slice(-1)[0]) +
                      "的作用结果相等"
                  )
                ])
              : _vm._e()
          ])
        }
      },
      {
        key: "H1",
        fn: function(data) {
          return _c("div", {}, [
            data.xys[0].length > 0 && data.xys[1].length > 0
              ? _c("div", [
                  _vm._v(
                    _vm._s(data.xys[0][0].slice(-1)[0]) +
                      "与" +
                      _vm._s(data.xys[1][0].slice(-1)[0]) +
                      "的作用结果不相等"
                  )
                ])
              : _vm._e()
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }