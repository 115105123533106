var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isload
        ? _c(
            "div",
            { staticClass: "divLoading" },
            [
              _c("a-spin", {
                staticClass: "spin-content",
                attrs: { tip: _vm.tip, size: "large" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { fixed: "top" } },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.goRules } },
            [_vm._v("筛选样本")]
          ),
          _vm.sxFields !== null
            ? _c(
                "a-checkbox",
                {
                  staticStyle: { "margin-left": "20px" },
                  on: { change: _vm.handelPerviewAll },
                  model: {
                    value: _vm.perviewAll,
                    callback: function($$v) {
                      _vm.perviewAll = $$v
                    },
                    expression: "perviewAll"
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v(
                            "默认不显示挖掘的数据，不影响后面的分析，预览全部加载时间会比较长！"
                          )
                        ])
                      ]),
                      _vm._v(" 预览全部 ")
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticStyle: { "margin-left": "20px" } }, [
            _vm._v("样本总数: "),
            _c("span", { staticStyle: { color: "#1890ff" } }, [
              _vm._v(_vm._s(_vm.totalCount))
            ])
          ]),
          _c("span", { staticStyle: { "margin-left": "20px" } }, [
            _vm._v("选择样本量: "),
            _c("span", { staticStyle: { color: "#1890ff" } }, [
              _vm._v(_vm._s(_vm.selectedRowKeys.length))
            ])
          ]),
          _vm.selectString
            ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("条件: "),
                _c("span", { staticStyle: { color: "#1890ff" } }, [
                  _vm._v(_vm._s(_vm.selectString))
                ])
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "table-page-search-submitButtons",
              staticStyle: { float: "right" }
            },
            [
              _vm.sxFields === null
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        disabled: _vm.selectedRowKeys.length > 0 ? false : true
                      },
                      on: { click: _vm.goExcel }
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.selectedRowKeys.length > 0 ? false : true
                  },
                  on: {
                    click: function($event) {
                      return _vm.goAnysis()
                    }
                  }
                },
                [_vm._v("进入分析")]
              )
            ],
            1
          ),
          _vm.fields.filter(function(r) {
            return r.fieldType === "数字" || r.fieldType === "日期"
          }).length > 0 &&
          !(_vm.role === "admin" && _vm.type === "questionnaire")
            ? _c(
                "a",
                {
                  staticStyle: {
                    float: "right",
                    "text-decoration": "underline",
                    "margin-right": "20px",
                    "text-align": "center",
                    "line-height": "32px"
                  },
                  on: { click: _vm.dataHandle }
                },
                [_vm._v("数据处理")]
              )
            : _vm._e(),
          _vm.missDocuments.length > 0
            ? _c(
                "a",
                {
                  staticStyle: {
                    float: "right",
                    "text-decoration": "underline",
                    "margin-right": "20px",
                    "text-align": "center",
                    "line-height": "32px"
                  },
                  attrs: {
                    title: "将缺失值处理后的数据另存到文件中，以供永久使用"
                  },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("另存为")]
              )
            : _vm._e(),
          _vm.sxFields === null
            ? _c(
                "a",
                {
                  staticStyle: {
                    float: "right",
                    "text-decoration": "underline",
                    "margin-right": "20px",
                    "text-align": "center",
                    "line-height": "32px"
                  },
                  on: { click: _vm.handleMiss }
                },
                [_vm._v("缺失值处理")]
              )
            : _vm._e(),
          _vm.sxFields === null
            ? _c(
                "a",
                {
                  staticStyle: {
                    float: "right",
                    "text-decoration": "underline",
                    "margin-right": "20px",
                    "text-align": "center",
                    "line-height": "32px"
                  },
                  on: { click: _vm.handleMissStatistics }
                },
                [_vm._v("缺失值统计")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("s-table", {
        ref: "table",
        staticClass: "tableStyle",
        staticStyle: { "margin-top": "10px" },
        attrs: {
          bordered: "",
          size: "small",
          rowKey: "key",
          columns: _vm.columns,
          data: _vm.loadData,
          scroll: { x: true },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            fixed: true,
            onSelectAll: _vm.onSelectAllChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "Number",
            fn: function(text) {
              return _c("span", {}, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      text && Number(text) !== NaN
                        ? Math.round(text * 1000) / 1000
                        : ""
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "xuhao",
            fn: function(text, record, index) {
              return _c("span", {}, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.page.pageSize * (_vm.page.pageNo - 1) + index + 1
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "popover",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  text && text.constructor === Array
                    ? _c(
                        "a-popover",
                        [
                          text && text.constructor === Array
                            ? _c(
                                "template",
                                { slot: "content" },
                                _vm._l(text, function(a, j) {
                                  return _c("div", { key: j }, [
                                    _vm._v(
                                      " " + _vm._s(a.time + ":" + a.value) + " "
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          text && text.constructor === Array
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      text[0].time + "：" + text[0].value
                                    ) +
                                    "... "
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  text && text.constructor !== Array && !_vm.isJson(text)
                    ? _c("span", [_vm._v(_vm._s(text))])
                    : _vm._e(),
                  text && _vm.isJson(text)
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            JSON.parse(text).time +
                              "：" +
                              JSON.parse(text).value
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c("data-rule", {
        ref: "DataRules",
        attrs: {
          visible: _vm.visible,
          confirmLoading: _vm.confirmLoading,
          ruleFields: _vm.ruleFields,
          model: _vm.selectModel,
          errString: _vm.errString,
          errors: _vm.errors
        },
        on: { cancel: _vm.handleCancel, ok: _vm.handleOk }
      }),
      _c("missing-value", {
        ref: "missvalue",
        attrs: { visible: _vm.missvisible, loading: _vm.missLoading },
        on: { cancel: _vm.handleMissCancel, ok: _vm.handleMissOk }
      }),
      _c("save-data", {
        ref: "savedata",
        attrs: {
          visible: _vm.savevisible,
          form: _vm.saveform,
          loading: _vm.saveLoading
        },
        on: { cancel: _vm.handleSaveCancel, ok: _vm.handleSaveOk }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }