<template>
  <div>
    <a-row>
      <a-col :span="24" style="margin-bottom: 10px">
        <a-button type="primary" @click="goToNewExp"> 新建实验 </a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <s-table ref="table" size="default" rowKey="key" :columns="columns" :data="loadData" showPagination="auto">
          <template slot="type" slot-scope="text">
            {{ typeEnum[text + ''] }}
          </template>
          <template slot="expName" slot-scope="text, record">
            <a @click="goToDetail(record)">{{ text }}</a>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a>编辑</a><a-divider type="vertical" />
            <a-popconfirm :title="`确认${record.control.deleteFlag ===0?'停用':'启用'}?`" @confirm="() => onDelete(record.key)">
              <a href="javascript:;">{{ record.control.deleteFlag ===0?'停用':'启用' }}</a>
            </a-popconfirm>
          </template>
          <span slot="deleteFlag" slot-scope="text,record">
            <template>
              <a-badge :status="record.control.deleteFlag ===0?'success':'error'" :text="record.control.deleteFlag ===0?'启用':'停用'" />
            </template>
          </span>
        </s-table></a-col
      >
    </a-row>
  </div>
</template>
<script>
import { getExperiments, deleteExp } from '@/api/exp2/experiment'
import { STable } from '@/components'
export default {
  name: 'Exp2',
  components: {
    STable
  },
  data() {
    return {
      columns: [
        {
          title: '实验编号',
          key: 'serialNum',
          dataIndex: 'serialNum'
        },
        {
          title: '实验名称',
          key: 'expName',
          dataIndex: 'expName',
          scopedSlots: { customRender: 'expName' }
        },
        {
          title: '说明',
          key: 'explain',
          dataIndex: 'explain'
        },
        {
          title: '状态',
          dataIndex: 'control.deleteFlag',
          scopedSlots: { customRender: 'deleteFlag' },
          align: 'center'
        },
        {
          title: '操作',
          key: 'operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ exptypeID: Number(this.$route.params.id), orgID: this.$store.state.user.info.orgid }, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
        return getExperiments(requestParameters).then(res => {
          console.log('%c 🍚 res: ', 'font-size:20px;background-color: #FFDD4D;color:#fff;', res)
          return res
        })
      }
    }
  },
  methods: {
    goToNewExp() {
      this.$router.push(`/experiment/Exp2/add/${Number(this.$route.params.id)}`)
    },
    goToDetail(record) {
      this.$router.push(`/experiment/Exp2/detail/${record.id}`)
    },
    onDelete(key) {
      deleteExp(key).then(res => {
        console.log(res, '假删除成功')
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>