<template>
  <index :xysName="xysName" :xys="xys" :formAddKeyName="formAddKeyName" :pForm="form" :pSettings="settings"
         @click="onClick">
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 0">{{ param_name(data.xys) }}中频数观测值与频数预测值的比例没有差异。</div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 0">{{ param_name(data.xys) }}中频数观测值与频数预测值的比例有差异。</div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'fittingXys',
      xys: [[], []],
      formAddKeyName: 'fittingFormAddKey',
      settings,
      form: {
        data: [],
        columns: [],
        sum_of_f_exp: null
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        // 如果频数观测值的总和等于频数预测值的总和
          var form = this.$deepClone(that.form)
          // 将表格结构的频数转换成数组形式
          this.get_obs_and_exp_ary(form)
          // 去掉总和那一行
          form.data = form.data.slice(0, -1)
          form.xys = this.$deepClone(that.computedXys)
          this.$store.dispatch('saveDybtjy', form)
          this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    },
    get_obs_and_exp_ary(form) {
      let { data } = form
      data = data.slice(0, -1)
      const obs_ary = data.map(v => +v.f_obs)
      const exp_ary = data.map(v => +v.f_exp)
      form.obs_ary = obs_ary
      form.exp_ary = exp_ary
    }
  },
  computed: {
    param_name() {
      return (xys) => {
        if (xys[0].length > 0) {
          const param = xys[0][0]
          let name
          if (typeof (param) === 'string') {
            name = param
          } else {
            name = param[param.length - 1]
          }
          return name
        }
        return ''
      }
    }
  }
}
</script>