var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "head-bar" } },
        [
          _c(
            "a-row",
            {
              attrs: {
                id: "main",
                type: "flex",
                justify: "center",
                align: "middle"
              }
            },
            [
              _c("a-col", [
                _c("a", { attrs: { href: "https://www.medspss.com/" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/logo.png"), width: "50px" }
                  }),
                  _c("span", { attrs: { id: "plat-title" } }, [
                    _vm._v("MedSPSS")
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "margin-top": "25px", padding: "0 20px" },
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { attrs: { id: "left-main", span: 24 } },
            [
              _c("div", { attrs: { id: "article-title" } }, [
                _vm._v("上海内环新冠感染率是外环的5.85倍？")
              ]),
              _c(
                "a-row",
                { attrs: { type: "flex", align: "middle", id: "meta" } },
                [
                  _c("a-col", [
                    _c("span", { attrs: { id: "tag" } }, [_vm._v("原创")]),
                    _c("span", { attrs: { id: "time" } }, [
                      _vm._v(" 2022-03-29 17:20:12 ")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c("a-col", [
                    _c("img", {
                      attrs: {
                        width: _vm.isMobile ? "90%" : "100%",
                        src: "/article-static/扫码关注.png"
                      }
                    })
                  ])
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("div", { staticClass: "help-wrapper" }, [
                _c("div", {
                  staticClass: "help-logo",
                  staticStyle: { background: "#3a4d80" }
                }),
                _c("div", { staticClass: "help-logo-blank" }),
                _c("div", {
                  staticClass: "help-logo",
                  staticStyle: { background: "#cfaf43" }
                }),
                _c("div", { staticClass: "head-text" }, [
                  _vm._v(
                    " 基于“上海发布”的公众号公布的数据，本文整理了3月8日至3月29日的所有新冠阳性感染者(含确诊病例和无症状感染者)的信息，共26037份。统计了上海各区阳性感染者的人数，阳性感染率和阳性中确诊病例比例(以下简称确诊率)等信息。 "
                  )
                ]),
                _c("br"),
                _c("div", { staticClass: "head-text" }, [
                  _vm._v(
                    " 同时，为了得出影响上海各区新冠感染率的重要因素，我们收集了诸多相关指标，最终得出各区“人口密度”和区域所在“环线”是影响新冠阳性感染率的显著性因素。 "
                  )
                ]),
                _c("div", { staticStyle: { "text-align": "right" } }, [
                  _c("div", {
                    staticClass: "help-logo",
                    staticStyle: { background: "#3a4d80" }
                  }),
                  _c("div", { staticClass: "help-logo-blank" }),
                  _c("div", {
                    staticClass: "help-logo",
                    staticStyle: { background: "#cfaf43" }
                  })
                ])
              ]),
              _c(
                "div",
                { attrs: { id: "article-content" } },
                [
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", [
                        _c("img", {
                          attrs: {
                            width: "100%",
                            src: "/article-static/3_30/动图.gif"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title1-blue" }, [
                    _c("span", { staticClass: "number" }, [_vm._v("01")]),
                    _c("span", { staticClass: "text" }, [
                      _vm._v("上海各区累计阳性感染者人数的统计")
                    ])
                  ]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", { staticClass: "center" }, [
                        _c("img", {
                          attrs: {
                            width: _vm.isMobile ? "100%" : "60%",
                            src:
                              "/article-static/3_29/上海各区新冠阳性感染人数.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title1-blue" }, [
                    _c("span", { staticClass: "number" }, [_vm._v("02")]),
                    _c("span", { staticClass: "text" }, [
                      _vm._v("上海各区阳性感染率（每万人分之）")
                    ])
                  ]),
                  _c("p", [
                    _vm._v(
                      "各区中阳性感染率较高的区域为“徐汇区”、“闵行区”、“浦东新区”和“黄浦区”。"
                    )
                  ]),
                  _c("div", { staticClass: "light-blue-bg" }, [
                    _c("div", [
                      _vm._v(
                        "“徐汇区”总感染人数虽然排第三，但其阳性感染率最高。"
                      )
                    ]),
                    _c("br"),
                    _c("div", [
                      _vm._v("“闵行区”总的感染人数和阳性感染率都排第二。")
                    ]),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        "“浦东新区”总感染人数最多，但由于人口基数大，其阳性感染率排第三。"
                      )
                    ])
                  ]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", { staticClass: "center" }, [
                        _c("img", {
                          attrs: {
                            width: _vm.isMobile ? "100%" : "60%",
                            src:
                              "/article-static/3_30/各区每万人中阳性感染人数.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("h3", [_vm._v("上海各区确诊率")]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", { staticClass: "center" }, [
                        _c("img", {
                          attrs: {
                            width: _vm.isMobile ? "100%" : "60%",
                            src: "/article-static/3_29/上海各区确诊率排序.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v("各区中确诊率较高的为"),
                    _c("b", [_vm._v("“静安区”")]),
                    _vm._v("，"),
                    _c("b", [_vm._v("“虹口区”")]),
                    _vm._v("和"),
                    _c("b", [_vm._v("“金山区”")]),
                    _vm._v("。")
                  ]),
                  _c("h3", [_vm._v("感染率的影响因素分析")]),
                  _c("p", [
                    _vm._v(
                      " 为了研究影响上海新冠阳性感染率的重要影响因素，我们收集到上海市各区房价、GDP、人均GDP、人口、面积、人口密度、购物中心数和环线等指标，对相关数据进行对数处理，通过最小二乘逐步回归分析，最终得到了新冠阳性感染率的重要影响因素。 "
                    )
                  ]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", [
                        _c("img", {
                          attrs: {
                            width: "100%",
                            src: "/article-static/3_29/回归分析系数表.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("p", [_vm._v("回归分析结果为：")]),
                  _c("p", [
                    _vm._v(
                      " Log_百万分之_感染率＝ 5.5333 － 0.9581 × LOG_人口密度＋ 0.8245 × 环线_外环＋ 1.1445 × 环线_中环＋ 1.5921 × 环线_内环。 "
                    )
                  ]),
                  _c("p", [
                    _vm._v("模型的"),
                    _c("i", [_vm._v("p")]),
                    _vm._v(
                      "值为0.00762 < 0.05，表明模型具有显著性意义。R^2=0.690，显示其相关性高达83%。"
                    )
                  ]),
                  _c("p", [_vm._v("由公式系数可以计算得出以下结论：")]),
                  _c("p", [
                    _c("b", [
                      _vm._v(
                        "内环系数(1.5921)＞中环系数(1.1445)＞外环系数(0.8245)，显示越靠近内环，新冠阳性的感染率越高。内环感染率是外环的5.85倍。"
                      )
                    ])
                  ]),
                  _c("p", [_vm._v("线性回归结果如下：")]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", [
                        _c("img", {
                          attrs: {
                            width: "100%",
                            src: "/article-static/3_29/线性回归图.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "人口数据来源：上海市第七次全国人口普查主要数据公报。"
                    )
                  ]),
                  _c("p", [_vm._v("关注本公众号，可查看每日最新分析结果。")]),
                  _c(
                    "a-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("a-col", [
                        _c("img", {
                          attrs: {
                            width: "100%",
                            src: "/article-static/shanghai_jiayou.png"
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "help-wrapper",
                      staticStyle: { margin: "10px 0" }
                    },
                    [
                      _c("div", { staticStyle: { "text-align": "right" } }, [
                        _c("div", {
                          staticClass: "help-logo",
                          staticStyle: { background: "red" }
                        }),
                        _c("div", { staticClass: "help-logo-blank" }),
                        _c("div", {
                          staticClass: "help-logo",
                          staticStyle: { background: "red" }
                        }),
                        _c("div", { staticClass: "help-logo-blank" }),
                        _c("div", {
                          staticClass: "help-logo",
                          staticStyle: { background: "red" }
                        })
                      ]),
                      _c("div", { staticClass: "head-text" }, [
                        _c("div", [
                          _vm._v(
                            " 本份数据的统计和分析是基于MedSPSS数据分析平台，由算法自动生成，结果和结论仅做参考，不表达任何观点。 "
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "上传数据，您也可以获取自己的报告，敬请联系我们！"
                          )
                        ]),
                        _c("div", [
                          _vm._v("联系邮箱：contacts@swktech.com.cn")
                        ]),
                        _c("div", [_vm._v("MedSPSS版权所有，欢迎转载。")])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-col", { attrs: { span: "24" } }),
          _c(
            "a-col",
            { attrs: { id: "right-main", span: "24" } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c("a-col", { staticClass: "center" }, [
                    _c("img", {
                      staticStyle: { border: "1px solid #ccc" },
                      attrs: {
                        width: _vm.isMobile ? "90%" : "100%",
                        src: "/article-static/扫码关注.png"
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { type: "flex", justify: "center" }
                },
                [_c("a-col", [_vm._v(" 微信公众号 ")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }