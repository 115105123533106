<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 0">{{ data.xys[0][0] }}的平均值 {{ data.form.judge }} {{ data.form.reducedValue }}</div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 0">{{ data.xys[0][0] }}的平均值 {{ $hypothesis.replaceJudge(data.form.judge) }} {{ data.form.reducedValue }}</div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'dybtjyXys',
      xys: [[]],
      formAddKeyName: 'dybtjyFormAddKey',
      settings,
      form: {
          a: '5',
          reducedValue: '0',
          judge: '＝'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
          if (this.$store.state.hypothesis.method) {
          var form = this.$deepClone(that.form)
          form.xys = this.$deepClone(that.computedXys)
          form.a = parseFloat(that.form.a) / 100
          form.reducedValue = parseFloat(that.form.reducedValue)
          this.$store.dispatch('saveDybtjy', form)
          this.$router.push('/hypothesistest/result')
        } else {
          this.$message.info('请选择假设检验方法')
          return false
        }
    }
  }
}
</script>