import request from '@/utils/request'
const articleApi = {
  getList: '/Article/List',
  sub: '/Article'
}

export function List(parameter) {
  return request({
    url: articleApi.getList,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function Get(parameter) {
  return request({
    url: articleApi.sub,
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}