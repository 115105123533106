var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      attrs: { breadcrumb: null },
      scopedSlots: _vm._u([
        {
          key: "extra",
          fn: function() {
            return [_c("sample")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "a-layout",
            { staticClass: "divContent", attrs: { id: "divContent" } },
            [
              _c(
                "a-row",
                { staticStyle: { width: "100% !important" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: _vm.screenWidth > 1080 ? 3 : 4 } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "divContentleft leftWidth",
                          staticStyle: { overflow: "auto" },
                          attrs: { id: "divContentleft" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v("描述性分析")]
                          ),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 20 } },
                                _vm._l(
                                  _vm.iconsList.filter(function(r) {
                                    return r.type === "anysis"
                                  }),
                                  function(item, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        class: item.enable
                                          ? item.state
                                            ? "divBClicked"
                                            : "divB"
                                          : "divBUnable",
                                        style:
                                          "background:" +
                                          (item.opacity === 1
                                            ? "white"
                                            : "#f5faff"),
                                        on: {
                                          click: function($event) {
                                            return _vm.clickIcon(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 描述性分析 ")]
                                    )
                                  }
                                ),
                                0
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v("常用图表")]
                          ),
                          _c(
                            "a-row",
                            _vm._l(
                              _vm.iconsList.filter(function(r) {
                                return r.type === "common"
                              }),
                              function(item) {
                                return _c(
                                  "a-col",
                                  { key: item.name, attrs: { span: 6 } },
                                  [
                                    _c("img", {
                                      class: item.enable ? "" : "form-onlyRead",
                                      style:
                                        "cursor: pointer; opacity:" +
                                        item.opacity,
                                      attrs: {
                                        title: item.cName,
                                        src:
                                          "/chartsIcons/" +
                                          item.name +
                                          "_" +
                                          (item.enable
                                            ? item.state
                                              ? "xuanzhong"
                                              : "moren"
                                            : "hui") +
                                          ".png",
                                        alt: item.action
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickIcon(item)
                                        }
                                      }
                                    })
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          _vm.sxfields && _vm.sxfields.length > 0
                            ? _c("a-divider")
                            : _vm._e(),
                          _vm.sxfields && _vm.sxfields.length > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-bottom": "10px",
                                    "font-weight": "bold"
                                  }
                                },
                                [_vm._v("时序图表")]
                              )
                            : _vm._e(),
                          _vm.sxfields && _vm.sxfields.length > 0
                            ? _c(
                                "a-row",
                                _vm._l(
                                  _vm.iconsList.filter(function(r) {
                                    return r.type === "shixu"
                                  }),
                                  function(item) {
                                    return _c(
                                      "a-col",
                                      { key: item.name, attrs: { span: 6 } },
                                      [
                                        _c("img", {
                                          class: item.enable
                                            ? ""
                                            : "form-onlyRead",
                                          style:
                                            "cursor: pointer; opacity:" +
                                            item.opacity,
                                          attrs: {
                                            title: item.cName,
                                            src:
                                              "/chartsIcons/" +
                                              item.name +
                                              "_" +
                                              (item.enable
                                                ? item.state
                                                  ? "xuanzhong"
                                                  : "moren"
                                                : "hui") +
                                              ".png",
                                            alt: item.action
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickIcon(item)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _c("a-divider"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v("表格")]
                          ),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 20 } },
                                _vm._l(
                                  _vm.iconsList.filter(function(r) {
                                    return r.type === "table"
                                  }),
                                  function(item, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        class: item.enable
                                          ? item.state
                                            ? "divBClicked"
                                            : "divB"
                                          : "divBUnable",
                                        style:
                                          "background:" +
                                          (item.opacity === 1
                                            ? "white"
                                            : "#f5faff"),
                                        on: {
                                          click: function($event) {
                                            return _vm.clickIcon(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 表格 ")]
                                    )
                                  }
                                ),
                                0
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    "text-align": "start",
                                    "font-size": "12px",
                                    "border-top":
                                      "1px solid rgb(232, 232, 232)",
                                    margin: "10px 0",
                                    padding: "10px 0"
                                  },
                                  attrs: { span: 24 }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        margin: "20px 0",
                                        "font-size": "16px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.chartsType))]
                                  ),
                                  _c("br"),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.iconHelp)
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        "border-left": "1px solid rgb(232, 232, 232)",
                        "border-right": "1px solid rgb(232, 232, 232)"
                      },
                      attrs: { span: 4 }
                    },
                    [_c("field", { attrs: { from: "chart" } })],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: { overflow: "auto" },
                      attrs: {
                        span: _vm.screenWidth > 1080 ? 17 : 16,
                        id: "chartContent"
                      }
                    },
                    [
                      _c(
                        "a-layout-content",
                        { staticStyle: { padding: "15px", width: "'100%'" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-input-group-wrapper",
                                  attrs: { "data-v-11f4d49c": "" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ant-input-wrapper ant-input-group"
                                    },
                                    [
                                      _vm.iconState !== "Scatter" &&
                                      _vm.iconState !== "Line" &&
                                      _vm.iconState !== "roc" &&
                                      _vm.iconState !== "Regression" &&
                                      _vm.iconState !== "heatmap" &&
                                      _vm.iconState !== "Scatter_shixu" &&
                                      _vm.iconState !== "Line_shixu" &&
                                      _vm.iconState !== "table" &&
                                      _vm.iconState !== "ciyun" &&
                                      _vm.iconState !== "GIF" &&
                                      _vm.iconState !== "Waterfall"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "130px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.xtype
                                                    ? "分析项【" +
                                                        _vm.xtype +
                                                        "】"
                                                    : "分析项"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "Waterfall"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "155px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "分析项【" + _vm.xtype + "】"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "ciyun"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "160px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.xtype
                                                    ? "分析项【" +
                                                        _vm.xtype +
                                                        "】"
                                                    : "分析项"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "table"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "横轴变量【" +
                                                    _vm.xtype +
                                                    "】"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "Scatter_shixu" ||
                                      _vm.iconState === "Line_shixu" ||
                                      _vm.iconState === "GIF"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.xtype
                                                    ? "分析项【" +
                                                        _vm.xtype +
                                                        "】"
                                                    : "分析项"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "Scatter" ||
                                      _vm.iconState === "Line" ||
                                      _vm.iconState === "roc" ||
                                      _vm.iconState === "Regression" ||
                                      _vm.iconState === "heatmap"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.xtype
                                                    ? "分析项【" +
                                                        _vm.xtype +
                                                        "-X轴】"
                                                    : "分析项"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ant-input",
                                          staticStyle: {
                                            display: "inline-block"
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            drop: function($event) {
                                              return _vm.drop("x")
                                            },
                                            dragover: function($event) {
                                              $event.preventDefault()
                                            }
                                          }
                                        },
                                        [
                                          _vm.iconState === ""
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请先选择图表类型，再拖入左侧变量"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          (_vm.iconState === "pie" ||
                                            _vm.iconState === "bar" ||
                                            _vm.iconState === "bar2" ||
                                            _vm.iconState === "Waterfall") &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数≤2个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          (_vm.iconState === "histogram" ||
                                            _vm.iconState === "MiDu" ||
                                            _vm.iconState === "qq" ||
                                            _vm.iconState === "roc" ||
                                            _vm.iconState === "Boxplot" ||
                                            _vm.iconState === "ErrorBar" ||
                                            _vm.iconState === "Violin" ||
                                            _vm.iconState === "DDT" ||
                                            _vm.iconState === "ciyun" ||
                                            _vm.iconState === "funnel" ||
                                            _vm.iconState === "Area" ||
                                            _vm.iconState === "TreeMap" ||
                                            _vm.iconState === "Radar") &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（1个≤个数≤5个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          (_vm.iconState === "Pearson" ||
                                            _vm.iconState === "Spearman" ||
                                            _vm.iconState === "Kendall" ||
                                            _vm.iconState === "AnysisC" ||
                                            _vm.iconState === "AnysisV") &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数≤10个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "pairplot" &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（2个≤个数≤5个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Phi" &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数≤10个），且变量中只能有两种类别"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          (_vm.iconState === "Scatter_shixu" ||
                                            _vm.iconState === "Line_shixu") &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数≤1个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "table" &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数<=10个）,且同一方向必须为同种类型"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "paleituotu" &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数≤10个）,当需要分析多个定类时，请确保所有分析项为二分类，如多选的各个选项"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          (_vm.iconState === "Scatter" ||
                                            _vm.iconState === "Line" ||
                                            _vm.iconState === "Regression" ||
                                            _vm.iconState === "GIF" ||
                                            _vm.iconState === "heatmap") &&
                                          _vm.x.length === 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "rgba(0, 0, 0, 0.45)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "请拖入左侧变量（个数=1个）"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(_vm.x, function(item, i) {
                                            return _c(
                                              "a-tag",
                                              {
                                                key: item.key,
                                                attrs: {
                                                  closable: "",
                                                  "v-if": item !== null
                                                },
                                                on: {
                                                  close: function($event) {
                                                    return _vm.closeTag(
                                                      $event,
                                                      "x",
                                                      i
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          })
                                        ],
                                        2
                                      ),
                                      _vm.iconState === "table" &&
                                      ((_vm.x.length > 0 &&
                                        _vm.x[0].type === "量") ||
                                        (_vm.x.length === 2 &&
                                          _vm.x[0].type === "类"))
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "130px" }
                                            },
                                            [
                                              _vm.x.length > 0 &&
                                              _vm.x[0].type === "量"
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      model: {
                                                        value: _vm.vtype,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.vtype = $$v
                                                        },
                                                        expression: "vtype"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "显示描述性分析的详细内容,适用于身高、体重这类身体体征数据"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "1",
                                                              attrs: {
                                                                value: "detail"
                                                              }
                                                            },
                                                            [_vm._v("详细")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "数据求和处理,适用于人数、病例这类统计类数据"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "2",
                                                              attrs: {
                                                                value: "sum"
                                                              }
                                                            },
                                                            [_vm._v("求和")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "数据平均值处理"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "3",
                                                              attrs: {
                                                                value: "avg"
                                                              }
                                                            },
                                                            [_vm._v("平均值")]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.x.length === 2 &&
                                              _vm.x[0].type === "类"
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      model: {
                                                        value: _vm.ctypex,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.ctypex = $$v
                                                        },
                                                        expression: "ctypex"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "数据的交叉统计，例如：统计性别为“男”的中民族为“汉”的人数"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "1",
                                                              attrs: {
                                                                value: "merge"
                                                              }
                                                            },
                                                            [_vm._v("交叉")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "数据的单独统计"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "2",
                                                              attrs: {
                                                                value: "single"
                                                              }
                                                            },
                                                            [_vm._v("单独")]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "Line" &&
                                      _vm.x.length > 0 &&
                                      _vm.x[0].type === "日期"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "270px" }
                                            },
                                            [
                                              _vm.x.length > 0 &&
                                              _vm.x[0].type === "日期"
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      on: {
                                                        change: _vm.YearMD
                                                      },
                                                      model: {
                                                        value: _vm.dataType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.dataType = $$v
                                                        },
                                                        expression: "dataType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "显示所有数据"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "1",
                                                              attrs: {
                                                                value: "default"
                                                              }
                                                            },
                                                            [_vm._v("默认值")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "按年进行统计"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "2",
                                                              attrs: {
                                                                value: "year"
                                                              }
                                                            },
                                                            [_vm._v("年")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "按月进行统计"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "3",
                                                              attrs: {
                                                                value: "month"
                                                              }
                                                            },
                                                            [_vm._v("月")]
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "按日进行统计"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "4",
                                                              attrs: {
                                                                value: "day"
                                                              }
                                                            },
                                                            [_vm._v("日")]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "Radar" &&
                                      _vm.x.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "170px" }
                                            },
                                            [
                                              _vm.x.length > 0
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      on: {
                                                        change: _vm.YearMD
                                                      },
                                                      model: {
                                                        value: _vm.lineType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.lineType = $$v
                                                        },
                                                        expression: "lineType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-radio",
                                                        {
                                                          key: "1",
                                                          attrs: {
                                                            value: "avg"
                                                          }
                                                        },
                                                        [_vm._v("平均值")]
                                                      ),
                                                      _c(
                                                        "a-radio",
                                                        {
                                                          key: "3",
                                                          attrs: {
                                                            value: "sum"
                                                          }
                                                        },
                                                        [_vm._v("求和")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.iconState === "paleituotu" &&
                                      _vm.x.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "150px" }
                                            },
                                            [
                                              _c(
                                                "a-checkbox",
                                                {
                                                  model: {
                                                    value: _vm.cp,
                                                    callback: function($$v) {
                                                      _vm.cp = $$v
                                                    },
                                                    expression: "cp"
                                                  }
                                                },
                                                [_vm._v("累积占比")]
                                              ),
                                              _c(
                                                "a-checkbox",
                                                {
                                                  model: {
                                                    value: _vm.percent,
                                                    callback: function($$v) {
                                                      _vm.percent = $$v
                                                    },
                                                    expression: "percent"
                                                  }
                                                },
                                                [_vm._v("显示百分比")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.iconState === "bar" ||
                                        _vm.iconState === "bar2" ||
                                        _vm.iconState === "pie") &&
                                      (_vm.x.length === 1 ||
                                        (_vm.x.length === 2 &&
                                          !(
                                            _vm.x[0].type === "类" &&
                                            _vm.x[1].type === "类"
                                          )))
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "170px" }
                                            },
                                            [
                                              _vm.x.length > 0
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      on: {
                                                        change: _vm.YearMD
                                                      },
                                                      model: {
                                                        value: _vm.barType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.barType = $$v
                                                        },
                                                        expression: "barType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-radio",
                                                        {
                                                          key: "1",
                                                          attrs: {
                                                            value: "percent"
                                                          }
                                                        },
                                                        [_vm._v("百分比")]
                                                      ),
                                                      _c(
                                                        "a-radio",
                                                        {
                                                          key: "2",
                                                          attrs: {
                                                            value: "number"
                                                          }
                                                        },
                                                        [_vm._v("个数")]
                                                      ),
                                                      _vm.iconState === "pie"
                                                        ? _c(
                                                            "a-radio",
                                                            {
                                                              key: "3",
                                                              attrs: {
                                                                value: "all"
                                                              }
                                                            },
                                                            [_vm._v("都要")]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _vm.iconState === "histogram" ||
                              _vm.iconState === "Boxplot" ||
                              _vm.iconState === "ErrorBar" ||
                              _vm.iconState === "Scatter" ||
                              _vm.iconState === "Line" ||
                              _vm.iconState === "roc" ||
                              _vm.iconState === "MiDu" ||
                              _vm.iconState === "Regression" ||
                              _vm.iconState === "Violin" ||
                              _vm.iconState === "pairplot" ||
                              _vm.iconState === "heatmap" ||
                              _vm.iconState === "DDT" ||
                              _vm.iconState === "table" ||
                              (_vm.iconState === "ciyun" &&
                                (_vm.x.length === 0 ||
                                  _vm.x.filter(function(r) {
                                    return r.type === "类"
                                  }).length > 0)) ||
                              _vm.iconState === "funnel" ||
                              _vm.iconState === "rank" ||
                              _vm.iconState === "GIF" ||
                              _vm.iconState === "Area" ||
                              _vm.iconState === "TreeMap" ||
                              _vm.iconState === "Radar" ||
                              _vm.iconState === "Waterfall" ||
                              (_vm.iconState === "paleituotu" &&
                                _vm.quanzhong) ||
                              ((_vm.iconState === "Scatter_shixu" ||
                                _vm.iconState === "Line_shixu") &&
                                _vm.type !== "questionnaire")
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          (_vm.iconState === "Scatter_shixu" ||
                                            _vm.iconState === "Line_shixu") &&
                                          _vm.type !== "questionnaire"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ytype
                                                        ? "分析项【" +
                                                            _vm.ytype +
                                                            "】"
                                                        : "分析项"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "table"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "纵轴变量【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "funnel" ||
                                          _vm.iconState === "rank" ||
                                          _vm.iconState === "TreeMap" ||
                                          _vm.iconState === "paleituotu"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "权重【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "GIF"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "权重【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "ciyun"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "160px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "权重【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Area" ||
                                          _vm.iconState === "Radar"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "分析项【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Waterfall"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "155px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "分析项【" +
                                                        _vm.ytype +
                                                        "】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState !== "Scatter" &&
                                          _vm.iconState !== "Line" &&
                                          _vm.iconState !== "roc" &&
                                          _vm.iconState !== "Regression" &&
                                          _vm.iconState !== "heatmap" &&
                                          _vm.iconState !== "Scatter_shixu" &&
                                          _vm.iconState !== "Line_shixu" &&
                                          _vm.iconState !== "table" &&
                                          _vm.iconState !== "ciyun" &&
                                          _vm.iconState !== "funnel" &&
                                          _vm.iconState !== "rank" &&
                                          _vm.iconState !== "GIF" &&
                                          _vm.iconState !== "Area" &&
                                          _vm.iconState !== "TreeMap" &&
                                          _vm.iconState !== "Radar" &&
                                          _vm.iconState !== "Waterfall" &&
                                          _vm.iconState !== "paleituotu"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ytype
                                                        ? "分组【" +
                                                            _vm.ytype +
                                                            "】"
                                                        : "分组"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Scatter" ||
                                          _vm.iconState === "Line" ||
                                          _vm.iconState === "roc" ||
                                          _vm.iconState === "Regression" ||
                                          _vm.iconState === "heatmap"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ytype
                                                        ? "分析项【" +
                                                            _vm.ytype +
                                                            "-Y轴】"
                                                        : "分析项"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              staticStyle: {
                                                display: "inline-block"
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                drop: function($event) {
                                                  return _vm.drop("y")
                                                },
                                                dragover: function($event) {
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [
                                              (_vm.iconState === "Boxplot" ||
                                                _vm.iconState === "ErrorBar" ||
                                                _vm.iconState === "Violin") &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.boxTest)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.iconState === "table" &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数<=10个）,且同一方向必须为同种类型"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              (_vm.iconState === "Scatter" ||
                                                _vm.iconState === "Line" ||
                                                _vm.iconState === "roc" ||
                                                _vm.iconState ===
                                                  "Regression" ||
                                                _vm.iconState === "heatmap" ||
                                                _vm.iconState === "DDT" ||
                                                _vm.iconState === "Area" ||
                                                _vm.iconState === "Radar" ||
                                                _vm.iconState ===
                                                  "Waterfall") &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数=1个）"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              (_vm.iconState === "MiDu" ||
                                                _vm.iconState === "pairplot" ||
                                                _vm.iconState === "histogram" ||
                                                _vm.iconState ===
                                                  "Line_shixu" ||
                                                ((_vm.iconState ===
                                                  "Scatter_shixu" ||
                                                  _vm.iconState ===
                                                    "Line_shixu") &&
                                                  _vm.type !==
                                                    "questionnaire")) &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数≤1个）"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              (_vm.iconState === "funnel" ||
                                                _vm.iconState === "rank" ||
                                                _vm.iconState === "GIF" ||
                                                _vm.iconState === "TreeMap") &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数≤1个）"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.iconState === "ciyun" &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数≤1个）权重只与定类匹配，如果分析项是文本，权重则无效"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.iconState === "paleituotu" &&
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数≤1个）【可选】"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(_vm.y, function(item, i) {
                                                return _c(
                                                  "a-tag",
                                                  {
                                                    key: item.key,
                                                    attrs: { closable: "" },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.closeTag(
                                                          $event,
                                                          "y",
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _vm.iconState === "table" &&
                                          ((_vm.y.length > 0 &&
                                            _vm.y[0].type === "量") ||
                                            (_vm.y.length === 2 &&
                                              _vm.y[0].type === "类"))
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _vm.y.length > 0 &&
                                                  _vm.y[0].type === "量"
                                                    ? _c(
                                                        "a-radio-group",
                                                        {
                                                          model: {
                                                            value: _vm.vtype,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.vtype = $$v
                                                            },
                                                            expression: "vtype"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "显示描述性分析的详细内容,适用于身高、体重这类身体体征数据"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "1",
                                                                  attrs: {
                                                                    value:
                                                                      "detail"
                                                                  }
                                                                },
                                                                [_vm._v("详细")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "数据求和处理,适用于人数、病例这类统计类数据"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "2",
                                                                  attrs: {
                                                                    value: "sum"
                                                                  }
                                                                },
                                                                [_vm._v("求和")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "数据平均值处理"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "3",
                                                                  attrs: {
                                                                    value: "avg"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "平均值"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.y.length == 2 &&
                                                  _vm.y[0].type === "类"
                                                    ? _c(
                                                        "a-radio-group",
                                                        {
                                                          model: {
                                                            value: _vm.ctypey,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.ctypey = $$v
                                                            },
                                                            expression: "ctypey"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "数据的交叉统计，例如：统计性别为“男”的中民族为“汉”的人数"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "1",
                                                                  attrs: {
                                                                    value:
                                                                      "merge"
                                                                  }
                                                                },
                                                                [_vm._v("交叉")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "数据的单独统计"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "2",
                                                                  attrs: {
                                                                    value:
                                                                      "single"
                                                                  }
                                                                },
                                                                [_vm._v("单独")]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Line" &&
                                          _vm.x.length > 0 &&
                                          _vm.x[0].type === "日期" &&
                                          _vm.y.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "270px"
                                                  }
                                                },
                                                [
                                                  _vm.x.length > 0 &&
                                                  _vm.x[0].type === "日期" &&
                                                  _vm.y.length > 0
                                                    ? _c(
                                                        "a-radio-group",
                                                        {
                                                          on: {
                                                            change: _vm.YearMD
                                                          },
                                                          model: {
                                                            value: _vm.lineType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.lineType = $$v
                                                            },
                                                            expression:
                                                              "lineType"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "1",
                                                              attrs: {
                                                                value: "avg"
                                                              }
                                                            },
                                                            [_vm._v("平均值")]
                                                          ),
                                                          _c(
                                                            "a-radio",
                                                            {
                                                              key: "3",
                                                              attrs: {
                                                                value: "sum"
                                                              }
                                                            },
                                                            [_vm._v("求和")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Waterfall" &&
                                          _vm.y.length > 0 &&
                                          _vm.y[0].type === "日期"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "270px"
                                                  }
                                                },
                                                [
                                                  _vm.y.length > 0 &&
                                                  _vm.y[0].type === "日期"
                                                    ? _c(
                                                        "a-radio-group",
                                                        {
                                                          on: {
                                                            change: _vm.YearMD
                                                          },
                                                          model: {
                                                            value: _vm.dataType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.dataType = $$v
                                                            },
                                                            expression:
                                                              "dataType"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "显示所有数据"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "1",
                                                                  attrs: {
                                                                    value:
                                                                      "default"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "默认值"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "按年进行统计"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "2",
                                                                  attrs: {
                                                                    value:
                                                                      "year"
                                                                  }
                                                                },
                                                                [_vm._v("年")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "按月进行统计"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "3",
                                                                  attrs: {
                                                                    value:
                                                                      "month"
                                                                  }
                                                                },
                                                                [_vm._v("月")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "按日进行统计"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "4",
                                                                  attrs: {
                                                                    value: "day"
                                                                  }
                                                                },
                                                                [_vm._v("日")]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.iconState === "Scatter" ||
                              _vm.iconState === "Line" ||
                              _vm.iconState === "Regression"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        id: "groupC",
                                        "data-v-11f4d49c": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _vm.iconState === "Scatter" ||
                                          _vm.iconState === "Line" ||
                                          _vm.iconState === "Regression"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.iconState ===
                                                        "Scatter"
                                                        ? "颜色分组【定类】"
                                                        : "分组【定类】"
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: {
                                                id: "groupC1",
                                                type: "text",
                                                disabled: _vm.zdisabled
                                              },
                                              on: {
                                                drop: function($event) {
                                                  return _vm.drop("z")
                                                },
                                                dragover: function($event) {
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [
                                              (_vm.iconState === "Scatter" ||
                                                _vm.iconState === "Line" ||
                                                _vm.iconState === "roc" ||
                                                _vm.iconState ===
                                                  "Regression") &&
                                              _vm.z.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.ScatterTest)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(_vm.z, function(item, i) {
                                                return _c(
                                                  "a-tag",
                                                  {
                                                    key: item.key,
                                                    attrs: { closable: "" },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.closeTag(
                                                          $event,
                                                          "z",
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _vm.iconState === "Scatter"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px",
                                                    "border-left": "none",
                                                    "border-right": "none"
                                                  }
                                                },
                                                [_vm._v("形状分组【定类】")]
                                              )
                                            : _vm._e(),
                                          _vm.iconState === "Scatter"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "ant-input",
                                                  attrs: {
                                                    id: "groupC2",
                                                    type: "text",
                                                    disabled: _vm.mdisabled
                                                  },
                                                  on: {
                                                    drop: function($event) {
                                                      return _vm.drop("m")
                                                    },
                                                    dragover: function($event) {
                                                      $event.preventDefault()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.iconState === "Scatter" &&
                                                  _vm.m.length === 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "rgba(0, 0, 0, 0.45)"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ScatterTest
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(_vm.m, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "a-tag",
                                                      {
                                                        key: item.key,
                                                        attrs: { closable: "" },
                                                        on: {
                                                          close: function(
                                                            $event
                                                          ) {
                                                            return _vm.closeTag(
                                                              $event,
                                                              "m",
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.iconState === "roc"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [_vm._v(" 阳性为 ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ant-input" },
                                            [
                                              _vm.y.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请先向Y轴拖入定类变量"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.y.length > 0
                                                ? _c(
                                                    "a-radio-group",
                                                    {
                                                      model: {
                                                        value: _vm.roc,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.roc = $$v
                                                        },
                                                        expression: "roc"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.y[0].option,
                                                      function(item) {
                                                        return _c(
                                                          "a-radio",
                                                          {
                                                            key: item,
                                                            attrs: {
                                                              value: item
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(item) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.iconState === "GIF"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [_vm._v(" 时间变量【日期/定类】 ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: {
                                                type: "text",
                                                disabled: _vm.zdisabled
                                              },
                                              on: {
                                                drop: function($event) {
                                                  return _vm.drop("z")
                                                },
                                                dragover: function($event) {
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [
                                              _vm.z.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数=1个）"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(_vm.z, function(item, i) {
                                                return _c(
                                                  "a-tag",
                                                  {
                                                    key: item.key,
                                                    attrs: { closable: "" },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.closeTag(
                                                          $event,
                                                          "z",
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              (_vm.iconState === "bar" ||
                                _vm.iconState === "bar2") &&
                              _vm.x.length === 2 &&
                              _vm.x[0].type === "类" &&
                              _vm.x[1].type === "类"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "155px" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.x[0].name) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ant-input" },
                                            [
                                              _c(
                                                "a-checkbox-group",
                                                {
                                                  model: {
                                                    value: _vm.barOption,
                                                    callback: function($$v) {
                                                      _vm.barOption = $$v
                                                    },
                                                    expression: "barOption"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.x[0].option,
                                                  function(item) {
                                                    return _c(
                                                      "a-checkbox",
                                                      {
                                                        key: item,
                                                        attrs: {
                                                          value: item,
                                                          checked: true
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.barOption.length === 1 &&
                                          _vm.iconState == "bar2"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-checkbox",
                                                    {
                                                      model: {
                                                        value: _vm.barOrder,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.barOrder = $$v
                                                        },
                                                        expression: "barOrder"
                                                      }
                                                    },
                                                    [_vm._v("排序")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.iconState === "table" &&
                              _vm.x.length > 0 &&
                              _vm.y.length > 0 &&
                              _vm.x[0].type === "类" &&
                              _vm.y[0].type === "类"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _vm.iconState === "table"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "189px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "度量【" +
                                                      _vm._s(_vm.ztype) +
                                                      "】"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: { type: "text" },
                                              on: {
                                                drop: function($event) {
                                                  return _vm.drop("z")
                                                },
                                                dragover: function($event) {
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [
                                              _vm.iconState === "table" &&
                                              _vm.z.length === 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgba(0, 0, 0, 0.45)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请拖入左侧变量（个数<=10个）"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(_vm.z, function(item, i) {
                                                return _c(
                                                  "a-tag",
                                                  {
                                                    key: item.key,
                                                    attrs: { closable: "" },
                                                    on: {
                                                      close: function($event) {
                                                        return _vm.closeTag(
                                                          $event,
                                                          "z",
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _vm.iconState === "table" &&
                                          _vm.z.length > 0 &&
                                          _vm.z[0].type === "量"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-input-group-addon",
                                                  staticStyle: {
                                                    width: "130px"
                                                  }
                                                },
                                                [
                                                  _vm.z.length > 0
                                                    ? _c(
                                                        "a-radio-group",
                                                        {
                                                          model: {
                                                            value: _vm.zfuntype,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.zfuntype = $$v
                                                            },
                                                            expression:
                                                              "zfuntype"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "适用于人数、病例这类统计类数据"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "1",
                                                                  attrs: {
                                                                    value: "sum"
                                                                  }
                                                                },
                                                                [_vm._v("求和")]
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "适用于身高、体重这类身体体征数据"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-radio",
                                                                {
                                                                  key: "2",
                                                                  attrs: {
                                                                    value: "avg"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "平均值"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              (_vm.iconState === "Scatter_shixu" ||
                                _vm.iconState === "Line_shixu") &&
                              _vm.type === "questionnaire"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { "data-v-11f4d49c": "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [_vm._v(" 时间分析项 ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: { id: "groupC1" }
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  model: {
                                                    value: _vm.sxtime,
                                                    callback: function($$v) {
                                                      _vm.sxtime = $$v
                                                    },
                                                    expression: "sxtime"
                                                  }
                                                },
                                                _vm._l(_vm.sxfields, function(
                                                  f,
                                                  i
                                                ) {
                                                  return _c(
                                                    "a-radio",
                                                    {
                                                      key: i,
                                                      attrs: { value: f }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(f.fieldName)
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              (_vm.iconState === "Scatter_shixu" ||
                                _vm.iconState === "Line_shixu") &&
                              _vm.type === "questionnaire"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        id: "groupC",
                                        "data-v-11f4d49c": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [_vm._v(" 统计方法")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: { id: "groupC1" }
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.anysis(
                                                        _vm.infos
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.sxfun,
                                                    callback: function($$v) {
                                                      _vm.sxfun = $$v
                                                    },
                                                    expression: "sxfun"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "1",
                                                      attrs: { value: "sum" }
                                                    },
                                                    [_vm._v("求和")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "2",
                                                      attrs: { value: "max" }
                                                    },
                                                    [_vm._v("最大值")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "3",
                                                      attrs: { value: "min" }
                                                    },
                                                    [_vm._v("最小值")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "4",
                                                      attrs: { value: "avg" }
                                                    },
                                                    [_vm._v("平均值")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "5",
                                                      attrs: { value: "count" }
                                                    },
                                                    [_vm._v("频次")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: {
                                                width: "189px",
                                                "border-left": "none",
                                                "border-right": "none"
                                              }
                                            },
                                            [_vm._v("统计范围")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: { id: "groupC2" }
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.anysis(
                                                        _vm.infos
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.sxrange,
                                                    callback: function($$v) {
                                                      _vm.sxrange = $$v
                                                    },
                                                    expression: "sxrange"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "1",
                                                      attrs: {
                                                        value: "0",
                                                        disabled:
                                                          _vm.count > 10
                                                            ? true
                                                            : false,
                                                        title:
                                                          "超过10人时，不支持个人统计"
                                                      }
                                                    },
                                                    [_vm._v("个人")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    {
                                                      key: "2",
                                                      attrs: { value: "1" }
                                                    },
                                                    [_vm._v("整体")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              (_vm.iconState === "Scatter_shixu" ||
                                _vm.iconState === "Line_shixu") &&
                              _vm.type !== "questionnaire"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ant-input-group-wrapper",
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        id: "groupC",
                                        "data-v-11f4d49c": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ant-input-wrapper ant-input-group"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ant-input-group-addon",
                                              staticStyle: { width: "189px" }
                                            },
                                            [_vm._v("时间分析项")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ant-input",
                                              attrs: { id: "groupC2" }
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  on: {
                                                    change: _vm.sxtimeChange
                                                  },
                                                  model: {
                                                    value: _vm.sxtime,
                                                    callback: function($$v) {
                                                      _vm.sxtime = $$v
                                                    },
                                                    expression: "sxtime"
                                                  }
                                                },
                                                _vm._l(_vm.sxfields, function(
                                                  f,
                                                  i
                                                ) {
                                                  return _c(
                                                    "a-radio",
                                                    {
                                                      key: i,
                                                      attrs: { value: f }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(f.fieldName)
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.anysisLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.anysis(_vm.infos)
                                    }
                                  }
                                },
                                [_vm._v("分析")]
                              ),
                              _vm.errMessage
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "margin-left": "10px"
                                      }
                                    },
                                    [_vm._v("数据量太大，请使用其他图形")]
                                  )
                                : _vm._e(),
                              ((_vm.iconState === "pie" ||
                                _vm.iconState === "bar" ||
                                _vm.iconState === "bar2") &&
                                _vm.x.length === 2 &&
                                _vm.x.filter(function(r) {
                                  return r.type === "类"
                                }).length == 2) ||
                              (_vm.iconState === "Boxplot" &&
                                _vm.x.length > 0) ||
                              (_vm.iconState === "Violin" &&
                                _vm.x.length > 0) ||
                              (_vm.iconState === "DDT" &&
                                _vm.x.length > 0 &&
                                _vm.y.length > 0) ||
                              ((_vm.iconState === "Scatter" ||
                                _vm.iconState === "Line" ||
                                _vm.iconState === "Regression" ||
                                _vm.iconState === "heatmap") &&
                                _vm.x.length == 1 &&
                                _vm.y.length === 1) ||
                              (_vm.iconState === "roc" &&
                                _vm.x.length > 0 &&
                                _vm.y.length > 0) ||
                              (_vm.iconState === "table" &&
                                (_vm.x.length > 0 || _vm.y.length > 0))
                                ? _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "20px",
                                      "margin-left": "10px",
                                      color: "rgb(24, 144, 255)",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      type: "retweet",
                                      title:
                                        _vm.iconState === "roc"
                                          ? "互换单调性"
                                          : _vm.iconState === "Boxplot" ||
                                            _vm.iconState === "Violin"
                                          ? "互换坐标轴"
                                          : "互换分析项的顺序"
                                    },
                                    on: { click: _vm.retweet }
                                  })
                                : _vm._e(),
                              _vm.anysisLoading
                                ? _c("a-spin", {
                                    staticStyle: { "margin-left": "10px" }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.chartLoad
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "a-spin",
                                    {
                                      attrs: {
                                        tip: "图表加载中，请稍候",
                                        size: "large"
                                      }
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: { height: "100px" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { attrs: { id: "chartDiv" } },
                            [
                              _vm.iconState !== "Phi" &&
                              _vm.iconState !== "Pearson" &&
                              _vm.iconState !== "Spearman" &&
                              _vm.iconState !== "Kendall"
                                ? _c(
                                    "div",
                                    [
                                      _vm.dataChart &&
                                      _vm.dataChart.length > 0 &&
                                      !_vm.chartLoad
                                        ? _c("a-table", {
                                            staticStyle: {
                                              "margin-top": "20px"
                                            },
                                            attrs: {
                                              bordered: "",
                                              size: "small",
                                              rowKey: "key",
                                              columns: _vm.columnsChart,
                                              "data-source": _vm.dataChart,
                                              pagination: false,
                                              scroll: { x: true }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "aucConf",
                                                  fn: function(text) {
                                                    return _c("div", {}, [
                                                      _vm._v(
                                                        "( " +
                                                          _vm._s(text[0]) +
                                                          " , " +
                                                          _vm._s(text[1]) +
                                                          " )"
                                                      )
                                                    ])
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4232016787
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              (_vm.optionList &&
                                _vm.optionList.length > 0 &&
                                _vm.iconState !== "AnysisV" &&
                                _vm.iconState !== "AnysisC") ||
                              _vm.iconState !== "pairplot"
                                ? _c(
                                    "a-row",
                                    { attrs: { gutter: 20 } },
                                    _vm._l(_vm.optionList, function(item, a) {
                                      return _c(
                                        "a-col",
                                        {
                                          key: item.id,
                                          style: {
                                            marginTop: "30px",
                                            marginBottom: "30px",
                                            overflowX: "auto",
                                            webkitOverflowScrolling: "touch",
                                            borderBottom:
                                              _vm.optionList.length > 1 &&
                                              a < _vm.optionList.length - 1
                                                ? "1px #dedede dashed"
                                                : "",
                                            paddingBottom: "20px"
                                          },
                                          attrs: { span: 24, align: "center" }
                                        },
                                        [
                                          _vm.iconState === "Pearson" ||
                                          _vm.iconState === "Spearman"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "800px",
                                                    margin:
                                                      "20px auto 30px auto"
                                                  }
                                                },
                                                [
                                                  _vm.cxz.length > 0 ||
                                                  _vm.cgd.length > 0
                                                    ? _c(
                                                        "a-descriptions",
                                                        {
                                                          attrs: {
                                                            bordered: "",
                                                            size: "middle",
                                                            layout: "vertical"
                                                          }
                                                        },
                                                        [
                                                          _vm.cxz.length > 0
                                                            ? _c(
                                                                "a-descriptions-item",
                                                                {
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "center"
                                                                  },
                                                                  attrs: {
                                                                    label:
                                                                      "显著相关【0.5<|r|≤0.8】"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.cxz,
                                                                  function(
                                                                    aa,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: i
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            aa
                                                                          )
                                                                        ),
                                                                        _c("br")
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                          _vm.cgd.length > 0
                                                            ? _c(
                                                                "a-descriptions-item",
                                                                {
                                                                  staticStyle: {
                                                                    "vertical-align":
                                                                      "top !important"
                                                                  },
                                                                  attrs: {
                                                                    label:
                                                                      "高度相关【0.8<|r|<1】"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.cgd,
                                                                  function(
                                                                    aa,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: i
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            aa
                                                                          )
                                                                        ),
                                                                        _c("br")
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("img", {
                                            staticStyle: { align: "center" },
                                            attrs: { src: item.src }
                                          }),
                                          _c("br"),
                                          _c(
                                            "div",
                                            [
                                              _vm.iconState !== "AnysisV"
                                                ? _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handeldownload(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("下载原图")]
                                                  )
                                                : _vm._e(),
                                              _vm.iconState != "pairplot"
                                                ? _c("a-icon", {
                                                    staticStyle: {
                                                      "margin-left": "10px"
                                                    },
                                                    attrs: { type: "setting" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.settitleClick(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm.iconState === "AnysisV" &&
                              _vm.anysisVData !== null &&
                              _vm.anysisVData.datasource.length > 0
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            margin: "10px auto",
                                            "font-size": "18px"
                                          }
                                        },
                                        [_vm._v("定量")]
                                      ),
                                      _c(
                                        "a-table",
                                        {
                                          attrs: {
                                            bordered: "",
                                            size: "small",
                                            rowKey: "key",
                                            columns: _vm.anysisVData.columns1,
                                            "data-source":
                                              _vm.anysisVData.datasource,
                                            pagination: false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "number",
                                                fn: function(text, record) {
                                                  return _c(
                                                    "span",
                                                    {},
                                                    [
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              record["平均值"] +
                                                                "±" +
                                                                record["标准差"]
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ],
                                                    2
                                                  )
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3851326753
                                          )
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold"
                                              },
                                              attrs: { slot: "基础指标" },
                                              slot: "基础指标"
                                            },
                                            [_vm._v("基础指标")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table",
                                        {
                                          staticStyle: { "margin-top": "-1px" },
                                          attrs: {
                                            bordered: "",
                                            size: "small",
                                            rowKey: "key",
                                            columns: _vm.anysisVData.columns2,
                                            "data-source":
                                              _vm.anysisVData.datasource,
                                            pagination: false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "zhixin",
                                                fn: function(text, record) {
                                                  return _c(
                                                    "span",
                                                    {},
                                                    [
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              "(" +
                                                                record[
                                                                  "置信区间"
                                                                ][0] +
                                                                "," +
                                                                record[
                                                                  "置信区间"
                                                                ][1] +
                                                                ")"
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ],
                                                    2
                                                  )
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2768564284
                                          )
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold"
                                              },
                                              attrs: { slot: "深入指标" },
                                              slot: "深入指标"
                                            },
                                            [_vm._v("深入指标")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "zhixinTitle" },
                                              slot: "zhixinTitle"
                                            },
                                            [
                                              _c("div", [
                                                _vm._v("均值置信区间")
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "8px",
                                                    color: "grey"
                                                  }
                                                },
                                                [_vm._v("（置信度：95%）")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm.iconState === "AnysisV" &&
                                _vm.anysisCData !== null &&
                                _vm.anysisCData.datasource.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "margin-top": "20px",
                                              "font-size": "18px"
                                            }
                                          },
                                          [_vm._v("定类")]
                                        ),
                                        _c(
                                          "a-table",
                                          {
                                            key: _vm.i,
                                            staticStyle: {
                                              margin: "10px auto"
                                            },
                                            attrs: {
                                              size: "small",
                                              bordered: "",
                                              rowKey: "key",
                                              columns: _vm.anysisCData.columns,
                                              "data-source":
                                                _vm.anysisCData.datasource,
                                              pagination: false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "zhixinTitle" },
                                                slot: "zhixinTitle"
                                              },
                                              [
                                                _c("div", [_vm._v("置信区间")]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "8px",
                                                      color: "grey"
                                                    }
                                                  },
                                                  [_vm._v("（置信度：95%）")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                              _vm.iconState === "Phi" ||
                              _vm.iconState === "Pearson" ||
                              _vm.iconState === "Spearman" ||
                              _vm.iconState === "Kendall"
                                ? _c(
                                    "div",
                                    [
                                      _vm.dataChart && _vm.dataChart.length > 0
                                        ? _c("a-table", {
                                            staticStyle: {
                                              "margin-top": "20px"
                                            },
                                            attrs: {
                                              bordered: "",
                                              size: "small",
                                              rowKey: "key",
                                              columns: _vm.columnsChart,
                                              "data-source": _vm.dataChart,
                                              pagination: false,
                                              scroll: { x: true }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !this.iconState
                                ? _c("a-row", [_c("my-select")], 1)
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Settitle", {
        ref: "ruleForm",
        attrs: { visible: _vm.titleVisible, data: _vm.titledata },
        on: { cancel: _vm.handleCancel, ok: _vm.handleOk }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }