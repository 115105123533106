var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.charts, function(a, thirdIndex) {
      return _c(
        "div",
        { key: thirdIndex },
        [
          _c(
            "div",
            {
              ref: "title," + (thirdIndex + 1),
              refInFor: true,
              staticClass: "third-title",
              attrs: {
                id:
                  "1." + _vm.secondIndex + "." + (thirdIndex + 1) + " " + a.text
              }
            },
            [
              _vm._v(
                " 1." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(thirdIndex + 1) +
                  " " +
                  _vm._s(a.text) +
                  " "
              )
            ]
          ),
          _c(
            "div",
            [
              a.detail
                ? _c("Conclusion", [
                    _c(
                      "p",
                      {
                        ref: "text," + (thirdIndex + 1) + ",0",
                        refInFor: true
                      },
                      [_vm._v(" " + _vm._s(a.detail) + " ")]
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._l(a.charts, function(f, forthIndex) {
            return _c(
              "div",
              { key: forthIndex, staticStyle: { "margin-top": "10px" } },
              [
                _c(
                  "div",
                  {
                    ref:
                      "sub_title," +
                      (thirdIndex + 1) +
                      "," +
                      (8 * forthIndex + 1),
                    refInFor: true,
                    staticClass: "forth-title",
                    attrs: {
                      id:
                        "1." +
                        _vm.secondIndex +
                        "." +
                        (thirdIndex + 1) +
                        "." +
                        (forthIndex + 1) +
                        " " +
                        f.name
                    }
                  },
                  [
                    _vm._v(
                      " 1." +
                        _vm._s(_vm.secondIndex) +
                        "." +
                        _vm._s(thirdIndex + 1) +
                        "." +
                        _vm._s(forthIndex + 1) +
                        " " +
                        _vm._s(f.name) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  [
                    _c("chartShow", {
                      ref: "content," + (thirdIndex + 1),
                      refInFor: true,
                      attrs: {
                        tables: f.tables,
                        imgs: f.imgs,
                        indexT: forthIndex
                      },
                      scopedSlots: _vm._u(
                        [
                          f.tables.length > 0
                            ? {
                                key: "text1",
                                fn: function() {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        ref:
                                          "text," +
                                          (thirdIndex + 1) +
                                          "," +
                                          (8 * forthIndex + 2),
                                        refInFor: true
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(f.tables[0].text) + " "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null,
                          {
                            key: "text2",
                            fn: function() {
                              return [
                                _c(
                                  "p",
                                  {
                                    ref:
                                      "text," +
                                      (thirdIndex + 1) +
                                      "," +
                                      (8 * forthIndex + 5),
                                    refInFor: true
                                  },
                                  [_vm._v(" " + _vm._s(f.text2) + " ")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }