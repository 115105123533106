var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.type, function(obj, index) {
      return _c(
        "div",
        { key: index, attrs: { id: "3." + (index + 1) + " " + obj.text } },
        [
          _c(
            "div",
            { ref: "title", refInFor: true, staticClass: "second-title" },
            [_vm._v("3." + _vm._s(index + 1) + " " + _vm._s(obj.text))]
          ),
          _c(obj.value, {
            ref: "content",
            refInFor: true,
            tag: "component",
            staticClass: "second-module",
            attrs: {
              data: _vm.data[index],
              xys: _vm.xys[index],
              secondIndex: index + 1,
              settings: _vm.get_settings_obj(obj.value)
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }