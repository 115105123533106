var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      attrs: { breadcrumb: null },
      scopedSlots: _vm._u([
        {
          key: "extra",
          fn: function() {
            return [
              _c(
                "router-link",
                { attrs: { to: "/commonchart" } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "28px" },
                      attrs: { type: "primary" }
                    },
                    [_vm._v("档案统计")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-layout",
        { staticClass: "divContent", attrs: { id: "divContent" } },
        [
          _c(
            "a-layout-sider",
            { staticClass: "divContentleft", attrs: { id: "divContentleft" } },
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "table-operator" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", icon: "plus" },
                                on: {
                                  click: function($event) {
                                    _vm.isNewAdd = true
                                  }
                                }
                              },
                              [_vm._v("新建")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "table-page-search-wrapper",
                            staticStyle: { "margin-bottom": "5px" }
                          },
                          [
                            _c(
                              "a-row",
                              { attrs: { gutter: 38 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { md: 80, sm: 24 } },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "搜索用户" },
                                      on: {
                                        change: function($event) {
                                          return _vm.$refs.table.refresh(true)
                                        }
                                      },
                                      model: {
                                        value: _vm.queryParam.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.queryParam, "name", $$v)
                                        },
                                        expression: "queryParam.name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("s-table", {
                          ref: "table",
                          attrs: {
                            size: "small",
                            rowKey: "key",
                            columns: _vm.columns,
                            data: _vm.loadData,
                            pagination: _vm.pagination,
                            pageSize: _vm.pageSize,
                            customRow: _vm.click,
                            rowClassName: function(record) {
                              return record.id === this$1.docId
                                ? "clickRowStyle"
                                : ""
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function(text) {
                                return _c("span", {}, [
                                  _vm._v(_vm._s(_vm._f("hideName")(text)))
                                ])
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-layout-content",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 22 } },
                    [
                      _c("router-view", {
                        on: {
                          refreshtable: function($event) {
                            return _vm.$refs.table.refresh()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("basic-information-modal", {
        attrs: { isNewAdd: _vm.isNewAdd },
        on: {
          handleCancel: function($event) {
            _vm.isNewAdd = false
          },
          refreshDocList: function($event) {
            return _vm.$refs.table.refresh(true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }