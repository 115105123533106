var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          bordered: "",
          pagination: false
        },
        scopedSlots: _vm._u([
          {
            key: "f_exp",
            fn: function(text, record) {
              return [
                record.key === "总和"
                  ? _c("span", [_vm._v(_vm._s(_vm.sum_of_f_exp))])
                  : _c("span", [_vm._v(" " + _vm._s(record.f_exp))])
              ]
            }
          },
          {
            key: "f_exp_ratio",
            fn: function(text, record) {
              return [
                record.key === "总和"
                  ? _c("span", [_vm._v(_vm._s(_vm.sum_of_f_exp_ratio))])
                  : _c("a-input", {
                      attrs: { placeholder: "请填写频数预测值" },
                      model: {
                        value: record.f_exp_ratio,
                        callback: function($$v) {
                          _vm.$set(record, "f_exp_ratio", $$v)
                        },
                        expression: "record.f_exp_ratio"
                      }
                    })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }