var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "third-module" }, [_vm._t("text1")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      [
        _vm.table
          ? _c(
              "a-table",
              {
                ref: "tables,9",
                attrs: {
                  showHeader: _vm.table.showHeader,
                  pagination: false,
                  dataSource: _vm.table.data,
                  columns: _vm.table.columns,
                  size: "small"
                }
              },
              _vm._l(_vm.table.columns, function(item) {
                return _c(
                  "div",
                  {
                    key: item.key,
                    attrs: { slot: "slotValue" in item ? item.slotValue : "" },
                    slot: "slotValue" in item ? item.slotValue : ""
                  },
                  [
                    _c(
                      "div",
                      { domProps: { innerHTML: _vm._s(item.slotValue) } },
                      [_vm._v(_vm._s(item.slotValue))]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text2")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }