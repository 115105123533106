var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "ant-input": true,
        dropDiv: true,
        "drop-disabled": "mark" in _vm.item
      },
      attrs: { type: "text" },
      on: {
        drop: function($event) {
          return _vm.drop(_vm.item)
        },
        dragover: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _vm.item.list.length === 0
        ? _c(
            "span",
            _vm._l(_vm.item.divHelp, function(help) {
              return _c("span", { key: help, class: { helpFontColor: true } }, [
                _vm._v(_vm._s(help))
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._l(_vm.item.list, function(value, i) {
        return _c(
          "a-tag",
          {
            key: i,
            staticClass: "myTag",
            attrs: {
              closable: !("mark" in _vm.item) && !("mark" in value),
              visible: value !== null
            },
            on: {
              close: function(e) {
                return _vm.onClose(e, _vm.item, i, value)
              }
            }
          },
          [_vm._v(_vm._s(value.name))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }