<template>
  <a-row>
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px; border-right: 1px solid rgb(232, 232, 232)">
            <myTable :data="data" :columns="columns" :title="'交叉卡方检验分析结果'"></myTable>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'交叉卡方检验智能分析结果'">
              <div>
                <div v-for="(item, index) in result" :key="index">
                  <span v-html="item"></span>
                </div>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
import { Tables } from '@/views/hypothesisTest/utils/formatTableClass'

export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      columns: [],
      method: this.fetchTable,
      method2: Tables.kaFang,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [['']]
      }
    }
  },
  computed: {
    lei1() {
      return '&lt;' + this.part2.x + '&gt;'
    },
    lei2() {
      return '&lt;' + this.part2.y + '&gt;'
    },
    H0() {
      return [`${this.lei1}与${this.lei2}不具有关联性`]
    },
    H1() {
      return [`${this.lei1}与${this.lei2}具有关联性`]
    },
    result() { return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0], this.part2.pyMethod)}`] }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['statistic', 'p'])
    },
    fetchTable(columns, t, res) {
      const xLength = res.part2.xys[0][0].length
      const yLength = res.part2.xys[1][0].length
      this.method2(columns, ['χ²', 'p'], xLength, yLength)
      // 给第一列加背景色
      this.$nextTick(() => {
        const a = document.getElementsByClassName('colTitle')
        for (const span of a) {
          const b = span.parentNode
          b.style.background = '#FAFAFA'
        }
      })
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.jckf()
    }
  }
}
</script>