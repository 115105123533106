<template>
  <div style="margin-top: 50px">
    <a-spin tip="文件加载中..." :spinning="spinning">
      <a-row type="flex" justify="center">
        <a-col :span="8">
          <a-upload-dragger
            name="file"
            @change="handleChange"
            :before-upload="beforeUpload"
            :file-list="fileList"
            accept=".xls, .xlsx"
          >
            <div style="padding: 50px">
              <p>
                <a
                  style="font-size: 16px; font-weight: bold; margin-right: 5px; text-decoration: underline"
                  class="ant-upload-text"
                >点击上传文件</a
                >
                或者拖拽上传<br />
                支持Excel文件
              </p>
            </div>
          </a-upload-dragger>
        </a-col>
        <a-col :span="7">
          <div style="margin-left: 30px; margin-top: 20px">
            <router-link to="/file/List"
            ><a style="font-size: 16px; font-weight: bold; text-decoration: underline" class="ant-upload-text"
            >返回文件列表</a
            ></router-link
            ><br />
            导入说明：<br />
            1、支持xls、xlsx等格式<br />
            2、不能合并单元格<br />
            3、第一行是标题不能缺失<br />
            4、标题中请不要包含以下特殊字符[`~!@#$^&amp;*()=|{}':;',\\[\\].&lt;&gt;《》/?~！@#￥……&amp;*（）——|{}【】‘；：”“'。，、？空格<br />
            5、当数值型超过有效数据的70%，则自动将该列变成数值型<br />
            6、当文本种类小于有效数据的30%且种类少于13个时，该列为定类
            <br />
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="15">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="表格示例1">
              <a-table
                style="margin-top: 20px"
                bordered
                size="small"
                rowKey="key"
                :columns="columns1"
                :data-source="dataChart1"
                :pagination="false"
              ></a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="表格示例2" force-render>
              <a-table
                style="margin-top: 20px"
                bordered
                size="small"
                rowKey="key"
                :columns="columns2"
                :data-source="dataChart2"
                :pagination="false"
              ></a-table>

              <div style="margin-top: 20px">
                如果是上面格式的表格需要转换成下面的格式<a-icon
                  style="font-size: 20px; margin-left: 20px"
                  type="sync"
                />
              </div>
              <a-table
                style="margin-top: 20px"
                bordered
                size="small"
                rowKey="key"
                :columns="columns3"
                :data-source="dataChart3"
                :pagination="false"
              ></a-table> </a-tab-pane
            ></a-tabs>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
import XLSX from 'xlsx'
// 列
const columns1 = [
  {
    title: '第1列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '编号',
        dataIndex: 'key',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第2列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '性别',
        dataIndex: 'x2',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第3列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '年龄',
        dataIndex: 'x3',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第4列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '学历',
        dataIndex: 'x4',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第5列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '民族',
        dataIndex: 'x5',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第6列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '婚姻情况',
        dataIndex: 'x6',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '......',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '......',
        dataIndex: 'x7',
        align: 'center',
        className: 'column-money'
      }
    ]
  }
]
const dataChart1 = [
  {
    key: '1',
    x2: '男',
    x3: '25',
    x4: '本科含大专',
    x5: '汉族',
    x6: '未婚',
    x7: '......'
  },
  {
    key: '2',
    x2: '男',
    x3: '30',
    x4: '研究生及以上',
    x5: '汉族',
    x6: '已婚',
    x7: '......'
  },
  {
    key: '3',
    x2: '女',
    x3: '23',
    x4: '高中含中专',
    x5: '其他民族',
    x6: '未婚',
    x7: '......'
  },
  {
    key: '4',
    x2: '男',
    x3: '44',
    x4: '初中',
    x5: '汉族',
    x6: '离异',
    x7: '......'
  },
  {
    key: '5',
    x2: '女',
    x3: '36',
    x4: '小学',
    x5: '汉族',
    x6: '已婚',
    x7: '......'
  }
]
// 列
const columns2 = [
  {
    title: '性别',
    dataIndex: 'x1',
    align: 'center',
    className: 'column-money'
  },

  {
    title: '研究生及以上',
    dataIndex: 'x2',
    align: 'center',
    className: 'column-money'
  },

  {
    title: '本科含大专',
    dataIndex: 'x3',
    align: 'center',
    className: 'column-money'
  },
  {
    title: '高中含中专',
    dataIndex: 'x4',
    align: 'center',
    className: 'column-money'
  },
  {
    title: '初中',
    dataIndex: 'x5',
    align: 'center',
    className: 'column-money'
  },
  {
    title: '小学',
    dataIndex: 'x6',
    align: 'center',
    className: 'column-money'
  }
]

const dataChart2 = [
  {
    key: '1',
    x1: '男',
    x2: '1',
    x3: '5',
    x4: '4',
    x5: '2',
    x6: '2'
  },
  {
    key: '2',
    x1: '女',
    x2: '1',
    x3: '7',
    x4: '5',
    x5: '2',
    x6: '4'
  }
]
const columns3 = [
  {
    title: '第1列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '性别',
        dataIndex: 'X1',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第2列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '学历',
        dataIndex: 'x2',
        align: 'center',
        className: 'column-money'
      }
    ]
  },
  {
    title: '第3列',
    align: 'center',
    className: 'column-shili',
    children: [
      {
        title: '人数',
        dataIndex: 'x3',
        align: 'center',
        className: 'column-money'
      }
    ]
  }
]
const dataChart3 = [
  {
    key: '1',
    X1: '男',
    x2: '研究生及以上',
    x3: '1'
  },
  {
    key: '2',
    X1: '男',
    x2: '本科含大专',
    x3: '5'
  },
  {
    key: '3',
    X1: '男',
    x2: '高中含中专',
    x3: '4'
  },
  {
    key: '4',
    X1: '男',
    x2: '初中',
    x3: '2'
  },
  {
    key: '5',
    X1: '男',
    x2: '小学',
    x3: '2'
  },
  {
    key: '6',
    X1: '女',
    x2: '研究生及以上',
    x3: '1'
  },
  {
    key: '7',
    X1: '女',
    x2: '本科含大专',
    x3: '7'
  },
  {
    key: '8',
    X1: '女',
    x2: '高中含中专',
    x3: '5'
  },
  {
    key: '9',
    X1: '女',
    x2: '初中',
    x3: '2'
  },
  {
    key: '10',
    X1: '女',
    x2: '小学',
    x3: '4'
  }
]
export default {
  name: 'Step1',
  data() {
    return {
      columns1,
      dataChart1,
      columns2,
      dataChart2,
      columns3,
      dataChart3,
      fileList: [],
      spinning: false, // 加载中百分比
      error: false
    }
  },
  methods: {
    // 文件上传或者拖拽
    handleChange(info) {
      if (this.error) {
        return
      }
      this.spinning = true
      const that = this
      // 拿到文件对象
      var f = this.fileList[0]
      // 用FileReader来读取
      var reader = new FileReader()
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = ''
        var wb // 读取完成的数据
        var outdata // 你需要的数据
        var reader = new FileReader()
        reader.onload = function(e) {
          that.setPercent(30) // 加载进度条
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result)
          var length = bytes.byteLength
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          // 接下来就是xlsx了，具体可看api
          wb = XLSX.read(binary, {
            type: 'binary',
            cellDates: true
          })
          var sheet = wb.Sheets[wb.SheetNames[0]]
          var ref = sheet['!ref']
          var se = ref.split(':')
          var rowcount = that.getRowFromId(se[1] + 1)
          // 判断是否有合并单元格，如果有从合并开始就不显示
          if (sheet['!merges'] && sheet['!merges'].length > 0) {
            sheet['!ref'] = 'A1:' + that.getColFromNum(sheet['!merges'][0].s.c) + rowcount
          }

          that.setPercent(40) // 加载进度条
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, defval: null })

          that.setPercent(60) // 加载进度条
          // 自定义方法向父组件传递数据
          // 整合的标题和数据类
          var data = that.dataHandel(outdata)
          that.setPercent(80) // 加载进度条
          // 如果有单元格合并，在下一个页面提示
          if (sheet['!merges'] && sheet['!merges'].length > 0) {
            data.merges = true
          } else {
            data.merges = false
          }
          that.setPercent(90) // 加载进度条
          that.$emit('nextStep', { ...data, name: f.name.substring(0, f.name.lastIndexOf('.')) })
        }
        reader.readAsArrayBuffer(f)
      }
      reader.readAsBinaryString(f)
    },
    readeronload(e, callback) {},
    setPercent(prcent) {
      this.precent = 20 // 加载进度条
    },
    // A23取23
    getRowFromId(str) {
      var reg = /(\d+)/g
      var result = reg.exec(str)
      return parseInt(result[0]) - 1
    },
    // 数字转换成excel列AA
    getColFromNum(n) {
      const ordA = 'A'.charCodeAt(0)
      const ordZ = 'Z'.charCodeAt(0)
      const len = ordZ - ordA + 1
      let str = ''
      while (n >= 0) {
        str = String.fromCharCode((n % len) + ordA) + str
        n = Math.floor(n / len) - 1
      }
      return str
    },

    timeG(dateData) {
      const date = new Date(dateData)
      return date.Format('yyyy-MM-dd')
    },
    dataHandel(data) {
      // 新增时用
      const Control = { CreateDate: new Date(), Order: 0, DeleteFlag: 0 }
      var dateStr = new RegExp(
        '^(([2-9]d{3}((0[1-9]|1[012])(0[1-9]|1d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)|(([2-9]d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00))0229))|([0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30))))| ([0-9]{4}/(((0[13578]|(10|12))/(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)/(0[1-9]|[1-2][0-9]|30))))$'
      )
      // 数据的列数都是一样的
      if (data.length > 0) {
        var row = data[0] // 取第一行
        data.shift() // 去掉第一行
        var dataValue = data // excel数据的集合
        var titles = []
        var nary = []
        row = row.map((r, i) => {
          var length = nary.filter(a => a == r).length

          nary.push(r)
          if (length > 0) {
            r = r + '_' + length
          }
          return r
        })

        // 按字段检索
        row.map((r, i) => {
          if (r == null) {
            r = '_Empty' + i
          }
          // 找出不为空的值
          var rowdata = dataValue
            .filter(m => m[i] != null && m[i] != undefined && m[i] !== '')
            .map(m => {
              return m[i]
            })
          // 检索该字段是否有数值型
          var numberdata = rowdata.filter(m => !isNaN(Number(m))) // 纯数字
          // var numberStrData = rowdata.filter(m => !isNaN(parseInt(m)))
          var dateParse = rowdata.filter(m => m.toString().indexOf('(中国标准时间)') > -1 || dateStr.test(m))
          if (dateParse.length > 0) {
            // 日期类型，type=3
            titles.push({ name: r, fileType: 3, Control, index: i })
          } else if (numberdata.length > 0 && (numberdata.length * 100.0) / rowdata.length >= 70) {
            // 当这列数据有数值，且数值比例占有值数据的70以上则设为数值型
            // 相同的数字分类
            var option = this.unique(rowdata)
            // 相同的种类占总数的百分比
            var p = Math.round((option.length / rowdata.length) * 100)
            // 相同的种类占总数的百分比小于1/3时，设置为定类
            if (option.length < rowdata.length && p < 34 && option.length < 13) {
              // 整合列的属性
              titles.push({
                name: r,
                fileType: 2,
                option: JSON.stringify(option),
                Control,
                index: i
              })
            } else {
              // 数值型数据，type=0
              titles.push({ name: r, fileType: 0, Control, index: i })
            }
          } else {
            // 字符型转换成定类
            option = this.unique(rowdata)
            if (option.length > 12) {
              // 如果分类数超过12个则为文本类型
              titles.push({ name: r, fileType: 4, Control, index: i })
            } else {
              // 整合列的属性
              titles.push({
                name: r,
                fileType: 2,
                option: JSON.stringify(option),
                Control,
                index: i
              })
            }
          }
        })
        // 整理数据
        // 1.全部为数值型的不处理
        // 2.全部为字符的作定类处理
        // 2.有数值有字符的，保留数值，字符为空
        var dataout = []
        dataValue.map((r, index) => {
          var out = {}
          out['key'] = index + 1
          titles.map((m, i) => {
            switch (m.fileType) {
              case 3: // 日期型，将不是日期的清空，必须写在数值上面，因为日期类型的都可以转换成数值
                if (r[i] && r[i].toString().indexOf('GMT+') === -1 && !dateStr.test(r[i]) && isNaN(Date.parse(r[i]))) {
                  out[m.name] = ''
                } else {
                  var d = new Date(r[i])
                  if (!d.getDate()) {
                    var date = dateStr.exec(r[i])
                    if (date && date[0]) {
                     d = new Date(date[0].replace(/-/g, '/'))
                    }
                  }
                  if (!isNaN(d)) {
                    var datetime = ''
                    var datetype = ''
                    if (d.getFullYear() === 1899 && d.getMonth() + 1 === 12 && d.getDate() === 30) {
                      datetype = '%H:%M:%S'
                      // 日期显示1899-12-30说明这边格式是 时间
                      datetime =
                        d
                          .getHours()
                          .toString()
                          .padStart(2, '0') +
                        ':' +
                        d
                          .getMinutes()
                          .toString()
                          .padStart(2, '0') +
                        ':' +
                        d
                          .getSeconds()
                          .toString()
                          .padStart(2, '0')
                    } else if (d.getHours() === 23 && d.getMinutes() === 59 && d.getSeconds() === 17) {
                      // 时间是23:59:17说明显示的是日期
                      datetype = '%Y-%m-%d'
                      datetime =
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0') +
                        '-' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '-' +
                        (d.getDate() + 1)
                          .toString()
                          .padStart(2, '0')
                    } else if (d.getHours() === 0 && d.getMinutes() === 0 && d.getSeconds() === 0) {
                      // 时间是23:59:17说明显示的是日期
                      datetype = '%Y-%m-%d'
                      datetime =
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0') +
                        '-' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '-' +
                        d.getDate()
                          .toString()
                          .padStart(2, '0')
                    } else {
                      // 日期+时间
                      datetype = '%Y-%m-%d %H:%M:%S'
                      datetime =
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0') +
                        '-' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '-' +
                        d
                          .getDate()
                          .toString()
                          .padStart(2, '0') +
                        ' ' +
                        d
                          .getHours()
                          .toString()
                          .padStart(2, '0') +
                        ':' +
                        d
                          .getMinutes()
                          .toString()
                          .padStart(2, '0') +
                        ':' +
                        d
                          .getSeconds()
                          .toString()
                          .padStart(2, '0')
                    }
                    console.log(datetype)
                    out[m.name] = datetime
                    if (i === '4.出生年月日') {
                      console.log(
                        'aa',
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0') +
                          '-' +
                          (d.getMonth() + 1).toString().padStart(2, '0') +
                          '-' +
                          d
                            .getDate()
                            .toString()
                            .padStart(2, '0') +
                          ' ' +
                          d
                            .getHours()
                            .toString()
                            .padStart(2, '0') +
                          ':' +
                          d
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          ':' +
                          d
                            .getSeconds()
                            .toString()
                            .padStart(2, '0')
                      )
                    }
                  }
                }
                break
              case 0: // 数值型,将不是数值型的清空
                if (r[i] !== null && r[i] !== '') {
                  var value1 = Number(r[i]) // 直接数值转换，成功则为纯数字
                  var value2 = parseInt(r[i]) // 对于**分，**岁这种格式的可以将**领出来
                  if (isNaN(value1) && isNaN(value2)) {
                    // 这种说明是字符串
                    out[m.name] = ''
                  } else if (isNaN(value1) && !isNaN(value2)) {
                    out[m.name] = value2
                  } else {
                    out[m.name] = value1
                  }
                } else {
                  out[m.name] = ''
                }
                break
              default:
                // 其他类型如果是null设为空
                if (r[i] && r[i].toString().toUpperCase() === 'NULL') {
                  out[m.name] = ''
                } else {
                  // 文本类型
                  out[m.name] = r[i]
                }
            }
          })

          dataout.push(out)
        })
      }
      return { data: dataout, title: titles }
    },
    // 数组去重
    unique(arr) {
      var obj = {}
      return arr.filter(function(item, index, arr) {
        return obj.hasOwnProperty(typeof item + item) ? false : (obj[typeof item + item] = true)
      })
    },
    // 上传时的校验
    beforeUpload(file) {
      console.log('%c 🥧 file: ', 'font-size:20px;background-color: #42b983;color:#fff;', file)
      this.error = false
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('文件大小不得超过10M！')
        this.error = true
        return false
      }
      this.fileList.splice(0, 1, file)
      return false
    }
  }
}
</script>