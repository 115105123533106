<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index" style="margin-bottom: 10px">
        <a-table
          ref="tables,3"
          v-if="table"
          :showHeader="table.showHeader"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
          <div slot="title">
            <div style="text-align: center">正态检验</div>
          </div>
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="text2"></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <a-row>
        <a-col v-for="(img, index) in imgs" :key="index" :span="12">
          <MyImg ref="imgs,6" :imgBytes="img" :fileName="imgFileNameAry[index]"></MyImg>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
import { getPictureSize } from '@/utils/util'

export default {
  name: 'Resid',
  props: ['resid', 'yName', 'parentType'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'Resid',
      resid: this.resid,
      y_name: this.yName,
      style: { language: this.$store.state.user.info.setting.language },
      ...getPictureSize(this.parentType, 45)
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '残差的正态检验',
          msg: res
        })
      } else {
        this.tables = res.tables
        this.imgs = res.imgs
        // 取到表格中的p值，并且传递出去
        const p = this.tables[0].data[0].p
        this.$emit('resid_norm_p', p)
        // 取到表格中的算法名字，并且传递出去
        const method_name = this.tables[0].data[0].method
        this.$emit('method_name', method_name)
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: [],
      imgsLayout: 2,
      imgFileNameAry: ['残差序列散点图', '残差序列直方图', '标准化残差直方图', '标准化残差PP图']
    }
  }
}
</script>
