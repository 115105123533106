<template>
  <page-header-wrapper :breadcrumb="null">
    <template v-slot:extra>
      <Sample />
    </template>
    <div>
      <a-layout class="divContent" id="divContent">
        <a-row>
          <a-col
            :span="4"
            style="border-left: 1px solid rgb(232, 232, 232); border-right: 1px solid rgb(232, 232, 232)"
          >
            <field :from="'hypothesis'"></field>
          </a-col>
          <a-col :span="20" id="hypContent">
            <div style="position: absolute; height: 100%; width: 100%; padding: 20px; overflow: auto">
              <a-row>
                <a-col :span="24">
                  <a-button type="primary" @click="onClick" style="margin-bottom: 10px">生成智能报告</a-button>
                  <a-button style="margin: 0 10px" @click="reset" :disabled="this.load.anysisLoading">重置</a-button>
                  <Settings ref="settings" :parentType="typeOfFilter" />
                  <a-spin style="margin-left: 10px" v-if="load.anysisLoading"></a-spin>
                </a-col>
              </a-row>
              <div>
                <div class="part">
                  <a-row>
                    <a-col :span="24">
                      <h1>智能分析</h1>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" style="margin-top: 10px">
                    <a-col :span="24">
                      <a-form-model ref="ruleForm1" :model="form1">
                        <ReportDrops
                          :item="item3"
                          :vuex="'report'"
                          :typeSelectOptions="typeSelectOptions"
                          :load="load"
                        />
                      </a-form-model>
                    </a-col>
                    <!-- <a-col :span="8">
                      <a-row>
                        <a-col :span="24" class="strong-font">研究方向</a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="24">
                          <a-row v-for="(obj, index) in typeSelectOptions" :key="index">
                            <a-col :span="24" style="height: 42px; display: flex; align-items: center">
                              <a-select
                                v-model="item3.settings[index][0].value2"
                                mode="multiple"
                                :maxTagCount="2"
                                :maxTagTextLength="8"
                                placeholder="暂无研究方向"
                                style="width: 100%"
                                option-label-prop="label"
                                :disabled="obj.options.length === 1"
                              >
                                <a-select-option v-for="v in obj.options" :key="v.value" :label="v.label">{{
                                  v.text
                                }}</a-select-option>
                              </a-select>
                            </a-col>
                          </a-row>
                        </a-col>
                      </a-row>
                    </a-col> -->
                  </a-row>
                </div>
                <div class="part">
                  <a-row>
                    <a-col :span="24">
                      <h1>基础分析</h1>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" style="margin-top: 10px">
                    <a-col :span="16">
                      <a-form-model ref="ruleForm2" :model="form2">
                        <Drops :item="item2" :vuex="'report'" :load="load" />
                      </a-form-model>
                    </a-col>
                  </a-row>
                </div>
                <div class="part">
                  <a-row>
                    <a-col :span="24">
                      <h1>基础统计</h1>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" style="margin-top: 10px">
                    <a-col :span="16">
                      <a-row type="flex">
                        <a-col flex="50px" class="center"></a-col>
                        <a-col :span="21">
                          <a-row type="flex" justify="center">
                            <a-col :span="22">
                              <Drop
                                :item="item1"
                                :index="0"
                                :parentType="'drop'"
                                :load="load"
                                :indexGroup="indexSettings1[0]"
                                :xysName="'drop1'"
                                :vuex="'report'"
                              ></Drop>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col flex="25px" class="center"> </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-layout>
    </div>
  </page-header-wrapper>
</template>
<script>
import Sample from '@/views/customchart/components/sample.vue'
import Field from '@/views/customchart/components/field'
import ReportDrops from '@/components/myComponents/reportDrops.vue'
import Drop from '@/components/myComponents/drop'
import Drops from '@/components/myComponents/drops'
import Settings from './components/settings'
import store from '@/store'
import { settings1, settings2, settings3 } from './dropSettings'
import { AutoHeight } from '@/utils/util'
import DataClass from './utils/DataClass'
import { DataFilterClass } from '@/utils/dataFilterClass'
import VarianceClass from '@/views/report/utils/VarianceClass'
import MeansClass from '@/views/report/utils/MeansClass'

export default {
  name: 'Report',
  components: { Sample, Field, ReportDrops, Drops, Drop, Settings },
  created() {
    this.autoHeightChange()
    this.$store.dispatch('resetDropGroups3', this.$deepClone(this.item3.addXys))
    this.$store.dispatch('resetDropGroups2', this.$deepClone(this.item2.addXys))
    this.$store.dispatch('resetDrop1')
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'intelligenceReports') {
      store.dispatch('keep_alive')
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'reportPrint') {
      this.$store.dispatch('no_keep_alive')
    }
    this.$notification.destroy()
    next()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  data() {
    return {
      form1: {},
      form2: {},
      indexSettings1: this.$deepClone(settings1),
      item1: this.$deepClone(settings1[0]),
      item2: this.$deepClone(settings2[0]),
      item3: this.$deepClone(settings3[0]),
      load: { anysisLoading: false }
    }
  },
  methods: {
    async onClick() {
      this.$store.state.report.errorMsgs = []
      let valid1
      let valid2
      // 校验智能分析
      await this.$refs.ruleForm1.validate(valid => {
        valid1 = valid
      })
      // 校验基础分析
      await this.$refs.ruleForm2.validate(valid => {
        valid2 = valid
      })
      const part3List = this.item3.settings.filter(group => group[0].list.length > 0 || group[1].list.length > 0)
      const part2List = this.item2.settings.filter(group => group[0].list.length > 0 || group[1].list.length > 0)
      const part1List = this.$deepClone(this.item1.list)
      // 如果校验通过并且拖选框里存在变量并且所拖的变量都有研究方向
      if (valid1 && valid2 && (part3List.length > 0 || part2List.length > 0 || part1List.length > 0) && this.typeOfFilter.every(v => v && v.length > 0)) {
        const datas = {
          part1: {},
          part2: {}
        }
        const xys = this.$store.state.report.dropGroups3.filter(group => group[0].length > 0 || group[1].length > 0)
        // 由于一组变量可能选择多个研究方向，因此要把xys和type重新根据研究方向扁平化一下。
        const { new_xys, type } = this.transferXysAndType(part3List, xys)
        // 这个xys2是基础分析和基础统计中需要的参数
        const xys2 = part3List
        // 生成data
        const dataClass = new DataClass(new_xys, type, xys2)
        const data = dataClass.main()
        // 把part3需要的变量赋值进去
        const part3 = { new_xys, xys2, type, data, settings: this.$refs.settings.settings }
        datas.part3 = part3
        datas.part2.xys2 = part2List
        datas.part2.data = this.getPart2Data()
        datas.part1.xys2 = part1List
        // 数据审查，防止report页面报错
        const expMsgs = this.handleExceptions(datas)
        // 消除之前的异常弹框
        this.$notification.destroy()
        // 如果没有异常消息
        if (expMsgs.length === 0) {
          // 把生成的数据传到store里去
          this.$store.dispatch('update_data', datas)
          this.$router.push('/report/Print')
        } else {
          // 弹出错误消息框
          this.$notification.error({
            message: `样本数据质量不佳`,
            description: () => {
              return (expMsgs.map((v, i) => <p style="color:red">{i + 1}. {v}</p>))
            },
            style: {
              width: '700px',
              marginLeft: `${335 - 700}px`
            },
            placement: `bottomRight`,
            duration: 0
          })
        }
      } else {
        this.$message.error('请拖入正确的变量')
      }
    },
    handleExceptions(data) {
      const exceptionsAry = []
      const part3 = data.part3
      // 取到包含当前页面研究方向的数组
      const typeAry = part3.type
      typeAry.forEach((typeObj, typeIndex) => {
        const key = typeObj.value
        const typeData = part3.data[typeIndex]
        const typeText = typeObj.text
        const typeValue = typeObj.value
        let object
        switch (key) {
          case 'variance':
            object = new VarianceClass(typeData, typeText, typeValue)
            break
          case 'means':
            object = new MeansClass(typeData, typeText, typeValue)
        }
        if (object) object.getDataExceptions(exceptionsAry)
      })
      return exceptionsAry
    },
    transferXysAndType(list, xys) {
      const type = []
      list.forEach((group, index) => {
        const value = group[0].value2
        // 根据value给type数组添加对象
        const options = this.typeSelectOptions.filter(v => v.options.length)[index].options
        console.log('%c 🍾 this.typeSelectOptions: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', this.typeSelectOptions);
        value.forEach(v => {
          const text = options.length > 0 ? options.find(o => o.value === v).text : ''
          type.push({
            value: v,
            text
          })
        })
        // 如果value中有多个值，那么xys里的数组也要同时更换成多个
        if (value.length > 1) {
          const to_add_list = []
          value.forEach(v => {
            const clone_xys_group = this.$deepClone(xys[index])
            to_add_list.push(clone_xys_group)
          })
          xys.splice(index, 1, ...to_add_list)
        }
      })
      return { new_xys: xys, type }
    },
    getPart2Data() {
      const list = []
      const xys = this.$store.state.report.dropGroups2.filter(group => group[0].length > 0 && group[1].length > 0)
      xys.forEach(group => {
        const groupList = []
        group.forEach(drop => {
          drop.forEach(item => {
            if (groupList.indexOf(item) === -1) groupList.push(item)
          })
        })
        list.push(groupList)
      })
      const documents = list.map(gList => new DataFilterClass(gList, true).main())
      return documents
    },
    reset() {
      console.log('执行了reset')
      this.resetForm1()
      this.resetForm2()
      this.resetForm3()
    },
    resetForm3() {
      this.item3 = this.$deepClone(settings3[0])
      this.$store.dispatch('resetDropGroups3', this.$deepClone(this.item3.addXys))
    },
    resetForm2() {
      this.item2 = this.$deepClone(settings2[0])
      this.$store.dispatch('resetDropGroups2', this.$deepClone(this.item2.addXys))
    },
    resetForm1() {
      this.item1 = this.$deepClone(settings1[0])
      this.$store.dispatch('resetDrop1')
    },
    get_index_of_selected_options_of_settings() {
      const obj = {
        chiSquare() {

        }
      }
      return obj
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var min = ['divContent', 'hypContent', 'field']
      var max = ['method', 'field', 'hypContent', 'childContent']
      AutoHeight(this, min, max, 176)
    }
  },
  computed: {
    // 根据每组拖选的变量，生成对应的选择器中的options，比如拖了两个变量，有可能是均值分析，也有可能是线性回归。
    typeSelectOptions() {
      const result = []
      this.$store.state.report.dropGroups3.forEach((group, index) => {
        const list = DataClass.statisticalVariable(group)
        const x = group[0]
        const y = group[1]
        const xNum = list[0]
        const yNum = list[1]
        // 根据自变量X生成由变量组成的字符串
        const x_str = x.map(v => v instanceof Array ? v[v.length - 1] : v).join('、')
        const y_str = y.map(v => v instanceof Array ? v[v.length - 1] : v).join('、')
        const obj = {
          options: []
        }
        if (xNum.类 === 1 && xNum.量 === 0 && xNum.numOfOptions > 2 && yNum.类 === 0 && yNum.量 === 1) {
          obj.options = [{
            text: `不同${x_str}与${y_str}的单因素方差分析`,
            value: 'variance',
            label: '单因素方差分析'
          }]
        } else if (xNum.类 === 2 && xNum.量 === 0 && yNum.类 === 0 && yNum.量 === 1) {
          obj.options = [{
            text: `不同${x_str}与${y_str}的双因素方差分析（开发中）`,
            value: 'doubleVariance',
            label: '双因素方差分析（开发中）'
          }]
        } else if (xNum.类 === 1 && xNum.量 === 0 && xNum.numOfOptions === 2 && yNum.类 === 0 && yNum.量 === 1) {
          obj.options = [{
            text: `不同${x_str}与${y_str}的位置分析`,
            value: 'means',
            label: '位置分析'
          }]
        } else if (xNum.类 === 1 && xNum.量 === 0 && yNum.类 === 1 && yNum.量 === 0) {
          obj.options = [{
            text: `${x_str}与${y_str}的卡方检验`,
            value: 'chiSquare',
            label: '卡方检验'
          },
          {
            text: `${x_str}与${y_str}的逻辑回归`,
            value: 'logic',
            label: '逻辑回归'
          }
          ]
        } else if (xNum.类 === 0 && xNum.量 === 1 && yNum.类 === 0 && yNum.量 === 1) {
          obj.options = [{
            text: `${x_str}与${y_str}的位置分析`,
            value: 'position_for_two_liang',
            label: '位置分析'
          }, {
            text: `${x_str}与${y_str}的最小二乘回归`,
            value: 'ordinaryLeastSquares',
            label: '最小二乘回归'
          }, {
            text: `${x_str}与${y_str}的广义最小二乘回归`,
            value: 'generalizedOrdinaryLeastSquares',
            label: '广义最小二乘回归'
          }, {
            text: `${x_str}与${y_str}的鲁棒线性回归`,
            value: 'robustRegression',
            label: '鲁棒线性回归'
          }]
        } else if (xNum.量 >= 1 && yNum.量 === 1 && yNum.类 === 0) {
          obj.options = [{
            text: `${x_str}与${y_str}的最小二乘回归`,
            value: 'ordinaryLeastSquares',
            label: '最小二乘回归'
          }, {
            text: `${x_str}与${y_str}的广义最小二乘回归`,
            value: 'generalizedOrdinaryLeastSquares',
            label: '广义最小二乘回归'
          }, {
            text: `${x_str}与${y_str}的鲁棒线性回归`,
            value: 'robustRegression',
            label: '鲁棒线性回归'
          }]
        } else if ((xNum.量 >= 1 || xNum.类 >= 1) && yNum.类 === 1 && yNum.量 === 0 && yNum.numOfOptions === 2) {
          obj.options = [{
            text: `${x_str}与${y_str}的逻辑回归`,
            value: 'logic',
            label: '逻辑回归'
          }]
        } else {
        }

        result.push(obj)
      })
      return result
    },
    itemsOfSettings2() {
      const ary = []
      this.item2.settings.forEach(group => {
        group.forEach(drop => {
          drop.list.forEach(item => {
            if (ary.findIndex(v => v.name === item.name) === -1) {
              item = this.$deepClone(item)
              item.mark = true
              ary.push(item)
            }
          })
        })
      })
      return ary
    },
    // 原始的研究方向数组
    typeOfFilter() {
      const part3List = this.item3.settings.filter(group => group[0].list.length > 0 && group[1].list.length > 0)
      // 研究方向的值是存在group里面的
      return part3List.map(group => group[0].value2)
    }
  },
  watch: {
    // 根据智能分析的变量组，生成变量组到基础分析中去。
    typeSelectOptions(newValue, oldValue) {
      newValue.forEach((v, i) => {
        // 如果选择框里有选项，并且没有值，那么默认赋值第一个选项
        if (v.options.length > 0) {
          this.item3.settings[i][0].value2 = [v.options[0].value]
          // 如果选择框里没有值，并且当前有值，那么清空当前值
        } else if (v.options.length === 0 && this.item3.settings[i][0].value2) {
          this.item3.settings[i][0].value2 = undefined
        }
      })
      const settings3 = this.$deepClone(this.item3.settings)
      const settings2 = this.item2.settings
      // 把第三部分中有研究方向的几组都过滤出来
      const newSettings3 = settings3.filter((group, index) => group[0].value2)
      // 把第二部分中不带mark标记的过滤出来
      const newSettings2 = settings2.filter(group => !('mark' in group[0]))
      //
      const settingListBy3 = []
      // 遍历第三部分
      newSettings3.forEach(group => {
        // 遍历第三部分中的每一组的自变量
        group[0].list.forEach(param => {
          // 如果自变量中的变量和因变量中的变量配对组在settingListBy3中找不到，那么构造好新的group添加进去
          if (!settingListBy3.find(group2 => group2[0].list[0].name === param.name && group2[1].list[0].name === group[1].list[0].name)) {
            const new_group = this.$deepClone(group)
            new_group[0].list = [this.$deepClone(param)]
            settingListBy3.push(new_group)
          }
        })
      })
      settingListBy3.forEach(group => {
        group[0].mark = true
        group[1].mark = true
      })
      // 将新过滤的第二部分和新过滤的第三部分合并成最终的第二部分。
      this.item2.settings = settingListBy3.concat(newSettings2)
      // 根据第二部分的settings生成新的xys赋值到store里去。
      const dropGroups2 = this.item2.settings.map(group => {
        return group.map(drop => {
          return drop.list.map(item => {
            if (item.type === '量') {
              return item.name
            } else {
              const ary = this.$deepClone(item.options)
              ary.push(item.name)
              return ary
            }
          })
        })
      })
      this.$store.dispatch('updateDropGroups', dropGroups2)
    },
    // 根据基础分析中的变量组，生成变量到基础统计中去。
    itemsOfSettings2(newValue) {
      const list = this.item1.list.filter(item => !('mark' in item))
      this.item1.list = newValue.concat(list)
      const drop1 = this.item1.list.map(item => {
        if (item.type === '量') {
          return item.name
        } else {
          const ary = this.$deepClone(item.options)
          ary.push(item.name)
          return ary
        }
      })
      this.$store.dispatch('updateDrop1', [drop1])
    }
  }
}
</script>
<style lang="less">
.part {
  padding: 15px 0;
}

.dropDiv {
  height: auto;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center;
}
h1 {
  font-size: 16px;
  padding: 0 0 0 20px;
}
</style>