export const settings = [{
    name: '方差分析',
    value: 'variance',
    options: {
      flows: [{
        label: '差异显著性检验方法',
        value: 'auto',
        type: 'radio',
        options: [{
            text: '自动',
            value: 'auto'
          },
          {
            text: '参数检验',
            value: 'anova'
          },
          {
            text: '非参数检验',
            value: 'none_param'
          }
        ]
      }],
      methods: [{
          name: '正态检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Shapiro-Wilktest检验',
                value: 'Shapiro-Wilktest'
              },
              {
                text: 'Kolmogorov-Smirnov检验',
                value: 'Kolmogorov-Smirnov'
              },
              {
                text: 'Lilliefors检验',
                value: 'Lilliefors'
              }
            ]
          }]
        },
        {
          name: '方差齐性检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Bartlett检验',
                value: 'Bartlett'
              },
              {
                text: 'Levene检验',
                value: 'Levene'
              }
            ]
          }]
        },
        {
          name: '参数检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Welch检验',
                value: 'Welch'
              },
              {
                text: 'Anova检验',
                value: 'Anova'
              }
            ]
          }]
        },
        {
          name: '事后检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Games-Howell检验',
                value: 'Games-Howell'
              },
              {
                text: 'Turkey检验',
                value: 'Turkey'
              },
              {
                text: 'Scheffe检验',
                value: 'Scheffe'
              }
            ]
          }]
        }
      ]
    }
  },
  {
    name: '双因素方差分析(开发中)',
    value: 'doubleVariance',
    options: {
      flows: [],
      methods: [{
          name: '正态检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Shapiro-Wilktest检验',
                value: 'Shapiro-Wilktest'
              },
              {
                text: 'Kolmogorov-Smirnov检验',
                value: 'Kolmogorov-Smirnov'
              },
              {
                text: 'Lilliefors检验',
                value: 'Lilliefors'
              }
            ]
          }]
        },
        {
          name: '方差齐性检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Bartlett检验',
                value: 'Bartlett'
              },
              {
                text: 'Levene检验',
                value: 'Levene'
              }
            ]
          }]
        }
      ]
    }
  },
  {
    name: '位置分析',
    value: 'means,position_for_two_liang',
    options: {
      flows: [{
        label: '位置检验方法',
        value: 'auto',
        type: 'radio',
        options: [{
            text: '自动',
            value: 'auto'
          },
          {
            text: 't检验',
            value: 't'
          },
          {
            text: 'z检验',
            value: 'z'
          },
          {
            text: '非参数检验',
            value: 'none_param'
          }
        ]
      }],
      methods: [{
          name: '正态检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Shapiro-Wilktest检验',
                value: 'Shapiro-Wilktest'
              },
              {
                text: 'Kolmogorov-Smirnov检验',
                value: 'Kolmogorov-Smirnov'
              },
              {
                text: 'Lilliefors检验',
                value: 'Lilliefors'
              }
            ]
          }]
        },
        {
          name: '方差齐性检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Bartlett检验',
                value: 'Bartlett'
              },
              {
                text: 'Levene检验',
                value: 'Levene'
              },
              {
                text: 'F检验',
                value: 'F'
              }
            ]
          }]
        },
        {
          name: '事后检验',
          args: [{
            name: '方法',
            type: 'radio',
            value: 'auto',
            options: [{
                text: '自动',
                value: 'auto'
              },
              {
                text: 'Games-Howell检验',
                value: 'Games-Howell'
              },
              {
                text: 'Turkey检验',
                value: 'Turkey'
              },
              {
                text: 'Scheffe检验',
                value: 'Scheffe'
              }
            ]
          }]
        }
      ]
    }
  },
  {
    name: '卡方检验',
    value: 'chiSquare',
    options: {
      flows: [{
        label: '算法',
        value: 'auto',
        type: 'radio',
        options: [{
            text: '自动',
            value: 'auto'
          },
          {
            text: 'Pearson检验',
            value: 'Pearson'
          },
          {
            text: 'yates检验',
            value: 'yates'
          }
        ]
      }],
      methods: []
    }
  },
  {
    name: '最小二乘回归',
    value: 'ordinaryLeastSquares',
    options: {
      methods: [{
        name: '最小二乘回归',
        args: [{
          name: '方法',
          type: 'radio',
          value: 'pinv',
          options: [{
              text: 'Moore-Penrose伪逆',
              value: 'pinv'
            },
            {
              text: 'QR分解',
              value: 'qr'
            }
          ]
        }]
      }],
      flow: []
    }
  },
  {
    name: '广义最小二乘回归',
    value: 'generalizedOrdinaryLeastSquares',
    options: {
      methods: [{
        name: '广义最小二乘回归',
        args: [{
          name: '方法',
          type: 'radio',
          value: 'pinv',
          options: [{
              text: 'Moore-Penrose伪逆',
              value: 'pinv'
            },
            {
              text: 'QR分解',
              value: 'qr'
            }
          ]
        }]
      }],
      flow: []
    }
  },
  {
    name: '鲁棒线性回归',
    value: 'robustRegression',
    options: {
      methods: [{
        name: '鲁棒线性回归',
        args: [{
          name: '协方差',
          type: 'radio',
          value: 'H1',
          options: [{
              text: 'H1',
              value: 'H1'
            },
            {
              text: 'H2',
              value: 'H2'
            },
            {
              text: 'H3',
              value: 'H3'
            }
          ]
        }]
      }],
      flow: []
    }
  }
]