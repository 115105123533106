var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      attrs: { breadcrumb: null },
      scopedSlots: _vm._u([
        {
          key: "extra",
          fn: function() {
            return [_c("sample")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingController,
            expression: "loadingController"
          }
        ]
      }),
      _c(
        "a-layout",
        { staticClass: "divContent", attrs: { id: "divContent" } },
        [
          _c(
            "a-row",
            { staticStyle: { width: "100% !important" } },
            [
              _c("a-col", { attrs: { span: 5 } }, [_c("method")], 1),
              _c(
                "a-col",
                {
                  staticStyle: {
                    overflow: "auto",
                    "border-left": "1px solid rgb(232, 232, 232)"
                  },
                  attrs: { span: 19, id: "resultContent" }
                },
                [
                  _c(_vm.currentComponent, {
                    key: _vm.key,
                    tag: "component",
                    on: {
                      hideLoading: function($event) {
                        _vm.loadingController = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }