<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <a-table
        ref="tables,2"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        :settings="table.settings"
        size="small"
      >
        <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'

export default {
  name: 'Z',
  props: ['data', 'lei', 'isHomogeneous'],
  created() {
    const { a, data, lei, isHomogeneous } = this
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'Z',
      data,
      lei,
      isHomogeneous,
      a
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: 'z检验',
          msg: res
        })
      } else {
        this.imgs = res.imgs
        const tables = res.tables
        // 传递P值出去
        const ary = this.getPList(tables)
        this.$emit('isMeanEqual', ary)
        tables.forEach(table => {
          // 合并统计指标
          table.columns.forEach((column, i) => {
            if (i === 0 || i === 2 || i === 3 || i === 4) {
              column.customRender = (text, record, index) => {
                const obj = {
                  children: typeof (text) === 'string' ? text : this.$options.filters.round(text),
                  attrs: {}
                }
                if (index === 0) {
                  obj.attrs.rowSpan = table.data.length
                } else {
                  obj.attrs.rowSpan = 0
                }
                return obj
              }
            }
          })
        })
        this.getMergeSetting(tables)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  methods: {
    getPList(tables) {
      return tables.map(table => table.data.slice(-1)[0].p)
    },
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      tables[0].settings = [[], [], [], []]
      const columnAry = [0, 2, 3, 4]
      columnAry.forEach((v, i) => {
        for (let index = 1; index <= table_row_num; index++) {
          tables[0].settings[i].push([index, v])
        }
      })
    }
  },
  computed: {
    a() { return this.$store.state.report.a }
  }
}
export function get_text_z(name_of_sample, flow_value) {
  /** 获取方差齐性检验中文字的描述
   * @param name_of_sample 样本的名字，例如“不同文化程度中年龄的样本”
   * @param flow_value 流程的值
   */
  const text_end = `<br/><span class="H0H1">H0：${name_of_sample}均值相等。</span><br/><span class="H0H1">H1：${name_of_sample}均值不相等。</span><br>检验结果如下表所示：`
  let text
  if (flow_value === 'auto') {
    text = `由于${name_of_sample}数量都 ≥ 30，因此采用z检验，`
  } else {
    text = `采用z检验进行均值分析，`
  }
  return text + text_end
}
</script>