<template>
  <div>
    <div class="third-module">
      <slot name="text1"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index" style="margin-bottom: 10px">
        <a-table
          v-if="table"
          ref="tables,2"
          :title="() => table.title"
          :showHeader="table.showHeader"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="text2" :formula="formula"></slot>
    </div>
    <div class="third-module">
      <slot name="text3"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables2" :key="index" style="margin-bottom: 10px">
        <a-table
          v-if="table"
          ref="tables,5"
          :showHeader="false"
          :pagination="false"
          :title="() => table.title"
          :dataSource="table.data"
          :columns="table.columns"
          :settings="table.settings"
          size="small"
        >
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="text4"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables3" :key="index" style="margin-bottom: 10px">
        <a-table
          v-if="table"
          ref="tables,7"
          :pagination="false"
          :title="() => table.title"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <a-row v-for="(img, index) in rocImg" :key="index">
        <MyImg ref="imgs,8" :imgBytes="img" :fileName="'roc图'"></MyImg>
      </a-row>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
import { getPictureSize, change_to_percent } from '@/utils/util'
import { translate } from '@/utils/language'
import ChartClass from '@/commonmodules/chart'

export default {
  name: 'Logic',
  props: ['data', 'xys', 'settings'],
  data() {
    return {
      tables: [],
      imgs: [],
      formula: null,
      tables2: [
        {
          title: '混淆矩阵表',
          settings: null,
          columns: [
            {
              title: '',
              dataIndex: 'col1',
              align: 'center',
              customRender: (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([0, 5].includes(index)) {
                  obj.attrs.colSpan = 2
                }
                if ([0].includes(index)) {
                  obj.attrs.rowSpan = 3
                }
                if ([3].includes(index)) {
                  obj.attrs.rowSpan = 2
                }
                if ([1, 2, 4].includes(index)) {
                  obj.attrs.rowSpan = 0
                  obj.attrs.colSpan = 0
                }
                return obj
              }
            },
            {
              title: '',
              dataIndex: 'col2',
              align: 'center',
              customRender: (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([0, 1, 2, 5].includes(index)) {
                  obj.attrs.rowSpan = 0
                  obj.attrs.colSpan = 0
                }
                return obj
              }
            },
            {
              title: '',
              dataIndex: 'col3',
              align: 'center',
              customRender: (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([1].includes(index)) {
                  obj.attrs.colSpan = 2
                }
                if ([0].includes(index)) {
                  obj.attrs.colSpan = 3
                }
                return obj
              }
            },
            {
              title: '',
              dataIndex: 'col4',
              align: 'center',

              customRender: (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([0, 1].includes(index)) {
                  obj.attrs.rowSpan = 0
                  obj.attrs.colSpan = 0
                }
                return obj
              }
            },
            {
              title: '',
              dataIndex: 'col5',
              align: 'center',
              customRender: (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if ([1].includes(index)) {
                  obj.attrs.rowSpan = 2
                }
                if ([0, 2].includes(index)) {
                  obj.attrs.rowSpan = 0
                  obj.attrs.colSpan = 0
                }
                return obj
              }
            }
          ]
        },
        {
          title: '评价指标表',
          showHeader: false,
          settings: null,
          columns: [
            {
              title: '',
              dataIndex: 'col1',
              align: 'center'
            },
            {
              title: '',
              dataIndex: 'col2',
              align: 'center'
            },
            {
              title: '',
              dataIndex: 'col3',
              align: 'center'
            },
            {
              title: '',
              dataIndex: 'col4',
              align: 'center'
            }
          ],
          data: []
        }
      ],
      tables3: [{ title: 'ROC结果相关汇总表', columns: [], data: [] }],
      rocImg: []
    }
  },
  async created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    await getModule({ module_name: 'Logic', data: { ...this.data, ...getPictureSize('report', 50) } }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '逻辑回归',
          msg: res
        })
      } else {
        this.tables = res.tables
        // 把原表中带有dw值的表格去除
        res.tables.splice(2, 1)
        const table = res.tables[1]
        // 生成线性回归后的方程式
        this.get_formula(table)
        // 从res中取出相应表格3中需要的值，并且构造表格。
        this.makeTable3(res)
        this.makeTable4(res)
        this.getMergeSettings(this.tables2)
      }
    })
    await this.getRocPicture(this.data)
    this.$store.dispatch('addReqNumOfFactOfReportPage')
  },
  methods: {
    get_formula(table) {
      const y_name = this.xys[1][0].slice(-1)[0]
      // let str_formula = `${y_name} ＝ `
      let str_formula = `ln(p/(1-p)) ＝ `
      table.data.forEach((row, row_index) => {
        if (row_index === 0) {
          str_formula = `${str_formula}${row[translate('系数')].trim()}`
        } else {
          str_formula = `${str_formula}${+row[translate('系数')] > 0 ? ' ＋ ' : ' － '}${Math.abs(+row[translate('系数')])} × ${row[translate('变量')].trim()}`
        }
      })
      this.formula = str_formula + `（其中p表示为${y_name}被预测为${this.data.yAs1}的概率）`
    },
    makeTable3(res) {
      const table3 = this.tables2[0]
      const { y_name, predAry, key_value_map } = res.result
      const leftTop = predAry[0][0]
      const rightTop = predAry[0][1]
      const leftBottom = predAry[1][0]
      const rightBottom = predAry[1][1]
      const percentTop = (leftTop * 100 / (leftTop + rightTop)).toFixed(1) + '%'
      const percentMiddle = (rightBottom * 100 / (leftBottom + rightBottom)).toFixed(1) + '%'
      const percentBottom = (((leftTop + rightBottom) * 100) / (leftTop + rightTop + leftBottom + rightBottom)).toFixed(1) + '%'
      const data = [
        {
          key: 0,
          col1: translate('观测值'),
          col3: translate('预测值')
        },
        {
          key: 1,
          col3: y_name,
          col5: translate('百分比正确')
        },
        {
          key: 2,
          col3: key_value_map['0'],
          col4: key_value_map['1']
        },
        {
          key: 3,
          col1: y_name,
          col2: key_value_map['0'],
          col3: leftTop,
          col4: rightTop,
          col5: percentTop
        },
        {
          key: 4,
          col2: key_value_map['1'],
          col3: leftBottom,
          col4: rightBottom,
          col5: percentMiddle
        },
        {
          key: 5,
          col1: translate('准确率'),
          col5: percentBottom
        }
      ]
      table3.data = data
    },
    makeTable4(res) {
      const table4 = this.tables2[1]
      const { predAry } = res.result
      const TN = predAry[0][0]
      const FP = predAry[0][1]
      const FN = predAry[1][0]
      const TP = predAry[1][1]
      // const P = TP + FN
      // const N = FP + TN
      const TPR = change_to_percent(TP, TP + FN)
      const FPR = change_to_percent(FP, FP + TN)
      const FNR = change_to_percent(FN, FN + TP)
      const TNR = change_to_percent(TN, FP + TN)
      const data = [
        {
          key: 0,
          col1: translate('灵敏度'),
          col2: TPR,
          col3: translate('假阳率'),
          col4: FPR
        },
        {
          key: 1,
          col1: translate('假阴率'),
          col2: FNR,
          col3: translate('特异度'),
          col4: TNR
        }
      ]
      table4.data = data
    },
    async getRocPicture(req) {
      // 由于代码耦合，只能在调用ChartClass的文件里加一个roc的变量，并且赋值分类的名字，例如“男”
      this.roc = req.yAs1
      await new ChartClass(
        JSON.parse(req.rocData),
        {
          ...req.rocArgs,
          change: false,
          isdownload: false
        }, 'roc', {}, []
      ).main(this, undefined).then(res => {
        this.rocImg.push(res.imgs[0].src)
        this.tables3[0].columns = res.columns
        this.tables3[0].data = JSON.parse(res.tabdata)
      })
    },
    getMergeSettings(tables) {
      const leiOptionsLength = this.xys[1][0].length - 1
      tables[0].settings = [[[0, 0], [0, 1]], [[1, 0], [1, 1]], [[2, 0], [2, 1]], [[0, 0], [1, 0], [2, 0]], [[1, 4], [2, 4]], [], [], [], []]
      // 横向合并预测值
      for (let index = 2; index <= 2 + leiOptionsLength; index++) {
        tables[0].settings[5].push([0, index])
      }
      // 横向合并y中的定类名字
      for (let index = 2; index < 2 + leiOptionsLength; index++) {
        tables[0].settings[6].push([1, index])
      }
      // 竖向合并y中的定类名字
      for (let index = 3; index < 3 + leiOptionsLength; index++) {
        tables[0].settings[7].push([index, 0])
      }
      // 横向合并准确率
      for (let index = 0; index <= leiOptionsLength + 1; index++) {
        tables[0].settings[8].push([5, index])
      }
    }
  }
}
</script>