import store from '@/store/index'
const LANGUAGE_SETTINGS = {
  1: {
    置信区间: 'Confidence Interval',
    置信度: 'α',
    频数观测值: 'Frequency Observations',
    频数预测值: 'Predicted Value Of Frequency',
    频数预测值比例: 'Ratio Of Frequency Predicted Values',
    系数: 'Coefficient',
    变量: 'Variable',
    '相关性<i>p</i>值': 'P Value Of Correlation',
    观测值: 'Observation Value',
    预测值: 'Predictive Value',
    正确百分比: 'Percentage Correct',
    准确率: 'Accuracy',
    众数: 'Mode',
    灵敏度: 'Sensitivity',
    特异度: 'Specificity',
    假阳率: 'False Positive Rate',
    假阴率: 'False Negative Rate'
  }
}
export function translate(word) {
  const language = store.state.user.info.setting.language
  if (language === 0) return word
  if (word in LANGUAGE_SETTINGS[language]) return LANGUAGE_SETTINGS[language][word]
  // debugger
  return word
}