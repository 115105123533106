<template>
  <div>
    <div v-for="(obj, index) in gp.xys" :key="index" :id="obj.title">
      <div
        :ref="`title,${index+1}`"
        :id="`1.${indexT}.${index + 1} ${obj.title}`"
        class="third-title"
      >
        2.{{ indexT }}.{{ index + 1 }}{{ obj.title }}
      </div>
      <vcchart v-if="gp.type=='vc'"
               class="third-module"
               :ref="`content,${index+1}`"
               :data="obj.data"
               :xys="gp.xys[index]"
               :indexT="index + 1"
               style="padding: 0 25px"
      ></vcchart>
      <ccfield v-if="gp.type=='cc'"
               class="third-module"
               :ref="`content,${index+1}`"
               :data="obj.data"
               :xys="gp.xys[index]"
               :indexT="index + 1"
               :count="gp.xys.length"
               style="padding: 0 25px"
      ></ccfield>
    </div>
  </div>
</template>
<script>

import vcchart from './vcchart'
import ccfield from '../ccfield'
export default {
  components: { vcchart, ccfield },
  props: ['gp', 'indexT']

}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
