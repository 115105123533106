<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <keep-alive include="BasicLayout">
        <router-view />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import store from './store'
import { Add } from './api/sample'
import storage from 'store'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import { addClassForDom } from './utils/util'
function getDPI() {
  var arrDPI = new Array(2)
  if (window.screen.deviceXDPI) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement('DIV');
    tmpNode.style.cssText = 'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden';
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  console.dir(arrDPI);
  return arrDPI;
}

export default {
  data() {
    return {
      zhCN,
      height: document.body.offsetHeight - 186 + 'px !important',
      id: 'divContentleft'
    }
  },
  created() {
    if (!['/', '/personLogin/login', '/user/login'].includes(this.$route.path) && !JSON.parse(localStorage.autoLogin)) {
      window.addEventListener('beforeunload', e => this.beforeunloadFn())
      window.addEventListener('unload', e => this.unloadFn())
    }
  },
  mounted() {
    // window.onbeforeunload = () => { this.saveVuex() }

    // 新增刷新事件
    // window.addEventListener('beforeunload', this.saveVuex)
    const dpiAry = getDPI()
    this.$store.dispatch('setDpi', dpiAry[0])
    this.$store.dispatch('setClientWidth')
    this.handleWatchClient()
    console.log(localStorage.autoLogin)
  },
  methods: {
    handleWatchClient() {
      /**
       * 监听窗口大小变化执行的方法
       */
      window.onresize = () => {
        // 设置窗口的宽度，并存放在vuex里。
        this.$store.dispatch('setClientWidth')
      }
    },
    saveVuex() {
      /**
    * 刷新时存储vuex中的数据
    */
      var samples = store.state.samples
      if (samples.keys !== '') {
        sessionStorage['shuaxin1'] = '刷新' + Date()
        var guid = this.NewGuid().toString().replace(/\s+/g, '').toString()
        sessionStorage['guid'] = guid
        Add({ data: JSON.stringify(samples), guid }).then(res => {
          sessionStorage['shuaxin3'] = '已存' + Date()
        })
      } else {
        sessionStorage['biaoshi'] = '无'
        sessionStorage['guid'] = ''
      }
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    NewGuid() {
      return (
        this.S4() +
        this.S4() +
        ' - ' +
        this.S4() +
        ' - ' +
        this.S4() +
        ' - ' +
        this.S4() +
        ' - ' +
        this.S4() +
        this.S4() +
        this.S4()
      )
    },
    unloadFn() {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      if (this._gap_time <= 5) {
        storage.remove(ACCESS_TOKEN)
      }
    },
    beforeunloadFn() {
      this._beforeUnload_time = new Date().getTime();
    },
    change_layout_margin_by_route() {
      const a = document.getElementsByClassName('ant-pro-basicLayout-content')
      // 如果当前页面有a这个dom。
      if (a.length > 0) {
        if (this.$route.path === '/personHome') {
          a[0].style.margin = '0'
        } else {
          a[0].style.margin = '24px'
        }
      }
    },
    add_nav_title_selected_by_route() {
      const map = [
        {
          navName: '假设检验',
          routeName: 'hypothesis'
        },
        {
          navName: '回归分析',
          routeName: 'analysisOfRegression'
        }
      ]
      console.log('%c 🥫 map: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', map);
      const obj = map.find(v => this.$route.path.includes(v.routeName))
      const domAry = document.getElementsByClassName('ant-menu-item')
      const dom = Array.from(domAry).find(v => v.innerText === obj.navName)
      addClassForDom(dom, ' ant-menu-item-selected')
      const aDom = dom.getElementsByTagName('a')[0]
      addClassForDom(aDom, ' router-link-active')
    }
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        // 根据路径调整框架的margin
        this.change_layout_margin_by_route()
        // this.add_nav_title_selected_by_route()
      })
    }
  }
}
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.strong-font {
  font-weight: 700;
}

.serialNumber {
  font-size: 20px;
}
.helpFontColor {
  // font-size: 8px;
  color: rgb(191, 191, 191);
}
// input::-webkit-input-placeholder {
//   /* placeholder字体大小 */
//   font-size: 8px;
// }
// .ant-menu-item {
//   padding: 0 !important;
// }
.ant-menu {
  display: flex;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.ant-pro-top-nav-header-menu {
  max-width: 1000px !important;
}
.myTag {
  margin: 2.5px 5px !important;
}
</style>