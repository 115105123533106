<template>
  <div>
    <basic-information :isSave="isSave" @refreshTable="$emit('refreshtable')"></basic-information>
    <div class="documentItemWrapper">
      <medical-history-modal
        :isAddMedicalHistory="isAddMedicalHistory"
        @handleCancel="isAddMedicalHistory = false"
        @updateHistoryValue="fetchDocHistory($route.params.id)"
        @updateExamValue="fetchDocExam($route.params.id)"
        :tabController="'history'"
        :tabsExamList="healthData"
        :tabsHistoryList="tabsData"
      ></medical-history-modal>
      <h1 style="float:left">既往史</h1>
      <div class="addTag" @click="handleHistoryAddTag">
        <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        <span class="iconText">添加既往史</span>
      </div>
      <div style="clear:both"></div>
      <medical-history
        :tabsData="tabsData"
        :colLayout="tabsLayout.history"
        :isSave="isSave"
        :tabController="'history'"
        @updateHistoryValue="fetchDocHistory($route.params.id)"
      ></medical-history>
    </div>
    <div class="documentItemWrapper" v-if="false">
      <medicine-modal :isMedicine="isAddMedicine" @handleCancel="isAddMedicine = false"></medicine-modal>
      <h1 style="float:left">用药史</h1>
      <div class="addTag" @click="isAddMedicine = true">
        <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        <span class="iconText">添加用药史</span>
      </div>
      <div style="clear:both"></div>
      <medicine></medicine>
    </div>
    <div class="documentItemWrapper">
      <medical-history-modal
        :isAddMedicalHistory="isAddMedicalHistory"
        @handleCancel="isAddMedicalHistory = false"
        @updateExamValue="fetchDocExam($route.params.id)"
        @updateHistoryValue="fetchDocHistory($route.params.id)"
        :tabController="tabController"
        :tabsExamList="healthData"
        :tabsHistoryList="tabsData"
      ></medical-history-modal>
      <h1 style="float:left">相关检查</h1>
      <div class="addTag" @click="handleExamAddTag">
        <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        <span class="iconText">添加相关检查</span>
      </div>
      <div style="clear:both"></div>
      <medical-history
        :tabsData="healthData"
        :colLayout="tabsLayout.health"
        :isSave="isSave"
        :tabController="'exam'"
        @updateExamValue="fetchDocExam($route.params.id)"
      ></medical-history>
    </div>
    <div style="text-align:center;padding-bottom:30px">
      <a-button type="primary" @click="isSave = !isSave">保存</a-button>
    </div>
  </div>
</template>

<script>
import { Cascader, FormModel } from 'ant-design-vue'
import BasicInformation from './basicInformation'
import MedicalHistory from './medicalHistory.vue'
import MedicalHistoryModal from './medicalHistoryModal'
import Medicine from './medicine'
import MedicineModal from './medicineModal'
import { getHistoryField } from '@/api/historyField'
import { getExamField } from '@/api/examField'

export default {
  created() {
    this.$store.dispatch('clearFinishedReqCount')
    var docId = this.$route.params.id
    this.fetchDocHistory(docId)
    this.fetchDocExam(docId)
  },
  data() {
    return {
      // 控制tab模块显示history还是Exam
      BMI: null,
      tabController: '',
      isAddMedicalHistory: false,
      isAddMedicine: false,
      isSave: false,
      tabsData: null,
      healthData: null,
      tabsLayout: {
        history: 12,
        health: 8
      }
    }
  },
  components: { FormModel, Cascader, MedicalHistory, MedicalHistoryModal, Medicine, MedicineModal, BasicInformation },
  methods: {
    fetchDocHistory(docId) {
      getHistoryField(docId).then(res => {
        this.tabsData = {
          form: res
        }
        console.log('既往史带值的input', res)
      })
    },
    fetchDocExam(docId) {
      getExamField(docId).then(res => {
        console.log('相关检查带值的字段', res)
        this.healthData = {
          form: res.tab
          }
        this.$store.dispatch('saveExamId', res.examList)
      })
    },
    displayHomeRender({ labels }) {
      return labels[labels.length - 1]
    },
    displayHomeNowRender({ labels }) {
      console.log(labels)
      return labels[labels.length - 1]
    },
    // 点击添加图标执行的方法
    handleHistoryAddTag() {
      this.tabController = 'history'
      this.isAddMedicalHistory = true
    },
    handleExamAddTag() {
      this.tabController = 'exam'
      this.isAddMedicalHistory = true
    }
  },
  computed: {
    finishedReqCount() {
      return this.$store.state.document.finishedReqCount
    }
  },
  watch: {
    $route() {
      var docId = this.$route.params.id
      this.fetchDocHistory(docId)
      this.fetchDocExam(docId)
    },
    finishedReqCount(value) {
      if (value === 3) {
        this.$message.success('保存成功', 5)
        this.$store.dispatch('clearFinishedReqCount')
      }
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  line-height: 26px;
  padding: 0 0 0 10px;
  width: 180px;
  background-color: rgb(232, 232, 232);
}
.ant-form-item {
  height: 0px !important;
  line-height: 0 !important;
  margin-bottom: 0;
}
.documentItemWrapper {
  margin-bottom: 30px;
}

.addTag {
  float: left;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
  .iconText {
    margin-left: 5px;
  }
}

.physiqueWrapper > div {
  display: inline-block;
}
.physiqueWrapper > div > .physiqueLeftItem {
  text-align: center;
  border: 1px solid rgb(232, 232, 232);
}
.physiqueNameWrapper {
  margin-right: -1px;
  display: inline-block;
  width: 10%;
  text-align: center;
}
.physiqueName {
  border: 1px solid rgb(232, 232, 232);
  margin-bottom: -1px;
}
.physiqueScore {
  border: 1px solid rgb(232, 232, 232);
}
.ant-input-sm {
  width: 60px;
  height: 15px;
}
.ant-cascader-picker {
  width: 200px !important;
}
</style>
