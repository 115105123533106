import {
  onChange,
  onClose
} from '@/views/hypothesisTest/utils/inputItemsClass'
import {
  computed
} from '@/api/hypothesis'
import {
  deepClone
} from '@/utils/util'
import {
  DataFilterClass
} from '@/utils/dataFilterClass'
var xysName = 'dybksjyXys'

export function fetchParam(that) {
  var liang = that.$store.state.hypothesis[xysName][0].length && that.$store.state.hypothesis[xysName][0][0]
  var obj = that.$hypothesis.findChildren(2, that.settings)
  if (liang && obj) {
    var reqList = []
    obj.children.forEach(v => {
      if (v.parentValue === obj.value) reqList.push(v)
    })
    // 数据过滤
    // 由于这个方法被两个文件调用，所以that指向的对象是不同的
    const xys = that.xys[0].length > 0 ? that.xys : that.computedXys

    var arry = []
    xys.forEach(v => {
      v.forEach(i => {
        arry.push(i)
      })
    })
    var documents = new DataFilterClass(arry, true).main()
    //
    var list = []
    documents.forEach(element => {
      list.push(parseFloat(element[liang]))
    })
    //
    // 向后端发送请求
    computed({
      reqList,
      list
    }).then(res => {
      var resultList = res.resultList
      // 由于fetchParam这个方法在index页面和dropItems里都调用了，因此that的指向不同，所以一个用$emit，一个直接用updateForm
      reqList.forEach((m, index) => {
        // 如果是拖动变量到框里触发这个事件，执行下面语句
        if (that.xys[0].length > 0) {
          that.$emit('updateForm', m.prop, resultList[index])
        } else {
          // 如果是点了离群值自动处理或者去零处理触发这个事件
          that.updateForm(m.prop, resultList[index])
        }
        m.value = resultList[index]
      })
    })
  }
}

function resetDivHelp(that) {
  var indexObj = that.$hypothesis.findChildren(2, deepClone(that.indexSettings))
  var obj = that.$hypothesis.findChildren(2, that.settings)
  obj.children = indexObj.children
}

function inputOnChange(e, thisObj, that, breakMark) {
  thisObj.value = e.target.value
  if (!thisObj.value) {
    that.$emit('updateForm', thisObj.prop, thisObj.placeholderValue)
    breakMark[0] = true
  }
}

export const settings = [{
    id: 0,
    prop: 'liang',
    label: '样本【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: [`请拖入左侧变量：(个数＝1个)`], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.hypothesis[xysName], this.id, item)
      fetchParam(that)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.hypothesis[xysName], this.id, item)
      resetDivHelp(that)
    }
  },
  {
    id: 2,
    label: '分布方式',
    prop: 'distribution',
    parentValue: 1,
    type: 'selectSelect',
    value: 'norm',
    onChange(e, item, that) {
      fetchParam(that)
    },
    options: [{
      text: '正态分布',
      value: 'norm'
    }],
    children: [{
        label: '平均值',
        prop: 'mean',
        parentValue: 'norm',
        placeholderValue: '',
        placeholder() {
          return `默认为样本的平均值${this.placeholderValue}`
        },
        onChange(e, item, that, breakMark) {
          inputOnChange(e, this, that, breakMark)
        },
        type: 'input',
        value: '',
        options: [],
        children: []
      },
      {
        label: '标准差',
        prop: 'std',
        parentValue: 'norm',
        placeholderValue: '',
        placeholder() {
          return `默认为样本的${this.label}${this.placeholderValue}`
        },
        onChange(e, item, that, breakMark) {
          inputOnChange(e, this, that, breakMark)
        },
        type: 'input',
        value: '',
        options: [],
        children: []
      },
      {
        label: '最小值',
        prop: 'min',
        parentValue: 'uniform',
        placeholderValue: '',
        placeholder() {
          return `默认为样本的最小值${this.placeholderValue}`
        },
        onChange(e, item, that, breakMark) {
          inputOnChange(e, this, that, breakMark)
        },
        type: 'input',
        value: '',
        options: [],
        children: []
      },
      {
        label: '最大值',
        prop: 'max',
        parentValue: 'uniform',
        placeholderValue: '',
        placeholder() {
          return `默认为样本的最大值${this.placeholderValue}`
        },
        onChange(e, item, that, breakMark) {
          inputOnChange(e, this, that, breakMark)
        },
        type: 'input',
        value: '',
        options: [],
        children: []
      },
      {
        label: 'λ',
        prop: 'λ',
        parentValue: 'expon',
        placeholderValue: '',
        placeholder() {
          return `默认为样本的λ${this.placeholderValue}`
        },
        onChange(e, item, that, breakMark) {
          inputOnChange(e, this, that, breakMark)
        },
        type: 'input',
        value: '',
        options: [],
        children: []
      }
    ]
  },
  {
    label: '显著水平α',
    prop: 'a',
    parentValue: 1,
    type: 'radio',
    value: '5',
    options: [{
      text: '1%',
      value: '1'
    }, {
      text: '5%',
      value: '5'
    }, {
      text: '10%',
      value: '10'
    }],
    children: []
  },
  {
    label: '判断条件',
    prop: 'judge',
    parentValue: 1,
    type: 'radio',
    value: '＝',
    options: [{
      text: '≥',
      value: '≥'
    }, {
      text: '=',
      value: '＝'
    }, {
      text: '≤',
      value: '≤'
    }],
    children: []
  }
]
// {
//   text: '正态分布',
//   value: 'norm'
// }, {
//   text: '均匀分布',
//   value: 'uniform'
// }, {
//   text: '泊松分布',
//   value: 'poisson'
// }, {
//   text: '指数分布',
//   value: 'expon'
// }