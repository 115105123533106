<template >
  <div>
    <div
      style="position: fixed; top: 0px; width: 100%; height: 50px; background: #001529; z-index: 9999; opacity: 0.5"
      v-if="answerid && data.state === 0"
    >
      <a-row type="flex" justify="end">
        <a-col class="title" style="margin-right: 20px">
          <a-button @click="handelSubmit" style="font-weight: bold; color: black">确认有效量表(问卷)</a-button>
          <a-button @click="cancel" style="margin-left: 10px; color: black; font-weight: bold">取消</a-button>
        </a-col>
      </a-row>
    </div>
    <div class="example" v-if="loading">
      <a-spin tip="加载中..." size="large"> </a-spin>
    </div>
    <div style="background-color: #fbfaf7; padding-top: 50px">
      <a-form-model
        ref="ruleForm"
        :model="form"
        class="divStyle"
        :bordered="false"
        style="background-color: #fefefe"
        v-if="loading === false"
      >
        <a-row type="flex" justify="center">
          <a-col :span="20" class="title marginTop">{{ data.modelName }}</a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :span="20" class="marginTop">
            <div style="white-space: pre-wrap">{{ data.modelDescription }}</div></a-col
          >
        </a-row>
        <subject-comp-new
          :data="data.questions"
          :formaaa="form"
          :childrenRequire="true"
          :isAnswer="false"
          :disabled="answerid && data.state === 1 ? true : false"
        ></subject-comp-new>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import { Preview, SubmitAnswer } from '@/api/questionnaire'
import SubjectCompNew from './components/SubjectCompNew'
import { Base64 } from 'js-base64'
import { message } from 'ant-design-vue'
import { BMI, height, jinshi, age, jibing } from './jisuan'
import { getColFromNum } from './utils'
export default {
  components: {
    SubjectCompNew
  },
  data() {
    return {
      form: {},
      data: {},
      isScore: false,
      disable: false,
      answerid: this.$route.params.currentid,
      loading: false,
      isSubmit: false
    }
  },
  created() {
    this.loading = true
    this.isSubmit = false
    localStorage.setItem(`watchDetail${this.$route.params.currentid}${this.$route.params.id}`, 0)
    Preview({
      id: Number(Base64.decode(this.$route.params.id)),
      answerid: this.$route.params.currentid && Number(Base64.decode(this.$route.params.currentid))
    }).then(res => {
      this.data = res
      this.form = res.questionKeys
      this.isScore = res.isScore
      if (res.answers) {
        this.disable = true
        res.answers.map(m => {
          const key = m.questionID + '_' + m.type + '_' + m.docField
          if (m.type === 5) {
            const value = JSON.parse(m.value)
            const score = JSON.parse(m.score)
            const checkboxV = []
            var question = this.data.questions.filter(r => r.id === m.questionID)
            value.map((v, i) => {
              const checkValue = score[i] + '_' + v
              checkboxV.push(checkValue)
              if (question.length > 0) {
                question[0].optionArry.map(r => {
                  if (r.text === v) {
                    r.childrenRequire = true
                  }
                })
              }
            })
            this.form[key] = checkboxV
          } else if (m.type === 2 || m.type === 1) {
            this.form[key] = m.score + '_' + m.value
            question = this.data.questions.filter(r => r.id === m.questionID)
            if (question.length > 0) {
              question[0].optionArry.map(r => {
                if (r.text === m.value) {
                  r.childrenRequire = true
                }
              })
            }
          } else if (m.type === 6) {
            this.form[key] = m.value && JSON.parse(m.value)
          } else if (m.type === 7) {
            this.form[key] = m.value && JSON.parse(m.value)
            // 图片
            var images = m.imageJson && JSON.parse(m.imageJson)
            if (images) {
              var fileList = []
              images.map(v => {
                var image = {}
                image['preview'] = v.imageData
                image['thumbUrl'] = v.imageData
                image['type'] = v.imageType
                image['name'] = v.imageName
                image['uid'] = v.imageID
                image['status'] = 'done'
                fileList.push(image)
              })

              this.form[key + '_image'] = fileList
            }
          } else if (m.type === 8) {
            this.form[key + '_table'] = JSON.parse(m.value)
          } else if (m.type === 9) {
            this.form[key] = JSON.parse(m.value)
          } else {
            this.form[key] = m.value
          }
          // 当单选和多选选中某个选项时，childRequire=true
        })
        console.log('form', this.form)
        // 若选择某个选项时有跳转
        this.getChildSkip(this.data.questions, res.answers)
      }
      this.loading = false
    })
  },
  mounted() {
    // 关闭详情页面
    window.onunload = e => {
      // 如果不是提交后的关闭，设置为2
      if (!this.isSubmit) {
        localStorage.setItem(`watchDetail${this.$route.params.currentid}${this.$route.params.id}`, 2)
      }
    }
  },
  methods: {
    getChildSkip(current, answers) {
      current.map(r => {
        this.valueChange(r, current)
        var skip = r.skipValue && JSON.parse(r.skipValue)
        if (skip) {
          skip.map(m => {
            // 跳转序号
            var order = Number(m.order)
            // 跳转值
            var value = m.value
            // 查看答卷中是否有跳转所需要的答案。如果有则将有些题目隐藏
            var answer = answers.filter(o => o.questionID === r.id && o.value === value)
            if (answer.length > 0) {
              var endorder = null
              current.map(s => {
                if (s.order > r.order && s.order < order) {
                  s.isShow = false
                } else {
                  // s.isShow = true
                  if (s.isEnd && !endorder) {
                    endorder = s.order
                  }
                }
              })
              if (endorder) {
                current.map(s => {
                  if (s.order > endorder) {
                    s.isShow = false
                  }
                })
              }
            }
          })
        } else if (r.children.length > 0) {
          this.getChildSkip(r.children, answers)
        }
      })
    },
    handelSubmit() {
      this.$refs.ruleForm.validate(valid => {
        var values = this.form
        if (valid) {
          console.log('values', values)
          // api 新增的入参
          const parm = {}
          // 题目答案列表
          const model = []
          let score = 0
          var jb = 0
          var ageS = 0
          var bmis = 0
          var downHeightS = 0
          var shiliangS = 0
          parm['Options'] = model
          parm['QuestionnaireID'] = Number(Base64.decode(this.$route.params.id))
          for (var key in values) {
            const value = values[key]
            if (value !== null && value !== undefined) {
              const answer = {}
              // key的格式 questionID_type_docField,如果是图片后面还有_image或者_previewVisible或_previewImage
              var keys = key.split('_') // 将key属性用_分割，一般是3个，图片是4个
              answer['QuestionID'] = Number(keys[0])
              // 类型
              const type = Number(keys[1])
              answer['type'] = type
              // 字段名
              answer['docField'] = keys[2]
              // 图片key值
              var imagekey = ''
              if (keys.length === 4) {
                imagekey = keys[3]
              } // 一般题目或者图片类型，图片只取image和标签的值
              if (type !== 7 || (type === 7 && (keys.length === 3 || imagekey === 'image'))) {
                const checkboxValue = []
                const checkboxScore = []
                // 单选
                if (type === 2 || type === 1) {
                  answer['Value'] = value.slice(value.indexOf('_') + 1)
                  answer['Score'] = Number(value.slice(0, value.indexOf('_'))).toString()
                  score = score + Number(value.slice(0, value.indexOf('_')))
                } else if (type === 5) {
                  // 多选（value是选项的json值）
                  value.map(m => {
                    score = score + Number(m.slice(0, m.indexOf('_')))
                    checkboxScore.push(Number(m.slice(0, m.indexOf('_'))))
                    checkboxValue.push(m.slice(m.indexOf('_') + 1))
                  })
                  answer['Score'] = JSON.stringify(checkboxScore).toString()
                  answer['Value'] = JSON.stringify(checkboxValue)
                } else if (type === 3) {
                  // 日期
                  const moment = require('moment')
                  answer['Value'] = moment(value).format('YYYY-MM-DD HH:mm:ss')
                } else if (type === 6) {
                  answer['Value'] = JSON.stringify(value)
                } else if (type === 7) {
                  // 如果是图片内容的做个标记
                  if (keys.length === 4) {
                    answer['Remark'] = 'image'
                    var images = []
                    value.map(v => {
                      var image = {}
                      image['imageData'] = v.preview
                      image['imageType'] = v.type
                      image['imageName'] = v.name
                      image['imageID'] = v.uid
                      images.push(image)
                    })
                    answer['Value'] = JSON.stringify(images)
                  } else {
                    answer['Value'] = JSON.stringify(value)
                  }
                } else if (type === 8 || type === 9) {
                  answer['Value'] = JSON.stringify(value)
                } else {
                  answer['Value'] = value.toString()
                }
                switch (key) {
                  case '583_0_null':
                    bmis = BMI(answer['Value'])
                    break
                  case '585_2_null':
                    shiliangS = jinshi(answer['Value'])
                    break
                  case '588_2_null':
                    downHeightS = height(answer['Value'])
                    break
                  case '586_2_null':
                    ageS = age(answer['Value'])
                    break
                  case '580_2_null':
                    jb = jibing(answer['Value'])
                    break
                }
                model.push(answer)
              }
            }
          }

          parm['ScoreTotal'] = score
          if (this.isScore) {
            var renti = [bmis, downHeightS, shiliangS]
            var scoreRenti = Math.max.apply(Math, renti)
            var total = jb + scoreRenti + ageS
            parm['ComputeScore'] = total
          }
          console.log(parm)
          SubmitAnswer({ ...parm, answerID: Number(Base64.decode(this.$route.params.currentid)) }).then(res => {
            if (res) {
              message.config({
                top: `80px`,
                duration: 2,
                maxCount: 3
              })
              message.error(res)
            } else {
              // 提交的关闭设置为1
              this.isSubmit = true
              localStorage.setItem(`watchDetail${this.$route.params.currentid}${this.$route.params.id}`, 1)
              window.close()
            }
          })
        }
      })
    },
    cancel() {
      window.close()
    },
    valueChange(record, current) {
      if (record.isFormula && record.formulas.filter(a => a.formulaQuestion.location === 'remark').length > 0) {
        // 如果参与公式计算的话
        var recordkey = record.id + '_' + record.type + '_' + record.docField
        if (this.form[recordkey] !== '') {
          // 首先确保自己是有值的
          var qValue = [] // 值的数组{id,value}的集合
          qValue.push({ id: record.id.toString(), value: Number(this.form[recordkey]) }) // 先将当前值添加进去
          // 如果参与多个公式，需要一个个参与计算
          record.formulas.map(r => {
            // 如果有其他题目参与需要先判断其他题目是否已经有值了
            var otherCount = 0 // 用来判断其他的问题是不是都有值
            r.questions.map(q => {
              var key = q.id + '_' + q.type + '_' + q.docField
              if (q.type === 0) {
                // 数值型
                if (this.form[key] !== null) {
                  qValue.push({ id: q.id.toString(), value: Number(this.form[key]) })
                  otherCount++
                }
              } else if (q.type === 2) {
                // 单选
                if (this.form[key] !== null) {
                  var value = this.form[key].slice(0, this.form[key].indexOf('_'))
                  qValue.push({ id: q.id.toString(), value: Number(value) })
                  otherCount++
                }
              }
            })
            // 当参与计算的项都有值之后
            if (otherCount === r.questions.length) {
              // 按顺序将值付给ABCD
              var ids = r.formulaIDs.split(',')
              var gs = r.formula // 公式
              for (let i = ids.length - 1; i >= 0; i--) {
                var qKey = getColFromNum(i) // i从0开始，将变量key转化为ABC
                // 找到匹配的题目和答案,按理说应该有且只有一个
                var value = qValue.filter(a => a.id === ids[i])
                if (value.length > 0) {
                  gs = gs.replace(new RegExp(qKey, 'g'), value[0].value)
                }
              }
              // eslint-disable-next-line no-eval
              var result = Math.round(eval(gs) * 100) / 100
              var formulaKey = r.formulaQuestion.id + '_' + r.formulaQuestion.type + '_' + r.formulaQuestion.docField
              if (r.formulaQuestion.location === 'remark') {
                var formulaQ = current.filter(a => a.id === r.formulaQuestion.id) // 将结果的题目找出来
                // 给他的remark赋值
                formulaQ[0].remark = result
              } else {
                this.form[formulaKey] = result
              }
              console.log('结果', result)
            }
          })
        } else {
          // 否则已经生成的结果也要删掉
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  font: 2em sans-serif;
  text-align: center;
}
.example {
  text-align: center;
  min-height: 500px;
  padding-top: 300px;
}
.marginTop {
  margin-top: 40px;
  font: 20px;
}
.marginSujectTop {
  margin-top: 10px;
  font: 20px;
}
.marginLeft {
  padding-left: 20px;
  text-align: left;
}
.example {
  text-align: center;
  min-height: 500px;
  padding-top: 300px;
}
//正常网页
@media screen {
  .content {
    font-size: 14px;
    height: 30px;
  }
  .subject {
    font-size: 14px;
    font-weight: bold;
  }
  .divStyle {
    width: 1280px;
    margin: 0 auto;
    height: 100%;
  }
  .widthStyle {
    width: 70%;
    height: 40px;
    line-height: 40px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: black !important;
  }

  //手机
  @media (max-width: 768px) {
    .content {
      font-size: 18px;
      height: 30px;
    }
    .subject {
      font-size: 18px;
      font-weight: bold;
    }
    .divStyle {
      width: 100%;
      height: 100%;
    }
    .divBac {
      background-color: white;
      //   background-image:none;
    }
    .widthStyle {
      width: 100%;
    }
  }
}
</style>
