var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          position: "fixed",
          top: "0px",
          width: "100%",
          height: "50px",
          background: "#001529",
          "z-index": "9999",
          opacity: "0.5"
        }
      },
      [
        _c(
          "a-row",
          { attrs: { type: "flex", justify: "end" } },
          [
            _c(
              "a-col",
              { staticClass: "title", staticStyle: { "margin-right": "20px" } },
              [
                _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "print",
                        rawName: "v-print",
                        value: "#PrintContent",
                        expression: "'#PrintContent'"
                      }
                    ],
                    staticStyle: {
                      "font-weight": "bold",
                      color: "black",
                      "margin-top": "10px"
                    }
                  },
                  [_vm._v("打印")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      color: "black",
                      "font-weight": "bold"
                    },
                    on: { click: _vm.cancel }
                  },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { attrs: { id: "PrintContent" } }, [
      _vm.printModel === "linchuangyyfxscjlb"
        ? _c(
            "div",
            _vm._l(_vm.answers, function(item, i) {
              return _c("linchuangyyfxscjlb", {
                key: i,
                attrs: { data: item, ids: _vm.questions }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.printModel === "linchuangyyfxscjlbSimple"
        ? _c(
            "div",
            _vm._l(_vm.answers, function(item, i) {
              return _c("linchuangyyfxscjlbSimple", {
                key: i,
                attrs: { data: item, ids: _vm.questions }
              })
            }),
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }