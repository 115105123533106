import request from '@/utils/request'

const api = {
  getData: '/CommonChart/GetData'
}

export function getData(parameter) {
    return request({
      url: api.getData,
      method: 'post',
      params: parameter
    })
  }