var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      attrs: {
        id: "verification",
        type: "flex",
        justify: "space-between",
        align: "top",
        size: _vm.inputSize
      }
    },
    [
      _c(
        "a-col",
        { attrs: { span: 15 } },
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "verificationCode" } },
            [
              _c(
                "a-input",
                {
                  attrs: { placeholder: "请输入验证码", size: _vm.inputSize },
                  model: {
                    value: _vm.form.verificationCode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "verificationCode", $$v)
                    },
                    expression: "form.verificationCode"
                  }
                },
                [
                  _c("a-icon", {
                    staticStyle: { color: "rgba(0, 0, 0, 0.25)" },
                    attrs: { slot: "prefix", type: "mail" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { staticStyle: { height: "40px" }, attrs: { span: 8 } },
        [
          _c(
            "a-button",
            {
              staticStyle: {
                height: "100%",
                color: "#1890ff",
                "font-size": "12px",
                "border-radius": "2px"
              },
              attrs: { disabled: _vm.verifyButtonDisabled, block: "" },
              on: { click: _vm.clickVerifyButton }
            },
            [_vm._v(_vm._s(_vm.verifyButtonText2))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }