var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-checkbox-group",
    {
      staticStyle: { width: "100%" },
      on: { change: _vm.onChange },
      model: {
        value: _vm.item.value,
        callback: function($$v) {
          _vm.$set(_vm.item, "value", $$v)
        },
        expression: "item.value"
      }
    },
    _vm._l(_vm.item.options, function(category, index) {
      return _c(
        "div",
        { key: index },
        [
          _c(
            "a-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("a-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v(_vm._s(category.className) + ":")])
              ])
            ],
            1
          ),
          _c(
            "a-row",
            _vm._l(category.children, function(option, i) {
              return _c(
                "a-col",
                {
                  key: i,
                  staticStyle: { margin: "5px 0" },
                  attrs: {
                    xxl: _vm.item.optionsColLg,
                    sm: _vm.item.optionsColSm
                  }
                },
                [
                  _c("a-checkbox", { attrs: { value: option.value } }, [
                    _c(
                      "span",
                      { domProps: { innerHTML: _vm._s(option.label) } },
                      [_vm._v(_vm._s(option.label))]
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }