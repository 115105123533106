<template>
  <page-header-wrapper
    :breadcrumb="null"
    :tab-list="tabList"
    :tab-active-key="tabActiveKey"
    :tab-change="handleTabChange"
    class="minHeight"
  >
    <!-- <template v-slot:extra>
      <a-button type="primary" style="margin-left: 28px" @click="gotoBack">&lt;&lt; 返 回</a-button>
    </template> -->
    <router-view />
  </page-header-wrapper>
</template>
<script>
import { gotoScreen } from '@/utils/datasource'
const getActiveKey = path => {
  if (path.indexOf('rangecode') > -1) {
    return '1'
  } else if (path.indexOf('invalidSample') > -1) {
    return '2'
  }
}
export default {
  data() {
    return {
      tabList: [
        { key: '1', tab: '范围编码' },
        { key: '2', tab: '无效样本' }
      ],
      tabActiveKey: '1'
    }
  },
  created() {
    this.tabActiveKey = getActiveKey(this.$route.path)
    this.handleTabChange(this.tabActiveKey)
    this.$watch('$route', val => {
      this.tabActiveKey = getActiveKey(val.path)
    })
  },
  mounted() {
    this.tabActiveKey = getActiveKey(this.$route.path)
  },
  methods: {
    handleTabChange(key) {
      this.tabActiveKey = key
      var index = this.$route.path.lastIndexOf('/')
      var path = this.$route.path.substring(0, index)
      switch (key) {
        case '1':
          this.$router.push(path + '/rangecode')
          break
        case '2':
          this.$router.push(path + '/invalidSample')
          break
      }
    },
    gotoBack() {
      gotoScreen()
    }
  }
}
</script>