import request from '@/utils/request'

const api = {
    historyFields: '/HistoryField',
    docHistoryField: '/DocHistoryField'
}

export function getHistoryFields(parameter) {
    return request({
        url: api.historyFields,
        method: 'get',
        params: parameter
    })
}

export function getHistoryField(parameter) {
    return request({
        url: api.docHistoryField + '/' + parameter,
        method: 'get'
    })
}

export function addHistoryFields(parameter) {
    return request({
        url: api.docHistoryField,
        method: 'post',
        data: parameter
    })
}

export function updateHistoryFields(parameter) {
    return request({
        url: api.docHistoryField,
        method: 'put',
        data: parameter
    })
}

export function deleteHistoryFields(parameter) {
    return request({
        url: api.docHistoryField,
        method: 'delete',
        data: parameter
    })
}
