import {
  onChange,
  onClose
} from '@/views/analysisOfRegression/utils/inputItemsClass'
import store from '@/store/index'
var xysName = 'pcaXys'

const makePCANumber = (that) => {
  const ary = [{
    text: '特征值大于1的个数',
    value: 0
  }]
  store.state.analysisOfRegression[xysName][0].forEach((v, i) => {
    ary.push({
      text: i + 1 + '',
      value: i + 1
    })
  })
  that.settings[1].value = 0
  that.settings[1].options = ary
}

export const settings = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 20, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    // rules: [
    //   (callback, v, divHelp) => {
    //     if (v.list.every(v => v.type === '类')) callback(divHelp[0])
    //   }
    // ],
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(1个 ≤ 个数 ≤ 20个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
      makePCANumber(that)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
      makePCANumber(that)
    }
  },
  {
    label: '提取主成分个数',
    prop: 'number',
    parentValue: 1,
    type: 'select',
    value: 0,
    options: [{
      text: '特征值大于1的个数',
      value: 0
    }],
    children: []
  }
]