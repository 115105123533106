<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 30px">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="display: flex; justify-content: center">
            <a-form-model
              style="width: 70%"
              labelAlign="left"
              :label-col="{ md: { span: 4 }, xxl: { span: 3 } }"
              :wrapper-col="{ md: { span: 20 }, xxl: { span: 21 } }"
            >
              <a-form-model-item label="阶数">
                <a-radio-group v-model="k" @change="fetchResult()">
                  <a-radio v-for="v in kOptions" :key="v" :value="v">
                    {{ v }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="光滑指数">
                <a-row>
                  <a-col :span="18">
                    <a-slider
                      style="width: 95%"
                      v-model="ind"
                      @afterChange="fetchResult()"
                      :marks="marks"
                      :min="0"
                      :max="1"
                      :step="0.01"
                    />
                  </a-col>
                  <a-col :span="6">
                    <a-input-number style="width: 50%" v-model="ind" :step="0.01" :min="0" :max="1" />
                    <a-icon
                      type="redo"
                      @click="fetchResult()"
                      style="cursor: pointer; color: #1890ff; font-size: 20px; margin-left: 20px"
                    />
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24"></a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            v-for="(item, index) in res.images"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="`样条平滑图`"></MyImg>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/views/analysisOfRegression/MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import MyTable2 from '../../table'
export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      kOptions: [1, 2, 3, 4, 5],
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      marks: {
        0: '细节',
        1: '平滑'
      },
      k: 3, // 阶数
      ind: 0.5, // 光滑指数
      res: {}
    }
  },
  methods: {
    fetchResult() {
      this.$emit('showLoading')
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this, true).splineSmooth()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      console.log(res.images.length)
      return res
    }
  }
}
</script>