<template>
  <div class="wrapper">
    <h1 style="margin-bottom: 15px">{{ title }}</h1>
    <div style="letter-spacing: 1.4px !important">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title']
}
</script>
<style lang="less" scoped>
.wrapper {
  background: white;
  box-shadow: rgba(183, 182, 182, 0.6) 0px 3px 20px 0px;
  padding: 20px;
}
</style>