<template >
  <div style="margin-left: 20px">
    <a-checkbox style="margin-left: 20px" @change="onCheckChange" :checked="without0">去零处理</a-checkbox>
    <a-checkbox style="margin-left: 20px" @change="outlierChange" :checked="outlier">离群值自动处理</a-checkbox>
    当前样本数:
    <span style="margin-left: 8px; margin-right: 8px; font-size: 16px; font-weight: bold; color: #1890ff">{{
      count
    }}</span>
    数据源:
    <span style="margin-left: 8px; font-size: 14px; font-weight: bold; color: #1890ff">
      <a-tooltip>
        <template slot="title">
          <div v-if="$store.state.samples.sourceModule == '量表(问卷)数据'">
            <div v-for="(name, i) in $store.state.samples.qesNameAry" :key="i">{{ name }}</div>
          </div>
          <div v-else>{{ $store.state.samples.sourceModule }}</div>
        </template>
        {{ $store.state.samples.fileName }}
      </a-tooltip>
    </span>

    <a-tooltip placement="bottom">
      <template slot="title"> 图表设置 </template>
      <a-icon style="margin-left: 20px; font-size: 20px" type="setting" theme="twoTone" @click="onSetting"></a-icon>
    </a-tooltip>

    <a-button type="primary" style="margin-left: 28px" @click="onBack">&lt;&lt; 重新选择数据</a-button>
    <Settings :visible="setVisible" :data="setData" @cancel="handleCancel" @ok="handleOk"></Settings>
  </div>
</template>

<script>
import { gotoScreen } from '@/utils/datasource'
import Settings from './settings'
import { updateSettings, getSettingsByID } from '@/api/report'
import { setSession } from '@/api/session'
export default {
  components: { Settings },
  created() {
    // sessionStorage.filter = '{}'
  },
  activated() {
    this.indexFields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
  },
  data() {
    return {
      count: this.$store.state.samples.keys ? JSON.parse(this.$store.state.samples.keys).length : 0, // 样本数量,
      setVisible: false,
      setData: {}
      // outlier: this.$store.state.samples.filtersAll.outlier !== 0,
      // without0: this.$store.state.samples.filtersSimple.without0 !== 0
    }
  },
  computed: {
    outlier() {
      return this.$store.state.samples.filtersAll.outlier !== 0
    },
    without0() {
      return this.$store.state.samples.filtersSimple.without0 !== 0
    }
  },

  methods: {
    onCheckChange(e) {
      // this.without0 = e.target.checked
      this.$store.dispatch('updateSamples', {
        filtersSimple: { without0: e.target.checked && e.target.checked ? 1 : 0 }
      })
    },
    outlierChange(e) {
      // this.outlier = e.target.checked
      this.$store.dispatch('updateSamples', { filtersAll: { outlier: e.target.checked && e.target.checked ? 1 : 0 } })
    },
    onBack() {
      gotoScreen()
    },
    onSetting() {
      const userID = this.$store.state.user.info.id
      getSettingsByID(userID).then(res => {
        this.setData = res
        this.setVisible = true
      })
    },
    handleCancel() {
      this.setVisible = false
    },
    handleOk() {
      var values = this.setData
      values.colors = JSON.stringify(values.colorsArry)
      updateSettings(values).then(res => {
        this.setVisible = false
        this.$store.dispatch('GetInfo')
      })
      const { language, colorsArry, picformat } = values
      setSession({ language, colors: JSON.stringify(colorsArry), picformat }).then(res => {
        console.log('session更新成功')
        // 通过这个变量来告知假设检验或者回归分析中的结果页面，刷新一下。
        this.$store.state.samples.settingController = !this.$store.state.samples.settingController
      })
    }
  }
}
</script>