var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          {
            ref: "title," + _vm.thirdIndex.normIndex + ",for:5",
            staticClass: "third-title",
            attrs: {
              id:
                "3." +
                _vm.secondIndex +
                "." +
                _vm.thirdIndex.normIndex +
                " 正态性检验"
            }
          },
          [
            _vm._v(
              " 3." +
                _vm._s(_vm.secondIndex) +
                "." +
                _vm._s(_vm.thirdIndex.normIndex) +
                " 正态性检验 "
            )
          ]
        ),
        _vm._l(_vm.lei_name_list, function(lei_name, lei_name_index) {
          return _c(
            "div",
            { key: lei_name_index },
            [
              _c("Norm", {
                ref: "content," + _vm.thirdIndex.normIndex,
                refInFor: true,
                attrs: {
                  data: {
                    data1: _vm.data.data1[lei_name],
                    data2: _vm.data.data2
                  },
                  lei: lei_name,
                  xys: _vm.xys,
                  settings: _vm.obj_of_settings.norm
                },
                on: {
                  isNorm: function(val) {
                    return _vm.isNorm.push(val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text1",
                      fn: function() {
                        return [
                          _c(
                            "p",
                            {
                              ref: "text," + _vm.thirdIndex.normIndex + ",1",
                              refInFor: true
                            },
                            [
                              _vm._v(
                                " 对不同" +
                                  _vm._s(lei_name) +
                                  "中的" +
                                  _vm._s(_vm.liang) +
                                  "做正态性检验，"
                              ),
                              _c("br"),
                              _c("span", { staticClass: "H0H1" }, [
                                _vm._v(
                                  "H0：不同" +
                                    _vm._s(lei_name) +
                                    "中" +
                                    _vm._s(_vm.liang) +
                                    "的样本服从正态分布。"
                                )
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "H0H1" }, [
                                _vm._v(
                                  "H1：不同" +
                                    _vm._s(lei_name) +
                                    "中" +
                                    _vm._s(_vm.liang) +
                                    "的样本不服从正态分布。"
                                )
                              ]),
                              _c("br"),
                              _vm._v("检验结果如下表所示： ")
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "text2",
                      fn: function(ref) {
                        var text = ref.text
                        return [
                          _c("Conclusion", [
                            _c(
                              "p",
                              {
                                ref: "text," + _vm.thirdIndex.normIndex + ",3",
                                refInFor: true
                              },
                              [
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(text[0]) }
                                })
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "text3",
                      fn: function() {
                        return [
                          _c(
                            "p",
                            {
                              ref: "text," + _vm.thirdIndex.normIndex + ",4",
                              refInFor: true
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.liang) + "的PP图和QQ图如下图所示："
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        })
      ],
      2
    ),
    _vm.isNorm.length === 2
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                ref: "title," + _vm.thirdIndex.homogeneousIndex + ",for:3",
                staticClass: "third-title",
                attrs: {
                  id:
                    "3." +
                    _vm.secondIndex +
                    "." +
                    _vm.thirdIndex.homogeneousIndex +
                    " 方差齐性检验"
                }
              },
              [
                _vm._v(
                  " 3." +
                    _vm._s(_vm.secondIndex) +
                    "." +
                    _vm._s(_vm.thirdIndex.homogeneousIndex) +
                    " 方差齐性检验 "
                )
              ]
            ),
            _vm._l(_vm.lei_name_list, function(lei_name, lei_name_index) {
              return _c(
                "div",
                { key: lei_name_index },
                [
                  _c("HomogeneityOfVariances", {
                    ref: "content," + _vm.thirdIndex.homogeneousIndex,
                    refInFor: true,
                    attrs: {
                      data: {
                        data1: _vm.data.data1[lei_name],
                        data2: _vm.data.data2
                      },
                      lei: lei_name,
                      isNorm: _vm.isNorm[lei_name_index],
                      settings: _vm.obj_of_settings.homogeneous
                    },
                    on: {
                      isHomogeneous: function(val) {
                        return _vm.isHomogeneous.push(val)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "textFront",
                          fn: function() {
                            return [
                              _c("p", {
                                ref:
                                  "text," +
                                  _vm.thirdIndex.homogeneousIndex +
                                  ",1",
                                refInFor: true,
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.get_text("方差齐性检验", lei_name_index)
                                  )
                                }
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "textBack",
                          fn: function() {
                            return [
                              _c("Conclusion", [
                                _c(
                                  "p",
                                  {
                                    ref:
                                      "text," +
                                      _vm.thirdIndex.homogeneousIndex +
                                      ",3",
                                    refInFor: true
                                  },
                                  [
                                    _vm._v(" 由上表可得： "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$hypothesis.conclusion(
                                            _vm.isHomogeneous[lei_name_index],
                                            _vm.a,
                                            "不同" +
                                              lei_name +
                                              "中" +
                                              _vm.liang +
                                              "的样本方差齐",
                                            "不同" +
                                              lei_name +
                                              "中" +
                                              _vm.liang +
                                              "的样本方差不齐"
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.isHomogeneous.length === 2
      ? _c("div", [
          _c(
            "div",
            {
              ref: "title," + _vm.thirdIndex.anovaIndex,
              staticClass: "third-title",
              attrs: {
                id:
                  "3." +
                  _vm.secondIndex +
                  "." +
                  _vm.thirdIndex.anovaIndex +
                  " 方差分析"
              }
            },
            [
              _vm._v(
                " 3." +
                  _vm._s(_vm.secondIndex) +
                  "." +
                  _vm._s(_vm.thirdIndex.anovaIndex) +
                  " 方差分析 "
              )
            ]
          ),
          _c(
            "div",
            [
              _c("DoubleVarianceIndex", {
                ref: "content," + _vm.thirdIndex.anovaIndex,
                attrs: { data: _vm.data, xys: _vm.xys },
                on: {
                  diff_lei_list: function($event) {
                    _vm.diff_lei_list = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "textFront",
                      fn: function() {
                        return [
                          _c(
                            "p",
                            { ref: "text," + _vm.thirdIndex.anovaIndex + ",1" },
                            [
                              _vm._v(
                                " 对" +
                                  _vm._s(_vm.lei_name_list[0]) +
                                  "、" +
                                  _vm._s(_vm.lei_name_list[1]) +
                                  "和" +
                                  _vm._s(_vm.liang) +
                                  "做双因素方差分析，分析结果如下表所示： "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "textBack",
                      fn: function(ref) {
                        var text_list = ref.text_list
                        return [
                          _c(
                            "Conclusion",
                            [
                              _c(
                                "span",
                                {
                                  ref:
                                    "text," + _vm.thirdIndex.anovaIndex + ",3"
                                },
                                [_vm._v(" 由上表可得： ")]
                              ),
                              _vm._l(text_list, function(text, text_index) {
                                return _c(
                                  "p",
                                  {
                                    key: text_index,
                                    ref:
                                      "text," +
                                      _vm.thirdIndex.anovaIndex +
                                      ",4",
                                    refInFor: true
                                  },
                                  [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(text) }
                                    })
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  298913098
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.diff_lei_list.length > 0
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                ref: "title," + _vm.thirdIndex.postHocIndex + ",for:4",
                staticClass: "third-title",
                attrs: {
                  id:
                    "3." +
                    _vm.secondIndex +
                    "." +
                    _vm.thirdIndex.postHocIndex +
                    " 事后多重比较"
                }
              },
              [
                _vm._v(
                  " 3." +
                    _vm._s(_vm.secondIndex) +
                    "." +
                    _vm._s(_vm.thirdIndex.postHocIndex) +
                    " 事后多重比较 "
                )
              ]
            ),
            _vm._l(_vm.diff_lei_list, function(lei_name, lei_index) {
              return _c(
                "div",
                { key: lei_index },
                [
                  _c("PostHoc", {
                    ref: "content," + _vm.thirdIndex.postHocIndex,
                    refInFor: true,
                    attrs: {
                      data: {
                        data1: _vm.data.data1[lei_name],
                        data3: _vm.data.data3
                      },
                      lei: lei_name,
                      isLenEqual: _vm.is_len_equal_list[lei_index],
                      leiOptionsCombine: _vm.options_combine_list[lei_index],
                      methodOfAnova: "Anova",
                      method: "auto"
                    },
                    on: {
                      findLessP: function(x) {
                        return (_vm.findLessP = x)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "textFront",
                          fn: function() {
                            return [
                              _c("p", {
                                ref:
                                  "text," + _vm.thirdIndex.postHocIndex + ",1",
                                refInFor: true,
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.get_text("事后检验", lei_index)
                                  )
                                }
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "textBack",
                          fn: function() {
                            return [
                              _c("Conclusion", [
                                _vm.findLessP.length > 0
                                  ? _c(
                                      "span",
                                      {
                                        ref:
                                          "text," +
                                          _vm.thirdIndex.postHocIndex +
                                          ",3",
                                        refInFor: true
                                      },
                                      [_vm._v(" 由上表可得： ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  {
                                    ref:
                                      "text," +
                                      _vm.thirdIndex.postHocIndex +
                                      ",4",
                                    refInFor: true
                                  },
                                  [
                                    _vm.findLessP.length > 0
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.liang) + "中 "
                                            ),
                                            _vm._l(_vm.findLessP[0], function(
                                              groupObj,
                                              groupObjIndex
                                            ) {
                                              return _c(
                                                "span",
                                                { key: groupObjIndex },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(groupObj.A) +
                                                      "与" +
                                                      _vm._s(groupObj.B) +
                                                      "， "
                                                  )
                                                ]
                                              )
                                            }),
                                            _vm._v("这些组合的"),
                                            _c("i", [_vm._v("p")]),
                                            _vm._v(
                                              "值 < " +
                                                _vm._s(_vm.a) +
                                                "，因此拒绝H0（差别不具有统计学意义），接受H1（差别具有统计学意义）。 "
                                            )
                                          ],
                                          2
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.liang) +
                                              "中所有组合的"
                                          ),
                                          _c("i", [_vm._v("p")]),
                                          _vm._v(
                                            "值 ≥ " +
                                              _vm._s(_vm.a) +
                                              "，因此不拒绝H0（差别不具有统计学意义）。 "
                                          )
                                        ])
                                  ]
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }