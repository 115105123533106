<template>
  <div>
    <a-row v-for="(content,index) in list" :key="index" style="margin-bottom:15px">
      <a-col :span="24">
        <help-size1 :content="content"></help-size1>
      </a-col>
    </a-row>
    <!-- <a-row>
      <a-col :span="24">
        <help-size2></help-size2>
      </a-col>
    </a-row> -->
  </div>
</template>
<script>
import HelpSize1 from './helpSize1'
import HelpSize2 from './helpSize2'
import { hypothesisBrief } from '@/common/enums'
export default {
  computed: {
      list() {
          return hypothesisBrief[this.$store.state.hypothesis.method]
      }
  },
components: { HelpSize1, HelpSize2 }
}
</script>
<style lang="less" scoped>
// .wrapper {
//   display: flex;
//   justify-content: center;
// }
</style>