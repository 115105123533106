<template>
  <a-card :bordered="false" id="divPatient">
    <!-- <a-row style="background: rgb(245, 245, 245)" type="flex" justify="center">
      <a-col style="background: rgba(0, 160, 233, 0.7)" flex="1 0 100px"> col-4 </a-col>
      <a-col style="background: #00a0e9" :xs="{ span: 24 }" :xl="{ span: 4 }"> col-4 </a-col>
      <a-col style="background: rgba(0, 160, 233, 0.7)" :xs="{ span: 24 }" :xl="{ span: 6 }"> col-4 </a-col>
      <a-col style="background: #00a0e9" :xs="{ span: 24 }" :xl="{ span: 6 }"> col-4 </a-col>
    </a-row> -->
    <a-row type="flex">
      <a-col :flex="3">
        <a-breadcrumb style="margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/question/List">问卷列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 患者列表 </a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
    </a-row>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col id="query" :md="8" :sm="24">
            <a-form-item label="查询">
              <a-input
                v-model="queryParam.key"
                @pressEnter="$refs.table.refresh(true)"
                placeholder="请输入住院号或者患者姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="
                  () => {
                    ;(this.queryParam = {}), $refs.table.refresh(true)
                  }
                "
              >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table ref="table" size="default" rowKey="key" :columns="columns" :data="loadData" showPagination="auto">
      <template slot="action" slot-scope="text, record">
        <router-link :to="`/question/sequentialStatisticsDetails/${questionID}/${record.serialNumber}`"
        >查看详情</router-link
        >
      </template>
    </s-table>
  </a-card>
</template>
<script>
import { GetPatients } from '@/api/questionnaire'
import { Base64 } from 'js-base64'
import { AutoHeight } from '@/utils/util'

export default {
  created() {
    this.questionID = +Base64.decode(this.$route.params.id)
    // 高度自定义使用
    var max = []
    var min = ['divPatient']
    AutoHeight(this, min, max, 215)
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      columns: [
        {
          title: '住院号',
          dataIndex: 'serialNumber'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '性别',
          dataIndex: 'gender'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
          width: 450
        }
      ],
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        const req = {
          id: this.questionID,
          ...requestParameters
        }
        console.log('loadData request parameters:', req)
        return GetPatients(req).then(res => {
          return res
        })
      }
    }
  }
}
</script>
<style scoped>
#query .ant-form-item {
  margin: 0 !important;
}
</style>