<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    :rules="rules"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 0">
        &lt;{{ data.xys[0][0] }}&gt;服从{{ $hypothesis.distribution[data.form.distribution] }}
      </div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 0">
        &lt;{{ data.xys[0][0] }}&gt;不服从{{ $hypothesis.distribution[data.form.distribution] }}
      </div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'andersonXys',
      xys: [[]],
      formAddKeyName: 'andersonFormAddKey',
      settings,
      form: {
          a: '5',
          distribution: 'norm',
          method: 0
      },
      rules: {}
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>