<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="12" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'单样本t检验分析结果'"></myTable>
          </a-col>
          <a-col :span="12" style="padding: 30px">
            <a-row>
              <a-col :span="24">
                <box-shadow-card :title="'单样本t检验智能分析结果'">
                  <div>
                    <div v-for="(item, index) in result" :key="index">
                      <span v-html="item">{{ item }}</span>
                    </div>
                  </div>
                </box-shadow-card>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      // method: () => {},
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: ['']
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['t'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.oneLiang()
    }
  },
  computed: {
    H0() { return [`&lt;${this.part2.xys[0][0]}&gt;的平均值 ${this.part2.judge} ${this.part2.reducedValue}`] },
    H1() { return [`&lt;${this.part2.xys[0][0]}&gt;的平均值  ${this.$hypothesis.replaceJudge(this.part2.judge)} ${this.part2.reducedValue}`] },
    result() { return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0])}`] }
  }
}
</script>