// import pick from 'lodash.pick'
import {
  getChart
} from '@/api/hypothesis'

import store from '@/store'
import {
  Array
} from 'core-js'
/**
 * 图表入口类
 */
export default class ChartClass {
  /**
   *
   * @param {*} documents 档案集合
   * @param {*} xyzm 变量{x,y,z,m}的对象
   * @param {*} action 图表类型
   * @param {*} style 图表样式对象
   */
  constructor(documents, xyzm, action, style, infos) {
    const type = store.state.samples.type
    this.documents = documents
    this.xyzm = xyzm
    // 所有变量的集合[]
    this.fields = xyzm.x
      .concat(xyzm.y)
      .concat(xyzm.z)
      .concat(xyzm.m)

    this.action = action
    if ((this.action === 'Scatter_shixu' || this.action === 'Line_shixu') && type !== 'questionnaire') {
      this.fields.push({
        name: xyzm.change.fieldName
      })
    }
    this.style = {
      ...style,
      language: store.state.user.info.setting.language,
      picformat: store.state.user.info.setting.picformat
    }
    this.colors = JSON.parse(store.state.user.info.setting.colors)
    this.infos = infos
    // this.language = store.state.user.info.setting.language
    this.result = {}
  }

  main(that, callback) {
    const type = store.state.samples.type
    var count = 0
    if (this.action === 'AnysisV' || this.action === 'qq' || this.action === 'roc' || this.action === 'histogram' || this.action === 'MiDu' || this.action === 'DDT' || this.action === 'Scatter_shixu' || this.action === 'Line_shixu') {
      this.documents.map(r => {
        var f = this.fields.filter(a => a.name === r.name)[0]
        r.data.map(b => {
          if (b[f.name].length > 0) {
            count++
          }
          if (f.type === '量' || (f.type === '时序量' && !(b[f.name] instanceof Array))) {
            b[f.name] = Number(b[f.name])
          }
        })
      })
    } else {
      // 从document中取出所有需要的变量数据集合
      // pick(this.documents, this.fields.map(r => {
      //   return r.name
      // }))
      // 将定量数据转换成number
      this.documents.map(r => {
        this.fields.map(a => {
          if (a.type === '量' || (a.type === '时序量' && !this.isJson(r[a.name]))) {
            r[a.name] = Number(r[a.name])
          }
        })
      })
    }
    var value = []
    if (this.action === 'Scatter_shixu' || this.action === 'Line_shixu') {
      if (type === 'questionnaire') {
        value = this.sxdataFilter(this.xyzm.change, this.xyzm.fun, this.xyzm.range)
      } else {}
      console.log('时序数据', value)
    }
    var data = {
      field: {
        ...this.xyzm,
        roc: that.roc,
        type
      },
      count: ((this.action === 'Scatter_shixu' || this.action === 'Line_shixu') && type === 'questionnaire') ? count : this.documents.length,
      data: ((this.action === 'Scatter_shixu' || this.action === 'Line_shixu') && type === 'questionnaire') ? JSON.stringify(value) : JSON.stringify(this.documents),
      style: this.style,
      colors: this.colors,
      language: this.language,
      action: this.action,
      infos: this.infos

    }

    // 组合api参数
    var req = {
      method: 'chart',
      args: data,
      style: this.style
    }
    return getChart(req).then(res => {
      console.log('reuslt', res)
      if (this.xyzm.isdownload === true) { // 下载图表
        if (res.erro) {
          that.$message.error(res.erro)
        } else {
          var img = res.imgs.filter(r => r.key === that.downloadsrc.key)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = img[0].src
          link.download = that.iconsList.filter((r) => r.action === that.iconState)[0].cName
          link.id = 'Adownload'
          document.body.appendChild(link)
          link.click()
          document.getElementById('Adownload').remove();
        }
      } else {
        that.optionList = []
        that.columnsChart = []
        that.cxz = []
        that.cgd = []
        that.dataChart = []
        that.anysisCData = null
        that.anysisVData = null
        if (res.erro) {
          that.$message.error(res.erro)
        } else {
          if (this.action === 'AnysisV') {
            that.anysisCData = this.getColumnanysisC(res.anysisCData)
            that.anysisVData = res.anysisVData
          } else {
            that.dataChart = res.tabdata && JSON.parse(res.tabdata)
            if (this.action === 'table' && res.colparm.length > 0) {
              that.columnsChart = this.getColumnTableSpan(res)
            } else {
              that.columnsChart = res.columns
            }
            that.optionList = res.imgs
            that.cxz = res.cxz
            that.cgd = res.cgd
          }
        }
        that.chartLoad = false
      }
      return this.action === 'roc' ? res : res.imgs
    })
  }

  /**
   * 文件的时序管理
   * @param {*} sxtime 时序的变量
   * @param {*} fun 统计的方法 求和，均值，最大值，最小值
   */
  sxdataFilterFile(sxtime, fun) {

  }

  // 时序数据整理,目前只支持问卷术后疼痛情况
  /**
   * 因为他有很多层。比如手术当天还有24小时
   * @param {*} sxtime 时序的变量
   * @param {*} fun 统计的方法 求和，均值，最大值，最小值
   * @param {*} range 统计的范围，0个人还是1整体
   */
  sxdataFilter(sxtime, fun, range) {
    // 时序变量整理数据
    // 时序图表根据时序时间查询
    var option = sxtime.option
      ? JSON.parse(sxtime.option).map(r => {
        return r.Text
      }) : []

    // var roption = []
    var coption = [] // 第二层时序时间
    // this.document的数据格式是[{name:'',data:[]}]
    this.documents.map(r => {
      r.data.map(m => {
        if (m[r.name] && m[r.name].filter(a => a.value instanceof Array).length > 0) {
          m[r.name].map(a => {
            a.value.map(b => {
              coption.push(b.time)
            })
          })
        }
      })
    })
    coption = Array.from(new Set([...coption]))
    var sxOp = 0 // 第一层
    if (option.filter(r => coption.indexOf(r) > -1).length > 0) {
      sxOp = 1 // 第二层
    }
    var sxvalues = []
    this.documents.map(d => {
      var yxcount = d.data.filter(a => a[d.name]).length // 有效的数据个数
      var sxvalue = []
      option.map(t => {
        if (range === '0') {
          d.data.map(r => {
            var sum = 0 // 求和
            var max = 0 // 最大值
            var min = 0 // 最小值
            var count = 0 // 频次
            if (sxOp === 0) {
              if (r[d.name]) {
                r[d.name].filter(a => a.time === t).map(a => {
                  if (a.value instanceof Array) {
                    a.value.map(b => {
                      var value = Number(b.value)
                      sum += value
                      if (count === 0) {
                        max = value
                        min = value
                      } else {
                        max = value > max ? value : max
                        min = value < min ? value : min
                      }

                      count++
                    })
                  } else {
                    var value = Number(a.value)
                    sum += value
                    if (count === 0) {
                      max = value
                      min = value
                    } else {
                      max = value > max ? value : max
                      min = value < min ? value : min
                    }

                    count++
                  }
                })
              }
            } else {
              if (r[d.name]) {
                r[d.name].map(a => {
                  a.value.filter(b => b.time === t).map(b => {
                    var value = Number(b.value)
                    sum += value
                    if (count === 0) {
                      max = value
                      min = value
                    } else {
                      max = value > max ? value : max
                      min = value < min ? value : min
                    }
                    count++
                  })
                })
              }
            }
            if (count > 0) {
              switch (fun) {
                case 'min':
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: min,
                    key: r.key
                  })
                  break
                case 'max':
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: max,
                    key: r.key
                  })
                  break
                case 'avg':
                  if (count > 0) {
                    sxvalue.push({
                      [sxtime.fieldName]: t,
                      value: Math.round(sum / count * 100) / 100,
                      key: r.key
                    })
                  }
                  break
                case 'pci':
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: count,
                    key: r.key
                  })
                  break
                case 'sum':
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: sum,
                    key: r.key
                  })

                  break
                case 'count':
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: count,
                    key: r.key
                  })

                  break
              }
            }
          })
        } else {
          var sum = 0 // 求和
          var max = 0 // 最大值
          var min = 0 // 最小值
          var count = 0 // 频次
          d.data.map(r => {
            if (sxOp === 0) {
              if (r[d.name]) {
                r[d.name].filter(a => a.time === t).map(a => {
                  if (a.value instanceof Array) {
                    a.value.map(b => {
                      var value = Number(b.value)
                      sum += value
                      if (count === 0) {
                        max = value
                        min = value
                      } else {
                        max = value > max ? value : max
                        min = value < min ? value : min
                      }

                      count++
                    })
                  } else {
                    var value = Number(a.value)
                    sum += value
                    if (count === 0) {
                      max = value
                      min = value
                    } else {
                      max = value > max ? value : max
                      min = value < min ? value : min
                    }
                    count++
                  }
                })
              }
            } else {
              if (r[d.name]) {
                r[d.name].map(a => {
                  a.value.filter(b => b.time === t).map(b => {
                    var value = Number(b.value)
                    sum += value
                    if (count === 0) {
                      max = value
                      min = value
                    } else {
                      max = value > max ? value : max
                      min = value < min ? value : min
                    }
                    count++
                  })
                })
              }
            }
          })
          if (count > 0) {
            switch (fun) {
              case 'min':
                sxvalue.push({
                  [sxtime.fieldName]: t,
                  value: min
                })
                break
              case 'max':
                sxvalue.push({
                  [sxtime.fieldName]: t,
                  value: max
                })
                break
              case 'avg':
                if (count > 0) {
                  sxvalue.push({
                    [sxtime.fieldName]: t,
                    value: Math.round(sum / count * 100) / 100
                  })
                }
                break
              case 'pci':
                sxvalue.push({
                  [sxtime.fieldName]: t,
                  value: count
                })
                break
              case 'sum':
                sxvalue.push({
                  [sxtime.fieldName]: t,
                  value: sum
                })

                break
              case 'count':
                sxvalue.push({
                  [sxtime.fieldName]: t,
                  value: count
                })

                break
            }
          }
        }
      })
      sxvalues.push({
        name: d.name,
        count: yxcount,
        data: sxvalue
      })
    })

    return sxvalues
    // that.documents
  }

  /**
   * 描述性分析定类的Columns,因为rowspan需要单独设置
   */
  getColumnanysisC(data) {
    if (data) {
      var columns = []
      var defaultCol = [{
        'title': '组别',
        'dataIndex': 'type',
        'align': 'center',
        width: '20%',
        className: 'column-money'
      }, {
        'title': '数量',
        'dataIndex': '人数',
        'align': 'center',
        width: '20%',
        className: 'column-money'
      }, {
        'title': '构成比(%)',
        'dataIndex': '构成比',
        'align': 'center',
        width: '20%',
        className: 'column-money'
      }, {
        'title': '置信区间(置信度：95%)',
        'dataIndex': '置信区间',
        'align': 'center',
        width: '20%',
        className: 'column-money'
      }]
      columns.push({
        'title': '分析项',
        'align': 'center',
        width: '20%',
        'dataIndex': 'field',
        className: 'column-money',
        customRender: (value, row, index) => {
          if (row.first) {
            var aa = data.colparm.filter(r => r.field === row.field)
            if (aa.length > 0) {
              return {
                children: value,
                attrs: {
                  rowSpan: aa[0].length
                }
              }
            } else {
              return {
                children: value,
                attrs: {
                  rowSpan: 0
                }
              }
            }
          } else {
            return {
              children: value,
              attrs: {
                rowSpan: 0
              }
            }
          }
        }
      })
      columns = columns.concat(defaultCol)
      data['columns'] = columns
    }
    return data
  }

  getColumnTableSpan(data) {
    var columns = []
    data.columns.map(r => {
      if (r.rowspan1) {
        r['customRender'] = (value, row, index) => {
          if (row.first) {
            var aa = data.colparm.filter(s => s.field === row[r.dataIndex])
            if (aa.length > 0) {
              return {
                children: value,
                attrs: {
                  rowSpan: aa[0].length
                }
              }
            } else {
              return {
                children: value,
                attrs: {
                  rowSpan: 0
                }
              }
            }
          } else {
            return {
              children: value,
              attrs: {
                rowSpan: 0
              }
            }
          }
        }
      } else if (r.rowspan2) {
        r['customRender'] = (value, row, index) => {
          if (row.second) {
            var aa = data.colparm.filter(s => s.field === row[r.dataIndex])
            if (aa.length > 0) {
              return {
                children: value,
                attrs: {
                  rowSpan: aa[0].length
                }
              }
            } else {
              return {
                children: value,
                attrs: {
                  rowSpan: 0
                }
              }
            }
          } else {
            return {
              children: value,
              attrs: {
                rowSpan: 0
              }
            }
          }
        }
      }
      columns.push(r)
    })
    return columns
  }

  /**
   * 判断是否为json字符串
   */
  isJson(str) {
    if (typeof str === 'string') {
      try {
        var obj = JSON.parse(str)
        if (typeof obj === 'object' && obj) {
          return true
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    }
  }
}