var xysName = 'splineSmoothXys'
export const group = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数 = 1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [] // 必填项1
  },
  {
    id: 1,
    index: 1,
    prop: 'dependentVariable',
    label: '因变量Y【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数 = 1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [] // 必填项1
  }
]

export const settings = [
  // {
  //   label: '组合',
  //   componentName: 'Combine',
  //   prop: 'combine',
  //   parentValue: 1,
  //   type: 'component',
  //   children: []
  // },
  {
    label: '', // 在不需要label的时候，一定要定义出一个空字符串。
    componentName: 'Drops',
    group, // 初始group
    xysName,
    addXys: [
      [],
      []
    ], // 这个是点击增加后xys当中需要增加的数组，相当于一个group
    parentValue: 1,
    type: 'component',
    settings: [
      group
    ],
    children: []
  }
]