var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingController ? _c("loading") : _vm._e(),
      _c("div", { staticStyle: { padding: "50px 0 0 0" } }, [
        _c(
          "div",
          {
            staticStyle: {
              position: "fixed",
              top: "0px",
              width: "100%",
              height: "50px",
              background: "#001529",
              "z-index": "500",
              opacity: "0.5"
            }
          },
          [
            _c(
              "a-row",
              { attrs: { type: "flex", justify: "end" } },
              [
                _c(
                  "a-col",
                  {
                    staticClass: "title",
                    staticStyle: { "margin-right": "20px" }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "print",
                            rawName: "v-print",
                            value: "#PrintContent",
                            expression: "'#PrintContent'"
                          }
                        ],
                        staticStyle: {
                          "font-weight": "bold",
                          color: "black",
                          "margin-top": "10px"
                        }
                      },
                      [_vm._v("打印")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          color: "black",
                          "font-weight": "bold"
                        },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", { attrs: { id: "PrintContent" } }, [
          _c("div", { staticStyle: { background: "#dedede" } }, [
            _c(
              "div",
              { staticClass: "pagediv" },
              [
                _c(
                  "a-table",
                  {
                    staticClass: "base recordTable",
                    attrs: {
                      size: "small",
                      columns: _vm.columns1,
                      "data-source": _vm.data1,
                      showHeader: false,
                      pagination: false,
                      bordered: ""
                    }
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "20px",
                            "font-weight": "600"
                          }
                        },
                        [_vm._v("正确度验证（参考方法）")]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "a-table",
                  {
                    staticClass: "dataTable recordTable",
                    attrs: {
                      size: "small",
                      columns: _vm.columns2,
                      "data-source": _vm.data2,
                      pagination: false,
                      bordered: ""
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 24 } },
                              _vm._l(_vm.sampleConcentrations, function(
                                concentration,
                                index
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    class: {
                                      concentration: true,
                                      active:
                                        _vm.currentConcentrationIndex === index
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.currentConcentrationIndex = index
                                        _vm.fetch()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(concentration) + "%浓度 "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  "font-weight": "700",
                                  "background-color": "#f7f7f7",
                                  "border-top": "1px solid #999999"
                                },
                                attrs: { span: 24 }
                              },
                              [_vm._v("数据")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagediv" },
              [
                _c(
                  "a-table",
                  {
                    staticClass: "recordTable",
                    attrs: {
                      size: "small",
                      columns: _vm.columns3,
                      "data-source": _vm.data3,
                      showHeader: false,
                      pagination: false,
                      bordered: ""
                    }
                  },
                  [_c("template", { slot: "title" }, [_vm._v("计算")])],
                  2
                ),
                _c("a-table", {
                  staticClass: "recordTable",
                  attrs: {
                    size: "small",
                    columns: _vm.columns4,
                    "data-source": _vm.data4,
                    pagination: false,
                    bordered: ""
                  }
                }),
                _c(
                  "a-table",
                  {
                    staticClass: "recordTable",
                    attrs: {
                      size: "small",
                      columns: _vm.columns5,
                      "data-source": _vm.data5,
                      showHeader: false,
                      pagination: false,
                      bordered: ""
                    }
                  },
                  [_c("template", { slot: "title" }, [_vm._v("结论")])],
                  2
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }