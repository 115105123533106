<template>
  <div>
    <a-spin tip="文件处理中..." :spinning="spinning">
      <a-alert v-if="form.merges" type="error" message="Excel存在单元格合并，缺失部分数据，请核查校验！" banner />
      <a-form-model :model="form" v-bind="formLayout" layout="inline" ref="ruleForm">
        <a-form-model-item
          label="文件名称"
          prop="name"
          :rules="{ required: true, message: '文件名称不能为空', trigger: 'blur' }"
        >
          <a-input v-model="form.name" style="width: 300px"></a-input>
          <a-checkbox v-model="form.isSx" style="margin-left: 30px">是否是时序数据</a-checkbox> </a-form-model-item
          ><br />
        <div v-if="form.isSx">
          <a-form-model-item
            label="时序时间"
            prop="name"
            :rules="{ required: true, message: '文件名称不能为空', trigger: 'blur' }"
          >
            <a-select v-model="form.sxtime" style="width: 300px" >
              <a-select-option :value="item" v-for="(item, i) in form.fields" :key="i">{{ item }}</a-select-option>
            </a-select> </a-form-model-item
            ><br />
          <a-form-model-item
            label="时序变量"
            prop="name"
            :rules="{ required: true, message: '文件名称不能为空', trigger: 'blur' }"
          >
            <a-select v-model="form.sxfield" style="width: 300px" mode="multiple">
              <a-select-option :value="item" :key="i" v-for="(item, i) in form.fields">{{ item }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <div style="margin-top: 10px">
          当前样本量:<span style="color: #3397ff">{{ form.data ? form.data.length : 0 }}</span>
        </div>

        <a-row>
          <a-col :span="12"> <div style="margin-top: 10px; font-size: 16px; font-weight: bold">数据预览</div></a-col>
          <a-col :span="12" style="text-align: right">
            <router-link to="/file/List" style="margin-left: 10px">&lt;&lt;返回文件列表</router-link></a-col
          >
        </a-row>
        <a-alert
          style="margin-top: 10px"
          type="error"
          message="上传的文件的标题中含有[`~!@#$^&amp;*()=|{}':;',\\[\\].&lt;&gt;《》/?~！@#￥……&amp;*（）——|{}【】‘；：”“'。，、？ ]空格特殊字符（红色标记代表有问题），请修改后重新上传"
          banner
          v-if="excelerro > 0"
        />
        <a-table
          style="margin-top: 10px"
          bordered
          size="small"
          rowKey="key"
          :columns="form.columns"
          :data-source="form.display"
          :scroll="{ x: true }"
        ></a-table>
        <div style="text-align: center">
          <a-button @click="prevStep">上一步</a-button>
          <a-button type="primary" style="margin-left: 20px" @click="finish" v-if="excelerro === 0">提交</a-button>
        </div>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue'
import { Add } from '@/api/file'
export default {
  name: 'Step2',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    excelerro: {
      type: Number,
      default: 0
    }
  },
  data() {
    this.formLayout = {
      layout: 'horizontal',
      labelAlign: 'left'
    }

    return {
      spinning: false // 加载中百分比
    }
  },

  methods: {
    prevStep() {
      Modal.confirm({
        title: '提示',
        content: '此操作将撤销上传的数据，请确认！',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          this.$emit('prevStep')
        }
      })
    },
    finish() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.title.map(r => {
            if (this.form.sxfield && this.form.sxfield.indexOf(r.name) > -1) {
              r.isSx = true
            } else {
              r.isSx = false
            }
          })
          this.spinning = true // 加载中百分比
          var parm = {
            orgid: this.$store.state.user.info.org.id,
            userid: this.$store.state.user.info.id,
            name: this.form.name,
            count: this.form.data.length,
            fileTitles: this.form.title,
            isSx: this.form.isSx,
            sxtime: JSON.stringify([this.form.sxtime]),
            sxfield: JSON.stringify(this.form.sxfield),
            fileData: { value: JSON.stringify(this.form.display) }
          }
          Add(parm).then(res => {
            this.$emit('finish')
          })
        }
      })
    }
  }
}
</script>