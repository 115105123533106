var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "third-module" },
      _vm._l(_vm.tables, function(table, index) {
        return _c("a-table", {
          key: index,
          attrs: {
            pagination: false,
            dataSource: table.data,
            columns: table.columns,
            size: "small"
          }
        })
      }),
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      _vm._l(_vm.imgs, function(ary, index) {
        return _c(
          "a-row",
          { key: index },
          _vm._l(ary, function(img, i) {
            return _c("a-col", { key: i, attrs: { span: 12 } }, [
              _c("img", { attrs: { width: "100%", src: img } })
            ])
          }),
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }