var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      staticClass: "hypinthesisTest",
      attrs: { breadcrumb: null },
      scopedSlots: _vm._u([
        {
          key: "extra",
          fn: function() {
            return [_c("sample")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "a-layout",
            { staticClass: "divContent", attrs: { id: "divContent" } },
            [
              _c(
                "a-row",
                { staticStyle: { width: "100% !important" } },
                [
                  _c("a-col", { attrs: { span: 5 } }, [_c("method")], 1),
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        "border-left": "1px solid rgb(232, 232, 232)",
                        "border-right": "1px solid rgb(232, 232, 232)"
                      },
                      attrs: { span: 4 }
                    },
                    [_c("field", { attrs: { from: "hypothesis" } })],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 15, id: "hypContent" } },
                    [
                      _c("router-view", {
                        staticStyle: { height: "100% !important" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }