import store from '@/store/index'
import dataTool from 'echarts/extension/dataTool'
import {
  Array
} from 'core-js'
// import { outlier } from '@/views/customchart/charts/outlierManage'

export class DataFilterClass {
  /**
   * 初始化类
   * @param {*} fieldArray//变量数组(图表是field的集合[{name,type,unit}]，假设检验是[定量：['身高'],[定类：['男','女','性别']]])
   * @param {*} isHypothesis//是否是假设检验,true是假设检验，false是图表
   */
  constructor(fieldArray, isHypothesis = false) {
    console.log('%c 🍡 fieldArray: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', fieldArray);
    this.documents = store.state.samples.documents ? JSON.parse(store.state.samples.documents) : [] // 本地缓存的原始数据
    this.fieldArray = fieldArray // 变量数组
    this.outlierArry = [] // 离群值数组，用来离群处理
    this.isHypothesis = isHypothesis // 是否是假设检验,true是假设检验，false是图表
  }

  main() {
    const objSimple = store.state.samples.filtersSimple // 取vuex中数据清洗参数需要单个数据的对象，目前有属性：without0（去0）
    const objAll = store.state.samples.filtersAll // 取vuex中数据清洗参数需要整体数据的对象，目前有属性：outlier（离群值处理）
    // 循环样本
    const documents = this.documents.filter(element => {
      // 循环变量数组
      return this.fieldArray.every(v => {
        // 去空值,如果是定量，取出非数字的值
        if (this.isHypothesis) {
          // 假设检验
          if (typeof (v) === 'string') {
            // 定量,当没有值，或者值不是数字时return false
            if (element[v] instanceof Array) {
              // 定序
              if (element[v][1] === null || element[v][1] === '' || isNaN(element[v][1])) return false
            } else {
              if (element[v] === null || element[v] === '' || isNaN(element[v])) {
                return false
              }
            }
          } else {
            // 定类
            if (element[v[v.length - 1]] instanceof Array) {
              if (!element[v[v.length - 1]][0]) return false
            } else {
              if (!element[v[v.length - 1]]) return false
            }
          }

          if (typeof (v) === 'string') {
            if (element[v] instanceof Array) {
              element[v] = element[v][1]
            }
          } else {
            if (element[v[v.length - 1]] instanceof Array) {
              element[v[v.length - 1]] = element[v[v.length - 1]][0]
            }
          }
        } else {
          if (!v.forOrder) {
            if (element[v.name] === null || element[v.name] === '' || (v.type === '量' && isNaN(element[v.name]))) {
              return false
            }
          } else {
            if ((v.type.indexOf('类') > -1 && !element[v.name][0]) || (v.type === '量' && !v.ptype && element[v.name][1] === null) || (v.type === '量' && !v.ptype && isNaN(element[v.name][1]))) {
              return false
            }
          }
          // // 图表
          // if ((!v.forOrder && !element[v.name] && v.type === '类') || (v.forOrder && !element[v.name][0] && v.type === '类') || (v.type === '量' && ((v.forOrder && isNaN(element[v.name][1])) || (!v.forOrder && isNaN(element[v.name]))))) {
          //   return false
          // }
          if (v.forOrder) {
            if (v.type.indexOf('类') > -1) {
              element[v.name] = element[v.name][0]
            } else {
              element[v.name] = element[v.name][1]
            }
          }
        }

        // 定量数据 数据清洗参数是单个数据，without0（去0）
        if ((this.isHypothesis && typeof (v) === 'string') || (!this.isHypothesis && v.type === '量')) {
          // if (v.type === '量') {
          for (const key1 in objSimple) {
            // 如果是1：选中
            if (objSimple[key1]) {
              if (!this[key1](element, v)) return false
            }
          }
        }
        return true
      })
    })
    // 数据清洗参数需要整体数据：，outlier（离群值处理）
    for (const key2 in objAll) {
      // 如果是1：选中
      if (objAll[key2]) {
        // 去0处理
        this[key2](documents)
      }
    }
    return documents
  }
  /**
   *去0处理
   * @param {*} element 单个变量的数据的单个值
   * @param {*} v 单个变量，假设检验是'身高',图表是{name:'身高'}
   */
  without0(element, v) {
    if (this.isHypothesis) {
      // eslint-disable-next-line eqeqeq
      return element[v] != '0'
    } else {
      // eslint-disable-next-line eqeqeq
      return element[v.name] != '0'
    }
  }
  /**
   * 离群值处理
   */
  outlier(documents) {
    // 只有定量才需要离群值处理

    var fields = this.fieldArray.filter(v => (this.isHypothesis && typeof (v) === 'string') || (!this.isHypothesis && v.type === '量')).map(r => {
      if (this.isHypothesis) {
        return r
      } else {
        return r.name
      }
    })
    if (fields.length > 0) {
      var bb = [] // 传入BoxplotData的参数[[变量1数据],[变量2数据]]
      fields.map(m => {
        // 单个变量数据
        var aa = documents.map(r => {
          return r[m]
        })
        bb.push(aa)
      })
      // BoxplotData处理函数
      var Iqrss = dataTool.prepareBoxplotData(bb)
      // 处理方式：直接删除离群样本
      // Iqrss.outliers离群的数据[[fields中的Index索引，离群的数据值]]
      Iqrss.outliers.map(r => {
        var index = -1
        for (let i = 0; i < documents.length; i++) {
          const element = documents[i]
          if (element[fields[r[0]]] === r[1]) {
            index = i
            break
          }
        }
        if (index > -1) {
          documents.splice(index, 1)
        }
      })
    }
    return documents
  }
}