<template>
  <page-header-wrapper :breadcrumb="null">
    <template v-slot:extra>
      <sample></sample>
    </template>

    <div>
      <a-layout class="divContent" id="divContent">
        <a-row style="width: 100% !important">
          <a-col :span="screenWidth > 1080 ? 3 : 4">
            <!---图标列表-->
            <div class="divContentleft leftWidth" style="overflow: auto" id="divContentleft">
              <!--描述性分析-->
              <div style="margin-bottom: 10px; font-weight: bold">描述性分析</div>
              <a-row>
                <a-col :span="20">
                  <div
                    :style="`background:${item.opacity === 1 ? 'white' : '#f5faff'}`"
                    v-for="(item, i) in iconsList.filter((r) => r.type === 'anysis')"
                    :key="i"
                    @click="clickIcon(item)"
                    :class="item.enable ? (item.state ? 'divBClicked' : 'divB') : 'divBUnable'"
                  >
                    描述性分析
                  </div>
                </a-col>
              </a-row>
              <a-divider></a-divider>

              <!--常用图表-->
              <div style="margin-bottom: 10px; font-weight: bold">常用图表</div>
              <!-- 图标列表 -->
              <a-row>
                <a-col :span="6" v-for="item in iconsList.filter((r) => r.type === 'common')" :key="item.name">
                  <img
                    :title="item.cName"
                    :style="`cursor: pointer; opacity:${item.opacity}`"
                    :src="`/chartsIcons/${item.name}_${item.enable ? (item.state ? 'xuanzhong' : 'moren') : 'hui'}.png`"
                    :alt="item.action"
                    :class="item.enable ? '' : 'form-onlyRead'"
                    @click="clickIcon(item)"
                  />
                </a-col>
              </a-row>
              <a-divider v-if="sxfields && sxfields.length > 0"></a-divider>
              <!--时序图表-->
              <div style="margin-bottom: 10px; font-weight: bold" v-if="sxfields && sxfields.length > 0">时序图表</div>
              <!-- 图标列表 -->
              <a-row v-if="sxfields && sxfields.length > 0">
                <a-col :span="6" v-for="item in iconsList.filter((r) => r.type === 'shixu')" :key="item.name">
                  <img
                    :title="item.cName"
                    :style="`cursor: pointer; opacity:${item.opacity}`"
                    :src="`/chartsIcons/${item.name}_${item.enable ? (item.state ? 'xuanzhong' : 'moren') : 'hui'}.png`"
                    :alt="item.action"
                    :class="item.enable ? '' : 'form-onlyRead'"
                    @click="clickIcon(item)"
                  />
                </a-col>
              </a-row>

              <a-divider></a-divider>
              <!--表格-->
              <div style="margin-bottom: 10px; font-weight: bold">表格</div>
              <a-row>
                <a-col :span="20">
                  <div
                    :style="`background:${item.opacity === 1 ? 'white' : '#f5faff'}`"
                    v-for="(item, i) in iconsList.filter((r) => r.type === 'table')"
                    :key="i"
                    @click="clickIcon(item)"
                    :class="item.enable ? (item.state ? 'divBClicked' : 'divB') : 'divBUnable'"
                  >
                    表格
                  </div>
                </a-col>
              </a-row>
              <!--相关系数-->
              <!-- <div style="margin-top: 20px; font-weight: bold">相关系数</div>
              <a-row>
                <a-col :span="20">
                  <div
                    :style="`background:${item.opacity === 1 ? 'white' : '#f5faff'}`"
                    v-for="(item, i) in iconsList.filter((r) => r.type === 'xgxs')"
                    :key="i"
                    @click="clickIcon(item)"
                    :class="item.enable ? (item.state ? 'divBClicked' : 'divB') : 'divBUnable'"
                  >
                    {{ item.action }}
                  </div>
                </a-col>
              </a-row> -->
              <!--帮助-->
              <a-row>
                <a-col
                  :span="24"
                  style="
                    text-align: start;
                    font-size: 12px;
                    border-top: 1px solid rgb(232, 232, 232);
                    margin: 10px 0;
                    padding: 10px 0;
                  "
                >
                  <span style="margin: 20px 0; font-size: 16px; font-weight: bold">{{ chartsType }}</span
                  ><br />
                  <span style v-html="iconHelp"></span>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col
            :span="4"
            style="border-left: 1px solid rgb(232, 232, 232); border-right: 1px solid rgb(232, 232, 232)"
          >
            <!-- 字段列表组件 -->
            <field :from="'chart'"></field>
          </a-col>
          <a-col :span="screenWidth > 1080 ? 17 : 16" id="chartContent" style="overflow: auto">
            <!---图表展示-->
            <a-layout-content style="padding: 15px; width: '100%'">
              <!--分析项选择框--->
              <div>
                <span data-v-11f4d49c class="ant-input-group-wrapper">
                  <span class="ant-input-wrapper ant-input-group">
                    <span
                      style="width: 130px"
                      v-if="
                        iconState !== 'Scatter' &&
                          iconState !== 'Line' &&
                          iconState !== 'roc' &&
                          iconState !== 'Regression' &&
                          iconState !== 'heatmap' &&
                          iconState !== 'Scatter_shixu' &&
                          iconState !== 'Line_shixu' &&
                          iconState !== 'table' &&
                          iconState !== 'ciyun' &&
                          iconState !== 'GIF' &&
                          iconState !== 'Waterfall'
                      "
                      class="ant-input-group-addon"
                    >{{ xtype ? `分析项【${xtype}】` : '分析项' }}</span
                    >

                    <span style="width: 155px" v-if="iconState === 'Waterfall'" class="ant-input-group-addon">{{
                      `分析项【${xtype}】`
                    }}</span>
                    <span style="width: 160px" v-if="iconState === 'ciyun'" class="ant-input-group-addon">{{
                      xtype ? `分析项【${xtype}】` : '分析项'
                    }}</span>
                    <span style="width: 189px" v-if="iconState === 'table'" class="ant-input-group-addon">{{
                      `横轴变量【${xtype}】`
                    }}</span>
                    <span
                      style="width: 189px"
                      v-if="iconState === 'Scatter_shixu' || iconState === 'Line_shixu'|| iconState === 'GIF'"
                      class="ant-input-group-addon"
                    >{{ xtype ? `分析项【${xtype}】` : '分析项' }}</span
                    >
                    <span
                      style="width: 189px"
                      v-if="
                        iconState === 'Scatter' ||
                          iconState === 'Line' ||
                          iconState === 'roc' ||
                          iconState === 'Regression' ||
                          iconState === 'heatmap'
                      "
                      class="ant-input-group-addon"
                    >{{ xtype ? `分析项【${xtype}-X轴】` : '分析项' }}</span
                    >
                    <div
                      type="text"
                      class="ant-input"
                      style="display: inline-block"
                      @drop="drop('x')"
                      @dragover.prevent
                    >
                      <span v-if="iconState === ''" style="color: rgba(0, 0, 0, 0.45)"
                      >请先选择图表类型，再拖入左侧变量</span
                      >
                      <span
                        v-if="
                          (iconState === 'pie' ||
                            iconState === 'bar' ||
                            iconState === 'bar2' ||
                            iconState === 'Waterfall') &&
                            x.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤2个）</span
                      >
                      <span
                        v-if="
                          (iconState === 'histogram' ||
                            iconState === 'MiDu' ||
                            iconState === 'qq' ||
                            iconState === 'roc' ||
                            iconState === 'Boxplot' ||
                            iconState === 'ErrorBar' ||
                            iconState === 'Violin' ||
                            iconState === 'DDT' ||
                            iconState === 'ciyun' ||
                            iconState === 'funnel' ||
                            iconState === 'Area' ||
                            iconState === 'TreeMap' ||
                            iconState === 'Radar') &&
                            x.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（1个≤个数≤5个）</span
                      >
                      <span
                        v-if="
                          (iconState === 'Pearson' ||
                            iconState === 'Spearman' ||
                            iconState === 'Kendall' ||
                            iconState === 'AnysisC' ||
                            iconState === 'AnysisV') &&
                            x.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤10个）</span
                      >
                      <span v-if="iconState === 'pairplot' && x.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（2个≤个数≤5个）</span
                      >
                      <span v-if="iconState === 'Phi' && x.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤10个），且变量中只能有两种类别</span
                      >
                      <span
                        v-if="(iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && x.length === 0"
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤1个）</span
                      >
                      <span v-if="iconState === 'table' && x.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数&lt;=10个）,且同一方向必须为同种类型</span
                      >
                      <span v-if="iconState === 'paleituotu' && x.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤10个）,当需要分析多个定类时，请确保所有分析项为二分类，如多选的各个选项</span
                      >
                      <span
                        v-if="
                          (iconState === 'Scatter' ||
                            iconState === 'Line' ||
                            iconState === 'Regression' ||
                            iconState === 'GIF' ||
                            iconState === 'heatmap') &&
                            x.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数=1个）</span
                      >
                      <a-tag
                        closable
                        @close="closeTag($event, 'x', i)"
                        v-for="(item, i) in x"
                        :key="item.key"
                        :v-if="item !== null"
                      >{{ item.name }}</a-tag
                      >
                    </div>
                    <div
                      style="width: 130px"
                      v-if="
                        iconState === 'table' &&
                          ((x.length > 0 && x[0].type === '量') || (x.length === 2 && x[0].type === '类'))
                      "
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="x.length > 0 && x[0].type === '量'" v-model="vtype">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>显示描述性分析的详细内容,适用于身高、体重这类身体体征数据</span>
                          </template>
                          <a-radio key="1" value="detail">详细</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据求和处理,适用于人数、病例这类统计类数据</span>
                          </template>
                          <a-radio key="2" value="sum">求和</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据平均值处理</span>
                          </template>
                          <a-radio key="3" value="avg">平均值</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                      <a-radio-group v-if="x.length === 2 && x[0].type === '类'" v-model="ctypex">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据的交叉统计，例如：统计性别为“男”的中民族为“汉”的人数</span>
                          </template>
                          <a-radio key="1" value="merge">交叉</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据的单独统计</span>
                          </template>
                          <a-radio key="2" value="single">单独</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                    </div>
                    <div
                      style="width: 270px"
                      v-if="iconState === 'Line' && x.length > 0 && x[0].type === '日期'"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="x.length > 0 && x[0].type === '日期'" v-model="dataType" @change="YearMD">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>显示所有数据</span>
                          </template>
                          <a-radio key="1" value="default">默认值</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按年进行统计</span>
                          </template>
                          <a-radio key="2" value="year">年</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按月进行统计</span>
                          </template>
                          <a-radio key="3" value="month">月</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按日进行统计</span>
                          </template>
                          <a-radio key="4" value="day">日</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                    </div>
                    <div
                      style="width: 170px"
                      v-if="iconState === 'Radar' && x.length > 0"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="x.length > 0" v-model="lineType" @change="YearMD">
                        <a-radio key="1" value="avg">平均值</a-radio>
                        <a-radio key="3" value="sum">求和</a-radio>
                      </a-radio-group>
                    </div>
                    <div
                      style="width: 150px"
                      v-if="iconState === 'paleituotu' && x.length > 0"
                      class="ant-input-group-addon"
                    >
                      <a-checkbox v-model="cp">累积占比</a-checkbox>
                      <a-checkbox v-model="percent">显示百分比</a-checkbox>

                    </div>
                    <div
                      style="width: 170px"
                      v-if="(iconState === 'bar' || iconState === 'bar2' || iconState === 'pie') && (x.length ===1||(x.length ===2&&!(x[0].type==='类'&&x[1].type==='类')))"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="x.length >0" v-model="barType" @change="YearMD">
                        <a-radio key="1" value="percent">百分比</a-radio>
                        <a-radio key="2" value="number">个数</a-radio>
                        <a-radio key="3" value="all" v-if="iconState === 'pie'">都要</a-radio>
                      </a-radio-group>
                    </div>
                  </span>
                </span>
                <span
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if="
                    iconState === 'histogram' ||
                      iconState === 'Boxplot' ||
                      iconState === 'ErrorBar' ||
                      iconState === 'Scatter' ||
                      iconState === 'Line' ||
                      iconState === 'roc' ||
                      iconState === 'MiDu' ||
                      iconState === 'Regression' ||
                      iconState === 'Violin' ||
                      iconState === 'pairplot' ||
                      iconState === 'heatmap' ||
                      iconState === 'DDT' ||
                      iconState === 'table' ||
                      (iconState === 'ciyun' && (x.length === 0 || x.filter((r) => r.type === '类').length > 0)) ||
                      iconState === 'funnel' ||
                      iconState === 'rank'||
                      iconState === 'GIF' ||
                      iconState === 'Area' ||
                      iconState === 'TreeMap' ||
                      iconState === 'Radar' ||
                      iconState === 'Waterfall' ||
                      (iconState === 'paleituotu' && quanzhong) ||
                      ((iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && type !== 'questionnaire')
                  "
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span
                      style="width: 189px"
                      v-if="(iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && type !== 'questionnaire'"
                      class="ant-input-group-addon"
                    >{{ ytype ? `分析项【${ytype}】` : '分析项' }}</span
                    >
                    <span style="width: 189px" v-if="iconState === 'table'" class="ant-input-group-addon">{{
                      `纵轴变量【${ytype}】`
                    }}</span>
                    <span
                      style="width: 130px"
                      v-if="
                        iconState === 'funnel' ||
                          iconState === 'rank'||
                          iconState === 'TreeMap' ||
                          iconState === 'paleituotu'
                      "
                      class="ant-input-group-addon"
                    >{{ `权重【${ytype}】` }}</span
                    >
                    <span
                      style="width: 189px"
                      v-if="
                        iconState === 'GIF'
                      "
                      class="ant-input-group-addon"
                    >{{ `权重【${ytype}】` }}</span
                    >
                    <span style="width: 160px" v-if="iconState === 'ciyun'" class="ant-input-group-addon">{{
                      `权重【${ytype}】`
                    }}</span>
                    <span
                      style="width: 130px"
                      v-if="iconState === 'Area' || iconState === 'Radar'"
                      class="ant-input-group-addon"
                    >{{ `分析项【${ytype}】` }}</span
                    >
                    <span style="width: 155px" v-if="iconState === 'Waterfall'" class="ant-input-group-addon">{{
                      `分析项【${ytype}】`
                    }}</span>
                    <span
                      style="width: 130px"
                      v-if="
                        iconState !== 'Scatter' &&
                          iconState !== 'Line' &&
                          iconState !== 'roc' &&
                          iconState !== 'Regression' &&
                          iconState !== 'heatmap' &&
                          iconState !== 'Scatter_shixu' &&
                          iconState !== 'Line_shixu' &&
                          iconState !== 'table' &&
                          iconState !== 'ciyun' &&
                          iconState !== 'funnel' &&
                          iconState !== 'rank'&&
                          iconState !== 'GIF' &&
                          iconState !== 'Area' &&
                          iconState !== 'TreeMap' &&
                          iconState !== 'Radar' &&
                          iconState !== 'Waterfall' &&
                          iconState !== 'paleituotu'
                      "
                      class="ant-input-group-addon"
                    >{{ ytype ? `分组【${ytype}】` : '分组' }}</span
                    >
                    <span
                      style="width: 189px"
                      v-if="
                        iconState === 'Scatter' ||
                          iconState === 'Line' ||
                          iconState === 'roc' ||
                          iconState === 'Regression' ||
                          iconState === 'heatmap'
                      "
                      class="ant-input-group-addon"
                    >{{ ytype ? `分析项【${ytype}-Y轴】` : '分析项' }}</span
                    >
                    <div
                      type="text"
                      class="ant-input"
                      style="display: inline-block"
                      @drop="drop('y')"
                      @dragover.prevent
                    >
                      <span
                        v-if="
                          (iconState === 'Boxplot' || iconState === 'ErrorBar' || iconState === 'Violin') &&
                            y.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >{{ boxTest }}</span
                      >
                      <span v-if="iconState === 'table' && y.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数&lt;=10个）,且同一方向必须为同种类型</span
                      >
                      <span
                        v-if="
                          (iconState === 'Scatter' ||
                            iconState === 'Line' ||
                            iconState === 'roc' ||
                            iconState === 'Regression' ||
                            iconState === 'heatmap' ||
                            iconState === 'DDT' ||
                            iconState === 'Area' ||
                            iconState === 'Radar' ||
                            iconState === 'Waterfall') &&
                            y.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数=1个）</span
                      >
                      <span
                        v-if="
                          (iconState === 'MiDu' ||
                            iconState === 'pairplot' ||
                            iconState === 'histogram' ||
                            iconState === 'Line_shixu' ||
                            ((iconState === 'Scatter_shixu' || iconState === 'Line_shixu') &&
                            type !== 'questionnaire')) &&
                            y.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤1个）</span
                      >
                      <span
                        v-if="
                          (iconState === 'funnel' || iconState === 'rank'||
                            iconState === 'GIF' || iconState === 'TreeMap') && y.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤1个）</span
                      >
                      <span v-if="iconState === 'ciyun' && y.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤1个）权重只与定类匹配，如果分析项是文本，权重则无效</span
                      >
                      <span v-if="iconState === 'paleituotu' && y.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数≤1个）【可选】</span
                      >
                      <a-tag closable v-for="(item, i) in y" :key="item.key" @close="closeTag($event, 'y', i)">{{
                        item.name
                      }}</a-tag>
                    </div>
                    <div
                      style="width: 130px"
                      v-if="
                        iconState === 'table' &&
                          ((y.length > 0 && y[0].type === '量') || (y.length === 2 && y[0].type === '类'))
                      "
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="y.length > 0 && y[0].type === '量'" v-model="vtype">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>显示描述性分析的详细内容,适用于身高、体重这类身体体征数据</span>
                          </template>
                          <a-radio key="1" value="detail">详细</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据求和处理,适用于人数、病例这类统计类数据</span>
                          </template>
                          <a-radio key="2" value="sum">求和</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据平均值处理</span>
                          </template>
                          <a-radio key="3" value="avg">平均值</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                      <a-radio-group v-if="y.length == 2 && y[0].type === '类'" v-model="ctypey">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据的交叉统计，例如：统计性别为“男”的中民族为“汉”的人数</span>
                          </template>
                          <a-radio key="1" value="merge">交叉</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>数据的单独统计</span>
                          </template>
                          <a-radio key="2" value="single">单独</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                    </div>
                    <div
                      style="width: 270px"
                      v-if="iconState === 'Line' && x.length > 0 && x[0].type === '日期' && y.length > 0"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group
                        v-if="x.length > 0 && x[0].type === '日期' && y.length > 0"
                        v-model="lineType"
                        @change="YearMD"
                      >
                        <a-radio key="1" value="avg">平均值</a-radio>
                        <a-radio key="3" value="sum">求和</a-radio>
                      </a-radio-group>
                    </div>
                    <div
                      style="width: 270px"
                      v-if="iconState === 'Waterfall' && y.length > 0 && y[0].type === '日期'"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="y.length > 0 && y[0].type === '日期'" v-model="dataType" @change="YearMD">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>显示所有数据</span>
                          </template>
                          <a-radio key="1" value="default">默认值</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按年进行统计</span>
                          </template>
                          <a-radio key="2" value="year">年</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按月进行统计</span>
                          </template>
                          <a-radio key="3" value="month">月</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>按日进行统计</span>
                          </template>
                          <a-radio key="4" value="day">日</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                    </div>
                  </span>
                </span>

                <span
                  id="groupC"
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if="iconState === 'Scatter' || iconState === 'Line' || iconState === 'Regression'"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span
                      style="width: 189px"
                      v-if="iconState === 'Scatter' || iconState === 'Line' || iconState === 'Regression'"
                      class="ant-input-group-addon"
                    >{{ iconState === 'Scatter' ? '颜色分组【定类】' : '分组【定类】' }}</span
                    >
                    <div
                      id="groupC1"
                      type="text"
                      class="ant-input"
                      @drop="drop('z')"
                      @dragover.prevent
                      :disabled="zdisabled"
                    >
                      <span
                        v-if="
                          (iconState === 'Scatter' ||
                            iconState === 'Line' ||
                            iconState === 'roc' ||
                            iconState === 'Regression') &&
                            z.length === 0
                        "
                        style="color: rgba(0, 0, 0, 0.45)"
                      >{{ ScatterTest }}</span
                      >
                      <a-tag closable v-for="(item, i) in z" :key="item.key" @close="closeTag($event, 'z', i)">{{
                        item.name
                      }}</a-tag>
                    </div>
                    <span
                      style="width: 189px; border-left: none; border-right: none"
                      v-if="iconState === 'Scatter'"
                      class="ant-input-group-addon"
                    >形状分组【定类】</span
                    >
                    <div
                      id="groupC2"
                      type="text"
                      class="ant-input"
                      @drop="drop('m')"
                      @dragover.prevent
                      v-if="iconState === 'Scatter'"
                      :disabled="mdisabled"
                    >
                      <span v-if="iconState === 'Scatter' && m.length === 0" style="color: rgba(0, 0, 0, 0.45)">{{
                        ScatterTest
                      }}</span>
                      <a-tag closable v-for="(item, i) in m" :key="item.key" @close="closeTag($event, 'm', i)">{{
                        item.name
                      }}</a-tag>
                    </div>
                  </span>
                </span>
                <span
                  v-if="iconState === 'roc'"
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 189px" class="ant-input-group-addon"> 阳性为 </span>
                    <div class="ant-input">
                      <span v-if="y.length === 0" style="color: rgba(0, 0, 0, 0.45)">请先向Y轴拖入定类变量</span>
                      <a-radio-group v-if="y.length > 0" v-model="roc">
                        <a-radio v-for="item in y[0].option" :key="item" :value="item">
                          {{ item }}
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </span>
                </span>

                <span
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if=" iconState === 'GIF'"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 189px" class="ant-input-group-addon"> 时间变量【日期/定类】 </span>
                    <div
                      type="text"
                      class="ant-input"
                      @drop="drop('z')"
                      @dragover.prevent
                      :disabled="zdisabled"
                    >
                      <span v-if="z.length === 0" style="color: rgba(0, 0, 0, 0.45)">请拖入左侧变量（个数=1个）</span>
                      <a-tag closable v-for="(item, i) in z" :key="item.key" @close="closeTag($event, 'z', i)">{{
                        item.name
                      }}</a-tag>
                    </div>
                  </span>
                </span>
                <span
                  v-if="
                    (iconState === 'bar' || iconState === 'bar2') &&
                      x.length === 2 &&
                      x[0].type === '类' &&
                      x[1].type === '类'
                  "
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 155px" class="ant-input-group-addon"> {{ x[0].name }} </span>
                    <div class="ant-input">
                      <a-checkbox-group v-model="barOption">
                        <a-checkbox v-for="item in x[0].option" :key="item" :value="item" :checked="true">
                          {{ item }}
                        </a-checkbox>
                      </a-checkbox-group>
                    </div>
                    <div
                      style="width: 130px"
                      v-if="barOption.length ===1&&iconState=='bar2' "
                      class="ant-input-group-addon"
                    >
                      <a-checkbox v-model="barOrder">排序</a-checkbox>
                    </div>

                  </span>

                </span>
                <span
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if="
                    iconState === 'table' && x.length > 0 && y.length > 0 && x[0].type === '类' && y[0].type === '类'
                  "
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 189px" v-if="iconState === 'table'" class="ant-input-group-addon"
                    >度量【{{ ztype }}】</span
                    >

                    <div type="text" class="ant-input" @drop="drop('z')" @dragover.prevent>
                      <span v-if="iconState === 'table' && z.length === 0" style="color: rgba(0, 0, 0, 0.45)"
                      >请拖入左侧变量（个数&lt;=10个）</span
                      >

                      <a-tag closable v-for="(item, i) in z" :key="item.key" @close="closeTag($event, 'z', i)">{{
                        item.name
                      }}</a-tag>
                    </div>
                    <div
                      style="width: 130px"
                      v-if="iconState === 'table' && z.length > 0 && z[0].type === '量'"
                      class="ant-input-group-addon"
                    >
                      <a-radio-group v-if="z.length > 0" v-model="zfuntype">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>适用于人数、病例这类统计类数据</span>
                          </template>
                          <a-radio key="1" value="sum">求和</a-radio>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>适用于身高、体重这类身体体征数据</span>
                          </template>
                          <a-radio key="2" value="avg">平均值</a-radio>
                        </a-tooltip>
                      </a-radio-group>
                    </div>
                  </span>
                </span>

                <!-- 时序问卷 -->

                <span
                  v-if="(iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && type === 'questionnaire'"
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 189px" class="ant-input-group-addon"> 时间分析项 </span>
                    <div class="ant-input" id="groupC1">
                      <a-radio-group v-model="sxtime">
                        <a-radio :value="f" v-for="(f, i) in sxfields" :key="i">{{ f.fieldName }}</a-radio>
                      </a-radio-group>
                    </div>
                  </span>
                </span>

                <span
                  id="groupC"
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if="(iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && type === 'questionnaire'"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <span style="width: 189px" class="ant-input-group-addon"> 统计方法</span>
                    <div class="ant-input" id="groupC1">
                      <a-radio-group v-model="sxfun" @change="anysis(infos)">
                        <a-radio key="1" value="sum">求和</a-radio>
                        <a-radio key="2" value="max">最大值</a-radio>
                        <a-radio key="3" value="min">最小值</a-radio>
                        <a-radio key="4" value="avg">平均值</a-radio>
                        <a-radio key="5" value="count">频次</a-radio>
                      </a-radio-group>
                    </div>
                    <span style="width: 189px; border-left: none; border-right: none" class="ant-input-group-addon"
                    >统计范围</span
                    >
                    <div class="ant-input" id="groupC2">
                      <a-radio-group v-model="sxrange" @change="anysis(infos)">
                        <a-radio
                          key="1"
                          value="0"
                          :disabled="count > 10 ? true : false"
                          title="超过10人时，不支持个人统计"
                        >个人</a-radio
                        >
                        <a-radio key="2" value="1">整体</a-radio>
                      </a-radio-group>
                    </div>
                  </span>
                </span>
                <!-- 时序文件 -->
                <span
                  id="groupC"
                  data-v-11f4d49c
                  class="ant-input-group-wrapper"
                  style="margin-top: 10px"
                  v-if="(iconState === 'Scatter_shixu' || iconState === 'Line_shixu') && type !== 'questionnaire'"
                >
                  <span class="ant-input-wrapper ant-input-group">
                    <!-- <span style="width: 189px" class="ant-input-group-addon"> 统计方法</span>
                    <div class="ant-input" id="groupC1">
                      <a-radio-group v-model="sxfun" @change="anysis">
                        <a-radio key="1" value="sum">求和</a-radio>
                        <a-radio key="2" value="max">最大值</a-radio>
                        <a-radio key="3" value="min">最小值</a-radio>
                        <a-radio key="4" value="avg">平均值</a-radio>
                        <a-radio key="5" value="count">频次</a-radio>
                      </a-radio-group>
                    </div> -->
                    <span style="width: 189px" class="ant-input-group-addon">时间分析项</span>
                    <div class="ant-input" id="groupC2">
                      <a-radio-group v-model="sxtime" @change="sxtimeChange">
                        <a-radio :value="f" v-for="(f, i) in sxfields" :key="i">{{ f.fieldName }}</a-radio>
                      </a-radio-group>
                    </div></span
                  >
                </span>

                <a-button type="primary" style="margin-top: 10px" @click="anysis(infos)" :disabled="anysisLoading"
                >分析</a-button
                >
                <span v-if="errMessage" style="color: red; margin-left: 10px">数据量太大，请使用其他图形</span>
                <a-icon
                  @click="retweet"
                  v-if="
                    ((iconState === 'pie' || iconState === 'bar' || iconState === 'bar2') &&
                      x.length === 2 &&
                      x.filter((r) => r.type === '类').length == 2) ||
                      (iconState === 'Boxplot' && x.length > 0) ||
                      (iconState === 'Violin' && x.length > 0) ||
                      (iconState === 'DDT' && x.length > 0 && y.length > 0) ||
                      ((iconState === 'Scatter' ||
                      iconState === 'Line' ||
                      iconState === 'Regression' ||
                      iconState === 'heatmap') &&
                      x.length == 1 &&
                      y.length === 1) ||
                      (iconState === 'roc' && x.length > 0 && y.length > 0) ||
                      (iconState === 'table' && (x.length > 0 || y.length > 0))
                  "
                  style="font-size: 20px; margin-left: 10px; color: rgb(24, 144, 255); cursor: pointer"
                  type="retweet"
                  :title="
                    iconState === 'roc'
                      ? '互换单调性'
                      : iconState === 'Boxplot' || iconState === 'Violin'
                        ? '互换坐标轴'
                        : '互换分析项的顺序'
                  "
                ></a-icon>
                <a-spin style="margin-left: 10px" v-if="anysisLoading"></a-spin>
              </div>
              <!--加载中-->
              <div style="margin-top: 20px" v-if="chartLoad">
                <a-spin tip="图表加载中，请稍候" size="large">
                  <div style="height: 100px"></div>
                </a-spin>
              </div>
              <div id="chartDiv">
                <!--表格数据（非假设检验）-->
                <div
                  v-if="
                    iconState !== 'Phi' &&
                      iconState !== 'Pearson' &&
                      iconState !== 'Spearman' &&
                      iconState !== 'Kendall'
                  "
                >
                  <a-table
                    v-if="dataChart && dataChart.length > 0 && !chartLoad"
                    style="margin-top: 20px"
                    bordered
                    size="small"
                    rowKey="key"
                    :columns="columnsChart"
                    :data-source="dataChart"
                    :pagination="false"
                    :scroll="{ x: true }"
                  >
                    <div slot="aucConf" slot-scope="text">( {{ text[0] }} , {{ text[1] }} )</div>
                  </a-table>
                </div>
                <!--图表-->
                <a-row
                  :gutter="20"
                  v-if="
                    (optionList && optionList.length > 0 && iconState !== 'AnysisV' && iconState !== 'AnysisC') ||
                      iconState !== 'pairplot'
                  "
                >
                  <a-col
                    v-for="(item, a) in optionList"
                    :key="item.id"
                    :span="24"
                    :style="{
                      marginTop: '30px',
                      marginBottom: '30px',
                      overflowX: 'auto',
                      webkitOverflowScrolling: 'touch',
                      borderBottom: optionList.length > 1 && a < optionList.length - 1 ? '1px #dedede dashed' : '',
                      paddingBottom: '20px',
                    }"
                    align="center"
                  >
                    <!--相关系数的表格-->
                    <div
                      style="width: 800px; margin: 20px auto 30px auto"
                      v-if="iconState === 'Pearson' || iconState === 'Spearman'"
                    >
                      <a-descriptions bordered v-if="cxz.length > 0 || cgd.length > 0" size="middle" layout="vertical">
                        <a-descriptions-item
                          label="显著相关【0.5<|r|≤0.8】"
                          v-if="cxz.length > 0"
                          style="text-align: center"
                        >
                          <span v-for="(aa, i) in cxz" :key="i">{{ aa }}<br /></span>
                        </a-descriptions-item>
                        <a-descriptions-item
                          label="高度相关【0.8<|r|<1】"
                          v-if="cgd.length > 0"
                          style="vertical-align: top !important"
                        >
                          <span v-for="(aa, i) in cgd" :key="i">{{ aa }}<br /></span>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                    <!--python图-->
                    <img :src="item.src" style="align: center" />
                    <br />
                    <div>
                      <a v-if="iconState !== 'AnysisV'" @click="handeldownload(item)">下载原图</a>
                      <a-icon
                        style="margin-left: 10px"
                        type="setting"
                        @click="settitleClick(item)"
                        v-if="iconState != 'pairplot' "
                      ></a-icon>
                    </div>
                  </a-col>
                </a-row>
                <!--描述性分析-->
                <!--定量-->
                <div
                  style="margin-top: 10px"
                  v-if="iconState === 'AnysisV' && anysisVData !== null && anysisVData.datasource.length > 0"
                >
                  <div style="font-weight: bold; margin: 10px auto; font-size: 18px">定量</div>
                  <a-table
                    bordered
                    size="small"
                    rowKey="key"
                    :columns="anysisVData.columns1"
                    :data-source="anysisVData.datasource"
                    :pagination="false"
                  >
                    <div slot="基础指标" style="font-weight: bold">基础指标</div>
                    <span slot="number" slot-scope="text, record">
                      <template>
                        <span>{{ record['平均值'] + '±' + record['标准差'] }}</span>
                      </template>
                    </span></a-table
                  >
                  <a-table
                    style="margin-top: -1px"
                    bordered
                    size="small"
                    rowKey="key"
                    :columns="anysisVData.columns2"
                    :data-source="anysisVData.datasource"
                    :pagination="false"
                  >
                    <div slot="深入指标" style="font-weight: bold">深入指标</div>
                    <div slot="zhixinTitle">
                      <div>均值置信区间</div>
                      <div style="font-size: 8px; color: grey">（置信度：95%）</div>
                    </div>
                    <span slot="zhixin" slot-scope="text, record">
                      <template>
                        <span>{{ '(' + record['置信区间'][0] + ',' + record['置信区间'][1] + ')' }}</span>
                      </template>
                    </span></a-table
                  >
                </div>
                <!--定类-->
                <div>
                  <div v-if="iconState === 'AnysisV' && anysisCData !== null && anysisCData.datasource.length > 0">
                    <div style="font-weight: bold; margin-top: 20px; font-size: 18px">定类</div>
                    <a-table
                      size="small"
                      style="margin: 10px auto"
                      :key="i"
                      bordered
                      rowKey="key"
                      :columns="anysisCData.columns"
                      :data-source="anysisCData.datasource"
                      :pagination="false"
                    ><div slot="zhixinTitle">
                      <div>置信区间</div>
                      <div style="font-size: 8px; color: grey">（置信度：95%）</div>
                    </div></a-table
                    >
                  </div>
                </div>
                <!--相关系数表格-->
                <div
                  v-if="
                    iconState === 'Phi' ||
                      iconState === 'Pearson' ||
                      iconState === 'Spearman' ||
                      iconState === 'Kendall'
                  "
                >
                  <a-table
                    v-if="dataChart && dataChart.length > 0"
                    style="margin-top: 20px"
                    bordered
                    size="small"
                    rowKey="key"
                    :columns="columnsChart"
                    :data-source="dataChart"
                    :pagination="false"
                    :scroll="{ x: true }"
                  ></a-table>
                </div>
                <a-row v-if="!this.iconState">
                  <my-select></my-select>
                </a-row>
              </div>
            </a-layout-content>
          </a-col>
        </a-row>
      </a-layout>
    </div>
    <Settitle ref="ruleForm" :visible="titleVisible" :data="titledata" @cancel="handleCancel" @ok="handleOk"></Settitle>
  </page-header-wrapper>
</template>
<script>
import linegraph from './components/ChartLineGraph'
import Field from './components/field'
import mySelect from './components/select'
import SetTitle from './components/settitle'
import { DropClass, AutoHeight, tubiaoopacity } from '@/utils/util'
import { postChartlog } from '@/api/log'
import Sample from './components/sample.vue'
import { DataFilterClass } from '@/utils/dataFilterClass'
import ChartClass from '@/commonmodules/chart/index'
import pick from 'lodash.pick'
import Settitle from './components/settitle.vue'

// 列
const columnsHeatmap = [
  {
    title: '显著相关(0.5<|r|≤0.8)',
    dataIndex: 'xz',
    scopedSlots: { customRender: 'xg' },
    align: 'center',
    width: 100
  },
  {
    title: '高度相关(0.8<|r|<1)',
    dataIndex: 'gd',
    scopedSlots: { customRender: 'xg' }
  }
]
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}

export default {
  created() {
    this.autoHeightChange()
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
        this.setAutowidth()
      })
    })
  },
  data() {
    return {
      downloadsrc: {},
      infos: [],
      titleVisible: false,
      titledata: {},
      errMessage: false, // 数据量很大时，无法使用图形的提示
      chartLoad: false, // 图表加载中提示
      zdisabled: false,
      mdisabled: false,
      ScatterTest: '请拖入左侧变量（个数≤1个）', // 散点图分组的提示显示
      boxTest: '请拖入左侧变量（个数≤2个）', // 箱线图分组提示
      roc: '',
      count: this.$store.state.samples.keys ? JSON.parse(this.$store.state.samples.keys).length : 0, // 样本数量,
      formLayout,
      anysisVData: {
        columns1: [],
        columns2: [],
        datasource: []
      }, // 描述性分析数据
      cxz: [], // 显著相关
      cgd: [], // 高度相关
      anysisCData: {
        columns: [],
        datasource: []
      }, // 定类描述性分析 列
      columnsHeatmap,
      screenWidth: document.body.clientWidth,
      x: [],
      y: [],
      z: [],
      m: [],
      columnsChart: [], // 图表数据的column
      dataChart: [], // 图表数据
      xtype: '',
      ytype: '',
      ztype: '',
      helpx: [],
      helpy: [],
      helpz: [],
      helpm: [],
      check: {},
      pieData: [],
      data: [],
      optionList: [],
      option: {},
      anysisLoading: false,
      lasticonState: '', // 上次选的图表类型
      keys: this.$store.state.samples.keys && JSON.parse(this.$store.state.samples.keys),
      quanzhong: true,
      documents: '',
      // 图标列表
      iconsList: [
        {
          name: 'bingtu',
          cName: '饼图',
          action: 'pie',
          state: false, // 是否选中
          enable: true, // 是否可用
          type: 'common', // 常用图表
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc'],
          help: '需要1-2个定类指标或者1个定量指标和一个定类指标',
          check: {
            x: {
              num: 2,
              type: ['量', '类'],
              message: `请向横轴拖入最多一个定类变量`
            },
            y: {
              num: 0,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'zhuzhuangtu',
          cName: '柱状图',
          action: 'bar',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc'],
          help: '需要1-2个定类指标或者1个定量指标和一个定类指标',
          check: {
            x: {
              num: 2,
              type: ['量', '类'],
              message: `请向横轴拖入最多一个定类变量`
            },
            y: {
              num: 0,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'tiaoxingtu',
          cName: '条形图',
          action: 'bar2',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc'],
          help: '需要1-2个定类指标或者1个定量指标和一个定类指标',
          check: {
            x: {
              num: 2,
              type: ['量', '类'],
              message: `请向横轴拖入最多一个定类变量`
            },
            y: {
              num: 0,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'sandiantu',
          cName: '散点图',
          action: 'Scatter',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['cc', 'v', 'vv', 'vc', 'vcc', 'vvc', 'vvcc'],
          help: '需要两个指标',
          check: {
            x: {
              num: 1,
              type: ['量', '类'],
              message: `请向横轴拖入一个变量`
            },
            y: {
              num: 1,
              type: ['量', '类'],
              message: `请向纵轴拖入一个变量`
            },
            z: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            },
            m: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            }
          }
        },

        {
          name: 'zhexiantu',
          cName: '折线图',
          action: 'Line',
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vv', 'vvc', 'rq'],
          help: '需要1个定量指标,1~2个定类指标',
          enable: true,
          check: {
            x: {
              num: 1,
              type: ['量', '日期'],
              message: `请向横轴拖入一个变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向纵轴拖入一个变量`
            },
            z: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            }
          }
        },
        {
          name: 'xianxinhuigui',
          cName: '线性回归图',
          action: 'Regression',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vv', 'vvc'],
          help: '需要两个定量指标，1个定类分组',
          check: {
            x: {
              num: 1,
              type: ['量'],
              message: `请向横轴拖入一个变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向纵轴拖入一个变量`
            },
            z: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            }
          }
        },
        {
          name: 'heatmap',
          cName: '密度散点图',
          action: 'heatmap',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vv'],
          check: {
            x: {
              num: 1,
              type: ['量'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'doudongtu',
          cName: '抖动图',
          action: 'DDT',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vc', 'vvc', 'vvvc'],
          help: '需要1~5个定量指标,1个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },

        {
          name: 'zhifangtu',
          cName: '直方图',
          action: 'histogram',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vc', 'vvc', 'vvv', 'vvvc'],
          help: '需要1~5个定量指标,1个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },

        {
          name: 'midutu',
          cName: '密度图',
          action: 'MiDu',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vc', 'vvc', 'vvv', 'vvvc'],
          help: '需要1~5个定量指标,1个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'xiangxiantu',
          cName: '箱线图',
          action: 'Boxplot',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vc', 'vcc', 'vvc', 'vvv', 'vvvc'],
          help: '需要1~5个定量指标,1~2个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 2,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          // name: 'paleituotu',
          name: 'wuchatiaotu',
          cName: '误差条图',
          action: 'ErrorBar',
          state: false,
          enable: true,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vc', 'vcc', 'vvc', 'vvv', 'vvvc'],
          help: '需要1~5个定量指标,1~2个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 2,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'xiaotiqintu',
          cName: '小提琴图',
          action: 'Violin',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vc', 'vcc', 'vvc', 'vvv', 'vvvc'],
          help: '需要1~5个定量指标，1个定类指标',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 2,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'juzhentu',
          cName: '矩阵图',
          action: 'pairplot',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vv', 'vvc', 'vvv', 'vvvc'],
          help: '需要至少两个定量指标，分组是定类指标。<br/>注意：数据量很大的时候，不建议使用这个图',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向纵轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'roc',
          cName: 'roc曲线图',
          action: 'roc',
          enable: true,
          state: false,
          type: 'common',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['cc', 'vc', 'vvc', 'vcc', 'vvcc', 'vvccc', 'vvvc', 'vccc'],
          // help: '需要至少两个定量指标，分组是定类指标。\n注意：数据量很大的时候，不建议使用这个图',
          check: {
            x: {
              num: 5,
              type: ['量', '类'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },

        {
          name: 'qq',
          cName: 'QQ图',
          action: 'qq',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['v', 'vv', 'vvv'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'ciyuntu',
          cName: '词云',
          action: 'ciyun',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc', 'vcc', 'vccc', 'wb'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['类', '文本'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'juxingshutu',
          cName: '矩形树图',
          action: 'TreeMap',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc', 'vcc', 'vccc'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['类'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        // {
        //   name: 'loudoutu',
        //   cName: '漏斗图',
        //   action: 'funnel',
        //   enable: true,
        //   state: false,
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['c', 'cc', 'vc', 'vcc', 'vccc'],
        //   type: 'common',
        //   check: {
        //     x: {
        //       num: 5,
        //       type: ['类'],
        //       message: `请向X轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 1,
        //       type: ['量'],
        //       message: `请向Y轴拖入最多一个定类变量`
        //     }
        //   }
        // },

        {
          name: 'paihangbang',
          cName: '排行榜',
          action: 'rank',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'vc', 'vcc', 'vccc'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['类'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        // {
        //   name: 'GIF',
        //   cName: '动态排行榜',
        //   action: 'GIF',
        //   enable: true,
        //   state: false,
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['cc', 'vcc'],
        //   type: 'common',
        //   check: {
        //     x: {
        //       num: 1,
        //       type: ['类'],
        //       message: `请向X轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 1,
        //       type: ['量'],
        //       message: `请向Y轴拖入最多一个定类变量`
        //     },
        //     z: {
        //       num: 1,
        //       type: ['日期', '类'],
        //       message: `请向Y轴拖入最多一个定类变量`
        //     }
        //   }
        // },
        {
          name: 'duidiemianjitu',
          cName: '面积堆叠图',
          action: 'Area',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vc', 'vvc'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'leidatu',
          cName: '雷达图',
          action: 'Radar',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vc', 'vvc'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'pubutu',
          cName: '瀑布图',
          action: 'Waterfall',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['vc', 'vvc'],
          type: 'common',
          check: {
            x: {
              num: 5,
              type: ['量'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['类', '日期'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        {
          name: 'paleituotu',
          cName: '帕累托图',
          action: 'paleituotu',
          enable: true,
          state: false,
          opacity: 1, // 透明度默认是1
          help:
            '帕累托图又叫排列图、主次图，是按照各问题(选项)发生频率的大小顺序绘制的频数图，同时用来反映各问题(选项)的占比情况，通过图形找出最重要的问题(选项)出现的原因。<br/>如针对大学生考研动机的调查中，一个问题为：你考研的原因是什么？，该题目为一个多选题，在对这道题目分析的时候，可以将所有相关的选项作为变量拖入，采用帕累托图直观的展示出来。',
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: [],
          type: 'common',
          check: {
            x: {
              num: 10,
              type: ['类'],
              message: `请向X轴拖入最多一个定量变量`
            },
            y: {
              num: 1,
              type: ['量'],
              message: `请向Y轴拖入最多一个定类变量`
            }
          }
        },
        // {
        //   name: 'zhifangtu',
        //   cName: '积差相关系数',
        //   action: 'Pearson',
        //   state: false,
        //   enable: true,
        //   type: 'xgxs', // 相关系数
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['v', 'vv', 'vvv'],
        //   help: '需要1-10个定量指标',
        //   check: {
        //     x: {
        //       num: 10,
        //       type: ['量'],
        //       message: `请向横轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 0,
        //       type: ['量'],
        //       message: `请向纵轴拖入最多一个定量变量`
        //     }
        //   }
        // },
        // {
        //   name: 'zhifangtu',
        //   cName: '秩相关系数',
        //   action: 'Spearman',
        //   state: false,
        //   enable: true,
        //   type: 'xgxs',
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['v', 'vv', 'vvv'],
        //   help: '需要1-10个定量指标',
        //   check: {
        //     x: {
        //       num: 10,
        //       type: ['量'],
        //       message: `请向横轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 0,
        //       type: ['量'],
        //       message: `请向纵轴拖入最多一个定量变量`
        //     }
        //   }
        // },
        // {
        //   name: 'Kendall',
        //   cName: '等级相关系数',
        //   action: 'Kendall',
        //   state: false,
        //   enable: true,
        //   type: 'xgxs',
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['v', 'vv', 'vvv'],
        //   help: '需要1-10个定量指标或者定序指标',
        //   check: {
        //     x: {
        //       num: 10,
        //       type: ['量'],
        //       message: `请向横轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 0,
        //       type: ['量'],
        //       message: `请向纵轴拖入最多一个定量变量`
        //     }
        //   }
        // },
        // {
        //   name: 'zhifangtu',
        //   cName: '二分变数相关系数',
        //   action: 'Phi',
        //   state: false,
        //   enable: true,
        //   type: 'xgxs',
        //   opacity: 1, // 透明度默认是1
        //   // 兼容的变量类型
        //   // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
        //   // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
        //   fieldtype: ['c', 'cc', 'ccc'],
        //   help: '需要1-10个定类指标，且每个定类指标只能有两种类别',
        //   check: {
        //     x: {
        //       num: 10,
        //       type: ['类'],
        //       optLimit: 2,
        //       message: `请向横轴拖入最多一个定量变量`
        //     },
        //     y: {
        //       num: 0,
        //       type: ['类'],
        //       message: `请向纵轴拖入最多一个定量变量`
        //     }
        //   }
        // },
        {
          name: 'miaoshuxingfenxi',
          cName: '描述性分析',
          action: 'AnysisV',
          state: false,
          enable: true,
          type: 'anysis', // 描述性分析
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'v', 'vc', 'vcc', 'vv', 'vvc', 'vvcc', 'vvccc', 'vvv', 'vvvc'],
          help: '需要1-10个指标',
          check: {
            x: {
              num: 10,
              type: ['量', '类'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 0,
              type: ['量'],
              message: `请向纵轴拖入最多一个定量变量`
            }
          }
        },
        {
          name: 'table',
          cName: '表格',
          action: 'table',
          state: false,
          enable: true,
          type: 'table', // 描述性分析
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['c', 'cc', 'v', 'vc', 'vcc', 'vv', 'vvc', 'vvcc', 'vvccc', 'vvv', 'vvvc'],
          help: '需要1-10个指标',
          check: {
            x: {
              num: 10,
              type: ['量', '类'],
              message: `请向横轴拖入最多一个定量变量`
            },
            y: {
              num: 10,
              type: ['量', '类'],
              message: `请向纵轴拖入最多一个定量变量`
            },
            z: {
              num: 10,
              type: ['量'],
              message: `请向纵轴拖入最多一个定量变量`
            }
          }
        },
        {
          name: 'sandiantu_shixu',
          cName: '散点图',
          action: 'Scatter_shixu',
          state: false,
          enable: true,
          type: 'shixu',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['sxv'],
          help: '需要两个指标',
          check: {
            x: {
              num: 1,
              type: ['时序量'],
              message: `请向横轴拖入一个变量`
            },
            y: {
              num: 0,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            }
          }
        },

        {
          name: 'zhexiantu_shixu',
          cName: '折线图',
          action: 'Line_shixu',
          state: false,
          type: 'shixu',
          opacity: 1, // 透明度默认是1
          // 兼容的变量类型
          // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
          // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
          fieldtype: ['sxv'],
          help: '需要1个定量指标,1~2个定类指标',
          enable: true,
          check: {
            x: {
              num: 1,
              type: ['时序量'],
              message: `请向横轴拖入一个变量`
            },
            y: {
              num: 0,
              type: ['类'],
              message: `请向纵轴拖入一个变量`
            }
          }
        }
      ],
      // 控制点击分析以后生成什么类型的图表
      iconState: '',
      // 图表下面的文字提示
      iconHelp: '',
      // 控制图表类型名字
      chartsType: '',
      roc_exchange: false,
      box_exchange: false,
      violin_exchange: false,
      ddt_exchange: false,
      // 变量类型，默认没有，只有在变量发生改变时，值才会改变
      // c:1个定类 cc:2个定类  v:1个定量 vv:多个定量 vc:1个定量+1个定类 vcc:1个定量+2个定类
      // vvc:多个定量+1个定类 vvcc:多个定量+2个定类 vvccc:多个定量+多个定类（超过2个） roc专用
      fieldtype: '',
      sxfields: this.$store.state.samples.sxFields,
      sxtime: null,
      sxfun: 'sum', // 时序的计算方法
      sxrange: '1', // 时序的统计范围，个人还是整体（只针对术后疼痛这个问卷）
      type: this.$store.state.samples.type,
      ctypex: 'single', // 表格制作时，定类是交叉还是单独显示 merge/single
      ctypey: 'single', // 表格制作时，定类是交叉还是单独显示 merge/single
      vtype: 'detail', // 表格制作时，定量是详细的还是简单的数据求和 detail/sum
      zfuntype: 'sum', // 表格制作时，度量是求和还是平均值 avg/sum
      dataType: 'default', // 日期类型的统计方式，默认是自己的值
      lineType: 'avg', // 折线图的统计方式，默认是平均值
      barType: 'percent', // 柱状图和饼图显示百分比还是个数
      barOption: [],
      barOrder: false,
      cp: true, // 累积占比,默认为
      percent: false
    }
  },

  components: { linegraph, Field, mySelect, Sample, SetTitle, Settitle },
  methods: {
    handeldownload(item) {
      this.downloadsrc = { key: item.key }
      this.anysis(this.infos, true)
    },
    /**
     * 单独一张表设置取消
     */
    handleCancel() {
      this.titleVisible = false
    },
    /**
     * 单独一张表设置保存
     */
    handleOk() {
      var infos = this.optionList.map(r => {
        return { key: r.key, title: r.title, x: r.x, y: r.y, labels: r.labels, legend: r.legend }
      })
      var form = this.$refs.ruleForm.data
      this.infos = infos.map(r => {
        if (r.key === form.key) {
          r = form
        }
        return r
      })
      this.anysis(this.infos)
      this.titleVisible = false
    },
    settitleClick(item) {
      this.titleVisible = true
      var aa = JSON.parse(JSON.stringify(item))
      this.titledata = {
        title: aa.title,
        x: aa.x,
        y: aa.y,
        z: aa.z,
        action: this.iconState,
        key: aa.key,
        labels: aa.labels,
        legend: aa.legend
      }
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      var max = ['chartContent', 'divContentleft', 'field']
      var min = ['divContent', 'divContentleft', 'field']
      AutoHeight(this, min, max, 176)
    },
    /**
     * 转置
     */
    retweet() {
      this.infos = []
      if (this.iconState === 'pie' || this.iconState === 'bar' || this.iconState === 'bar2') {
        var a = this.x
        var b = []
        b[0] = a[1]
        b[1] = a[0]
        this.x = b
        if (this.x.length === 2 && this.x[0].type === '类' && this.x[1].type === '类') {
          this.barOption = this.x[0].option
        }
      } else if (this.iconState === 'Violin') {
        this.violin_exchange = !this.violin_exchange
      } else if (this.iconState === 'roc') {
        this.roc_exchange = !this.roc_exchange
      } else if (this.iconState === 'Boxplot') {
        this.box_exchange = !this.box_exchange
      } else if (this.iconState === 'DDT') {
        this.ddt_exchange = !this.ddt_exchange
      } else if (this.iconState === 'table') {
        var x = this.x
        var y = this.y
        this.x = y
        this.y = x
        var ctypex = this.ctypex
        var ctypey = this.ctypey
        this.ctypey = ctypex
        this.ctypex = ctypey
        if (this.x.length > 0 && this.x[0].type === '量') {
          this.ytype = '定类'
          this.xtype = '定量/定类'
        } else if (this.y.length > 0 && this.y[0].type === '量') {
          this.xtype = '定类'
          this.ytype = '定量/定类'
        }
      } else {
        b = []
        b[0] = this.x[0]
        b[1] = this.y[0]
        this.x[0] = b[1]
        this.y[0] = b[0]
      }
      this.anysis(this.infos)
    },
    getHtml(text) {
      if (text) {
        var v = JSON.parse(text)
        var reuslt = ''
        v.map(r => {
          reuslt = reuslt + r + '<br/>'
        })
        return reuslt
      }
    },
    // 在一个拖动过程中，释放鼠标键时触发此事件
    drop(mark) {
      this.infos = []
      if (Object.keys(this.check).length !== 0) {
        // 选中图后才可以拖动
        const djson = event.dataTransfer.getData('item')
        const data = JSON.parse(djson)
        // 当拖动项为x轴或者y轴，并且领一轴已经有值时，需要情况分组，并修改提示
        if (
          this.iconState === 'Scatter' &&
          ((mark === 'x' && this.y.length > 0 && this.leiorLiang(data.fieldType) === '类' && this.y[0].type === '类') ||
            (mark === 'y' && this.x.length > 0 && this.leiorLiang(data.fieldType) === '类' && this.x[0].type === '类'))
        ) {
          this.m = []
          this.z = []
          this.mdisabled = true
          this.zdisabled = true
        }
        if (this.iconState === 'pie' || this.iconState === 'bar' || this.iconState === 'bar1') {
          if (this.x.length === 1 && this.x[0].type === '量' && this.leiorLiang(data.fieldType) === '量') {
            this.$message.warning('最多只能有一个定量')
            return
          }
        }
        if (this.iconState === 'pairplot') {
          if (this.x.filter(r => r.name === data.fieldName).length > 0) {
            this.$message.warning('矩阵图请不要使用相同的变量')
            return
          }
        }
        if (this.iconState === 'heatmap') {
          if (
            (this.x.filter(r => r.name === data.fieldName).length > 0 && mark === 'y') ||
            (this.y.filter(r => r.name === data.fieldName).length > 0 && mark === 'x')
          ) {
            this.$message.warning('热力图x和y请不要用相同的变量')
            return
          }
        }
        if (this.iconState === 'paleituotu') {
          // 如果x轴中已经有变量了，继续拖的话，必须保证x轴的变量都是多选的定类，选项只有“是”和“否”
          if (mark === 'x' && this.x.length > 0) {
            if (!(this.x[0].option.toString() === ['是', '否'].toString() || this.x[0].option.toString() === ['否', '是'].toString() || data.fieldType === '多选')) {
              this.$message.warning('只有变量的分类只有“是”和“否”的才可以拖入多个')
              return
            }
            if (this.y.length > 0) {
              this.$message.warning('权重只能和一个定类匹配,如果想拖入多个定类，请先删除权重')
              return
            }
          }
          if (mark === 'y' && this.x.length > 1) {
            this.$message.warning('权重只能和一个定类匹配')
            return
          }
        }
        if (
          this.iconState === 'Scatter' &&
          ((mark === 'x' && this.y.length > 0 && this.leiorLiang(data.fieldType) === '类' && this.y[0].type === '量') ||
            (mark === 'x' &&
              this.y.length > 0 &&
              this.leiorLiang(data.fieldType) === '量' &&
              this.y[0].type === '类') ||
            (mark === 'y' &&
              this.x.length > 0 &&
              this.leiorLiang(data.fieldType) === '类' &&
              this.x[0].type === '量') ||
            (mark === 'y' && this.x.length > 0 && this.leiorLiang(data.fieldType) === '量' && this.x[0].type === '类'))
        ) {
          this.m = []
          this.mdisabled = true
        }
        // 散点图，x轴和y轴都是定类，当拖入分组时，只能拖入x轴或者Y轴，需要进行判断
        if (
          this.iconState === 'Scatter' &&
          this.x.length > 0 &&
          this.x[0].type === '类' &&
          this.y.length > 0 &&
          this.y[0].type === '类' &&
          (mark === 'z' || mark === 'm')
        ) {
          if (data.fieldName !== this.x[0].name && data.fieldName !== this.y[0].name) {
            this.$message.warning('散点图当x轴y轴都为定类时，分组请选择x轴或者y轴')
            return
          }
        }
        // 表格数据同一方向类型必须一样
        if (
          this[mark].length > 0 &&
          this[mark][this[mark].length - 1].type !== this.leiorLiang(data.fieldType) &&
          this.iconState === 'table'
        ) {
          this.$message.warning('表格同一个方向的类型必须一样')
          return
        }
        // 表格数据不同的方向类型必须不一样
        if (
          ((mark === 'x' &&
            this.y.length > 0 &&
            this.leiorLiang(data.fieldType) === this.y[0].type &&
            this.y[0].type === '量') ||
            (mark === 'y' &&
              this.x.length > 0 &&
              this.leiorLiang(data.fieldType) === this.x[0].type &&
              this.x[0].type === '量')) &&
          this.iconState === 'table'
        ) {
          this.$message.warning('表格两个方向不能都为定量')
          return
        }
        var title = ''
        switch (this.iconState) {
          case 'Boxplot':
            title = '箱线图'
            break
          case 'ErrorBar':
            title = '误差条图'
            break
        }
        // 箱线图，x轴有2个定量时，分组只能有一个需要进行判断
        if (
          (this.iconState === 'Boxplot' || this.iconState === 'ErrorBar' || this.iconState === 'Violin') &&
          this.x.length > 1 &&
          mark === 'y' &&
          this.y.length > 0
        ) {
          this.$message.warning(title + '分析项有多个时，分组只能有一个')
          return
        }
        if (
          (mark === 'm' && this.z.length > 0 && this.z[0].name === data.fieldName) ||
          (mark === 'z' && this.m.length > 0 && this.m[0].name === data.fieldName)
        ) {
          // 散点图的分组有形状和颜色
          this.$message.warning('分组变量不能重复')
          return
        }
        var drop = new DropClass(
          this[mark],
          data,
          this[`help${mark}`],
          this.$message,
          this.check[mark].message,
          this,
          false,
          null,
          mark
        )
        this.check[mark].location = mark
        this.check[mark].vm = this
        drop.check(this.check[mark])
        // if (result !== false) {
        //   GetDocuments(data, this, this.callReturn)
        // }
      } else {
        this.$message.info('请选择一种图表类型再拖动')
      }
    },
    callReturn(that) {
      that.documents = this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents)
      that.anysisLoading = false
      // drop.check(this.check[mark], true)
    },
    /**
     * 判断是定类还是定量
     */
    leiorLiang(stype) {
      var type = ''
      switch (stype) {
        case '数字':
        case '日期':
          type = '量'
          break
        case '单选':
          type = '类'
          break
        case '多选':
          type = '类'
          break
        case '下拉框':
          type = '类'
          break
        default:
          type = ''
      }
      return type
    },
    // 删除标签
    closeTag(e, mark, i) {
      this.infos = []
      if (mark === 'x') {
        this.x.splice(i, 1)
        this.ScatterTest = '请拖入左侧变量（个数≤1个）'
        if (
          (this.iconState === 'Boxplot' || this.iconState === 'ErrorBar' || this.iconState === 'Violin') &&
          this.x.length < 2
        ) {
          this.boxTest = '请拖入左侧变量（个数≤2个）'
        }
        this.mdisabled = false
        this.zdisabled = false
        if (this.iconState === 'table' && this.x.length > 0 && this.x[0].type === '类') {
          if (this.x.length !== 2) {
            this.ctypex = 'single'
          }
        }
        if (this.iconState === 'table' && this.x.length === 0) {
          this.ytype = '定量/定类'
        }
        if (this.iconState === 'Line') {
          this.dataType = 'default'
          this.lineType = 'avg'
        }
        if (this.iconState === 'bar' || this.iconState === 'bar2') {
          this.barOption = []
        }
        console.log('x: ', JSON.stringify(this.x))
      } else if (mark === 'z') {
        this.z.splice(i, 1)
      } else if (mark === 'm') {
        this.m.splice(i, 1)
      } else {
        this.y.splice(i, 1)
        if (this.iconState === 'table' && this.y.length > 0 && this.y[0].type === '类') {
          if (this.y.length !== 2) {
            this.ctypey = 'single'
            this.z = []
          }
        }
        if (this.iconState === 'table' && this.y.length === 0) {
          this.xtype = '定量/定类'
        }
        this.ScatterTest = '请拖入左侧变量（个数≤1个）'
        this.mdisabled = false
        this.zdisabled = false
        console.log('y: ', JSON.stringify(this.y))
      }
      tubiaoopacity(this)
    },
    // YMD日期点击
    YearMD() {
      if (this.x.length > 0 && this.y.length > 0) {
        this.anysis()
      }
    },
    // 点击图标
    clickIcon(item) {
      console.log(this.screenWidth)
      if (this.lasticonState !== item.action) {
        this.barOrder = false
        this.infos = []
        this.anysisDataV = { columns1: [], columns2: [], datasource: [] } // 描述性分析数据
        this.anysisVData = {
          columns: [],
          datasource: []
        }
        this.optionList = []
        this.dataChart = []
        this.cxz = []
        this.cgd = []
        this.ScatterTest = '请拖入左侧变量（个数≤1个）'
        this.mdisabled = false
        this.zdisabled = false
        this.chartLoad = false
        this.errMessage = false
        this.box_exchange = false
        this.violin_exchange = false
        this.ddt_exchange = false
      }
      // 改变图标行为状态
      this.iconState = item.action
      // 把所有图标状态都变成默认
      var iconsList = this.iconsList
      iconsList.forEach(v => {
        v.state = false
      })
      this.chartsType = item.cName
      // 改变图标下方提示文字
      this.iconHelp = item.help
      // 当前点击的图标的下标
      var index = iconsList.indexOf(item)
      iconsList[index].state = true
      // 改变当前的拖动校验信息
      this.check = item.check
      this.xtype = this.showTips(item.check.x.type)
      this.ytype = this.showTips(item.check.y.type)
      this.ztype = item.check.z ? this.showTips(item.check.z.type) : []
      // 如果opacity=0.5的话，说明这个图是不兼容当前变量规则的，则需要清空
      if (item.opacity === 0.5) {
        this.x = []
        this.y = []
        this.z = []
        this.m = []
        tubiaoopacity(this)
        this.dataType = 'default'
        this.lineType = 'avg'
      } else {
        tubiaoopacity(this)
        var fields = this.x
          .concat(this.y)
          .concat(this.z)
          .concat(this.m)
        if (this.lasticonState !== item.action && this.lasticonState !== '' && fields.length > 0) {
          // 否则直接出图
          this.setFieldAnysis(this.fieldtype, item)
          this.anysis()
          this.dataType = 'default'
          this.lineType = 'avg'
        }
      }

      this.lasticonState = item.action
      if (this.iconState === 'Scatter') {
        this.setAutowidth()
      }
    },
    // 给图表赋变量值，并分析
    setFieldAnysis(type, item) {
      var fields = this.x
        .concat(this.y)
        .concat(this.z)
        .concat(this.m)
      var cF = fields.filter(r => r.type === '类')
      var vF = fields.filter(r => r.type === '量')
      // var wbF = fields.filter(r => r.type === '文本')
      // var rqF = fields.filter(r => r.type === '日期')
      // var xF = fields.filter(r => r.forOrder === 1)
      if (item.action === 'table') {
        this.x = cF
        this.y = vF
        this.z = []
        this.m = []
        this.ctypex = 'single'
        this.ctypey = 'single'
        this.vtype = 'detail'
        if (this.x.length > 0 && this.x[0].type === '量') {
          this.ytype = '定类'
        } else if (this.y.length > 0 && this.y[0].type === '量') {
          this.xtype = '定类'
        }
      } else {
        // 变量允许的类型，和现在所选变量类型
        var types = item.fieldtype.filter(r => type.indexOf(r) > -1)
        if (types.indexOf('c') > -1) {
          // 0个定量+1个定类
          this.x = fields
          this.y = []
          this.x.map(r => {
            r.type = '类'
          })
        } else if (types.indexOf('cc') > -1) {
          // 0个定量+2个定类
          fields.map(r => {
            r.type = '类'
          })
          if (
            item.action === 'pie' ||
            item.action === 'bar' ||
            item.action === 'bar2' ||
            item.action === 'AnysisV' ||
            item.action === 'Phi' ||
            item.action === 'Kendall' ||
            item.action === 'table'
          ) {
            this.x = fields
            if ((item.action === 'bar' || item.action === 'bar2') && this.x.length > 0 && this.x[0].type == '类' && this.x[1].type === '类') {
              this.barOption = this.x[0].option
            } else {
              this.barOption = []
            }
            this.y = []
          } else if (item.action === 'Scatter' || item.action === 'roc') {
            this.roc = ''
            this.x = [fields[0]]
            this.y = [fields[1]]
            this.mdisabled = true
            this.zdisabled = true
          } else {
            this.x = fields
            this.y = []
          }
        } else if (types.indexOf('v') > -1) {
          // 1个定量+0个定类
          this.x = fields
          this.x.map(r => {
            r.type = '量'
          })
          this.y = []
        } else if (types.indexOf('vc') > -1) {
          // 1个定量+1个定类
          if (
            item.action === 'pie' ||
            item.action === 'bar' ||
            item.action === 'bar2' ||
            item.action === 'AnysisV' ||
            item.action === 'Kendall'
          ) {
            this.x = fields
            this.y = []
            this.z = []
          } else if (
            item.action === 'table' ||
            item.action === 'ciyun' ||
            item.action === 'rank' ||
            item.action === 'funnel' ||
            item.action === 'TreeMap'
          ) {
            this.x = cF
            this.y = vF
            this.z = []
            this.m = []
          } else {
            this.x = vF
            this.y = cF
            this.z = []
          }
        } else if (types.indexOf('vcc') > -1) {
          // 1个定量+2个定类
          if (item.action === 'Scatter') {
            this.x = vF
            this.y = [cF[0]]
            this.z = [cF[1]]
          } else if (item.action === 'AnysisV' || item.action === 'Kendall') {
            this.x = fields
            this.y = []
            this.z = []
          } else if (item.action === 'roc') {
            this.roc = ''
            this.x = vF.concat(cF[0])
            this.y = [cF[1]]
            this.z = []
            this.m = []
          } else if (
            item.action === 'table' ||
            item.action === 'ciyun' ||
            item.action === 'rank' ||
            item.action === 'funnel' ||
            item.action === 'TreeMap'
          ) {
            this.x = cF
            this.y = vF
            this.z = []
            this.m = []
          } else {
            this.x = vF
            this.y = cF
            this.z = []
          }
        } else if (types.indexOf('vv') > -1) {
          fields.map(r => {
            r.type = '量'
          })
          if (
            item.action === 'Scatter' ||
            item.action === 'Line' ||
            item.action === 'Regression' ||
            item.action === 'heatmap'
          ) {
            this.x = [fields[0]]
            this.y = [fields[1]]
          } else {
            this.x = fields
            this.y = []
          }
          // 多个定量+0个定类
        } else if (types.indexOf('vvc') > -1) {
          if (item.action === 'Scatter' || item.action === 'Line' || item.action === 'Regression') {
            this.x = [vF[0]]
            this.y = [vF[1]]
            this.z = cF
            this.m = []
          } else if (item.action === 'AnysisV' || item.action === 'Kendall') {
            this.x = fields
            this.y = []
            this.z = []
            this.m = []
          } else if (item.action === 'table') {
            this.x = cF
            this.y = vF
            this.z = []
            this.m = []
          } else {
            this.x = vF
            this.y = cF
            this.z = []
            this.m = []
          }
          // 多个定量+1个定类
        } else if (types.indexOf('vvcc') > -1) {
          // 多个定量+2个定类
          if (item.action === 'roc') {
            this.roc = ''
            this.x = vF.concat(cF[0])
            this.y = [cF[1]]
            this.z = []
            this.m = []
          } else if (item.action === 'AnysisV' || item.action === 'Kendall') {
            this.x = fields
            this.y = []
            this.z = []
            this.m = []
          } else if (item.action === 'table') {
            this.x = cF
            this.y = vF
            this.z = []
            this.m = []
          } else {
            this.x = [vF[0]]
            this.y = [vF[1]]
            this.z = [cF[0]]
            this.m = [cF[1]]
          }
        } else if (types.indexOf('vvv') > -1) {
          fields.map(r => {
            r.type = '量'
          })
          this.x = fields
          // 多个定量+0个定类
        } else if (types.indexOf('vvvc') > -1) {
          // 多个定量+1个定类
          if (item.action === 'AnysisV' || item.action === 'Kendall') {
            this.x = fields
            this.y = []
            this.z = []
          } else {
            this.x = vF
            this.y = cF
          }
        } else if (types.indexOf('vvccc') > -1) {
          if (item.action === 'AnysisV' || item.action === 'Kendall') {
            this.x = fields
            this.y = []
            this.z = []
          } else {
            this.x = vF.concat(cF.filter((r, i) => i < cF.length - 1))
            this.y = [cF[cF.length - 1]]
          }
          // 多个定量+>2个定类
          // 目前只有roc图
        } else if (types.indexOf('vccc') > -1) {
          if (item.action === 'roc') {
            this.x = vF.concat(cF.filter((r, i) => i < cF.length - 1))
            this.y = [cF[cF.length - 1]]
            this.z = []
          } else if (item.action === 'ciyun' || item.action === 'rank') {
            this.x = cF
            this.y = vF
          }
        }
      }
    },
    showTips(type) {
      var result = ''
      type.map((r, i) => {
        if (r === '时序量') {
          result = result + (i > 0 ? '/' : '') + '时序定量'
        } else if (r === '时序量') {
          result = result + (i > 0 ? '/' : '') + '时序定类'
        } else if (r === '文本' || r === '日期') {
          result = result + (i > 0 ? '/' : '') + r
        } else {
          result = result + (i > 0 ? '/' : '') + '定' + r
        }
      })

      return result
    },
    /**
     * 动态设置两个分组的宽度
     */
    setAutowidth() {
      this.$nextTick(() => {
        var groupC = document.getElementById('groupC')
        var groupC1 = document.getElementById('groupC1')
        var groupC2 = document.getElementById('groupC2')
        if (groupC && groupC1 && groupC2) {
          var width = (groupC.clientWidth - 189 * 2) / 2 + 'px'
          groupC1.style.minWidth = width
          groupC2.style.minWidth = width
        }
      })
    },
    // 点击分析
    anysis(infos = [], isdownload = false) {
      if (isdownload === false) {
        this.anysisData = []
        this.anysisDataC = []
        this.optionList = []
        this.dataChart = []
        this.err = []
        // 清空optionList
        this.optionList.length = 0
      }

      // xyz所有的定量
      var list = this.x
        .concat(this.y)
        .concat(this.z)
        .concat(this.m)
      // 获取当前图表的类型
      var action = this.iconState

      var isSucess = false
      if (list.length > 0 && action) {
        if (action === 'Line' || action === 'Regression' || action === 'heatmap') {
          // 散点图||折线图
          if (this.x.length === 0 || this.y.length === 0) {
            this.$message.warning('分析项X轴和Y轴必填')
            return
          }
        }
        if (
          action === 'histogram' ||
          action === 'Boxplot' ||
          action === 'ErrorBar' ||
          action === 'MiDu' ||
          action === 'Violin'
        ) {
          // 直方图和箱线图和误差条图
          // 直方图横轴有且必须有一个定量
          if (this.x.length === 0) {
            this.$message.warning('定量分析项必填')
            return
          }
        }
        if (action === 'pairplot') {
          if (this.x.length < 2) {
            this.$message.warning('请至少选择2个分析项')
            return
          }
        }
        if (action === 'DDT' || action === 'Area' || action === 'Radar') {
          if (this.x.length === 0 || this.y.length === 0) {
            this.$message.warning('定类和定量都必须选择')
            return
          }
        }
        if (this.iconState === 'pie' || this.iconState === 'bar' || this.iconState === 'bar1') {
          if (this.x.length === 1 && this.x[0].type === '量') {
            this.$message.warning('当有定量时必须有一个定类')
            return
          }
        }
        if (isdownload === false) {
          if (
            action === 'Scatter' ||
            action === 'MiDu' ||
            action === 'Regression' ||
            action === 'Violin' ||
            action === 'pairplot' ||
            action === 'Pearson' ||
            action === 'Spearman' ||
            action === 'Kendall' ||
            action === 'heatmap' ||
            action === 'Line' ||
            action === 'Boxplot' ||
            action === 'pie' ||
            action === 'bar' ||
            action === 'Scatter_shixu' ||
            action === 'Line_shixu' ||
            action === 'qq' ||
            action === 'histogram' ||
            action === 'DDT' ||
            action === 'table' ||
            action === 'ciyun' ||
            action === 'rank' ||
            action === 'funnel' ||
            action === 'TreeMap' ||
            action === 'Radar' ||
            action === 'Waterfall' ||
            action === 'paleituotu'
          ) {
            this.chartLoad = true
          }
        }
        // 时序变量整理数据
        if (action === 'Scatter_shixu' || action === 'Line_shixu') {
          if (!this.sxtime) {
            this.$message.warning('请选择时序的时间分析项')
            this.chartLoad = false
            return
          }
        }
        var data = [] // 清洗后的数据
        if (action === 'table') {
          // 表格数据比较特殊，需要单独处理
          if (this.x.length === 0 || this.y.length === 0) {
            // 当只有单向有数据时
            var x = this.x.length === 0 ? this.y : this.x
            if (x.length === 2 && x[0].type === '类' && (this.ctypex === 'merge' || this.ctypey === 'merge')) {
              // 当定类数据有2个，且方式是交叉，例如，性别为‘男’的中民族为‘汉’的个数
              var ff = x
              data = this.pushdata(data, ff, x[0].name)
            } else {
              x.map(r => {
                var ff = [r]
                data = this.pushdata(data, ff, r.name)
              })
            }
          } else {
            if (this.x[0].type === '类' && this.y[0].type === '类') {
              if (this.z.length > 0) {
                // 有需要单独的求和项
                if (this.ctypex === 'merge' && this.ctypey === 'merge') {
                  // 两个放向都为合并数据
                  this.z.map(z => {
                    var ff = [...this.x.concat(this.y), z]
                    data = this.pushdata(data, ff, z.name, this.x[0].name, this.y[0].name)
                  })
                } else if (this.ctypex === 'single' && this.ctypey === 'single') {
                  // 两个放向都为单独数据
                  this.z.map(z => {
                    this.x.map(x => {
                      this.y.map(y => {
                        var ff = [x, y, z]
                        data = this.pushdata(data, ff, z.name, x.name, y.name)
                      })
                    })
                  })
                } else {
                  var mergeF = this.ctypex === 'merge' ? this.x : this.y
                  var singleF = this.ctypey === 'merge' ? this.x : this.y
                  this.z.map(z => {
                    singleF.map(r => {
                      var ff = [...mergeF, r, z]
                      data = this.pushdata(data, ff, z.name, r.name, mergeF[0].name)
                    })
                  })
                }
              } else {
                // 如果两个方向都是定类
                if (this.ctypex === 'merge' && this.ctypey === 'merge') {
                  // 两个放向都为合并数据
                  ff = this.x.concat(this.y)

                  data = this.pushdata(data, ff, this.x[0].name, this.y[0].name)
                } else if (this.ctypex === 'single' && this.ctypey === 'single') {
                  // 两个放向都为单独数据
                  this.x.map(x => {
                    this.y.map(y => {
                      var ff = [x, y]

                      data = this.pushdata(data, ff, x.name, y.name)
                    })
                  })
                } else {
                  mergeF = this.ctypex === 'merge' ? this.x : this.y
                  singleF = this.ctypey === 'merge' ? this.x : this.y
                  singleF.map(r => {
                    var ff = [...mergeF, r]
                    data = this.pushdata(data, ff, r.name, mergeF[0].name)
                  })
                }
              }
            } else {
              var fields = this.x.concat(this.y)
              var vF = fields.filter(r => r.type === '量') // 定量
              var cF = fields.filter(r => r.type === '类') // 定类
              if (this.ctypex === 'merge' || this.ctypey === 'merge') {
                // 当定类是重叠时
                vF.map(v => {
                  var ff = [v].concat(cF)
                  data = this.pushdata(data, ff, v.name)
                })
              } else {
                // 当定类是非重叠时
                vF.map(v => {
                  cF.map(c => {
                    var ff = [v, c]
                    data = this.pushdata(data, ff, v.name, c.name)
                  })
                })
              }
            }
          }
        } else {
          if (
            action === 'AnysisV' ||
            action === 'qq' ||
            action === 'histogram' ||
            action === 'MiDu' ||
            action === 'DDT' ||
            action === 'Scatter_shixu' ||
            action === 'Line_shixu' ||
            action === 'roc' ||
            action === 'ciyun' ||
            action === 'funnel' ||
            action === 'rank' ||
            action === 'TreeMap' ||
            action === 'Waterfall'
          ) {
            // 当为描述性分析的时候，需要对单项进行清洗
            this.x.map(r => {
              var flist = []
              const type = this.$store.state.samples.type
              if ((action === 'Scatter_shixu' || action === 'Line_shixu') && type !== 'questionnaire') {
                flist.push(this.sxtime.fieldName)
              }
              flist.push(r.name)
              if (
                (this.y.length > 0 && action !== 'ciyun') ||
                (action === 'ciyun' && r.type == '类' && this.y.length > 0)
              ) {
                flist.push(this.y[0].name)
                var d = new DataFilterClass([r, this.y[0]]).main().map(a => {
                  return pick(a, flist)
                })
                if (d.length > 0) {
                  data.push({
                    name: r.name,
                    data: d
                  })
                }
              } else {
                flist.push('key')
                d = new DataFilterClass([r]).main().map(a => {
                  return pick(a, flist)
                })
                if (d.length > 0) {
                  data.push({
                    name: r.name,
                    data: d
                  })
                }
              }
            })
          } else {
            // 数据清洗
            data = new DataFilterClass(list).main()
            data = data.map(r => {
              return pick(
                r,
                list.map(r => {
                  return r.name
                })
              )
            })
          }
        }
        if (data.length === 0) {
          this.$message.warning('当前变量没有合适的数据进行分析')
          this.chartLoad = false
          return
        }
        if (action === 'pairplot' && data.length > 3000) {
          this.errMessage = true
          this.chartLoad = false
          return
        }
        var change = false
        if (action === 'Boxplot') {
          change = this.box_exchange
        } else if (action === 'Violin') {
          change = this.violin_exchange
        } else if (action === 'roc') {
          change = this.roc_exchange
        } else if (action === 'DDT') {
          change = this.ddt_exchange
        } else if (action === 'Scatter_shixu' || action === 'Line_shixu') {
          change = this.sxtime
        }

        new ChartClass(
          data,
          {
            x: this.x,
            y: this.y,
            z: this.z,
            m: this.m,
            change: change,
            fun: this.sxfun,
            range: this.sxrange,
            isdownload,
            ctypex: this.ctypex,
            ctypey: this.ctypey,
            vtype: this.vtype,
            ztype: this.zfuntype,
            dataType: this.dataType,
            lineType: this.lineType,
            barType: this.barType,
            barOption: this.barOption,
            barOrder: this.barOrder,
            cp: this.cp,
            percent: this.percent
          },
          action,
          {},
          infos
        ).main(this, that => {
          that.chartLoad = false
        })

        console.log('optionList', this.optionList)
        isSucess = true
        if (this.iconState === 'Scatter') {
          // 散点图需要重新计算分组的宽度
          this.setAutowidth()
        }
      } else {
        this.$message.warning('分析项至少选择1个')
      }
      if (isSucess) {
        var xp = []
        var yp = []
        this.x.map(r => {
          xp.push(r.name)
        })
        this.y.map(r => {
          yp.push(r.name)
        })
        var parm = {
          Userid: this.$store.state.user.info.id,
          ChartType: this.chartsType,
          x: xp.join(','),
          y: yp.join(',')
        }
        postChartlog(parm)
      }
    },
    // 新增数据
    pushdata(data, fields, name, type1 = '', type2 = '') {
      var fdata = new DataFilterClass(fields).main().map(a => {
        return pick(
          a,
          fields.map(r => {
            return r.name
          })
        )
      })
      if (fdata.length > 0) {
        data.push({
          name: name,
          type: type1,
          type1: type2,
          data: fdata
        })
      }
      return data
    },
    /**
     * 刷新
     */
    refresh() {
      // 当已经点过分析的时候才刷新
      if (
        this.anysisData.length > 0 ||
        this.anysisDataC.length > 0 ||
        this.optionList.length > 0 ||
        this.dataChart.length > 0
      ) {
        this.anysis(this.infos)
      }
    }
  },
  computed: {
    outlier() {
      return this.$store.state.samples.filtersAll.outlier !== 0
    },
    without0() {
      return this.$store.state.samples.filtersSimple.without0 !== 0
    }
  },
  watch: {
    outlier() {
      this.refresh()
    },
    without0() {
      this.refresh()
    }
  }
}
</script>
<style lang="less" scoped>
.leftWidth {
  height: 100%;
}
.form-onlyRead {
  pointer-events: none;
}
.divB {
  border: 1px solid #dadada;
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.divBUnable {
  border: 1px solid #dadada;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  pointer-events: none;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f7f7f7;
}
.divBClicked {
  border: 1px solid #3f8ce8;
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.ant-card {
  min-height: 30px !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: none !important;
}
.ant-card-grid {
  padding: 10px !important;
}
.colC {
  border: 1px solid #dadada;
  padding: 10px;
  text-align: center;
  float: left;
}
.divContentleft {
  border-right: none !important;
}
body {
  overflow: hidden !important;
}
</style>
