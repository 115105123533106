import {
  getExamFields
, getHypothesis } from '@/api/hypothesis'
import {
  postHypothesislog
} from '@/api/log'
import {
  ParamStatistic
} from '@/views/hypothesisTest/utils/paramStatisticClass'
import { methods } from '@/common/enums'
import { GetDocuments } from './sampleManage'
import { DataFilterClass } from './dataFilterClass'
import jckf from '@/views/hypothesisTest/components/correlation/kffx/makeReq'
import store from '@/store/index'
import { translate } from '@/utils/language'
// import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
// const h = new Vue().$createElement
// const formatValue = (h, record, text) => {
//   return (record, text) => {
//     if (record.index === translate('置信区间')) {
//       return <span>({text[0]},{text[1]})</span>
//     } else if (record.index === translate('众数')) {
//       return (<div>
//         {text.map(value => {
//         return <div>{value}</div>
//       })}
//       </div>)
//     } else {
//       return {
//         children: text
//       }
//     }
// }
// }

export function get_formula(table, that) {
  const y_name = that.y_name
      let str_formula = `${y_name} ＝ `
      table.data.forEach((row, row_index) => {
        if (row_index === 0) {
          str_formula = `${str_formula}${row[translate('系数')].trim()}`
        } else {
          str_formula = `${str_formula}${+row[translate('系数')] > 0 ? ' ＋ ' : ' － '}${Math.abs(+row[translate('系数')])} × ${row[translate('变量')].trim()}`
        }
      })
      that.formula = str_formula
}

export function addClassForDom(dom, addClassAry) {
    let classAry = dom.getAttribute('class')
    classAry = classAry.concat(addClassAry)
    dom.setAttribute('class', classAry)
}

export function change_to_percent(up, down) {
  /** 将小数转换成百分比
   * @param up 分子
   * @param down 分母
   */
return (up * 100 / (down)).toFixed(1) + '%'
}
export function getPictureSize(module, sizePercent) {
  // 获取窗口宽度
  const clientWidth = store.state.app.clientWidth
  // 显示图片内容地方的宽度
  const contentWidth = {
    report: clientWidth,
    analysisOfRegression: (clientWidth - 24 * 2) / 24 * 19,
    hypothesis: (clientWidth - 24 * 2) / 24 * 19
  }
  // 获取图片在页面上的宽度（像素）
  const pictureWidth = contentWidth[module] * (sizePercent / 100)
  // 获取屏幕dpi
  const dpi = store.state.app.dpi
  // 将图片宽高的像素转换成figsize
  const figsize = [pictureWidth / dpi, pictureWidth / dpi / 5 * 3.5]
  return { figsize, dpi }
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}
/**
 * 动态调整页面的高度
 * @param {} min 最小高度id的集合
 * @param {} max 最高高度id的集合
 * @param {*} otherheight 其他节点的高度
 */
export function AutoHeight(current, min, max, otherheight) {
  current.$nextTick(function() {
    var height = document.body.clientHeight - otherheight + 'px'
    min.map(r => {
      const content = document.getElementById(r)
      if (content) { r === 'field' ? content.style.minHeight = ( document.body.clientHeight - otherheight - 73 + 'px') : content.style.minHeight = height }
    })
    max.map(r => {
      const content = document.getElementById(r)
      if (content) { r === 'field' ? content.style.maxHeight = ( document.body.clientHeight - otherheight - 73 + 'px') : content.style.maxHeight = height }
    })
  })
}

export function deepClone(obj) {
  var objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        // 判断obj的子元素是否为object对象，如果是则就递归拷贝
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          // 如果不为对象就直接拷贝
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

export function log(mark, info) {
  mark = mark || '无'
}

export class Req {
  constructor(form, method, that) {
    this.form = form
    this.method = method
    this.that = that
    this.addForm()
    this.makeReq()
    this.filter()
    if (typeof (this.form.xys[0][0]) === 'object') this.statisObj = this.msxfxReq()
  }
  // 数据过滤
  filter() {
    var list = []

    this.form.xys.forEach(v => {
      v.forEach(i => {
        list.push(i)
      })
    })
    console.log('lalalalalal')
      this.that.documents = new DataFilterClass( list, true).main()
  }
  // 构造req
  makeReq() {
    this.req = {}
    this.req.method = this.method
    this.req.args = {}
    this.req.args.form = this.form
  }
  // 给form添加参数judge2和tail
  addForm() {
    this.form.judge2 = this.that.$hypothesis.replaceJudge(this.form.judge)
    this.form.tail = this.that.$hypothesis.judgeTail(this.form.judge2)
  }
  // 构造描述性分析表需要的req结构,类多量1
  msxfxReq() {
    var statisObj = {}
    this.form.xys[0].forEach(j => {
      statisObj[j.slice(-1)[0]] = {}
      var j2 = deepClone(j)
      j2.pop()
      j2.forEach(m => {
        statisObj[j.slice(-1)[0]][m] = []
      })
    })
    this.statisObj = statisObj
    return statisObj
  }
  /// / 构造描述性分析表需要的req结构，量多
  descriptionLiangDuo(liangList) {
    var description = {}
    liangList.forEach(liang => {
      description[liang] = []
    })
    this.form.description = description
  }
}
// 假设检验里构造req的类
export class MakeReq extends Req {
  // eslint-disable-next-line no-useless-constructor
  constructor(form, method, that) {
    super(form, method, that)
    console.log('%c 🥥 form: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', this.form)
  }

  common() {
    return this.req
  }

  // 交叉卡方
  jckf() {
    jckf(this.form, this.that.documents)
    return this.req
  }
  // 配对样本t检验
  pdybtjy() {
    this.form.tList = [[], []]
    this.form.list = []
    const liangList = this.form.xys[0].concat(this.form.xys[1])
    this.descriptionLiangDuo(liangList)
    const liang1 = this.form.xys[0][0]
    const liang2 = this.form.xys[1][0]
    this.that.documents.forEach(element => {
      this.form.tList[0].push(parseFloat(element[liang1]))
      this.form.tList[1].push(parseFloat(element[liang2]))
      this.form.list.push(parseFloat(element[liang1]) - parseFloat(element[liang2]))
      new ParamStatistic(element, [], liangList, this.form.description).liangDuo()
    })
    return this.req
  }
  // 两个定量
  twoLiang() {
    this.form.list = [[], []]
    const liangList = this.form.xys[0].concat(this.form.xys[1])
    this.descriptionLiangDuo(liangList)
      this.that.documents.forEach(element => {
          this.form.list[0].push(parseFloat(element[this.form.xys[0][0]]))
          this.form.list[1].push(parseFloat(element[this.form.xys[1][0]]))
          new ParamStatistic(element, [], liangList, this.form.description).liangDuo()
      })
    return this.req
  }
  // 一个定类，一个定量
  leiLiang() {
    this.form.list = []
    // // 重构以后数据结构改变
    // this.form.xys[0] = this.form.xys[0][0]
    // //
    var xys = this.form.xys
    for (var i = 0; i < xys[0][0].length - 1; i++) {
      this.form.list.push([])
    }
      var optionList = xys[0][0].slice(0, xys[0][0].length - 1)
      var lei = xys[0][0][xys[0][0].length - 1]
      var liang = xys[1][0]
      this.that.documents.forEach(element => {
        // 确保每个人性别有值，并且每个人年龄也有值
          const index = optionList.indexOf(element[lei])
              const value = isNaN(Number(element[liang])) ? element[liang] : Number(element[liang])
              this.form.list[index].push(value)
          // 构造变量统计表需要的结构
      var paramStatistic = new ParamStatistic(element, this.form.xys[0], this.form.xys[1], this.statisObj)
      paramStatistic.LeiDuoLiang1()
      //
      })
      this.form.msxfxReq = this.statisObj
      const { figsize, dpi } = getPictureSize('hypothesis', 45)
      this.form.figsize = figsize
      this.form.dpi = dpi
      return this.req
  }
  leiXu() {
    this.form.list = []
    // // 重构以后数据结构改变
    // this.form.xys[0] = this.form.xys[0][0]
    // //
    var xys = this.form.xys
    for (var i = 0; i < xys[0][0].length - 1; i++) {
      this.form.list.push([])
    }
      var optionList = xys[0][0].slice(0, xys[0][0].length - 1)
      var lei = xys[0][0][xys[0][0].length - 1]
      var xu = xys[1][0].slice(-1)[0]
      var xuOptions = xys[1][0][0]
      this.that.documents.forEach(element => {
        // 确保每个人性别有值，并且每个人年龄也有值
          let value = 0
          optionList.forEach((gender, index) => {
            // 如果当前人的性别等于当前性别，那么存到比较的两个列表的对应下标的列表中
            if (this.that.$formatOption.main(element[lei]) === this.that.$formatOption.main(gender)) {
              // 如果当前人等于配对中的第二个，那么值加上比较值存入所在数组中
              const option = xuOptions.find(v => v.Text === element[xu])
              value = option.Score ? option.Score : xuOptions.indexOf(option) + 1
              this.form.list[index].push(value)
            }
          })
          // 构造变量统计表需要的结构
          this.statisObj[lei][element[lei]].push(value)
      //
      })
      xys[1] = xys[1].map(v => v.slice(-1)[0])
      this.form.msxfxReq = this.statisObj
      return this.req
  }
  leiLiangByIndex(indexLei, indexLiang) {
    this.form.list = []
    ///  描述性分析结构
    var statisObj = {}
    this.form.xys[indexLei].forEach(j => {
      statisObj[j.slice(-1)[0]] = {}
      var j2 = deepClone(j)
      j2.pop()
      j2.forEach(m => {
        statisObj[j.slice(-1)[0]][m] = []
      })
    })
    this.statisObj = statisObj
    // // 重构以后数据结构改变
    // this.form.xys[0] = this.form.xys[0][0]
    // //
    var xys = this.form.xys
    for (var i = 0; i < xys[indexLei][0].length - 1; i++) {
      this.form.list.push([])
    }
      var optionList = xys[indexLei][0].slice(0, xys[indexLei][0].length - 1)
      console.log('%c 🍝 optionList: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', optionList)
      var lei = xys[indexLei][0][xys[indexLei][0].length - 1]
      var liang = xys[indexLiang][0]
      console.log('%c 🍠 lei: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', lei, liang)
      this.that.documents.forEach(element => {
        // 确保每个人性别有值，并且每个人年龄也有值
          optionList.forEach((gender, index) => {
            // 如果当前人的性别等于当前性别，那么存到比较的两个列表的对应下标的列表中
            if (this.that.$formatOption.main(element[lei]) === this.that.$formatOption.main(gender)) {
              // 如果当前人等于配对中的第二个，那么值加上比较值存入所在数组中
              const value = isNaN(Number(element[liang])) ? element[liang] : Number(element[liang])
              this.form.list[index].push(value)
            }
          })
          // 构造变量统计表需要的结构
      var paramStatistic = new ParamStatistic(element, this.form.xys[indexLei], this.form.xys[indexLiang], this.statisObj)
      paramStatistic.LeiDuoLiang1()
      //
      })
      this.form.msxfxReq = this.statisObj
      return this.req
  }
  // 比如多重比较检验 。一个定类，一个定量，为obj格式
  leiliang2() {
    this.form.list = []
    const xys = this.form.xys
    const lei = xys[0][0].slice(-1)[0]
    const liang = xys[1][0]
    this.that.documents.forEach(element => {
      // 确保每个人性别有值，并且每个人年龄也有值
       this.form.list.push({
         [lei]: element[lei],
         [liang]: element[liang].toString(),
         id: element.key
       })
       // 构造变量统计表需要的结构
      var paramStatistic = new ParamStatistic(element, this.form.xys[0], this.form.xys[1], this.statisObj)
      paramStatistic.LeiDuoLiang1()
      //
    })
    this.form.msxfxReq = this.statisObj
    return this.req
  }
  // 一个定量
  oneLiang() {
    const xys = this.form.xys
    const liangDrop = xys.find(ary => ary.length > 0 ? typeof (ary[0]) === 'string' : false)
    this.form.list = [[]]
    this.descriptionLiangDuo(liangDrop)
    this.that.documents.forEach(element => {
      // eslint-disable-next-line eqeqeq
        this.form.list[0].push(parseFloat(element[liangDrop[0]]))
        var paramStatistic = new ParamStatistic(element, [], liangDrop, this.form.description)
        paramStatistic.liangDuo()
    })
    const { figsize, dpi } = getPictureSize('hypothesis', 45)
      this.form.figsize = figsize
      this.form.dpi = dpi
    return this.req
  }
  // 两个定类、一个定量
  Lei2Liang1() {
    this.form.list = []
    var lei1 = this.form.xys[0][0].slice(-1)[0]
    var lei2 = this.form.xys[0][1].slice(-1)[0]
    var liang = this.form.xys[1][0]
    var liang2 = this.form.xys[2][0] || ''
    // 构造变量统计表的结构
    var statisObj = {}
    this.form.xys[0].forEach(j => {
      statisObj[j.slice(-1)[0]] = {}
      var j2 = deepClone(j)
      j2.pop()
      j2.forEach(m => {
        statisObj[j.slice(-1)[0]][m] = []
      })
    })
    console.log('abcd', statisObj)
    //
    this.that.documents.forEach(element => {
        var obj = {
          [lei1]: element[lei1],
          [lei2]: element[lei2],
          [liang]: Number(element[liang]),
          [liang2]: element[liang2] || ''
        }
        this.form.list.push(obj)
        // 构造变量统计表需要的结构
      var paramStatistic = new ParamStatistic(element, this.form.xys[0], this.form.xys[1], statisObj)
      paramStatistic.LeiDuoLiang1()
    this.form.statisticList = statisObj
      //
    })
    return this.req
  }
  // 比如多因素方差分析
  LeiDuoLiang1() {
    this.form.list = []
    var leiList = []
  var liang = this.form.xys[1][0]
    var liang2 = this.form.xys.length > 2 ? ( this.form.xys[2][0] || '' ) : ''
    this.form.xys[0].forEach(v => {
    leiList.push(v.slice(-1)[0])
    })
    leiList.push(liang)
    if (liang2) leiList.push(liang2)
    for (var element of this.that.documents) {
      // 构造ANOVA表需要的结构

        var obj = { id: element.key }
        leiList.forEach(lei => {
           obj[lei] = !isNaN(Number(element[lei])) ? Number(element[lei]) : element[lei]
        })
        this.form.list.push(obj)

      //
      // 构造变量统计表需要的结构
      var paramStatistic = new ParamStatistic(element, this.form.xys[0], this.form.xys[1], this.statisObj)
      paramStatistic.LeiDuoLiang1()
      //
    }
    this.form.statisticList = this.statisObj
    this.form.leiList = leiList
    return this.req
  }
  Lei1LiangDuo() {
    this.form.list = []
    var lei = this.form.xys[0].length && this.form.xys[0][0].slice(-1)[0]
    var liangList = this.form.xys[1]
    var liang2 = this.form.liang2 // 收缩压
    // 构造描述性分析表的数据结构
    var description = {}
    liangList.forEach(liang => {
      description[liang] = []
    })
    for (var element of this.that.documents) {
      //  这一段是判断每个人的定量都有值

          liangList.forEach(liang => {
          var obj = {}
            obj[liang2] = !isNaN(Number(element[liang])) ? Number(element[liang]) : element[liang]
            obj['组别'] = element[lei]
            obj['时间点'] = liang
            obj['患者编号'] = element.key
            this.form.list.push(obj)
          })
          var object = new ParamStatistic(element, '', liangList, description)
          object.liangDuo()
    }
    this.form.description = description
    return this.req
  }
}

export const hypothesis = {
  judgeEleIsNull(list, element) {
    // eslint-disable-next-line eqeqeq
    return list.every(v => element[v] || (element[v] == 0 && element[v] !== '' && element[v] !== null))
  },
  minimum(up, down) {
      return `<div style="display:inline-block !important;margin:0 5px">
  <div style="border-bottom:1px solid black;padding:0 2px;line-height:1.5;text-align:center">${up}</div>
  <div style="padding:0 2px;line-height:1.5;text-align:center">${down}</div>
</div>`
  },
  fetchResult: getExamFields,
  distribution: {
    'norm': '正态分布',
    'uniform': '均匀分布',
    'poisson': '泊松分布',
    'expon': '指数分布',
    'logistic': '逻辑斯谛分布',
    'gumbel': '耿贝尔分布'
  },
  distribution2: {
    norm(part2) {
      return `<i>N</i> ( ${part2.mean} , ${part2.std} )的正态分布`
    },
    uniform(part2) {
      return `均匀分布`
    },
    poisson(part2) {
      return `泊松分布`
    },
    expon(part2) {
      return `指数分布`
    },
    logistic(part2) {
      return `逻辑斯谛分布`
    },
    gumbel(part2) {
      return `耿贝尔分布`
    }
  },
  replaceJudge(judge) {
    switch (judge) {
      case '≥':
        return '<'
      case '≤':
        return '>'
      case '＝':
        return '≠'
      default:
        return undefined
    }
  },

  // 注意：传进去的是H1的符号
  judgeTail(judge2) {
    switch (judge2) {
      case '<':
        return '左尾'
      case '>':
        return '右尾'
      default:
        return '双尾'
    }
  },
  postLog(xys, method, that) {
    var obj = {
      userid: that.$store.state.user.info.id,
      hypothesisType: that.$hypothesis.findHypothesisNameByPingYin(method),
      x: that.$hypothesis.fetchxyList(xys[0]),
      y: xys.length > 1 ? that.$hypothesis.fetchxyList(xys[1]) : ''
    }
    postHypothesislog(obj).then(res => {
      console.log(
        '日志添加成功！')
    })
  },
  fetchxyList(list) {
    return list.map(v => {
      if (typeof (v) === 'object') return v[v.length - 1]
      return v
    }).join(' ')
  },
  // 根据拼音找假设检验方法名字
  findHypothesisNameByPingYin(name) {
    for (var method of methods) {
      for (var v of method.children) {
        if (v.componentName === name) return v.name
      }
    }
  },
  // 拖拽框拖进去改变当前的框的校验规则和状态
  changeDropRule(obj, item, that) {
    if (obj.type === 'dropSelect') {
      // 取到拖进来的变量属于什么类型
      var itemType = this.judgeItemType(item)
      // 去拖选框配置对象里的options找到与itemType一致类型的配置
      var obj2 = obj.options.find(element => element.value === itemType)
      // 将找到的新配置覆盖老配置
      Object.assign(obj, obj2)
      if (Array.isArray(obj.dropType)) {
        obj.dropType = obj.dropType.join('')
      }
      that.$emit('initialRules')
    }
  },
  // 判断拖进来的变量是定类还是定量
  judgeItemType(item) {
    var type = ''
    switch (item.fieldType) {
      case '数字':
      case '日期':
        type = '量'
        return type
      case '单选':
      case '多选':
      case '下拉框':
        type = '类'
        return type
      default:
        type = ''
        return type
    }
  },
  findChildren(id, list) {
    for (const item of list) {
      if (item.id === id) {
        return item
      } else if (item.children.length > 0) {
        const a = this.findChildren(id, item.children)
        if (a) return a
      }
    }
  },
  // 初始化settings
  initialSettings(that, xysName, xys, settings, module, vuexForm) {
    const name = xysName.slice(0, -3)
    that.settings = deepClone(settings)
    that.form = deepClone(that.indexForm)
    that.$store.state[module][xysName] = xys
    that.$store.state[module][name + 'FormAddKey'] = []
    if (vuexForm) {
      that.$store.state[module][name + 'Form'] = deepClone(vuexForm)
    }
  },
  // 点击重置执行的方法
  resetForm(that, xysName, xys) {
    // 重置v-model绑定的表单
    that.$refs.ruleForm.resetFields()
    //  重置展示在界面上的列表
    that.initialSettings()
    // 重置不在v-model的Form表单的属性
    Object.assign(that.form, that.indexForm)
  },
  resultMethod(that, t, func, isCombine = true, isColumns = true) {
    /**
     * @param that 组件里的this
     * @param t 需要合并单元格且斜体的字符串
     * @param isCombine
     * @param isColumns 是否需要columns和data赋值
     */
    var method = that.$store.state.hypothesis.method
      var form = that.$store.state.hypothesis.dybtjy
      form = { ...form, style: { language: store.state.user.info.setting.language } }
      // var
      var req = that.makeReq(form, method)
      that.$log('req', req)
      getHypothesis(req).then(res => {
        that.$log('res', res)
        // python报错执行的操作
        if (typeof (res) === 'string') {
           // 消除之前的异常弹框
    that.$notification.destroy()
    // 弹出错误消息框
    that.$notification.error({
      message: `提醒`,
      description: res,
      style: {
        width: '700px',
        marginLeft: `${335 - 700}px`
      },
      placement: `bottomRight`,
      duration: 4.5
    })
    that.$router.go(-1)
    that.$store.state.hypothesis.loading = false

      //     that.$message.warning(res, 5)
      //    that.$store.state.hypothesis.loading = false
      //     var countdown = setInterval(() => {
      //       that.$store.state.hypothesis.seconds--
      //       if (that.$store.state.hypothesis.seconds === 0) {
      //         window.clearInterval(countdown)
      //         that.$router.go(-1)
      //         that.$store.state.hypothesis.loading = true
      //         that.$store.state.hypothesis.seconds = 5
      //       }
      //   }, 1000)
      //   window.addEventListener('popstate', () => { // 监听回退按钮
      //     window.clearInterval(countdown) // 在回退时进行某种操作。
      //     that.$store.state.hypothesis.loading = true
      //     that.$store.state.hypothesis.seconds = 5
      // }, false)
        } else {
          this.postLog(req.args.form.xys, that.$store.state.hypothesis.method, that)
          if (isColumns) that.data = res.part1.data
          var columns = res.part1.columns
          // 配置columns的方法
          that.method(columns, t, res, isCombine)
          if (func) func(res)
          if (isColumns) that.columns = columns
          that.part2 = res.part2
          that.$emit('hideLoading')
        }
      })
  },

  setColumns: {
    general(columns, t, res, isCombine) {
      /**
     * 大部分描述分析表都可以用这个方法配置
     * @param columns 配置表格的columns
     * @param t 额外计算出的参数数组，例如['p','dof','q']
     * @param res 返回的res
     * @param isCombine 是否合并t数组里面的单元格
     */
      // 如果res当中有mark字段，那么根据mark字段来判断是用量的还是类的。如果没有则根据xys里面当中有没有数组来判断。
      if ('mark' in res.part2 ? (res.part2.mark === 'lei') : (res.part2.xys.some(v => v[0] ? typeof (v[0]) === 'object' : false))) {
      // 格式化第一列列的置信区间和p以及statistic
 columns[0].customRender = (text, record, index) => {
        for (const param of t) {
          if (text === param) return <i>{text}</i>
        }
        if (text === translate(translate('置信区间'))) {
          return <div><div>{translate('置信区间')}</div><div style="font-size:8px;color:grey">({translate('置信度')}:{(1 - res.part2.a) * 100}%）</div></div>
        } else {
          return text
        }
      }
      columns[1].children[0].customRender = (text, record, index) => {
        for (const param of t) {
          if (record.index === param && isCombine) {
          return {
            children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>,
            attrs: {
              colSpan: columns[1].children.length
            }
          }
        }
        if (record.index === param && !isCombine) {
          return {
            children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>
          }
        }
        }
       if (record.index === translate('置信区间')) {
          return <span>({text[0]},{text[1]})</span>
        } else if (record.index === translate('众数')) {
          return (<div>
            {text.map(value => {
            return <div>{value}</div>
          })}
          </div>)
        } else {
          return {
            children: text
          }
        }
      }
      for (var i = 1; i < res.part1.columns[1].children.length; i++ ) {
        columns[1].children[i].customRender = (text, record, index) => {
          for (const param of t) {
            if (record.index === param && isCombine) {
              return {
                            children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>,
                            attrs: {
                              colSpan: 0
                            }
                          }
              }
              if (record.index === param && !isCombine) {
                return {
                  children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>
                }
              }
          }
          if (record.index === translate('置信区间')) {
            return <span>({text[0]},{text[1]})</span>
          } else if (record.index === translate('众数')) {
            return (<div>
              {text.map(value => {
              return <div>{value}</div>
            })}
            </div>)
          } else {
            return {
              children: text
            }
          }
        }
      }
} else { // 只有定量
  columns[0].customRender = (text, record, index) => {
    for (const param of t) {
      if (text === param) return <i>{text}</i>
    }
    if (text === translate('置信区间')) {
      return <div><div>{translate('置信区间')}</div><div style="font-size:8px;color:grey">({translate('置信度')}:{(1 - res.part2.a) * 100}%）</div></div>
    } else {
      return <span>{text}</span>
    }
  }
  columns[1].customRender = (text, record, index) => {
    for (const param of t) {
      if (record.index === param && isCombine) {
      return {
        children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>,
        attrs: {
          colSpan: res.part1.columns.length - 1
        }
      }
    }
    if (record.index === param && !isCombine) {
      return {
        children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>
      }
    }
    }
    // return formatValue(h, record, text)
   if (record.index === translate('置信区间')) {
      return <span>({text[0]},{text[1]})</span>
    } else if (record.index === translate('众数')) {
      return (<div>
        {text.map(value => {
        return <div>{value}</div>
      })}
      </div>)
    } else {
      return {
        children: text
      }
    }
  }
  for (var j = 2; j < res.part1.columns.length; j++ ) {
    columns[j].customRender = (text, record, index) => {
      for (const param of t) {
        if (record.index === param && isCombine) {
          return {
                        children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>,
                        attrs: {
                          colSpan: 0
                        }
                      }
          }
          if (record.index === param && !isCombine) {
            return {
              children: <span>{typeof text === 'string' ? text : this.$options.filters.round(text)}</span>
            }
          }
      }
      console.log('%c 🥗 text: ', 'font-size:20px;background-color: #465975;color:#fff;', text);
      if (record.index === translate('置信区间')) {
        return <span>({text[0]},{text[1]})</span>
      } else if (record.index === translate('众数')) {
        return (<div>
          {text.map(value => {
          return <div>{value}</div>
        })}
        </div>)
      } else {
        return {
          children: text
        }
      }
    }
  }
}
    },
    manyParamsWithNoP(columns, res) {
      console.log('%c 🍡 res: ', 'font-size:20px;background-color: #42b983;color:#fff;', res)
      columns[0].customRender = (text, record, index) => {
        if (text === translate('置信区间')) {
          return <div><div>{translate('置信区间')}</div><div style="font-size:8px;color:grey">({translate('置信度')}:{100 - res.part2.a * 100}%）</div></div>
        } else if (text === 't' || text === 'p') {
          return <i>{text}</i>
        } else {
          return <span>{text}</span>
        }
      }
      for (var i = 0; i < columns[1].children.length; i++ ) {
        columns[1].children[i].customRender = (text, record, index) => {
          if (record.index === translate('置信区间')) {
            return <span>({text[0]},{text[1]})</span>
          } else {
            return {
              children: text
            }
          }
        }
      }
    },
    jiaoCha(columns, dataLength) {
       // 让卡方那一列合并
       columns[columns.length - 1].customRender = (text, record, index) => {
        if (index === 0) {
          return {
            children: text,
            attrs: {
              rowSpan: dataLength
            }
          }
        } else {
          return {
            children: text,
            attrs: {
              rowSpan: 0
            }
          }
        }
      }
      // 让p那一列合并
      columns[columns.length - 2].customRender = (text, record, index) => {
        if (index === 0) {
          return {
            children: text,
            attrs: {
              rowSpan: dataLength
            }
          }
        } else {
          return {
            children: text,
            attrs: {
              rowSpan: 0
            }
          }
        }
      }
    },
    fcfx(columns, t, res, that) {
      for (const key in res.part2.tables) {
        that.method2(res.part2.tables[key].columns, res)
      }
      // this.manyParams(res.part2.)
    },
    chi_fitting(columns, t, res, isCombine) {
      // 第一列的
      columns[0].customRender = (text, row, index) => {
        if (text === 'chisq' || text === 'p') return <i>{text}</i>
        return text
      }
      columns[1].customRender = (text, row, index) => {
        if (row.key === 'chisq' || row.key === 'p') {
 return {
          children: text,
          attrs: {
            colSpan: 3
          }
        }
} else {
  return text
}
      }
      columns[2].customRender = (text, row, index) => {
        if (row.key === 'chisq' || row.key === 'p') {
 return {
          children: text,
          attrs: {
            colSpan: 0
          }
        }
} else {
  return text
}
      }
      columns[3].customRender = (text, row, index) => {
        if (row.key === 'chisq' || row.key === 'p') {
 return {
          children: text,
          attrs: {
            colSpan: 0
          }
        }
} else {
  return text
}
      }
    },
    common(columns) {

    }
  },
  combine: {
    /**
     * 排列组合算法ƒ
     */
    array: [],
    run() {
      var parentArr = []
      for (var i = 0; i < this.array.length; i++) {
        var newArr = []; var tempI = i
            newArr.push(this.array[i])
            parentArr.push(newArr)
            this.goOn(++tempI, parentArr, newArr)
      }
      return parentArr
    },
    goOn(start, parentArr, newArr) {
      for (var i = start; i < this.array.length; i++) {
        var chileArr = []; var tempI = i
            chileArr = chileArr.concat(newArr)
            chileArr.push(this.array[i])
            parentArr.push(chileArr)
            this.goOn(++tempI, parentArr, chileArr)
      }
    }
  },
  getJudgeByP(p) {
    return p > 0.05 ? '>' : '≤'
  },
  conclusion(p, a, H0, H1, method) {
    // 如果p是数组，那么它的长度要大于0；如果不是数组，那么p值要存在或者等于0才执行。
    if (Array.isArray(p) ? p.length > 0 : (p || p === 0)) {
      let str = ''
    let str2 = ''
    if (method) {
      str = `根据${method}方法计算得出：`
    }
    if (typeof p === 'object') {
      p = p.map(v => v || v === 0 ? (+v).toFixed(3) : null)
    } else if (typeof p === 'string') {
      console.log('数据质量不佳')
    } else {
      p = (+p).toFixed(3)
    }
    if (p !== null && p > a) {
       str2 = `由于<i>p</i> = ${p} > ${(+a).toFixed(2)}，因此不拒绝H0（${H0}）。`
    } else if ( p !== null && p <= a) {
      str2 = `由于<i>p</i> = ${p} ≤ ${(+a).toFixed(2)}，因此拒绝H0（${H0}），接受H1（${H1}）。`
    } else {
      str2 = '数据质量不佳，无法分析'
    }
    return str + str2
    } else {
      return ''
    }
  },
  conclusion2(p, a, H0, H1, pName, method, aName) {
    let str = ''
    let str2 = ''
    if (method) {
      str = `根据${method}方法计算得出：`
    }
    p = (+p).toFixed(3)
    if (p !== null && p > a) {
       str2 = `由于${pName}的<i>p</i>值(${p}) > ${(+a).toFixed(2)}，因此不拒绝H0（${H0}）`
    } else if ( p !== null && p <= a) {
      str2 = `由于${pName}的<i>p</i>值(${p}) ≤ ${(+a).toFixed(2)}，因此拒绝H0（${H0}），接受H1（${H1}）`
    } else {
      str2 = '数据质量不佳，无法分析'
    }
    return str + str2
  }
}

// Drop类
export class DropClass {
  constructor(x, item, help, message, errorContent, thatPage, hypothesis = false, obj, mark) {
    this.x = x || null // 被拖入标签的数组
    this.item = item // 被拖拽的对象
    this.help = help || null // 框里的提示文字
    this.message = message // ant里面的this.$message
    this.errorContent = errorContent // 传过来的错误信息
    this.error = '' // 最终的错误信息
    this.thatPage = thatPage
    this.hypothesis = hypothesis
    this.obj = obj
    this.mark = mark
    this.paramType = isleiorliang(item.fieldType, item.ptype) // 得到被拖拽的对象的变量类型
  }
  // checkObj = {
  //   num:1,    变量的个数限制
  //   type:'类',   定类或者定量
  //   location:'x',   拖入的是哪个轴
  //   optLimit:2,  如果是定类变量，变量中类别的个数限制
  // }
  check(checkObj) {
    this.initialCheckObj(checkObj)
    var list = [this.leiOrLiang, this.outOfRange]
    // if (isTwo) {
    //   list = [this.isOptionOverLength]
    // }
    for (var i in list) {
      if (this.error) {
        break
      } else {
        list[i](this, checkObj)
      }
    }
    return this.result(checkObj)
  }
  // 最终执行
  result(checkObj) {
    if (this.error) {
      this.message.warning(this.error)
      if (this.hypothesis) { this.thatPage.load.anysisLoading = false } else { this.thatPage.anysisLoading = false }
      return false
    } else {
      if (this.x) return GetDocuments(this.item, this.thatPage, this, checkObj, callbackLimitGetDocument)
    }
  }
  // 给checkObj设置默认值
  initialCheckObj(checkObj) {
    for (var key in checkObj) {
      checkObj[key] = checkObj[key] || null
    }
  }
  // 判断是定类还是定量
  leiOrLiang(that, checkObj) {
    var type = isleiorliang(that.item.fieldType, that.item.ptype)
    if (checkObj.type.indexOf(type) > -1 ||
     (that.item.forOrder === 1 && !that.item.ptype && (checkObj.type.indexOf('类') > -1 || checkObj.type.indexOf('量') > -1)) ||
     (that.item.forOrder === 1 && that.item.ptype && (checkObj.type.indexOf('时序类') > -1 || checkObj.type.indexOf('时序量') > -1))) {

    } else {
      var result = ''
      if (Array.isArray(checkObj.type)) {
        checkObj.type.map((r, i) => {
          if (r === '时序量') {
            result = result + (i > 0 ? '/' : '') + '时序定量'
          } else if (r === '时序量') {
            result = result + (i > 0 ? '/' : '') + '时序定类'
          } else {
          result = result + (i > 0 ? '/' : '') + '定' + r
          }
  })
      } else {
      result = `定${checkObj.type}变量`
      }
      that.error = `请拖入${result}`
    }

  //   if (that.item.forOrder) {
  //     // 针对定序变量
  //   } else if (checkObj.type !== type && checkObj.type && checkObj.type !== '量序') {
  //     that.error = `请拖入定${checkObj.type}变量`

  //   //   if (checkObj.type !== '序' && checkObj.type !== '量序' && checkObj.type !== type) {
  //   //     that.error = `请拖入定${checkObj.type}变量`
  //   // } else if (checkObj.type !== '量序' && !(checkObj.type === '序' && type === '类' && that.item.forOrder === 1)) {
  //   //   that.error = `请拖入定序变量`
  //   // } else if (checkObj.type !== '序' && !(checkObj.type === '量序' && ((type === '类' && that.item.forOrder === 1) || (type === '量')))) {
  //   //   that.error = `请拖入定量或者定序变量`
  //   // }
  // }
  }

  limitDocLength(that, checkObj) {
    /**
     * 校验拖拽的变量相关的样本数不能超过多少，例如卡方拟合性检验。
     * @param that that指向当前类的对象，因为是循环调用的方法，所以指向不能用this。
     * @param checkObj 拖拽框相关的配置项。
     */
    // docLenLimit的格式为['量',20] || [<type>,<最大的样本数>]
    const docLenLimit = checkObj.docLenLimit
    // 如果docLenLimit有值。
    if (docLenLimit) {
      const askedType = docLenLimit[0]
    const askedLentgh = docLenLimit[1]
    const item = that.item
    // 判断
    const type = this.paramType
    const new_item = {
      name: item.fieldName,
      type: type,
      unit: item.unit
    }
    const documents = new DataFilterClass([new_item], false).main()
    const docLength = documents.length
    if (type.indexOf(askedType) !== -1 && docLength > askedLentgh) {
        that.error = `定${askedType}变量的样本数不得大于${askedLentgh}`
    }
    }
  }

  atLeast2Options(that, checkObj) {
    if (that.fetchOption().length < 2) that.error = '定类变量应至少有两个类别(当前数据中只有一种类别)'
  }

  // 判断变量是否已经存在
  isOnly(that, checkObj) {
    var chart = that.x.filter(r => r.name === that.item.fieldName)// 图表
    var hypothesis = false// 假设检验
    if (checkObj.vm.xys !== undefined) {
      for (let i = 0; i < checkObj.vm.xys.length; i++) {
        const element = checkObj.vm.xys[i]
        if (element && element[element.length - 1] === that.item.fieldName) {
          hypothesis = true
          break
        }
      }
    }
    if ((chart && chart.length > 0) ||
     (hypothesis)) that.error = '该变量已存在'
  }
  // 判断是否超出个数限制
  outOfRange(that, checkObj) {
    if (that.x && checkObj.num && that.x.length >= checkObj.num) that.error = `变量数超出个数限制（${checkObj.num}个）`
  }
  // 判断所拖拽的对象中的类别有没有超出限制
  isOptionOverLength(that, checkObj) {
    if (checkObj.optLimit && that.fetchOption().length !== checkObj.optLimit) that.error = `变量只能有${checkObj.optLimit}个类别`
  }
  // 获取被拖拽的对象中option列表，比如拖拽性别，option列表为['男','女']
  fetchOption() {
    if (this.item.option.indexOf('ChildrenRequire') === -1 && this.item.option.indexOf('{') === -1) {
      return this.item.option.split(',')
    } else {
      return JSON.parse(this.item.option).map(element => element.Text)
    }
  }
}

/**
 * callback事件
 * @param {*} item field
 * @param {*} checkObj 图表check
 * @param {*} that 页面的this
 * @param {*} thatClass  dropclass
 */
export function callbackLimitGetDocument(item, checkObj, that, thatClass, source) {
  if (checkObj.optLimit) {
    thatClass.isOptionOverLength(thatClass, checkObj)
    if (thatClass.error) {
      thatClass.message.warning(thatClass.error)
      // 假设检验专有的
      if (thatClass.hypothesis) { that.load.anysisLoading = false } else { that.anysisLoading = false }
      return null
    }
  }
  if (thatClass.paramType.includes('类')) {
    if (thatClass.hypothesis) {
    thatClass.atLeast2Options(thatClass, checkObj)
    }
    if (thatClass.error) {
      thatClass.message.warning(thatClass.error)
      // 假设检验专有的
      if (thatClass.hypothesis) { that.load.anysisLoading = false } else { that.anysisLoading = false }
      return null
    }
  }
  if (checkObj.docLenLimit) {
    thatClass.limitDocLength(thatClass, checkObj)
    if (thatClass.error) {
      thatClass.message.warning(thatClass.error)
      // 假设检验专有的
      if (thatClass.hypothesis) { that.load.anysisLoading = false } else { that.anysisLoading = false }
      return null
    }
  }
  var optionC = []
  var optionInitC = []
  var options
  var optionInits
  if (store.state.samples.type === 'document' || store.state.samples.type === 'experiment' || (store.state.samples.type === 'file' && source !== 'questionnaire')) {
    options = item.option && item.option.split(',')
    if (options && options.length > 0 && (item.fieldType === '单选' || item.fieldType === '下拉框' || item.fieldType === '多选') ) {
      options.map(r => {
        optionC.push(r)
      })
    }
    optionInits = item.optionInit && item.optionInit.split(',')
    if (optionInits && optionInits.length > 0 && (item.fieldType === '单选' || item.fieldType === '下拉框' || item.fieldType === '多选') ) {
      optionInits.map(r => {
        optionInitC.push(r)
      })
    }
  } else {
    options = item.option ? JSON.parse(item.option) : null
    if (options && options.length > 0 && (item.fieldType === '单选' || item.fieldType === '下拉框' || item.fieldType === '多选')) {
        options.map(r => {
        optionC.push(r.Text)
      })
    }
    optionInits = item.optionInit ? JSON.parse(item.optionInit) : null
    if (optionInits && optionInits.length > 0 && (item.fieldType === '单选' || item.fieldType === '下拉框' || item.fieldType === '多选')) {
        optionInits.map(r => {
        optionInitC.push(r)
      })
    }
  }

  if (that.iconState === 'pie' || that.iconState === 'bar' || that.iconState === 'bar1') {
    if ( isleiorliang(item.fieldType, item.ptype) === '量') {
      var doc = store.state.samples.documents && JSON.parse(store.state.samples.documents)
      if (doc.filter(r => Number(r[item.fieldName]) < 0).length > 0) {
        thatClass.message.warning('定量必须为非负数')
        that.anysisLoading = false // 成功后取消加载中
        return null
      }
    }
  }
  var obj = {
    key: item.fieldID ? item.fieldID + '_' + item.fieldName : item.fieldDoc + '_' + item.fieldName,
    name: item.fieldName,
    type: (item.forOrder && (checkObj.type.indexOf('量') > -1 || checkObj.type.indexOf('量序') > -1) && checkObj.type.indexOf('类') === -1) ? '量' : ((item.forOrder && checkObj.type.indexOf('时序量') > -1) ? '时序量' : isleiorliang(item.fieldType, item.ptype)),
    unit: item.unit,
    forOrder: item.forOrder,
    option: optionC,
    optionInit: optionInitC,
    fieldcheckbox: item.fieldcheckbox
  }
  thatClass.item = item
  thatClass.x.push(obj)
  thatClass.help.length = 0
  that.documents = store.state.samples.documents && JSON.parse(store.state.samples.documents)
  if (that.iconState === 'table') {
    // 如果是定类，只要个数不是2个就按单独的方式统计
    if (thatClass.x !== 2 && thatClass.x[0].type === '类') {
      that['ctype' + thatClass.mark] = 'single'
      that.z = []
    }
    if (thatClass.x[0].type === '量' ) {
      if (thatClass.mark === 'x') {
        that.ytype = '定类'
      } else if (thatClass.mark === 'y') {
        that.xtype = '定类'
      }
    } else {
      if (thatClass.mark === 'x' && that.y.length > 0 && that.y[0].type === '量') {
        that.xtype = '定类'
      } else if (thatClass.mark === 'y' && that.x.length > 0 && that.x[0].type === '量') {
        that.ytype = '定类'
      } else {
      that.xtype = '定量/定类'
      that.ytype = '定量/定类'
      }
    }
  }

  // 箱线图根据x的变量修改分组的提示
  if ((that.iconState === 'Boxplot' || that.iconState === 'ErrorBar' || that.iconState === 'Violin') && thatClass.mark === 'x' && thatClass.x.length > 1 ) {
    that.y = []
    that.boxTest = '请拖入左侧变量（个数≤1个）'
  }
  // 假设检验专有的
  if (thatClass.hypothesis) {
  // 如果校验规则通过，那么就要把拖拽项加入到store里的xys列表里去，如果当前拖拽的是定类，还要把它的所有类别都找出来存入它的属性当中去。
  if (obj) {
    if (obj.type === '类') {
      obj.options = thatClass.fetchOption()
    }
    if (thatClass.obj.hasOwnProperty('onChange')) {
      thatClass.obj.onChange(thatClass.obj, obj, that)
    } else { that.onChange(obj) }
  }
  // this.$store.dispatch('pushDropObj', item)
  // 如果拖拽框里一个变量都没有，那么初始化它以及它的子内容。
  if (thatClass.x.length === 0) {
    this.initialDropDiv(thatClass.item)
  }
  that.load.anysisLoading = false
} else {
 that.anysisLoading = false // 成功后取消加载中
 if ((that.iconState === 'bar' || that.iconState === 'bar2') && that.x.length === 2 && that.x[0].type === '类' && that.x[1].type === '类') {
   that.barOption = that.x[0].option
 }
//  if (that.iconState === 'paleituotu' && that.x.length > 1) {
//    that.y = []
//    that.quanzhong = false
//  }
}
tubiaoopacity(that)
return true
}

export function tubiaoopacity(that) {
  if (that.x && that.y && that.z && that.m) {
  var fields = that.x
  .concat(that.y)
  .concat(that.z)
  .concat(that.m)
  // 定类
  var cF = fields.filter(r => r.type === '类')
  // 定序
  var xF = fields.filter(r => r.forOrder === 1)
  // 二分类
  var cF2 = fields.filter(r => r.option.length === 2)
  // 定量
  var vf = fields.filter(r => r.type === '量')
  var wbf = fields.filter(r => r.type === '文本')
  var rqf = fields.filter(r => r.type === '日期')
    // 定量
  var sxvf = fields.filter(r => r.type === '时序量')
  if (vf.length === 0 && cF.length === 0 && sxvf.length === 0 && wbf.length === 0 && rqf.length === 0) {
    that.iconsList.map(r => {
        r.opacity = 1
    })
    that.fieldtype = []// 实际的type值，如果都是定序可能会有两个值
  } else {
    if (vf.length === 0 && cF.length === 1 && sxvf.length === 0 ) {
      // 0个定量+1个定类
      if (xF.length === cF.length) {
        that.fieldtype = ['c', 'v']
      } else {
        that.fieldtype = ['c']
      }
    } else if (vf.length === 0 && cF.length === 2 && sxvf.length === 0) {
      // 0个定量+2个定类
      if (xF.length === cF.length) {
        that.fieldtype = ['cc', 'vv']
      } else {
        that.fieldtype = ['cc']
      }
    } else if (vf.length === 1 && cF.length === 0 && sxvf.length === 0) {
      // 1个定量+0个定类
      if (xF.length === vf.length) {
        that.fieldtype = ['c', 'v']
      } else {
        that.fieldtype = ['v']
      }
    } else if (vf.length === 1 && cF.length === 1 && sxvf.length === 0) {
      // 1个定量+1个定类
      that.fieldtype = ['vc']
    } else if (vf.length === 1 && cF.length === 2 && sxvf.length === 0) {
      // 1个定量+2个定类
      that.fieldtype = ['vcc']
    } else if (vf.length === 2 && cF.length === 0 && sxvf.length === 0) {
      // 2个定量+0个定类
      // 1个定量+0个定类
      if (xF.length === vf.length) {
        that.fieldtype = ['cc', 'vv']
      } else {
        that.fieldtype = ['vv']
      }
    } else if (vf.length === 2 && cF.length === 1 && sxvf.length === 0) {
      // 2个定量+1个定类
      that.fieldtype = ['vvc']
    } else if (vf.length === 2 && cF.length === 2 && sxvf.length === 0) {
      // 2个定量+2个定类
      that.fieldtype = ['vvcc']
    } else if (vf.length > 2 && cF.length === 0 && sxvf.length === 0) {
      // 多个定量+0个定类
      if (xF.length === vf.length) {
        that.fieldtype = ['ccc', 'vvv']
      } else {
        that.fieldtype = ['vvv']
      }
    } else if (vf.length > 2 && cF.length === 1 && sxvf.length === 0) {
      // 多个定量+1个定类
      that.fieldtype = ['vvvc']
    } else if (vf.length === 1 && cF.length > 2 && sxvf.length === 0) {
      that.fieldtype = ['vccc']
    } else if (cF.length > 2 || (vf.length > 2 && cF.length > 1) && sxvf.length === 0) {
      // 多个定量+>2个定类
      that.fieldtype = ['vvccc']
    } else if (sxvf.length > 0) {
      that.fieldtype = ['sxv']
    }

    if (wbf.length > 0) {
      if (that.fieldType) {
        that.fieldType.push('wb')
      } else {
        that.fieldtype = ['wb']
      }
    }
    if (rqf.length > 0) {
      if (that.fieldType) {
        that.fieldType.push('rq')
      } else {
        that.fieldtype = ['rq']
      }
    }
    that.iconsList.map(r => {
      if ( r.action !== that.iconState) {
       if ( r.fieldtype.filter(a => that.fieldtype.indexOf(a) > -1).length > 0) {
        r.opacity = 1
       } else { r.opacity = 0.5 }
      // 当所有的定类都是二分类时
      if (cF2.length === fields.length && r.action === 'Phi') {
        r.opacity = 1
      }
    }
    })
  }
  }
  // // 不兼容当前变量配置的图
  // var notList = that.iconsList.filter(r => r.fieldtype.indexOf(that.fieldtype) === -1 && r.type === 'common')
  // // 兼容当前变量配置的图
  // var inList = that.iconsList.filter(r => r.fieldtype.indexOf(that.fieldtype) > -1 && r.type === 'common')
  // // 不兼容的透明度为0.5
  // notList.map(r => { r.opacity = 0.5 })
  // // 兼容的透明度为1
  // inList.map(r => { r.opacity = 1 })
}
// 格式化字，比如汉格式化成汉族
export const formatOption = {
  returnStr: null,
  main(str) {
    this.returnStr = ''
    var list = [this.minority]
    for (var i in list) {
      if (this.returnStr) {
        break
      } else {
        list[i](this, str)
      }
    }
    return this.returnStr || str
  },
  minority(that, str) {
    if (str === '汉') {
      that.returnStr = '汉族'
    }
  }
}
// 判断变量的类型
function isleiorliang(fieldType, ptype) {
  var type = ''
  switch (fieldType) {
    case '数字':
    case '日期范围':
      if (ptype) {
        type = '时序量'
      } else {
      type = '量'
      }
      break
    case '单选':
    case '多选':
    case '下拉框':
      if (ptype) {
        type = '时序类'
      } else {
      type = '类'
      }
      break

    case '日期':
      type = '日期'
      break
    case '文本':
      type = '文本'
      break;
    default:
      type = ''
  }
  return type
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}