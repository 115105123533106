<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div style="padding: 30px">
        <a-row>
          <a-col :span="24">
            <myTable :data="data" :columns="columns" :title="'正态检验分析结果'" :size="'small'"></myTable>
          </a-col>
        </a-row>
        <!-- <a-row>
          <a-col :span="24">
            <myTable
              :data="part2.table2.datas"
              :columns="part2.table2.columns"
              :title="'正态检验分析结果'"
              :size="'small'"
            ></myTable>
          </a-col>
        </a-row> -->
        <a-row>
          <a-col :span="24">
            <box-shadow-card :title="'正态检验智能分析结果'">
              <div>
                <div v-for="(item, index) in result" :key="index">
                  <span v-html="item">{{ item }}</span>
                </div>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
        <a-row style="margin-top: 15px">
          <a-col
            :span="12"
            v-for="(item, index) in part2.imd_list"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="imgFileNameAry[index]"></MyImg>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      imgFileNameAry: ['PP图', 'QQ图'],
      data: [],
      part2: {
        p: 0,
        list: [],
        xys: [[['']], ['']]
      }
    }
  },
  methods: {

    fetchResult() {
      this.$hypothesis.resultMethod(this, ['statistic', 'p'], null, false)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      // 定类拖选框中有变量，那么执行leilaing(),反之oneliang(0)
      return form.xys[0].length > 0 ? makeReq.leiLiang() : makeReq.oneLiang()
    }
  },
  computed: {
    liang() { return `&lt;${this.part2.xys[1][0]}&gt;` },
    H0() { return [`${this.liang}服从正态分布`] },
    H1() { return [`${this.liang}不服从正态分布`] },
    method2() {
      if (this.part2.method === 0) return 'shapiro-wilk'
      if (this.part2.method === 1) return 'kolmogorov-smirnov'
      return 'lilliefors'
    },
    // 只有定量的时候用
    resultForLiang() { return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0], this.method2)}`] },
    // 定类定量都有的时候用
    resultForLeiLiang() {
      // 把表格中data里的P值进行分组，再组织语言。
      if (this.data.length > 0) {
        // 当data里的数据存在时，才执行以下代码，不然会报错。
        const pObj = this.data.slice(-1)[0]
        const a = this.part2.a
        const pDivideObj = {
          greater: [],
          less: []
        }
        const lei = this.part2.xys[0][0]
        const leiName = lei.slice(-1)[0]
        this.part2.xys[0][0].forEach(option => {
          if (option === leiName) option = '总体'
          const pValue = pObj[option]
          pValue >= a ? pDivideObj.greater.push(option) : pDivideObj.less.push(option)
        })
        let greater = pDivideObj.greater
        let less = pDivideObj.less
        greater = greater.map(v => `&lt;${v}&gt;`).join('、')
        less = less.map(v => `&lt;${v}&gt;`).join('、')
        const str1 = `根据${this.method2}方法计算得出：`
        let str2 = ''
        if (greater && less) {
          str2 = `${greater}的${this.liang}的<i>p</i>值 ≥ ${a}，因此符合正态分布;${less}的${this.liang}的<i>p</i>值 < ${a}，不符合正态分布。`
        } else if (greater && !less) {
          str2 = `&lt;${leiName}&gt;中<总体>及其分类的${this.liang}的<i>p</i>值 ≥ ${a}，因此都符合正态分布。`
        } else {
          str2 = `&lt;${leiName}&gt;中<总体>及其分类的${this.liang}的<i>p</i>值 < ${a}，因此都不符合正态分布。`
        }
        return [str1 + str2]
      }
      return ''
    },
    result() {
      // 如果拖了定类执行这个resultForLeiLiang，反之另外一个。
      if (this.part2.xys[0].length > 0) return this.resultForLeiLiang
      return this.resultForLiang
    }
  }
}
</script>