var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "example" },
          [_c("a-spin", { attrs: { tip: "加载中...", size: "large" } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticStyle: { "background-color": "#fbfaf7" } },
      [
        _vm.loading === false
          ? _c(
              "a-form-model",
              {
                ref: "ruleForm",
                staticClass: "divStyle",
                staticStyle: { "background-color": "#fefefe" },
                attrs: { model: _vm.form, bordered: false }
              },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      { staticClass: "title marginTop", attrs: { span: 20 } },
                      [_vm._v(_vm._s(_vm.data.modelName))]
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      {
                        staticStyle: { color: "#ff7200", "margin-top": "20px" },
                        attrs: { span: 20 }
                      },
                      [
                        _vm._v(
                          "提交问卷前请勿离开此页面，否则您将丢失输入的数据"
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c("a-col", { attrs: { span: 20 } }, [
                      _c(
                        "div",
                        { staticStyle: { "white-space": "pre-wrap" } },
                        [_vm._v(_vm._s(_vm.data.modelDescription))]
                      )
                    ])
                  ],
                  1
                ),
                _vm.screenWidth > 768
                  ? _c("subject-comp-new", {
                      attrs: {
                        data: _vm.data.questions,
                        childrenRequire: true,
                        isAnswer: true,
                        formaaa: _vm.form,
                        linkQuestionIDs: _vm.data.linkQuestionIDs
                      },
                      on: {
                        doclink: function($event) {
                          return _vm.documentLink(arguments)
                        },
                        questionLink: function($event) {
                          return _vm.questionLink(arguments)
                        }
                      }
                    })
                  : _vm._e(),
                _vm.screenWidth <= 768
                  ? _c("subject-comp-mobile-new", {
                      attrs: {
                        data: _vm.data.questions,
                        childrenRequire: true,
                        isAnswer: true,
                        formaaa: _vm.form,
                        linkQuestionIDs: _vm.data.linkQuestionIDs
                      },
                      on: {
                        doclink: function($event) {
                          return _vm.documentLink(arguments)
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      {
                        staticStyle: { color: "#ff7200", "margin-top": "20px" },
                        attrs: { span: 20 }
                      },
                      [
                        _vm._v(
                          "提交问卷前请勿离开此页面，否则您将丢失输入的数据"
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      {
                        staticClass: "title",
                        staticStyle: { margin: "20px auto 20px auto" },
                        attrs: { span: 20 }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { width: "100%", height: "50px" },
                            attrs: {
                              type: "primary",
                              disabled: _vm.submitEnable
                            },
                            on: { click: _vm.handelSubmit }
                          },
                          [_vm._v(_vm._s(_vm.submitStr))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }