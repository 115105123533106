import { render, staticRenderFns } from "./result.vue?vue&type=template&id=55d0b95f&"
import script from "./result.vue?vue&type=script&lang=js&"
export * from "./result.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\PageCode\\SR\\SRWeb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55d0b95f')) {
      api.createRecord('55d0b95f', component.options)
    } else {
      api.reload('55d0b95f', component.options)
    }
    module.hot.accept("./result.vue?vue&type=template&id=55d0b95f&", function () {
      api.rerender('55d0b95f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hypothesisTest/components/divergence/f/result.vue"
export default component.exports