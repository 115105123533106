<template>
  <span>
    <span v-if="array.length !== 1" style="margin-left: 10px; cursor: pointer" @click="deleteGroup(index)">
      <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
    </span>
    <span v-if="index === array.length - 1" style="margin-left: 10px; cursor: pointer" @click="addGroup">
      <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
    </span>
  </span>
</template>
<script>
export default {
  name: 'PlusAndMinus',
  props: ['array', 'obj', 'index'],
  methods: {
    addGroup() {
      this.array.push(this.obj)
    },
    deleteGroup(index) {
      this.array.splice(index, 1)
    }
  }
}
</script>