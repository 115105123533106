import {
  getAnalysisOfRegression,
  getHypothesis
} from '@/api/hypothesis'
import {
  methods2
} from '@/common/enums'
import {
  postAORlog,
  postHypothesislog
} from '@/api/log'
export class FetchResult {
  constructor(that, req, type = 'analysisOfRegression') {
    this.that = that
    this.req = req
    this.type = type
    console.log('%c 🍤 req: ', 'font-size:20px;background-color: #FFDD4D;color:#fff;', JSON.stringify(req))
    this.method = that.$store.state[type].method
    this.form = that.$store.state[type].dybtjy
  }

  main() {
    const {
      type,
      req,
      that
    } = this
    const axios = type === 'analysisOfRegression' ? getAnalysisOfRegression : getHypothesis
    axios(req).then(res => {
      console.log('%c 🍧 res: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', JSON.stringify(res))
      if (typeof (res) === 'string') {
        this.handleException(res)
      } else {
        res = that.handleRes(res)
        that.res = res
        console.log('%c 🍟 res: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', res);
        this.addLog(req.args.form.xys, that.$store.state[type].method, that)
        that.$emit('hideLoading')
      }
    })
  }

  handleException(e) {
    // 消除之前的异常弹框
    this.that.$notification.destroy()
    // 弹出错误消息框
    this.that.$notification.error({
      message: `提醒`,
      description: e,
      style: {
        width: '700px',
        marginLeft: `${335 - 700}px`
      },
      placement: `bottomRight`,
      duration: 4.5
    })
    this.that.$router.go(-1)
    this.that.$store.state.hypothesis.loading = false
    // const init = () => {
    //   window.clearInterval(countdown) // 在回退时进行某种操作。
    //   window.removeEventListener('popstate', init)
    //   this.that.$store.state.hypothesis.loading = true
    //   this.that.$store.state.hypothesis.seconds = 5
    // }
    // var countdown = setInterval(() => {
    //   this.that.$store.state.hypothesis.seconds--
    //   if (this.that.$store.state.hypothesis.seconds === 0) {
    //     init()
    //     this.that.$emit('hideLoading')
    //     this.that.$router.go(-1)
    //   }
    // }, 1000)
    // window.addEventListener('popstate', init, false)
  }

  addLog(xys, method, that) {
    var obj = {
      userid: that.$store.state.user.info.id,
      hypothesisType: this.findAORNameByPingYin(method),
      x: this.fetchxyList(xys[0]),
      y: xys.length > 1 ? this.fetchxyList(xys[1]) : ''
    }
    const axios = this.type === 'analysisOfRegression' ? postAORlog : postHypothesislog
    axios(obj).then(res => {
      console.log(
        '日志添加成功！')
    })
  }

  // 根据拼音找假设检验方法名字
  findAORNameByPingYin(name) {
    for (var method of methods2) {
      for (var v of method.children) {
        if (v.componentName === name) return v.name
      }
    }
  }

  fetchxyList(list) {
    return list.map(v => {
      if (typeof (v) === 'object') return v[v.length - 1]
      return v
    }).join(' ')
  }
}