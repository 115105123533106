var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.settings, function(item, index) {
      return _c(
        "div",
        { key: index },
        [
          item.parentValue === _vm.parentValue
            ? _c(
                "a-form-model-item",
                {
                  class: {
                    myRequired:
                      ["drop", "dropSelect", "text", "component"].includes(
                        item.type
                      ) && !item.hasOwnProperty("required")
                  },
                  style: {
                    alignItems: item.type === "custom1" ? "start" : "center"
                  },
                  attrs: {
                    extra:
                      item.type === "custom1" ? "(参考项可任意选择一个)" : "",
                    labelCol: item.label
                      ? { md: { span: 7 }, lg: { span: 6 }, xxl: { span: 4 } }
                      : { span: 0 },
                    wrapperCol: item.label
                      ? {
                          md: { span: 17 },
                          lg: { span: 18 },
                          xxl: { span: 20 }
                        }
                      : { span: 24 },
                    label: item.label,
                    prop: item.prop
                  }
                },
                [
                  item.type === "radio" || item.type === "radioSelect"
                    ? _c(
                        "a-radio-group",
                        {
                          on: {
                            change: function(e) {
                              return _vm.onRadioChange(e, item)
                            }
                          },
                          model: {
                            value: item.value,
                            callback: function($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value"
                          }
                        },
                        [
                          "optionsColLg" in item
                            ? _c(
                                "a-row",
                                _vm._l(item.options, function(
                                  option,
                                  optionIndex
                                ) {
                                  return _c(
                                    "a-col",
                                    {
                                      key: optionIndex,
                                      staticStyle: { margin: "5px 0" },
                                      attrs: {
                                        xxl: item.optionsColLg,
                                        sm: item.optionsColSm
                                      }
                                    },
                                    [
                                      _c(
                                        "a-radio",
                                        { attrs: { value: option.value } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                innerHTML: _vm._s(option.text)
                                              }
                                            },
                                            [_vm._v(_vm._s(option.text))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c(
                                "div",
                                _vm._l(item.options, function(
                                  option,
                                  optionIndex
                                ) {
                                  return _c(
                                    "a-radio",
                                    {
                                      key: optionIndex,
                                      attrs: { value: option.value }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(option.text)
                                          }
                                        },
                                        [_vm._v(_vm._s(option.text))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                        ],
                        1
                      )
                    : item.type === "select" || item.type === "selectSelect"
                    ? _c(
                        "a-select",
                        {
                          on: {
                            change: function(e) {
                              return _vm.onSelectChange(e, item)
                            }
                          },
                          model: {
                            value: item.value,
                            callback: function($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value"
                          }
                        },
                        _vm._l(item.options, function(option, optionIndex) {
                          return _c(
                            "a-select-option",
                            {
                              key: optionIndex,
                              attrs: { value: option.value }
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        1
                      )
                    : item.type === "input"
                    ? _c("a-input", {
                        attrs: {
                          placeholder:
                            typeof item.placeholder === "string"
                              ? item.placeholder
                              : item.placeholder()
                        },
                        on: {
                          change: function(e) {
                            return _vm.onInputChange(e, item)
                          }
                        },
                        model: {
                          value: item.value,
                          callback: function($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value"
                        }
                      })
                    : item.type === "checkbox"
                    ? _c("a-checkbox-group", {
                        attrs: { options: item.options },
                        on: {
                          change: function(e) {
                            return _vm.onCheckboxChange(e, item)
                          }
                        },
                        model: {
                          value: item.value,
                          callback: function($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value"
                        }
                      })
                    : item.type === "drop" || item.type === "dropSelect"
                    ? _c(
                        "div",
                        {
                          staticClass: "ant-input dropDiv",
                          attrs: { type: "text" },
                          on: {
                            drop: function($event) {
                              return _vm.drop(item)
                            },
                            dragover: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _vm._l(item.divHelp, function(help) {
                            return _c(
                              "span",
                              { key: help, staticClass: "helpFontColor" },
                              [_vm._v(_vm._s(help))]
                            )
                          }),
                          _vm._l(item.list, function(value, i) {
                            return _c(
                              "a-tag",
                              {
                                key: i,
                                attrs: {
                                  closable: "",
                                  visible: value !== null
                                },
                                on: {
                                  close: function(e) {
                                    return _vm.onClose(e, item, i, value)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(value.name))]
                            )
                          })
                        ],
                        2
                      )
                    : item.type === "text"
                    ? _c(
                        "div",
                        { staticStyle: { "font-size": "14px", color: "red" } },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    : item.type === "textarea"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              item.value(
                                _vm.$store,
                                _vm.settings,
                                _vm.$hypothesis
                              )
                            )
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.value(
                                  _vm.$store,
                                  _vm.settings,
                                  _vm.$hypothesis
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : item.type === "custom1"
                    ? _c(
                        "div",
                        [
                          item.data.length > 0
                            ? _c("a-table", {
                                attrs: {
                                  showHeader: false,
                                  size: "small",
                                  columns: item.columns,
                                  "data-source": item.data,
                                  bordered: "",
                                  pagination: false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "options",
                                      fn: function(text, record, i) {
                                        return [
                                          _c(
                                            "a-radio-group",
                                            {
                                              on: {
                                                change: function(e) {
                                                  return _vm.$store.state.analysisOfRegression[
                                                    item.formName
                                                  ].compareList.splice(
                                                    i,
                                                    1,
                                                    e.target.value
                                                  )
                                                }
                                              },
                                              model: {
                                                value: record.value,
                                                callback: function($$v) {
                                                  _vm.$set(record, "value", $$v)
                                                },
                                                expression: "record.value"
                                              }
                                            },
                                            _vm._l(text, function(
                                              option,
                                              optionIndex
                                            ) {
                                              return _c(
                                                "a-radio",
                                                {
                                                  key: optionIndex,
                                                  attrs: { value: option }
                                                },
                                                [_vm._v(_vm._s(option))]
                                              )
                                            }),
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _c("span", [_vm._v("无")])
                        ],
                        1
                      )
                    : item.type === "component"
                    ? _c(item.componentName, {
                        tag: "component",
                        attrs: {
                          item: item,
                          vuex: "analysisOfRegression",
                          settings: _vm.settings,
                          load: _vm.load
                        },
                        on: { updateForm: _vm.updateForm }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          item.children.length > 0 && item.parentValue === _vm.parentValue
            ? _c("drop-items2", {
                attrs: {
                  settings: item.children,
                  formAddKeyName: _vm.formAddKeyName,
                  indexSettings: _vm.indexSettings,
                  parentValue: item.value,
                  refs: _vm.refs,
                  xys: _vm.xys,
                  load: _vm.load
                },
                on: {
                  updateForm: _vm.updateForm,
                  initialRules: function($event) {
                    return _vm.$emit("initialRules")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }