var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增实验数据",
            visible: _vm.visible,
            "confirm-loading": _vm.confirmLoading
          },
          on: {
            ok: _vm.handleOk,
            cancel: function($event) {
              return _vm.$emit("handleCancel")
            }
          }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                labelAlign: "left",
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 }
              }
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "实验人员", prop: "userID" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择实验人员" },
                      model: {
                        value: _vm.form.userID,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "userID", $$v)
                        },
                        expression: "form.userID"
                      }
                    },
                    _vm._l(_vm.exp.users, function(user) {
                      return _c("a-select-option", { key: user.id }, [
                        _vm._v(" " + _vm._s(user.userName) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "实验设备", prop: "deviceID" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择实验设备" },
                      model: {
                        value: _vm.form.deviceID,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "deviceID", $$v)
                        },
                        expression: "form.deviceID"
                      }
                    },
                    _vm._l(_vm.exp.devices, function(device) {
                      return _c("a-select-option", { key: device.id }, [
                        _vm._v(" " + _vm._s(device.name) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "试剂批号", prop: "reagentID" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择试剂批号" },
                      model: {
                        value: _vm.form.reagentID,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "reagentID", $$v)
                        },
                        expression: "form.reagentID"
                      }
                    },
                    _vm._l(_vm.res.reagents, function(reagent) {
                      return _c("a-select-option", { key: reagent.id }, [
                        _vm._v(" " + _vm._s(reagent.name) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "实验时间", prop: "date" } },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "a-row",
                { attrs: { gutter: 8 } },
                _vm._l(_vm.form.dataDetails, function(dataDetail, i) {
                  return _c("a-col", { key: i, attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px solid #e8e8e8",
                          padding: "10px"
                        }
                      },
                      [
                        _c("h4", [
                          _vm._v(_vm._s(dataDetail.concentrationName))
                        ]),
                        _c("a-divider", { staticStyle: { margin: "12px 0" } }),
                        _vm.exp.accuracyFunction !== 1
                          ? _c(
                              "div",
                              _vm._l(dataDetail.concentrationValue, function(
                                value,
                                index
                              ) {
                                return _c(
                                  "a-form-model-item",
                                  {
                                    key: index,
                                    attrs: {
                                      prop:
                                        "dataDetails[" +
                                        i +
                                        "].concentrationValue[" +
                                        index +
                                        "]",
                                      rules: {
                                        required: true,
                                        message: "测量值不能为空",
                                        trigger: "blur"
                                      },
                                      "label-col": { span: 4 },
                                      "wrapper-col": { span: 20 },
                                      label: "重复" + (index + 1)
                                    }
                                  },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: {
                                        width: "90%",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        id: "inputNumber",
                                        step: 0.1,
                                        placeholder: "请输入测量值"
                                      },
                                      model: {
                                        value:
                                          _vm.form.dataDetails[i]
                                            .concentrationValue[index],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.dataDetails[i]
                                              .concentrationValue,
                                            index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.dataDetails[i].concentrationValue[index]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm.exp.accuracyFunction === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "dataDetails[" +
                                        i +
                                        "].concentrationValue[0]",
                                      rules: {
                                        required: true,
                                        message: "测量值不能为空",
                                        trigger: "blur"
                                      },
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 18 },
                                      label: "试验方法结果"
                                    }
                                  },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: {
                                        width: "90%",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        id: "inputNumber",
                                        step: 0.1,
                                        placeholder: "请输入测量值"
                                      },
                                      model: {
                                        value:
                                          _vm.form.dataDetails[i]
                                            .concentrationValue[0],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.dataDetails[i]
                                              .concentrationValue,
                                            0,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.dataDetails[i].concentrationValue[0]"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "dataDetails[" +
                                        i +
                                        "].concentrationValue[1]",
                                      rules: {
                                        required: true,
                                        message: "测量值不能为空",
                                        trigger: "blur"
                                      },
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 18 },
                                      label: "比较方法结果"
                                    }
                                  },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: {
                                        width: "90%",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        id: "inputNumber",
                                        step: 0.1,
                                        placeholder: "请输入测量值"
                                      },
                                      model: {
                                        value:
                                          _vm.form.dataDetails[i]
                                            .concentrationValue[1],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.dataDetails[i]
                                              .concentrationValue,
                                            1,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.dataDetails[i].concentrationValue[1]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                }),
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "质控结果", prop: "result" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.result,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "result", $$v)
                        },
                        expression: "form.result"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v(" 通过 ")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v(" 未通过 ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }