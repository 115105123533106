var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      _vm._l(_vm.result, function(obj, i) {
        return _c(
          "a-row",
          { key: i, attrs: { gutter: 24 } },
          _vm._l(obj, function(p, j) {
            return _c(
              "a-col",
              {
                key: j,
                attrs: {
                  xs: 24,
                  sm: 24,
                  md: 24,
                  lg: 24,
                  xl: obj.length === 1 ? 24 : 12
                }
              },
              [
                _c("Anysis", {
                  ref:
                    "content," + (_vm.indexT * _vm.count + i * obj.length + j),
                  refInFor: true,
                  attrs: {
                    tables: p.tables,
                    imgs: p.imgs,
                    indexT: i * obj.length + j
                  }
                })
              ],
              1
            )
          }),
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }