<template>
  <!-- 方差齐性检验 -->
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <a-table
        ref="tables,2"
        :pagination="false"
        v-for="(table, index) in tables"
        :key="index"
        :dataSource="table.data"
        :columns="table.columns"
        :settings="table.settings"
        size="small"
      >
        <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
          <div v-html="item.slotValue">{{ item.slotValue }}</div>
        </div>
      </a-table>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
export function get_text_homogeneityOfVariances(value, name_of_sample, flow_value, isNorm, secondIndex, thirdIndex, a) {
  /** 获取方差齐性检验中文字的描述
   * @param value 当前进行的检验的值
   * @param name_of_sample 样本的名字，例如“不同文化程度中年龄的样本”
   * @param flow_value 流程的值
   * @param isNorm 正态检验的p值
   * @param a 显著水平a
   */
  const index_of_norm = `3.${secondIndex}.${thirdIndex.normIndex}`
  const text_norm = `由${index_of_norm}可知：${name_of_sample}服从正态分布，因此采用Bartlett算法进行方差齐性检验，`
  const text_close_norm = `由${index_of_norm}可知：${name_of_sample}近似服从正态分布，因此采用Levene算法进行方差齐性检验，`
  const text_no_norm = `由${index_of_norm}可知：${name_of_sample}不服从正态分布，因此采用Levene算法进行方差齐性检验，`
  const text_end = `<br/><span class="H0H1">H0：${name_of_sample}方差齐。</span><br/><span class="H0H1">H1：${name_of_sample}方差不齐。</span><br>检验结果如下表所示：`
  let text
  if (value !== 'auto') {
    text = `采用${value}算法进行方差齐性检验，`
  } else {
    if (flow_value === 'auto') {
      if (isNorm === 1) {
        text = text_norm
      } else if (isNorm === a) {
        text = text_close_norm
      } else if (isNorm === 0) {
        text = text_no_norm
      }
    } else if (['anova', 't', 'z'].indexOf(flow_value) !== -1) {
      if (isNorm === 1) {
        text = text_norm
      } else if (isNorm === a) {
        text = text_close_norm
      } else if (isNorm === 0) {
        text = text_no_norm
      }
    }
  }
  return text + text_end
}
export default {
  props: ['data', 'lei', 'isNorm', 'settings'],
  created() {
    const { data, lei, isNorm, a } = this
    // 把settings里的配置添加到data里去。
    this.data.method = 'auto'
    if (this.settings) this.data.method = this.settings.args[0].value // method是方差齐性检验时候要使用的方法。
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'HomogeneityOfVariances', data, lei, isNorm, a }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '方差齐性检验',
          msg: res
        })
      } else {
        this.imgs = res.imgs
        const tables = res.tables
        // 传递P值出去
        const ary = this.getPList(tables)
        this.$emit('isHomogeneous', ary)
        tables.forEach(table => {
          // 合并统计指标
          table.columns.forEach((column, i) => {
            if (i === 0 || i === 2 || i === 3 || i === 4) {
              column.customRender = (text, record, index) => {
                const obj = {
                  children: text,
                  attrs: {}
                }
                if (index === 0) {
                  obj.attrs.rowSpan = table.data.length
                } else {
                  obj.attrs.rowSpan = 0
                }
                return obj
              }
            }
          })
        })
        this.getMergeSetting(tables)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  methods: {
    getPList(tables) {
      return tables.map(table => table.data.slice(-1)[0].p)
    },
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      tables[0].settings = [[], [], [], []]
      const columnAry = [0, 2, 3, 4]
      columnAry.forEach((v, i) => {
        for (let index = 1; index <= table_row_num; index++) {
          tables[0].settings[i].push([index, v])
        }
      })
    }
  },
  computed: {
    a() {
      return this.$store.state.report.a
    }
  }
}
</script>