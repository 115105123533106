var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "50px" } },
    [
      _c(
        "a-spin",
        { attrs: { tip: "文件加载中...", spinning: _vm.spinning } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-upload-dragger",
                    {
                      attrs: {
                        name: "file",
                        "before-upload": _vm.beforeUpload,
                        "file-list": _vm.fileList,
                        accept: ".xls, .xlsx"
                      },
                      on: { change: _vm.handleChange }
                    },
                    [
                      _c("div", { staticStyle: { padding: "50px" } }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              staticClass: "ant-upload-text",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "bold",
                                "margin-right": "5px",
                                "text-decoration": "underline"
                              }
                            },
                            [_vm._v("点击上传文件")]
                          ),
                          _vm._v(" 或者拖拽上传"),
                          _c("br"),
                          _vm._v(" 支持Excel文件 ")
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("a-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  {
                    staticStyle: { "margin-left": "30px", "margin-top": "20px" }
                  },
                  [
                    _c("router-link", { attrs: { to: "/file/List" } }, [
                      _c(
                        "a",
                        {
                          staticClass: "ant-upload-text",
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold",
                            "text-decoration": "underline"
                          }
                        },
                        [_vm._v("返回文件列表")]
                      )
                    ]),
                    _c("br"),
                    _vm._v(" 导入说明："),
                    _c("br"),
                    _vm._v(" 1、支持xls、xlsx等格式"),
                    _c("br"),
                    _vm._v(" 2、不能合并单元格"),
                    _c("br"),
                    _vm._v(" 3、第一行是标题不能缺失"),
                    _c("br"),
                    _vm._v(
                      " 4、标题中请不要包含以下特殊字符[`~!@#$^&*()=|{}':;',\\\\[\\\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？空格"
                    ),
                    _c("br"),
                    _vm._v(
                      " 5、当数值型超过有效数据的70%，则自动将该列变成数值型"
                    ),
                    _c("br"),
                    _vm._v(
                      " 6、当文本种类小于有效数据的30%且种类少于13个时，该列为定类 "
                    ),
                    _c("br")
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: 15 } },
                [
                  _c(
                    "a-tabs",
                    { attrs: { "default-active-key": "1" } },
                    [
                      _c(
                        "a-tab-pane",
                        { key: "1", attrs: { tab: "表格示例1" } },
                        [
                          _c("a-table", {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              bordered: "",
                              size: "small",
                              rowKey: "key",
                              columns: _vm.columns1,
                              "data-source": _vm.dataChart1,
                              pagination: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        {
                          key: "2",
                          attrs: { tab: "表格示例2", "force-render": "" }
                        },
                        [
                          _c("a-table", {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              bordered: "",
                              size: "small",
                              rowKey: "key",
                              columns: _vm.columns2,
                              "data-source": _vm.dataChart2,
                              pagination: false
                            }
                          }),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _vm._v(
                                " 如果是上面格式的表格需要转换成下面的格式"
                              ),
                              _c("a-icon", {
                                staticStyle: {
                                  "font-size": "20px",
                                  "margin-left": "20px"
                                },
                                attrs: { type: "sync" }
                              })
                            ],
                            1
                          ),
                          _c("a-table", {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              bordered: "",
                              size: "small",
                              rowKey: "key",
                              columns: _vm.columns3,
                              "data-source": _vm.dataChart3,
                              pagination: false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }