// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/gr_indexbj.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#top[data-v-dff4100c] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100%;\n  height: 200px;\n}\nth[data-v-dff4100c]:nth-child(1) {\n  width: 20%;\n}\nth[data-v-dff4100c]:nth-child(2) {\n  width: 40%;\n}\nth[data-v-dff4100c]:nth-child(3) {\n  width: 40%;\n}\n#table[data-v-dff4100c] {\n  text-align: center;\n  background-color: white;\n  border-color: #dfdfdf;\n}\n#table .icon[data-v-dff4100c] {\n  font-size: 20px;\n}\n#table .close[data-v-dff4100c] {\n  color: #999999;\n}\n#table .check[data-v-dff4100c] {\n  color: #dbb427;\n}\n#table .common-th[data-v-dff4100c] {\n  background-color: #595959;\n  color: white;\n}\n#table .vip-th[data-v-dff4100c] {\n  background-color: #e2cd7e;\n}\n", ""]);
// Exports
module.exports = exports;
