var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "30px 30px"
      }
    },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c(
                      "a-form-model",
                      {
                        staticStyle: { width: "70%" },
                        attrs: {
                          labelAlign: "left",
                          "label-col": { md: { span: 4 }, xxl: { span: 3 } },
                          "wrapper-col": { md: { span: 20 }, xxl: { span: 21 } }
                        }
                      },
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "阶数" } },
                          [
                            _c(
                              "a-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.fetchResult()
                                  }
                                },
                                model: {
                                  value: _vm.k,
                                  callback: function($$v) {
                                    _vm.k = $$v
                                  },
                                  expression: "k"
                                }
                              },
                              _vm._l(_vm.kOptions, function(v) {
                                return _c(
                                  "a-radio",
                                  { key: v, attrs: { value: v } },
                                  [_vm._v(" " + _vm._s(v) + " ")]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "光滑指数" } },
                          [
                            _c(
                              "a-row",
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c("a-slider", {
                                      staticStyle: { width: "95%" },
                                      attrs: {
                                        marks: _vm.marks,
                                        min: 0,
                                        max: 1,
                                        step: 0.01
                                      },
                                      on: {
                                        afterChange: function($event) {
                                          return _vm.fetchResult()
                                        }
                                      },
                                      model: {
                                        value: _vm.ind,
                                        callback: function($$v) {
                                          _vm.ind = $$v
                                        },
                                        expression: "ind"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("a-input-number", {
                                      staticStyle: { width: "50%" },
                                      attrs: { step: 0.01, min: 0, max: 1 },
                                      model: {
                                        value: _vm.ind,
                                        callback: function($$v) {
                                          _vm.ind = $$v
                                        },
                                        expression: "ind"
                                      }
                                    }),
                                    _c("a-icon", {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                        "font-size": "20px",
                                        "margin-left": "20px"
                                      },
                                      attrs: { type: "redo" },
                                      on: {
                                        click: function($event) {
                                          return _vm.fetchResult()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("a-row", [_c("a-col", { attrs: { span: 24 } })], 1),
            _c(
              "a-row",
              _vm._l(_vm.res.images, function(item, index) {
                return _c(
                  "a-col",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    },
                    attrs: { span: 24 }
                  },
                  [
                    _c("MyImg", {
                      attrs: { imgBytes: item, fileName: "样条平滑图" }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }