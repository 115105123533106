import {
  onChange,
  onClose
} from '@/views/analysisOfRegression/utils/inputItemsClass'
var xysName = 'polynomialRegressionXys'

export const settings = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 10, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    rules: [
      (callback, v, divHelp) => {
        if (v.list.every(v => v.type === '类')) callback(divHelp[0])
      }
    ],
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(1个 ≤ 个数 ≤ 10个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
    }
  },
  {
    id: 1,
    index: 1,
    prop: 'dependentVariable',
    label: '因变量y【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
    }
  },
  {
    label: '阶数',
    componentName: 'Step',
    rules() {
      return [{
        required: true,
        message: this.label + '不能为空',
        trigger: 'change'
      }]
    },
    prop: 'step',
    parentValue: 1,
    placeholder: '其他',
    type: 'component',
    radioValue: '2',
    selectValue: undefined,
    radioOptions: [{
        text: '二次方',
        value: '2'
      },
      {
        text: '三次方',
        value: '3'
      },
      {
        text: '四次方',
        value: '4'
      },
      {
        text: '五次方',
        value: '5'
      },
      {
        text: '六次方',
        value: '6'
      }
    ],
    selectOptions: [{
        text: '七次方',
        value: '7'
      },
      {
        text: '八次方',
        value: '8'
      },
      {
        text: '九次方',
        value: '9'
      },
      {
        text: '十次方',
        value: '10'
      }
    ],
    children: []
  }
  // {
  //   label: '交互',
  //   prop: 'interaction',
  //   parentValue: 1,
  //   type: 'radio',
  //   value: '0',
  //   options: [{
  //       value: '0',
  //       text: '否'
  //     },
  //     {
  //       value: '1',
  //       text: '是'
  //     }
  //   ],
  //   children: []
  // }
]