<template>
  <div class="drops">
    <a-row type="flex">
      <a-col flex="50px" class="center"></a-col>
      <a-col :span="14">
        <a-row type="flex" justify="space-around">
          <a-col v-for="(obj, index) in item.settings[0]" :key="index" :span="numOfDrop" class="center strong-font">
            {{ obj.label }}
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="25px" class="center"> </a-col>
      <a-col class="center strong-font" :span="8">研究方向</a-col>
    </a-row>
    <div class="single" v-for="(single, index) in item.settings" :key="index">
      <a-row type="flex" align="middle">
        <a-col flex="50px" class="center strong-font">{{ index + 1 }}.</a-col>
        <a-col :span="14">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col
              style="display: flex !important; align-items: center"
              v-for="(drop, i) in single"
              :key="i"
              :span="numOfDrop"
            >
              <a-form-model-item
                style="width: 100%"
                :labelCol="{ md: { span: 0 }, xxl: { span: 0 } }"
                :wrapperCol="{ md: { span: 24 }, xxl: { span: 24 } }"
                :label="``"
                :prop="drop.prop"
                :rules="
                  single.every((drop) => drop.list.length === 0)
                    ? []
                    : [
                      {
                        validator: (rule, value, callback) =>
                          validateDrop(rule, value, callback, drop, $deepClone(drop.divHelp), this),
                      },
                    ]
                "
              >
                <div style="display: flex; align-items: start">
                  <drop
                    :load="load"
                    :item="drop"
                    :index="[index, i]"
                    :vuex="vuex"
                    :parentType="'drops'"
                    :xysName="item.xysName"
                    :indexGroup="group"
                  ></drop>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="25px" class="center">
          <a-icon
            v-if="!('mark' in single[0])"
            class="dynamic-delete-button"
            type="minus-circle-o"
            :disabled="item.settings.length === 1"
            @click="deleteGroup(index, item.settings.length)"
          />
        </a-col>
        <a-col :span="8">
          <a-row>
            <a-col :span="24" style="height: 42px; display: flex; align-items: center">
              <a-select
                v-model="single[0].value2"
                mode="multiple"
                :maxTagCount="2"
                :maxTagTextLength="8"
                placeholder="暂无研究方向"
                style="width: 100%"
                option-label-prop="label"
                :disabled="typeSelectOptions[index].options.length === 1"
              >
                <a-select-option v-for="v in typeSelectOptions[index].options" :key="v.value" :label="v.label">{{
                  v.text
                }}</a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- <a-divider></a-divider> -->
    </div>
    <a-row type="flex">
      <a-col flex="50px"></a-col>
      <a-col :span="14" class="center">
        <a-button type="dashed" style="width: 91%" @click="addGroup"> <a-icon type="plus" /> 增加变量组 </a-button>
      </a-col>
      <a-col flex="25px" class="center"> </a-col>
      <a-col :span="8"></a-col>
    </a-row>
  </div>
</template>
<script>
import Drop from '@/components/myComponents/drop'
export default {
  name: 'ReportDrops',
  components: { Drop },
  data() {
    return {
      i: 0
    }
  },
  props: ['item', 'settings', 'load', 'vuex', 'typeSelectOptions'], // 这里的item传的是整个drops组件的属性
  methods: {
    validateDrop(rule, value, callback, v, divHelp, that) {
      // 'required' in v直接通过是因为存在required:false，允许为空的话，所以能通过。
      // v为拖进拖拽框的每一个项
      if ('required' in v && v.list.length === 0) { callback() } else {
        // 如果拖拽框里的个数不满足要求，页面报错
        if (v.list.length < (v.min === 0 ? 0 : (v.min || 1)) || v.list.length > (v.num || 5)) callback(divHelp[0])
        // 如果拖拽框有自定义规则，那么执行自定义规则，例如线性回归里的最小二乘
        if ('rules' in v && v.rules.length > 0) v.rules.forEach(func => { func(callback, v, divHelp, that) })
        // 如果都满足条件，直接通过
        callback()
      }
    },
    addGroup() {
      const newGroup = this.$deepClone(this.item.group)
      const newXys = this.$deepClone(this.item.addXys)
      newGroup.forEach(v => {
        v.prop = v.prop + this.i.toString()
      })
      this.item.settings.push(newGroup)
      this.$store.state[this.vuex][this.item.xysName].push(newXys)
      this.i++
      this.$store.dispatch('refreshRules')
    },
    deleteGroup(index, length) {
      if (length !== 1) {
        this.item.settings.splice(index, 1)
        this.$store.state[this.vuex][this.item.xysName].splice(index, 1)
        this.$store.dispatch('refreshRules')
      }
    }
  },
  computed: {
    numOfDrop() {
      return 20 / this.item.settings[0].length
    },
    group() {
      return this.item.group
    },
    lastGroup() {
      return this.item.settings.slice(-1)[0].map(v => v.list)
    }
  },
  watch: {
    lastGroup(newValue, oldValue) {
      if (newValue.every(obj => obj.length > 0)) this.addGroup()
    }
  }
}
</script>
<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  right: 30px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.drops .ant-form-item {
  margin: 5px !important;
  display: flex !important;
  align-items: center;
}
</style>