var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("a-result", {
        attrs: {
          status: "warning",
          title:
            _vm.id === 1
              ? "量表(问卷)未开始"
              : _vm.id === 2
              ? "量表(问卷)已结束"
              : "量表(问卷)已失效"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }