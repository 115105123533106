<template>
  <div>
    <a-row v-for="(content, index) in list" :key="index" style="margin-bottom: 15px">
      <a-col :span="24">
        <help-size1 :content="content"></help-size1>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import HelpSize1 from './helpSize1'
import { analysisOfRegressionBrief } from '@/common/enums'
export default {
  computed: {
    list() {
      return analysisOfRegressionBrief[this.$store.state.analysisOfRegression.method]
    }
  },
  components: { HelpSize1 }
}
</script>
<style lang="less" scoped>
// .wrapper {
//   display: flex;
//   justify-content: center;
// }
</style>