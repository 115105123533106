<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 30px">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24">
            <div style="margin-bottom: 20px" v-for="(table, index) in res.tables" :key="index">
              <my-table2
                :showHeader="table.showHeader"
                :data="table.data"
                :columns="table.columns"
                :title="table.title"
                :size="table.size"
              >
              </my-table2>
              <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            v-for="(item, index) in res.images"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="`碎石图`"></MyImg>
          </a-col>
        </a-row>
      </div>
      <box-shadow-card v-if="'tables' in res" style="margin-top: 20px" :title="'主成分和各研究项之间的关系'">
        <div>
          <p v-for="(item, index) in getConclusion()" :key="index">
            {{ item }}
          </p>
        </div>
      </box-shadow-card>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/views/analysisOfRegression/MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import MyTable2 from '../../table'
import { getBartlettTable, getZfcjsb, getZhxsb, getZcfdfxsb } from './getResultTable'
export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {}
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).PCA()
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      console.log('%c 🍿 res: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', res)
      return {
        tables: [
          getBartlettTable(res.tables[0]),
          getZfcjsb(res.tables[1]),
          getZhxsb(res.tables[2]),
          getZcfdfxsb(res.tables[3])
        ],
        images: res.imgs
      }
    },
    getConclusion() {
      const { res } = this
      const ary = []
      res.tables[3].columns[1].children.forEach(v => {
        const dataIndex = v.dataIndex
        let formula = `成分得分${v.title} = `
        res.tables[3].data.forEach((j, jIndex) => {
          jIndex === 0
            ? formula = `${formula}${+j[dataIndex]} × ${j.index.trim()}`
            : formula = `${formula}${+j[dataIndex] > 0 ? ' ＋ ' : ' － '}${Math.abs(j[dataIndex])} × ${j.index.trim()}`
        })
        ary.push(formula)
      })
      ary.push('各成分得分中研究项的数据需要z标准化才能代入。')
      // 综合得分结论
      const number = res.tables[1].number
      const total = res.tables[1].data[number - 1]['累积方差贡献率%']
      let formula = `综合得分 = ( `
      res.tables[1].data.forEach((v, vIndex) => {
        if (vIndex < number) {
          vIndex === 0
            ? formula = `${formula}${v['方差贡献率%2']} × 成分得分${v.key}`
            : formula = `${formula} + ${v['方差贡献率%2']} × 成分得分${v.key}`
        }
      })
      formula = `${formula} ) / ${total}`
      ary.push(formula)
      return ary
    }
  }
}
</script>