<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 1 && data.xys[1].length > 0">
        <div>1.<{{ data.xys[0][0].slice(-1)[0] }}&gt;对<{{ data.xys[1][0] }}&gt;的影响不显著</div>
        <div>2.<{{ data.xys[0][1].slice(-1)[0] }}&gt;对<{{ data.xys[1][0] }}&gt;的影响不显著</div>
        <div>
          3.<{{ data.xys[0][0].slice(-1)[0] }}&gt;和<{{ data.xys[0][1].slice(-1)[0] }}&gt;的交互作用对<{{
            data.xys[1][0]
          }}&gt;的影响不显著
        </div>
      </div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 1 && data.xys[1].length > 0">
        <div>1.<{{ data.xys[0][0].slice(-1)[0] }}&gt;对<{{ data.xys[1][0] }}&gt;的影响显著</div>
        <div>2.<{{ data.xys[0][1].slice(-1)[0] }}&gt;对<{{ data.xys[1][0] }}&gt;的影响显著</div>
        <div>
          3.<{{ data.xys[0][0].slice(-1)[0] }}&gt;和<{{ data.xys[0][1].slice(-1)[0] }}&gt;的交互作用对<{{
            data.xys[1][0]
          }}&gt;的影响显著
        </div>
      </div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'sysfcfxXys',
      xys: [[], [], []],
      formAddKeyName: 'sysfcfxFormAddKey',
      settings,
      form: {
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
          if (this.$store.state.hypothesis.method) {
            var form = this.$deepClone(that.form)
            form.xys = this.$deepClone(that.computedXys)
            form.a = parseFloat(form.a) / 100
            this.$store.dispatch('saveDybtjy', form)
            this.$router.push('/hypothesistest/result')
          } else {
            this.$message.info('请选择假设检验方法')
            return false
          }
    }
  }
}
</script>