var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        [_c("a-col", { attrs: { span: 24 } }, [_vm._t("default")], 2)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }