var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings,
      rules: _vm.rules
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[1].length > 0 && xys[0].length === 0
              ? _c("div", [_vm._v("<" + _vm._s(xys[1][0]) + ">服从正态分布")])
              : _vm._e(),
            xys[1].length > 0 && xys[0].length > 0
              ? _c("div", [
                  _vm._v(
                    " 分别为<" +
                      _vm._s(xys[0][0].slice(-1)[0]) +
                      ">中<总体>、" +
                      _vm._s(
                        xys[0][0]
                          .slice(0, -1)
                          .map(function(v) {
                            return "<" + v + ">"
                          })
                          .join("、")
                      ) +
                      "的<" +
                      _vm._s(xys[1][0]) +
                      ">做正态检验，符合正态分布。 "
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "H1",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[1].length > 0 && xys[0].length === 0
              ? _c("div", [_vm._v("<" + _vm._s(xys[1][0]) + ">不服从正态分布")])
              : _vm._e(),
            xys[1].length > 0 && xys[0].length > 0
              ? _c("div", [
                  _vm._v(
                    " 分别为<" +
                      _vm._s(xys[0][0].slice(-1)[0]) +
                      ">中<总体>、" +
                      _vm._s(
                        xys[0][0]
                          .slice(0, -1)
                          .map(function(v) {
                            return "<" + v + ">"
                          })
                          .join("、")
                      ) +
                      "的<" +
                      _vm._s(xys[1][0]) +
                      ">做正态检验，不符合正态分布。 "
                  )
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }