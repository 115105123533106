var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.answerid && _vm.data.state === 0
      ? _c(
          "div",
          {
            staticStyle: {
              position: "fixed",
              top: "0px",
              width: "100%",
              height: "50px",
              background: "#001529",
              "z-index": "9999",
              opacity: "0.5"
            }
          },
          [
            _c(
              "a-row",
              { attrs: { type: "flex", justify: "end" } },
              [
                _c(
                  "a-col",
                  {
                    staticClass: "title",
                    staticStyle: { "margin-right": "20px" }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "font-weight": "bold", color: "black" },
                        on: { click: _vm.handelSubmit }
                      },
                      [_vm._v("确认有效量表(问卷)")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          color: "black",
                          "font-weight": "bold"
                        },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "example" },
          [_c("a-spin", { attrs: { tip: "加载中...", size: "large" } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticStyle: { "background-color": "#fbfaf7", "padding-top": "50px" } },
      [
        _vm.loading === false
          ? _c(
              "a-form-model",
              {
                ref: "ruleForm",
                staticClass: "divStyle",
                staticStyle: { "background-color": "#fefefe" },
                attrs: { model: _vm.form, bordered: false }
              },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      { staticClass: "title marginTop", attrs: { span: 20 } },
                      [_vm._v(_vm._s(_vm.data.modelName))]
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "a-col",
                      { staticClass: "marginTop", attrs: { span: 20 } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "white-space": "pre-wrap" } },
                          [_vm._v(_vm._s(_vm.data.modelDescription))]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("subject-comp-new", {
                  attrs: {
                    data: _vm.data.questions,
                    formaaa: _vm.form,
                    childrenRequire: true,
                    isAnswer: false,
                    disabled:
                      _vm.answerid && _vm.data.state === 1 ? true : false
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }