// eslint-disable-next-line
import {
  UserLayout,
  BasicLayout,
  PersonLayout,
  PersonLoginLayout
} from '@/layouts'
// import store from '../store'
import store from '@/store/index'
// import { bxAnaalyse } from '@/core/icons'

// const RouteView = {
//   name: 'RouteView',
//   render: (h) => h('router-view')
// }
const domain = document.domain.toLowerCase()
var select = 'index'
export const asyncRouterMap = [{
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: {
      title: 'menu.home',
      keepAlive: true
    },
    // redirect: domain.includes('uubigdata') ? '/home' : '/personHome',
    redirect: domain.includes('uubigdata') ? '/personHome' : '/home',
    children: [
      // {
      //   path: '/report/list',
      //   name: 'reportList',
      //   component: () => import('@/views/report/ReportShare'),
      //   meta: {
      //     title: '智能报告列表',
      //     icon: 'home',
      //     keepAlive: true,
      //     permission: ['admin', 'user', 'groupleader', 'statistician'],
      //     active: true
      //   }
      // },
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          title: '总览',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician'],
          active: true
        }
      },
      {
        path: '/personHome',
        name: 'personHome',
        component: () => import('@/views/home/PersonHome'),
        meta: {
          title: '主页',
          icon: 'home',
          keepAlive: true,
          permission: ['person'],
          active: true
        }
      },
      {
        path: '/missingStatistics',
        name: 'home',
        hidden: true,
        component: () => import('@/views/missingValue'),
        meta: {
          title: '缺失值统计',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        }
      },
      // 假设检验 变量处理
      {
        path: '/hypothesistest/dataHandle/',
        name: 'hypothesistestdataHandle',
        component: () => import('@/views/dataHandle/index'),
        hidden: true,
        meta: {
          title: '数据处理',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        children: [{
          path: '/hypothesistest/dataHandle/document/rangecode',
          name: 'hypothesistestdataHandledocumentrangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/questionnaire/rangecode',
          name: 'hypothesistestdataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/experiment/rangecode',
          name: 'hypothesistestdataHandleexperimentrangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/file/rangecode',
          name: 'hypothesistestdataHandlefilerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/document/invalidSample',
          name: 'hypothesistestdataHandledocumentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/questionnaire/invalidSample',
          name: 'hypothesistestdataHandlequestionnaireinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/experiment/invalidSample',
          name: 'hypothesistestdataHandleexperimentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/hypothesistest/dataHandle/file/invalidSample',
          name: 'hypothesistestdataHandlefileinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/hypothesistest/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }]
      },
      // 智能报告 变量处理
      {
        path: '/intelligenceReports/dataHandle/',
        name: 'intelligenceReportsdataHandle',
        component: () => import('@/views/dataHandle/index'),
        hidden: true,
        meta: {
          title: '数据处理',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        },
        children: [{
          path: '/intelligenceReports/dataHandle/document/rangecode',
          name: 'intelligenceReportsdataHandledocumentrangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/questionnaire/rangecode',
          name: 'intelligenceReportsdataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/experiment/rangecode',
          name: 'intelligenceReportsdataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/file/rangecode',
          name: 'intelligenceReportsdataHandlefilerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/document/invalidSample',
          name: 'intelligenceReportsdataHandledocumentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/questionnaire/invalidSample',
          name: 'intelligenceReportsdataHandlequestionnaireinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/experiment/invalidSample',
          name: 'intelligenceReportsdataHandleexperimentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/intelligenceReports/dataHandle/file/invalidSample',
          name: 'intelligenceReportsdataHandlefileinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/intelligenceReports/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }]
      },

      // 回归分析 变量处理
      {
        path: '/analysisOfRegression/dataHandle/',
        name: 'analysisOfRegressiondataHandle',
        component: () => import('@/views/dataHandle/index'),
        hidden: true,
        meta: {
          title: '数据处理',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        },
        children: [{
          path: '/analysisOfRegression/dataHandle/document/rangecode',
          name: 'analysisOfRegressiondataHandledocumentrangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/questionnaire/rangecode',
          name: 'analysisOfRegressiondataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/experiment/rangecode',
          name: 'analysisOfRegressiondataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/file/rangecode',
          name: 'analysisOfRegressiondataHandlefilerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/document/invalidSample',
          name: 'analysisOfRegressiondataHandledocumentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/questionnaire/invalidSample',
          name: 'analysisOfRegressiondataHandlequestionnaireinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/experiment/invalidSample',
          name: 'analysisOfRegressiondataHandleexperimentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician']
          }
        }, {
          path: '/analysisOfRegression/dataHandle/file/invalidSample',
          name: 'analysisOfRegressiondataHandlefileinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/analysisOfRegression/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician']
          }
        }]
      },

      // 图表 变量处理
      {
        path: '/customchart/dataHandle/',
        name: 'customchartdataHandle',
        component: () => import('@/views/dataHandle/index'),
        hidden: true,
        meta: {
          title: '数据处理',
          icon: 'home',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        children: [{
          path: '/customchart/dataHandle/document/rangecode',
          name: 'customchartdataHandledocumentrangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }

        }, {
          path: '/customchart/dataHandle/questionnaire/rangecode',
          name: 'customchartdataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/experiment/rangecode',
          name: 'customchartdataHandlequestionnairerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/file/rangecode',
          name: 'customchartdataHandlefilerangecode',
          component: () => import('@/views/dataHandle/rangeCode'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/document/invalidSample',
          name: 'customchartdataHandledocumentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/questionnaire/invalidSample',
          name: 'customchartdataHandlequestionnaireinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/experiment/invalidSample',
          name: 'customchartdataHandleexperimentinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }, {
          path: '/customchart/dataHandle/file/invalidSample',
          name: 'customchartdataHandlefileinvalidSample',
          component: () => import('@/views/dataHandle/invalidSample'),
          beforeEnter: (to, from, next) => {
            var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
            if (keys && keys.length > 0) {
              next()
            } else {
              next({
                path: '/customchart/screendata'
              })
            }
          },
          meta: {
            title: '范围编码',
            icon: 'home',
            keepAlive: true,
            permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
            active: true
          }
        }]
      },
      // 假设检验
      {
        path: '/hypothesistest/screendata',
        name: 'sfaf',
        component: () => import('@/views/screendata'),
        hideChildrenInMenu: true,
        hidden: true,
        meta: {
          title: 'ss',
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        redirect: '/hypothesistest/screendata/document',
        children: [{
            path: '/hypothesistest/screendata/document',
            component: () => import('@/views/screendata/conditiondocument'),
            meta: {
              title: '11',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            redirect: '/hypothesistest/screendata/document/nodata',
            children: [{
                path: '/hypothesistest/screendata/document/nodata',
                name: 'hypothesistestdocumentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/hypothesistest/screendata/document/data',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '档案数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/hypothesistest/screendata/questionnaire',
            component: () => import('@/views/screendata/conditionquestion'),
            meta: {
              title: '11',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            redirect: '/hypothesistest/screendata/questionnaire/nodata',
            children: [{
                path: '/hypothesistest/screendata/questionnaire/nodata',
                name: 'hypothesistestquestionnairenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/hypothesistest/screendata/questionnaire/data/:id?',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '量表(问卷)数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/hypothesistest/screendata/experiment',
            name: 'hypothesistestexperiment',
            component: () => import('@/views/screendata/conditionexperiment'),
            meta: {
              title: '实验数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/hypothesistest/screendata/experiment/nodata',
            children: [{
                path: '/hypothesistest/screendata/experiment/nodata',
                name: 'hypothesistestexperimentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/hypothesistest/screendata/experiment/data/:id?',
                name: 'hypothesistestexperimentdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '实验数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/hypothesistest/screendata/file',
            name: 'hypothesistestfile',
            component: () => import('@/views/screendata/conditionfile'),
            meta: {
              title: '文件',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/hypothesistest/screendata/file/nodata',
            children: [{
                path: '/hypothesistest/screendata/file/nodata',
                name: 'hypothesistestfilenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/hypothesistest/screendata/file/data/:id?',
                name: 'hypothesistestdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '文件',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          }
        ]
      },
      // 回归分析
      {
        path: '/analysisOfRegression/screendata',
        name: 'zb',
        component: () => import('@/views/screendata'),
        hideChildrenInMenu: true,
        hidden: true,
        meta: {
          title: 'cv',
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        },
        redirect: '/analysisOfRegression/screendata/document',
        children: [{
            path: '/analysisOfRegression/screendata/document',
            component: () => import('@/views/screendata/conditiondocument'),
            meta: {
              title: '45',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            redirect: '/analysisOfRegression/screendata/document/nodata',
            children: [{
                path: '/analysisOfRegression/screendata/document/nodata',
                name: 'analysisOfRegressiondocumentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/analysisOfRegression/screendata/document/data',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '档案数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/analysisOfRegression/screendata/questionnaire',
            component: () => import('@/views/screendata/conditionquestion'),
            meta: {
              title: '1125',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            redirect: '/analysisOfRegression/screendata/questionnaire/nodata',
            children: [{
                path: '/analysisOfRegression/screendata/questionnaire/nodata',
                name: 'analysisOfRegressionquestionnairenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/analysisOfRegression/screendata/questionnaire/data/:id?',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '量表(问卷)数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/analysisOfRegression/screendata/experiment',
            name: 'analysisOfRegressionexperiment',
            component: () => import('@/views/screendata/conditionexperiment'),
            meta: {
              title: '实验数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            hideChildrenInMenu: true,
            redirect: '/analysisOfRegression/screendata/experiment/nodata',
            children: [{
                path: '/analysisOfRegression/screendata/experiment/nodata',
                name: 'analysisOfRegressionexperimentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/analysisOfRegression/screendata/experiment/data/:id?',
                name: 'analysisOfRegressionexperimentdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '实验数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/analysisOfRegression/screendata/file',
            name: 'analysisOfRegressionfile',
            component: () => import('@/views/screendata/conditionfile'),
            meta: {
              title: '文件',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            hideChildrenInMenu: true,
            redirect: '/analysisOfRegression/screendata/file/nodata',
            children: [{
                path: '/analysisOfRegression/screendata/file/nodata',
                name: 'analysisOfRegressionfilenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/analysisOfRegression/screendata/file/data/:id?',
                name: 'analysisOfRegressionfiledata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '文件',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          }
        ]
      },
      // 智能报告
      {
        path: '/intelligenceReports/screendata',
        name: 'zb',
        component: () => import('@/views/screendata'),
        hideChildrenInMenu: true,
        hidden: true,
        meta: {
          title: 'cv',
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        },
        redirect: '/intelligenceReports/screendata/document',
        children: [{
            path: '/intelligenceReports/screendata/document',
            component: () => import('@/views/screendata/conditiondocument'),
            meta: {
              title: '45',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            redirect: '/intelligenceReports/screendata/document/nodata',
            children: [{
                path: '/intelligenceReports/screendata/document/nodata',
                name: 'reportdocumentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/intelligenceReports/screendata/document/data',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '档案数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/intelligenceReports/screendata/questionnaire',
            component: () => import('@/views/screendata/conditionquestion'),
            meta: {
              title: '1125',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            redirect: '/intelligenceReports/screendata/questionnaire/nodata',
            children: [{
                path: '/intelligenceReports/screendata/questionnaire/nodata',
                name: 'reportquestionnairenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/intelligenceReports/screendata/questionnaire/data/:id?',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '量表(问卷)数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/intelligenceReports/screendata/experiment',
            name: 'reportexperiment',
            component: () => import('@/views/screendata/conditionexperiment'),
            meta: {
              title: '实验数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            hideChildrenInMenu: true,
            redirect: '/intelligenceReports/screendata/experiment/nodata',
            children: [{
                path: '/intelligenceReports/screendata/experiment/nodata',
                name: 'reportexperimentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/intelligenceReports/screendata/experiment/data/:id?',
                name: 'reportexperimentdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '实验数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          },
          {
            path: '/intelligenceReports/screendata/file',
            name: 'reportfile',
            component: () => import('@/views/screendata/conditionfile'),
            meta: {
              title: '文件',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            hideChildrenInMenu: true,
            redirect: '/intelligenceReports/screendata/file/nodata',
            children: [{
                path: '/intelligenceReports/screendata/file/nodata',
                name: 'reportfilenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/intelligenceReports/screendata/file/data/:id?',
                name: 'reportfiledata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '文件',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }
            ]
          }
        ]
      },
      // 图表
      {
        path: '/customchart/screendata',
        name: 'customchartscreendata',
        component: () => import('@/views/screendata'),
        hideChildrenInMenu: true,
        hidden: true,
        meta: {
          title: '筛选样本',
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        redirect: '/customchart/screendata/document',
        children: [{
            path: '/customchart/screendata/document',
            name: 'customchartdocument',
            component: () => import('@/views/screendata/conditiondocument'),
            meta: {
              title: '档案数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            redirect: '/customchart/screendata/document/nodata',
            children: [{
                path: '/customchart/screendata/document/nodata',
                name: 'customchartdocumentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/customchart/screendata/document/data',
                name: 'customchartdocumentdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '档案数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/customchart/screendata/questionnaire',
            name: 'customchartquestionnaire',
            component: () => import('@/views/screendata/conditionquestion'),
            meta: {
              title: '量表(问卷)数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/customchart/screendata/questionnaire/nodata',
            children: [{
                path: '/customchart/screendata/questionnaire/nodata',
                name: 'customchartquestionnairenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/customchart/screendata/questionnaire/data/:id?',
                name: 'customchartquestionnairedata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '量表(问卷)数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/customchart/screendata/experiment',
            name: 'customchartexperiment',
            component: () => import('@/views/screendata/conditionexperiment'),
            meta: {
              title: '实验数据',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/customchart/screendata/experiment/nodata',
            children: [{
                path: '/customchart/screendata/experiment/nodata',
                name: 'customchartexperimentnodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/customchart/screendata/experiment/data/:id?',
                name: 'customchartexperimentdata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '实验数据',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          },
          {
            path: '/customchart/screendata/file',
            name: 'customchartfile',
            component: () => import('@/views/screendata/conditionfile'),
            meta: {
              title: '文件',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/customchart/screendata/file/nodata',
            children: [{
                path: '/customchart/screendata/file/nodata',
                name: 'customchartfilenodata',
                component: () => import('@/views/screendata/dataEmpty'),
                meta: {
                  title: '数据选择',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              },
              {
                path: '/customchart/screendata/file/data/:id?',
                name: 'customchartfiledata',
                component: () => import('@/views/screendata/documentData'),
                meta: {
                  title: '文件',
                  icon: 'table',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }
            ]
          }
        ]

      },
      {
        path: '/document',
        name: 'document',
        component: () => import('@/views/document'),
        redirect: '/document/select',
        hideChildrenInMenu: true, // 隐藏子菜单
        meta: {
          title: '档案中心',
          icon: 'solution',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician']
        },
        children: [{
            path: '/document/select',
            name: 'selector',
            component: () => import('@/views/document/select'),
            meta: {
              title: '档案中心',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician']
            }
          },
          {
            path: '/document/:id',
            name: 'documentdetail',
            component: () => import('@/views/document/document'),
            meta: {
              title: '详情',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician']
            }
          }
        ]
      },
      {
        path: '/data',
        name: 'data',
        component: () => import('@/views/data/index'),
        meta: {
          title: '数据管理',
          icon: 'database',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        hideChildrenInMenu: true,
        children: [{
            path: '/question',
            name: 'question',
            component: () => import('@/views/question/index'),
            meta: {
              title: '数据管理',
              icon: 'form',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            hideChildrenInMenu: true,
            redirect: '/question/List',
            children: [{
                path: '/question/List',
                name: 'questionList',
                component: () => import('@/views/question/List'),
                meta: {
                  title: '量表(问卷)',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/question/AnswerList/:id?',
                name: 'questionAnswerList',
                component: () => import('@/views/question/AnswerList'),
                meta: {
                  title: '答卷列表',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/question/scoreStatistics/:id?',
                name: 'questionscoreStatistics',
                component: () => import('@/views/question/scoreStatistics'),
                meta: {
                  title: '分值统计',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/question/sequentialStatistics/:id?',
                name: 'questionscoreStatistics',
                component: () => import('@/views/question/sequentialStatistics'),
                meta: {
                  title: '患者列表',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              },
              {
                path: '/question/sequentialStatisticsDetails/:qID/:sID',
                name: 'sequentialStatisticsDetails',
                component: () => import('@/views/question/sequentialStatisticsDetails'),
                meta: {
                  title: '详情',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
                }
              }

            ]
          },
          {
            path: '/file',
            name: 'file',
            component: () => import('@/views/file/index'),
            meta: {
              title: '文件',
              icon: 'form',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            hideChildrenInMenu: true,
            redirect: '/file/List',
            children: [{
                path: '/file/List',
                name: 'fileList',
                component: () => import('@/views/file/List'),
                meta: {
                  title: '文件',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }, {
                path: '/file/Add',
                name: 'fileAdd',
                component: () => import('@/views/file/Add'),
                meta: {
                  title: '上传文件',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }, {
                path: '/file/FileData/:id?',
                name: 'fileFileData',
                component: () => import('@/views/file/FileData'),
                meta: {
                  title: '数据详情',
                  keepAlive: true,
                  permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                  active: true
                }
              }

            ]
          }
        ]
      },

      {
        path: '/experiment',
        name: 'experiment',
        component: () => import('@/views/experiment/index'),
        meta: {
          title: '实验',
          icon: 'experiment',
          permission: ['admin', 'user', 'groupleader', 'statistician']
        },
        hideChildrenInMenu: true,
        children: [{
          path: '/experiment/Exp1/:id?',
          name: 'Exp1',
          meta: {
            title: '',
            permission: ['admin', 'user', 'groupleader', 'statistician']
          },
          component: () => import('@/views/experiment/Exp1/index'),
          redirect: '/experiment/Exp1/List/:id?',
          children: [{
            path: '/experiment/Exp1/List/:id?',
            name: 'Exp1List',
            meta: {
              title: '实验',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp1/List')
          }, {
            path: '/experiment/Exp1/add/:id?',
            name: 'Exp1Add',
            meta: {
              title: '新建实验',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp1/NewExp/index')
          }, {
            path: '/experiment/Exp1/detail/:id?',
            name: 'Exp1Detail',
            meta: {
              title: '实验详情',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp1/Detail/index')
          }]
        }, {
          path: '/experiment/Exp2/:id?',
          name: 'Exp2',
          meta: {
            title: '',
            permission: ['admin', 'user', 'groupleader', 'statistician']
          },
          component: () => import('@/views/experiment/Exp2/index'),
          redirect: '/experiment/Exp2/List/:id?',
          children: [{
            path: '/experiment/Exp2/List/:id?',
            name: 'Exp2List',
            meta: {
              title: '实验',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp2/List')
          }, {
            path: '/experiment/Exp2/add/:id?',
            name: 'Exp2Add',
            meta: {
              title: '新建实验',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp2/NewExp/index')
          }, {
            path: '/experiment/Exp2/detail/:id?',
            name: 'Exp2Detail',
            meta: {
              title: '实验详情',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Exp2/Detail/index')
          }]
        }, {
          path: '/experiment/Exp3',
          name: 'Exp3',
          meta: {
            title: '量表(问卷)',
            permission: ['admin', 'user', 'groupleader', 'statistician']
          },
          component: () => import('@/views/experiment/Exp3/index')
        }, {
          path: '/experiment/Device',
          name: 'Device',
          meta: {
            title: '量表(问卷)',
            permission: ['admin', 'user', 'groupleader', 'statistician']
          },
          redirect: '/experiment/Device/index',
          component: () => import('@/views/experiment/Device/index'),
          children: [{
            path: '/experiment/Device/index',
            name: 'Device',
            meta: {
              title: '量表(问卷)',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Device/List')
          }, {
            path: '/experiment/Device/calibration/:id?',
            name: 'Device',
            meta: {
              title: '量表(问卷)',
              permission: ['admin', 'user', 'groupleader', 'statistician']
            },
            component: () => import('@/views/experiment/Device/Jiaoyan/index')
          }]
        }]

      },
      {
        path: '/commonchart',
        name: 'commonchart',
        component: () => import('@/views/commonCharts'),
        hidden: true,
        meta: {
          title: '档案统计',
          icon: 'line-chart',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician']
        }
      },
      {
        path: '/customchart',
        name: 'customchart',
        component: () => import('@/views/customchart'),
        hideChildrenInMenu: true,
        meta: {
          title: '图表',
          icon: 'control',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        redirect: '/customchart/chart',
        children: [{
            path: '/customchart/chart',
            name: 'customchartchart',
            component: () => import('@/views/customchart/chartNew'),
            beforeEnter: (to, from, next) => {
              var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
              if (keys && keys.length > 0) {
                next()
              } else {
                next({
                  path: '/customchart/screendata'
                })
              }
            },
            hidden: true,
            meta: {
              title: '图表',
              icon: 'table',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            }

          }

        ]

      },
      {
        path: '/hypothesistest',
        name: 'hypothesistest',
        component: () => import('@/views/hypothesisTest'),
        hideChildrenInMenu: true,
        redirect: `/hypothesistest/hypothesis/${select}`,
        meta: {
          title: '假设检验',
          icon: 'check-circle',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
          active: true
        },
        children: [
          // 假设检验
          {
            path: '/hypothesistest/hypothesis',
            name: 'hypothesisIndex',
            component: () => import('@/views/hypothesisTest/hypothesis'),
            beforeEnter: (to, from, next) => {
              var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
              if (keys && keys.length > 0) {
                next()
              } else {
                next({
                  path: '/hypothesistest/screendata'
                })
              }
            },
            meta: {
              title: '假设检验',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            },
            children: [{
              path: '/hypothesistest/hypothesis/:name',
              name: '假设检验样本',
              component: () => import('@/views/hypothesisTest/indexChild'),
              meta: {
                title: '假设检验',
                keepAlive: true,
                permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
                active: true
              }
            }]
          },
          {
            path: '/hypothesistest/result',
            name: 'hypothesisResult',
            component: () => import('@/views/hypothesisTest/result'),
            beforeEnter: (to, from, next) => {
              var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
              if (keys && keys.length > 0) {
                next()
              } else {
                next({
                  path: '/hypothesistest/screendata'
                })
              }
            },
            keepAlive: true,
            meta: {
              title: '假设检验',
              keepAlive: false,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person'],
              active: true
            }
          }

        ]
      },
      {
        path: '/analysisOfRegression',
        name: 'analysisOfRegression',
        component: () => import('@/views/analysisOfRegression'),
        hideChildrenInMenu: true,
        redirect: `/analysisOfRegression/analysisOfRegression/${select}`,
        meta: {
          title: '回归分析',
          icon: 'file-search',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        },
        children: [{
            path: '/analysisOfRegression/analysisOfRegression',
            name: 'analysisOfRegressionIndex',
            component: () => import('@/views/analysisOfRegression/analysisOfRegression'),
            beforeEnter: (to, from, next) => {
              var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
              if (keys && keys.length > 0) {
                next()
              } else {
                next({
                  path: '/analysisOfRegression/screendata'
                })
              }
            },
            meta: {
              title: '回归分析',
              keepAlive: true,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            },
            children: [{
              path: '/analysisOfRegression/analysisOfRegression/:name',
              name: '回归分析样本',
              component: () => import('@/views/analysisOfRegression/indexChild'),
              meta: {
                title: '回归分析',
                keepAlive: true,
                permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
              }
            }]
          },
          {
            path: '/analysisOfRegression/result',
            name: 'analysisOfRegressionResult',
            component: () => import('@/views/analysisOfRegression/result'),
            beforeEnter: (to, from, next) => {
              var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
              if (keys && keys.length > 0) {
                next()
              } else {
                next({
                  path: '/analysisOfRegression/screendata'
                })
              }
            },
            keepAlive: true,
            meta: {
              title: '回归分析',
              keepAlive: false,
              permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
            }
          }

        ]
      },
      {
        path: '/intelligenceReports',
        name: 'intelligenceReports',
        hidden: false, // 隐藏智能报告.
        component: () => import('@/views/report'),
        beforeEnter: (to, from, next) => {
          var keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
          if (keys && keys.length > 0) {
            next()
          } else {
            next({
              path: '/intelligenceReports/screendata'
            })
          }
        },
        hideChildrenInMenu: true,
        meta: {
          title: '智能报告',
          icon: 'file-text',
          keepAlive: true,
          permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
        }
      }
    ]
  },
  {
    path: '/question/Preview/:id?/:currentid?',
    name: 'questionPerview',
    component: () => import('@/views/question/Preview'),
    meta: {
      title: '量表(问卷)预览',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician']
    }
  },
  // {
  //   path: '/reportDetail/:id?',
  //   name: 'reportDetail',
  //   component: () => import('@/views/report/ReportShareDetail'),
  //   meta: {
  //     title: '智能报告详情',
  //     keepAlive: true,
  //     permission: ['admin', 'user', 'groupleader', 'statistician']
  //   }
  // },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
    meta: {
      active: true
    }
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
    path: '/article/:id?',
    name: 'article',
    component: () => import('@/views/article/index.vue'),
    meta: {
      active: true
    }
  },
  {
    path: '/person',
    component: PersonLayout,
    redirect: '/person/register',
    hidden: true,
    children: [{
        path: 'register',
        name: 'register',
        component: () => import( /* webpackChunkName: "user" */ '@/views/person/Register'),
        meta: {
          active: true
        }
      },
      {
        path: 'findPassword1',
        name: 'findPassword1',
        component: () => import( /* webpackChunkName: "user" */ '@/views/person/FindPassword1'),
        meta: {
          active: true
        }
      }
    ]
  },
  {
    path: '/personLogin',
    component: PersonLoginLayout,
    redirect: '/personLogin/login',
    hidden: true,
    children: [{
      path: 'login',
      name: 'personLogin',
      component: () => import('@/views/person/Login'),
      meta: {
        active: true
      }
    }]
  },
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [{
        path: 'login',
        name: 'login',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/Login'),
        meta: {
          active: true
        }
      },
      {
        path: 'register',
        name: 'register',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/Register'),
        meta: {
          active: true
        }
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
        meta: {
          active: true
        }
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },
  {
    path: '/questionnaire/:id?/:index?',
    name: 'questionnaireAnswer',
    component: () => import('@/views/question/AnswerModel'),
    meta: {
      title: '量表(问卷)',
      target: '_blank',
      meta: {
        active: true
      }
    }
  },
  {
    path: '/question/Print/:id?',
    name: 'questionPrint',
    component: () => import('@/views/question/Print'),
    meta: {
      title: '打印预览',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
    }
  },
  {
    path: '/experiment/Print',
    name: 'experimentPrint',
    component: () => import('@/views/experiment/Exp1/Detail/recordTable'),
    meta: {
      title: '打印预览',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician']
    }
  },
  {
    path: '/reference/Print',
    name: 'referencePrint',
    component: () => import('@/views/experiment/Exp1/Detail/referenceTable'),
    meta: {
      title: '打印预览',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician']
    }
  },
  {
    path: '/report/Print',
    name: 'reportPrint',
    component: () => import('@/views/report/report'),
    meta: {
      title: '智能报告',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
    }
  },
  {
    path: '/convention/Print',
    name: 'conventionPrint',
    component: () => import('@/views/experiment/Exp1/Detail/conventionTable'),
    meta: {
      title: '打印预览',
      keepAlive: true,
      permission: ['admin', 'user', 'groupleader', 'statistician', 'person']
    }
  },
  {
    path: '/result/:id?/:index?',
    name: 'result',
    component: () => import('@/views/question/Result'),
    meta: {
      title: '结果',
      active: true
    }
  },
  {
    path: '/warnning/:id/:qid?/:index?',
    name: 'warnning',
    component: () => import('@/views/question/Warnning'),
    meta: {
      title: '结果'
    }
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404'),
    meta: {
      active: true
    }
  }
]