var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "个人信息",
            visible: _vm.isNewAdd,
            "confirm-loading": _vm.confirmLoading
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "a-form",
                    {
                      attrs: {
                        form: _vm.form,
                        "label-col": { span: 5 },
                        "wrapper-col": { span: 19 },
                        labelAlign: "left"
                      },
                      on: { submit: _vm.handleSubmit }
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "姓名" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["name"],
                                expression: "['name']"
                              }
                            ],
                            attrs: { placeholder: "请输入姓名" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "编号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "serialNo",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "编号为必填项",
                                        whitespace: true
                                      },
                                      {
                                        pattern: "^.{4,}$",
                                        message: "请填写至少4位的编号"
                                      },
                                      { validator: _vm.onlySeriaNo }
                                    ]
                                  }
                                ],
                                expression:
                                  "['serialNo',{\n                rules:\n                  [\n                    { required: true, message: '编号为必填项', whitespace: true },\n                    { pattern: '^.{4,}$', message: '请填写至少4位的编号'},\n                    { validator: onlySeriaNo}\n                  ]\n              }]"
                              }
                            ],
                            attrs: { placeholder: "请输入编号" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["gender"],
                                  expression: "['gender']"
                                }
                              ]
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("男")
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("女")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "出生日期" } },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["birthDay"],
                                expression: "['birthDay']"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择出生日期" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "民族" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["minority", { initialValue: 0 }],
                                  expression: "['minority',{ initialValue: 0 }]"
                                }
                              ],
                              attrs: {
                                "show-search": "",
                                placeholder: "请选择民族"
                              }
                            },
                            _vm._l(_vm.minorities, function(minority) {
                              return _c(
                                "a-select-option",
                                {
                                  key: minority.id,
                                  attrs: { value: minority.id }
                                },
                                [_vm._v(_vm._s(minority.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "文化程度" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["education"],
                                  expression: "['education']"
                                }
                              ],
                              attrs: {
                                "show-search": "",
                                placeholder: "请选择文化程度"
                              }
                            },
                            _vm._l(_vm.educations, function(education) {
                              return _c(
                                "a-select-option",
                                {
                                  key: education.key,
                                  attrs: { value: education.key }
                                },
                                [_vm._v(_vm._s(education.value))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "手机号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["mobile"],
                                expression:
                                  "[\n                'mobile'\n              ]"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请输入手机号",
                              maxLength: 11
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }