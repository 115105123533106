<template>
  <a-row>
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px; border-right: 1px solid rgb(232, 232, 232)">
            <myTable :data="data" :columns="columns" :title="'卡方拟合性检验分析结果'"></myTable>
          </a-col>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'卡方拟合性检验智能分析结果'">
              <div>
                <div v-for="(item, index) in result" :key="index">
                  <span v-html="item"></span>
                </div>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
import { Tables } from '@/views/hypothesisTest/utils/formatTableClass'

export default {
  name: 'Fitting',
  created() {
    this.fetchResult()
  },
  data() {
    return {
      columns: [],
      method: this.$hypothesis.setColumns.chi_fitting,
      method2: Tables.kaFang,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [['']]
      }
    }
  },
  computed: {
    param_name() {
      const xys = this.part2.xys
      if (xys[0].length > 0) {
        const param = xys[0][0]
        let name
        if (typeof (param) === 'string') {
          name = param
        } else {
          name = param[param.length - 1]
        }
        return name
      }
      return ''
    },
    H0() {
      return [`${this.param_name}中频数观测值与频数预测值的比例没有差异`]
    },
    H1() {
      return [`${this.param_name}中频数观测值与频数预测值的比例有差异`]
    },
    result() { return [`1.${this.$hypothesis.conclusion(this.part2.p, 0.05, this.H0[0], this.H1[0])}`] }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['chisq', 'p'])
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.common()
    }
  }
}
</script>