var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.prefixCls, _vm.lastCls, _vm.blockCls, _vm.gridCls] },
    [
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "antd-pro-components-standard-form-row-index-label"
            },
            [_c("span", [_vm._v(_vm._s(_vm.title))])]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "antd-pro-components-standard-form-row-index-content" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }