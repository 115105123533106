import request from '@/utils/request'

const api = {
  documet: '/Document/getDocumets',
  get: '/Document',
  post: '/Document',
  put: '/Document'
}

export function getDocumetList(parameter) {
  return request({
    url: api.documet,
    method: 'post',
    data: parameter
  })
}

export function getDocument(parameter) {
  return request({
    url: api.get + '/' + parameter,
    method: 'get'
  })
}

export function createDocument(parameter) {
  return request({
    url: api.post,
    method: 'post',
    data: parameter
  })
}

export function updateDocument(parameter) {
  return request({
    url: api.put + '/' + parameter.id,
    method: 'put',
    data: parameter
  })
}
export function updateNameOrMobile(parameter) {
  return request({
    url: api.put + '/updateNameOrMobile/' + parameter.id,
    method: 'put',
    data: parameter
  })
}

export function getDocByNum(parameter) {
  return request({
    url: api.post + '/getDocByName',
    method: 'post',
    data: parameter
  })
}
