var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "third-module" }, [_vm._t("textFront")], 2),
    _c(
      "div",
      { staticClass: "third-module" },
      _vm._l(_vm.tables, function(table, index) {
        return _c(
          "div",
          { key: index },
          [
            table
              ? _c(
                  "a-table",
                  {
                    ref: "tables,2",
                    refInFor: true,
                    attrs: {
                      pagination: false,
                      dataSource: table.data,
                      columns: table.columns,
                      settings: table.settings,
                      size: "small"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.xys[0][0].slice(-1)[0]) +
                                      "和" +
                                      _vm._s(_vm.xys[1][0].slice(-1)[0]) +
                                      "的卡方检验 -- 样本数 (理论频数) "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  _vm._l(table.columns, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.key,
                        attrs: {
                          slot: "slotValue" in item ? item.slotValue : ""
                        },
                        slot: "slotValue" in item ? item.slotValue : ""
                      },
                      [
                        _c(
                          "div",
                          { domProps: { innerHTML: _vm._s(item.slotValue) } },
                          [_vm._v(_vm._s(item.slotValue))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("textBack")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }