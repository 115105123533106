<template>
  <div style="margin: 0 50px !important">
    <a-row type="flex">
      <a-col style="padding: 0 12px">
        <a-breadcrumb style="margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/experiment/Exp1/List/5">药效实验列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 新建药效实验 </a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
    </a-row>
    <a-form-model
      ref="ruleForm"
      :model="form"
      labelAlign="left"
      :label-col="{ md: { span: 4 }, xxl: { span: 3 } }"
      :wrapper-col="{ md: { span: 20 }, xxl: { span: 21 } }"
    >
      <a-form-model-item label="实验编号">
        <a-input :style="{ width: style.input_width }" placeholder="请输入记录编号" v-model="form.serialNum" />
      </a-form-model-item>
      <a-form-model-item label="实验名称" prop="expName" :rules="[{ required: true, message: `实验名称不能为空` }]">
        <a-input :style="{ width: style.input_width }" placeholder="请输入实验名称" v-model="form.expName" />
      </a-form-model-item>
      <a-form-model-item label="实验类型">
        <a-radio-group v-model="form.type">
          <a-radio :value="0"> 普通 </a-radio>
          <!-- <a-radio :value="1"> 特殊 </a-radio> -->
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="实验人员">
        <a-checkbox-group
          :value="JSON.parse(form.expUsers)"
          @change="(value) => (form.expUsers = JSON.stringify(value))"
        >
          <a-checkbox v-for="user in res.users" :key="user.id" :value="user.id"> {{ user.userName }} </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <!-- 分组 -->
      <div
        :style="{
          borderTop: index === 0 ? '1px solid rgb(217,217,217)' : 'none',
          borderBottom: '1px solid rgb(217,217,217)',
        }"
        v-for="(group, index) in form.groups"
        :key="index + 'group'"
      >
        <a-form-model-item
          :label="`分组${index + 1}`"
          :prop="`groups[${index}].value`"
          :rules="[{ required: true, message: `分组${index + 1}不能为空` }]"
        >
          <a-input v-model="group.value" :style="{ width: style.input_width }" placeholder="请输入分组" />
          <PlusAndMinus
            :index="index"
            :array="form.groups"
            :obj="{
              value: '',
              animals: [
                {
                  gender: undefined,
                  num: undefined,
                },
              ],
            }"
          ></PlusAndMinus>
        </a-form-model-item>
        <a-form-model-item
          v-for="(animal, i) in group.animals"
          class="myRequired"
          :key="i"
          :label="`动物性别${i + 1}`"
          :prop="`groups[${index}].animals[${i}].gender`"
          :rules="{
            validator: (rule, value, callback) => {
              validator(rule, value, callback, {
                valueList: [value, animal.num],
                checkMsg: `动物性别${index + 1}及其动物数`,
              })
            },
          }"
        >
          <a-input-group compact :style="{ width: style.input_width }">
            <a-input style="width: 50%" placeholder="请输入动物性别" v-model="animal.gender" />
            <a-input style="width: 50%" suffix="只" placeholder="请输入所需动物数" v-model="animal.num" />
          </a-input-group>
          <PlusAndMinus
            :index="i"
            :array="group.animals"
            :obj="{
              gender: undefined,
              num: undefined,
            }"
          ></PlusAndMinus>
        </a-form-model-item>
      </div>
      <!-- <a-form-model-item label="每组所需动物数" prop="animalSize">
        <a-input
          v-model="form.animalSize"
          :style="{ width: style.input_width }"
          suffix="只"
          placeholder="请输入每组所需动物数"
        />
      </a-form-model-item> -->
      <!-- <a-form-model-item
        v-for="(gender, index) in form.genders"
        :key="index + 'gender'"
        label="动物性别"
        :prop="`genders[${index}].value`"
        :rules="[{ required: true, message: `动物性别不能为空` }]"
      >
        <a-input :style="{ width: style.input_width }" placeholder="请输入动物性别" v-model="gender.value" />
        <span v-if="form.genders.length !== 1" style="margin-left: 10px; cursor: pointer" @click="deleteGender(index)">
          <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
        <span v-if="index === form.genders.length - 1" style="margin-left: 10px; cursor: pointer" @click="addGender">
          <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
      </a-form-model-item> -->
      <a-form-model-item label="实验天数" prop="expDays">
        <a-input
          v-model="form.expDays"
          :style="{ width: style.input_width }"
          suffix="天"
          placeholder="请输入实验天数"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="form.expDays && !isNaN(Number(form.expDays))"
        label="哪些天记录数据"
        prop="recordedDataDays"
      >
        <a-select
          mode="multiple"
          v-model="form.recordedDataDays"
          :style="{ width: style.input_width }"
          style="width: 100%"
          placeholder="请选择在哪几天记录数据"
        >
          <a-select-option v-for="i in Number(form.expDays)" :key="i"> 第{{ i }}天 </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item class="myRequired" label="测量值名称" prop="valueName">
        <a-input-group compact :style="{ width: style.input_width }">
          <a-input style="width: 70%" placeholder="请输入测量值名称" v-model="form.valueName" />
          <a-input style="width: 30%" placeholder="单位" v-model="form.unit" />
        </a-input-group>
      </a-form-model-item> -->
      <!-- 额外药物名称 -->
      <!-- <a-form-model-item
        v-for="(item, index) in form.extraMedcineNameAndUnit"
        :key="`extraMedcineNameAndUnit${index}`"
        :label="`额外药物名称${index + 1}`"
        :prop="`extraMedcineNameAndUnit[${index}].name`"
      >
        <a-input-group compact :style="{ width: style.input_width }">
          <a-input style="width: 70%" placeholder="请输入额外药物名称" v-model="item.name" />
          <a-input style="width: 30%" placeholder="单位" v-model="item.unit" />
        </a-input-group>
        <span
          v-if="form.extraMedcineNameAndUnit.length !== 1"
          style="margin-left: 10px; cursor: pointer"
          @click="deleteExtraMedcineNameAndUnit(index)"
        >
          <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
        <span
          v-if="index === form.extraMedcineNameAndUnit.length - 1"
          style="margin-left: 10px; cursor: pointer"
          @click="addExtraMedcineNameAndUnit"
        >
          <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
        </span>
      </a-form-model-item> -->
      <!-- 最终测量值名称 -->
      <a-form-model-item
        v-for="(item, index) in form.periodValues"
        :key="`periodValues${index}`"
        class="myRequired"
        :rules="{
          validator: (rule, value, callback) => {
            validator(rule, value, callback, {
              valueList: [value, item.unit],
              checkMsg: `周期性测量值${index + 1}及其单位`,
            })
          },
        }"
        :label="`周期性测量值名称${index + 1}`"
        :prop="`periodValues[${index}].name`"
      >
        <a-input-group compact :style="{ width: style.input_width }">
          <a-input style="width: 70%" placeholder="请输入周期性测量值名称" v-model="item.name" />
          <a-input style="width: 30%" placeholder="单位" v-model="item.unit" />
        </a-input-group>
        <PlusAndMinus
          :index="index"
          :array="form.periodValues"
          :obj="{ name: undefined, unit: undefined }"
        ></PlusAndMinus>
      </a-form-model-item>
      <a-form-model-item
        v-for="(item, index) in form.finalValues"
        :key="index"
        :label="`最终测量值名称${index + 1}`"
        :prop="`finalValues[${index}].name`"
        :rules="{
          validator: (rule, value, callback) => {
            validator2(rule, value, callback, {
              valueList: Object.values(item),
              checkMsg: `名称及其单位不能为空`,
            })
          },
        }"
      >
        <a-input-group compact :style="{ width: style.input_width }">
          <a-input style="width: 70%" placeholder="请输入最终测量值名称" v-model="item.name" />
          <a-input style="width: 30%" placeholder="单位" v-model="item.unit" />
        </a-input-group>
        <PlusAndMinus
          :index="index"
          :array="form.finalValues"
          :obj="{ name: undefined, unit: undefined }"
        ></PlusAndMinus>
      </a-form-model-item>
      <a-form-model-item label="可选参数">
        <div>
          <span style="margin-right: 10px" v-for="(param, index) in params" :key="index">
            <a-tag v-if="param.name"> {{ param.name }}：{{ param.value }} </a-tag>
          </span>
        </div>
      </a-form-model-item>
      <!-- 需要计算的参数值 -->
      <!-- <a-row>
        <a-col :span=""></a-col>
      </a-row> -->
      <a-form-model-item
        v-for="(item, index) in form.yName"
        :key="`yName${index}`"
        :label="`计算属性名称${index + 1}`"
        :prop="`yName[${index}].name`"
        :rules="{
          validator: (rule, value, callback) => {
            validator2(rule, value, callback, {
              valueList: Object.values(item),
              checkMsg: `名称、单位及其公式不能为空`,
            })
          },
        }"
      >
        <a-input-group compact :style="{ width: '80%' }">
          <a-input style="width: 42.5%" placeholder="请输入计算属性" v-model="item.name" />
          <a-input style="width: 15%" placeholder="单位" v-model="item.unit" />
          <a-input style="width: 42.5%" placeholder="请输入公式" v-model="item.formula" />
        </a-input-group>
        <PlusAndMinus
          :index="index"
          :array="form.yName"
          :obj="{ name: undefined, unit: undefined, formula: undefined }"
        ></PlusAndMinus>
      </a-form-model-item>
      <!-- <div v-if="form.type === 1">
        <a-form-model-item
          v-for="(item, index) in form.timeLineValues"
          :key="'time' + index"
          :colon="index === 0"
          class="myRequired"
          :rules="
            index === 0
              ? {
                validator: (rule, value, callback) => {
                  if (!(value && form.timeLineUnit)) callback(`时间${index + 1}及其单位不能为空`)
                  callback()
                },
              }
              : [{ required: true, message: '时间不能为空' }]
          "
          :label="index === 0 ? `时间` : ' '"
          :prop="`timeLineValues[${index}].value`"
        >
          <a-input-group v-if="index === 0" compact :style="{ width: style.input_width }">
            <a-input style="width: 70%" placeholder="请输入时间" v-model="item.value" />
            <a-input style="width: 30%" placeholder="单位" v-model="form.timeLineUnit" />
          </a-input-group>
          <a-input v-else :style="{ width: style.input_width }" placeholder="请输入时间" v-model="item.value" />
          <span
            v-if="form.timeLineValues.length !== 1"
            style="margin-left: 10px; cursor: pointer"
            @click="deleteTimeLineValues(index)"
          >
            <a-icon type="minus-circle" theme="twoTone" twoToneColor="#1890FF" />
          </span>
          <span
            v-if="index === form.timeLineValues.length - 1"
            style="margin-left: 10px; cursor: pointer"
            @click="addTimeLineValues"
          >
            <a-icon type="plus-circle" theme="twoTone" twoToneColor="#1890FF" />
          </span>
        </a-form-model-item>
      </div> -->
      <div
        :style="{
          borderTop: index === 0 ? '1px solid rgb(217,217,217)' : 'none',
          borderBottom: '1px solid rgb(217,217,217)',
        }"
        v-for="(obj, index) in form.groupCompute"
        :key="`组运算${index}`"
      >
        <a-form-model-item
          label="组运算参数"
          :rules="{
            validator: (rule, value, callback) => {
              validator3(rule, value, callback, {
                valueList: [obj.name, obj.unit, obj.formula],
                checkValueList: [obj.name, obj.unit],
                checkMsg: `名称及其单位不能为空`,
              })
            },
          }"
          :prop="`groupCompute[${index}].name`"
        >
          <a-input-group compact :style="{ width: style.input_width }">
            <a-input style="width: 70%" placeholder="请输入参数名" v-model="obj.name" />
            <a-input style="width: 30%" placeholder="单位" v-model="obj.unit" />
          </a-input-group>
          <PlusAndMinus
            :index="index"
            :array="form.groupCompute"
            :obj="{
              type: '公式',
              keys: [{ value: undefined }, { value: undefined }],
              order: undefined,
              formula: undefined,
              map: undefined,
              name: undefined,
              unit: undefined,
              isShow: true,
            }"
          ></PlusAndMinus>
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="obj.type" :style="{ width: style.input_width }">
            <a-select-option key="公式" value="公式">公式</a-select-option>
            <a-select-option key="平均值" value="平均值">平均值</a-select-option>
          </a-select>
        </a-form-model-item>
        <div v-if="obj.type === '公式'">
          <a-form-model-item label="可选参数">
            <span style="margin-right: 10px" v-for="(param, paramIndex) in groupParams" :key="paramIndex">
              <a-tag v-if="param.name"> {{ param.name }}：{{ param.value }} </a-tag>
            </span>
          </a-form-model-item>
          <a-form-model-item
            label="计算公式"
            :rules="{
              validator: (rule, value, callback) => {
                validator3(rule, value, callback, {
                  valueList: [obj.name, obj.unit, obj.formula],
                  checkValueList: [obj.formula],
                  checkMsg: `计算公式不能为空`,
                })
              },
            }"
            :prop="`groupCompute[${index}].formula`"
          >
            <a-input :style="{ width: style.input_width }" placeholder="请输入计算公式" v-model="obj.formula" />
          </a-form-model-item>
        </div>
        <div v-else-if="obj.type === '平均值'">
          <a-form-model-item label="关键词组">
            <a-input-group compact :style="{ width: style.input_width }">
              <a-select style="width: 50%" v-model="obj.keys[0].value" placeholder="请选择组别名">
                <a-select-option
                  v-for="(groupName, groupIndex) in keysSelectOptions.组别名"
                  :key="groupIndex"
                  :value="groupName"
                >{{ groupName }}</a-select-option
                >
              </a-select>
              <a-select style="width: 50%" v-model="obj.keys[1].value" placeholder="请选择运算参数名">
                <a-select-option
                  v-for="(paramName, paramIndex) in keysSelectOptions.运算参数名"
                  :key="paramIndex"
                  :value="paramName"
                >{{ paramName }}</a-select-option
                >
              </a-select>
            </a-input-group>
          </a-form-model-item>
        </div>
        <!-- <a-form-model-item label="顺序">
          <a-input-number
            :style="{ width: style.input_width }"
            v-model="obj.order"
            placeholder="请输入参数顺序"
          ></a-input-number>
        </a-form-model-item> -->
        <a-form-model-item label="显示在页面">
          <a-radio-group v-model="obj.isShow">
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>

      <a-form-model-item label="说明">
        <a-textarea
          v-model="form.explain"
          style="width: 50%"
          placeholder="请输入说明"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
    </a-form-model>
    <a-button type="primary" @click="handleOk"> 提交 </a-button>
  </div>
</template>
<script>
import Item from './item'
import { getUsers } from '@/api/user'
import { postExperiment2 } from '@/api/exp2/experiment'
import MyInputNumber from '@/components/myComponents/myInputNumber'
import PlusAndMinus from '@/components/myComponents/plusAndMinus'
export default {
  name: 'NewExp',
  components: { Item, MyInputNumber, PlusAndMinus },
  created() {
    this.fetchUsers()
  },
  data() {
    return {
      res: {
        users: []
      },
      form: {
        test: 0,
        serialNum: '',
        expName: '',
        type: 0,
        groups: [{
          value: '',
          animals: [
            {
              gender: undefined,
              num: undefined
            }
          ]
        }],
        animalSize: undefined,
        genders: [{ value: undefined }],
        expUsers: JSON.stringify([this.$store.state.user.info.id]),
        expDays: undefined,
        recordedDataDays: undefined, // 这个是选择需要在第几天记录数据的
        valueName: undefined, // 测量值名称
        unit: undefined,
        explain: '',
        extraMedcineNameAndUnit: [{ name: undefined, unit: undefined }],
        periodValues: [{ name: undefined, unit: undefined }],
        finalValues: [{ name: undefined, unit: undefined }],
        yName: [{ name: undefined, unit: undefined, formula: undefined }],
        timeLineValues: [{ value: undefined }],
        timeLineUnit: '',
        expTypeid: Number(this.$route.params.id),
        orgID: this.$store.state.user.info.orgid,
        groupCompute: [{
          type: '公式',
          keys: [{ value: undefined }, { value: undefined }],
          order: undefined,
          formula: undefined,
          map: undefined,
          name: undefined,
          unit: undefined,
          isShow: true
        }]
      },
      style: {
        input_width: '40%'
      }
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const form = this.$deepClone(this.form)
          // 组计算值如果没有填写，那么把它的值清空。
          form.groupCompute = form.groupCompute.filter(v => v.name && v.unit && v.formula)
          // 点击提交后，处理要提交到Exp2ParamDB的数据的结构。
          this.handleExp2ParamDBReq(form.groupCompute)
          // 这个list是用来存到animalDB里的finalValues的,类似['','']
          form.finalValues = form.finalValues.filter(v => v.name && v.unit)
          const list = form.finalValues.map(v => '')
          // 这个list2是用来存到animalDB里的yValues的,类似['','']
          form.yName = form.yName.filter(v => v.name && v.unit && v.formula)
          const list2 = form.yName.map(v => '')
          // 这个list3是用来存到animalDB里的periodValues的,类似['','']
          const list3 = form.periodValues.map(v => '')
          // 这个list2是用来存到animalDataDB里的extraMedcineNameAndUnitValue的,类似['','']
          // const list2 = form.extraMedcineNameAndUnit.map(v => '')
          // 把expDB里的finalValues序列化一下
          const finalValues = JSON.stringify(list)
          form.finalValues = JSON.stringify(form.finalValues)
          // 把expDB里的yName序列化一下
          const yValues = JSON.stringify(list2)
          form.yName.forEach(v => {
            v.params = this.params
          })
          form.yName = JSON.stringify(form.yName)
          // 把expDB里的periodValues序列化一下
          const periodValues = JSON.stringify(list3)
          form.periodValues = JSON.stringify(form.periodValues)
          // 把expDB里的extraMedcineValue序列化一下
          // const extraMedcineValue = JSON.stringify(list2)
          form.extraMedcineNameAndUnit = JSON.stringify(form.extraMedcineNameAndUnit)
          // 把时间线格式生成一下
          form.timeLineValues = JSON.stringify(form.timeLineValues.map(v => v.value))
          form.groupGenders = []
          form.groups.forEach(v => {
            const obj = {
              group: v.value,
              animals: []
            }
            v.animals.forEach(m => {
              let i = 0
              while (i < m.num) {
                const obj2 = {
                  finalValues,
                  yValues,
                  gender: m.gender,
                  animalDatas: []
                }
                form.recordedDataDays.forEach(o => {
                  const obj3 = {
                    recordedDataDay: o,
                    value: null,
                    // extraMedcineValue,
                    periodValues
                  }
                  obj2.animalDatas.push(obj3)
                })
                obj.animals.push(obj2)
                i++
              }
            })
            form.groupGenders.push(obj)
          })
          console.log('%c 🍡 form: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', form)
          postExperiment2(form).then(res => {
            this.$message.success('新建成功')
            this.$router.push(`/experiment/Exp2/${Number(this.$route.params.id)}`)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 接口：获取当前机构的所有人
    fetchUsers() {
      getUsers({ orgid: this.$store.state.user.info.orgid }).then(res => {
        this.res.users = res.data
      })
    },
    // 点击提交后，处理要提交到Exp2ParamDB的数据的结构。
    handleExp2ParamDBReq(groupCompute) {
      groupCompute.forEach(v => {
        // 格式化映射
        v.map = JSON.stringify(this.groupParams)
        // 格式化关键字
        v.keys = JSON.stringify(v.keys.map(v => v.value))
      })
    },
    validator(rule, value, callback, obj) {
      const { valueList, checkMsg } = obj
      if (valueList.every(v => v)) callback()
      callback(`${checkMsg}不能为空`)
    },
    validator2(rule, value, callback, obj) {
      const { valueList, checkMsg } = obj
      if (valueList.some(v => v)) {
        if (valueList.every(v => v)) {
          callback()
        } else {
          callback(checkMsg)
        }
      } else {
        callback()
      }
    },
    validator3(rule, value, callback, obj) {
      const { valueList, checkValueList, checkMsg } = obj
      if (valueList.some(v => v)) {
        if (checkValueList.every(v => v)) {
          callback()
        } else {
          callback(checkMsg)
        }
      } else {
        callback()
      }
    }
  },
  computed: {
    params() {
      const paramsList = ['a', 'b', 'c', 'd', 'f', 'g']
      const list = this.form.finalValues.filter(v => v.name && v.unit).concat(this.form.yName.filter(v => v.name && v.unit && v.formula))
      const result = list.map((v, index) => ({ name: `${v.name}(${v.unit})`, value: paramsList[index] }))
      return result
    },
    groupParams() {
      const paramsList = ['a', 'b', 'c', 'd', 'f', 'g']
      const result = this.form.groupCompute.filter(v => v.name && v.unit).map((v, index) => ({ name: `${v.name}(${v.unit})`, value: paramsList[index] }))
      return result
    },
    keysSelectOptions() {
      return {
        组别名: this.form.groups.map(v => v.value),
        运算参数名: this.form.yName.map(v => `${v.name}(${v.unit})`)
      }
    }
  }
}
</script>
<style lang="less">
.myRequired label:before {
  margin-right: 4px;
  content: '*';
  color: #f5222d;
  font-size: 14px;
}
.ant-form-item {
  margin: 12px;
}
</style>>