<template>
  <div>
    <page-header-wrapper :breadcrumb="null">
      <div style="height: auto !important;">
        <a-anchor :target-offset="targetOffset" style="position: fixed;right: 10px;width:210px" >
          <a-anchor-link href="#overview" title="概述"></a-anchor-link>
          <a-anchor-link href="#demographyinfo" title="人口学信息">
            <a-anchor-link href="#age" title="年龄"></a-anchor-link>
            <a-anchor-link href="#sex" title="性别"></a-anchor-link>
            <a-anchor-link href="#nation" title="民族"></a-anchor-link>
            <a-anchor-link href="#marry" title="婚姻状况"></a-anchor-link>
          </a-anchor-link>
          <a-anchor-link href="#lifestyle" title="生活方式和社会因素">
            <a-anchor-link href="#diet" title="饮食习惯"></a-anchor-link>
            <a-anchor-link href="#exercise" title="经常参与体育锻炼"></a-anchor-link>
            <a-anchor-link href="#smoke" title="吸烟和被动吸烟"></a-anchor-link>
            <a-anchor-link href="#wine" title="经常饮酒"></a-anchor-link>
            <a-anchor-link href="#stayUpLate" title="经常熬夜"></a-anchor-link>
            <a-anchor-link href="#pressure" title="面临生活或工作方面的压力"></a-anchor-link>
          </a-anchor-link>
          <!-- <a-anchor-link href="#pearson" title="相关性"></a-anchor-link> -->
        </a-anchor>
        <a-layout class="divContent" style="margin-right:210px">
          <div >
            <span >当前样本数：</span>
            <span class="htCommonColor">{{ data.count }}</span>
          </div>
          <over-view :data="data" class="itemMarginCommon" id="overview"></over-view>
          <demography-info :datapro="data" class="itemMarginCommon" id="demographyinfo">></demography-info>
          <life-style :data="data" class="itemMarginCommon" id="lifestyle">></life-style>
          <!-- <pearson :data="pearsondata" class="itemMarginCommon" id="pearson"></pearson> -->
        </a-layout>

      </div>
    </page-header-wrapper>
  </div>

</template>
<script>
import demographyInfo from './demographyInfo'
import overView from './overView'
import lifeStyle from './lifeStyle'
import pearson from './pearson'
import { getData } from '@/api/commonChart'

export default {
  components: { demographyInfo, overView, lifeStyle, pearson },
  data() {
    return {
      data: {},
      pearsondata: {},
      targetOffset: undefined
    }
  },
  created() {
    getData({ orgid: this.$store.state.user.info.orgid }).then(res => {
      this.data = res.data
      this.pearsondata = {
        pearsonData: res.pearsonData,
        fields: res.fields
      }
    })
  },
  mounted() {
    this.targetOffset = 70
  }
}
</script>

<style lang="less" scoped>
.divContent {
  padding: 30px;
}
</style>

<style lang="less" >
.itemWrapperCommon {
  border: 1px solid rgb(232, 232, 232);
}
.itemWrapperCommonPadding40{

  padding: 40px;
}
.itemWrapperCommonPaddingLeft{

  padding:  0 40px 20px 40px;
}
.itemMarginCommon{
  margin-top: 20px;
}
.hCommon{
  font-size: 23px;
}
.htCommon{
  font-size: 18px;
}
.htCommonColor{
  color:#158bef;
}
.httCommon{
  font-size: 13px;
  font-weight: bold;
  padding-left: 5px;
}
.ttCommon{
  font-size: 13px;
  padding-left: 5px;
}
.dividerCommon{
  margin: 10px 0 !important;
}
body{
  overflow: auto !important;
}
</style>