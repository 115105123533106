<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index">
        <a-table
          ref="tables,2"
          v-if="table"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          :settings="table.settings"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
          <!-- <template #title>
            <div style="text-align: center">不同{{ lei }}对{{ Object.keys(data.data1)[index] }}的非参数检验</div>
          </template> -->
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'
export function get_text_noneParam(name_of_sample, flow_value, is_position, secondIndex, thirdIndex) {
  /** 获取非参数检验中文字的描述
   * @param name_of_sample 样本的名字，例如“不同文化程度中年龄的样本”
   * @param flow_value 流程的值
   * @param is_position 是否是位置分析
   */
  const index_of_norm = `3.${secondIndex}.${thirdIndex.normIndex}`
  // 方差分析中的文字描述
  const text_auto = `由${index_of_norm}可知：${name_of_sample}不服从正态分布，因此采用Kruskal-WallisH算法进行非参数检验，`
  const text_none_param = `采用Kruskal-WallisH算法进行非参数检验，`
  const text_end = `<br/><span class="H0H1">H0：${name_of_sample}间差异不显著。</span><br/><span class="H0H1">H1：${name_of_sample}间差异显著。</span><br>检验结果如下表所示：`
  // 均值分析中的文字描述
  const text_auto_position = `由${index_of_norm}可知：${name_of_sample}不服从正态分布，因此采用Mann-Whitney U算法进行非参数检验，`
  const text_none_param_position = `采用Mann-Whitney U算法进行非参数检验，`
  let text
  if (flow_value === 'auto') {
    text = is_position ? text_auto_position : text_auto
  } else {
    text = is_position ? text_none_param_position : text_none_param
  }
  return text + text_end
}
export default {
  props: ['data', 'lei'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'NoneParam',
      data: this.data,
      lei: this.lei
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '非参数检验',
          msg: res
        })
      } else {
        const tables = res.tables
        let numOfOptions
        if (this.lei === '变量') {
          numOfOptions = 2
        } else {
          numOfOptions = Object.keys(Object.values(this.data.data1)[0]).length
        }
        console.log('%c 🍛 numOfOptions: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', numOfOptions);
        const ary = []
        tables.forEach(table => {
          if (table) {
            table.columns.forEach((column, i) => {
              if (this.isShouldToCombine(numOfOptions, i)) {
                column.customRender = (text, record, index) => {
                  const obj = {
                    children: typeof (text) === 'string' ? text : this.$options.filters.round(text),
                    attrs: {}
                  }
                  if (index === 0) {
                    obj.attrs.rowSpan = table.data.length
                  } else {
                    obj.attrs.rowSpan = 0
                  }
                  return obj
                }
              }
            })
            ary.push(table.data[0].p)
          } else {
            ary.push(null)
          }
        })
        this.$emit('noneParamIsDiff', ary)
        this.getMergeSetting(tables)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  methods: {
    // 判断是否需要合并表格中的格子
    isShouldToCombine(numOfOptions, i) {
      // 如果当前遍历的列号在数组中，说明是需要合并的返回true
      if (numOfOptions > 2) return [0, 3, 4].indexOf(i) !== -1
      if (numOfOptions === 2) return [0, 4, 5, 6].indexOf(i) !== -1
    },
    findLessP(tables) {
      const { a } = this
      const result = []
      tables.forEach((table, index) => {
        if (table) {
          const list = []
          table.data.forEach(dataObj => {
            if (dataObj.pval < a) {
              const A = dataObj.A
              const i = list.findIndex(v => v.A === A)
              if (i === -1) {
                list.push({
                  A, B: dataObj.B
                })
              } else {
                list[i].B.concat(`、${dataObj.B}`)
              }
            }
          })
          result.push(list)
        }
      })
      return result
    },
    getMergeSetting(tables) {
      const table_row_num = tables[0].data.length
      let columnAry
      if (table_row_num > 2) {
        tables[0].settings = [[], [], []]
        columnAry = [0, 3, 4]
      } else {
        tables[0].settings = [[], [], [], []]
        columnAry = [0, 4, 5, 6]
      }
      columnAry.forEach((v, i) => {
        for (let index = 1; index <= table_row_num; index++) {
          tables[0].settings[i].push([index, v])
        }
      })
    }
  },
  computed: {
    a() { return this.$store.state.report.a }
  }
}
</script>