<template>
  <a-row id="verification" type="flex" justify="space-between" align="top" :size="inputSize">
    <a-col :span="15">
      <a-form-model-item prop="verificationCode">
        <a-input v-model="form.verificationCode" placeholder="请输入验证码" :size="inputSize">
          <a-icon slot="prefix" type="mail" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
    </a-col>
    <a-col :span="8" style="height: 40px">
      <a-button
        style="height: 100%; color: #1890ff; font-size: 12px; border-radius: 2px"
        :disabled="verifyButtonDisabled"
        @click="clickVerifyButton"
        block
      >{{ verifyButtonText2 }}</a-button
      >
    </a-col>
  </a-row>
</template>
<script>
export default {
  /**
   * @param form 验证码所在的form表单
   * @param inputSize input的大小，值为ant-design-vue里的input大小值
   */
  props: ['form', 'inputSize'],
  data() {
    return {
      verifyButtonDisabled: false,
      verifyButtonText: '获取验证码'
    }
  },
  methods: {
    clickVerifyButton() {
      /**
       * 点击获取验证码按钮
       */
      this.verifyButtonDisabled = true
      this.verifyButtonText = 60
      const interval = setInterval(() => {
        if (this.verifyButtonText === 0) {
          window.clearInterval(interval)
          this.verifyButtonDisabled = false
          this.verifyButtonText = '获取验证码'
        } else {
          this.verifyButtonText--
        }
      }, 1000)
    }
  },
  computed: {
    verifyButtonText2() {
      const { verifyButtonText } = this
      if (verifyButtonText !== '获取验证码') {
        return `${verifyButtonText}s`
      }
      return verifyButtonText
    }
  }
}
</script>