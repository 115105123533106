<template>
  <div>
    <h1 class="hCommon">生活方式及社会因素</h1>
    <div class="itemWrapperCommon">
      <div id="diet" class="itemWrapperCommonPaddingLeft" style="margin-top:20px">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor" >饮食习惯</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">嗜甜食</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.dietsweetPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">嗜咸食</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.dietsaltyPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">嗜高脂</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.diethighfatPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">其他</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.dietotherPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.dietlostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.dietlostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-histogram
                  :data="dietChart"
                  height="200px"
                  width="250px"
                  :legend-visible="false"
                  :extend="histogramExtend"
                  style="float:right"></ve-histogram>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div id="exercise" class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">经常参与体育锻炼</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">是</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.exerciseyesPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">否</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.exercisenoPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.exerciselostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.exerciselostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="exerciseChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="chartExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div id="smoke" class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">吸烟或被动吸烟</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">是</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.smokeyesPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">否</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.smokenoPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.smokelostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.smokelostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="smokeChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="chartExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div id="wine" class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">经常饮酒</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">是</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.wineyesPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">否</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.winenoPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.winelostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.winelostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="wineChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="chartExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div id="stayUpLate" class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">经常熬夜</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">是</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.stayUpLateyesPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">否</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.stayUpLatenoPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.stayUpLatelostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.stayUpLatelostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="stayUpLateChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="chartExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <divider-common></divider-common>
      <div id="pressure" class="itemWrapperCommonPaddingLeft">
        <a-row>
          <a-col span="11" >
            <a-row>
              <a-col span="12">
                <span class="htCommon htCommonColor">面临生活或工作方面的压力</span>
              </a-col>
              <a-col span="12">

                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">是</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.pressureyesPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">否</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.pressurenoPercent }}%</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.pressurelostCount }}</span>
                  </a-col>
                </a-row>
                <divider-common></divider-common>
                <a-row>
                  <a-col span="12">
                    <span class="httCommon">缺失%</span>
                  </a-col>
                  <a-col span="12">
                    <span class="ttCommon">{{ data.pressurelostPercent }}%</span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="11" :offset="2">
            <a-row>
              <a-col span="12" :offset="10">
                <ve-bar
                  :data="pressureChart"
                  width="250px"
                  style="float:right"
                  height="180px"
                  :legend-visible="false"
                  :extend="chartExtend"></ve-bar>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import DividerCommon from './DividerCommon'
export default {
    components: { DividerCommon },
     props: {
        data: {
          type: Object,
          default: () => {}
        }
    },
     data() {
      console.log('props', this.datapro)
      return {
          pressureChart: { columns: [], rows: [] },
          stayUpLateChart: { columns: [], rows: [] },
          wineChart: { columns: [], rows: [] },
          smokeChart: { columns: [], rows: [] },
          exerciseChart: { columns: [], rows: [] },
          dietChart: { columns: [], rows: [] },
          histogramExtend: {
            tooltip: {
              axisPointer: {
                type: 'shadow'
              }
            },
            color: ['#3a77b6'],
            series: {
              type: 'bar',
              barWidth: 15,
              label: {
                show: true,
                position: 'top'
              }
            },
            yAxis: {
              show: false
            },
            xAxis: {
              axisLine: {
                show: true
              }
            }
          },
          chartExtend: {
            color: ['#3a77b6'],
            tooltip: {
              axisPointer: {
                type: 'shadow'
              }
            },
            series: {
              type: 'bar',
              label: {
                show: true,
                position: 'inside'
              }
            },
            xAxis: {
              show: false
            }
          }
      }
    },
    watch: {
      data(val, oldval) {
        this.pressureChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '否', '人数': val.pressureno },
            { '类型': '是', '人数': val.pressureyes }
            ]
        }
        this.stayUpLateChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '否', '人数': val.stayUpLateno },
            { '类型': '是', '人数': val.stayUpLateyes }
            ]
        }
        this.wineChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '否', '人数': val.wineno },
            { '类型': '是', '人数': val.wineyes }
            ]
        }
        this.smokeChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '否', '人数': val.smokeno },
            { '类型': '是', '人数': val.smokeyes }
            ]
        }
        this.exerciseChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '否', '人数': val.exerciseno },
            { '类型': '是', '人数': val.exerciseyes }
            ]
        }
        this.dietChart = {
          columns: ['类型', '人数'],
          rows: [
            { '类型': '嗜甜食', '人数': val.dietsweet },
            { '类型': '嗜咸食', '人数': val.dietsalty },
            { '类型': '嗜高脂', '人数': val.diethighfat },
            { '类型': '其他', '人数': val.dietother }
            ]
        }
      }
    }

}
</script>