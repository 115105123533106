<template>
  <a-modal
    width="800px"
    :maskClosable="false"
    :visible="visible" title="图表信息设置"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }">
    <a-form-model ref="ruleForm" :model="data" v-bind="formLayout">
      <a-form-model-item label="语言">
        <a-radio-group v-model="data.language">
          <a-radio :key="0" :value="0">CN 简体中文</a-radio>
          <a-radio :key="1" :value="1">US English</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="颜色">
        <colorPicker v-for="(item,i) in data.colorsArry" :key="i" v-model="data.colorsArry[i]"></colorPicker>
        <a-button @click="setDefault">恢复默认</a-button>
      </a-form-model-item>
      <a-form-model-item label="图片下载格式">
        <a-radio-group v-model="data.picformat">
          <a-radio :key="0" value="png">PNG (.png)</a-radio>
          <a-radio :key="1" value="jpeg">JPEG (.jpg)</a-radio>
          <a-radio :key="2" value="tiff">TIFF (.tif)</a-radio>
          <a-radio :key="3" value="bmp">BMP (.bmp/.dib)</a-radio>
          <a-radio :key="4" value="gif">GIF (.gif)</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

  </a-modal>
</template>
<script>
export default {
  name: 'Settings',
  props: ['visible', 'data'],
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      settings: []
    };
  },
  created() {
    console.log('aa', this.data)
  },
  methods: {
    setDefault() {
      var moren = ['#5f90e3', '#e5896b', '#81d385', '#e6cd75', '#a990da', '#6bc3e1', '#e5acaf', '#a7c23b', '#ddb06e', '#808693', '#adbfc3', '#41e7ea']
      this.data.colorsArry = moren
    }

  },
  computed: {

  }
}
</script>
<style lang="less" >
 .colorBtn{
  width:50px !important
}
</style>