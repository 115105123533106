<template>
  <a-row style="padding: 0 15%; height: 100%" type="flex" align="middle" justify="space-between">
    <a-col :span="12" style="position: relative; bottom: 50px; line-height: 60px">
      <div style="font-size: 25px; color: #61d0fe">为特定数据提供</div>
      <div style="font-size: 40px; color: white">收集、展示、分析、处理的<br />专业平台</div>
    </a-col>
    <a-col :span="9" style="height: 100%">
      <div id="loginContainer">
        <a-row>
          <a-col
            class="changeButton psdLogin"
            :class="{ active: currentActive === 'psdLogin' }"
            @click="currentActive = 'psdLogin'"
            :span="12"
          >密码登录</a-col
          >
          <a-col
            class="changeButton msgLogin"
            :class="{ active: currentActive === 'msgLogin' }"
            @click="currentActive = 'msgLogin'"
            :span="12"
          >短信登录</a-col
          >
        </a-row>
        <div>
          <a-row style="padding: 0 10%">
            <a-col>
              <a-form-model v-if="currentActive === 'psdLogin'" ref="psdform" :model="psdform" :rules="psdRules">
                <a-alert v-if="errorMsg" type="error" showIcon style="margin-bottom: 24px" message="帐户或密码错误" />
                <a-form-model-item prop="mobile">
                  <a-input v-model="psdform.mobile" placeholder="请输入手机号" :size="inputSize">
                    <a-icon slot="prefix" type="mobile" class="icon" />
                  </a-input>
                </a-form-model-item>
                <a-form-model-item prop="password">
                  <a-input-password
                    v-model="psdform.password"
                    placeholder="请输入登录密码"
                    :size="inputSize"
                    type="password"
                    autocomplete="off"
                  >
                    <a-icon slot="prefix" type="lock" class="icon" />
                  </a-input-password>
                </a-form-model-item>
                <a-row type="flex" justify="space-between">
                  <a-col>
                    <a-checkbox :checked="psdform.autoLogin" @change="onAutoLoginChange"> 自动登录 </a-checkbox>
                  </a-col>
                  <a-col>
                    <router-link to="/person/findPassword1"> 忘记密码 </router-link>
                  </a-col>
                </a-row>
              </a-form-model>
              <a-form-model v-if="currentActive === 'msgLogin'" ref="msgform" :model="msgform" :rules="msgRules">
                <a-form-model-item prop="mobile">
                  <a-input v-model="msgform.mobile" placeholder="请输入手机号" :size="inputSize">
                    <a-icon slot="prefix" type="mobile" class="icon" />
                  </a-input>
                </a-form-model-item>
                <VerificationCode :form="msgform" :inputSize="inputSize"></VerificationCode>
              </a-form-model>
              <a-row style="margin: 24px 0 7px 0">
                <a-col :span="24">
                  <a-button
                    type="primary"
                    block
                    :size="inputSize"
                    @click="login"
                    :loading="buttonState.loginBtn"
                    :disabled="buttonState.loginBtn"
                  >登录</a-button
                  >
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div style="border-top: 1px solid rgb(217, 217, 217); height: 8% !important; width: 100%">
          <a-row type="flex" justify="center" align="middle" style="height: 100%">
            <a-col>
              <span>还没有帐号？</span>
              <router-link to="/person/register"> 免费注册</router-link>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import VerificationCode from '@/components/myComponents/VerificationCode.vue'
import md5 from 'md5'
import storage from 'store'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
export default {
  components: { VerificationCode },
  data() {
    return {
      psdform: {
        autoLogin: true
      },
      psdRules: {
        mobile: [
          { required: true, message: '请输入正确的手机号码', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ }
        ]
      },
      msgform: {

      },
      msgRules: {
        mobile: [
          { required: true, message: '请输入正确的手机号码', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ }
        ],
        verificationCode: [{ required: true, message: '请输入正确的验证码', trigger: 'blur', pattern: /^\d{6}$/ }]
      },
      inputSize: 'large',
      currentActive: 'psdLogin',
      buttonState: {
        loginBtn: false
      },
      errorMsg: false
    }
  },
  methods: {
    onAutoLoginChange(e) {
      this.psdform.autoLogin = e.target.checked
      localStorage.autoLogin = e.target.checked
    },
    login() {
      const { buttonState, resetButton, psdform } = this
      buttonState.loginBtn = true
      const form = this.currentActive === 'psdLogin' ? 'psdform' : 'msgform'
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$store.dispatch('Login', { username: this[form].mobile, password: md5(this[form].password) }).then(res => {
            // 如果自动登录没有勾选，则监听关闭浏览器清除storage
            if (!psdform.autoLogin) {
              window.addEventListener('beforeunload', e => this.beforeunloadFn())
              window.addEventListener('unload', e => this.unloadFn())
            }
            this.$router.push({ path: '/' })
          }).catch(error => {
            console.log('%c 🍪 error: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', error);
            this.errorMsg = true
          }).finally(() => {
            this.resetButton()
          })
        } else {
          resetButton()
          return false
        }
      }
      )
    },
    resetButton() {
      setTimeout(() => {
        this.buttonState.loginBtn = false
      }, 600)
    },
    unloadFn() {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      if (this._gap_time <= 5) {
        storage.remove(ACCESS_TOKEN)
      }
    },
    beforeunloadFn() {
      this._beforeUnload_time = new Date().getTime();
    }
  }
}
</script>
<style lang="less">
#loginContainer {
  border-radius: 5px;
  background: #ffffff;
  height: 100%;
  width: 100%;
  // position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .changeButton {
    padding: 15px 0;
    text-align: center !important;
    cursor: pointer;
  }
  .psdLogin {
    border-right: 1px solid rgb(217, 217, 217);
    border-bottom: 1px solid rgb(217, 217, 217);
    white-space: nowrap;
  }
  .active {
    border-bottom: 2px solid #1890ff !important;
  }
  .msgLogin {
    border-bottom: 1px solid rgb(217, 217, 217);
    white-space: nowrap;
  }
  // .ant-form-item-control {
  //   line-height: 0 !important;
  // }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 12px;
  }
  .icon {
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>