var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "用药史",
        visible: _vm.isMedicine,
        "confirm-loading": _vm.modal.confirmLoading
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c("a-col", { attrs: { span: 22 } }, [
            _c(
              "div",
              [
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: "选择药物",
                      labelCol: { span: 5 },
                      wrapperCol: { span: 19 }
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          mode: "multiple",
                          "default-value": [],
                          placeholder: "请选择要添加的药物"
                        },
                        on: { change: _vm.onSelectChange }
                      },
                      _vm._l(_vm.select.medicineOptions, function(medcine) {
                        return _c("a-select-option", { key: medcine.id }, [
                          _vm._v(_vm._s(medcine.name))
                        ])
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { gutter: 120 }
                  },
                  _vm._l(_vm.select.medicineList, function(medicine) {
                    return _c(
                      "a-col",
                      { key: medicine.id, attrs: { span: 12 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: medicine.name,
                              labelCol: { span: 10 },
                              wrapperCol: { span: 10 }
                            }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [medicine.name],
                                  expression: "[medicine.name]"
                                }
                              ],
                              attrs: { suffix: "g" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }