var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pro-layout",
    _vm._b(
      {
        attrs: {
          title: _vm.title,
          menus: _vm.menus,
          collapsed: _vm.collapsed,
          mediaQuery: _vm.query,
          isMobile: _vm.isMobile,
          handleMediaQuery: _vm.handleMediaQuery,
          handleCollapse: _vm.handleCollapse,
          logo: _vm.logoRender,
          i18nRender: _vm.i18nRender
        },
        scopedSlots: _vm._u([
          {
            key: "rightContentRender",
            fn: function() {
              return [
                _c("right-content", {
                  attrs: {
                    "top-menu": _vm.settings.layout === "topmenu",
                    "is-mobile": _vm.isMobile,
                    theme: _vm.settings.theme
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footerRender",
            fn: function() {
              return [_c("global-footer")]
            },
            proxy: true
          }
        ])
      },
      "pro-layout",
      _vm.settings,
      false
    ),
    [
      _c(
        "keep-alive",
        { attrs: { include: _vm.catchList } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }