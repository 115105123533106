const enumFiled = {
  Age: {
    value: 1,
    desc: '年龄'
  },
  Sex: {
    value: 2,
    desc: '性别'
  },
  Bloodtype: {
    value: 3,
    desc: '血型'
  }
}

export const methods = [{
    name: '位置检验',
    children: [{
        name: '单样本t检验',
        componentName: 'Dybtjy',
        state: 1
      },
      {
        name: '配对样本t检验',
        componentName: 'Pdybtjy',
        state: 1
      },
      {
        name: '独立样本t检验',
        componentName: 'Dlybtjy',
        state: 1
      },
      {
        name: 'z检验',
        componentName: 'Z',
        state: 1
      }
    ]
  },
  {
    name: '散度检验',
    children: [{
        name: '总体方差检验(卡方)',
        componentName: 'Kfjy',
        state: 1
      },
      {
        name: 'F检验',
        componentName: 'F',
        state: 1
      }
    ]
  },
  {
    name: '分布检验',
    children: [{
        name: '方差齐性分析',
        componentName: 'Fcqxfx',
        state: 1
      },
      {
        name: '单样本KS检验',
        componentName: 'Dybksjy',
        state: 1
      },
      {
        name: '双样本KS检验',
        componentName: 'Sybksjy',
        state: 1
      },
      {
        name: 'anderson检验',
        componentName: 'Anderson',
        state: 1
      },
      {
        name: '正态检验',
        componentName: 'Ztjy',
        state: 1
      }
    ]
  },
  {
    name: '独立性检验',
    children: [{
        name: '交叉卡方',
        componentName: 'Kffx',
        state: 1
      },
      {
        name: 'McNemar配对交叉卡方检验',
        componentName: 'Mcnemar',
        state: 1
      },
      {
        name: '卡方拟合性检验',
        componentName: 'Fitting',
        state: 1
      }
    ]
  },
  {
    name: '方差分析（ANOVA）',
    children: [{
        name: '单因素方差分析',
        componentName: 'Danysfcfx',
        state: 1
      },
      {
        name: 'Welch方差分析',
        componentName: 'Welch',
        state: 1
      },
      {
        name: '双因素方差分析',
        componentName: 'Sysfcfx',
        state: 1
      },
      {
        name: '多因素方差分析',
        componentName: 'Duoysfcfx',
        state: 1
      },
      {
        name: '重复测量方差分析',
        componentName: 'Cfclfcfx',
        state: 1
      },
      {
        name: '混合方差分析',
        componentName: 'Mixed',
        state: 1
      },
      {
        name: 'Tukey-HSD事后检验',
        // 原名:多重比较检验
        componentName: 'Dcbjjy',
        state: 1
      },
      {
        name: 'Games-Howell事后检验',
        componentName: 'GamesHowell',
        state: 1
      }
    ]
  },
  {
    name: '非参数秩和检验',
    children: [{
        name: 'Wilcoxon配对样本秩检验',
        componentName: 'Pdybzjy',
        state: 1
      },
      {
        name: 'Mann-WhitneyU独立样本秩检验',
        componentName: 'Dlybzjy',
        state: 1
      },
      {
        name: 'Kruskal-Wallis多样本秩检验',
        componentName: 'Dybzjy',
        state: 1
      },
      {
        name: 'Cochran秩检验',
        componentName: 'Cochran',
        state: 1
      },
      {
        name: 'Friedman秩检验',
        componentName: 'Friedman',
        state: 1
      }
    ]
  },
  {
    name: '比例检验',
    children: [{
        name: '单样本比例检验',
        componentName: 'Dybbljy',
        state: 1
      },
      {
        name: '双样本比例检验',
        componentName: 'Sybbljy',
        state: 1
      }
    ]
  },
  {
    name: '相关性检验',
    children: [{
        name: 'Pearson',
        componentName: 'Pearson',
        state: 1
      },
      {
        name: 'Spearman',
        componentName: 'Spearman',
        state: 1
      },
      {
        name: 'Kendall',
        componentName: 'Kendall',
        state: 1
      },
      {
        name: 'Phi',
        componentName: 'Phi',
        state: 1
      }
    ]
  }
]
export const methods2 = [{
    name: '线性回归',
    children: [{
        name: '最小二乘回归',
        componentName: 'OrdinaryLeastSquares',
        state: 1
      },
      {
        name: '广义最小二乘回归',
        componentName: 'GeneralizedOrdinaryLeastSquares',
        state: 1
      },
      {
        name: '鲁棒线性回归',
        componentName: 'RobustRegression',
        state: 1
      }
    ]
  },
  {
    name: '非线性回归',
    children: [{
        name: '多项式回归',
        componentName: 'PolynomialRegression',
        state: 1
      },
      {
        name: '二分类逻辑回归',
        componentName: 'Logic',
        state: 1
      },
      {
        name: '多分类逻辑回归',
        componentName: 'MultipleLogic',
        state: 1
      }
    ]
  },
  {
    name: '拟合',
    children: [{
      name: '拟合',
      componentName: 'Fitting',
      state: 1
    }]
  },
  {
    name: '平滑',
    children: [{
      name: '样条平滑',
      componentName: 'SplineSmooth',
      state: 1
    }]
  },
  {
    name: '主成分分析',
    children: [{
      name: '主成分分析',
      componentName: 'PCA',
      state: 1
    }]
  },
  {
    name: '生存分析',
    children: [{
        name: '单因素生存分析<br>(Kaplan Meier)</br>',
        componentName: 'Dysscfx',
        state: 1
      },
      {
        name: 'Cox回归',
        componentName: 'Cox',
        state: 1
      }
    ]
  }
]

export const hypothesisBrief = {
  Dybtjy: [{
      title: '方法描述',
      content: ['用于样本均数与已知总体均数的比较，以检验样本均值所代表的总体均值是否和已知的总体均数有差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当n<30时，数据为正态，可使用t检验；若总体标准差已知，也可使用z检验。',
        '<span class="serialNumber">②</span> 当n<30时，若数据为非正态，可考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态，再进行t检验。',
        '<span class="serialNumber">③</span> 当n≥30时，用t检验，也可使用z检验。'
      ]
    }
  ],
  Dlybtjy: [{
      title: '方法描述',
      content: ['用于两独立样本均数的比较，以检验两样本所来自的总体的均值是否有差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当n1<30或n2<30时，数据为正态分析，且方差齐，可采用独立样本t检验。',
        '<span class="serialNumber">②</span> 当n1<30或n2<30时，正态和方差齐性不满足，可进行数据变换，在满足正态性和方差齐性后，进行独立样本t检验；当变换后正态满足，方差齐性依然不满足或两者都同时不满足时，可采用秩和检验或非参数检验方法。',
        '<span class="serialNumber">③</span> 当n1≥30且n2≥30时，且满足方差齐性，可采用独立样本t检验。',
        '<span class="serialNumber">④</span> 当n1≥30且n2≥30时，且不满足方差齐性，可采用秩和检验或非参数检验方法。',
        '<span class="serialNumber">⑤</span> 当n1≥30且n2≥30时，可直接采用z检验。'
      ]
    }
  ],
  Pdybtjy: [{
      title: '方法描述',
      content: ['用于配对计量资料均值的比较，以检验两相关样本均值所代表的未知总体均值是否有差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当n<30时，数据为正态，用配对t检验；当数据不满足正态，可考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态，再进行配对t检验。',
        '<span class="serialNumber">②</span> 当n<30时，样本总体标准差已知时，也可使用配对z检验。',
        '<span class="serialNumber">③</span> 当n≥30时，用配对t检验，也可用配对z检验。'
      ]
    }
  ],
  Z: [{
      title: '方法描述',
      content: ['Z检验（Z Test）又叫U检验，用于比较一组数据均值与某数值，或两组数据的平均值差异的比较。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当n<30时, 总体标准差已知，可使用z检验。',
        '<span class="serialNumber">②</span> n≥30时，可直接使用z检验。'
      ]
    }
  ],
  Kfjy: [{
    title: '方法描述',
    content: ['用来检验一个总体的方差是否等于一个数值。']
  }],
  Fcqxfx: [{
      title: '方法描述',
      content: ['用于检验多个总体的方差是否相等。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 数据为正态分布时，使用Bartlett检验法。',
        '<span class="serialNumber">②</span> 数据为非正态分布时，可采用Levene检验。'
      ]
    }
  ],
  Dybksjy: [{
      title: '方法描述',
      content: ['用以检验一个计量变量或等级变量是否符合某种理论分布，为一种拟合优度检验。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 检验计量变量或等级变量的分布类型，如正态、均匀、泊松和指数分布等。'
      ]
    }
  ],
  Sybksjy: [{
      title: '方法描述',
      content: ['为两独立样本的Kolmogorov-Smirnov Z检验(柯尔莫戈洛夫-斯米诺夫Z)法，用于检验两个样本总体分布是否相同，是两独立样本最有用，最常规的非参数检验方法。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当两样本量(n1<30或n2<30)，数据为非正态分布时使用。',
        '<span class="serialNumber">② </span> 当两样本量(n1≥30且n2≥30)时，且方差不齐时使用。'
      ]
    }
  ],
  Anderson: [{
      title: '方法描述',
      content: ['安德森检验用于检验一个计量变量或等级变量样本是否来自特定分布，它是对单样本Kolmogorov-Smirnov (KS)检验的修正。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 可检验计量变量或等级变量的分布类型，包括：正态, 指数, 耿贝尔, 逻辑斯蒂分布等。'
      ]
    }
  ],
  Ztjy: [{
      title: '方法描述',
      content: ['检验观测数据总体是否服从正态分布。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当样本量在3-50时，使用shapiro-wilk检验。',
        '<span class="serialNumber">②</span> 当样本量在50-300时，使用Lilliefors检验。',
        '<span class="serialNumber">③</span> 当样本量大于300时，使用Kolmogorov-Smirnov检验。'
      ]
    }
  ],
  Kffx: [{
      title: '方法描述',
      content: ['卡方检验是一种用途很广的计数资料的假设检验方法，它属于非参数检验的范畴，主要用于推断两个或多个总体率或构成比之间有无差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 针对2*2（R=2，C=2）四格表n>=40 且 所有理论频数>=5，则使用普通的Pearson卡方。n>=40，所有理论数>1, 且至少一个理论数出现1<=E<5，则使用yates校正卡方。理论频数<1或n<40，则使用Fisher’s确切概率法检验。',
        '<span class="serialNumber">② </span> 针对R*C(R，C中任意一个大于2；且R>=2,且C>=2)所有理论频≥1 且 理论频数<5的格子的比例小于20%，则使用Pearson卡方。所有理论频数<1 或 理论频数<5的格子的比例超过20%，则使用yates校正卡方。'
      ]
    }
  ],
  Mcnemar: [{
      title: '方法描述',
      content: ['是一种针对配对设计的检验方法，如对同一样本的每一份样品分别用两种方法处理，观察其各水平分布的差异性。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 样本需是配对样本，且各为二分类变量。'
      ]
    }
  ],
  Danysfcfx: [{
      title: '方法描述',
      content: ['用于分析定类变量是否对定量变量产生显著影响，其基本思想是将全部观测值的总变异按影响因素分解为相应若干部分变异，计算假设统计量，实现对总体均数进行差异系推断。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 对于多组(k>2)样本均数的比较。',
        '<span class="serialNumber">②</span> 各样本必须是相互独立的随机样本。',
        '<span class="serialNumber">③</span> 样本的总体符合正态分布，若数据非正态应考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态分布后再进行方差分析。',
        '<span class="serialNumber">④</span> 各组样本具有方差齐性。'
      ]
    }
  ],
  Sysfcfx: [{
      title: '方法描述',
      content: ['双因素及多因素方差分析分别用以研究两个或两个以上分类变量是否对观测的定量数据产生显著影响。一个是无交互作用的双或多因素方差分析，另一个是有交互作用的双或多因素方差分析。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 样本的总体符合正态分布，若数据非正态应考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态分布后再进行方差分析。',
        '<span class="serialNumber">②</span> 各组样本具有方差齐性。',
        '<span class="serialNumber">③</span> 双因素及多因素方差更多用于实验研究，需谨慎使用。'
      ]
    }
  ],
  Duoysfcfx: [{
      title: '方法描述',
      content: ['双因素及多因素方差分析分别用以研究两个或两个以上分类变量是否对观测的定量数据产生显著影响。一个是无交互作用的双或多因素方差分析，另一个是有交互作用的双或多因素方差分析。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 样本的总体符合正态分布，若数据非正态应考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态分布后再进行方差分析。',
        '<span class="serialNumber">②</span> 各组样本具有方差齐性。',
        '<span class="serialNumber">③</span> 双因素及多因素方差更多用于实验研究，需谨慎使用。'
      ]
    }
  ],
  Cfclfcfx: [{
      title: '方法描述',
      content: ['用于处理同一受试对象的某一观察指标在不同时间点上进行多次测量的研究方法，如在医学研究中，需要针对这些患者在服药期间的观察指标进行不同时间点的重复测量。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 样本的总体符合正态分布，若数据非正态应考虑用对数转换、平方根变换、倒数变换、平方根反正弦变换等变量变换方法变为正态或接近正态分布后再进行方差分析。',
        '<span class="serialNumber">②</span> 需满足协方差阵的球形性或复合对称性。'
      ]
    }
  ],
  Mixed: [{
      title: '方法描述',
      content: ['为普通方差分析和重复测量方差分析的结合，其会涉及到组间项和组内项，组内项称为被试内，组间项称为被试间。如共有42名高血压患者，采用两种降压药物对不同患者的血压进行干预，20名患者使用传统的降压药，22名患者使用新的降压药，再分别测试42名患者用药后1周，1个月和3个月的血压数据，数据中涉及到时间点和药物干预（用药类别）的记录，时间点则称之组内项；药物干预称为组间项。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 即有独立样本,又有不独立的样本。',
        '<span class="serialNumber">②</span> 需要有组间项和组内项。'
      ]
    }
  ],
  Welch: [{
      title: '方法描述',
      content: ['在进行方差分析时，当不满足方差齐性要求时，直接进行差异性检验的一种方法。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 不满足方差齐性要求时使用。'
      ]
    }
  ],
  Dcbjjy: [{
      title: '方法描述',
      content: ['在方差分析后，进行事后检验的一种方法，比较两两平均数之间的差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 适用于各组样本数相等，且方差分析具有显著性差异后的事后检验。'
      ]
    }
  ],
  GamesHowell: [{
      title: '方法描述',
      content: ['是单因素方差分析中，各组方差不齐的情况下选用的一种事后两两比较的方法。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 适用于具有显著性差异后的事后检验。'
      ]
    }
  ],
  Pdybzjy: [{
      title: '方法描述',
      content: ['用于推断配对资料的差值是否来自中位数为零的总体。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 非正态定量资料。',
        '<span class="serialNumber">②</span> 有序或半定量资料。',
        '<span class="serialNumber">③</span> 为两组配对资料的比较。'
      ]
    }
  ],
  Dlybzjy: [{
      title: '方法描述',
      content: ['为两独立样本秩和检验方法，用于比较两样本分别代表的总体分布位置有无差异。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 当两样本量(n1<30或n2<30)，数据为非正态分布时使用。',
        '<span class="serialNumber">②</span> 当两样本量(n1≥30且n2≥30)时，且方差不齐时使用。'
      ]
    }
  ],
  Dybzjy: [{
      title: '方法描述',
      content: ['多组资料比较时，用于推断多个计量资料或多组有序资料的总体分布位置有无差别。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 数据不满足方差分析的条件时。',
        '<span class="serialNumber">②</span> 用于多组有序资料的比较。',
        '<span class="serialNumber">③</span> 为三组及以上资料的比较。'
      ]
    }
  ],
  Cochran: [{
      title: '方法描述',
      content: ['用于研究多相关样本数据是否呈现出显著性差异的一种检验方法。它的应用场景为N个评价者对于M个选手(对象，作品等)进行评价是否呈现出差异性，也可以针对问卷多选题数据是否存在差异进行研究。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 针对二分类变量，如满意度(0不满意/1满意), 认可度(0不认可/1认可), 同意度(0不同意/1同意),选择结果(0不选择/1选择)。',
        '<span class="serialNumber">②</span> 相关待分析样本数据格式要求：“行”表示评价者，“列”代表多个评价对象被评价的结果。'
      ]
    }
  ],
  Friedman: [{
      title: '方法描述',
      content: ['用于多个(相关)样本是否具有显著性差异的统计检验方法，它的应用场景和Cochran秩检验一致。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 针对定量数据,多个相关样本(至少3个)。',
        '<span class="serialNumber">②</span> 相关待分析样本数据格式要求和Cochran秩检验一致。'
      ]
    }
  ],
  Dybbljy: [{
      title: '方法描述',
      content: ['某事件的发生率为p，用于检验该发生率p与预期比率的差异性。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 事件的发生数≥5且未发生数≥5。'
      ]
    }
  ],
  Sybbljy: [{
      title: '方法描述',
      content: ['用于一个样本某一事件发生率和另外一个样本某一事件发生率的比较。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 样本1和样本2的发生数都>5。'
      ]
    }
  ]
}

export const analysisOfRegressionBrief = {
  OrdinaryLeastSquares: [{
      title: '方法描述',
      content: ['是一种数学优化技术，通过最小化误差的平方和寻找数据的最佳函数匹配。利用该方法可以简便地求得未知的数据，并使得这些求得的数据与实际数据之间误差的平方和为最小。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 无异方差性。'
      ]
    }
  ],
  GeneralizedOrdinaryLeastSquares: [{
      title: '方法描述',
      content: ['为解释变量加上一个权重，从而使得加上权重后的回归方程方差相同，进而用普通最小二乘法估计回归参数。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 回归分析中出现异方差性。'
      ]
    }
  ],
  RobustRegression: [{
      title: '方法描述',
      content: ['用于解决异常数据(或极端数据)时的回归估计，若数据中没有异常值(也或者异常数据可以自行进行处理掉)，建议使用常用的最小二乘法回归即可。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 数据中存在异常值或极端值，也或者如回归分析中出现异方差性。'
      ]
    }
  ],
  PolynomialRegression: [{
      title: '方法描述',
      content: ['回归函数是回归变量多项式的回归。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 处理非线性问题。'
      ]
    }
  ],
  Fitting: [{
      title: '方法描述',
      content: ['把平面上一系列的点，采用相应的拟合函数用光滑的曲线靠近这些点。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 需根据各个变量本身的特点，选择最适合的曲线拟合模型，才能得到最合理的结果。'
      ]
    }
  ],
  SplineSmooth: [{
      title: '方法描述',
      content: ['采用分段的低阶多项式逼近函数，将一系列数据点所连接起来所形成的光滑的曲线。']
    },
    {
      title: '使用条件',
      content: [
        '<span class="serialNumber">①</span> 可应用于具有不同非线性度或存在多个极值点的函数。'
      ]
    }
  ],
  Cox: [{
      title: '方法描述',
      content: ['Cox回归模型，又称“比例风险回归模型”，该模型以生存结局和生存时间为因变量，研究多个影响因素下生存或结局情况。如化疗程度、用药情况对生存时间或结局的影响；营销活动、价格等对于用户留存情况的影响等。']
    },
    {
      title: '使用条件',
      content: [
        '要求结局指标既有生存的二分类结局，也要有生存时间，对生存时间分布无要求，自变量可为任意类型的数据变量。'
      ]
    }
  ],
  Dysscfx: [{
      title: '方法描述',
      content: ['也称单因素生存分析，它用于研究1个因素对于生存时间的影响情况。比如新药物使用是否会有效的增加癌症病人的存活时间。']
    },
    {
      title: '使用条件',
      content: [
        '针对小样本或大样本且有精确生存时间的资料。'
      ]
    }
  ]
}

export const enumHour = [{
  value: 0
}, {
  value: 1
}, {
  value: 2
}, {
  value: 3
}, {
  value: 4
}, {
  value: 5
}, {
  value: 6
}, {
  value: 7
}, {
  value: 8
}, {
  value: 9
}, {
  value: 10
}, {
  value: 11
}, {
  value: 12
}, {
  value: 13
}, {
  value: 14
}, {
  value: 15
}, {
  value: 16
}, {
  value: 17
}, {
  value: 18
}, {
  value: 19
}, {
  value: 20
}, {
  value: 21
}, {
  value: 22
}, {
  value: 23
}]
export const enumMinture = [{
  value: 0
}, {
  value: 10
}, {
  value: 20
}, {
  value: 30
}, {
  value: 40
}, {
  value: 50
}]
export const enumUnit = [{
    value: 1,
    text: '年'
  },
  {
    value: 2,
    text: '月'
  },
  {
    value: 3,
    text: '天'
  },
  {
    value: 5,
    text: '次'
  }, {
    value: 6,
    text: '岁'
  }, {
    value: 7,
    text: '分'
  }, {
    value: 8,
    text: '小时'
  }, {
    value: 9,
    text: '周'
  }, {
    value: 10,
    text: '米'
  }, {
    value: 11,
    text: '厘米(cm)'
  }, {
    value: 12,
    text: '毫米(mm)'
  }, {
    value: 13,
    text: '微米(μm)'
  }, {
    value: 14,
    text: '纳米(纳米)'
  }, {
    value: 15,
    text: '分钟'
  }, {
    value: 20,
    text: '公斤(kg)'
  }, {
    value: 21,
    text: '吨(t)'
  }, {
    value: 22,
    text: '克(g)'
  }, {
    value: 23,
    text: '毫克(mg)'
  }, {
    value: 30,
    text: '毫米汞柱(mmHg)'
  }, {
    value: 31,
    text: '摩尔(mol)'
  }, {
    value: 40,
    text: '升(L)'
  }, {
    value: 41,
    text: '分升(dl)'
  }, {
    value: 42,
    text: '毫升(ml)'
  }, {
    value: 43,
    text: '微升(μL)'
  }, {
    value: 50,
    text: '纳克/毫升(ng/ml)'
  }, {
    value: 52,
    text: '单位/毫升(U/ml)'
  }, {
    value: 53,
    text: '国际单位/毫升(IU/ml)'
  }, {
    value: 54,
    text: 'uIU/毫升(uIU/ml)'
  }, {
    value: 55,
    text: '皮摩尔/升(pmol/L)'
  }, {
    value: 56,
    text: '毫摩尔/升(mmol/L)'
  }, {
    value: 57,
    text: '微摩尔/升(ummol/L)'
  }, {
    value: 58,
    text: 'kg/m²'
  }, {
    value: 60,
    text: '克/升(g/L)'
  }, {
    value: 61,
    text: 'nmol/L'
  }, {
    value: 62,
    text: 'mIU/L'
  }, {
    value: 63,
    text: 'mIU/ml'
  }, {
    value: 70,
    text: '%'
  }, {
    value: 71,
    text: '次/月'
  }, {
    value: 80,
    text: '所'
  }, {
    value: 90,
    text: '人'
  }
]

export const minorities = [{
    id: 0,
    name: '汉族'
  },
  {
    id: 1,
    name: '其他民族'
  }
]

export const educations = [{
    key: 0,
    value: '文盲及半文盲'
  },
  {
    key: 1,
    value: '小学'
  },
  {
    key: 2,
    value: '初中'
  },
  {
    key: 3,
    value: '高中'
  },
  {
    key: 4,
    value: '技工学校'
  },
  {
    key: 5,
    value: '中等专业学校'
  },
  {
    key: 6,
    value: '大学专科和专科学校'
  },
  {
    key: 7,
    value: '大学本科'
  },
  {
    key: 8,
    value: '研究生'
  }
]

export const ocupations = [{
    key: 0,
    value: '国家机关、党群组织、企业、事业单位负责人'
  },
  {
    key: 1,
    value: '专业技术人员'
  },
  {
    key: 2,
    value: '办事人员和有关人员'
  },
  {
    key: 3,
    value: '商业、服务业人员'
  },
  {
    key: 4,
    value: '农、林、牧、渔、水利业生产人员'
  },
  {
    key: 5,
    value: '军人'
  },
  {
    key: 6,
    value: '生产、运输设备操作人员及有关人员'
  },
  {
    key: 7,
    value: '不便分类的其他从业人员'
  },
  {
    key: 8,
    value: '无职业'
  }
]

export const satisfactions = [{
    key: 0,
    value: '非常不满意'
  },
  {
    key: 1,
    value: '不满意'
  },
  {
    key: 2,
    value: '一般'
  },
  {
    key: 3,
    value: '满意'
  },
  {
    key: 4,
    value: '非常满意'
  }
]

export const marries = [{
    key: 0,
    value: '未婚'
  },
  {
    key: 1,
    value: '已婚'
  },
  {
    key: 2,
    value: '离婚'
  },
  {
    key: 3,
    value: '其它'
  }
]

export const stayWiths = [{
    key: 0,
    value: '独居'
  },
  {
    key: 1,
    value: '与配偶居住'
  },
  {
    key: 2,
    value: '与父母居住'
  },
  {
    key: 3,
    value: '与配偶父母居住'
  },
  {
    key: 4,
    value: '与配偶、父母居住'
  },
  {
    key: 5,
    value: '与配偶、配偶父母居住'
  }
]

export const lifeStyles = [{
    key: 'eatHabit',
    keyName: '饮食习惯',
    valueName: [{
      id: 0,
      name: '嗜甜食'
    }, {
      id: 1,
      name: '嗜咸食'
    }, {
      id: 2,
      name: '嗜高脂'
    }, {
      id: 3,
      name: '其他'
    }]
  },
  {
    key: 'exercise',
    keyName: '经常参与体育锻炼',
    valueName: [{
      id: true,
      name: '是'
    }, {
      id: false,
      name: '否'
    }]
  },
  {
    key: 'smoke',
    keyName: '吸烟或被动吸烟',
    valueName: [{
      id: true,
      name: '是'
    }, {
      id: false,
      name: '否'
    }]
  },
  {
    key: 'wine',
    keyName: '经常饮酒',
    valueName: [{
      id: true,
      name: '是'
    }, {
      id: false,
      name: '否'
    }]
  },
  {
    key: 'stayUpLate',
    keyName: '经常熬夜',
    valueName: [{
      id: true,
      name: '是'
    }, {
      id: false,
      name: '否'
    }]
  },
  {
    key: 'pressure',
    keyName: '面临生活或工作方面的压力',
    valueName: [{
      id: true,
      name: '是'
    }, {
      id: false,
      name: '否'
    }]
  }
]

export default {
  enumFiled
}