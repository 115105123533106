var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false, id: "divQuestion" } },
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-breadcrumb",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "a-breadcrumb-item",
                    [
                      _c("router-link", { attrs: { to: "/file/List" } }, [
                        _vm._v("文件列表")
                      ])
                    ],
                    1
                  ),
                  _c("a-breadcrumb-item", [_vm._v(" 文件详情 ")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("div", { staticStyle: { "font-size": "15px" } }, [
              _c("span", [_vm._v("文件名称：" + _vm._s(_vm.filename))]),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("样本量:"),
                _c("span", { staticStyle: { color: "#3397ff" } }, [
                  _vm._v(_vm._s(_vm.count))
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "s-table",
        {
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            bordered: "",
            size: "small",
            rowKey: "key",
            columns: _vm.columns,
            data: _vm.loadData,
            showPagination: "auto",
            scroll: { x: true }
          }
        },
        _vm._l(_vm.columns, function(item, i) {
          return _c(
            "div",
            { key: i, attrs: { slot: item.dataIndex }, slot: item.dataIndex },
            [
              _vm._v(
                " " +
                  _vm._s(
                    item.slotValue + "【" + item.fileType.split("_")[1] + "】"
                  ) +
                  " "
              ),
              _c(
                "a-popover",
                {
                  attrs: {
                    title: "修改类型",
                    trigger: "click",
                    visible: _vm.colVisible && _vm.colNum === i
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { slot: "content" },
                      slot: "content"
                    },
                    [
                      _c(
                        "a-form-model",
                        _vm._b(
                          {
                            attrs: {
                              "v-model": _vm.columform,
                              labelAlign: "left"
                            }
                          },
                          "a-form-model",
                          _vm.formLayout,
                          false
                        ),
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "类型" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.columform.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.columform, "type", $$v)
                                    },
                                    expression: "columform.type"
                                  }
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { key: "0", attrs: { value: "0_定量" } },
                                    [_vm._v("定量")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "2", attrs: { value: "2_定类" } },
                                    [_vm._v("定类")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "3", attrs: { value: "3_日期" } },
                                    [_vm._v("日期")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "4", attrs: { value: "4_文本" } },
                                    [_vm._v("文本")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.PopoverSubmit(item, i)
                                }
                              }
                            },
                            [_vm._v("确定")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "cancel" },
                              on: {
                                click: function($event) {
                                  return _vm.PopoverClose(item, i)
                                }
                              }
                            },
                            [_vm._v("取消")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-icon", {
                    attrs: { type: "edit", theme: "twoTone" },
                    on: {
                      click: function($event) {
                        return _vm.PopoverOpen(item, i)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }