var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticStyle: {
        "border-right": "1px solid rgb(232, 232, 232)",
        padding: "30px 30px"
      }
    },
    [
      _c("a-col", { attrs: { span: 24 } }, [
        _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  _vm._l(_vm.res.tables, function(table, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "margin-bottom": "20px" } },
                      [
                        _c("my-table2", {
                          attrs: {
                            showHeader: table.showHeader,
                            data: table.data,
                            columns: table.columns,
                            title: table.title,
                            size: table.size
                          }
                        }),
                        _c("div", {
                          staticStyle: {
                            height: "5px",
                            border: "1px solid rgb(232, 232, 232)"
                          }
                        }),
                        _c("div", { staticStyle: { margin: "10px 0" } }, [
                          _c("b", {
                            domProps: { innerHTML: _vm._s(table.text) }
                          })
                        ])
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._l(_vm.res.imgs, function(item, index) {
        return _c(
          "a-col",
          {
            key: index,
            staticStyle: { display: "flex", "justify-content": "center" },
            attrs: { span: 24 }
          },
          [
            _c("MyImg", {
              attrs: { imgBytes: item, fileName: "协变量平均值处的生存函数" }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }