<template>
  <div>
    <change-password :show="changePasswordVisible" @show="handleChangePassword"></change-password>
    <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
      <span class="ant-pro-account-avatar">
        <a-avatar
          size="small"
          src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
          class="antd-pro-global-header-index-avatar"
        />
        <span>{{ currentUser.name }}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <!-- <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          个人中心
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          个人设置
        </a-menu-item>
        <a-menu-divider v-if="menu" /> -->
          <a-menu-item @click="info">
            <a-icon type="message" />
            用户信息
          </a-menu-item>
          <a-menu-item key="changePassword" @click="handleChangePassword">
            <a-icon type="edit" />
            修改密码
          </a-menu-item>
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import ChangePassword from '@/views/user/ChangePassword.vue'
import { clearSession } from '@/api/session'

export default {
  name: 'AvatarDropdown',
  components: { ChangePassword },
  mounted() {
    this.$nextTick(() => {
      this.addSeparatorForMenuItem(['图表'])
      if (this.$store.state.user.roles === 'person') this.addVipIcon(['回归分析', '智能报告'])
    })
  },
  data() {
    return {
      changePasswordVisible: false
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeInfo(info) {
      if (info === undefined) {
        return '无'
      }
      if (info === false) {
        return '否'
      }
      if (info === true) {
        return '是'
      }
      return info
    },
    info() {
      const h = this.$createElement
      console.log(this.$store.state.user.info)
      this.$info({
        centered: true,
        title: '帐户信息',
        content: h('b', { class: 'text' }, [h('p', '用户姓名：' + '' + this.changeInfo(this.$store.state.user.info.name)),
        h('p', '用户电话：' + '' + this.changeInfo(this.$store.state.user.info.mobile)),
        h('p', '公司名称：' + '' + this.changeInfo(this.$store.state.user.info.org.name)),
        h('p', '公司地址：' + '' + this.changeInfo(this.$store.state.user.info.address)),
        h('p', '所属机构：' + '' + this.changeInfo(this.$store.state.user.info.orgName)),
        h('p', '试用账号：' + '' + this.changeInfo(this.$store.state.user.info.trial)),
        h('p', '过期时间：' + '' + this.changeInfo(this.$moment(this.$store.state.user.info.expireTime))),
        h('p', '会员账号：' + '' + this.changeInfo(this.$store.state.user.info.IsVip))
        ]),
        onOk() {}
      });
    },
    addSeparatorForMenuItem(moduleNameAry) {
      /**
       * 该方法在导航栏里添加分隔符
       * @param moduleNameAry 存放需要在左侧添加虚线的模块名的数组
       */
      const domAry = document.getElementsByClassName('ant-menu-item')
      const menuDomAry = document.getElementsByClassName('ant-menu')
      domAry.forEach(itemDom => {
        if (moduleNameAry.includes(itemDom.innerText)) {
          // 创建一个li标签，当做分隔符。
          const li = document.createElement('li')
          li.style.width = '0px'
          li.style.height = '17px'
          li.style.display = 'inline-block'
          li.style.border = '0.5px dashed rgba(255,255,255,0.5)'
          li.style.margin = '0 20px'
          menuDomAry[0].insertBefore(li, itemDom)
        }
      })
    },
    addVipIcon(moduleNameAry) {
      /**
       * 该方法在需要的模块旁边加上vip图标
       * @param moduleNameAry 存放需要添加vip图标的的模块名的数组
       */
      const domAry = document.getElementsByClassName('ant-menu-item')
      domAry.forEach(itemDom => {
        if (moduleNameAry.includes(itemDom.innerText)) {
          const aTag = itemDom.children
          const icon = `<img src="/vip.png" style="display:inline-block;position:relative;bottom:8px;left:5px">`
          // 在a标签内部的最后一个子节点之后插入html
          aTag[0].insertAdjacentHTML('beforeend', icon);
        }
      })
    },
    handleToCenter() {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t('退出登录'),
        content: this.$t('您确定退出登录吗'),
        onOk: async () => {
          const roles = this.$store.state.user.roles
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          try {
            await clearSession({}).then(res => {
              console.log('清除session成功')
            })
          } catch (e) {
            console.log(e)
          }
          return this.$store.dispatch('Logout').then(() => {
            this.$store.dispatch('initSamples')
            this.$router.push({ name: roles === 'person' ? 'personLogin' : 'login' })
          })
        },
        onCancel() { }
      })
    },
    handleChangePassword(e) {
      this.changePasswordVisible = !this.changePasswordVisible
    },
    goto(path) {
      this.$router.push(path)
    }
  },
  computed: {
    payNoticeController() {
      return this.$store.state.user.payNoticeController
    }
  },
  watch: {
    payNoticeController() {
      const vipContentName = this.$store.state.user.vipContentName
      this.$notification.open({
        message: () => (<span style="color:#333333">{vipContentName}为<span style="color:#ff7200">VIP专享</span>功能</span>),
        // description: () => (<a class="vip" href="javascript:void(0)" onClick={}>开通VIP</a>),
        icon: <a-icon type="smile" style="color: grey" />
        // duration: 1000000,
        // style: {
        //   position: 'fixed',
        //   left: `${document.body.clientWidth / 2 - 384 / 2}px`
        // }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
.ant-pro-account-avatar {
  display: inline-block;
  height: 100%;
  width: 100%;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 12px;
}
.vip {
  display: inline-block;
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 70px;
  height: 25px;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
