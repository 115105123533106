var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-divider"),
      _vm.moreInfo
        ? _c(
            "div",
            { staticStyle: { width: "90%", margin: "0 auto" } },
            [
              _c(
                "a-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { size: "small" }
                },
                [
                  _c("a-descriptions-item", { attrs: { label: "记录编号" } }, [
                    _vm._v(_vm._s(_vm.exp.serialNum))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验类型" } }, [
                    _vm._v(
                      _vm._s(_vm.exp.type === 0 ? "精密度验证" : "正确度验证")
                    )
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验名称" } }, [
                    _vm._v(_vm._s(_vm.exp.expName))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验天数" } }, [
                    _vm._v(_vm._s(_vm.exp.expDays))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验设备" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.exp.devices && _vm.exp.devices.length > 0
                          ? _vm.exp.devices
                              .map(function(r) {
                                return r.name
                              })
                              .join(",")
                          : ""
                      )
                    )
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验人员" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.exp.users && _vm.exp.users.length > 0
                          ? _vm.exp.users
                              .map(function(r) {
                                return r.name
                              })
                              .join(",")
                          : ""
                      )
                    )
                  ]),
                  _vm._l(_vm.reagents, function(item, i) {
                    return _c(
                      "a-descriptions-item",
                      { key: i, attrs: { label: item.label } },
                      [_vm._v(_vm._s(item.value))]
                    )
                  }),
                  _c("a-descriptions-item", { attrs: { label: "样本名称" } }, [
                    _vm._v(_vm._s(_vm.exp.sampleName))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "样本浓度" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.exp.sampleConcentrations
                          ? JSON.parse(_vm.exp.sampleConcentrations).join(",")
                          : ""
                      )
                    )
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "样本批号" } }, [
                    _vm._v(_vm._s(_vm.exp.sampleBatchNum))
                  ]),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "重复测量次数" } },
                    [_vm._v(_vm._s(_vm.exp.repeatNum))]
                  ),
                  _vm.exp.accuracyFunction === null
                    ? [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "规定重复CV1" } },
                          [_vm._v(_vm._s(_vm.exp.cV1 * 100) + "%")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "规定重复CV2" } },
                          [_vm._v(_vm._s(_vm.exp.cV2 * 100) + "%")]
                        )
                      ]
                    : _vm._e(),
                  _vm.exp.accuracyFunction === 1
                    ? [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "厂家声明偏倚(B)" } },
                          [_vm._v(_vm._s(_vm.exp.b))]
                        )
                      ]
                    : _vm._e(),
                  _vm.exp.accuracyFunction === 0
                    ? [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "参考物质指定值" } },
                          [_vm._v(_vm._s(_vm.exp.designedValue) + "mmol/L")]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "指定标准差" } },
                          [_vm._v(_vm._s(_vm.exp.designedStd) + "mmol/L")]
                        )
                      ]
                    : _vm._e(),
                  _c("a-descriptions-item", { attrs: { label: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.moreInfo = false
                          }
                        }
                      },
                      [_vm._v("收起 > >")]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        : _c(
            "div",
            { staticStyle: { width: "90%", margin: "0 auto" } },
            [
              _c(
                "a-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { size: "small" }
                },
                [
                  _c("a-descriptions-item", { attrs: { label: "记录编号" } }, [
                    _vm._v(_vm._s(_vm.exp.serialNum))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验类型" } }, [
                    _vm._v(
                      _vm._s(_vm.exp.type === 0 ? "精密度验证" : "正确度验证")
                    )
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验名称" } }, [
                    _vm._v(_vm._s(_vm.exp.expName))
                  ]),
                  _c("a-descriptions-item", { attrs: { label: "实验天数" } }, [
                    _vm._v(_vm._s(_vm.exp.expDays))
                  ]),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "重复测量次数" } },
                    [_vm._v(_vm._s(_vm.exp.repeatNum))]
                  ),
                  _c("a-descriptions-item", { attrs: { label: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#1890ff", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.moreInfo = true
                          }
                        }
                      },
                      [_vm._v(" 更多信息 > > ")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
      _c("a-divider"),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.showModal } },
            [_vm._v("新增数据")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", disabled: _vm.disabled },
              on: { click: _vm.showRecord }
            },
            [_vm._v("查看报告")]
          )
        ],
        1
      ),
      _c("new-data", {
        attrs: { visible: _vm.visible, exp: _vm.exp },
        on: { handleCancel: _vm.handleCancel, refTable: _vm.refTable }
      }),
      _c("s-table", {
        ref: "table",
        staticClass: "smallTable",
        staticStyle: { "margin-top": "20px" },
        attrs: {
          bordered: "",
          size: "small",
          rowKey: "key",
          columns: _vm.columns,
          data: _vm.loadData,
          showPagination: "auto",
          scroll: { x: true }
        },
        scopedSlots: _vm._u([
          {
            key: "date",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          text
                            ? _vm.$moment(text).format("YYYY-MM-DD HH:mm:ss")
                            : ""
                        )
                      )
                    ])
                  ]
                ],
                2
              )
            }
          },
          {
            key: "result",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  [
                    _c("span", { staticStyle: { color: "#43c73d" } }, [
                      _vm._v(_vm._s(_vm._f("ResultFilter")(text)))
                    ])
                  ]
                ],
                2
              )
            }
          },
          {
            key: "operation",
            fn: function(text, record) {
              return [
                _c(
                  "a-popconfirm",
                  {
                    attrs: { title: "确认删除?" },
                    on: {
                      confirm: function() {
                        return _vm.onDelete(record.key)
                      }
                    }
                  },
                  [
                    _c("a", { attrs: { href: "javascript:;" } }, [
                      _vm._v("删除")
                    ])
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }