<template>
  <div>
    <div>
      <div
        :ref="`title,${thirdIndex.normIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.normIndex} 正态性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.normIndex }} 正态性检验
      </div>
      <div>
        <Norm
          :ref="`content,${thirdIndex.normIndex}`"
          :xys="xys"
          :data="data"
          :lei="data.lei"
          :settings="settings.options.methods.find((v) => v.name === '正态检验')"
          @isNorm="(val) => (isNorm = val)"
        >
          <template #text1 class="text">
            <p :ref="`text,${thirdIndex.normIndex},1`">
              对{{ text_label }}做正态性检验，<br /><span class="H0H1">H0：{{ text_label }}服从正态分布。</span
              ><br /><span class="H0H1">H1：{{ text_label }}不服从正态分布。</span><br />检验结果如下表所示：
            </p>
          </template>
          <template #text2="{ text }" class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.normIndex},3`" v-for="(pText, index) in text" :key="index">
                <span v-html="pText">{{ pText }}</span>
              </p>
            </Conclusion>
          </template>
          <template #text3 class="text">
            <p :ref="`text,${thirdIndex.normIndex},4`">
              {{ lei === '变量' ? `${liang1}和${liang2}` : liang }}的PP图和QQ图如下图所示：
            </p>
          </template>
        </Norm>
      </div>
    </div>
    <div
      v-if="isNorm.length > 0 && (flow_position_now === 'auto' ? isNorm[0] >= a : flow_position_now !== 'none_param')"
    >
      <div
        :ref="`title,${thirdIndex.homogeneousIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.homogeneousIndex} 方差齐性检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.homogeneousIndex }} 方差齐性检验
      </div>
      <div>
        <HomogeneityOfVariances
          :ref="`content,${thirdIndex.homogeneousIndex}`"
          :data="data"
          :lei="data.lei"
          :isNorm="isNorm"
          :settings="settings.options.methods.find((v) => v.name === '方差齐性检验')"
          @isHomogeneous="(val) => (isHomogeneous = val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.homogeneousIndex},1`" v-html="get_text('方差齐性检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.homogeneousIndex},3`">
                由上表可得：
                <span v-html="$hypothesis.conclusion(isHomogeneous, a, `${text_label}方差齐`, `${text_label}方差不齐`)">
                </span>
              </p>
            </Conclusion>
          </template>
        </HomogeneityOfVariances>
      </div>
    </div>
    <div
      v-if="isHomogeneous.length > 0 && (flow_position_now === 'auto' ? is_small_sample() : flow_position_now === 't')"
    >
      <div :ref="`title,${thirdIndex.tIndex}`" :id="`3.${secondIndex}.${thirdIndex.tIndex} t检验`" class="third-title">
        3.{{ secondIndex }}.{{ thirdIndex.tIndex }} t检验
      </div>
      <div>
        <T
          :ref="`content,${thirdIndex.tIndex}`"
          :data="data"
          :lei="data.lei"
          :isHomogeneous="isHomogeneous"
          @isMeanEqual="(val) => (isMeanEqual = val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.tIndex},1`" v-html="get_text('t检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.tIndex},3`">
                由上表可得：
                <span
                  v-html="$hypothesis.conclusion(isMeanEqual, a, `${text_label}均值相等`, `${text_label}均值不相等`)"
                ></span>
              </p>
            </Conclusion>
          </template>
        </T>
      </div>
    </div>
    <div
      v-if="isHomogeneous.length > 0 && (flow_position_now === 'auto' ? !is_small_sample() : flow_position_now === 'z')"
    >
      <div :ref="`title,${thirdIndex.zIndex}`" :id="`3.${secondIndex}.${thirdIndex.zIndex} z检验`" class="third-title">
        3.{{ secondIndex }}.{{ thirdIndex.zIndex }} z检验
      </div>
      <div>
        <Z
          :ref="`content,${thirdIndex.zIndex}`"
          :data="data"
          :lei="data.lei"
          :isHomogeneous="isHomogeneous"
          @isMeanEqual="(val) => (isMeanEqual = val)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.zIndex},1`" v-html="get_text('z检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.zIndex},3`">
                由上表可得：
                <span
                  v-html="$hypothesis.conclusion(isMeanEqual, a, `${text_label}均值相等`, `${text_label}均值不相等`)"
                ></span>
              </p>
            </Conclusion>
          </template>
        </Z>
      </div>
    </div>
    <div
      v-if="isNorm.length > 0 && (flow_position_now === 'auto' ? isNorm[0] < a : flow_position_now === 'none_param')"
    >
      <!-- <div> -->
      <div
        :ref="`title,${thirdIndex.noneParamIndex}`"
        :id="`3.${secondIndex}.${thirdIndex.noneParamIndex} 非参数检验`"
        class="third-title"
      >
        3.{{ secondIndex }}.{{ thirdIndex.noneParamIndex }} 非参数检验
      </div>
      <div>
        <NoneParam
          :ref="`content,${thirdIndex.noneParamIndex}`"
          :data="data"
          :lei="data.lei"
          @noneParamIsDiff="(x) => (noneParamIsDiff = x)"
        >
          <template #textFront class="text">
            <p :ref="`text,${thirdIndex.noneParamIndex},1`" v-html="get_text('非参数检验')"></p>
          </template>
          <template #textBack class="text">
            <Conclusion>
              <p :ref="`text,${thirdIndex.noneParamIndex},3`">
                由上表可得：
                <span
                  v-html="
                    $hypothesis.conclusion(noneParamIsDiff, a, `${text_label}间差异不显著`, `${text_label}间差异显著`)
                  "
                ></span>
              </p>
            </Conclusion>
          </template>
        </NoneParam>
      </div>
    </div>
  </div>
</template>
<script>
import Norm from '@/views/report/modules/hypothesis/norm'
import HomogeneityOfVariances, { get_text_homogeneityOfVariances } from '@/views/report/modules/hypothesis/homogeneityOfVariances'
import NoneParam, { get_text_noneParam } from '@/views/report/modules/hypothesis/noneParam'
import T, { get_text_t } from '@/views/report/modules/hypothesis/t'
import Z, { get_text_z } from '@/views/report/modules/hypothesis/z'
import { get_settings_obj } from '@/views/report/utils/utils'

export default {
  nama: 'Means',
  components: { Norm, HomogeneityOfVariances, T, NoneParam, Z },
  props: ['secondIndex', 'xys', 'data', 'settings'],
  created() {
    this.getNumOfSamples()
    this.obj_of_settings = get_settings_obj(this.settings)
  },
  data() {
    return {
      numOfN1: 0,
      numOfN2: 0,
      isNorm: [],
      isHomogeneous: [],
      noneParamIsDiff: [],
      isMeanEqual: [],
      obj_of_settings: {} // 用来存放各模块配置的obj
    }
  },
  methods: {
    // 获取两类的样本数
    getNumOfSamples() {
      const { data1, lei } = this.data
      let dataList
      if (lei === '变量') {
        dataList = Object.values(data1)
      } else {
        dataList = Object.values(Object.values(data1)[0])
      }
      this.numOfN1 = dataList[0].length
      this.numOfN2 = dataList[1].length
    },
    is_small_sample() {
      const { numOfN1, numOfN2 } = this
      return numOfN1 < 30 || numOfN2 < 30
    },
    get_text(name_of_module) {
      /**
       * @param 要获取文字信息的模块名
       */
      const { secondIndex, thirdIndex, obj_of_settings, text_label, flow_position_now, isNorm } = this
      let text
      switch (name_of_module) {
        case '方差齐性检验':
          text = get_text_homogeneityOfVariances(obj_of_settings.homogeneous.args[0].value, text_label, flow_position_now, isNorm[0], secondIndex, thirdIndex)
          break
        case '非参数检验':
          text = get_text_noneParam(text_label, flow_position_now, true, secondIndex, thirdIndex)
          break
        case 't检验':
          text = get_text_t(text_label, flow_position_now)
          break
        case 'z检验':
          text = get_text_z(text_label, flow_position_now)
      }
      return text
    }
  },
  computed: {
    thirdIndex() {
      const normIndex = 1
      const homogeneousIndex = normIndex + 1
      const noneParamIndex = normIndex + 1
      const tIndex = homogeneousIndex + 1
      const zIndex = homogeneousIndex + 1
      return { normIndex, homogeneousIndex, noneParamIndex, tIndex, zIndex }
    },
    liang1() {
      return this.xys[0][0]
    },
    liang2() {
      return this.xys[1][0]
    },
    text_label() {
      // 根据是两个定量还是一个定类和一个定量来决定文字的描述
      const { lei, liang, liang1, liang2 } = this
      const lei_liang_label = `不同${lei}中${liang}的样本`
      const liang_liang_label = `${liang1}和${liang2}的样本`
      return lei === '变量' ? liang_liang_label : lei_liang_label
    },
    lei() {
      return this.data.lei
    },
    liang() {
      return this.xys[1][0]
    },
    leiOptions() {
      return this.xys[0][0].slice(0, -1)
    },
    divideP() {
      return (ary) => {
        const { a } = this
        const obj = {
          less: [],
          greater: []
        }
        ary.forEach((p, index) => {
          if (p || p === 0) {
            const liang = this.xys[1][index]
            if (typeof p === 'number') {
              // p值有可能是数字/数组/true或者false
              p >= a ? obj.greater.push(liang) : obj.less.push(liang)
            } else if (typeof p === 'object') {
              p.every(v => v >= a) ? obj.greater.push(liang) : obj.less.push(liang)
            } else {
              p ? obj.greater.push(liang) : obj.less.push(liang)
            }
          }
        })
        obj.less = obj.less.join('、')
        obj.greater = obj.greater.join('、')
        return obj
      }
    },
    a() {
      return this.$store.state.report.a
    },
    flow_position_now() {
      // 配置中流程当前选择的方法的value值
      return this.settings.options.flows.find(v => v.label === '位置检验方法').value
    }
  }
}
</script>