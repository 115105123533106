<template>
  <a-card :bordered="false">
    <a-result status="warning" :title="id===1?'量表(问卷)未开始':(id===2?'量表(问卷)已结束':'量表(问卷)已失效')"></a-result>
  </a-card>
</template>
<script>
export default {
    data() {
        return {
            id: Number(this.$route.params.id)
        }
    },
    mounted() {
      // 关闭录入页面
      window.onunload = (e) => {
        // 设置为2
        localStorage.setItem(`watchInput${this.$route.params.qid}${this.$route.params.index}`, 2)
        localStorage.setItem(`watchCurrentIndex${this.$route.params.id}`, this.$route.params.index)
      }
    }
}
</script>