var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "800px",
        maskClosable: false,
        visible: _vm.visible,
        title: "图表信息设置"
      },
      on: {
        ok: function() {
          _vm.$emit("ok")
        },
        cancel: function() {
          _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.data } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "语言" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.data.language,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "language", $$v)
                    },
                    expression: "data.language"
                  }
                },
                [
                  _c("a-radio", { key: 0, attrs: { value: 0 } }, [
                    _vm._v("CN 简体中文")
                  ]),
                  _c("a-radio", { key: 1, attrs: { value: 1 } }, [
                    _vm._v("US English")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "颜色" } },
            [
              _vm._l(_vm.data.colorsArry, function(item, i) {
                return _c("colorPicker", {
                  key: i,
                  model: {
                    value: _vm.data.colorsArry[i],
                    callback: function($$v) {
                      _vm.$set(_vm.data.colorsArry, i, $$v)
                    },
                    expression: "data.colorsArry[i]"
                  }
                })
              }),
              _c("a-button", { on: { click: _vm.setDefault } }, [
                _vm._v("恢复默认")
              ])
            ],
            2
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "图片下载格式" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.data.picformat,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "picformat", $$v)
                    },
                    expression: "data.picformat"
                  }
                },
                [
                  _c("a-radio", { key: 0, attrs: { value: "png" } }, [
                    _vm._v("PNG (.png)")
                  ]),
                  _c("a-radio", { key: 1, attrs: { value: "jpeg" } }, [
                    _vm._v("JPEG (.jpg)")
                  ]),
                  _c("a-radio", { key: 2, attrs: { value: "tiff" } }, [
                    _vm._v("TIFF (.tif)")
                  ]),
                  _c("a-radio", { key: 3, attrs: { value: "bmp" } }, [
                    _vm._v("BMP (.bmp/.dib)")
                  ]),
                  _c("a-radio", { key: 4, attrs: { value: "gif" } }, [
                    _vm._v("GIF (.gif)")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }