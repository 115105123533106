<template>
  <div>
    <div id="head-bar">
      <a-row id="main" type="flex" justify="center" align="middle" style="">
        <a-col>
          <a href="https://www.medspss.com/">
            <img src="@/assets/logo.png" width="50px" />
            <span id="plat-title">MedSPSS</span>
          </a>
        </a-col>
      </a-row>
    </div>
    <a-row style="margin-top: 25px; padding: 0 20px" type="flex" justify="center">
      <!-- 左侧主体 -->
      <a-col id="left-main" :span="24">
        <div id="article-title">上海内环新冠感染率是外环的5.85倍？</div>
        <a-row type="flex" align="middle" id="meta">
          <a-col>
            <span id="tag">原创</span>
            <span id="time"> 2022-03-29 17:20:12 </span>
            <!-- <span id="source">参考消息网</span> -->
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col>
            <img :width="isMobile ? '90%' : '100%'" src="/article-static/扫码关注.png" />
          </a-col>
        </a-row>
        <br />
        <br />
        <div class="help-wrapper">
          <div class="help-logo" style="background: #3a4d80"></div>
          <div class="help-logo-blank"></div>
          <div class="help-logo" style="background: #cfaf43"></div>
          <div class="head-text">
            基于“上海发布”的公众号公布的数据，本文整理了3月8日至3月29日的所有新冠阳性感染者(含确诊病例和无症状感染者)的信息，共26037份。统计了上海各区阳性感染者的人数，阳性感染率和阳性中确诊病例比例(以下简称确诊率)等信息。
          </div>
          <br />
          <div class="head-text">
            同时，为了得出影响上海各区新冠感染率的重要因素，我们收集了诸多相关指标，最终得出各区“人口密度”和区域所在“环线”是影响新冠阳性感染率的显著性因素。
          </div>
          <div style="text-align: right">
            <div class="help-logo" style="background: #3a4d80"></div>
            <div class="help-logo-blank"></div>
            <div class="help-logo" style="background: #cfaf43"></div>
          </div>
        </div>
        <!-- 正文 -->
        <div id="article-content">
          <a-row type="flex" justify="center">
            <a-col>
              <img width="100%" src="/article-static/3_30/动图.gif" />
            </a-col>
          </a-row>
          <div class="title1-blue">
            <span class="number">01</span>
            <span class="text">上海各区累计阳性感染者人数的统计</span>
          </div>
          <a-row type="flex" justify="center">
            <a-col class="center">
              <img :width="isMobile ? '100%' : '60%'" src="/article-static/3_29/上海各区新冠阳性感染人数.png" />
            </a-col>
          </a-row>
          <div class="title1-blue">
            <span class="number">02</span>
            <span class="text">上海各区阳性感染率（每万人分之）</span>
          </div>

          <p>各区中阳性感染率较高的区域为“徐汇区”、“闵行区”、“浦东新区”和“黄浦区”。</p>
          <div class="light-blue-bg">
            <div>“徐汇区”总感染人数虽然排第三，但其阳性感染率最高。</div>
            <br />
            <div>“闵行区”总的感染人数和阳性感染率都排第二。</div>
            <br />
            <div>“浦东新区”总感染人数最多，但由于人口基数大，其阳性感染率排第三。</div>
          </div>
          <a-row type="flex" justify="center">
            <a-col class="center">
              <img :width="isMobile ? '100%' : '60%'" src="/article-static/3_30/各区每万人中阳性感染人数.png" />
            </a-col>
          </a-row>
          <h3>上海各区确诊率</h3>
          <a-row type="flex" justify="center">
            <a-col class="center">
              <img :width="isMobile ? '100%' : '60%'" src="/article-static/3_29/上海各区确诊率排序.png" />
            </a-col>
          </a-row>
          <p>各区中确诊率较高的为<b>“静安区”</b>，<b>“虹口区”</b>和<b>“金山区”</b>。</p>
          <h3>感染率的影响因素分析</h3>
          <p>
            为了研究影响上海新冠阳性感染率的重要影响因素，我们收集到上海市各区房价、GDP、人均GDP、人口、面积、人口密度、购物中心数和环线等指标，对相关数据进行对数处理，通过最小二乘逐步回归分析，最终得到了新冠阳性感染率的重要影响因素。
          </p>
          <a-row type="flex" justify="center">
            <a-col>
              <img width="100%" src="/article-static/3_29/回归分析系数表.png" />
            </a-col>
          </a-row>
          <p>回归分析结果为：</p>
          <p>
            Log_百万分之_感染率＝ 5.5333 － 0.9581 × LOG_人口密度＋ 0.8245 × 环线_外环＋ 1.1445 × 环线_中环＋ 1.5921 ×
            环线_内环。
          </p>
          <p>模型的<i>p</i>值为0.00762 &lt; 0.05，表明模型具有显著性意义。R^2=0.690，显示其相关性高达83%。</p>
          <p>由公式系数可以计算得出以下结论：</p>
          <p>
            <b
            >内环系数(1.5921)＞中环系数(1.1445)＞外环系数(0.8245)，显示越靠近内环，新冠阳性的感染率越高。内环感染率是外环的5.85倍。</b
            >
          </p>
          <p>线性回归结果如下：</p>
          <a-row type="flex" justify="center">
            <a-col>
              <img width="100%" src="/article-static/3_29/线性回归图.png" />
            </a-col>
          </a-row>
          <p>人口数据来源：上海市第七次全国人口普查主要数据公报。</p>
          <p>关注本公众号，可查看每日最新分析结果。</p>
          <a-row type="flex" justify="center">
            <a-col>
              <img width="100%" src="/article-static/shanghai_jiayou.png" />
            </a-col>
          </a-row>
          <!-- 正文结束 -->
          <div class="help-wrapper" style="margin: 10px 0">
            <div style="text-align: right">
              <div class="help-logo" style="background: red"></div>
              <div class="help-logo-blank"></div>
              <div class="help-logo" style="background: red"></div>
              <div class="help-logo-blank"></div>
              <div class="help-logo" style="background: red"></div>
            </div>
            <div class="head-text">
              <div>
                本份数据的统计和分析是基于MedSPSS数据分析平台，由算法自动生成，结果和结论仅做参考，不表达任何观点。
              </div>
              <div>上传数据，您也可以获取自己的报告，敬请联系我们！</div>
              <div>联系邮箱：contacts@swktech.com.cn</div>
              <div>MedSPSS版权所有，欢迎转载。</div>
            </div>
          </div>
        </div>
      </a-col>
      <!-- 下侧主体 -->
      <a-col span="24"></a-col>
      <a-col id="right-main" span="24">
        <a-row type="flex" justify="center">
          <a-col class="center">
            <img :width="isMobile ? '90%' : '100%'" style="border: 1px solid #ccc" src="/article-static/扫码关注.png" />
          </a-col>
        </a-row>
        <a-row style="margin: 10px 0" type="flex" justify="center">
          <a-col> 微信公众号 </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ArtImg from '@/views/article/components/ArtImg'
export default {
  components: { ArtImg },
  created() {
    this.clientWidth = document.body.clientWidth
    console.log('%c 🥩 this.clientWidth: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', this.clientWidth);
  },
  data() {
    return {
      clientWidth: 0
    }
  },
  computed: {
    isMobile() {
      return this.clientWidth <= 578
    },
    imgWidth() {
      return this.clientWidth > 578 ? '70%' : '100%'
    }
  }
}
</script>
<style lang="less">
@import '~@/views/article/components/article.less';
</style>