<template>
  <div class="result-container">
    <div class="imgAndTable">
      <h1>相关性检验结果</h1>
      <a-row v-if="table2.data.length" type="flex" justify="center">
        <a-col>
          <a-table :columns="table2.columns" :dataSource="table2.data" :pagination="false" bordered>
            <div slot="cxz" slot-scope="text">
              <div v-for="(item, index) in text" :key="index">{{ item }}</div>
            </div>
            <div slot="cgd" slot-scope="text">
              <div v-for="(item, index) in text" :key="index">{{ item }}</div>
            </div>
          </a-table>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col>
          <MyImg :imgBytes="img" :fileName="`Pearson相关系数`"></MyImg>
        </a-col>
      </a-row>
      <a-row type="flex">
        <a-col :span="24">
          <a-table :columns="table.columns" :dataSource="table.data" :pagination="false" bordered>
            <div style="text-align: center" slot="title" v-html="translate(`相关性<i>p</i>值`)"></div>
          </a-table>
        </a-col>
      </a-row>
    </div>
    <div class="conclusion">
      <box-shadow-card :title="'相关性检验智能分析结果'">
        <div v-html="conclusionStr"></div>
      </box-shadow-card>
    </div>
  </div>
</template>
<script>
import { MakeReq } from '@/views/hypothesisTest/utils/MakeReqClass'
import { FetchResult } from '@/views/hypothesisTest/utils/fetchResultClass'
import MyTable2 from '../../table'
import { translate } from '@/utils/language'

export default {
  components: { MyTable2 },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      table: {
        columns: [],
        data: []
      },
      table2: {
        columns: [
          {
            title: `显著相关【0.5<|r|≤0.8】`,
            dataIndex: 'cxz',
            scopedSlots: { customRender: 'cxz' },
            align: 'center'
          },
          {
            title: `高度相关【0.8<|r|<1】`,
            dataIndex: 'cgd',
            scopedSlots: { customRender: 'cgd' },
            align: 'center'
          }
        ],
        data: []
      },
      img: '',
      conclusionStr: ''
    }
  },
  methods: {
    fetchResult() {
      const form = this.$store.state.hypothesis.dybtjy
      const method = this.$store.state.hypothesis.method
      const req = new MakeReq(form, method, this).Pearson()
      console.log('%c 🍺 req: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', req);
      const fetchResult = new FetchResult(this, req, 'hypothesis')
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      const { table, table2 } = this
      const { columns, tabdata, imgs, cgd, cxz, greater, less } = res
      table.columns = columns
      table.data = JSON.parse(tabdata)
      this.img = imgs[0]
      // 生成显著相关的表格
      if (cgd.length || cxz.length) {
        const obj = {
          cgd, cxz
        }
        table2.data.push(obj)
      }
      // 生成结论
      const H0 = `差别不具有显著相关性`
      const H1 = `差别具有显著相关性`
      let greaterStr = ``
      let lessStr = ``
      if (greater.length) greaterStr = `${greater.join(`，`)}这些组合的<i>p</i>值 ≥ 0.05，因此不拒绝H0（${H0}）。`
      if (less.length) lessStr = `${less.join(`，`)}这些组合的<i>p</i>值 < 0.05，因此拒绝H0（${H0}），接受H1（${H1}）。`
      const conclusionStr = `由上表可得：${greaterStr}${lessStr}`
      this.conclusionStr = conclusionStr
      return res
    },
    translate(text) {
      return translate(text)
    }
  }
}
</script>