<template>
  <div>
    <loading v-if="loadingController"></loading>
    <div style="padding: 50px 0 0 0">
      <div
        style="position: fixed; top: 0px; width: 100%; height: 50px; background: #001529; z-index: 500; opacity: 0.5"
      >
        <a-row type="flex" justify="end">
          <a-col class="title" style="margin-right: 20px">
            <a-button
              v-print="'#PrintContent'"
              style="font-weight: bold; color: black; margin-top: 10px"
            >打印</a-button
            >
            <a-button @click="cancel" style="margin-left: 10px; color: black; font-weight: bold">取消</a-button>
          </a-col>
        </a-row>
      </div>
      <div id="PrintContent">
        <div style="background: #dedede">
          <div class="pagediv">
            <a-table
              class="base recordTable"
              size="small"
              :columns="columns1"
              :data-source="data1"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template
                slot="title"
              ><span style="font-size: 20px; font-weight: 600">正确度验证（参考方法）</span>
              </template>
            </a-table>
            <a-table
              class="dataTable recordTable"
              size="small"
              :columns="columns2"
              :data-source="data2"
              :pagination="false"
              bordered
            >
              <template slot="title">
                <a-row>
                  <a-col :span="24">
                    <span
                      :class="{ concentration: true, active: currentConcentrationIndex === index }"
                      v-for="(concentration, index) in sampleConcentrations"
                      :key="index"
                      @click="
                        currentConcentrationIndex = index
                        fetch()
                      "
                    >
                      {{ concentration }}%浓度
                    </span>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col
                    :span="24"
                    style="font-weight: 700; background-color: #f7f7f7; border-top: 1px solid #999999"
                  >数据</a-col
                  >
                </a-row>
              </template>
            </a-table>
          </div>
          <div class="pagediv">
            <a-table
              class="recordTable"
              size="small"
              :columns="columns3"
              :data-source="data3"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title">计算</template>
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns4"
              :data-source="data4"
              :pagination="false"
              bordered
            >
            </a-table>
            <a-table
              class="recordTable"
              size="small"
              :columns="columns5"
              :data-source="data5"
              :showHeader="false"
              :pagination="false"
              bordered
            >
              <template slot="title">结论</template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getT } from '@/api/exp/python'
import { List } from '@/api/exp/experimentData'
import { getByID } from '@/api/exp/experiment'
import Loading from '../../components/loading'
const columnWidth = 150
export default {
  components: { Loading },
  created() {
    // this.exp = JSON.parse(this.$route.query.exp)
    const id = Number(this.$route.query.id)
    List({ expID: id }).then(res => {
      const list = res.data
      getByID(id).then(res => {
        this.exp = res
        this.exp.list = list
        console.log('%c 🍔 this.exp: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.exp)
        this.fetch()
      })
    })
    // this.exp = this.$route.query.exp
  },
  data() {
    return {
      loadingController: true,
      currentConcentrationIndex: 0, // 当前选中浓度的下标
      columns1: [],
      data1: [],
      columns2: [],
      data2: [],
      columns3: [],
      data3: [],
      columns4: [],
      data4: [],
      columns5: [],
      data5: [],
      exp: {}
    }
  },
  computed: {
    sampleConcentrations() {
      if (JSON.stringify(this.exp) !== '{}') return JSON.parse(this.exp.sampleConcentrations)
      return []
    }
  },
  methods: {
    async fetch() {
      this.columns1 = [
        {
          title: 'key1',
          key: 'key1',
          dataIndex: 'key1',
          align: 'center'
        },
        {
          title: 'value1',
          key: 'value1',
          dataIndex: 'value1',
          align: 'center'
        },
        {
          title: 'key2',
          key: 'key2',
          dataIndex: 'key2',
          align: 'center'
        },
        {
          title: 'value2',
          key: 'value2',
          dataIndex: 'value2',
          align: 'center'
        },
        {
          title: 'key3',
          key: 'key3',
          dataIndex: 'key3',
          align: 'center'
        },
        {
          title: 'value3',
          key: 'value3',
          dataIndex: 'value3',
          align: 'center'
        }
      ]
      this.data1 = []
      this.columns2 = [{
        title: '',
        key: '',
        dataIndex: 'index',
        align: 'center',
        width: columnWidth
      },
      {
        title: '试验方法结果（Y）',
        key: 'test',
        dataIndex: 'test',
        align: 'center',
        width: columnWidth
      },
      {
        title: '比较方法结果（X）',
        key: 'compare',
        dataIndex: 'compare',
        align: 'center',
        width: columnWidth
      },
      {
        title: 'Y-X',
        key: 'Y-X',
        dataIndex: 'Y-X',
        align: 'center',
        width: columnWidth
      },
      {
        title: 'Y-X-B',
        key: 'Y-X-B',
        dataIndex: 'Y-X-B',
        align: 'center',
        width: columnWidth
      }
      ]
      this.data2 = []
      this.columns3 = [
        {
          title: '',
          key: '',
          dataIndex: 'col_title',
          align: 'center',
          width: columnWidth
        },
        {
          title: '',
          key: 'value',
          dataIndex: 'value',
          align: 'center'
        }
      ]
      this.data3 = []
      this.columns4 = [{
        title: '',
        key: '',
        dataIndex: 'col_title',
        align: 'center'
      },
      {
        title: '偏倚（B）',
        key: 'B',
        dataIndex: 'B',
        align: 'center'
      },
      {
        title: '上限',
        key: 'up',
        dataIndex: 'up',
        align: 'center'
      },
      {
        title: '下限',
        key: 'down',
        dataIndex: 'down',
        align: 'center'
      }
      ]
      this.columns5 = [{
        title: '',
        key: '',
        dataIndex: 'content',
        customRender: (text, record, index) =>
          <span style={`color:${text.indexOf('没落在') === -1 ? '#43c73d' : 'red'};white-space:normal !important`}>
            {text}
          </span>
      }]
      this.fetchData1()
      this.fetchData2()
      // eslint-disable-next-line arrow-spacing
      await this.fetchData3()
      this.fetchData4()
      this.fetchData5()
      this.loadingController = false
    },
    cancel() {
      // this.$router.back()
      window.close()
    },
    // 生成表格所需要的列数，”第1批，第2批“
    getColumns(tableIndex) {
      this.exp.list.forEach((v, index) => {
        this[`columns${tableIndex}`].push({
          title: `第${index + 1}批`,
          key: `第${index + 1}批`,
          dataIndex: `batch${index}`,
          align: 'center'
        })
      })
    },
    // 求批均值、批方差的平均值的公共方法
    avg(obj) {
      const day = this.exp.list.length
      let sum = 0
      for (const i in obj) {
        if (i !== 'key' && i !== 'col_title') {
          sum += Number(obj[i])
        }
      }
      return sum / day
    },
    fetchData1() {
      const exp = this.exp
      this.data1 = [
        {
          key: 0,
          key1: '试剂厂家：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.manufactor).join('、'),
          key2: '仪器：',
          value2: exp.devices.map(v => v.name).join('、'),
          key3: '记录编号：',
          value3: exp.serialNum
        },
        {
          key: 1,
          key1: '项目名称：',
          value1: exp.expName,
          key2: '校准品名称：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.name).join('、'),
          key3: '实验时间：',
          value3: `${this.$moment(exp.list[exp.list.length - 1].date).format('YYYY-MM-DD')}至${this.$moment(exp.list[0].date).format('YYYY-MM-DD')}`
        },
        {
          key: 2,
          key1: '试剂批号：',
          value1: exp.reagents.filter(v => v.type === 0).map(v => v.batchNum).join('、'),
          key2: '校准品批号：',
          value2: exp.reagents.filter(v => v.type === 1).map(v => v.batchNum).join('、'),
          key3: '实验人员：',
          value3: exp.users.map(v => v.name).join('、')
        }
      ]
    },
    fetchData2() {
      const list = this.$deepClone(this.exp.list)
      // 给data2赋值。
      list.forEach((v, index) => {
        const data = JSON.parse(v.dataDetails[this.currentConcentrationIndex].concentrationValue)
        const obj = {
          key: index,
          index: index + 1,
          test: data[0].toFixed(2),
          compare: data[1].toFixed(2),
          'Y-X': (data[0] - data[1]).toFixed(2),
          'Y-X-B': 0
        }
        this.data2.push(obj)
      })
    },
    async fetchData3() {
      const n = this.exp.list.length
      // Y均值和X均值 a是累加的value,b是当前obj。
      const YAvg = this.data2.reduce((a, b) => a + Number(b.test), 0) / n
      const XAvg = this.data2.reduce((a, b) => a + Number(b.compare), 0) / n
      // 差值(B)
      const B = YAvg - XAvg
      this.B = B
      let sum = 0
      this.data2.forEach(v => {
        v['Y-X-B'] = (v.test - v.compare - B).toFixed(2)
        sum += Number(v['Y-X-B'])
      })
      // 计算SDdiff差值标准差
      const avg = sum / n
      const std = Math.sqrt((this.data2.map(v => Math.pow(Number(v['Y-X-B']) - avg, 2)).reduce((a, b) => a + b, 0)) / (n - 1))
      await getT({ freedom: n - 1, p: 1 - 0.01 / 2 }).then(res => {
        const t = res.data
        this.data3 = [
          {
            key: 'Y均值',
            col_title: 'Y均值',
            value: YAvg.toFixed(2)
          },
          {
            key: 'X均值',
            col_title: 'X均值',
            value: XAvg.toFixed(2)
          },
          {
            key: '差值标准差（SDdiff）',
            col_title: '差值标准差（SDdiff）',
            value: std.toFixed(2)
          },
          {
            key: 'p',
            col_title: 'p',
            value: 0.01
          },
          {
            key: 't',
            col_title: 't',
            value: t.toFixed(3)
          }
        ]
      })
    },
    fetchData4() {
      const t = this.data3[4].value
      const n = this.exp.list.length
      const Sdiff = this.data3[2].value
      const B = this.B
      const expUp = B + t * Sdiff / Math.sqrt(n)
      const expDown = B - t * Sdiff / Math.sqrt(n)
      const BClaim = this.exp.b
      const claimUp = BClaim + t * Sdiff / Math.sqrt(n)
      const claimDown = BClaim - t * Sdiff / Math.sqrt(n)
      this.data4 = [{
        key: 'exp',
        col_title: '实验结果',
        B: B.toFixed(2),
        up: expUp.toFixed(2),
        down: expDown.toFixed(2)
      },
      {
        key: 'claim',
        col_title: '厂家声明结果',
        B: BClaim.toFixed(2),
        up: claimUp.toFixed(2),
        down: claimDown.toFixed(2)
      }]
    },
    fetchData5() {
      const B = this.data4[0].B
      const up = this.data4[1].up
      const down = this.data4[1].down
      const unit = 'mmol/L'
      let content = ''
      if (B >= down && B <= up) {
        content = `实验计算的差值（“偏倚”）${B}${unit}落在厂家声明的验证限之中(${down}${unit}~${up}${unit})，证明了厂家的声明，因此也证明数据与厂家的声明一致`
      } else {
        content = `实验计算的差值（“偏倚”）${B}${unit}没落在厂家声明的验证限之中(${down}${unit}~${up}${unit})，因此也证明数据与厂家的声明不一致`
      }
      this.data5 = [{
        key: 0,
        content
      }]
    }
  }
}
</script>
<style lang="less">
@boder:1px solid #999999 !important;
.base .ant-table-title {
  text-align: center !important;
}
// .ant-table-title {
//   top: 0;
// }
.concentration {
  font-weight: 700;
  padding: 6px 5px;
  margin-right: 10px;
  cursor: pointer;
}
.active {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.pagediv {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  background: white;
}
.dataTable .ant-table-title {
  padding: 0 0 !important;
  .ant-col {
    padding: 8px 16px;
  }
}
.recordTable {
  td {
    border-right: @boder;
    border-bottom: @boder;
  }
  td:last-child {
    border-right: 0 !important;
  }
  th {
    border-bottom: @boder;
    border-right: @boder;
  }
  th:last-child {
    border-right: 0 !important;
  }
  .ant-table-title {
    font-weight: 700;
    background-color: #f7f7f7;
    border-right: @boder;
    border-bottom: @boder;
    //  border-top:@boder;
    border-radius: 0;
  }
  .ant-table-content {
    border-right: @boder;
  }
  .ant-table {
    border-top: @boder;
    border-left: @boder;
    // border-bottom:@boder;
    border-radius: 0;
  }
}
@page {
  size: auto A4 landscape;
  margin: 5mm;
}
</style>