<template>
  <a-form-model
    :model="form"
    labelAlign="left"
    :rules="rules"
    ref="ruleForm"
    :labelCol="{ span: 7 }"
    :wrapperCol="{ span: 10 }"
  >
    <div class="documentItemWrapper">
      <h1>基本信息</h1>
      <a-row :gutter="50">
        <a-col v-show="false" :span="8">
          <a-form-model-item label="id">
            <a-input v-model="form.id" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="编号" prop="serialNo">
            <a-input placeholder="请输入编号" v-model="form.serialNo" style="width: 220px" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="姓名">
            <div v-if="!hideInput.name">
              {{ form.name | hideName
              }}<a-icon type="edit" theme="twoTone" style="margin-left: 10px" @click="hideInput.name = true" />
            </div>
            <a-input v-if="hideInput.name" placeholder="请输入姓名" style="width: 220px" v-model="form.name">
              <a-icon @click="clickIcon('name')" slot="addonAfter" type="save" theme="twoTone" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="性别">
            <a-radio-group v-model="form.gender">
              <a-radio :value="0">男</a-radio>
              <a-radio :value="1">女</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="出生日期">
            <a-date-picker
              style="width: 220px"
              placeholder="请选择出生日期"
              @change="onBirthdayChange"
              v-model="form.birthDay"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item :class="typeof age === 'string' ? 'ageHelp' : ''" label="年龄">
            <span>{{ age }}</span>
            <span v-show="typeof age != 'string'" style="margin-left: 5px">岁</span>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="手机号" prop="mobile">
            <div v-if="!hideInput.mobile">
              {{ form.mobile | hideMobilePhone
              }}<a-icon type="edit" theme="twoTone" style="margin-left: 10px" @click="hideInput.mobile = true" />
            </div>
            <a-input
              v-if="hideInput.mobile"
              placeholder="请输入手机号"
              style="width: 220px"
              :maxLength="11"
              v-model="form.mobile"
            >
              <a-icon @click="clickIcon('mobile')" slot="addonAfter" type="save" theme="twoTone" />
            </a-input>
          </a-form-model-item>
        </a-col>

        <a-col :span="8">
          <a-form-model-item label="籍贯" prop="hometown">
            <a-cascader
              :options="options"
              :field-names="{ label: 'name', value: 'code', children: 'children' }"
              expand-trigger="hover"
              placeholder="请选择"
              v-model="form.hometown"
            />
          </a-form-model-item>
        </a-col>

        <a-col :span="8">
          <a-form-model-item label="文化程度">
            <a-select
              show-search
              placeholder="请选择文化程度"
              v-model="form.education"
              style="width: 220px"
              :dropdownMatchSelectWidth="false"
            >
              <a-select-option v-for="education in educations" :key="education.key" :value="education.key">{{
                education.value
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="民族">
            <a-select show-search placeholder="请选择民族" v-model="form.minority" style="width: 220px">
              <a-select-option v-for="minority in minorities" :key="minority.id" :value="minority.id">{{
                minority.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="职业">
            <a-select
              show-search
              placeholder="请选择职业"
              v-model="form.occupation"
              style="width: 220px"
              :dropdownMatchSelectWidth="false"
            >
              <a-select-option v-for="ocupation in ocupations" :key="ocupation.key" :value="ocupation.key">
                <span>{{ ocupation.value }}</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="8">
          <a-form-model-item label="居住情况">
            <a-select show-search placeholder="请选择居住情况" v-model="form.stayWith" style="width: 220px">
              <a-select-option v-for="stayWith in stayWiths" :key="stayWith.key" :value="stayWith.key">{{
                stayWith.value
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="生活满意度">
            <a-select show-search placeholder="请选择生活满意度" v-model="form.lifeSatisfaction" style="width: 220px">
              <a-select-option
                v-for="satisfaction in satisfactions"
                :key="satisfaction.key"
                :value="satisfaction.key"
              >{{ satisfaction.value }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="婚姻情况">
            <a-select show-search placeholder="请选择婚姻情况" v-model="form.marry" style="width: 220px">
              <a-select-option v-for="marry in marries" :key="marry.key" :value="marry.key">{{
                marry.value
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="现居住地">
            <a-cascader
              :options="options"
              :field-names="{ label: 'name', value: 'code', children: 'children' }"
              expand-trigger="hover"
              placeholder="请选择"
              v-model="form.address"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="详细地址">
            <a-input style="width: 220px" placeholder="请输入详细的居住地址" v-model="form.detailAddress" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </div>
    <div class="documentItemWrapper" id="secondWrapper">
      <h1>生活方式及社会因素</h1>
      <a-row>
        <a-col v-for="lifeStyle in lifeStyles" :key="lifeStyle.key" :span="12">
          <div class="lifeStyleItem">
            <a-form-model-item
              :label="lifeStyle.keyName"
              :labelCol="handleCol(lifeStyle.keyName, 'label')"
              :wrapperCol="handleCol(lifeStyle.keyName, 'wrapper')"
            >
              <a-radio-group v-model="form[lifeStyle.key]">
                <a-radio v-for="selectItem in lifeStyle.valueName" :key="selectItem.id" :value="selectItem.id">{{
                  selectItem.name
                }}</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-form-model>
</template>
<script>
import { minorities, educations, ocupations, satisfactions, marries, stayWiths, lifeStyles } from '@/common/enums'
import { getDocument, updateDocument, getDocByNum, updateNameOrMobile } from '@/api/document'
import { test } from '@/common/test'

export default {
  created() {
    this.getDocumentById()
  },
  props: ['isSave'],
  data() {
    return {
      hideInput: {
        name: false,
        mobile: false
      },
      form: {},
      rules: {
        serialNo: [
          { required: true, message: '请输入编号', whitespace: true },
          { pattern: '^.{4,}$', message: '请填写至少4位的编号' },
          { validator: this.onlySeriaNo }]
      },
      lifeStyleSpan: {
        labelCol1: { md: { span: 6 }, xxl: { span: 6 } },
        labelCol2: { md: { span: 0 }, xxl: { span: 10 } },
        wrapperCol1: { md: { span: 18 }, xxl: { span: 16 } },
        wrapperCol2: { md: { span: 0 }, xxl: { span: 10 } }
        },
      age: '根据出生日期自动计算',
      minorities,
      educations,
      ocupations,
      satisfactions,
      marries,
      stayWiths,
      options: test,
      lifeStyles,
      serialNo: 0 // 用来保存原始的serialNo
    }
  },
  filters: {
    hideName(value) {
      if (value) {
        if (value.length === 2) {
          value = value.slice(0, 1)
          return value + '*'
        } else if (value.length === 1) {
          return value
        } else if (value.length >= 3) {
          const value1 = value.slice(0, 1)
          const value2 = value.slice(value.length - 1, value.length)
          const length = value.length - 2
          let xx = ''
          for (let i = 0; i < length; i++) {
            xx = xx + '*'
          }
          return value1 + xx + value2
        }
      } else { return value }
    },
    hideMobilePhone(value) {
      if (value) {
        var value1 = value.slice(0, 3)
        if (value.length >= 4 && value.length <= 7) {
          var x = ''
          for (var i = 0; i < value.length - 3; i++) {
            x += '*'
          }
          return value1 + x
        } else if (value.length > 7) {
          var value2 = value.slice(7, value.length)
          return value1 + '****' + value2
        } else { return value }
      } else { return value }
    }
  },
  methods: {
    getDocumentById() {
      getDocument(this.$route.params.id).then(res => {
        this.$refs.ruleForm.clearValidate()
        console.log('res', res.birthDay)
        res.birthDay = res.birthDay && this.$moment(res.birthDay)
        res.address = JSON.parse(res.address)
        res.hometown = JSON.parse(res.hometown)
        for (var key in res) {
          if (res[key] === null) res[key] = undefined
        }
        console.log('基本信息', res)
        this.form = res
        this.$store.dispatch('saveCurrentUserId', res.id)
        // 年龄根据生日自动计算
        this.age = this.form.birthDay && this.getAge(this.form.birthDay._d) || '根据出生日期计算'
        this.serialNo = res.serialNo
        // 改变手机、姓名的状态控制
        this.hideInput.name = false
        this.hideInput.mobile = false
      })
    },
    clickIcon(key) {
      updateNameOrMobile({
        id: this.form.id,
        key: key,
        value: this.form[key]
      }).then(res => {
        this.hideInput[key] = false
        this.$emit('refreshTable')
      })
    },
    getAge(birthday) {
      const TodayDate = new Date()
      const dateDif = TodayDate.getDate() - birthday.getDate()
      const year = TodayDate.getFullYear() - birthday.getFullYear()
      return dateDif >= 0 ? year : year - 1
    },
    onBirthdayChange(date) {
      this.age = this.getAge(date._d)
      console.log(date)
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var form = this.$deepClone(this.form)
          form.birthDay = this.form.birthDay && this.form.birthDay.format()
          console.log('put基本信息', form)
          form.address = JSON.stringify(form.address)
          form.hometown = JSON.stringify(form.hometown)
          updateDocument(form).then(res => {
            // 这个参数用来判断完成了几个请求，以便重新刷新页面
            this.$store.dispatch('addfinishedReqCount')
            this.$emit('refreshTable')
          })
        }
      })
    },
    // date转dateString
    transferDate(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      var dateString = year + '-' + month + '-' + day
      return dateString
    },
    onlySeriaNo(rule, value, callback) {
      if (value) {
        if (value === this.serialNo) {
          callback()
        } else {
          getDocByNum({
            oldName: this.serialNo,
            newName: value
          }).then(res => {
            this.$log('编号已存在', res)
            if (res) { callback('编号已存在') } else { callback() }
          })
        }
      }
    },
    handleCol(name, labelOrWrapper) {
        if (name.length > 8) {
          return labelOrWrapper === 'label' ? { md: { span: 10 }, xxl: { span: 6 } } : { md: { span: 14 }, xxl: { span: 18 } }
        } else if (name.length >= 5 && name.length <= 8) {
          return labelOrWrapper === 'label' ? { md: { span: 7 }, xxl: { span: 4 } } : { md: { span: 17 }, xxl: { span: 20 } }
        } else {
          return labelOrWrapper === 'label' ? { md: { span: 4 }, xxl: { span: 3 } } : { md: { span: 20 }, xxl: { span: 21 } }
        }
    }
  },
  watch: {
    isSave() {
      this.handleSubmit()
    },
    $route() {
      this.getDocumentById()
    },
    finishedReqCount(value) {
      if (value === 3) {
        this.getDocumentById()
      }
    }
  }
}
</script>
<style lang="less" scoped>
h1 {
  padding: 0 0 0 10px;
  width: 180px;
  background-color: rgb(232, 232, 232);
}
.documentItemWrapper {
  margin-bottom: 30px;
}
.lifeStyleItem {
  padding: 5px 0 5px 10px;
}
#secondWrapper .ant-col {
  border: 1px solid rgb(232, 232, 232);
  margin-bottom: -1px;
  margin-right: -1px;
}
.ageHelp {
  font-size: 8px;
  color: red;
}
.ant-cascader-picker {
  width: 220px !important;
}
</style>
