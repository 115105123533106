<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }">
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item hidden>
          <a-input v-decorator="['id']"></a-input>
        </a-form-item>
        <a-form-item label="设备名称">
          <a-input v-decorator="['name',{rules:[{required:true,message:'请填写设备名称'}]}]" placeholder="请填写设备名称"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import pick from 'lodash.pick'
// 表单字段
const fields = ['id', 'name']

export default {
  // props为父组件传过来的参数
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    title: {
      type: String,
      default: () => '新增设备'
    }
  },
  // 当前页面需要的参数
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created')

    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
