export const getBartlettTable = (context) => {
  const columns = [{
      dataIndex: 'one',
      key: 'one',
      align: 'center',
      customRender(text, row, index) {
        const obj = {
          children: text,
          attrs: {}
        };
        if (index === 0) obj.attrs.colSpan = 2
        if (index === 1) obj.attrs.rowSpan = 2
        if (index === 2) obj.attrs.rowSpan = 0
        return obj
      }
    },
    {
      dataIndex: 'two',
      key: 'two',
      align: 'center',
      customRender(text, row, index) {
        const obj = {
          children: text,
          attrs: {}
        };
        if (index === 0) obj.attrs.colSpan = 0
        return obj
      }
    },
    {
      dataIndex: 'three',
      key: 'three',
      align: 'center'
    }
  ]
  const data = [{
      key: 0,
      one: 'KMO',
      two: 'KMO',
      three: context.KMO.toFixed(3)
    },
    {
      key: 1,
      one: 'Bartlett球形度检验',
      two: '卡方值',
      three: context.chiSquare.toFixed(3)
    },
    {
      key: 2,
      one: 'Bartlett球形度检验',
      two: '显著性',
      three: context.pValue.toFixed(3)
    }
  ]
  return {
    columns,
    data,
    showHeader: false,
    title: 'KMO和巴特利特检验',
    size: 'small'

  }
}

// 总方差解释表
export const getZfcjsb = (context) => {
  // 特征值中的columns
  const childColumns = context.columns
  // 提取载荷平方和中的columns,这里重新生成一下是为了把key、dataIndex与上面的columns里的key、dataIndex区分开。
  const childColumns2 = context.columns.map(v => ({
    title: v.title,
    dataIndex: v.dataIndex + '2',
    key: v.key + '2',
    align: v.align
  }))
  const columns = [{
      title: '组件',
      dataIndex: 'key',
      key: 'key',
      align: 'center'
    },
    {
      title: '特征值',
      children: childColumns
    },
    {
      title: '提取载荷平方和',
      children: childColumns2
    }
  ]
  const data = context.data
  data.forEach((v, i) => {
    // key值都+1
    v.key = v.key + 1
    for (const key in v) {
      const value = v[key]
      if (key !== 'key') {
        // 数值都保留三位小数
        v[key] = value.toFixed(3)
        // 拷贝特征值里的数据到提取载荷平方和里
        if (i < context.number) v[key + '2'] = v[key]
      }
    }
  })
  return {
    columns,
    data,
    showHeader: true,
    title: '总方差解释表',
    number: context.number,
    size: 'small'
  }
}

// 载荷系数表
export const getZhxsb = (context) => {
  console.log('%c 🥝 context: ', 'font-size:20px;background-color: #F5CE50;color:#fff;', context);
  context.columns.forEach(v => {
    v.title = v.title + 1
  })
  const columns = [{
      title: '',
      dataIndex: 'index',
      key: 'index',
      align: 'center'
    },
    {
      title: '成分',
      children: context.columns,
      align: 'center'
    }
  ]
  const data = context.data
  data.forEach((v, i) => {
    for (const key in v) {
      v[key] = v[key].toFixed(3)
    }
    // 给data加上变量名那一列
    v.index = context.index[i]
  })
  return {
    columns,
    data,
    showHeader: true,
    title: '载荷系数表',
    size: 'small'
  }
}

// 主成分得分系数表
export const getZcfdfxsb = (context) => {
  console.log('%c 🍫 context: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', context);
  context.columns.forEach(v => {
    v.title = v.title + 1
  })
  const columns = [{
      title: '',
      dataIndex: 'index',
      key: 'index',
      align: 'center'
    },
    {
      title: '成分',
      children: context.columns,
      align: 'center'

    }
  ]
  const data = context.data
  data.forEach((v, i) => {
    for (const key in v) {
      v[key] = v[key].toFixed(3)
    }
    // 给data加上变量名那一列
    v.index = context.index[i]
  })
  return {
    columns,
    data,
    showHeader: true,
    title: '主成分得分系数表',
    size: 'small'
  }
}

export default {
  getBartlettTable,
  getZfcjsb,
  getZhxsb
}