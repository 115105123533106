const document = {
  state: {
    documents: {},
    docList: [],
    currentUserId: null,
    // 计算BMI需要的字段id值
    examIds: null,
    finishedReqCount: 0,
    filters: {
      without0: false,
      outlier: false
    }
  },

  mutations: {
    SET_DOCUMENTS: (state, documents) => {
      state.documents = documents
    },
    SAVE_BASE_INFORMATION(state, n) {
      state.documents = n
    },
    SAVE_CURRENT_USERID(state, id) {
      state.currentUserId = id
    },
    SAVE_EXAM_ID(state, list) {
      state.examIds = list
    },
    SAVE_FINISHED_REQ_COUNT(state) {
      state.finishedReqCount++
    },
    CLEAR_FINISHED_REQ_COUNT(state) {
      state.finishedReqCount = 0
    },
    SAVE_DOC_LIST(state, list) {
      state.docList = list
    },
    CHANGE_OUTLIER(state) {
      state.filters.outlier = !state.filters.outlier
    }
  },

  actions: {
    // 登录
    selectDocuments({
      commit
    }, docs) {
      commit('SET_DOCUMENTS', docs)
    },
    saveBaseInformation(context, n) {
      context.commit('SAVE_BASE_INFORMATION', n)
    },
    saveCurrentUserId(context, id) {
      context.commit('SAVE_CURRENT_USERID', id)
    },
    saveExamId(context, list) {
      context.commit('SAVE_EXAM_ID', list)
    },
    addfinishedReqCount(context) {
      context.commit('SAVE_FINISHED_REQ_COUNT')
    },
    clearFinishedReqCount(context) {
      context.commit('CLEAR_FINISHED_REQ_COUNT')
    },
    saveDocList(context, list) {
      context.commit('SAVE_DOC_LIST', list)
    },
    changeOutlier(context) {
      context.commit('CHANGE_OUTLIER')
    }
  }
}

export default document