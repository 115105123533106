import UserLayout from './UserLayout'
import PersonLayout from './PersonLayout'
import PersonLoginLayout from './PersonLoginLayout'
import BlankLayout from './BlankLayout'
import BasicLayout from './BasicLayout'
import RouteView from './RouteView'
import PageView from './PageView'

export {
  PersonLoginLayout,
  PersonLayout,
  UserLayout,
  BasicLayout,
  BlankLayout,
  RouteView,
  PageView
}