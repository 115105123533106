<template>
  <div>
    <div v-for="(obj, index) in type" :key="index" :id="`3.${index + 1} ${obj.text}`">
      <div ref="title" class="second-title">3.{{ index + 1 }} {{ obj.text }}</div>
      <component
        ref="content"
        :is="obj.value"
        :data="data[index]"
        :xys="xys[index]"
        :secondIndex="index + 1"
        :settings="get_settings_obj(obj.value)"
        class="second-module"
      ></component>
    </div>
  </div>
</template>
<script>
import Variance from './variance'
import ChiSquare from './chiSquare'
import Means from './means'
import OrdinaryLeastSquares from './ordinaryLeastSquares'
import GeneralizedOrdinaryLeastSquares from './generalizedOrdinaryLeastSquares'
import RobustRegression from './robustRegression'
import position_for_two_liang from './positionForTwoLiang'
import DoubleVariance from './doubleVariance'
import Logic from './logic'
export default {
  name: 'IntelligentAnalysis',
  components: { Variance, ChiSquare, Means, OrdinaryLeastSquares, GeneralizedOrdinaryLeastSquares, RobustRegression, position_for_two_liang, DoubleVariance, Logic },
  props: ['data', 'type', 'xys', 'settings'],
  methods: {
    get_settings_obj(value) {
      return this.settings.find((v) => v.value.indexOf(value) !== -1)
    }
  }
}
</script>
<style>
.third-module {
  margin: 15px 0;
}
</style>