var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("global-footer", {
    staticClass: "footer custom-render",
    staticStyle: { display: "none" },
    scopedSlots: _vm._u([
      {
        key: "links",
        fn: function() {
          return [
            _c("span", [
              _c("img", { attrs: { src: "/gongan.png" } }),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021402001617",
                    target: "_blank"
                  }
                },
                [_vm._v("苏公网安备 32021402001617号")]
              )
            ]),
            _c("a-divider", { attrs: { type: "vertical" } }),
            _c(
              "a",
              {
                attrs: { href: "https://beian.miit.gov.cn", target: "_blank" }
              },
              [_vm._v("苏ICP备11090926号-3")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "copyright",
        fn: function() {
          return [
            _c("div", { staticClass: "copyright" }, [
              _vm._v("Copyright © 2020 无锡市优特科科技有限公司")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }