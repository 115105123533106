import {
  asyncRouterMap,
  constantRouterMap
} from '@/config/router.config'
/**
 * 过滤帐户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission(permission, route) {
  if (route.meta && route.meta.permission) {
    let flag = false
    flag = route.meta.permission.includes(permission)
    if (flag) {
      return true
    }

    return false
  }
  return true
}

/**
 * 单帐户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}

function filterAsyncRouter(routerMap, roles, org) {
  const accessedRouters = routerMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.path === '/document') { // 档案是否显示，权限判断
        if ((org && org.isShowDoc) || // 有机构且isShowDoc=true
          (!org) || // 无机构
          (org && org.isShowDoc === null)) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.path === '/data') { // 问卷是否显示，权限判断
        if (((org && org.isShowQue) || // 有机构且isShowDoc=true
            (!org) || // 无机构
            (org && org.isShowQue === null)) || ((org && org.isShowFile) || // 有机构且isShowDoc=true
            (!org) || // 无机构
            (org && org.isShowFile === null))) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.path === '/experiment') {
        if ((org && org.isShowExperiment) || // 有机构且isShowDoc=true
          (!org) || // 无机构
          (org && org.isShowExperiment === null)) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.path === '/hypothesistest') { // 假设检验是否显示，权限判断
        if (((org && org.isShowHypothesis) || // 有机构且isShowDoc=true
            (!org) || // 无机构
            (org && org.isShowHypothesis === null))) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.path === '/analysisOfRegression') { // 回归分析是否显示，权限判断
        if (((org && org.isShowRegressionAnalysis) || // 有机构且isShowDoc=true
            (!org) || // 无机构
            (org && org.isShowRegressionAnalysis === null))) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.path === '/intelligenceReports') { // 报告是否显示，权限判断
        if (((org && org.isShowReport) || // 有机构且isShowDoc=true
            (!org) || // 无机构
            (org && org.isShowReport === null))) { // 有机构且isShowDoc=null
          route.hidden = false
        } else {
          route.hidden = true
        }
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles, org)
      }

      return true
    }

    return false
  })
  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    async GenerateRoutes({
      commit
    }, data) {
      await new Promise(resolve => {
        const {
          role,
          org
        } = data
        const accessedRouters = filterAsyncRouter(asyncRouterMap, role, org)
        commit('SET_ROUTERS', accessedRouters)
        commit('')
        resolve()
      })
    }
  }
}

export default permission