import {
  onChange,
  onClose
} from '@/views/analysisOfRegression/utils/inputItemsClass'
var xysName = 'fittingXys'

export const options = [{
    className: '指数',
    children: [{
        label: '指数1: y = a·e<sup>bx</sup>',
        argNumber: 2,
        value: 'exponential_1'
      },
      {
        label: '指数2: y = a·b<sup>x</sup> + c',
        argNumber: 3,
        value: 'exponential_2'
      },
      {
        label: '指数3: y = a·e<sup>bx</sup> + c·e<sup>dx</sup>',
        argNumber: 4,
        value: 'exponential_3'
      },
      {
        label: '负指数函数: y = a·e<sup>b/x</sup>',
        argNumber: 2,
        value: 'negative_exponential'
      }
    ]
  },
  {
    className: '对数',
    children: [{
        label: '半对数(10为底): y = a + b·log(x)',
        argNumber: 2, // 系数个数
        value: 'semi_log_base_10'
      },
      {
        label: '半对数(e为底): y = a + b·ln(x)',
        argNumber: 2,
        value: 'semi_log_base_e'
      },
      {
        label: 'Log-Log: log(y) = a + b·log(x)',
        argNumber: 2,
        value: 'log_log'
      }
    ]
  },
  {
    className: 'S型曲线',
    children: [{
        label: 'S型曲线: y = 1/(a + be<sup>-x</sup>\)',
        argNumber: 2,
        value: 'S_type_curve'
      },
      {
        label: '双曲线函数: y = e<sup>a + b/x</sup>',
        argNumber: 2,
        value: 'hyperbolic'
      },
      {
        label: 'Weibull_1: y = a·bx<sup>b-1</sup>·e<sup>-ax<sup>b</sup></sup>',
        argNumber: 2,
        value: 'Weibull_1'
      },
      {
        label: 'Weibull_2: y = a - b·e<sup>-c·x<sup>d</sup></sup>',
        argNumber: 4,
        value: 'Weibull_2'
      },
      {
        label: 'logistic: y = a/(1 + be<sup>-cx</sup>)',
        argNumber: 3,
        value: 'logistic'
      },
      {
        label: 'Gomperty: y = ae<sup>-be<sup>-cx</sup></sup>',
        argNumber: 3,
        value: 'Gomperty'
      },
      {
        label: 'Richards: y = a/(1 + e<sup>b - cx</sup>)<sup>1/d</sup>',
        argNumber: 4,
        value: 'Richards'
      }
    ]
  },
  {
    className: '幂',
    children: [{
        label: '四参数方程: y = (a - d) / [1 + (x/c)<sup>b</sup>] + d',
        argNumber: 4,
        value: 'four_parameter'
      },
      {
        label: '幂函数1: y = ax<sup>b</sup>',
        argNumber: 2,
        value: 'power_1'
      },
      {
        label: '幂函数2: y = ax<sup>b</sup> + c',
        argNumber: 3,
        value: 'power_2'
      }
    ]
  },
  {
    className: 'sin',
    children: [{
        label: 'sin_1: y = a·sin(bx + c)',
        argNumber: 3,
        value: 'sin_1'
      },
      {
        label: 'sin_2: y = a·sin(bx + c) + d·sin(fx + g)',
        argNumber: 6,
        value: 'sin_2'
      }
    ]
  },
  {
    className: '高斯',
    children: [{
        label: '高斯1: y = a·e<sup>-((x-b）/c)<sup>2</sup></sup>',
        argNumber: 3,
        value: 'Gauss_1'
      },
      {
        label: '高斯2: y = a·e<sup>-((x-b)/c)<sup>2</sup></sup> + d·e<sup>-((x-f)/g)<sup>2</sup></sup>',
        argNumber: 6,
        value: 'Gauss_2'
      }
    ]
  },
  {
    className: '其他',
    children: [{
      label: 'Fourier: y = a + b·cos(cx) + d·sin(cx)',
      argNumber: 4,
      value: 'Fourier'
    }]
  }
]

export const settings = [{
    id: 0,
    index: 0,
    prop: 'independentVariable',
    label: '自变量X【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    rules: [
      (callback, v, divHelp) => {
        if (v.list.every(v => v.type === '类')) callback(divHelp[0])
      }
    ],
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
    }
  },
  {
    id: 1,
    index: 1,
    prop: 'dependentVariable',
    label: '因变量y【定量】', // 字段名(必填项)
    parentValue: 1, // 当父对象的value为1时，则显示该input(必填项)
    type: 'drop', // input的类型是什么，drop为拖拽框，dropSelect为动态拖拽框，根据拖入的变量来决定最终的属性(必填项)
    dropType: ['量'], // 当前input如果为dropType类型，则为必填项，那么只能拖入定类变量
    num: 1, // 当前input如果为dropType类型，则为必填项，那么只能拖入1个变量
    value: '', // 用来决定children列表里哪些input是显示的
    options: [], // 当前input如果为dropType类型，则为必填项，里面应该为多个属性对象
    list: [], // 当前input如果为dropType类型，则为必填项，拖拽的变量都存在这个list中
    divHelp: ['请拖入左侧变量：(个数＝1个)'], // 当前input如果为dropType类型，则为必填项，input里的提示文字信息
    children: [], // 必填项1
    onChange(obj, item, that) {
      onChange.liang(that.$store.state.analysisOfRegression[xysName], this.index, item)
    },
    onClose(obj, item, that, index) {
      onClose.liang(that.$store.state.analysisOfRegression[xysName], this.index, index)
    }
  },
  {
    label: '公式',
    prop: 'formula',
    optionsColLg: 8,
    optionsColSm: 12,
    parentValue: 1,
    type: 'component',
    componentName: 'CheckBox',
    rules() {
      return [{
        validator: (rule, value, callback) => {
          if (value.length > 0 && value.length < 4) {
            callback()
          } else {
            callback(new Error('请选择1~3个公式'))
          }
        },
        trigger: 'change'
      }]
    },
    value: [],
    options: options,
    children: []
  }
]