<template>
  <a-layout class="divContent" id="divContent">
    <a-row>
      <a-col :span="screenWidth > 1080 ? 3 : 4">
        <div class="table-page-search-wrapper divContentleft" id="divContentleft">
          <div>
            <div class="table-operator">
              <span>筛选变量</span>
            </div>
            <a-table
              ref="table"
              size="small"
              rowKey="key"
              :columns="columns"
              :data-source="data"
              :pagination="false"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 30 }"
              :scroll="{ y: scrolly }"
            ></a-table>
            <!-- <a-button type="primary" style="margin-top:10px;float:right" @click="submitChoose">确认选择</a-button> -->
          </div>
        </div>
      </a-col>
      <a-col :span="screenWidth > 1080 ? 21 : 20">
        <a-layout-content style="padding: 20px 20px; margin: 0 auto">
          <router-view v-if="isRouterAlive"></router-view>
        </a-layout-content>
      </a-col>
    </a-row>
  </a-layout>
</template>
<script>
import { getFields } from '@/api/screendata'
import { AutoHeight } from '@/utils/util'

const columns = [
  {
    title: '变量名',
    dataIndex: 'fieldName',
    key: 'key'
  }
]
export default {
  components: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    this.columns = columns
    return {
      screenWidth: document.body.clientWidth,
      // 查询参数
      queryParam: {},
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        console.log('loadData request parameters:', requestParameters)
      },
      pagination: {
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: false, // 是否可以快速跳转至某页
        showSizeChanger: false // 是否可以改变 pageSize
      },
      // 是否点击了新建按钮
      isNewAdd: false,
      selectedRowKeys: [],
      data: [],
      isRouterAlive: true,
      scrolly: window.screen.height > 800 ? 550 : 400
    }
  },
  created() {
    this.autoHeightChange()
    const fields = this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields)
    const type = this.$store.state.samples.type
    const pathname = window.location.pathname
    if (type === 'document') {
      if (fields && fields.length > 0) {
        fields.map(r => {
          this.selectedRowKeys.push(r.key)
        })
      }
    }
    getFields({ orgid: this.$store.state.user.info.orgid }).then(res => {
      this.data = res
      // 如何type有值了，说明他已经选过了不是第一次了，按照session里的来
      if (type === 'document') {
        if (fields && fields.length > 0) {
          this.$store.dispatch('updateSamples', {
            fields: JSON.stringify(res.filter(r => this.selectedRowKeys.indexOf(r.key) > -1))
          })
        }
      }
      // type没有值，说明是第一次，第一次默认选中所有的变量
      // if (!type || type === 'questionnaire') {
      //   res.map(r => {
      //     this.selectedRowKeys.push(r.key)
      //   })
      //  this.$store.dispatch('updateSamples', { fields: JSON.stringify(res) })
      //  this.$store.dispatch('updateSamples', { type: 'document' })
      //   this.submitChoose()
      // }
    })
    console.log('fields', this.$store.state.samples.fields && JSON.parse(this.$store.state.samples.fields))

    if (this.selectedRowKeys.length === 0) {
      if (pathname.indexOf('hypothesistest') > -1) {
        this.$router.push('/hypothesistest/screendata/document/nodata')
      } else if (pathname.indexOf('customchart') > -1) {
        this.$router.push('/customchart/screendata/document/nodata')
      } else if (pathname.indexOf('intelligenceReports') > -1) {
        this.$router.push('/intelligenceReports/screendata/document/nodata')
      } else {
        this.$router.push('/analysisOfRegression/screendata/document/nodata')
      }
    } else {
      if (pathname.indexOf('hypothesistest') > -1) {
        this.$router.push('/hypothesistest/screendata/document/data')
      } else if (pathname.indexOf('customchart') > -1) {
        this.$router.push('/customchart/screendata/document/data')
      } else if (pathname.indexOf('intelligenceReports') > -1) {
        this.$router.push('/intelligenceReports/screendata/document/data')
      } else {
        this.$router.push('/analysisOfRegression/screendata/document/data')
      }
    }
  },
  mounted() {
    // 页面缩放时的事件
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.autoHeightChange()
      })
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /**
     * 自定义高度
     */
    autoHeightChange() {
      // 高度自定义使用
      // 高度自定义使用
      AutoHeight(this, ['divContent', 'divContentleft'], [], 215)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.$store.dispatch('updateSamples', { type: 'document' })
      this.$store.dispatch('updateSamples', { questionnaireID: null })
      this.$store.dispatch('updateSamples', { experimentID: null })
      this.$store.dispatch('updateSamples', { questionnaireModelID: null })
      this.$store.dispatch('updateSamples', { fileid: null })
      this.$store.dispatch('updateSamples', { fields: JSON.stringify(selectedRows) })
      this.$store.dispatch('updateSamples', { sxFields: null })
      this.$store.dispatch('updateSamples', { fileName: '档案数据' })
      this.$store.dispatch('updateSamples', { sourceModule: '档案数据' })
      this.submitChoose()
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    submitChoose() {
      this.$store.dispatch('changeField')
      const pathname = window.location.pathname
      if (this.selectedRowKeys.length === 0) {
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document/nodata')
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push('/customchart/screendata/document/nodata')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push('/intelligenceReports/screendata/document/nodata')
        } else {
          this.$router.push('/analysisOfRegression/screendata/document/nodata')
        }
      } else {
        if (pathname.indexOf('hypothesistest') !== -1) {
          this.$router.push('/hypothesistest/screendata/document/data')
        } else if (pathname.indexOf('customchart') !== -1) {
          this.$router.push('/customchart/screendata/document/data')
        } else if (pathname.indexOf('intelligenceReports') !== -1) {
          this.$router.push('/intelligenceReports/screendata/document/data')
        } else {
          this.$router.push('/analysisOfRegression/screendata/document/data')
        }
        this.reload()
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  overflow: auto !important;
}
</style>
