<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index">
        <a-table
          ref="tables,2"
          v-if="table"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          :settings="table.settings"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
          <template #title>
            <div style="text-align: center">
              {{ xys[0][0].slice(-1)[0] }}和{{ xys[1][0].slice(-1)[0] }}的卡方检验 -- 样本数 (理论频数)
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>

import { getModule } from '@/api/module'
import { Tables } from '@/views/hypothesisTest/utils/formatTableClass'

export default {
  name: 'ChiSquareMain',
  props: ['data', 'xys', 'flowChiSquareNow'],
  data() {
    return {
      tables: [],
      imgs: [],
      method: Tables.kaFang
    }
  },
  created() {
    if (this.flowChiSquareNow !== 'auto') this.data.pyMethod = this.flowChiSquareNow
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({ module_name: 'ChiSquare', data: this.data }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '卡方检验',
          msg: res
        })
      } else {
        const tables = res.tables
        const xLength = this.xys[0][0].length - 1
        const yLength = this.xys[1][0].length - 1
        this.method(tables[0].columns, ['χ²', 'p'], xLength, yLength)
        this.getMergeSetting(tables, xLength, yLength)
        this.tables = tables
        this.$emit('mainP', res.p)
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  methods: {
    getMergeSetting(tables, xLength, yLength) {
      tables[0].settings = [[], [[2 + yLength, 0], [2 + yLength, 1]], [[3 + yLength, 0], [3 + yLength, 1]], [], []]
      // 竖向定类合并
      for (let index = 2; index <= 1 + yLength; index++) {
        tables[0].settings[0].push([index, 0])
      }
      // 横向p值合并
      for (let index = 2; index <= 1 + xLength; index++) {
        tables[0].settings[3].push([3 + yLength, index])
      }
      // 横向卡方的值合并
      for (let index = 2; index <= 1 + xLength; index++) {
        tables[0].settings[4].push([2 + yLength, index])
      }
    }
  }
}
</script>