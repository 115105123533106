var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header-wrapper", { attrs: { breadcrumb: null } }, [
        _c(
          "div",
          { staticStyle: { height: "auto !important" } },
          [
            _c(
              "a-anchor",
              {
                staticStyle: {
                  position: "fixed",
                  right: "10px",
                  width: "210px"
                },
                attrs: { "target-offset": _vm.targetOffset }
              },
              [
                _c("a-anchor-link", {
                  attrs: { href: "#overview", title: "概述" }
                }),
                _c(
                  "a-anchor-link",
                  { attrs: { href: "#demographyinfo", title: "人口学信息" } },
                  [
                    _c("a-anchor-link", {
                      attrs: { href: "#age", title: "年龄" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#sex", title: "性别" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#nation", title: "民族" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#marry", title: "婚姻状况" }
                    })
                  ],
                  1
                ),
                _c(
                  "a-anchor-link",
                  {
                    attrs: { href: "#lifestyle", title: "生活方式和社会因素" }
                  },
                  [
                    _c("a-anchor-link", {
                      attrs: { href: "#diet", title: "饮食习惯" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#exercise", title: "经常参与体育锻炼" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#smoke", title: "吸烟和被动吸烟" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#wine", title: "经常饮酒" }
                    }),
                    _c("a-anchor-link", {
                      attrs: { href: "#stayUpLate", title: "经常熬夜" }
                    }),
                    _c("a-anchor-link", {
                      attrs: {
                        href: "#pressure",
                        title: "面临生活或工作方面的压力"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-layout",
              {
                staticClass: "divContent",
                staticStyle: { "margin-right": "210px" }
              },
              [
                _c("div", [
                  _c("span", [_vm._v("当前样本数：")]),
                  _c("span", { staticClass: "htCommonColor" }, [
                    _vm._v(_vm._s(_vm.data.count))
                  ])
                ]),
                _c("over-view", {
                  staticClass: "itemMarginCommon",
                  attrs: { data: _vm.data, id: "overview" }
                }),
                _c(
                  "demography-info",
                  {
                    staticClass: "itemMarginCommon",
                    attrs: { datapro: _vm.data, id: "demographyinfo" }
                  },
                  [_vm._v(">")]
                ),
                _c(
                  "life-style",
                  {
                    staticClass: "itemMarginCommon",
                    attrs: { data: _vm.data, id: "lifestyle" }
                  },
                  [_vm._v(">")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }