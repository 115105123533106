// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/gr_loginbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#personLayout[data-v-80151ca2] {\n  height: 100%;\n  background: #000610 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 120%;\n}\n#personLayout #top[data-v-80151ca2] {\n  position: relative;\n  height: 18%;\n}\n#personLayout #top #uuBigData[data-v-80151ca2] {\n  position: absolute;\n  top: 25%;\n  left: 5%;\n}\n#personLayout #container[data-v-80151ca2] {\n  height: 45%;\n}\n#personLayout #container #registerContainer[data-v-80151ca2] {\n  background: #ffffff;\n}\n#personLayout #container #registerContainer #firstLine[data-v-80151ca2] {\n  font-size: 25px;\n  font-weight: 1000;\n}\n#personLayout #container #registerContainer #formWrapper[data-v-80151ca2] {\n  margin-top: 20px;\n}\n#personLayout #container #registerContainer #formWrapper #verification .ant-form-item[data-v-80151ca2] {\n  margin-bottom: 0;\n}\n#personLayout #footer[data-v-80151ca2] {\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  padding: 0 16px;\n  margin: 48px 0 24px;\n  text-align: center;\n}\n#personLayout #footer .links[data-v-80151ca2] {\n  margin-bottom: 8px;\n  font-size: 14px;\n}\n#personLayout #footer .links a[data-v-80151ca2] {\n  color: white;\n  -webkit-transition: all 0.3s;\n  transition: all 0.3s;\n}\n#personLayout #footer .links a[data-v-80151ca2]:not(:last-child) {\n  margin-right: 40px;\n}\n#personLayout #footer .copyright[data-v-80151ca2] {\n  color: white;\n  font-size: 14px;\n}\n", ""]);
// Exports
module.exports = exports;
