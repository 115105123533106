export class ParamStatistic {
    /**
     *
     * @param {documents里面的每一条患者数据} element
     * @param {leiList} lei
     * @param {liangList} liang或者xu
     * @param {构造好的存放数据的数据结构} result
     */
    constructor(element, lei, liang, result) {
      this.element = element
      this.lei = lei
      this.liang = liang
      this.result = result
    }

    LeiDuoLiang1() {
        // v:['男’,'女','性别']
        this.lei.forEach( v => {
            var key = v.slice(-1)[0]
            var value = this.element[key]
            this.result[key][value].push(Number(this.element[this.liang[0]]))
        })
    }
    LeiDuoXu1() {
        // v:['男’,'女','性别']
        this.lei.forEach( v => {
            var key = v.slice(-1)[0]
            var value = this.element[key]
            this.result[key][value].push(Number(this.element[this.liang[0]]))
        })
    }
    liangDuo() {
        // v:'身高'
        this.liang.forEach(v => {
            var value = this.element[v]
            this.result[v].push(Number(value))
        })
    }
}