var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(ref) {
          var xys = ref.xys
          var form = ref.form
          return [
            xys[0].length > 0 && form.option && form.proportion
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("Minimum", {
                      attrs: { up: form.option, down: xys[0][0].slice(-1)[0] }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(form.judge) +
                        " " +
                        _vm._s(Number(form.proportion) * 100) +
                        "% "
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "H1",
        fn: function(ref) {
          var xys = ref.xys
          var form = ref.form
          return [
            xys[0].length > 0 && form.option && form.proportion
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("Minimum", {
                      attrs: { up: form.option, down: xys[0][0].slice(-1)[0] }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$hypothesis.replaceJudge(form.judge)) +
                        " " +
                        _vm._s(Number(form.proportion) * 100) +
                        "% "
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }