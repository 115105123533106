<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232); padding: 30px 0">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 0 30px">
            <my-table2
              :showHeader="res.table.showHeader"
              :data="res.table.data"
              :columns="res.table.columns"
              :title="res.table.title"
              :size="res.table.size"
            ></my-table2>
            <div style="height: 5px; border: 1px solid rgb(232, 232, 232)"></div>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :span="24"
            v-for="(item, index) in res.images"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <MyImg :imgBytes="item" :fileName="`拟合图`"></MyImg>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../../../MakeReqClass'
import { FetchResult } from '../../../utils/fetchResultClass'
import {
  FormModel, Row, Col
} from 'ant-design-vue'
import MyTable2 from '../../table'
export default {
  components: { MyTable2, FormModel, Row, Col },
  created() {
    this.fetchResult()
  },
  data() {
    return {
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      res: {
        table: {
          showHeader: true,
          columns: [],
          data: []
        }
      },
      initialValues: []
    }
  },
  methods: {
    refresh() {
      this.$emit('showLoading')
      this.fetchResult()
    },
    fetchResult() {
      const form = this.$store.state.analysisOfRegression.dybtjy
      const method = this.$store.state.analysisOfRegression.method
      const req = new MakeReq(form, method, this).linearRegression()
      if (this.initialValues.length > 0) req.args.form.initialValues = this.initialValues
      const fetchResult = new FetchResult(this, req)
      fetchResult.main()
    },
    handleRes(res) {
      console.log('处理res')
      const columns = res.table.columns
      // 给initialValues赋值
      this.initialValues = res.args.initialValues
      const self = this
      for (const i in columns) {
        // 从第二列开始都要增加的customRender
        if (i > 0) {
          columns[i].customRender = (text, row, index) => {
            // 如果是方程那一行
            if (index === 0) {
              return <div domPropsInnerHTML={text} />
              // 如果是参数那一行
            } else if (index === 1) {
              return (<div>{
                text.map(item => {
                  return <div>{item}</div>
                })
              }</div>)
              // 如果是初始值那一行
            } else if (index === 6) {
              return (
                <div style="display:flex;align-items:center">
                  <div>
                    {
                      text.map((item, index) => <div>
                        <FormModel.Item label={item}>
                          <a-input value={self.initialValues[i - 1][index].toString()} onBlur={e => {
                            self.initialValues[i - 1][index] = Number(e.target.value)
                            self.initialValues.push('')
                            self.initialValues.pop()
                          }} />
                        </FormModel.Item>
                      </div>)
                    }
                  </div>
                  <div>
                    <a-icon type="redo" onClick={self.refresh} style="cursor:pointer;color:#1890ff;font-size:20px;margin-left:20px" />
                  </div>
                </div>
              )
              // 其余行
            } else {
              return text
            }
          }
        }
      }
      return res
    },
    // 创建初始值的绑定值initialValues
    createInitialValues(dict) {
      const initialValues = this.initialValues
      delete dict.columnTitle
      delete dict.key
      let i = 0
      Object.values(dict).forEach(value => {
        initialValues.push([])
        value.forEach(v => {
          initialValues[i].push('0')
        })
        i++
      })
    }
  }
}
</script>