var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [_c("a-col", [_c("img", { attrs: { src: _vm.imgBytes } })])],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c("a-col", [
            _c("a", { attrs: { href: _vm.imgBytes, download: _vm.fileName } }, [
              _vm._v("下载原图")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }