<template>
  <div>
    <a-modal
      title="个人信息"
      :visible="isNewAdd"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-row type="flex" justify="center">
        <a-col :span="22">
          <a-form
            :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            labelAlign="left"
            @submit="handleSubmit"
          >
            <a-form-item label="姓名">
              <a-input placeholder="请输入姓名" v-decorator="['name']" />
            </a-form-item>
            <a-form-item label="编号">
              <a-input
                placeholder="请输入编号"
                v-decorator="['serialNo',{
                  rules:
                    [
                      { required: true, message: '编号为必填项', whitespace: true },
                      { pattern: '^.{4,}$', message: '请填写至少4位的编号'},
                      { validator: onlySeriaNo}
                    ]
                }]"
              />
            </a-form-item>
            <a-form-item label="性别">
              <a-radio-group v-decorator="['gender']">
                <a-radio :value="0">男</a-radio>
                <a-radio :value="1">女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="出生日期">
              <a-date-picker style="width:100%" placeholder="请选择出生日期" v-decorator="['birthDay']" />
            </a-form-item>
            <a-form-item label="民族">
              <a-select
                show-search
                placeholder="请选择民族"
                v-decorator="['minority',{ initialValue: 0 }]"
              >
                <a-select-option
                  v-for="minority in minorities"
                  :key="minority.id"
                  :value="minority.id"
                >{{ minority.name }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="文化程度">
              <a-select show-search placeholder="请选择文化程度" v-decorator="['education']">
                <a-select-option
                  v-for="education in educations"
                  :key="education.key"
                  :value="education.key"
                >{{ education.value }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="手机号">
              <a-input
                placeholder="请输入手机号"
                :maxLength="11"
                v-decorator="[
                  'mobile'
                ]"
                style="width: 100%"
              >
              </a-input>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { minorities, educations } from '@/common/enums'
import { getDocByNum, createDocument } from '@/api/document'
export default {
  props: { isNewAdd: Boolean },
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'basicInformation' }),
      minorities,
      educations,
      // 模态框参数
      confirmLoading: false
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          createDocument({ ...values, orgid: this.$store.state.user.info.orgid }).then(res => {
            this.$message.success('创建成功', 3)
            this.form.resetFields()
            this.$emit('handleCancel')
            this.$emit('refreshDocList')
            this.$router.push(`/document/${res.id}`)
          })
        }
      })
    },
    handleSelectChange(value) {
      console.log(value)
      this.form.setFieldsValue({
        note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`
      })
    },
    // 模态框方法
    handleOk(e) {
      this.confirmLoading = true
      this.handleSubmit(e)
      setTimeout(() => {
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      this.$emit('handleCancel')
      this.form.resetFields()
    },
    onlySeriaNo(rule, value, callback) {
      if (value) {
          getDocByNum({
            oldName: this.serialNo,
            newName: value
          }).then(res => {
            this.$log('编号已存在', res)
            if (res) { callback('编号已存在') } else { callback() }
          })
      }
    }
  }
}
</script>
<style scoped>
.ant-form-item {
  margin-bottom: 20px;
}
.ant-form-item-label {
  height: 0px !important;
  padding-bottom: 0 !important;
}
</style>
