// 样本预处理
// 本地存储
import store from '../store'
import {
  GetData,
  GetQuestionData,
  GetExperimentData,
  GetExperimentData2,
  GetFileData
} from '@/api/screendata'
import {
  Array
} from 'core-js'
/**
 * 拖拽变量时获取下载变量的数据
 * @param {*} data //变量的信息
 * @param {*} that //调用dropClass的页面，比如chart.vue
 * @param {*} thatClass //dropClass类
 * @param {*} checkObj //变量的check对象
 * @param {*} callback //需要执行的方法
 */
export function GetDocuments(data, that, thatClass, checkObj, callback) {
  that.anysisLoading = true
  // 获取单个变量数据
  var doc = store.state.samples.documents && JSON.parse(store.state.samples.documents)
  var isExist = false // 判断当前变量是否已存在，如果存在就不需要重新下载了
  if (doc) {
    if (doc.length > 0) {
      var a = Object.keys(doc[0]).filter(m => m === data.fieldName)
      if (a && a.length > 0) {
        isExist = true
      }
    }
  }
  if (!isExist) {
    that.anysisLoading = true
    const keys = store.state.samples.keys && JSON.parse(store.state.samples.keys)
    if (!doc) {
      // 若document为undefined,说明刚进来还没有参数
      doc = []
      // 若是第一次赋值先将key全部写入
      keys.map(r => {
        doc.push({
          key: r,
          [data.fieldName]: ''
        })
      })
    } else {
      doc.map(r => {
        r[data.fieldName] = ''
      })
    }
    const type = store.state.samples.type
    const fields = []
    fields.push(data)
    if (type === 'document') {
      return GetData({
        fields: fields,
        selectRows: keys,
        orgid: that.$store.state.user.info.orgid
      }).then(res => {
        setDocuemts(res, data, doc)
        const key = data.fieldName
        if (doc.some(v => v[key] || v[key] === 0)) {
          store.dispatch('updateSamples', {
            documents: JSON.stringify(doc)
          })
          return callback(data, checkObj, that, thatClass, '')
        } else {
          that.$message.warning(`该样本${key}的值都为空`)
          that.load.anysisLoading = false
          return false
        }
      })
    } else if (type === 'questionnaire') {
      var questionnaireID = store.state.samples.questionnaireID
      if (keys.filter(a => !isNaN(Number(a))).length === keys.length) {
        // 说明key值是long类型
        return GetQuestionData({
          fields: fields,
          selectRows: keys,
          questionnaireID,
          orgid: that.$store.state.user.info.orgid
        }).then(res => {
          setDocuemts(res, data, doc)
          store.dispatch('updateSamples', {
            documents: JSON.stringify(doc)
          })
          return callback(data, checkObj, that, thatClass, '')
        })
      } else {
        return GetQuestionData({
          fields: fields,
          selectRowsexp: keys,
          questionnaireID,
          orgid: that.$store.state.user.info.orgid
        }).then(res => {
          setDocuemts(res, data, doc)
          store.dispatch('updateSamples', {
            documents: JSON.stringify(doc)
          })
          return callback(data, checkObj, that, thatClass, '')
        })
      }
    } else if (type === 'experiment') {
      var experimentID = store.state.samples.experimentID
      var guid = store.state.samples.experimentGuid
      if (guid === '3cc1a9c6-1af6-4081-9d5f-7de4e70b5fbb') {
        return GetExperimentData({
          fields: fields,
          selectRowsexp: keys,
          questionnaireID: experimentID
        }).then(res => {
          setDocuemts(res, data, doc)
          store.dispatch('updateSamples', {
            documents: JSON.stringify(doc)
          })
          return callback(data, checkObj, that, thatClass, '')
        })
      } else if (guid === '61bd643e-a81a-439b-94d6-61dac11a1b66') {
        return GetExperimentData2({
          fields: fields,
          selectRowsexp: keys,
          questionnaireID: experimentID
        }).then(res => {
          setDocuemts(res, data, doc)
          store.dispatch('updateSamples', {
            documents: JSON.stringify(doc)
          })
          return callback(data, checkObj, that, thatClass, '')
        })
      }
    } else if (type === 'file') {
      var fileID = store.state.samples.fileid
      return GetFileData({
        fields: fields,
        selectRows: keys,
        questionnaireID: fileID
      }).then(res => {
        setDocuemts(res, data, doc)
        store.dispatch('updateSamples', {
          documents: JSON.stringify(doc)
        })
        return callback(data, checkObj, that, thatClass, res.source)
      })
    }
  } else {
    if (data.forOrder === 1) {
      for (var i = 0; i < doc.length; i++) {
        if (!(doc[i][data.fieldName] instanceof Array)) {
          var value = getNumber(data.option, doc[i][data.fieldName])
          doc[i][data.fieldName] = [doc[i][data.fieldName], value]
        }
      }
    }
    store.dispatch('updateSamples', {
      documents: JSON.stringify(doc)
    })

    var source = store.state.samples.source
    ExistUpdateItem(doc, data, source)
    return callback(data, checkObj, that, thatClass, source)
  }
  return true
}

/**
 * session中已存在当前变量时，更新data的option
 * @param {*} doc  session中现在document
 * @param {*} data 变量
 */
function ExistUpdateItem(doc, data, source) {
  var optionData = [] // 实际数据中的option
  doc.map(r => {
    if (data.forOrder) {
      optionData.push(r[data.fieldName][0])
    } else {
      optionData.push(r[data.fieldName])
    }
  })
  data = FieldsSplice(data, optionData, source)
  return data
}

/**
 * 更新session中的Documents,如果是单选多选下拉，顺道吧option实际使用的值捞出来
 * @param {*} res api返回的值
 * @param {*} data 变量
 * @param {*} doc session中现在document
 */
export function setDocuemts(res, data, doc) {
  var optionData = [] // 实际数据中的option
  var resdata = JSON.parse(res.data)
  resdata.map(r => {
    for (var i = 0; i < doc.length; i++) {
      if (doc[i].key === r.key) {
        if (data.forOrder === 1) {
          if (data.ptype && r[data.fieldName]) {
            // 时序变量
            var sxvalue = JSON.parse(JSON.stringify(r[data.fieldName]))
            sxvalue = sxvalue.map(a => {
              if (a.value.constructor === Array) {
                // 时序的值中还有另一个时序，里面还有时间2：00
                a.value.map(b => {
                  var value = getNumber(data.option, b.value)
                  b.value = value
                  return b
                })
              } else {
                var value = getNumber(data.option, a.value)
                a.value = value
              }

              return a
            })
            doc[i][data.fieldName] = [r[data.fieldName], sxvalue]
            Object.keys(r).filter(kname => kname !== 'key' && kname !== data.fieldName).map(kname => {
              // 普通变量
              doc[i][kname] = r[kname]
            })
          } else {
            // 非时序变量
            // 定序变量
            var value = getNumber(data.option, r[data.fieldName])
            doc[i][data.fieldName] = [r[data.fieldName], value]
            Object.keys(r).filter(kname => kname !== 'key' && kname !== data.fieldName).map(kname => {
              // 普通变量
              doc[i][kname] = r[kname]
            })
          }
        } else {
          Object.keys(r).filter(kname => kname !== 'key').map(kname => {
            // 普通变量
            doc[i][kname] = r[kname]
          })
        }
        break
      }
    }
    if (data.fieldType === '单选' || data.fieldType === '多选' || data.fieldType === '下拉框') {
      optionData.push(r[data.fieldName])
    }
  })
  data = FieldsSplice(data, optionData, res.source)
}
// 定序获得定量的值
const getNumber = (option, value) => {
  var type = store.state.samples.type
  if (type === 'document' || type === 'experiment' || type === 'file') {
    var optionss = option.split(',')
    return optionss.indexOf(value) > -1 ? optionss.indexOf(value) : null
  } else {
    var options = JSON.parse(option)
    // 先看看是否填了分值
    var scores = options.filter(r => r.Score !== null).map(r => {
      return r.Score
    })
    scores = scores.filter((r, index, optionData1) => {
      return optionData1.indexOf(r) === index && r !== undefined
    })
    // 没有分值，或者分值都为0，按照选项的顺序，从0开始赋值
    if (scores.length === 0 || (scores.length === 1 && scores[0] === 0)) {
      var v = null
      options.map((r, i) => {
        if (r.Text === value) {
          v = i
        }
      })
      return v
    } else {
      return options.filter(r => r.Text === value)[0].Score
    }
  }
}
/**
 * 给变量的option赋值
 * @param {*} data 变量
 * @param {*} optionData 变量option的实际值
 * @param {*}source 文件数据的源 可能是档案。量表。实验缺失值处理后过来的
 */
function FieldsSplice(data, optionData, source) {
  if (data.fieldType === '单选' || data.fieldType === '多选' || data.fieldType === '下拉框') {
    // 去重
    optionData = optionData.filter((r, index, optionData1) => {
      return optionData1.indexOf(r) === index
    })
    var type = store.state.samples.type
    if (type === 'document' || type === 'experiment' || (type === 'file' && source !== 'questionnaire')) {
      // option反序列化
      var option = data.option ? data.option.split(',') : []
      data['optionInit'] = option.join(',') // 将原始的赋值给optionInit
      // 找出option有效的对象
      var optionSJ = option.filter(r => optionData.filter(a => a === r).length > 0)
      // data['optionData'] = optionData
      // 给当前变量修改option
      data.option = optionSJ.join(',')
      FindAndSplice(data)
      return data
    } else {
      // option反序列化
      option = data.option ? JSON.parse(data.option) : []
      data['optionInit'] = JSON.stringify([...option]) // 将原始的赋值给optionInit
      // 找出option有效的对象
      optionSJ = option.filter(r => optionData.filter(a => a === r.Text).length > 0)
      // data['optionData'] = optionData
      // 给当前变量修改option
      if (!data.ptype) {
        data.option = JSON.stringify(optionSJ)
      }
      FindAndSplice(data)
      return data
    }
  } else {
    data['optionInit'] = data.option
    FindAndSplice(data)
    return data
  }
}

/**
 * 找出session中变量所在的位置并替换
 * @param {*} data 更新过的变量
 */
function FindAndSplice(data) {
  // 找出当前变量在总变量中的位置
  var index = 0
  var fields = store.state.samples.fields && JSON.parse(store.state.samples.fields) // 总变量
  var type = store.state.samples.type
  for (let i = 0; i < fields.length; i++) {
    if (type === 'document') {
      if ((fields[i].fieldID && fields[i].fieldID === data.fieldID && fields[i].fieldName === data.fieldName) || (!fields[i].fieldID && fields[i].fieldName === data.fieldName)) {
        index = i
        break
      }
    } else {
      if (fields[i].fieldID === data.fieldID && fields[i].fieldName === data.fieldName) {
        index = i
        break
      }
    }
  }
  // 更新变量的session
  fields.splice(index, 1, data)
  store.dispatch('updateSamples', {
    fields: JSON.stringify(fields)
  })
}