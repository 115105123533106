<template>
  <a-modal
    title="缺失值处理"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="loading"
    okText="确定"
    cancelText="取消"
    @ok="
      () => {
        $emit('ok')
      }
    "
    @cancel="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-form-model ref="missvalue" :model="form" v-bind="formLayout" layout="vertical">
      <div style="color: #ff7200;height: 35px;background-color:#fff8ed;width:100%;line-height:35px;padding-left:10px">缺失值处理后的数据仅供一次分析使用，如果想永久使用请另存为</div><br />
      <a-form-model-item label="缺失值处理方法"
                         prop="fun"
                         :rules="{
                           required: true,
                           message: '必填',
                         }">
        <a-radio-group v-model="form.fun" @change="funchange">
          <a-radio value="default" style="height:35px"
          >固定值填充
            <a-input-number
              v-model="form.default"
              placeholder="固定值"
              :disabled="form.fun !== 'default'"
            ></a-input-number></a-radio
            >(仅支持定量数据) <span style="color: red">{{ form.err }}</span
          ><br />
          <a-radio value="mean" style="height:30px">均值填充(仅支持定量数据)</a-radio><br />
          <a-radio value="median" style="height:30px">中位数填充(仅支持定量数据)</a-radio><br />
          <a-radio value="knn" style="height:30px">KNN填充(仅支持定量数据)</a-radio><br />
          <a-radio value="mode" style="height:30px">众数填充</a-radio><br />
          <a-radio value="last" style="height:30px">上条数据填充</a-radio><br />
          <a-radio value="next" style="height:30px">下条数据填充</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="处理的变量"
      >
        <a-select
          mode="multiple"
          placeholder="默认对所有的变量进行处理，若需要处理部分变量，请在这里选择"
          v-model="form.fields"
          v-if="form.fun === 'mode' || form.fun === 'last' || form.fun === 'next'"
        >
          <a-select-option :value="`${item.fieldName}`" v-for="(item, i) in fields" :key="i">{{
            item.fieldName
          }}</a-select-option>
        </a-select>

        <a-select mode="multiple" placeholder="默认对所有的变量进行处理，若需要处理部分变量，请在这里选择" v-model="form.fields" v-else>
          <a-select-option
            :value="`${item.fieldName}`"
            v-for="(item, i) in fields.filter((r) => r.fieldType === '数字')"
            :key="i"
          >{{ item.fieldName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },

    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    }

    return {
      options: [], // 条件
      index: 0,
      form: {
        fun: '',
        default: undefined,
        err: '',
        fields: []
      },
      fields: []
    }
  },
  created() {
    this.fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
  },
  methods: {
    funchange(e) {
      var fields = this.$store.state.samples.fields ? JSON.parse(this.$store.state.samples.fields) : []
      if (e.target.value !== 'default') {
        this.form.default = ''
      }
      if (e.target.value === 'mode' || e.target.value === 'last' || e.target.value === 'next') {
        this.fields = fields
      } else {
        this.field = fields.filter(r => r.fieldType === '数字' || r.fieldType === '日期')
        if (
          fields
            .filter(r => this.form.fields.filter(a => a === r.fieldName).length > 0)
            .filter(r => r.fieldType !== '数字' && r.fieldType !== '日期').length > 0
        ) {
          this.form.fields = []
        }
      }
    }
  }
}
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  margin-left: 2px;
  color: #999;
  transition: all 0.3s;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
</style>