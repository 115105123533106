<template>
  <div>
    <a-divider />
    <div v-if="moreInfo" style="width: 90%; margin: 0 auto">
      <a-descriptions style="margin-top: 20px" size="small">
        <a-descriptions-item label="记录编号">{{ exp.serialNum }}</a-descriptions-item>
        <a-descriptions-item label="实验类型">{{ exp.type === 0 ? '精密度验证' : '正确度验证' }}</a-descriptions-item>
        <a-descriptions-item label="实验名称">{{ exp.expName }}</a-descriptions-item>
        <a-descriptions-item label="实验天数">{{ exp.expDays }}</a-descriptions-item>
        <a-descriptions-item label="实验设备">{{
          exp.devices && exp.devices.length > 0
            ? exp.devices
              .map((r) => {
                return r.name
              })
              .join(',')
            : ''
        }}</a-descriptions-item>
        <a-descriptions-item label="实验人员">{{
          exp.users && exp.users.length > 0
            ? exp.users
              .map((r) => {
                return r.name
              })
              .join(',')
            : ''
        }}</a-descriptions-item>
        <a-descriptions-item v-for="(item, i) in reagents" :key="i" :label="item.label">{{
          item.value
        }}</a-descriptions-item>
        <a-descriptions-item label="样本名称">{{ exp.sampleName }}</a-descriptions-item>
        <a-descriptions-item label="样本浓度">{{
          exp.sampleConcentrations ? JSON.parse(exp.sampleConcentrations).join(',') : ''
        }}</a-descriptions-item>
        <a-descriptions-item label="样本批号">{{ exp.sampleBatchNum }}</a-descriptions-item>
        <a-descriptions-item label="重复测量次数">{{ exp.repeatNum }}</a-descriptions-item>
        <template v-if="exp.accuracyFunction === null">
          <a-descriptions-item label="规定重复CV1">{{ exp.cV1*100 }}%</a-descriptions-item>
          <a-descriptions-item label="规定重复CV2">{{ exp.cV2*100 }}%</a-descriptions-item>
        </template>
        <template v-if="exp.accuracyFunction === 1">
          <a-descriptions-item label="厂家声明偏倚(B)">{{ exp.b }}</a-descriptions-item>
        </template>
        <template v-if="exp.accuracyFunction === 0">
          <a-descriptions-item label="参考物质指定值">{{ exp.designedValue }}mmol/L</a-descriptions-item>
          <a-descriptions-item label="指定标准差">{{ exp.designedStd }}mmol/L</a-descriptions-item>
        </template>
        <a-descriptions-item
          label=""
        ><span style="color: #1890ff; cursor: pointer" @click="moreInfo = false">收起 > ></span></a-descriptions-item
        >
      </a-descriptions>
    </div>
    <div v-else style="width: 90%; margin: 0 auto">
      <a-descriptions style="margin-top: 20px" size="small">
        <a-descriptions-item label="记录编号">{{ exp.serialNum }}</a-descriptions-item>
        <a-descriptions-item label="实验类型">{{ exp.type === 0 ? '精密度验证' : '正确度验证' }}</a-descriptions-item>
        <a-descriptions-item label="实验名称">{{ exp.expName }}</a-descriptions-item>
        <a-descriptions-item label="实验天数">{{ exp.expDays }}</a-descriptions-item>
        <a-descriptions-item label="重复测量次数">{{ exp.repeatNum }}</a-descriptions-item>
        <a-descriptions-item
          label=""
        >
          <span
            style="color: #1890ff; cursor: pointer"
            @click="moreInfo = true"
          >
            更多信息 > >
          </span
          ></a-descriptions-item
        >
      </a-descriptions>
    </div>
    <a-divider />
    <div style="display: flex; justify-content: space-between">
      <a-button type="primary" @click="showModal">新增数据</a-button>
      <!-- <router-link
        target="_blank"
        :to="{
          path: '/experiment/Print',
          query: {
            exp: exp,
          },
        }"
      >查看记录表</router-link
      > -->
      <a-button type="primary" @click="showRecord" :disabled="disabled">查看报告</a-button>
    </div>
    <new-data :visible="visible" @handleCancel="handleCancel" @refTable="refTable" :exp="exp" />
    <!-- <RecordTable :visible="record_visible" :exp="exp" @handleCancelRecord="handleCancelRecord"/> -->
    <s-table
      style="margin-top: 20px"
      class="smallTable"
      bordered
      ref="table"
      size="small"
      rowKey="key"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
      :scroll="{ x: true }"
    >
      <span slot="date" slot-scope="text">
        <template>
          <span>{{ text ? $moment(text).format('YYYY-MM-DD HH:mm:ss') : '' }}</span>
        </template>
      </span>
      <span slot="result" slot-scope="text">
        <template>
          <span style="color: #43c73d">{{ text | ResultFilter }}</span>
        </template>
      </span>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm :title="`确认删除?`" @confirm="() => onDelete(record.key)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </s-table>
  </div>
</template>
<script>
import NewData from './newData'
import RecordTable from './recordTable'
import { List, Delete } from '@/api/exp/experimentData'
import { getByID } from '@/api/exp/experiment'
import { STable } from '@/components'

// 状态
const ResultMap = {
  0: {
    status: '0',
    text: '通过'
  },
  1: {
    status: '1',
    text: '不通过'
  }
}
let columns = []
export default {
  components: { NewData, STable, RecordTable },
  props: {
    parm: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    // this.columns = columns
    return {
      moreInfo: false,
      currentCom: '',
      columns: [],
      ModalText: 'Content of the modal',
      visible: false,
      record_visible: false, // 控制记录表显示或者隐藏的visible
      confirmLoading: false,
      exp: {},
      list: [],
      disabled: true,
      reagents: [], // 用于显示试剂详情
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ expID: Number(this.$route.params.id) }, parameter, this.queryParam)
        return List(requestParameters).then(res => {
          this.list = res.data
          if (!res.calibration) {
            res.calibration = { date: null, result: null }
          }
          columns = [
            {
              title: '实验时间',
              dataIndex: 'date',
              scopedSlots: { customRender: 'date' },
              width: 200,
              fixed: 'left'
            },
            {
              title: '实验人员',
              dataIndex: 'user.name'
            },
            {
              title: '实验设备',
              dataIndex: 'device.name'
            },
            {
              title: '试剂批号',
              dataIndex: 'reagent.name'
            },
            {
              title: '校准时间',
              dataIndex: 'calibration.date',
              scopedSlots: { customRender: 'date' }
            },
            {
              title: '校准结果',
              dataIndex: 'calibration.result',
              scopedSlots: { customRender: 'result' }
            }
          ]
          var maxlength = []
          res.data.map(r => {
            if (r.dataDetails && r.dataDetails.length > 0) {
              r.dataDetails.map(d => {
                var values = d.concentrationValue && JSON.parse(d.concentrationValue)
                if (values && values.length > 0) {
                  var max = maxlength.filter(a => a.name === d.concentrationName)
                  if (max.length > 0) {
                    max[0].value = max[0].value < values.length ? values.length : max[0].value
                  } else {
                    maxlength.push({ name: d.concentrationName, value: values.length })
                  }
                }
              })
            }
          })

          res.data.map((d, index) => {
            if (d.dataDetails && d.dataDetails.length > 0) {
              d.dataDetails.map(r => {
                var children = []
                if (index === 0) {
                  // 根据最多的数据生成列
                  var max = maxlength.filter(a => a.name === r.concentrationName)
                  if (this.exp.accuracyFunction !== 1) {
                    for (let a = 0; a < max[0].value; a++) {
                      var key = r.concentrationName + (a + 1)
                      children.push({
                        title: '重复' + (a + 1),
                        dataIndex: key
                      })
                    }
                    columns.push({
                      title: r.concentrationName + '%浓度',
                      children: children
                    })
                  } else {
                    for (let a = 0; a < max[0].value; a++) {
                      var key2 = r.concentrationName + (a + 1)
                      children.push({
                        title: a === 0 ? '试验方法结果' : '比较方法结果',
                        dataIndex: key2
                      })
                    }
                    columns.push({
                      title: '测量值',
                      children: children
                    })
                  }
                }
                var values = r.concentrationValue && JSON.parse(r.concentrationValue)
                if (values && values.length > 0) {
                  maxlength = maxlength < values.length ? values.length : maxlength
                  values.map((a, i) => {
                    // 自定义数据key
                    var key = r.concentrationName + (i + 1)
                    // 给key赋值
                    d[key] = a
                  })
                }
              })
            }
          })
          columns.push({
            title: '质控结果',
            dataIndex: 'result',
            scopedSlots: { customRender: 'result' }
          })
          columns.push({
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' }
          })
          this.columns = columns
          return res
        })
      }
    }
  },

  created() {
    getByID(Number(this.$route.params.id)).then(res => {
      console.log('%c 🍬 res: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', res)
      var reagents = []
      let label = ''
      if (res.reagents && res.reagents.length > 0) {
        res.reagents.map(r => {
          switch (r.type) {
            case 0:
              label = '试剂'
              break
            case 1:
              label = '校准品'
              break
            case 2:
              label = '质控品'
              break
          }
          reagents.push({ label: `${label}名称`, value: r.name })
          reagents.push({ label: `${label}厂家`, value: r.manufactor })
          reagents.push({ label: `${label}批号`, value: r.batchNum })
        })
        this.reagents = reagents
      }
      this.exp = res
    })
  },
  computed: {
    printData() {
      const { exp, list } = this
      return { exp, list }
    }
  },
  watch: {
    printData(val) {
      if (Object.keys(val.exp).length > 0 && val.list.length > 0) {
        this.exp.list = this.list
        this.disabled = false
      }
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    showRecord() {
      // this.$router.push({
      //   path: '/experiment/Print',
      //   query: {
      //     exp: this.exp
      //   }
      // })
      const map = {
        0: '/reference/Print',
        1: '/convention/Print'
      }
      let path
      const accuracyFunction = this.exp.accuracyFunction
      if (accuracyFunction !== null) {
        path = map[accuracyFunction]
      } else {
        path = '/experiment/Print'
      }
      const routeData = this.$router.resolve({
        path,
        query: {
          id: this.$route.params.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleCancel(e) {
      this.visible = false
    },
    handleCancelRecord(e) {
      this.record_visible = false
    },
    refTable() {
      this.$refs.table.refresh()
    },
    onDelete(key) {
      Delete(key).then(res => {
        console.log(res, '删除成功')
        this.refTable()
      }
      )
    }
  },
  // 过滤器
  filters: {
    ResultFilter(type) {
      if (type || type === 0) {
        return ResultMap[type].text
      }
    }
  }
}
</script>
<style lang="less">
.smallTable thead tr {
  background-color: #fafafa;
}

</style>