<template>
  <div>
    <div class="third-module">
      <slot name="textFront"></slot>
    </div>
    <div class="third-module">
      <div v-for="(table, index) in tables" :key="index">
        <a-table
          ref="tables,2"
          v-if="table"
          :pagination="false"
          :dataSource="table.data"
          :columns="table.columns"
          size="small"
        >
          <div v-for="item in table.columns" :key="item.key" :slot="'slotValue' in item ? item.slotValue : ''">
            <div v-html="item.slotValue">{{ item.slotValue }}</div>
          </div>
          <template #title>
            <div style="text-align: center">
              不同{{ lei }}对{{ Object.keys(data.data1)[index] }}的非参数事后多重比较
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="third-module">
      <slot name="textBack"></slot>
    </div>
  </div>
</template>
<script>
import { getModule } from '@/api/module'

export default {
  props: ['data', 'lei', 'noneParamIsDiff', 'leiOptionsCombine'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    getModule({
      module_name: 'NoneParamPostHoc',
      data: this.data,
      lei: this.lei,
      noneParamIsDiff: this.noneParamIsDiff,
      leiOptionsCombine: this.leiOptionsCombine
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '非参数事后多重比较',
          msg: res
        })
      } else {
        const tables = res.tables
        const findLessP = this.findLessP(tables)
        this.$emit('findLessP', findLessP)
        this.tables = tables
        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      tables: [],
      imgs: []
    }
  },
  methods: {
    findLessP(tables) {
      const a = this.$store.state.report.a
      const result = []
      tables.forEach((table, index) => {
        if (table) {
          const list = []
          table.data.forEach(dataObj => {
            if (dataObj.p < a) {
              const A = dataObj.A
              const i = list.findIndex(v => v.A === A)
              if (i === -1) {
                list.push({
                  A, B: dataObj.B
                })
              } else {
                list[i].B = list[i].B.concat(`、${dataObj.B}`)
              }
            }
          })
          result.push(list)
        }
      })
      return result
    }
  }
}
</script>