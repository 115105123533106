<template>
  <a-modal
    :visible="isAddMedicalHistory"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="modalTitle">
      <span id="medicalHistoryTitle">{{ tabController === 'history'?'既往史':'相关检查' }}</span>
      <a-tree-select
        placeholder="请选择"
        v-model="value"
        style="width: 120px;margin-left:75px"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="treeData"
        size="small"
        @select="onTreeSelect"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <!-- <template slot="name" slot-scope="item">aaa{{ item }}</template> -->
      </a-tree-select>
    </div>
    <a-divider />
    <div class="modalBody">
      <a-row type="flex" justify="center">
        <a-col :span="22" v-if="medicalHistoryMadals !== null">
          <a-form-model
            :model="medicalHistoryMadals"
            labelAlign="left"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-row>
              <a-col
                :span="24"
                v-for="medicalHistoryMadal in medicalHistoryMadals.form"
                :key="medicalHistoryMadal.id"
              >
                <form-items
                  :tabContent="medicalHistoryMadals.form"
                  :formItem="medicalHistoryMadal"
                  :controller="'modal'"
                  :examList="examList"
                ></form-items>
              </a-col>
            </a-row>
          </a-form-model>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import { TreeSelect } from 'ant-design-vue'
import { getHistoryModels } from '@/api/historyModel'
import { getHistoryFields, addHistoryFields } from '@/api/historyField'
import { getExamModels } from '@/api/examModel'
import { getExamFields, addExamFields } from '@/api/examField'
import formItems from './formItems'

export default {
  created() {

  },
  props: ['isAddMedicalHistory', 'tabController', 'tabsHistoryList', 'tabsExamList'],
  data() {
    return {
      treeExpandList: [],
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      treeData: [],
      value: undefined,
      tabsDataCopy: [],
      medicalHistoryMadals: {
      },
      examList: null
    }
  },
  components: { TreeSelect, formItems },
  methods: {
    handleOk(e) {
      console.log('abcdef', this.medicalHistoryMadals)
      if (JSON.stringify(this.medicalHistoryMadals) === '{}' || this.medicalHistoryMadals === null) {
        this.$message.warning('请选择种类后再提交')
        return
      }
      this.confirmLoading = true
      this.handleSubmit(e)
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleSubmit(e) {
      e.preventDefault()
      // 获取当前患者的Id
      var docId = this.$store.state.document.currentUserId
      // 遍历form取到的对象，重新构造出新的数组传入后端
      var reqList = this.makeReq(this.$deepClone(this.medicalHistoryMadals.form))
      console.log('post', reqList)

      var req = {
        docId,
        reqList
      }
      console.log(req, 'req')
      if (this.tabController === 'history') {
        addHistoryFields(req).then(res => {
          this.$message.success('添加成功', 3)
          this.initialModal()
          this.$emit('updateHistoryValue')
          this.$emit('handleCancel')
        })
      } else {
        addExamFields(req).then(res => {
          this.$message.success('添加成功', 3)
          this.initialModal()
          this.$emit('updateExamValue')
          this.$emit('handleCancel')
        })
      }
    },
    // 把有children的字段里的children取出来
    makeReq(list) {
      var req = []
      this.getChild(req, list)
      return req
    },
    getChild(req, list) {
      list.forEach(v => {
        if (v.children) {
          this.getChild(req, v.children)
        }
        v.field_h = v.id
        v.option = v.option ? v.option.join(',') : null
        delete v.children
        delete v.id
        req.push(v)
      })
    },
    handleCancel(e) {
      this.$emit('handleCancel')
      this.initialModal()
    },
    // 初始化模态框里的状态
    initialModal() {
      console.log('初始化modal')
      this.medicalHistoryMadals = null
      this.value = undefined
      this.fetchHistoryModels()
    },
    // 既往史树选中后获取详情字段
    onTreeSelect(value, node) {
      console.log(value, node)
      var currentTabList = this.tabController === 'history' ? this.tabsHistoryList : this.tabsExamList
      if (currentTabList.form.some(v => v.modelName === node.title)) {
        this.$message.info(`${node.title}已存在档案中，请直接修改保存`)
        this.initialModal()
      } else {
        this.fetchHistoryFields(value)
      }
      // var modalobj = findModalItem(treeData, value)
      // this.medicalHistoryMadals = modalobj
    },
    onRadioChange(e) {
      console.log('radio-value', e.target.value)
    },
    // 获取既往史种类列表
    fetchHistoryModels() {
      if (this.tabController === 'history') {
        getHistoryModels().then(res => {
          console.log('treeData', res)
          // res.forEach(element => {
          //   element['scopedSlots'] = { title: 'name' }
          // })
          this.treeData = res
        })
      } else {
        getExamModels().then(res => {
          console.log('treeData', res)
          this.treeData = res
        })
      }
    },
    // 获取既往史详情
    fetchHistoryFields(value) {
      if (this.tabController === 'history') {
        getHistoryFields({ modelid_h: value }).then(res => {
          console.log('既往史详情', res)
          var form = {
            form: res
          }
          this.medicalHistoryMadals = form
        })
      } else {
        getExamFields({ modelid_h: value }).then(res => {
          console.log('获取相关检查详情', res)
          this.medicalHistoryMadals = {
            form: res.inputList
          }
          this.examList = res.examList
        })
      }
    },
    // date转dateString
    transferDate(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      var dateString = year + '-' + month + '-' + day
      return dateString
    }
  },
  watch: {
    tabController() {
      this.treeData = []
      this.fetchHistoryModels()
    }
  }
}
</script>
<style lang="less" scoped>
#medicalHistoryTitle {
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
}
.form-autolabel .ant-form-item-label {
  width: auto;
}
.ant-modal-body {
  padding: 0;
  .modalTitle {
    padding-top: 24px;
    padding-left: 24px;
  }
  .modalBody {
    padding: 0 24px 24px 24px;
    .ant-form-item-label {
      display: inline;
    }
    .ant-form-item-control-wrapper {
      display: inline;
    }
  }
}
</style>
