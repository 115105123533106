<template>
  <a-row style="border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable :data="data" :columns="columns" :title="'描述性分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <myTable
              v-if="typeof part2.p !== 'string'"
              :data="part2.table.data"
              :columns="part2.table.columns"
              :title="'主体间效应检验'"
            ></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'Tukey-HSD事后检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '@/utils/util'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.$hypothesis.setColumns.general,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']],
        table: {
          data: []
        }
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, 'F')
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.leiliang2()
    }
  },
  computed: {
    rejectH0List() {
      const list = []
      this.part2.table.data.forEach(v => {
        if (v['p-tukey'] < this.part2.a) list.push(v)
      })
      return list
    },
    rejectcombine() {
      return this.rejectH0List.map(v => `&lt;${v.A}&gt;与&lt;${v.B}&gt;`).join(' ')
    },
    method2() {
      return `根据Tukey-HSD方法计算得出：`
    },
    result() {
      if (typeof this.part2.p !== 'string') {
        if (this.rejectH0List.length > 0) {
          return [
            `1.${this.method2}${this.rejectcombine}比较时，<i>p</i> < ${this.part2.a}，拒绝H0，差别有统计学意义。`,
            `2.${this.method2}其余组合<i>p</i> ≥ ${this.part2.a}，不拒绝H0，差别无统计学意义`
          ]
        } else {
          return [`1.${this.method2}所有组合的<i>p</i> ≥ ${this.part2.a}，不拒绝H0，差别无统计学意义。`]
        }
      } else {
        return [`数据质量不佳，无法分析`]
      }
    }
  }
}
</script>