var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "white" }, attrs: { id: "rangecode" } },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.form, layout: "vertical" } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "border-right": "1px solid rgb(232, 232, 232)"
                  },
                  attrs: { span: 6 }
                },
                [_c("fieldcheck", { attrs: { form: _vm.form } })],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "margin-left": "20px", "margin-top": "20px" },
                  attrs: { span: 16 }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "判断标准" } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.form.sameNaN,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sameNaN", $$v)
                            },
                            expression: "form.sameNaN"
                          }
                        },
                        [_vm._v("缺失比例")]
                      ),
                      _vm._v("> "),
                      _c("a-input-number", {
                        staticStyle: { width: "200px" },
                        model: {
                          value: _vm.form.sameNaNP,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sameNaNP", $$v)
                          },
                          expression: "form.sameNaNP"
                        }
                      }),
                      _vm._v(" % ")
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "无效样本处理" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.handel,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "handel", $$v)
                            },
                            expression: "form.handel"
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { staticStyle: { height: "45px important" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c("a-radio", { attrs: { value: "new" } }, [
                                    _vm._v("标识(新生成标题)")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "delete" } },
                                    [_vm._v("删除无效样本")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { staticStyle: { "margin-top": "40px" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", "html-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("dynamicValidateForm")
                            }
                          }
                        },
                        [_vm._v(" 确定 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function($event) {
                              return _vm.gotoBack()
                            }
                          }
                        },
                        [_vm._v(" 取消 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }