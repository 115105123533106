<template>
  <div>
    <div>
      <a-row v-for="(obj, i) in result" :key="i">
        <a-col v-for="(p, j) in obj" :key="j" :xs="24" :sm="24" :md="24" :lg="24" :xl="obj.length === 1 ? 24 : 12">
          <div>
            <div class="third-module">
              <p :ref="`text,${i === 0 ? (j + 1)*3+1 : (j + 1 + 1)*3+1}`">
                {{ p.text1 }}
              </p>
            </div>
            <div class="third-module">
              <a-table
                :ref="`tables,${i === 0 ? (j + 1)*3+2 : (j + 1 + 1)*3+2}`"
                :pagination="false"
                v-for="(table, index) in p.tables"
                :key="index"
                :dataSource="JSON.parse(table.data)"
                :columns="table.columns"
                size="small"
              >
              </a-table>
            </div>
            <div class="third-module">
              <a-row >
                <a-col v-for="(ary, index) in p.imgs" :key="index" :xs="24" :sm="24" :md="24" :lg="24" :xl="12" :xxl="12">
                  <img :src="ary.src" :ref="`imgs,${i === 0 ? (j + 1)*3+3 : (j + 1 + 1)*3+3}`" />
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import Anysis from '@/views/report/modules/basicStatistics/anysis'
import { getModule } from '@/api/module'
export default {
  components: { Anysis },
  props: ['data', 'xys'],
  created() {
    this.$store.dispatch('addReqNumOfShouldOfReportPage')
    console.log('cc', this.xys)
    getModule({
      module_name: 'BasicAnalysis',
      data: this.data,
      xy: this.xys,
      count: this.data ? this.data.length : 0,
      style: {},
      method: this.xys.type,
      colors: JSON.parse(this.$store.state.user.info.setting.colors)
    }).then(res => {
      if (typeof (res) === 'string') {
        this.$store.dispatch('push_error_msg', {
          moduleName: '基础分析',
          msg: res
        })
      } else {
        console.log('result', res)
        this.result = res.result

        this.$store.dispatch('addReqNumOfFactOfReportPage')
      }
    })
  },
  data() {
    return {
      result: []
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
