<template>
  <index
    :xysName="xysName"
    :xys="xys"
    :formAddKeyName="formAddKeyName"
    :pForm="form"
    :pSettings="settings"
    @click="onClick"
  >
    <div slot="H0" slot-scope="data">
      <div v-if="data.xys[0].length > 0 && data.xys[1].length > 0">
        {{ data.xys[0][0].slice(-1)[0] }}与{{ data.xys[1][0].slice(-1)[0] }}不具有关联性
      </div>
    </div>
    <div slot="H1" slot-scope="data">
      <div v-if="data.xys[0].length > 0 && data.xys[1].length > 0">
        {{ data.xys[0][0].slice(-1)[0] }}与{{ data.xys[1][0].slice(-1)[0] }}具有关联性
      </div>
    </div>
  </index>
</template>
<script>
import { settings } from './inputItems'
import Index from '../../indexComponent'
export default {
  components: { Index },
  data() {
    return {
      xysName: 'kffxXys',
      xys: [[], [], []],
      formAddKeyName: 'kffxFormAddKey',
      settings,
      form: {
        a: '5'
      }
    }
  },
  methods: {
    // 这里that代指子组件里的this
    onClick(that) {
      if (this.$store.state.hypothesis.method) {
        var form = this.$deepClone(that.form)
        form.xys = this.$deepClone(that.computedXys)
        form.a = parseFloat(that.form.a) / 100
        this.$store.dispatch('saveDybtjy', form)
        this.$router.push('/hypothesistest/result')
      } else {
        this.$message.info('请选择假设检验方法')
        return false
      }
    }
  }
}
</script>