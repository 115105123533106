<style scoped>
.wrapper {
  position: fixed !important;
  background: white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
</style>
<template>
  <div class="wrapper">
    <a-spin :tip="tip" style="margin:300px auto;display:block;postion:relative" size="large"/>
  </div>
</template>
<script>
export default {
  computed: {
    tip() {
      return '计算中...'
    }
  }
}
</script>