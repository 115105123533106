<template>
  <a-row style=" border-right: 1px solid rgb(232, 232, 232)">
    <a-col :span="24">
      <div>
        <a-row>
          <a-col
            :span="24"
            style="padding: 30px"
          >
            <myTable :data="data" :columns="columns" :title="'Cochran秩检验分析结果'"></myTable>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 30px">
            <box-shadow-card :title="'Cochran多样本秩检验智能分析结果'">
              <div v-for="item in result" :key="item">
                <span v-html="item">{{ item }}</span>
              </div>
            </box-shadow-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { MakeReq } from '../MakeReqClass'
import { Tables } from '@/views/hypothesisTest/utils/formatTableClass'
export default {
  created() {
    this.fetchResult()
  },
  data() {
    return {
      method: this.fetchTable,
      method2: Tables.kaFang,
      documents: this.$store.state.samples.documents && JSON.parse(this.$store.state.samples.documents),
      columns: [],
      data: [],
      part2: {
        p: null,
        list: [],
        xys: [[[]], ['']]
      }
    }
  },
  methods: {
    fetchResult() {
      this.$hypothesis.resultMethod(this, ['q', 'p'])
    },
    fetchTable(columns, t, res) {
        const xLength = res.part2.xys[0].length
        const yLength = res.part2.yList.length
        this.method2(columns, t, xLength, yLength)
    },
    makeReq(form, method) {
      var makeReq = new MakeReq(form, method, this)
      return makeReq.leiDuo()
    }
  },
  computed: {
    leiList() { return this.part2.xys[0].map(v => `&lt;${v[v.length - 1]}&gt;`).join(' ') },
    H0() { return [`${this.leiList}之间不存在显著性差异`] },
    H1() { return [`${this.leiList}之间存在显著性差异`] },
    result() {
      return [`1.${this.$hypothesis.conclusion(this.part2.p, this.part2.a, this.H0[0], this.H1[0], 'cochran')}`]
    }
  }
}
</script>
