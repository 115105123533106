var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "hCommon" }, [_vm._v("生活方式及社会因素")]),
    _c(
      "div",
      { staticClass: "itemWrapperCommon" },
      [
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            staticStyle: { "margin-top": "20px" },
            attrs: { id: "diet" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("饮食习惯")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("嗜甜食")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.dietsweetPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("嗜咸食")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.dietsaltyPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("嗜高脂")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.diethighfatPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("其他")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.dietotherPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.dietlostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.dietlostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-histogram", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.dietChart,
                                height: "200px",
                                width: "250px",
                                "legend-visible": false,
                                extend: _vm.histogramExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "exercise" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("经常参与体育锻炼")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("是")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.exerciseyesPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("否")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.exercisenoPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.exerciselostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.exerciselostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.exerciseChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.chartExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "smoke" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("吸烟或被动吸烟")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("是")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.smokeyesPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("否")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.smokenoPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.smokelostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.smokelostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.smokeChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.chartExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "wine" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("经常饮酒")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("是")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.wineyesPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("否")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.winenoPercent) + "%")
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.winelostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.winelostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.wineChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.chartExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "stayUpLate" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("经常熬夜")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("是")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.stayUpLateyesPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("否")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.stayUpLatenoPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.stayUpLatelostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.stayUpLatelostPercent) +
                                        "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.stayUpLateChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.chartExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("divider-common"),
        _c(
          "div",
          {
            staticClass: "itemWrapperCommonPaddingLeft",
            attrs: { id: "pressure" }
          },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: "11" } },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: "12" } }, [
                          _c(
                            "span",
                            { staticClass: "htCommon htCommonColor" },
                            [_vm._v("面临生活或工作方面的压力")]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: "12" } },
                          [
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("是")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.pressureyesPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("否")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.pressurenoPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(_vm._s(_vm.data.pressurelostCount))
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c("divider-common"),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "httCommon" }, [
                                    _vm._v("缺失%")
                                  ])
                                ]),
                                _c("a-col", { attrs: { span: "12" } }, [
                                  _c("span", { staticClass: "ttCommon" }, [
                                    _vm._v(
                                      _vm._s(_vm.data.pressurelostPercent) + "%"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: "11", offset: 2 } },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: "12", offset: 10 } },
                          [
                            _c("ve-bar", {
                              staticStyle: { float: "right" },
                              attrs: {
                                data: _vm.pressureChart,
                                width: "250px",
                                height: "180px",
                                "legend-visible": false,
                                extend: _vm.chartExtend
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }