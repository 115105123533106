var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.data, function(item, key) {
      return _c("div", { key: key }, [
        item.isShow
          ? _c(
              "div",
              { attrs: { hidden: item.isHidden } },
              [
                _c(
                  "a-row",
                  {
                    staticClass: "marginTop",
                    attrs: { type: "flex", justify: "center" }
                  },
                  [
                    _c(
                      "a-col",
                      { staticClass: "subject", attrs: { span: 22 } },
                      [
                        item.type !== null &&
                        _vm.childrenRequire &&
                        item.isRequired
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              Number(item.order) > 0
                                ? (item.parentId
                                    ? key + 1 + "）"
                                    : key + 1 + ". ") + item.text
                                : item.text
                            ) +
                            " "
                        ),
                        item.location === "remark"
                          ? _c(
                              "span",
                              { staticStyle: { "padding-left": "5px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "(" +
                                        item.remarkName +
                                        ":" +
                                        (item.remark ? item.remark : "") +
                                        ")"
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                item.describe !== ""
                  ? _c(
                      "a-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        _c(
                          "a-col",
                          {
                            staticClass: "content marginLeft",
                            attrs: { span: 22 }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "white-space": "pre-wrap" } },
                              [_vm._v(_vm._s(item.describe))]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === null
                  ? _c(
                      "a-row",
                      {
                        staticClass: "marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                }
                              },
                              [
                                _c("subject-comp-new", {
                                  attrs: {
                                    data: item.children,
                                    childrenRequire: true,
                                    formaaa: _vm.formdata,
                                    disabled: _vm.disabled
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 4
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-input", {
                                  staticClass: "widthStyle",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: item.tips
                                  },
                                  on: {
                                    change: function(e) {
                                      _vm.$emit("doclink", item, e)
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.formdata[
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formdata,
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 5
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important",
                                  width: "100%"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: [
                                    {
                                      type: "array",
                                      required:
                                        _vm.childrenRequire && item.isRequired,
                                      message: "必填"
                                    },
                                    {
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        return _vm.chkvalueChange(
                                          rule,
                                          value,
                                          callback,
                                          item
                                        )
                                      },
                                      trigger: "change"
                                    }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "a-checkbox-group",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      change: function(value) {
                                        _vm.checkgroupChange(value, item)
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  },
                                  _vm._l(item.optionArry, function(option, i) {
                                    return _c(
                                      "div",
                                      { key: i },
                                      [
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              [
                                                _c(
                                                  "a-checkbox",
                                                  {
                                                    staticClass: "content",
                                                    attrs: {
                                                      disabled:
                                                        _vm.disabled ||
                                                        option.disabled,
                                                      name:
                                                        item.id +
                                                        "_" +
                                                        item.type +
                                                        "_" +
                                                        item.docField,
                                                      value:
                                                        option.score +
                                                        "_" +
                                                        option.text
                                                    },
                                                    on: {
                                                      change: function(value) {
                                                        _vm.childrenRequireChange(
                                                          value,
                                                          item.optionArry
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(option.text))]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        option.childrenRequire
                                          ? _c("subject-comp-new", {
                                              attrs: {
                                                data: option.children,
                                                formaaa: _vm.formdata,
                                                childrenRequire:
                                                  option.childrenRequire,
                                                disabled: _vm.disabled
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 2
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    staticStyle: { width: "80%" },
                                    on: {
                                      change: function(value) {
                                        _vm.radionChange(value, item)
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  },
                                  _vm._l(item.optionArry, function(option, i) {
                                    return _c(
                                      "div",
                                      { key: i },
                                      [
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              [
                                                _c(
                                                  "a-radio",
                                                  {
                                                    staticClass: "content",
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      value:
                                                        option.score +
                                                        "_" +
                                                        option.text
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(option.text))]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        option.childrenRequire
                                          ? _c("subject-comp-new", {
                                              attrs: {
                                                data: option.children,
                                                formaaa: _vm.formdata,
                                                childrenRequire:
                                                  option.childrenRequire,
                                                disabled: _vm.disabled
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 1
                  ? _c(
                      "a-row",
                      {
                        staticClass: "marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      "show-search": "",
                                      "filter-option": _vm.filterOption
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  },
                                  _vm._l(item.optionArry, function(option, i) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: i,
                                        attrs: {
                                          value:
                                            option.score + "_" + option.text
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(option.text))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 0
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-input-number", {
                                  staticClass: "widthStyle",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: item.tips
                                  },
                                  on: {
                                    change: function(e) {
                                      return _vm.valueChange(e, item)
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.formdata[
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formdata,
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(" " + _vm._s(_vm.getUnit(item.unit)))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 3
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-date-picker", {
                                  staticClass: "widthStyle",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    locale: _vm.locale,
                                    placeholder: "选择时间"
                                  },
                                  model: {
                                    value:
                                      _vm.formdata[
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formdata,
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 6
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("a-cascader", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    options: _vm.options,
                                    "field-names": {
                                      label: "name",
                                      value: "code",
                                      children: "children"
                                    },
                                    "expand-trigger": "hover",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value:
                                      _vm.formdata[
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formdata,
                                        item.id +
                                          "_" +
                                          item.type +
                                          "_" +
                                          item.docField,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 7
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField +
                                    "_image",
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "请上传图片"
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-upload",
                                  {
                                    attrs: {
                                      "list-type": "picture-card",
                                      "file-list":
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField +
                                            "_image"
                                        ],
                                      disabled: _vm.disabled,
                                      action: "/api/Upload"
                                    },
                                    on: {
                                      preview: function(e) {
                                        return _vm.handlePreview(e, item)
                                      },
                                      change: function(e) {
                                        return _vm.handleChange(e, item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "plus" }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "ant-upload-text" },
                                          [_vm._v("选择图片")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "a-modal",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField +
                                            "_previewVisible"
                                        ],
                                      footer: null
                                    },
                                    on: {
                                      cancel: function($event) {
                                        return _vm.handleCancel(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        alt: "example",
                                        src:
                                          _vm.formdata[
                                            item.id +
                                              "_" +
                                              item.type +
                                              "_" +
                                              item.docField +
                                              "_previewImage"
                                          ]
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField
                                }
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      mode: "tags",
                                      placeholder:
                                        "选择相应的标签，没有的话直接填写自己标签"
                                    },
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}`]"
                                    }
                                  },
                                  _vm._l(item.tags, function(tag) {
                                    return _c("a-select-option", { key: tag }, [
                                      _vm._v(" " + _vm._s(tag) + " ")
                                    ])
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 8
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "a-form-model-item",
                              [
                                _c(
                                  "a-table",
                                  {
                                    attrs: {
                                      size: "middle",
                                      columns: item.columns,
                                      dataSource:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField +
                                            "_table"
                                        ],
                                      pagination: false,
                                      scroll: { x: true }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(item.tabcols, function(col) {
                                          return {
                                            key: col.name,
                                            fn: function(text, record) {
                                              return [
                                                col.type === 1
                                                  ? _c(
                                                      "div",
                                                      { key: col.name },
                                                      [
                                                        _c(
                                                          "a-form-model-item",
                                                          [
                                                            _c("a-input", {
                                                              attrs: {
                                                                disabled:
                                                                  record[
                                                                    col.name +
                                                                      "_disabled"
                                                                  ] ||
                                                                  _vm.disabled
                                                              },
                                                              model: {
                                                                value:
                                                                  record[
                                                                    col.name
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    record,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "record[col.name]"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 2
                                                  ? _c(
                                                      "div",
                                                      { key: col.name },
                                                      [
                                                        _c(
                                                          "a-form-model-item",
                                                          [
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    record[
                                                                      col.name +
                                                                        "_disabled"
                                                                    ] ||
                                                                    _vm.disabled,
                                                                  max:
                                                                    col.rangeMax !==
                                                                    null
                                                                      ? col.rangeMax
                                                                      : Infinity,
                                                                  min:
                                                                    col.rangeMin !==
                                                                    null
                                                                      ? col.rangeMin
                                                                      : -Infinity
                                                                },
                                                                model: {
                                                                  value:
                                                                    record[
                                                                      col.name
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      record,
                                                                      col.name,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "record[col.name]"
                                                                }
                                                              }
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getUnit(
                                                                      col.unit
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 3
                                                  ? _c(
                                                      "div",
                                                      { key: col.name },
                                                      [
                                                        _c(
                                                          "a-form-model-item",
                                                          [
                                                            _c(
                                                              "a-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "160px"
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    record[
                                                                      col.name +
                                                                        "_disabled"
                                                                    ] ||
                                                                    _vm.disabled
                                                                },
                                                                model: {
                                                                  value:
                                                                    record[
                                                                      col.name
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      record,
                                                                      col.name,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "record[col.name]"
                                                                }
                                                              },
                                                              _vm._l(
                                                                col.options,
                                                                function(
                                                                  op,
                                                                  opi
                                                                ) {
                                                                  return _c(
                                                                    "a-select-option",
                                                                    {
                                                                      key: opi,
                                                                      attrs: {
                                                                        value:
                                                                          op.text
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          op.text
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 4
                                                  ? _c(
                                                      "div",
                                                      { key: col.name },
                                                      [
                                                        _c(
                                                          "a-form-model-item",
                                                          [
                                                            _c(
                                                              "a-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "160px"
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    record[
                                                                      col.name +
                                                                        "_disabled"
                                                                    ] ||
                                                                    _vm.disabled,
                                                                  mode:
                                                                    "multiple"
                                                                },
                                                                model: {
                                                                  value:
                                                                    record[
                                                                      col.name
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      record,
                                                                      col.name,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "record[col.name]"
                                                                }
                                                              },
                                                              _vm._l(
                                                                col.options,
                                                                function(
                                                                  op,
                                                                  opi
                                                                ) {
                                                                  return _c(
                                                                    "a-select-option",
                                                                    {
                                                                      key: opi,
                                                                      attrs: {
                                                                        value:
                                                                          op.text
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          op.text
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                col.type === 5
                                                  ? _c(
                                                      "div",
                                                      { key: col.name },
                                                      [
                                                        _c(
                                                          "a-form-model-item",
                                                          [
                                                            _c("a-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  record[
                                                                    col.name +
                                                                      "_disabled"
                                                                  ] ||
                                                                  _vm.disabled
                                                              },
                                                              on: {
                                                                change: function(
                                                                  value
                                                                ) {
                                                                  _vm.checkedChange(
                                                                    value,
                                                                    record,
                                                                    col.linkcol,
                                                                    item
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  record[
                                                                    col.name
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    record,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "record[col.name]"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        })
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.formdata[
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField +
                                            "_table"
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formdata,
                                          item.id +
                                            "_" +
                                            item.type +
                                            "_" +
                                            item.docField +
                                            "_table",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formdata[`${item.id}_${item.type}_${item.docField}_table`]"
                                    }
                                  },
                                  [
                                    _vm._l(item.tabcols, function(col, i) {
                                      return _c(
                                        "template",
                                        { slot: col.name + "_Title" },
                                        [
                                          _c("div", { key: i }, [
                                            _vm._v(_vm._s(col.name))
                                          ]),
                                          col.shuoming
                                            ? _c(
                                                "div",
                                                {
                                                  key: i + "shuoming",
                                                  staticStyle: {
                                                    "font-size": "8px",
                                                    color: "grey"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " （" +
                                                      _vm._s(col.shuoming) +
                                                      "） "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === 9
                  ? _c(
                      "a-row",
                      {
                        staticClass: "content marginSujectTop marginLeft",
                        attrs: { type: "flex", justify: "center" }
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px !important"
                                },
                                attrs: {
                                  prop:
                                    item.id +
                                    "_" +
                                    item.type +
                                    "_" +
                                    item.docField,
                                  rules: {
                                    required:
                                      _vm.childrenRequire && item.isRequired,
                                    message: "必填"
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "content" }, [
                                  _vm._v("开始时间")
                                ]),
                                _c(
                                  "a-input-group",
                                  { attrs: { compact: "" } },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticClass: "content",
                                        staticStyle: { width: "20%" },
                                        attrs: { placeholder: "时" },
                                        model: {
                                          value:
                                            _vm.formdata[
                                              item.id +
                                                "_" +
                                                item.type +
                                                "_" +
                                                item.docField
                                            ].start[0],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].start,
                                              0,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formdata[`${item.id}_${item.type}_${item.docField}`].start[0]"
                                        }
                                      },
                                      _vm._l(_vm.enumHour, function(h, i) {
                                        return _c(
                                          "a-select-option",
                                          { key: i, attrs: { value: h.value } },
                                          [_vm._v(_vm._s(h.value))]
                                        )
                                      }),
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { margin: "auto 10px" } },
                                      [_vm._v(":")]
                                    ),
                                    _c(
                                      "a-select",
                                      {
                                        staticClass: "content",
                                        staticStyle: { width: "20%" },
                                        attrs: { placeholder: "分" },
                                        model: {
                                          value:
                                            _vm.formdata[
                                              item.id +
                                                "_" +
                                                item.type +
                                                "_" +
                                                item.docField
                                            ].start[1],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].start,
                                              1,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formdata[`${item.id}_${item.type}_${item.docField}`].start[1]"
                                        }
                                      },
                                      _vm._l(_vm.enumMinture, function(h, i) {
                                        return _c(
                                          "a-select-option",
                                          { key: i, attrs: { value: h.value } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                h.value === 0 ? "00" : h.value
                                              )
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v("结束时间")
                                ]),
                                _c(
                                  "a-input-group",
                                  { attrs: { compact: "" } },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticClass: "content",
                                        staticStyle: { width: "20%" },
                                        attrs: { placeholder: "时" },
                                        model: {
                                          value:
                                            _vm.formdata[
                                              item.id +
                                                "_" +
                                                item.type +
                                                "_" +
                                                item.docField
                                            ].end[0],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].end,
                                              0,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formdata[`${item.id}_${item.type}_${item.docField}`].end[0]"
                                        }
                                      },
                                      _vm._l(_vm.enumHour, function(h, i) {
                                        return _c(
                                          "a-select-option",
                                          { key: i, attrs: { value: h.value } },
                                          [_vm._v(_vm._s(h.value))]
                                        )
                                      }),
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { margin: "auto 10px" } },
                                      [_vm._v(":")]
                                    ),
                                    _c(
                                      "a-select",
                                      {
                                        staticClass: "content",
                                        staticStyle: { width: "20%" },
                                        attrs: { placeholder: "分" },
                                        model: {
                                          value:
                                            _vm.formdata[
                                              item.id +
                                                "_" +
                                                item.type +
                                                "_" +
                                                item.docField
                                            ].end[1],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formdata[
                                                item.id +
                                                  "_" +
                                                  item.type +
                                                  "_" +
                                                  item.docField
                                              ].end,
                                              1,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formdata[`${item.id}_${item.type}_${item.docField}`].end[1]"
                                        }
                                      },
                                      _vm._l(_vm.enumMinture, function(h, i) {
                                        return _c(
                                          "a-select-option",
                                          { key: i, attrs: { value: h.value } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                h.value === 0 ? "00" : h.value
                                              )
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                item.children.length === 0 ? _c("a-divider") : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }