<template>
  <div
    type="text"
    :class="{ 'ant-input': true, dropDiv: true, 'drop-disabled': 'mark' in item }"
    @drop="drop(item)"
    @dragover.prevent
  >
    <span v-if="item.list.length === 0">
      <span v-for="help in item.divHelp" :key="help" :class="{ helpFontColor: true }">{{ help }}</span>
    </span>
    <a-tag
      class="myTag"
      :closable="!('mark' in item) && !('mark' in value)"
      @close="(e) => onClose(e, item, i, value)"
      v-for="(value, i) in item.list"
      :key="i"
      :visible="value !== null"
    >{{ value.name }}</a-tag
    >
  </div>
</template>
<script>
import { DropClass } from '@/utils/util'
/**
* @param obj 是拖进去的那一项有关的参数
* @param item 是当前drop框的相关属性
*/
export default {
  props: ['item', 'index', 'parentType', 'load', 'xysName', 'indexGroup', 'vuex'],
  /**
   * @param item 每一个drop框的属性对象
   * @param index 当前操作的drop框的索引，如果是drops中传来的则是[]，反之是数字
   * @param parentType 用来判断是drops还是drop传来的
   * @param load 控制加载图标的
   * @param xysName vuex中xys的名字
   * @param indexGroup 当类型是drops时，是一个组合的初始配置;当类型是drop时，是一个拖选框的初始配置。
   * @param vuex 使用vuex里的哪个模块的参数
   */
  data() {
    return {

    }
  },
  methods: {
    drop(item) {
      if (!('mark' in item)) {
        this.load.anysisLoading = true
        const djson = event.dataTransfer.getData('item')
        const obj = JSON.parse(djson)
        // 如果是可以选择的拖拽框，那么根据拖拽的变量的类别来决定这个框的属性。
        this.$hypothesis.changeDropRule(item, obj, this)
        const checkObj = {
          num: item.num,
          type: item.dropType,
          location: item.label,
          optLimit: item.optLimit,
          vm: this,
          load: { anysisLoading: false }
        }
        const dropObj = new DropClass(item.list, obj, item.divHelp, this.$message, `aaa`, this, true, item)
        dropObj.check(checkObj)
      }
    },
    onChange(obj) {
      onChange[obj.type](this.xysList, obj)
    },
    onClose(e, item, index, value) {
      item.list.splice(index, 1)
      if ((item.type === 'drop') || item.type === 'dropSelect') onClose.general(this.xysList, index)
      // 如果拖拽框里一个变量都没有，那么初始化它以及它的子内容。
      if (item.list.length === 0) {
        this.initialDropDiv(item)
      }
    },
    // 初始化拖拽框
    initialDropDiv(item) {
      // 通过当前点击的drop框是组合中的第几个，来获取原始item结构。来初始化赋值
      const index = this.index[1]
      const { parentType, indexGroup } = this
      // a是初始的一个拖选框的配置
      const a = this.$deepClone(parentType === 'drops' ? indexGroup[index] : indexGroup)
      //   var a = this.$hypothesis.findChildren(item.id, this.$deepClone(this.indexSettings))
      // 如果是dropSelect，那么执行下面代码
      if (item.options.length > 0) {
        item.label = a.label
        item.value = a.value
        item.type = a.type
        item.children = a.children
      }
      item.divHelp = a.divHelp
      this.$store.dispatch('refreshRules')
    }
  },
  computed: {
    //  获取vuex中当前操作的那个数组
    xysList() {
      const array = this.$store.state[this.vuex][this.xysName]
      const index = this.index
      const resultAry = typeof index === 'object' ? array[index[0]][index[1]] : array[index]
      return resultAry
    },
    dropType() {
      return this.item.dropType
    }
  }
}

const onChange = {
  量(list, obj) {
    list.push(obj.name)
  },
  类(list, obj) {
    const lei = [].concat(obj.options, obj.name)
    list.push(lei)
  }
}

const onClose = {
  general(list, i) {
    list.splice(i, 1)
  }
}
</script>
<style scoped>
.drop-disabled {
  background: #fafafa;
  word-wrap: break-word;
}
</style>