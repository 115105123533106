var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "third-module" }, [_vm._t("text1")], 2),
    _c(
      "div",
      { staticClass: "third-module " },
      _vm._l(_vm.tables, function(table, index) {
        return _c(
          "a-table",
          {
            key: index,
            ref: "tables,2",
            refInFor: true,
            staticStyle: { "margin-top": "20px" },
            attrs: {
              pagination: false,
              dataSource: table.data,
              columns: table.columns,
              size: "small"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "zhixin",
                  fn: function(text, record) {
                    return _c(
                      "span",
                      {},
                      [
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                "(" +
                                  record["置信区间"][0] +
                                  "," +
                                  record["置信区间"][1] +
                                  ")"
                              )
                            )
                          ])
                        ]
                      ],
                      2
                    )
                  }
                },
                {
                  key: "number",
                  fn: function(text, record) {
                    return _c(
                      "span",
                      {},
                      [
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(record["平均值"] + "±" + record["标准差"])
                            )
                          ])
                        ]
                      ],
                      2
                    )
                  }
                }
              ],
              null,
              true
            )
          },
          _vm._l(table.columns, function(item, i) {
            return _c(
              "div",
              {
                key: i,
                attrs: { slot: "slotValue" in item ? item.slotValue : "" },
                slot: "slotValue" in item ? item.slotValue : ""
              },
              [
                _c("div", { domProps: { innerHTML: _vm._s(item.slotValue) } }, [
                  _vm._v(_vm._s(item.slotValue))
                ])
              ]
            )
          }),
          0
        )
      }),
      1
    ),
    _c("div", { staticClass: "third-module" }, [_vm._t("text2")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }