var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.itemForms, function(itemForm, index) {
      return _c(
        "div",
        { key: index },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.name + "名称" } },
            [
              _c("a-input", {
                style: { width: _vm.inputWidth },
                attrs: { placeholder: "请输入" + _vm.name + "名称" },
                model: {
                  value: itemForm.name,
                  callback: function($$v) {
                    _vm.$set(itemForm, "name", $$v)
                  },
                  expression: "itemForm.name"
                }
              }),
              _vm.itemForms.length !== 1
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#1890ff",
                        "margin-left": "10px",
                        cursor: "pointer"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteItem(index)
                        }
                      }
                    },
                    [
                      _c("a-icon", {
                        attrs: {
                          type: "minus-circle",
                          theme: "twoTone",
                          twoToneColor: "#1890FF"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              index === _vm.itemForms.length - 1
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#1890ff",
                        "margin-left": "10px",
                        cursor: "pointer"
                      },
                      on: { click: _vm.addItem }
                    },
                    [
                      _c("a-icon", {
                        attrs: {
                          type: "plus-circle",
                          theme: "twoTone",
                          twoToneColor: "#1890FF"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.name + "厂家" } },
            [
              _c("a-input", {
                style: { width: _vm.inputWidth },
                attrs: { placeholder: "请输入" + _vm.name + "厂家" },
                model: {
                  value: itemForm.manufactor,
                  callback: function($$v) {
                    _vm.$set(itemForm, "manufactor", $$v)
                  },
                  expression: "itemForm.manufactor"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.name + "批号" } },
            [
              _c("a-input", {
                style: { width: _vm.inputWidth },
                attrs: { placeholder: "请输入" + _vm.name + "批号" },
                model: {
                  value: itemForm.batchNum,
                  callback: function($$v) {
                    _vm.$set(itemForm, "batchNum", $$v)
                  },
                  expression: "itemForm.batchNum"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.name + "有效截止日" } },
            [
              _c("a-date-picker", {
                style: { width: _vm.inputWidth },
                attrs: {
                  value: itemForm.expiryDate
                    ? _vm.$moment(itemForm.expiryDate)
                    : undefined
                },
                on: {
                  change: function(date, dateString) {
                    return date
                      ? (itemForm.expiryDate = date.format())
                      : (itemForm.expiryDate = undefined)
                  }
                }
              })
            ],
            1
          ),
          index !== _vm.itemForms.length - 1
            ? _c("a-divider", {
                staticStyle: { "min-width": "50%", width: "50%" },
                attrs: { dashed: "" }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }