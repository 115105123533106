var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("index", {
    attrs: {
      xysName: _vm.xysName,
      xys: _vm.xys,
      formAddKeyName: _vm.formAddKeyName,
      pForm: _vm.form,
      pSettings: _vm.settings
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "H0",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0 && xys[1].length > 0
              ? _c(
                  "div",
                  [
                    _vm._l(xys[0][0], function(item, index) {
                      return _c("span", { key: index }, [
                        index !== xys[0][0].length - 1
                          ? _c("span", [_vm._v("<" + _vm._s(item) + ">")])
                          : _vm._e()
                      ])
                    }),
                    _vm._v(
                      _vm._s(xys[0][0].length - 1) +
                        "组样本<" +
                        _vm._s(xys[1][0]) +
                        ">的总体均值相等 "
                    )
                  ],
                  2
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "H1",
        fn: function(ref) {
          var xys = ref.xys
          return [
            xys[0].length > 0 && xys[1].length > 0
              ? _c(
                  "div",
                  [
                    _vm._l(xys[0][0], function(item, index) {
                      return _c("span", { key: index }, [
                        index !== xys[0][0].length - 1
                          ? _c("span", [_vm._v("<" + _vm._s(item) + ">")])
                          : _vm._e()
                      ])
                    }),
                    _vm._v(
                      _vm._s(xys[0][0].length - 1) +
                        "组样本<" +
                        _vm._s(xys[1][0]) +
                        ">的总体均值不等或不全相等 "
                    )
                  ],
                  2
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }